


(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InvoiceDetailController', InvoiceDetailController);

    InvoiceDetailController.$inject = ['$q', '$state','$scope',  'entity', 'MessageService', 'Invoice', 'InvoiceLocation', 'InvoiceSetting', '$stateParams'];

    function InvoiceDetailController($q, $state, $scope, entity, MessageService, Invoice, InvoiceLocation, InvoiceSetting, $stateParams) {

        var vm = this;
        vm.save = save;
        vm.clear = clear;
        vm.invoice = entity;
        vm.addSetting = false;
        vm.clear2 = clear2;
        vm.save2 = save2;

        $scope.addSetting = function(){
            vm.addSetting = true;
        }
        vm.invoiceLocations = InvoiceLocation.getAll({});
        vm.settings = InvoiceSetting.getAllByCondition({});
        $q.all([vm.invoiceLocations.$promise,  vm.invoice.$promise, vm.settings.$promise]).then(function(){
            if (vm.invoiceLocations && vm.invoiceLocations.length>0) {
                for (var i = vm.invoiceLocations.length - 1; i >= 0; i--) {
                    var location = vm.invoiceLocations[i];
                    if (location.enable && vm.invoice.id == null) {
                        vm.selectedLocation = location.id;
                        break;
                    }
                }
            }
        });

        function clear2(){
            vm.addSetting = false;
        }

        $scope.validate2 = function(data) {
            if (!vm.openType) {
                if (data.title == null || data.title == "") {
                    MessageService.error("请输入发票抬头");
                    vm.isSaving = false;
                    return false;
                };
            } else {
                vm.invoiceSetting.title = "个人";
                vm.invoiceType = true;
            }

            // 增值税专用发票--不可为空， 增值税普通发票可为空
            if (!vm.invoiceType) {
                if (data.taxRegistrationNumber == null || data.taxRegistrationNumber == "") {
                    MessageService.error("请输入税务登记证号");
                    vm.isSaving = false;
                    return false;
                };
 
            }
            return true;
        }

        function save2() {
            if (vm.openType) {
                vm.invoiceSetting.openType = "Personal";
            } else {
                vm.invoiceSetting.openType = "Company";
            }

            if (vm.invoiceType) {
                vm.invoiceSetting.invoiceType = "Ordinary";
            } else {
                vm.invoiceSetting.invoiceType = "Special";
            }
            if (!$scope.validate2(vm.invoiceSetting)) {
                return;
            };
            vm.isSaving = true;
            InvoiceSetting.update(vm.invoiceSetting, function(result){
                vm.isSaving = false;
                vm.addSetting = false;
                vm.settings.push(result);
                vm.selectedTitle = result.id;
                MessageService.success("操作成功");
            }, function(){
                vm.isSaving = false;
                MessageService.error("操作失败");
            })
        }
        
        function clear () {
            $state.go("pay-order2")
        }

         $scope.validate = function() {
            if (vm.selectedLocation == null) {
                MessageService.error("请选择发票寄送地址");
                return false;
            };
             
            if (vm.selectedTitle == null || vm.selectedTitle == "") {
                MessageService.error("请选择发票抬头");
                return false;
            };
            return true;
         }

        function save() {
            if (!$scope.validate()) {
                return;
            };

            vm.isSaving = true;
            var data = {
                ids:$stateParams.ids,
                locationId: vm.selectedLocation,
                settingId: vm.selectedTitle
            }
            Invoice.create(data, function(result){
                MessageService.success("操作成功");
                $state.go("invoice");
            }, function(){
                vm.isSaving = false;
                MessageService.error("操作失败");
            })
        }
    }
})();
