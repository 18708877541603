(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassController', PassController);

    PassController.$inject = ['$scope', '$state', 'Pass', 'Qinius', '$uibModal', 'MessageService', 'pagingParams', 
    'ParseLinks', 'paginationConstants'];

    function PassController ($scope, $state, Pass, Qinius, $uibModal, MessageService, pagingParams, 
        ParseLinks, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.passProducts = [];
        Qinius.getDomain({}, function(data){
            $scope.domain = 'http://' + data.domains[0] + '/';
        });

        $scope.view = function (item) {
            $uibModal.open({
                templateUrl: "app/entities/pass/passes-dialog.html",
                controller: 'PassDialogController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                 size: 'lg',
                resolve: {
                    entity:item,
                }
            }).result.then(function(data) {
            });;
        }


        loadAll();

        function loadAll() {
            if (pagingParams.search) {
                Pass.byCondition({
                    items: [{key: "passProductName", op: "like", value: pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Pass.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.passes = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("产品获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }


    }
})();
