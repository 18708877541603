(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GiveNoticeController', GiveNoticeController);

    GiveNoticeController.$inject = ['$scope', '$state', 'VersionDescriptions', 'MessageService','$uibModal','Principal'];

    function GiveNoticeController ($scope, $state, VersionDescriptions, MessageService,$uibModal,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_VERSION_DESCRIPTION').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        VersionDescriptions.getAllByCondition({
            items:[{key:"initial", op:"=", value:"false"},{key:"cancelled", op:"=", value:"false"},{key:"enable", op:"=", value:"true"}],
            sort: ["seq,asc"]
        }, function (res) {
            vm.data = res.sort(function(a, b){
                return a.seq-b.seq;
            });
        });

        $scope.giveNoticeCb = function(item) {
            VersionDescriptions.get({ id: item.id }, function(data) {
                if (data) {
                    $uibModal.open({
                        templateUrl: 'app/entities/give-notice/modal/give-notice-modal.html',
                        controller: 'GiveNoticeModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        resolve: {
                            entity: [function() {
                                return data;
                            }]
                        }
                    });
                }
            })
        }
    }

})();
