(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PushTaskController', PushTaskController);

    PushTaskController.$inject = ['$scope', '$state', 'DateUtils', 'ParseLinks',  'pagingParams', 'paginationConstants', 'MessageService', 'BranchStore','$uibModal',
        'PushTask'];

    function PushTaskController ($scope, $state, DateUtils, ParseLinks, pagingParams, paginationConstants, MessageService, BranchStore,$uibModal,
                                   PushTask) {
        var vm = this;


        vm.dateformat = dateformat;
        function dateformat () {
            return 'yyyy-MM-dd';
        }
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // 修改开始时间之后要清空结束时间  以便应用日期禁用
        $scope.changeStartTime = function(){
            vm.endTime = ''
        }

        // vm.branchStores = BranchStore.getAllByCondition({
        //     items: [{key:"enabled",op:"=",value:true},{key:"cancelled",op:"=",value:false}]
        // })
        vm.branchStoreId = null;
        vm.status = "";
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.statusList = [
            {id:false, name:'失败'},
            {id:true, name:'成功'},
        ];

        vm.transition = function(){
            init();
        }
        $scope.search = function (){
            vm.page = 1;
            init();
        }
        $scope.clear = function (){
            vm.page = 1;
            vm.status = "";
            vm.branchStoreId = "";
            vm.keyword = "";
            vm.startTime = null
            vm.endTime = null
            init();
        }
        init();
        function init(){
            var items = [];
            if (vm.status!== null) {
                items.push({key:"status",op:"=",value:vm.status});
            }
            if (vm.keyword) {
                items.push({key:"orderNumber",op:"like",value:vm.keyword});
            }
            if (vm.startTime) {
                items.push({key: "operatorTime", op: ">=", value: DateUtils.convertLocalDateToServer(vm.startTime)+"T00:00:00.000Z"});
                items.push({key: "operatorTime", op: "<=", value: DateUtils.convertLocalDateToServer(vm.endTime)+"T23:59:59.000Z"});
            };
            PushTask.byCondition({
                items: items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res,headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tableList = res || [];
            }, function (error) {
                MessageService.error("获取失败");
            });
        }

        $scope.viewLog = function (data){
            $uibModal.open({
                templateUrl: 'app/entities/management/components/management-view-log.html',
                controller: 'ManagementViewLogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            });
        }
        // 批量重推
        $scope.rePush = function (data){
            PushTask.rePushTask(vm.data,function(){
                MessageService.success("重推成功");
                vm.data = {}
                init()
                vm.isSaving = false;
            },function onSaveError(error) {
                vm.isSaving = false;
                MessageService.error(error.data.message || "重推失败");
            })
        }
        $scope.getStatus = function (item) {
            // switch (item) {
            //     case true:
            //         return '成功';
            //     case 'error':
            //         return '失败';
            //     default:
            //         return item
            // }
        }
    }
})();
