(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GiftVoucherDetailController', GiftVoucherDetailController);

    GiftVoucherDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GiftVoucher'];

    function GiftVoucherDetailController($scope, $rootScope, $stateParams, previousState, entity, GiftVoucher) {
        var vm = this;

        vm.giftVoucher = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('backApp:giftVoucherUpdate', function(event, result) {
            vm.giftVoucher = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
