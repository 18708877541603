(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountMiniController', OpenAccountMiniController);


    OpenAccountMiniController.$inject = ['$scope', '$state', 'Store', 'MessageService', '$q', 'entity', 'PlatformWechatSetting', 'WechatAuth'];

    function OpenAccountMiniController ($scope, $state, Store, MessageService, $q, entity, PlatformWechatSetting, WechatAuth) {
        var vm = this;

        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        vm.pre_auth_code = WechatAuth.pre_auth_code();
        vm.platformWechatSetting = PlatformWechatSetting.get();
        vm.storeSmallAppSetting = entity;

        $q.all([vm.pre_auth_code.$promise, vm.platformWechatSetting.$promise, vm.storeSmallAppSetting.$promise, vm.store.$promise]).then(function () {
            if (vm.pre_auth_code.status == "200") {
                vm.preAuthCode =  vm.pre_auth_code.message;
            }
            $scope.notifyUrl = encodeURIComponent(vm.platformWechatSetting.backNotifyUrl + "/#/openAccount-mini-detail");
        })

        $scope.auth = function(){
            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="
                +vm.platformWechatSetting.appId+"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+$scope.notifyUrl+"&auth_type=2";
        }

        $scope.goBack= function(){
            window.history.go(-1);
        }
        $scope.save= function(){
            $state.go("openAccount-modePayment-mini");
        }
    }
})();
