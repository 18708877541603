(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassBankDetailController', PassBankDetailController);

    PassBankDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', '$state', '$uibModal'];

    function PassBankDetailController($scope, $rootScope, $stateParams, entity, $state, $uibModal) {
        var vm = this;
        vm.clear = clear;

        vm.backupPassCategory = entity;

        function clear(){
            $state.go("passBank")
        }
    }
})();
