(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('mould', {
            parent: 'entity',
            url: '/mould?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TEMPLATE'],
                pageTitle: 'platformApp.mould.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/mould/moulds.html',
                    controller: 'MouldController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('mould');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('mould-detail.edit', {
            parent: 'mould-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TEMPLATE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/mould/mould-dialog.html',
                    controller: 'MouldDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Mould', function(Mould) {
                            return Mould.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('mould.new', {
            parent: 'mould',
            url: '/new',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TEMPLATE']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/mould/mould-dialog.html',
                    controller: 'MouldDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function() {
                    return {
                        name: null,
                        number: null,
                        description: null,
                        enable: null,
                        cancelled: null,
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('mould');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('mould.edit', {
            parent: 'mould',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TEMPLATE']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/mould/mould-dialog.html',
                    controller: 'MouldDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('mould');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Mould','$stateParams', function(Mould,$stateParams) {
                    return Mould.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }

})();
