(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ClipArtController', ClipArtController);

    ClipArtController.$inject = ['$scope', '$q', '$state', 'ClipArt', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius'];

    function ClipArtController ($scope, $q, $state, ClipArt, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        // 获取qiniu服务器
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        $scope.changeType = function(clipArt){
            if (clipArt == null) {
                return;
            }
            MessageService.confirm("确认要修改该数据的类型？", function(){
                var type = "";
                if (clipArt.type = null || clipArt.type == "" || clipArt.type == "all") {
                    type = "wechat";
                }else{
                    type = "all";
                }
                ClipArt.updateType({id:clipArt.id, type:type},function(data){
                    MessageService.success("类型修改成功");
                    loadAll();
                }, function(){
                        MessageService.error("类型修改失败，请稍后再试");
                })
            },function(){})
        }


        $scope.delete = function(id){
            MessageService.confirm("确认删除该素材？",function(){
                ClipArt.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };

        function loadAll () {
            if (pagingParams.search) {
                ClipArt.byCondition({
                    items: [{key:"name,themeClassify.name",op:"like",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ClipArt.byCondition({
                    items:[],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.clipArts = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("素材获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
    }
})();
