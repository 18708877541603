(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('step1', {
            parent: 'store',
            url: '/step1',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/initialization/init-step1.html',
                    controller: 'InitStepOneController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })
        .state('init-wechat', {
            parent: 'store',
            url: '/init-wechat',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/initialization/init-wechat-subscription.html',
                    controller: 'InitWechatController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreWechatSetting', function($stateParams, StoreWechatSetting) {
                    return StoreWechatSetting.getPrivateWechatSetting().$promise;
                }],
            }
        })
        .state('init-wechat-notify', {
            parent: 'store',
            url: '/init-wechat-notify?auth_code&expires_in',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/initialization/init-wechat-subscription.html',
                    controller: 'InitWechatController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                    return WechatAuth.auth_notify({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in}).$promise;
                }]
            }
        })
        .state('init-mini', {
            parent: 'store',
            url: '/init-mini',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/initialization/init-wechat-mini.html',
                    controller: 'InitMiniController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreSmallAppWechatSetting', function($stateParams, StoreSmallAppWechatSetting) {
                    return StoreSmallAppWechatSetting.getEntityByStoreId().$promise;
                }],
            }
        })
        .state('init-mini-notify', {
            parent: 'store',
            url: '/init-mini-notify?auth_code&expires_in',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/initialization/init-wechat-mini.html',
                    controller: 'InitMiniController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                    return WechatAuth.auth_notify2({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in}).$promise;
                }]
            }
        })
        .state('init-payment', {
            parent: 'store',
            url: '/init-payment',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/initialization/init-payment.html',
                    controller: 'InitPaymentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        });
    }

})();
