(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MemberRegistrationController', MemberRegistrationController);

    MemberRegistrationController.$inject = ['$scope', '$state','UidService', 'Qinius', '$uibModal', '$q', 'VipSettings', 'Store','MembershipPackages','MessageService','Principal'];

    function MemberRegistrationController ($scope, $state,UidService, Qinius, $uibModal, $q, VipSettings, Store, MembershipPackages,MessageService,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_MEMBERSHIPSYS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();
        vm.member = {
            id: '',
            integralAlias: '',
            open: '',
            openIntegralExchange: '',
            registerPic: '',
            storeId: '',
            validate: '',
            vipLevelSettings: [],
            welfarePic: ''
        };
        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            init()
        });
        function init(){
            MembershipPackages.getAllByCondition({
                items: [{key: "cancelled", op: "=", value: "false"}],
                sort: ["seq,desc"]
            },function (res) {
                vm.couponData = res;
            });
            VipSettings.get({}, function (res) {
                vm.member = res;
            })
        }


        $scope.removeFile = function(item){
            if(item == 'welfarePic'){
                vm.member.welfarePic = "";
            }
            if(item == 'registerPic'){
                vm.member.registerPic = "";
            }
            if(item == 'entryImg'){
                vm.member.entryImg = "";
            }
        }
        window.upload = function ($event, item) {
            var files = $event.target.files;
            vm.uploadType = item;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'pic-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(vm.uploadType == 'welfarePic'){
                        vm.member.welfarePic = data.key;
                    }
                    if(vm.uploadType == 'registerPic'){
                        vm.member.registerPic = data.key;
                    }
                    if(vm.uploadType == 'entryImg'){
                        vm.member.entryImg = data.key;
                    }
                    VipSettings.update(vm.member, function (res) {
                        init()
                    })
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        //配置升级礼包
        $scope.cfgGift = function () {
            $uibModal.open({
                templateUrl: 'app/entities/memberRegistration/modal/gift.html',
                controller: 'GiftController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add',
                            id: ''
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    init()
                }
            });
        }
        $scope.editCoupons = function (item, index) {
            $uibModal.open({
                templateUrl: 'app/entities/memberRegistration/modal/gift.html',
                controller: 'GiftController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            id: item.id
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    init()
                }
            });
        }
    }
})();
