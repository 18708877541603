(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepot1Controller', MapDepot1Controller)

    MapDepot1Controller.$inject = ['$scope', '$state', 'Store', 'MessageService', '$timeout', 'StoragePayOrders', '$q', '$uibModalInstance','StoreStorages','StoragePrices1','$http'];

    function MapDepot1Controller($scope, $state, Store, MessageService, $timeout, StoragePayOrders, $q, $uibModalInstance, StoreStorages,StoragePrices1,$http) {
        var vm = this;
        vm.payType = 'weChat';
        vm.store = Store.getCurrent();
        $q.all([vm.store.$promise]).then(function () {
            StoragePrices1.byStoreId({id: vm.store.id},function (res) {
                if(res.status == 200){
                    vm.storages = res.message;
                    if(vm.storages.storagePriceLines && vm.storages.storagePriceLines.length>0){
                        for(var i=0; i<vm.storages.storagePriceLines.length; i++){
                            var _storagePriceLines = vm.storages.storagePriceLines[i];
                            if(!_storagePriceLines.cancelled){
                                vm.data = _storagePriceLines;
                                createOrder();
                                break
                            }
                        }
                        swiperInit();
                    }
                }else{
                    MessageService.error(res.message || "获取图片存储失败");
                }
            });
        });
        function swiperInit(){
            $timeout(function () {
                new Swiper('.swiper-container', {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    slidesPerView: 3,
                    grid: {
                        fill: 'column',
                        rows: 2,
                    },
                });
            }, 300)
        }
        $scope.depotSelect = function (item) {
            vm.data = item;
            createOrder()
        };

        function createOrder(){
            clearInterval($scope.timer);
            vm.thisTime = currentDateLater(vm.data.periodOfValidity);
            StoragePayOrders.createOrder({storagePriceLineId: vm.data.id}, function (res) {
                payInit(res)
            })
        }

        function payInit(item){
            if(vm.payType == 'weChat'){
                $http({
                    url: '/manager/api/storage-pay-orders/wechatPay/'+item.id,
                    method: 'GET',
                    responseType: 'blob'
                }).success(function(res) {
                    // if(res.status == 400){
                    //     MessageService.error(res.message || "获取支付信息失败");
                    //     return
                    // }
                    $timeout(function () {
                        vm.prepare = true;
                        var blob = new Blob([res], {
                            type: "image/jpeg"
                        });
                        $scope.image = URL.createObjectURL(blob);
                        console.log($scope.image)
                        //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                        $scope.timer = setInterval(function() {
                            StoragePayOrders.get({
                                id: item.id
                            }, function(val) {
                                if (!!val && val.paid) {
                                    clearInterval($scope.timer);
                                    MessageService.success("支付完成");
                                    $uibModalInstance.close(true);
                                }
                            }, function() {});
                        }, 2000);
                    })
                });
            }
        }

        //返回
        $scope.cancel = function() {
            clearInterval($scope.timer);
            $uibModalInstance.close(false);
        }

        function currentDateLater(item) {
            var date = new Date();
            var y = date.getFullYear() + item;
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            var second = date.getSeconds();
            minute = minute < 10 ? ('1' + minute) : minute;
            second = second < 10 ? ('0' + second) : second;
            return y + '-' + m + '-' + d;
            // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
        }
    }
})();
