(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('OrderAgreementModalController', OrderAgreementModalController);

    OrderAgreementModalController.$inject = ['$uibModalInstance', 'PlatformWechatSetting', '$q', '$sce'];

    function OrderAgreementModalController ($uibModalInstance, PlatformWechatSetting, $q, $sce) {
        var vm = this;
        vm.cancel = cancel;
        vm.orderTerms = "";
        vm.platformSetting = PlatformWechatSetting.get();
        $q.all([vm.platformSetting.$promise]).then(function(){
            vm.orderTerms = $sce.trustAsHtml(vm.platformSetting.orderTerms);
        })
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
