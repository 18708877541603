(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Evaluate', Evaluate);

    Evaluate.$inject = ['$resource', 'DateUtils'];

    function Evaluate($resource, DateUtils) {
        var resourceUrl = 'service/' + 'api/evaluates/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'update': {
                method: 'GET',
                params: {
                    path: 'update'
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        // data.startTime = DateUtils.convertLocalDateFromServer(data.startTime);
                        // data.endTime = DateUtils.convertLocalDateFromServer(data.endTime);
                    }
                    return data;
                }
            }
        });
    }
})();