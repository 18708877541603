(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountSubbranchController', OpenAccountSubbranchController);

    OpenAccountSubbranchController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', '$http', 'Store', '$q', 'MessageService'];

    function OpenAccountSubbranchController($state, $scope, $timeout, Qinius, $uibModal, $http, Store, $q, MessageService) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        $scope.save = function () {
            Store.branch(function (data) {
                if(vm.store.activated){
                    $state.go('openAccount-commercial');
                } else{
                   $state.go('openAccount-paymentMode');
                }
            },function (error) {
                MessageService.error("设置失败，请稍后重试！");
            })
        }
    }

})();
