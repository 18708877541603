(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShareSettingDialogController', ShareSettingDialogController);

    ShareSettingDialogController.$inject = ['$timeout', '$q', '$state', '$scope', '$stateParams', 'MessageService', 'entity', 'ShareSetting',
     'Product', 'Coupon', 'ListService'];

    function ShareSettingDialogController ($timeout, $q, $state, $scope, $stateParams, MessageService, entity, ShareSetting,
     Product, Coupon, ListService) {
        var vm = this;

        vm.shareSetting = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.products0 = Product.shareProducts();

        vm.coupons0 = Coupon.getAllByCondition({items:[{key:"sendRule", op:"=", value:"Share"}, 
            {key:"enabled", op:"=", value:"true"}]})

        $q.all([vm.products0.$promise, vm.shareSetting.$promise, vm.coupons0.$promise]).then(function () {
            vm.products = vm.products0;
            vm.coupons = vm.coupons0;
            for (var i = vm.shareSetting.products.length - 1; i >= 0; i--) {
                var p = vm.shareSetting.products[i];
                p.selected = true;
                vm.products.unshift(p);
            }

            for (var i = vm.coupons.length - 1; i >= 0; i--) {
                var c = vm.coupons[i];
                var result = ListService.inList(c, vm.shareSetting.coupons, ["id"]);
                if (result) {
                    c.selected = true;
                }else{
                    c.selected = false;
                }
            }
        });

        $scope.couponSelectd = function(c){
            c.selected = !c.selected;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () { 
            $state.go("share-setting")
        }

         $scope.productSelectd = function(t) {
            t.selected = !t.selected;
        }

        $scope.validata = function(){
            if (vm.shareSetting.products == null || vm.shareSetting.products.length == 0) {
                MessageService.error("请勾选适用商品");
                return false;
            }

            if (vm.shareSetting.type == null || vm.shareSetting.type == "") {
                MessageService.error("请选择类型");
                return false;
            }
            if (vm.shareSetting.type == "RedPacket") {
                if (vm.shareSetting.amount< vm.shareSetting.sendAmount) {
                    MessageService.error("最小金额不能大于最大金额");
                    return false;
                }
            }
            if (vm.shareSetting.type == "Coupon") {
                if (vm.shareSetting.coupons == null || vm.shareSetting.coupons.length == 0) {
                    MessageService.error("请设置优惠券");
                    return false;
                }
            }
            if (vm.shareSetting.dateEnable && (vm.shareSetting.startDate == null ||vm.shareSetting.endDate == null)) {
                MessageService.error("请设置时间");
                return false;
            }

            return true;
        }


        function save () {
            vm.shareSetting.products = [];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var t = vm.products[i];
                if (t.selected) {
                    vm.shareSetting.products.push({
                        id: t.id,
                        name: t.name
                    });
                };
            };
            vm.shareSetting.coupons = [];
            for (var i = vm.coupons.length - 1; i >= 0; i--) {
                var c = vm.coupons[i];
                if (c.selected) {
                    vm.shareSetting.coupons.push({
                        id: c.id,
                        name: c.name
                    });
                };
            };
            if(!$scope.validata()){
                return;
            }
            vm.isSaving = true;

            if (vm.shareSetting.id !== null) {
                ShareSetting.update(vm.shareSetting, onSaveSuccess, onSaveError);
            } else {
                ShareSetting.save(vm.shareSetting, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:shareSettingUpdate', result); 
            MessageService.success("保存成功");
            $state.go("share-setting")
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
