(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GiftCouponController', GiftCouponController);

    GiftCouponController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService', 'Coupon'];

    function GiftCouponController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService, Coupon) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.coupon = entity.data;
        vm.data = [];

        init();
        function init() {
            Coupon.getAllByCondition({
                items: [
                    {key: "cancelled", op: "=", value: "false"},
                    {key: "enabled", op: "=", value: "true"},
                    {key: "sendRule", op: "=", value: "Vip"}
                ]
            },function (res) {
                angular.forEach(res, function (item) {
                    vm.data.push(item)
                })
                // vm.data = res;
                // for(var i=0; i<vm.data.length; i++){
                //     if(vm.coupon.id == vm.data[i].id){
                //         vm.data[i].selected = true
                //     }
                // }
            })
        }

        $scope.chooseOne = function (item) {
            if(vm.coupon.id == item.id){
                vm.coupon = "";
            }else {
                vm.coupon = item;
            }
        }
        

        function save() {
            if(!vm.coupon){
                MessageService.error("请选择优惠卷");
                return
            }
            $uibModalInstance.close(vm.coupon);
        }
        //返回
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
