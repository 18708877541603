(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassProductNewController', PassProductNewController);

    PassProductNewController.$inject = ['$timeout', '$scope', '$stateParams', 'PassProduct', '$q', '$uibModal', 'MessageService', '$state',
    'Product', 'Qinius', 'Category'];

    function PassProductNewController ($timeout, $scope, $stateParams, PassProduct, $q, $uibModal, MessageService, $state,
     Product, Qinius, Category) {
        var vm = this;

        vm.search = search;
        vm.item = [];
        vm.availableProducts = PassProduct.getAvailableProducts();
        vm.domains = Qinius.getDomain();
        vm.classifyList  = Category.storeGetAllByCondition({items:[{key:"usable", op:"=", value:"true"}]});


        $scope.goodsOrder = function () {
            $uibModal.open({
                templateUrl: "app/entities/pass-product/pass-product-dialog.html",
                controller: "PassProductDialogController",
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:{},
                }
            }).result.then(function(data) {
                if(data && vm.item && vm.item.length>0){
                    var ids= [];
                    var changePrice = false;
                    for (var i = vm.item.length - 1; i >= 0; i--) {
                        var p = vm.item[i];
                        ids.push(p.id);
                        if (p.price<data.maxDiscount) {
                            changePrice = true;
                            data.maxDiscount = p.price;
                        }
                    }
                    if (changePrice) {
                        MessageService.confirm("抵扣金额上限大于商品最小金额，是否将商品最小金额设为抵扣金额上限？", function(){
                            PassProduct.batchCreate({productIds:ids, maxDiscount:data.maxDiscount,type:data.type,brokerage:data.brokerage }, function(data){
                                if (data && data.status == 400) {
                                    MessageService.error("新增失败");
                                }else{
                                    MessageService.success("新增成功");
                                    $state.go("pass-product");
                                }
                            }, function(error){
                                MessageService.error("新增失败");
                            })
                        }, function(){})
                    }else{
                        PassProduct.batchCreate({productIds:ids, maxDiscount:data.maxDiscount,type:data.type,brokerage:data.brokerage }, function(data){
                            if (data && data.status == 400) {
                                MessageService.error("新增失败");
                            }else{
                                MessageService.success("新增成功");
                                $state.go("pass-product");
                            }
                        }, function(error){
                            MessageService.error("新增失败");
                        })
                    }
                }
            });;
        }

        $q.all([vm.domains.$promise, vm.classifyList.$promise , vm.availableProducts.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            //获取分类
            productInit()
        });


        function search() {
            productInit()
        }


        $scope.classifyCb = function (data) {
            vm.classify = data;
            vm.page = 1;
            productInit()
        }

        //获取商品
        function productInit(){
            vm.products = [];
            for (var i = vm.availableProducts.length - 1; i >= 0; i--) {
                var p = vm.availableProducts[i];
                if(p.cacenlled || !p.saleable){
                    continue;
                }
                if (vm.classify!= null && vm.classify.id && p.categoryStoreId != vm.classify.id) {
                    continue;
                }
                if(vm.keyword && (p.name.toLowerCase()).indexOf(vm.keyword.toLowerCase()) <0 ){
                    continue;
                }
                 
                vm.products.push(p)
            }

            var nums = 20; //每页出现的数量
            var pages = Math.ceil(vm.products.length/nums); //得到总页数
            var thisDate = function(curr){
                //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                var str = [], last = curr*nums - 1;
                last = last >= vm.products.length ? (vm.products.length-1) : last;
                for(var i = (curr*nums - nums); i <= last; i++){
                    str.push(vm.products[i]);
                }
                return str;
            };

            //调用分页
            laypage({
                cont: 'c-paging',
                curr: vm.page, //初始化当前页
                pages: pages,
                skip: true, //是否开启跳页
                first: false,
                last: false,
                jump: function(obj){
                    $timeout(function () {
                        vm.products1=thisDate(obj.curr);
                        vm.page = obj.curr;
                    })
                }
            });
        }

        $scope.selectProduct = function(data){
            vm.selectProductBtn = data;
            vm.page = 1;
            productInit()
        }

        //获取选中商品
        $scope.itemValueLinesCb = function (data) {
            data.selected = !data.selected ? true : false;
            if(!data.selected){
                for(var i=0; i<vm.item.length; i++){
                    if(vm.item[i].id == data.id){
                        vm.item.splice(i, 1);
                    }
                }
            }else {
                vm.item.push(data)
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
    }
})();
