(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('vip-level', {
            parent: 'entity',
            url: '/vip-level?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_MEMBERSHIPSYS'],
                pageTitle: 'global.menu.entities.vip-level'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vip-level/vip-level.html',
                    controller: 'VipLevelController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vip-level');
                    $translatePartialLoader.addPart('ExchangeType');
                    $translatePartialLoader.addPart('sendRule');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('vip-level.page', {
            parent: 'vip-level',
            url: '/{id}/{type}/page',
            data: {
                authorities: ['ROLE_STORE_MEMBERSHIPSYS']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vip-level/vip-level-page.html',
                    controller: 'VipLevelPageController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', function ($stateParams) {
                    return {id: $stateParams.id, type: $stateParams.type};
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vip-level');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('productType');
                    return $translate.refresh();
                }]
            }
        })
        .state('integral-acq-setting', {
            parent: 'entity',
            url: '/integral-acq-setting',
            data: {
                authorities: ['ROLE_STORE_MEMBERSHIPSYS'],
                pageTitle: 'global.menu.entities.integral-acq-setting'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vip-level/integral-acq-setting.html',
                    controller: 'IntegralAcqSettingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', function ($stateParams) {
                    return {id: $stateParams.id, type: $stateParams.type};
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vip-level');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('productType');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
