(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('SubstitutionTask', SubstitutionTask)
        .factory('Substitution', Substitution);

    Substitution.$inject = ['$resource', 'DateUtils'];
    SubstitutionTask.$inject = ['$resource', 'DateUtils'];

    function SubstitutionTask ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/substitution-tasks/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.assignDate = DateUtils.convertLocalDateFromServer(data.assignDate);
                        data.postBackDate = DateUtils.convertDateTimeFromServer(data.postBackDate);
                    }
                    return data;
                }
            },
             "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.assignDate = DateUtils.convertLocalDateToServer(copy.assignDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.assignDate = DateUtils.convertLocalDateToServer(copy.assignDate);
                    return angular.toJson(copy);
                }
            }
        });
    }

    function Substitution ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/substitutions/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.assignDate = DateUtils.convertLocalDateFromServer(data.assignDate);
                        data.postBackDate = DateUtils.convertDateTimeFromServer(data.postBackDate);
                    }
                    return data;
                }
            },
            "payCancel":{
                method: 'GET',
                params: {
                    path: 'payCancel'
                }
            },
            "rejectRefund":{
                method: 'GET',
                params: {
                    path: 'rejectRefund'
                }
            },
            "assignmentBatch":{
                method: 'GET',
                params: {
                    path: 'assignmentBatch'
                }
            },
            "assignment":{
                method: 'GET',
                params: {
                    path: 'assignment'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            "upload2":{
                method:"POST",
                params:{
                    path:"upload2"
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.assignDate = DateUtils.convertLocalDateToServer(copy.assignDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.assignDate = DateUtils.convertLocalDateToServer(copy.assignDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
