(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('groupOrder', {
            parent: 'entity',
            url: '/groupOrder?page&sort&search&status',
            data: {
                authorities: ["ROLE_STORE_MARKETINGMANAGEMENT"],
                pageTitle: 'global.menu.entities.group-order'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/group-order/group-order.html',
                    controller: 'GroupOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        status: $stateParams.status
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('groupOrderDetail', {
                parent: 'groupOrder',
                url: '/{id}/detail?isLeader',
                data: {
                    authorities: ["ROLE_STORE_MARKETINGMANAGEMENT"]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/group-order/group-order-detail.html',
                        controller: 'GroupOrderDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            entity: ['GroupBuyOrders', function(GroupBuyOrders) {
                                return GroupBuyOrders.get({
                                    id: $stateParams.id
                                }).$promise;
                            }],
                        }
                    }).result.then(function() {
                        $state.go('groupOrder', null, {
                            reload: 'groupOrder'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
        ;
    }

})();
