(function () {
    'use strict';

    var SelectMultipleControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/select-multiple.html',
        controllerAs: 'vm',
        bindings: {
            options: '<', //下拉选项数据
            model: '=', //选中的数据
            multiple: '<', //是否多选
            key: '<',
            value: '<',
        },

        controller: ['$scope','MessageService',
            function ($scope,MessageService) {
            var vm = this;
            $scope.checked = function (item) {
                if(vm.multiple){
                    if(vm.model && vm.model.length>0){
                        for(var j=0; j<vm.model.length; j++){
                            var _model = vm.model[j];
                            if(item[vm.key] == _model[vm.key]){
                                return true
                            }
                        }
                    }
                }else{
                    if(item[vm.key] == vm.model[vm.key]){
                        return true
                    }
                }
                return false
            }
            $scope.optionSave = function (item) {
                var _index = -1;
                if(vm.model && vm.model.length>0 && vm.multiple){
                    for(var j=0; j<vm.model.length; j++){
                        var _model = vm.model[j];
                        if(item[vm.key] == _model[vm.key]){
                            _index = j;
                            break
                        }
                    }
                }
                if(_index>-1){
                    if(vm.multiple){
                        vm.model.splice(_index, 1);
                    }else{
                        vm.model=null;
                    }
                }else{
                    if(vm.multiple){
                        vm.model.push(item);
                    }else{
                        vm.model = item;
                    }
                }
            }
        }]
    };

    angular.module('backApp')
    	.component('selectMultiple', SelectMultipleControl);

})();
