(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomersDetail', CustomersDetail);

        CustomersDetail.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MessageService'];

    function CustomersDetail ($timeout, $scope, $stateParams, $uibModalInstance, entity, MessageService) {
        var vm = this;
        vm.close = close;
        vm.data = entity;
        function close () {
            $uibModalInstance.dismiss(false);
        }

        $scope.getCustomerSource = function(customerSource){
            //  wechat,mini,web, aliMini,byteDance
            switch(customerSource) {
                case "wechat":
                    return "H5";
                case "mini":
                    return "微信小程序";
                case "web":
                    return "web端";
                case "aliMini":
                    return "支付宝小程序";
                case "byteDance":
                    return "字节小程序";
                default:
                    return "";
            }
        }

    }
})();
