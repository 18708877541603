(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('BranchStoreController', BranchStoreController);

    BranchStoreController.$inject = ['$state', 'BranchStore', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', '$scope', '$http','$uibModal','Principal'];

    function BranchStoreController($state, BranchStore, ParseLinks, paginationConstants, pagingParams, MessageService, $scope, $http,$uibModal,Principal) {

        var vm = this;

        Principal.hasAuthority('ROLE_STORE_BRANCH_SHOP').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

         //交接客户
        $scope.opUrl =function () {
            $uibModal.open({
                templateUrl: 'app/entities/branch-store/branch-store-url.html',
                controller: 'BranchStoreUrlController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                }
            }).result.then(function(id) {
                if(id){
                    init()
                }
            });
        }

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                BranchStore.del({id: id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true);
        };

        // 下载二维码
        $scope.download2code = function(bStore){
            $http({
                url: "/fileserves/api/download/downloadBranchStoreCode?storeId=" + bStore.storeId +"&branchStoreId=" + bStore.id,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/octet-stream;charset=ISO8859-1"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = bStore.name + "公众号二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
            // window.open("/fileserves/api/download/downloadBranchStoreCode?storeId=" + sid +"&branchStoreId=" + id);
        }

        $scope.downFile = function(){
            $http({
                url: 'manager/api/branch-store/downLocationTemplate',
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "分店模板" + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
            // window.open("manager/api/branch-store/downLocationTemplate");
        };

        //下载小程序二维码
        $scope.downloadQrCode = function (bStore) {
            $http({
                url: "/fileserves/api/download/downloadMiniQrCode?storeId=" + bStore.storeId +"&branchStoreId=" + bStore.id,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/octet-stream;charset=ISO8859-1"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = bStore.name + "小程序二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
            // window.open("/fileserves/api/download/downloadMiniQrCode?storeId=" + sid +"&branchStoreId=" + id);
        }

        //下载小程序二维码
        $scope.downloadByteDanceQrCode = function (bStore) {
            $http({
                url: "/fileserves/api/download/downloadByteDanceQrCode?storeId=" + bStore.storeId +"&branchStoreId=" + bStore.id,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/octet-stream;charset=ISO8859-1"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = bStore.name + "小程序二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
            // window.open("/fileserves/api/download/downloadMiniQrCode?storeId=" + sid +"&branchStoreId=" + id);
        }


        function loadAll () {
            if (pagingParams.search) {
                BranchStore.byCondition({
                    items: [{key:"name,phone,shopowner",op:"like",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                BranchStore.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.branchStores = data;
                console.log(data);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        //提现
        $scope.amount =function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/shoppingGuide/modal/deposit.html',
                controller: 'DepositController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            }).result.then(function(id) {
                if(id){
                    loadPage(1)
                }
            });
        }
    }
})();
