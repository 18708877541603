 (function() {
     'use strict';
     angular
         .module('backApp')
         .factory('BackupPassCategory', BackupPassCategory)
         .factory('PassSetting',PassSetting)

     BackupPassCategory.$inject = ['$resource'];
     PassSetting.$inject = ['$resource'];

     function BackupPassCategory ($resource) {
         var resourceUrl =  'manager/' + 'api/backup-pass-category/:path/:id';

         return $resource(resourceUrl, {}, {
             'byCondition': {
                 method: 'POST',
                 params: {
                     path: 'byCondition'
                 },
                 isArray: true
             },
             'changeStatue': {
                 method: 'GET',
                 params: {
                     path: 'changeStatue'
                 }
             },
             'get':{
                 method: 'GET'
             }
         });
     }

     function PassSetting ($resource){
         var resourceUrl =  'manager/' + 'api/pass-settings/:path/:id';

         return $resource(resourceUrl, {}, {
             'query': { method: 'GET', isArray: true},
             'get': {
                 method: 'GET',
                 params: {
                     path: "getByStore"
                 },
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'update': { method:'PUT' },
         });
     }
 })();
