(function() {
    'use strict';

    angular.module('backApp')
        .controller('AddressModalController', AddressModalController);

    AddressModalController.$inject = ['$uibModalInstance', 'MessageService', 'entity', 'LogisticsCompany', 'LocationProvice', 'LocationCity', 'LocationDistrict', '$q'];

    function AddressModalController ($uibModalInstance, MessageService, entity, LogisticsCompany, LocationProvice, LocationCity, LocationDistrict, $q) {
        var vm = this;
        vm.type = entity.type;
        if(vm.type == 'add'){
            vm.express = {
                name: '',
                phone: '',
                province: '',
                city : '',
                area : '',
                address : '',
                sent : '',
            };
        }
        if(vm.type == 'edit' || vm.type == 'check' ){
            vm.express = entity.data;
        }

        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        $q.all([vm.express.$promise]).then(function() {
            LocationProvice.query({}, function(data) {
                vm.provinces = data;
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.express.province) {
                        vm.province = p;
                        break;
                    }
                }
                if (!!vm.province) {
                    vm.districts = [];
                    LocationCity.findByProvince({
                        provinceId: vm.province.id
                    }, function(data) {
                        vm.citys = data;
                        for (var i = vm.citys.length - 1; i >= 0; i--) {
                            var c = vm.citys[i];
                            if (Trim(c.name, "g") == Trim(vm.express.city, "g")) {
                                vm.city = c;
                                break;
                            }
                        }
                        if (vm.city != null) {
                            LocationDistrict.findByCities({
                                cityId: vm.city.id
                            }, function(data) {
                                vm.districts = data;
                                for (var i = vm.districts.length - 1; i >= 0; i--) {
                                    var di = vm.districts[i];
                                    if (Trim(di.name, "g") == Trim(vm.express.area, "g")) {
                                        vm.area = di;
                                    }
                                }
                            });
                        }
                    });
                }
            });
        });
        function changeProvince() {
            LocationCity.findByProvince({
                provinceId: vm.province.id
            }, function(data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({
                cityId: vm.city.id
            }, function(data) {
                vm.districts = data;
            });
        }
        // 除去字符串中的空格
        function Trim (str, is_global) {
            var result;
            result = str.replace(/(^\s+)|(\s+$)/g,"");
            if (is_global.toLowerCase()=="g") {
                result = result.replace(/\s/g,"");
            }
            return result;
        }

        vm.cancel = cancel;
        vm.save = save;

        //取消
        function cancel() {
            $uibModalInstance.close(false);
        }
        //保存
        function save() {
            vm.express.province = vm.province.name;
            vm.express.city = vm.city.name;
            vm.express.area = vm.area.name;
            if(!vm.express.name){
                MessageService.error("寄件人不能为空");
                return
            }
            if(!vm.express.phone){
                MessageService.error("手机号码不能为空");
                return
            }
            if(!vm.express.province || !vm.express.city || !vm.express.area || !vm.express.address){
                MessageService.error("地址不能为空");
                return
            }
            $uibModalInstance.close(vm.express);
        }
    }
})();
