(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShoppingGuideDialogController', ShoppingGuideDialogController);

    ShoppingGuideDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'Guide', 'MessageService', '$uibModal','$state','Principal','BranchStore', 
    'Customer','$localStorage', 'CashWalletApplications', 'CustomerWallets', "MHistory"];

    function ShoppingGuideDialogController ($timeout, $scope, $stateParams, Guide, MessageService, $uibModal,$state,Principal,BranchStore, 
        Customer, $localStorage, CashWalletApplications , CustomerWallets, MHistory) {
        var vm = this;
        vm.save = save;
        vm.clear = clear;
        vm.shoeGuide= true;
        // 判断权限
        if($localStorage.user.authorities.indexOf("ROLE_STORE_BRANCH_SHOP") > -1){
            vm.isSubbranch = false;
            init();
        }else {
            if($localStorage.user.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP") > -1){
                vm.isSubbranch = true;
                init();
            }else {
                MessageService.error("不好意思，你不具有导购管理访问的权限！");
                $state.go("home-page");
            }
        }
        function init(){
            Guide.get({id: $stateParams.id},function (res) {
                vm.data = res;
                customersInit()
            })

            
            MHistory.getAllByCondition({
                items: [{key: "classId", op: "=", value: $stateParams.id},{key: "className", op: "=", value: "Guide"}]
            }, function (res) {
                vm.histoies = res;
            });
            if(!vm.isSubbranch){
                BranchStore.getAllByCondition({
                    items: [{key: "cancelled", op: "=", value: "false"},{key: "enabled", op: "=", value: "true"}]
                }, function (res) {
                    vm.shops = res;
                });
            }
        }
        function customersInit(){
            CustomerWallets.customer({customerId:vm.data.customerId}, function(data){
               vm.customerWallet = data; 
            })
            var myDate = new Date();
            var year = myDate.getFullYear();
            var month = myDate.getMonth()+1;
            if (month<10){ month = "0"+month; }
            var firstDay = year + "-" + month+"-"+"01";
            //累计获客数
            Customer.getInviteData({
                customerId:vm.data.customerId,
                startTime:""
            }, function (res) {
                if(res.status == 200){
                    vm.customers = res.message;
                }
            })
            //当月获客数
            Customer.getInviteData({
                customerId:vm.data.customerId,
                startTime:firstDay
            }, function (res) {
                if(res.status == 200){
                    vm.customerMonth = res.message;
                }
            })
            //当月提成金额
            CashWalletApplications.getAmount({
                customerId:vm.data.customerId,
                startTime:firstDay
            }, function (res) {
                if(res.status == 200){
                    vm.deduct = res.message;
                }
            })
        }

        $scope.updateAmount = function(){
            $uibModal.open({
                templateUrl: 'app/entities/shoppingGuide/modal/update-amount.html',
                controller: 'UpdateAmountController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.data;
                    }]
                }
            }).result.then(function(id) {
                if(id){
                    init()
                }
            });
            
        }


        //交接客户
        $scope.connect =function () {
            $uibModal.open({
                templateUrl: 'app/entities/shoppingGuide/modal/connect.html',
                controller: 'ConnectController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.data;
                    }]
                }
            }).result.then(function(id) {
                if(id){
                    init()
                }
            });
        }
        //确定
        function save() {
            console.log(vm.data)
            Guide.update(vm.data, function (res) {
                MessageService.success("保存成功");
                $state.go('shoppingGuide');
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }
        //取消
        function clear() {
            $state.go('shoppingGuide');
        }
    }
})();
