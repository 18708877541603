(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope','Account', '$translate', 'Principal', 'Store', '$state', '$timeout', 'Auth', '$rootScope', 'MessageService', '$localStorage', '$uibModal', '$q', 'PicManagements','AuthServerProvider','$http'];

    function HomeController($scope,Account, $translate, Principal, Store, $state, $timeout, Auth, $rootScope, MessageService, $localStorage, $uibModal, $q, PicManagements,AuthServerProvider,$http) {
        var vm = this;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.password = $localStorage.momentsgoBackPassword || null;
        vm.username = $localStorage.momentsgoBackUsername || null;
        vm.phoneNumber=$localStorage.momentsgoBackPhoneNumber || null;
        vm.password2=$localStorage.momentsgoBackPassword2 || null;
        vm.formInput = null;
        vm.formInput2 = null;
        vm.rememberPass = $localStorage.momentsgoBackRememberPass || false;
        $(".wrap").hide();

        $timeout(function () {
            angular.element('#username').focus();
        });
        vm.pic = PicManagements.getAllByCondition({ items: [{ key: "pageSite", op: "=", value: 'login' }], sort: ["seq,asc"] });
        $q.all([vm.pic.$promise]).then(function () {
            if (vm.pic && vm.pic.length > 0) {
                $timeout(function () {
                    new Swiper('.swiper-container', {
                        spaceBetween: 20,
                        autoplay: {
                            disableOnInteraction: false,
                        }
                    });
                }, 1000)
            }
        })
        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        $scope.formInputFocus = function (item) {
            vm.formInput = item;
        }
        $scope.formInputBlur = function () {
            vm.formInput = null;
        }
        $scope.formInputFocus2 = function (item) {
            vm.formInput2 = item;
        }
        $scope.formInputBlur2 = function () {
            vm.formInput2 = null;
        }

        // 点击登录事件
        function login(event) {
            if ($scope.tab) {
                vm.UserNameLogin(event)
            } else {
                vm.MobileLogin(event)
            }
        }
        // 用户名登录
        vm.UserNameLogin = function (event) {
            if (vm.username == null || vm.username == '' || vm.password == null || vm.password == '') {
                MessageService.error("请输入用户名");
                return;
            }
            if ( vm.password == null || vm.password == '') {
                MessageService.error("请输入密码");
                return;
            }
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                if (vm.rememberPass) {
                    $localStorage.momentsgoBackUsername = vm.username;
                    $localStorage.momentsgoBackPassword = vm.password;
                    $localStorage.momentsgoBackRememberPass = vm.rememberPass;
                } else {
                    delete $localStorage.momentsgoBackUsername;
                    delete $localStorage.momentsgoBackPassword;
                    delete $localStorage.momentsgoBackRememberPass;
                }
                goHome();
            }).catch(function (error) {
                if (error && error.data && error.data["com.deltazo.westeros.security.UserNotActivatedException"]) {
                    MessageService.error("用户未激活");
                } else {
                    MessageService.error("用户名或密码不正确");
                }
                vm.authenticationError = true;
            });
        }
        // 手机号登录
        vm.MobileLogin = function (event) {
            // console.log(vm.phoneNumber);
            if (vm.phoneNumber == null || vm.phoneNumber == '') {
                MessageService.error("请输入手机号");
                return;
            }
            if ( vm.password2 == null || vm.password2 == '') {
                MessageService.error("请输入密码");
                return;
            }
            event.preventDefault();
            $http.post('security/api/authenticate2', {
                phone: vm.phoneNumber,
                password: vm.password2,
                rememberMe: vm.rememberMe
            }).success(function(data, status, headers) {
                if (vm.rememberPass) {
                    $localStorage.momentsgoBackPhoneNumber = vm.phoneNumber;
                    $localStorage.momentsgoBackPassword2 = vm.password2;
                } else {
                    delete $localStorage.momentsgoBackPhoneNumber;
                    delete $localStorage.momentsgoBackPassword2;
                }
                var bearerToken = headers('Authorization');
                console.log(bearerToken);
                
                if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                    var jwt = bearerToken.slice(7, bearerToken.length);
                    AuthServerProvider.storeAuthenticationToken(jwt, vm.rememberMe);
                    Principal.identity(true).then(function(account) {
                        // After the login the language will be changed to
                        // the language selected by the user during his registration
                        if (account!== null) {
                            $translate.use(account.langKey).then(function () {
                                $translate.refresh();
                            });
                        }
                        goHome()
                    });
                }
            })
        }

        function goHome(){
            Store.getCurrent(function (res) {
                if (res.cancelled) {
                    MessageService.info("商户已经作废，如需继续使用，请联系平台");
                    return;
                }
                if (res.periodOfValidity && res.periodOfValidity.getTime() < new Date().getTime()) {
                    MessageService.info("商户已经到期，请尽早联系平台");
                    return;
                }
                console.log(res);
                if (res.initialized) {
                    $state.go('home-clear');
                    return;
                }
                $state.go('openAccount-home');
            });
            vm.authenticationError = false;
            $(".wrap").show().css({ margin: '48px 10px 10px 230px' });
            delete $localStorage.menu1;
            delete $localStorage.menu2;
            delete $localStorage.pageName;
            $rootScope.$broadcast('authenticationSuccess');
            // previousState was set in the authExpiredInterceptor before being redirected to login modal.
            // since login is succesful, go to stored previousState and clear previousState
            if (Auth.getPreviousState()) {
                var previousState = Auth.getPreviousState();
                Auth.resetPreviousState();
                $state.go(previousState.name, previousState.params);
            }
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }

        //使用条款
        $scope.clause = function (data) {
            $uibModal.open({
                templateUrl: 'app/home/clause-modal.html',
                controller: 'ClauseModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            })
        }

        $scope.tab = true

        // tab栏切换
        $scope.func = function (param) {//tab切换的事件
            if (param == 1) {
                $scope.tab = true;
            } else if (param == 2) {
                $scope.tab = false;
            }
        }
       
    }
})();
