(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('PassGoodsController', PassGoodsController);

    PassGoodsController.$inject = ['$scope', '$state'];

    function PassGoodsController ($scope, $state) {
        var vm = this;
        
}
})();
