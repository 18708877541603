(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('PassProduct', PassProduct);

    PassProduct.$inject = ['$resource'];

    function PassProduct ($resource) {
        var resourceUrl =  'manager/' + 'api/pass-products/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAvailableProducts': {
                method: 'GET',
                params: {
                    path: 'getAvailableProducts'
                },
                isArray: true
            },
            'batchCreate': {
                method: 'GET',
                params: {
                    path: 'batchCreate'
                },
            },
            'updateEnable': {
                method: 'GET',
                params: {
                    path: 'updateEnable'
                },
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'update': { method:'PUT' }
        });
    }
})();
