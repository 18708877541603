(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-wechat-setting', {
            parent: 'store',
            url: '/store-wechat-setting/{tabFlag}',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'backApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-detail.html',
                    controller: 'StoreWechatSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreWechatSetting', function($stateParams, StoreWechatSetting) {
                    return StoreWechatSetting.getPrivateWechatSetting().$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'store-wechat-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

       .state('store-wechat-setting-detail', {
            parent: 'entity',
            url: '/auth_notify?auth_code&expires_in',
            data: {
                authorities: [],
                pageTitle: 'backApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-detail.html',
                    controller: 'StoreWechatSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                    return WechatAuth.auth_notify({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in}).$promise;

                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'store-wechat-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

       .state('store-wechat-setting-detail2', {
            parent: 'entity',
            url: '/auth_notify2?auth_code&expires_in',
            data: {
                authorities: [],
                pageTitle: 'backApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-detail.html',
                    controller: 'StoreWechatSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                    return WechatAuth.auth_notify2({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in}).$promise;

                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'store-wechat-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('store-wechat-setting.edit', {
            parent: 'store-wechat-setting',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_WECHAT'],
                pageTitle: 'backApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-dialog.html',
                    controller: 'StoreWechatSettingDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreWechatSetting', function($stateParams, StoreWechatSetting) {
                    return StoreWechatSetting.getByStoreId().$promise;
                }]
            }
        })
        .state('store-small-app-setting-edit2', {
            parent: 'store-wechat-setting',
            url: '/{id}/edit2',
            data: {
                authorities: ['ROLE_STORE_WECHAT'],
                pageTitle: 'backApp.storeWechatSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-wechat-setting/store-small-app-setting-dialog.html',
                    controller: 'StoreSmallAppSettingDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeWechatSetting');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreSmallAppWechatSetting', function($stateParams, StoreSmallAppWechatSetting) {
                    return StoreSmallAppWechatSetting.getEntityByStoreId().$promise;
                }]
            }
        })
        .state('show-preview', {
                parent: 'storeWechatSetting',
                url: '/preview',
                data: {
                    authorities: ['ROLE_STORE_WECHAT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/store-wechat-setting/store-wechat-setting-page.html',
                        controller: 'StoreWechatSettingPageController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('storeWechatSetting');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            entity: ['StoreWechatSetting', function(StoreWechatSetting) {
                                return StoreWechatSetting.getMenu();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('storeWechatSetting', {}, { reload: false });
                    }, function() {
                        $state.go('storeWechatSetting');
                    });
                }]
            })
            .state('storeWechatSetting', {
                parent: 'store-wechat-setting',
                url: '/menuSetting',
                data: {
                    authorities: [],
                    pageTitle: 'backApp.storeWechatSetting.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-wechat-setting/store-wechatMenu-setting.html',
                        controller: 'StoreWechatMenuSettingDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('storeWechatSetting');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'StoreWechatSetting', function($stateParams, StoreWechatSetting) {
                        return StoreWechatSetting.getByStoreId().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'store-wechat-setting',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

        ;

    }

})();
