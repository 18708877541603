(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('VipSettings', VipSettings)
        .factory('MembershipPackages', MembershipPackages);

    VipSettings.$inject = ['$resource', 'DateUtils'];
    MembershipPackages.$inject = ['$resource', 'DateUtils'];

    function VipSettings ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/vip-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params:{
                    path:"storeId"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validate = DateUtils.convertLocalDateFromServer(data.validate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            },
            "update":{method:"PUT"}
        });
    }
    function MembershipPackages ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/membership-packages/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params:{
                    path:"storeId"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validate = DateUtils.convertLocalDateFromServer(data.validate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
        });
    }
})();
