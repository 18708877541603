
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FeedbackController', FeedbackController);

    FeedbackController.$inject = ['$scope', 'Principal','$state', 'MessageService', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Feedback'];

    function FeedbackController($scope, Principal,$state, MessageService, ParseLinks, paginationConstants, pagingParams, Feedback) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.jump = jump;
        vm.status = pagingParams.status;
        vm.time = pagingParams.time;
        vm.isAuthenticated = Principal.isAuthenticated;
        loadAll();
        function loadAll () {
            var items=[];
            if (vm.status !=null) {
                items.push({key:"status",op:"=",value:vm.status});
            };
            if (pagingParams.search) {
                items.push({key:"title",op:"like",value:pagingParams.search});
                Feedback.byCondition({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Feedback.byCondition({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.feedbacks = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("反馈获取失败");
            }
        };

        $scope.byStatus =function(status){
            vm.status = status;
            loadAll();
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                time:vm.time,
                status:vm.status
            });
        };

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;

            vm.status = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
    }
})();
