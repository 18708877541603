(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreWechatSettingDetailController', StoreWechatSettingDetailController);

    StoreWechatSettingDetailController.$inject = ['$scope', '$timeout', '$q' ,'$rootScope', '$stateParams', 'MessageService', 'entity', 'StoreWechatSetting', 'WechatAuth',
     'PlatformWechatSetting', 'Upload', 'UidService', 'Qinius', '$localStorage', 'Customer', 'Store', 'StoreSmallAppWechatSetting', '$state','Principal', '$http'];

    function StoreWechatSettingDetailController($scope, $timeout, $q, $rootScope, $stateParams, MessageService, entity, StoreWechatSetting,
        WechatAuth, PlatformWechatSetting, Upload, UidService, Qinius, $localStorage, Customer, Store, StoreSmallAppWechatSetting, $state,Principal, $http) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.clear = clear;
        vm.btnNum = 1;
        vm.smallAppStatus = null;
        vm.loading = true;
        vm.tabDiv = tabDiv;
        vm.tabFlag = $stateParams.tabFlag||1;
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();
        vm.pre_auth_code = WechatAuth.pre_auth_code();
        vm.platformWechatSetting = PlatformWechatSetting.get();
        vm.storeWechatSetting = entity;
        vm.storeSmallAppSetting = StoreSmallAppWechatSetting.getEntityByStoreId()
        vm.account = $localStorage.user;

        $q.all([vm.platformWechatSetting.$promise, vm.storeWechatSetting.$promise, vm.tokens.$promise,
            vm.pre_auth_code.$promise, vm.store.$promise, vm.storeSmallAppSetting.$promise]).then(function(){
                if (vm.tabFlag == 1 && vm.store.storeType2.indexOf('WECHAT')<0) {
                     vm.loading = false;
                    $state.go("store-small-app-message-template");
                    return;
                }
                if (vm.tabFlag == 2 && vm.store.storeType2.indexOf('MINI')<0) {
                     vm.loading = false;
                    $state.go("store-small-app-message-template");
                    return;
                }
                if (vm.store.smallAppStatus != null && vm.store.smallAppStatus == 'Release') {
                    //已发布
                    vm.smallAppStatus = "已发布";
                }

                if (vm.store.smallAppStatus != null && vm.store.smallAppStatus == 'Commit') {
                    //审核中
                    vm.smallAppStatus = "审核中";
                }
            vm.token = vm.tokens.token;
            if (vm.pre_auth_code.status == "200") {
                vm.preAuthCode =  vm.pre_auth_code.message;
            }
            $scope.notifyUrl = encodeURIComponent(vm.platformWechatSetting.backNotifyUrl + "/#/auth_notify");
            $scope.notifyUrl2 = encodeURIComponent(vm.platformWechatSetting.backNotifyUrl + "/#/auth_notify2");
            vm.loading = false;
        })

        $scope.officialAccountChange = function(){
            Store.update(vm.store, function(data){
                MessageService.success("更新成功");
            }, function(data){
                MessageService.error("更新失败");
            });
        }

        $scope.auth = function(){
            if (vm.store.wechatType == "Public") {
                Customer.byCondition({}, function(data){
                    if (data && data.length > 0) {
                        MessageService.confirm("商户当前已有客户，如果授权，客户将失去已保存的信息，确认继续授权？", function(){
                            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="
                            +vm.platformWechatSetting.appId
                +"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+ $scope.notifyUrl+"&auth_type=1";
                        })
                    }else{
                        window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="+vm.platformWechatSetting.appId
                            +"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+ $scope.notifyUrl+"&auth_type=1";
                    }
                },function(){
                    MessageService.error("查询商铺用户信息失败");
                })
            }else{
                window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="+vm.platformWechatSetting.appId
                +"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+ $scope.notifyUrl+"&auth_type=1";
            }
        }


        $scope.auth2 = function(){
            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="+vm.platformWechatSetting.appId
                +"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+ $scope.notifyUrl2+"&auth_type=2";
        }

        function tabDiv (num) {
            vm.tabFlag = num;
        }

        $scope.authAgain = function(){
            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="
            +vm.platformWechatSetting.appId+"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+$scope.notifyUrl+"&auth_type=1";
        }

        getUrl();
        function getUrl () {
           vm.url = window.location.host;
        }

        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'file-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        console.log(data);
                        vm.file = "http://" + vm.url + "/fileserves/mp-verify/" + vm.account.storeId + "/" + file.name;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.removeFile = function(file) {
            vm.file = null;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.downloadQrCode = function () {
            $http({
                url: 'fileserves/api/download/downloadQrCode?storeId=' + vm.store.id,
                method: "GET",
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/octet-stream;charset=ISO8859-1"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "商户微信公众号二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);

            }).error(function (data, status, headers, config) {
            });
        }

        $scope.downloadMiniQrCode = function(){
            $http({
                url: 'fileserves/api/download/downloadMiniQrCode?storeId=' + vm.store.id,
                method: "GET",
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/octet-stream;charset=ISO8859-1"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "商户微信小程序二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);

            }).error(function (data, status, headers, config) {
            });
        }

        var unsubscribe = $rootScope.$on('backApp:storeWechatSettingUpdate', function(event, result) {
            vm.storeWechatSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
