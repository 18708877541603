(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sales-order', {
                parent: 'entity',
                 url: '/sales-order?page&sort&search&status&startTime&endTime&sourceType&categoryId&deleted&productName&prototype'+
                 '&payRefundStatus&branchStoreId&isbranchStore&inviterId&number&phone&deliveryNumber&code&giftCode&composition'+
                 '&noComposition&transactionId&recipientsPhone&deliveryType&deliveryCode&customer',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE'],
                    pageTitle: 'backApp.salesOrder.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-orders.html',
                        controller: 'SalesOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status,
                            startTime: $stateParams.startTime,
                            endTime:$stateParams.endTime,
                            customer:$stateParams.customer,
                            sourceType:$stateParams.sourceType,
                            productName:$stateParams.productName,
                            categoryId:$stateParams.categoryId,
                            deleted:$stateParams.deleted,
                            composition:$stateParams.composition,
                            noComposition:$stateParams.noComposition,
                            prototype:$stateParams.prototype,
                            payRefundStatus:$stateParams.payRefundStatus,
                            branchStoreId:$stateParams.branchStoreId,
                            isbranchStore:$stateParams.isbranchStore,
                            inviterId:$stateParams.inviterId,
                            number:$stateParams.number,
                            phone:$stateParams.phone,
                            deliveryNumber:$stateParams.deliveryNumber,
                            code:$stateParams.code,
                            giftCode:$stateParams.giftCode,
                            transactionId:$stateParams.transactionId,
                            recipientsPhone:$stateParams.recipientsPhone,
                            deliveryType:$stateParams.deliveryType,
                            deliveryCode:$stateParams.deliveryCode
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-detail', {
                parent: 'sales-order',
                url: '/sales-order/{id}',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-detail.html',
                        controller: 'SalesOrderDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })
            .state('sales-order2', {
                parent: 'entity',
                url: '/sales-order2?page&sort&search',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE'],
                    pageTitle: 'backApp.salesOrder.home.title2'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-orders2.html',
                        controller: 'SalesOrderController2',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-detail2', {
                parent: 'sales-order2',
                url: '/sales-order2/{id}',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-detail.html',
                        controller: 'SalesOrderDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order2', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order2');
                    });
                }]
            })
            .state('sales-order-express-history', {
                parent: 'sales-order-detail',
                url: '/history/{id}',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-delivery-history.html',
                        controller: 'SalesOrderDeliveryHistoryController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['ExpressHistory', function(ExpressHistory) {
                                return ExpressHistory.byOrderId({orderId:$stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })
            .state('sales-order3', {
                parent: 'entity',
                url: '/sales-order3?page&sort&search',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE'],
                    pageTitle: 'backApp.salesOrder.home.title3'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-orders3.html',
                        controller: 'SalesOrderController3',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-detail3', {
                parent: 'sales-order3',
                url: '/sales-order3/{id}',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-detail.html',
                        controller: 'SalesOrderDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order3', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order3');
                    });
                }]
            })
            .state('sales-order4', {
                parent: 'entity',
                url: '/sales-order4?page&sort&search',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE'],
                    pageTitle: 'backApp.salesOrder.home.title3'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-orders4.html',
                        controller: 'SalesOrderController4',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-detail4', {
                parent: 'sales-order4',
                url: '/sales-order4/{id}',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-detail.html',
                        controller: 'SalesOrderDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order4', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order4');
                    });
                }]
            })
            .state('sales-order.new', {
                parent: 'sales-order',
                url: '/new',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-dialog.html',
                        controller: 'SalesOrderDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    amount: null,
                                    freight: null,
                                    discountAmount: null,
                                    payAmount: null,
                                    number: null,
                                    cancelled: null,
                                    status: null,
                                    remarks: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('sales-order', null, {
                            reload: 'sales-order'
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })
            .state('sales-order.edit', {
                parent: 'sales-order',
                url: '/sales-order/{id}/edit/{status}',
                data: {
                    authorities: ['ROLE_ORDER_EDIT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-order-dialog.html',
                        controller: 'SalesOrderDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['SalesOrder', '$stateParams',
                        function(SalesOrder, $stateParams) {
                            return SalesOrder.get({id : $stateParams.id}).$promise;
                        }
                    ]
                }
            })

            .state('sales-order2.edit2', {
                parent: 'sales-order2',
                url: '/sales-order2/{id}/edit2',
                data: {
                    authorities: ['ROLE_ORDER_EDIT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-dialog.html',
                        controller: 'SalesOrderDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order2', null, {
                            reload: 'sales-order2'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('sales-order3.edit3', {
                parent: 'sales-order3',
                url: '/sales-order3/{id}/edit3',
                data: {
                    authorities: ['ROLE_ORDER_EDIT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-dialog.html',
                        controller: 'SalesOrderDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order3', null, {
                            reload: 'sales-order3'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('sales-order-delivery', {
                parent: 'sales-order',
                url: '/{id}/delivery',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-delivery-dialog.html',
                        controller: 'SalesOrderDeliveryController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', null, {
                            reload: 'sales-order'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('sales-order-delivery3', {
                parent: 'sales-order3',
                url: '/{id}/delivery3',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-delivery-dialog.html',
                        controller: 'SalesOrderDeliveryController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order3', null, {
                            reload: 'sales-order3'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('pic-view-article', {
                parent: 'sales-order',
                url: '/picView/{fileName}',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-pic-view.html',
                        controller: 'SalesOrderPicViewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProductXmlFileServes', '$stateParams', function(ProductXmlFileServes, $stateParams) {
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', null, {
                            reload: 'sales-order'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('sales-order-data-export', {
                parent: 'sales-order',
                url: '/sales-order-data-export',
                data: {
                    authorities: ['ROLE_STORE_ORDERMANAGE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-data-export.html',
                        controller: 'SalesOrderExportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }] ,
                            entity: ['SalesOrder', function(SalesOrder) {
                                return {};
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            });
    }

})();
