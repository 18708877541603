(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('WechatUserAnalysis', WechatUserAnalysis)
        .factory('Da', Da);

    Da.$inject = ['$resource', 'DateUtils'];

    WechatUserAnalysis.$inject = ['$resource', 'DateUtils'];

    function WechatUserAnalysis($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/store-wechat-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'getTodayData': {
                method: 'GET',
                params: {
                    path: 'getTodayData'
                }
            },
            'getUserSummaryDate': {
                method: 'GET',
                params: {
                    path: 'getUserSummaryDate'
                }
            }
        })
    }

    function Da($resource, DateUtils) {
        var resourceUrl = 'service/' + 'api/sales-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'getData4Chart': {
                method: 'GET',
                params: {
                    path: 'getData4Chart'
                }
            }
        });
    }
})();