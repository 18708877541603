(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('MemberSettingController', MemberSettingController);

    MemberSettingController.$inject = ['$scope', '$state', '$rootScope', 'entity', 'PassMemberSetting', 'MessageService'];

    function MemberSettingController ($scope, $state, $rootScope, entity, PassMemberSetting, MessageService) {
        var vm = this;
        vm.save = save;
        vm.clear = clear;
        vm.memberSetting = entity;
        vm.view = true;
        if ($rootScope && $rootScope.toState && $rootScope.toState.name == 'member-setting-edit') {
            vm.view = false;
        }
        console.log(vm);

        function save () {
            vm.isSaving = true;
            PassMemberSetting.update(vm.memberSetting, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:memberSettingUpdate', result);
            $state.go("memberSetting");
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }


        function clear () {
             $state.go("memberSetting");
        }

        $scope.backTo = function(){
            sessionStorage.setItem("lmenu", 'a_marketing');
            $state.go("marketing");
            vm.lmenu = "a_marketing";
        }
    }


})();
