(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CouponController', CouponController);

    CouponController.$inject = ['$state', 'Coupon','$scope', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', '$q', 'StoreConfig','UidService', 'Principal'];

    function CouponController($state, Coupon, $scope, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, $q, StoreConfig, UidService, Principal) {

        var vm = this;
        Principal.hasAuthority('ROLE_STORE_OUPON').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.open = false;
        vm.type = '';
        vm.img = '';
        vm.couponOpen = {
            type:'COUPON',
            open: false
        };
        vm.couponEntry = {
            type:'COUPONENTRY',
            value: ''
        };
        vm.couponImg = {
            type:'COUPONENTRYIMG',
            value: ''
        };

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        });
        loadAll();

        $scope.delete = function(coup){
            MessageService.confirm("确认要删除该优惠券?", function(){
                Coupon.delete({id:coup.id}, function(data){
                    MessageService.success("删除成功");
                    loadAll();
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){})
        };

        $scope.isExpired = function(coup){
            if (coup == null) {
                return false;
            };
            // 修正过期时间比较
            var endTime = new Date(coup.endTime+" 23:59:59").getTime();
            var nowTime = new Date().getTime();
            if (nowTime > endTime) {
                return true;
            }
            return false;
        }

        $scope.updateEnable = function(coup){
            if (!coup.sendRule) {
                MessageService.info("请先设置分发规则");
                return;
            };
            MessageService.confirm("确认要修改该优惠券上架状态?", function(){
                Coupon.changeStatus({id:coup.id}, function(data){
                    MessageService.success("修改成功");
                    loadAll();
                },function(){
                    MessageService.error("修改失败");
                })
            }, function(){})
        };

        function getStoreConfig(){
            StoreConfig.getAllByCondition({
            }, onSuccess, onError)
            function onSuccess(datas) {
                angular.forEach(datas, function (data, i) {
                    if(data.type == "COUPON"){
                        vm.couponOpen.open = data.open
                    }
                    if(data.type == "COUPONENTRY"){
                        vm.couponEntry.value = data.value
                    }
                    if(data.type == "COUPONENTRYIMG"){
                        vm.couponImg.value = data.value
                    }
                });
                console.log(vm.open + " type:" + vm.type + " img:" + vm.img);
            }
            function onError() {
                MessageService.error("优惠券设置获取失败");
            }
        }

        function loadAll () {
            getStoreConfig()
            if (pagingParams.search) {
                Coupon.byCondition({
                    items: [{key:"name",op:"like",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Coupon.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(datas, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.coupons = [];
                angular.forEach(datas, function (data, i) {
                    var endTime = new Date(data.endTime + " 23:59:59").getTime();
                    var nowTime = new Date().getTime();
                    vm.coupons.push(data);
                    if (endTime < nowTime) {
                        vm.coupons[i].cancelled = true;
                    }
                });
                console.log(vm.coupons);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("优惠券获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        $scope.typeUpdate = function(int){
            vm.couponEntry.value = int;
            updateConfig();
        }

        //启用
        $scope.startUsing = function () {
            updateConfig();
        };

        function updateConfig() {
            StoreConfig.update(vm.couponOpen);
            StoreConfig.update(vm.couponEntry);
            StoreConfig.update(vm.couponImg);
        }

        window.upload = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }
        $scope.removeFile = function(){
            vm.couponImg.value = '';
            updateConfig();
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'code-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.couponImg.value = data.key;
                    updateConfig();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
    }
})();
