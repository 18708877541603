(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MapClearClauseModalController', MapClearClauseModalController);

        MapClearClauseModalController.$inject = ['$uibModalInstance', 'PlatformWechatSetting', '$q', '$sce'];

    function MapClearClauseModalController ($uibModalInstance, PlatformWechatSetting, $q, $sce) {
        var vm = this;
        vm.cancel = cancel;
        vm.storageClearTerms = "";
        vm.platformSetting = PlatformWechatSetting.get();
        $q.all([vm.platformSetting.$promise]).then(function(){
            vm.storageClearTerms = $sce.trustAsHtml(vm.platformSetting.storageClearTerms);
        })
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
