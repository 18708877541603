(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShopController', ShopController);

    ShopController.$inject = ['$scope', '$state', 'Store', 'MessageService','$q', '$uibModal'];

    function ShopController ($scope, $state, Store, MessageService,$q, $uibModal) {
        var vm = this;
        vm.store = Store.getCurrent();
        vm.selectStyle ='';

        $q.all([vm.store.$promise]).then(function(){
            if(vm.store.skin == '' || vm.store.skin == null || vm.store.skin == undefined){
                vm.selectStyle = 'one';
            }else{
                vm.selectStyle =vm.store.skin;
            }
            console.log(vm.selectStyle)
        });

        $scope.themeSelectd = function(str){
            MessageService.confirm("确定要启用吗？",function(){
                Store.updateSkin({
                    id:vm.store.id,
                    skin:str
                }, function () {
                    vm.selectStyle = str;
                    MessageService.success('启用成功！');
                }, function () {
                    MessageService.success('启用失败！')
                });
            },function(){},true);
        };

        $scope.preview =function(data){
            localStorage.setItem('flag',data);
            $state.go('shop-detail')
        };

        //上一步
        $scope.back = function () {
            $state.go('guide-commodity')
        }
        
        $scope.save = function () {
            $uibModal.open({
                templateUrl: 'app/entities/guide/shop/shop-modal.html',
                controller: 'ShopModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {}
            });
        }

    }
})();
