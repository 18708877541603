(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SupportRejectOrderController',SupportRejectOrderController);

    SupportRejectOrderController.$inject = ['$uibModalInstance', '$scope', 'entity', 'SalesOrder', 'MessageService','LogisticsCompany', 'DateUtils'];

    function SupportRejectOrderController($uibModalInstance, $scope, entity, SalesOrder, MessageService, LogisticsCompany, DateUtils) {
        var vm = this;
        vm.rejectReason = "";
        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
     
        function save () {
            $uibModalInstance.close(vm.rejectReason);
        }
    }
})();
