(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('storeDeliveryDialogController', storeDeliveryDialogController);

    storeDeliveryDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'entity', 'Region', 'Province', 'MessageService', '$uibModal', 'LocationProvice'];

    function storeDeliveryDialogController($timeout, $scope, $q, $state, entity, Region, Province, MessageService, $uibModal, LocationProvice) {
        var vm = this;
        vm.region = entity;
        vm.clear = clear;
        vm.save = save;
        $q.all([vm.region.$promise]).then(function () {
            vm.provices = LocationProvice.other4Store({freightType: 'STORE', regionId:vm.region.id});
            deliveryAreaInfo();
        });
        $scope.address = function () {
            $uibModal.open({
                templateUrl: 'app/entities/store-delivery/address-modal.html',
                controller: 'AddressModal1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return {
                            deliveryAreas: vm.region.deliveryAreas,
                            provices: vm.provices
                        }
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    vm.region.deliveryAreas = backData;
                    deliveryAreaInfo()
                }
            });
        }
        function deliveryAreaInfo(){
            vm.deliveryAreas=[];
            vm.region.deliveryAreas.forEach(function (item) {
                var isProvince = false;
                for(var i=0; i<vm.deliveryAreas.length; i++){
                    if(vm.deliveryAreas[i].id == item.provinceId){
                        isProvince = true;
                        break
                    }
                }
                if(!isProvince){
                    vm.deliveryAreas.push({
                        id: item.provinceId,
                        name: item.provinceName,
                        citys:[]
                    })
                }
            })
            vm.region.deliveryAreas.forEach(function (item) {
                vm.deliveryAreas.forEach(function (deliveryArea) {
                    if(deliveryArea.id == item.provinceId){
                        var isCity = false;
                        for(var i=0; i<deliveryArea.citys.length; i++){
                            if(deliveryArea.citys[i].id == item.cityId){
                                isCity = true;
                                break
                            }
                        }
                        if(!isCity){
                            deliveryArea.citys.push({
                                id: item.cityId,
                                name: item.cityName,
                                districts:[]
                            })
                        }
                    }
                })
            })
            vm.region.deliveryAreas.forEach(function (item) {
                vm.deliveryAreas.forEach(function (deliveryArea) {
                    if(deliveryArea.id == item.provinceId){
                        deliveryArea.citys.forEach(function (city) {
                            if(city.id == item.cityId){
                                var isDistrict = false;
                                for(var i=0; i<city.districts.length; i++){
                                    if(city.districts[i].id == item.districtId){
                                        isDistrict = true;
                                        break
                                    }
                                }
                                if(!isDistrict){
                                    city.districts.push({
                                        id: item.districtId,
                                        name: item.districtName
                                    })
                                }
                            }
                        })
                    }
                })
            })
            console.log(vm.deliveryAreas)
        }
        function save() {
            if (vm.region.name == null || vm.region.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            if (vm.region.freight == null || vm.region.freight == ""&&vm.region.freight!=0) {
                MessageService.error("请输入运费");
                return false;
            }
            if (vm.region.deliveryAreas == null || vm.region.deliveryAreas.length == 0) {
                MessageService.error("请选择区域");
                return false;
            }
            vm.isSaving = true;
            if (vm.region.id !== null && vm.region.id != 0) {
                Region.update(vm.region, onSaveSuccess, onSaveError);
            } else {
                Region.save(vm.region, onSaveSuccess, onSaveError);
            }
        }
        function onSaveSuccess() {
            $state.go("storeDelivery");
            MessageService.success("信息更新成功");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
        function clear() {
            $state.go("storeDelivery");
        }
    }
})();
