(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('NavigationSettingDetailController', NavigationSettingDetailController);

    NavigationSettingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'NavigationSetting', 'StoreStyleSetting'];

    function NavigationSettingDetailController($scope, $rootScope, $stateParams, previousState, entity, NavigationSetting, StoreStyleSetting) {
        var vm = this;

        vm.navigationSetting = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('backApp:navigationSettingUpdate', function(event, result) {
            vm.navigationSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
