(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OperationManualPreviewController', OperationManualPreviewController);
    OperationManualPreviewController.$inject = ['$timeout', "msgdata", '$uibModalInstance', 'StoreOperationManuals', '$sce','Store'];

    function OperationManualPreviewController($timeout, msgdata, $uibModalInstance, StoreOperationManuals, $sce,Store) {
        var vm = this;
        vm.cancel = cancel;
        vm.store = Store.getCurrent();
        StoreOperationManuals.get({ id: msgdata.id }, function (res) {
            vm.data = res
            $("#preview").html(vm.data.content);
        });
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
