(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassProductController', PassProductController);

    PassProductController.$inject = ['$scope', '$state', 'PassProduct', 'Qinius', '$uibModal', 'MessageService', 'pagingParams', 
    'ParseLinks', 'paginationConstants'];

    function PassProductController ($scope, $state, PassProduct, Qinius, $uibModal, MessageService, pagingParams, 
        ParseLinks, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.passProducts = [];
        Qinius.getDomain({}, function(data){
            $scope.domain = 'http://' + data.domains[0] + '/';
        });

        $scope.updateEnable = function(p){
            if (p == null) {
                return;
            }
            PassProduct.updateEnable(p, function(data){
                if (data && data.status == 400) {
                    MessageService.error("设置失败");
                }else{
                    loadAll();
                    MessageService.success("设置成功");
                }
            }, function(error){
                MessageService.error("设置失败");
            })
        }

        $scope.delete = function(id){
            if (id == null) {
                return;
            }
            PassProduct.delete({id:id}, function(val){
                if (val && val.status == 400) {
                    MessageService.error("设置失败");
                }else{
                    loadAll();
                    MessageService.success("设置成功");
                }
            }, function(error){
                MessageService.error("设置失败");
            })
        }


        loadAll();
        $scope.edit = function (item) {
            $uibModal.open({
                templateUrl: "app/entities/pass-product/pass-product-dialog.html",
                controller: 'PassProductDialogController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:item,
                }
            }).result.then(function(data) {
                if(data){
                    if (item.productPrice<data.maxDiscount) {
                        MessageService.confirm("抵扣金额上限大于商品最小金额，是否将商品最小金额设为抵扣金额上限？", function(){
                            data.maxDiscount = item.productPrice;
                            PassProduct.update(data, function(val){
                                if (val && val.status == 400) {
                                    MessageService.error("新增失败");
                                }else{
                                    loadAll();
                                    MessageService.success("新增成功");
                                }
                            }, function(error){
                                MessageService.error("设置失败");
                            })

                        }, function(){
                            loadAll();
                        })
                    }else{
                        PassProduct.update(data, function(val){
                            if (val && val.status == 400) {
                                MessageService.error("新增失败");
                            }else{
                                loadAll();
                                MessageService.success("新增成功");
                            }
                        }, function(error){
                            MessageService.error("设置失败");
                        })
                    }
                }
            });;
        }


        function loadAll() {
            if (pagingParams.search) {
                PassProduct.byCondition({
                    items: [{key: "product.name", op: "like", value: pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                PassProduct.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.passProducts = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("产品获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }


    }
})();
