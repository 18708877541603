(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassDialogController', PassDialogController);

    PassDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'PassProduct', '$q', '$uibModalInstance',
    'PassOrder', 'Qinius', 'MessageService', 'entity'];

    function PassDialogController ($timeout, $scope, $stateParams, PassProduct, $q, $uibModalInstance,
     PassOrder, Qinius, MessageService, entity) {
        var vm = this;
        vm.data = entity; 
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $q.all([vm.data.$promise]).then(function () {
            vm.orders = PassOrder.getAllByCondition({items:[{key:"pass.id", op:'=', value:vm.data.id}]});
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        

    }
})();
