(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('miraclePass', {
            parent: 'marketing',
            url: '/miraclePass',
            data: {
                pageTitle: 'backApp.passwordActivity.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/miracle-pass/miracle-pass.html',
                    controller: 'MiraclePassController',
                    controllerAs: 'vm'
                }
            },
        }).state('passList', {
            parent: 'miraclePass',
            url: '/passList',
            data: {
                pageTitle: 'backApp.passwordActivity.home.title'
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-list.html',
                    controller: 'PassListController',
                    controllerAs: 'vm'
                }
            },
        }).state('passOrder', {
            parent: 'miraclePass',
            url: '/passOrder',
            data: {
                pageTitle: 'backApp.passwordActivity.home.title'
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-order.html',
                    controller: 'PassOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        }).state('cashRecord', {
            parent: 'miraclePass',
            url: '/cashRecord',
            data: {
                pageTitle: 'backApp.passwordActivity.home.title'
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/cash-record.html',
                    controller: 'CashRecordController',
                    controllerAs: 'vm'
                }
            },
        }).state('passGoods', {
            parent: 'miraclePass',
            url: '/passGoods',
            data: {
                pageTitle: 'backApp.passwordActivity.home.title'
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-goods.html',
                    controller: 'PassGoodsController',
                    controllerAs: 'vm'
                }
            },
        }).state('passBank', {
            parent: 'miraclePass',
            url: '/passBank',
            data: {
                pageTitle: 'backApp.passwordActivity.home.title'
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-bank.html',
                    controller: 'PassBankController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        }).state('backupPassCategoryView', {
            parent: 'miraclePass',
            url: '/passBankDetail/{id}',
            data: {
                pageTitle: ''
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-bank-detail.html',
                    controller: 'PassBankDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['BackupPassCategory','$stateParams', function(BackupPassCategory,$stateParams) {
                    return BackupPassCategory.get({id : $stateParams.id}).$promise;
                }]
            }
        }).state('backupPassCategoryEdit', {
            parent: 'miraclePass',
            url: '/passBankEdit/{id}',
            data: {
                pageTitle: ''
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-bank-dialog.html',
                    controller: 'PassBankDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['BackupPassCategory','$stateParams', function(BackupPassCategory,$stateParams) {
                    return BackupPassCategory.get({id : $stateParams.id}).$promise;
                }]
            }
        }).state('backupPassCategoryNew', {
            parent: 'miraclePass',
            url: '/passBankCreate',
            data: {
                pageTitle: ''
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-bank-dialog.html',
                    controller: 'PassBankDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        cancelled: null,
                        id: null,
                        enable: null
                    };
                }
            }
        }).state('pass-setting',{
            parent: 'miraclePass',
            url: '/passSetting',
            data: {
                pageTitle: ''
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/miracle-pass/pass-setting.html',
                    controller: 'PassSettingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['PassSetting', function(PassSetting) {
                    return PassSetting.get().$promise;
                }
                ]
            }
        })


    }

})();
