(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ItemValueLinePackage', ItemValueLinePackage)
        .factory('OrderLines', OrderLines)
        .factory('ItemValueLinePackageView', ItemValueLinePackageView)
        .factory('ItemValueLinePackageViewB', ItemValueLinePackageViewB);

    ItemValueLinePackage.$inject = ['$resource'];
    OrderLines.$inject = ['$resource'];
    ItemValueLinePackageView.$inject = ['$resource'];
    ItemValueLinePackageViewB.$inject = ['$resource'];

    function OrderLines ($resource) {
        var resourceUrl =  'service/' + 'api/order-lines/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getBackStockConsume':{
                method: "POST",
                params: {
                    path: "getBackStockConsume"
                },
                isArray: true
            }
        });
    }
    function ItemValueLinePackage ($resource) {
        var resourceUrl =  'manager/' + 'api/item-value-line-packages/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getPackage':{
                method: "GET",
                params: {
                    path: "getPackageByPurchaseIdAndItemValueLineId"
                }
            }
        });
    }
    function ItemValueLinePackageView ($resource) {
        var resourceUrl =  'manager/' + 'api/item-value-line-package-views/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function ItemValueLinePackageViewB ($resource) {
        var resourceUrl =  'manager/' + 'api/item-value-line-package-viewbs/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
