(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomerPurchaseRecordController', CustomerPurchaseRecordController);

    CustomerPurchaseRecordController.$inject = ['MessageService', 'CustomerStoragePayOrders', 'ParseLinks', 'paginationConstants'];

    function CustomerPurchaseRecordController(MessageService, CustomerStoragePayOrders, ParseLinks, paginationConstants) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.jump = jump;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 1;
        init();
        function init() {
            if (vm.searchQuery) {
                CustomerStoragePayOrders.byCondition1({
                    items: [{ key: "number", op: "like", value: vm.searchQuery }],
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                }, function (res, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.prices = res.message;
                    currentDateLater(vm.prices)
                }, function (error) {
                    MessageService.error("数据加载出错");
                });
            } else {
                CustomerStoragePayOrders.byCondition1({
                    items: [{ key: "paid", op: "=", value: true }],
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                }, function (res, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.prices = res.message;
                    currentDateLater(vm.prices)
                }, function (error) {
                    MessageService.error("数据加载出错");
                });
            }
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            init()
        }
        function transition() {
            init()
        }
        vm.search = function () {
            init();
        }
        // 计算到期日期
        function currentDateLater(arr) {
            arr.forEach(function(item) {
                if (item.paidDate) {
                    var date = new Date(item.paidDate);
                    var num = parseInt(item.periodOfValidity / 12);
                    var yu = item.periodOfValidity % 12;
                    var y = date.getFullYear() + num;
                    var m = date.getMonth() + yu;
                    if (m >= 12) {
                        m = m % 12;
                        y = y + 1;
                    }
                    var d = date.getDate();
                    var h = date.getHours();
                    var min = date.getMinutes();
                    var s = date.getSeconds();
                    var newDate = new Date(y, m, d, h, min, s);
                    item.dateExpiry = newDate.getFullYear() + '年' + (newDate.getMonth() + 1) + '月' + newDate.getDate() + '日';
                }
            });
        }
    }
})();
