(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('IntegralAddController', IntegralAddController);

    IntegralAddController.$inject = ['$timeout', '$scope','UidService', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService', 'MembershipPackages','$uibModal', 'Qinius', '$q', 'Coupon', 'Redeems'];

    function IntegralAddController ($timeout, $scope,UidService, $stateParams, $uibModalInstance, entity, $state, MessageService, MembershipPackages, $uibModal, Qinius, $q, Coupon, Redeems) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.redeemType = [
            {id: 'COUPON', name: '优惠卷'}
        ];
        vm.coupon = [];
        if(entity.type == 'add'){
            vm.data = {
                seq: '',
                name: '',
                pic: '',
                cost: '',
                totalQuantity: '',
                residueQuantity: '',
                type: '',
                enable: '',
                coupons:[]
            }
        }
        if (entity.type == 'edit'){
            Redeems.get({id:entity.id}, function (res) {
                vm.data = res;
            })
        }
        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            init()
        });

        function init(){
            Coupon.getAllByCondition({
                items: [
                    {key: "cancelled", op: "=", value: "false"},
                    {key: "enabled", op: "=", value: "true"},
                    {key: "sendRule", op: "=", value: "Vip"},
                ]
            },function (res) {
                angular.forEach(res, function (item) {
                    for(var i=0; i<vm.data.coupons.length; i++){
                        if(item.id == vm.data.coupons[i].id){
                            item.selected = true;
                        }
                    }
                    vm.coupon.push(item)
                })
            })
        }
        $scope.chooseOne = function(item){
            item.selected = !item.selected;
            couponInit()
        };
        function couponInit(){
            vm.data.coupons = [];
            for(var i=0; i<vm.coupon.length; i++){
                if(vm.coupon[i].selected){
                    vm.data.coupons.push(vm.coupon[i])
                }
            }
        }

        window.upload = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }
        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'integral-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.pic = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        function imgSrop(url, width, height){
            $uibModal.open({
                templateUrl: 'app/entities/initialization/modal/img-crop.html',
                controller: 'ImgCropController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            url: url,
                            width: width,
                            height: height
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    vm.data.pic = data;
                }
            });
        }
        $scope.removeFile = function(){
            vm.data.pic = "";
        }


        function save() {
            if(!vm.data.seq){
                MessageService.error("请输入排序");
                return
            }
            if(!vm.data.name){
                MessageService.error("请输入兑换品名称");
                return
            }
            if(!vm.data.pic){
                MessageService.error("请输入展示图片");
                return
            }
            if(!vm.data.cost){
                MessageService.error("请输入所需积分");
                return
            }
            if(!vm.data.totalQuantity){
                MessageService.error("请输入可兑换数量");
                return
            }
            if(!vm.data.type){
                MessageService.error("请输入兑换类型");
                return
            }
            if(vm.data.coupons.length == 0){
                MessageService.error("关联优惠卷不能为空");
                return
            }

            if(entity.type == 'add'){
                Redeems.save(vm.data, function (res) {
                    $uibModalInstance.close(true);
                })
            }
            if(entity.type == 'edit'){
                Redeems.update(vm.data, function (res) {
                    $uibModalInstance.close(true);
                })
            }
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
        function checkFloatNum(str){
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
    }
})();
