(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('HomePageStyleEditController', HomePageStyleEditController);

    HomePageStyleEditController.$inject = ['$scope', '$state', '$q', '$timeout', '$stateParams', 'MessageService', 'Qinius', 'Product', 'UidService', 'IndexStyleSettings', 'Store', 'CategoryStore', 'DateUtils', 'StoreGroupBuy'];

    function HomePageStyleEditController($scope, $state, $q, $timeout, $stateParams, MessageService, Qinius, Product, UidService, IndexStyleSettings, Store, CategoryStore, DateUtils, StoreGroupBuy) {
        var vm = this;
        if ($stateParams.id) {
            IndexStyleSettings.get({ id: $stateParams.id }, function (res) {
                if (res.status == 200) {
                    vm.widgets = res.message.widgets
                    vm.data = res.message
                    vm.data.widgets = []
                    console.log('这是接收的数据', vm.widgets);
                    vm.widgets.forEach(function (item) {
                        if (item.type == "banner") {
                            item.banners.forEach(function (it) {
                                if (it.jumpType == 'PRODUCT') {
                                    it.url = parseInt(it.url)
                                }
                            })
                        }
                        if (item.type == "productXml") {
                            getProducts(item)
                        }

                    })
                    RefreshBanner()
                } else {
                    MessageService.error('获取数据失败')
                }

            })
        }
        vm.data = {}
        // 上传图片------------------------------------------
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        vm.products = []
        vm.hotCategories = []
        vm.storeGroupBuy = [];
        $q.all([vm.tokens.$promise, vm.domains.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            console.log(vm.hotCategorie);
            // 商品名称价格
            Product.getProductsClient({ storeId: vm.store.id }, function (resp) {
                vm.widgets.forEach(function (widget) {
                    if (widget.type == 'product') {
                        widget.entities.forEach(function (ent) {
                            resp.forEach(function (item) {
                                if (item.id == ent.id) {
                                    ent.name = item.name
                                    ent.description = item.description
                                    ent.price = item.price
                                    ent.markingOutPrice = item.markingOutPrice
                                }
                            })
                        })
                    }
                })
            })
            // 热门分类
            CategoryStore.getAllByCondition({
                items: [
                    { key: "usable", op: "=", value: "true" },
                    { key: "haveSuperior", op: "=", value: "false" },
                    { key: "hot", op: "=", value: true },
                    { key: "storeId", op: "=", value: vm.store.id }
                ]
            }, function (res) {
                vm.hotCategories = res
            })
            // 拼团
            StoreGroupBuy.byCondition({
                items: [{ key: "enable", op: "=", value: true },
                { key: "storeId", op: "=", value: vm.store.id },
                { key: "periodOfValidity", op: ">=", value: DateUtils.convertLocalDateToServer(new Date()) }],
                page: 0,
                size: 20,
                sort: ["seq,asc"]
            }, function (res) {
                console.log(res);
                angular.forEach(res, function (item) {
                    if (vm.storeGroupBuy.length < 2 && item.stockQuantity - item.progressingQuantity > 0) {
                        vm.storeGroupBuy.push(item);
                    }
                });
            });
        });
        // // 热门分类数据
        // vm.hotCategories = [];
        // CategoryStore.getAllByCondition({
        //     items: [
        //         {key: "usable", op: "=", value: "true"},
        //         {key: "haveSuperior", op: "=", value: "false"},
        //         {key: "hot", op: "=", value: true},
        //         {key: "storeId", op: "=", value: vm.store.id}
        //     ]
        // }, function (res) {
        //     vm.hotCategories = res.sort(sortNavTitle("seq"));
        //     console.log(vm.hotCategorie);
        // });
        // function sortNavTitle(propertyName) {
        //     return function (object1, object2) {
        //         var value1 = object1[propertyName];
        //         var value2 = object2[propertyName];
        //         return value1 - value2;
        //     }
        // }
        // console.log(vm.hotCategories);
        // 搜索
        vm.search = {
            type: "search",
            seq: '',
            style: 'square', //circle/square
            position: 'center', //left/center
            height: '42',
            backgroundColor: '#F9F9F9',
            color: '#FFFFFF',
            textColor: '#969799',
            selected: false
        }
        // 轮播图
        vm.banner = {
            type: "banner",
            seq: '',
            size: '750*380px',
            selected: false,
            banners: [{
                title: '标题一',
                img: '',
                jumpType: 'NONE', //NONE, //不跳转PRODUCT, //商品COUPON, //优惠券,MINIAPP//小程序
                url: '', //存放商品或小程序路径
                appid: ''
            },
            {
                title: '标题二',
                img: '',
                jumpType: 'NONE', //NONE, //不跳转PRODUCT, //商品COUPON, //优惠券,MINIAPP//小程序
                url: '', //存放商品或小程序路径
                appid: ''
            },
            {
                title: '标题三',
                img: '',
                jumpType: 'NONE', //NONE, //不跳转PRODUCT, //商品COUPON, //优惠券,MINIAPP//小程序
                url: '', //存放商品或小程序路径
                appid: ''
            },
            {
                title: '标题四',
                img: '',
                jumpType: 'NONE', //NONE, //不跳转PRODUCT, //商品COUPON, //优惠券,MINIAPP//小程序
                url: '', //存放商品或小程序路径
                appid: ''
            },
            ]
        }
        // 公告
        vm.announce = {
            type: "announce",
            seq: '',
            backgroundColor: '#fff8e9',
            textColor: '#646566',
            icon: '',
            selected: false
        }
        // 定制入口
        vm.third = {
            type: "third",
            seq: '',
            img: '',
            style: 'common', // common / shadow
            radius: 'square',
            pageMargin: '10',
            selected: false
        }
        // 优惠券
        vm.coupon = {
            type: "coupon",
            seq: '',
            customized: true, //true/false
            img: '',
            rightMargin: 30,
            bottomMargin: 30,
            style: 'common', // common / shadow
            radius: 'square', //circle/square
            pageMargin: 0,
            selected: false
        }
        // 卡包
        vm.package = {
            type: "package",
            seq: '',
            img: '',
            style: 'common', //common/shadow
            radius: 'circle',
            pageMargin: 10,
            selected: false
        }
        // 组合
        vm.composition = {
            type: "composition",
            seq: '',
            couponImg: '',
            packageImg: '',
            pageMargin: '0',
            space: '0',
            selected: false
        }
        // 热门分类
        vm.hotCategory = {
            type: "hotCategory",
            seq: '',
            displayMode: 'lineOfFour', //lineOfFour / lineOfFive
            imgMask: 'square', //circle / square,
            selected: false
        }
        //文本标题
        vm.titleText = {
            type: "titleText",
            seq: '',
            content: '这是标题',
            description: '这是内容',
            position: 'left', //center/left
            contentSize: '16', //16/14/12
            descriptionSize: '12', //16/14/12
            contentBold: true, //true/false
            descriptionBold: true, //true/false
            contentColor: '#323233',
            descriptionColor: '#969799',
            backgroundColor: '#FFFFFF',
            height: '70',
            selected: false
        }
        vm.product = {
            type: "product",
            seq: '',
            entityType: 'product',//product/category,商品or分类
            displayMode: 'largerImg',//largerImg/lineOfTwo/lineOfThree/aLarger/detailList,排列方式
            entities: [
                {
                    id: 0,
                    name: '商品一',
                    price: '99',
                    img: ''
                },
                {
                    id: 1,
                    name: '商品二',
                    price: '99',
                    img: ''
                },
                {
                    id: 2,
                    name: '商品三',
                    price: '88',
                    img: ''
                }
            ],
            style: 'noBordWhite',//noBordWhite/shadow/bordWhite/noBordTransparent,图片样式
            radius: 'circle',//circle/square
            imageScale: 'oneByOne',//threeByTwo/oneByOne/threeByFour/sixteenByNine,图片比例
            imgFill: 'blank',//fill/blank,图片填充or留白
            contentBold: true,//true/false,内容加粗
            position: 'left',//left/center,位置
            pageMargin: '10',
            space: '10',
            showName: true,//true/false,
            showDescription: true,//true/false,
            showPrice: true,//true/false,
            showOriPrice: true,//true/false,
            showOrderBtn: true,//true/false,
            orderBtnStyle: 'seven',//one...eight,
            badge: 'market',//none/market,
            badgeType: 'newcn',//newcn/hotcn/new/hot/self
            badgeImg: ''
        }
        // 拼团
        vm.groupBuy = {
            type: "groupBuy",
            seq: ''
        }
        // 商品模板
        vm.productXml = {
            type: "productXml",
            seq: '',
            productId: 1,
            theme: 'style1',//style1/style2
            displayMode: 'lineOfTwo',//lineOfTwo/lineOfThree
            lineNum: '2',//2/3
            style: 'noBordWhite',//noBordWhite/shadow/bordWhite/noBordTransparent,
            radius: 'square',//circle/square
            imageScale: 'oneByOne',//threeByTwo/oneByOne/threeByFour/sixteenByNine
            imgFill: 'fill',//fill/blank,
            productNameStyle: 'bold',//bold/normal 
            productNameSite: 'left',//left/center
            xmlNameStyle: 'normal',//bold/normal
            xmlNameSite: 'left',//left/center
            showProductName: true,//true/false,
            showXmlName: true,//:false,
            pageEdge: '10',
            xmlEdge: '10'
        }

        vm.widgets = []
        $scope.operatorPanel = function (name, module) {
            if (name == 'ShufflingModule') {
                RefreshBanner()
            }
            var newObj = JSON.parse(JSON.stringify(module));
            newObj.selected = false
            vm.widgets.push(newObj)
            vm.widgets.forEach(function (item, index) {
                item.seq = index + 1
            })
        }
        // 轮播图
        function swiperInit() {
            $timeout(function () {
                new Swiper('.swiper-container', {
                    autoplay: true,
                    loop: true,
                    grabCursor: true,
                    pagination: {
                        el: '.swiper-pagination',
                    },
                });
            }, 300)
        }
        // 点击选中
        $scope.selected = function (name, item) {
            vm.OperatorPanel = name
            vm.index = ''
            vm.widgets.forEach(function (i, index) {
                i.selected = false
                if (i.seq == item.seq) {
                    vm.index = index
                    i.selected = true
                }
            })
        }

        // 上移模块
        $scope.moveUp = function (data) {
            if (data.seq == 1) {
                return
            }
            $timeout(function () {
                var OneObj = JSON.parse(JSON.stringify(data));
                var TowObj = JSON.parse(JSON.stringify(vm.widgets[data.seq - 2]));
                vm.widgets[data.seq - 2] = OneObj
                vm.widgets[data.seq - 1] = TowObj
                sortSeq()
                vm.index = vm.index - 1
                if (data.type = 'banner') {
                    RefreshBanner()
                }
            })

        }

        // 下移模块
        $scope.moveDown = function (data) {
            if (data.seq == vm.widgets[vm.widgets.length - 1].seq) {
                return
            }
            $timeout(function () {
                var OneObj = JSON.parse(JSON.stringify(data));
                var TowObj = JSON.parse(JSON.stringify(vm.widgets[data.seq]))
                vm.widgets[data.seq - 1] = TowObj
                vm.widgets[data.seq] = OneObj
                sortSeq()
                vm.index = vm.index + 1
                if (data.type = 'banner') {
                    RefreshBanner()
                }
            })

        }

        // 复制模块
        $scope.copy = function (data) {
            var newObj = JSON.parse(JSON.stringify(data));
            newObj.selected = false
            vm.widgets.splice(data.seq - 1, 0, newObj)
            sortSeq()
            if (data.type == 'banner') {
                RefreshBanner()
            }
        }

        // 删除模块
        $scope.delete = function (data) {
            vm.widgets.splice(data.seq - 1, 1)
            vm.OperatorPanel = ''
            sortSeq()
        }

        // 排序
        function sortSeq() {
            vm.widgets.forEach(function (item, index) {
                item.seq = index + 1
            })
        }

        // 二级路劲图片
        window.UploadImg = function (sign, $event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var url = window.URL || window.webkitURL;
                var img = new Image(); //手动创建一个Image对象
                img.src = url.createObjectURL(file); //创建Image的对象的url
                img.onload = function () {
                    var w = Math.abs(this.width - 750);
                    var h = Math.abs(this.height - 1200);
                    console.log("w: " + w + " h: " + h);
                    console.log('height:' + this.height + '----width:' + this.width)
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', 'back-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            $timeout(function () {
                                vm.widgets[vm.index][sign] = data.key;
                            })
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                }
            });
        };
        // 三级轮播图上传图片
        window.UploadImg2 = function (sign, $event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var url = window.URL || window.webkitURL;
                var img = new Image(); //手动创建一个Image对象
                img.src = url.createObjectURL(file); //创建Image的对象的url
                img.onload = function () {
                    var w = Math.abs(this.width - 750);
                    var h = Math.abs(this.height - 1200);
                    console.log("w: " + w + " h: " + h);
                    console.log('height:' + this.height + '----width:' + this.width)
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', 'back-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            $timeout(function () {
                                vm.widgets[vm.index][sign][vm.bannersIndex].img = data.key;
                                RefreshBanner()
                            })
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                }
            });
        };
        vm.RefreshBanner = true
        // 删除轮播图
        $scope.deleteBanner = function (i) {
            vm.widgets[vm.index].banners.splice(i, 1)
            RefreshBanner()

        }
        // 添加轮播图
        $scope.addBanner = function () {
            var newBanner = {
                title: '',
                img: '',
                jumpType: 'NONE', //NONE, //不跳转PRODUCT, //商品COUPON, //优惠券,MINIAPP//小程序
                url: '' //存放商品或小程序路径
            }
            vm.widgets[vm.index].banners.push(newBanner)
            RefreshBanner()
        }

        $scope.displayMode = function () {
            vm.widgets[vm.index].displayMode = 'detailList'
            vm.widgets[vm.index].imageScale = 'oneByOne'
        }

        function RefreshBanner() {
            vm.RefreshBanner = false
            $timeout(function () {
                vm.RefreshBanner = true
                swiperInit()
            })
        }






        // ***********************商品弹窗内容**************************
        vm.goodsList = false
        vm.tableData = []
        vm.selectedList = []

        $scope.goodsList = function () {
            Product.getProductsClient({ storeId: vm.store.id }, function (res) {
                vm.tableData = res
                vm.goodsList = true
                if (vm.widgets[vm.index].type == "productXml") {
                    vm.tableData.forEach(function (it) {
                        if (it.id == vm.widgets[vm.index].productId) {
                            it.selected = true
                        }
                    })
                    return
                }
                vm.widgets[vm.index].entities.forEach(function (item) {
                    vm.tableData.forEach(function (it) {
                        if (it.id == item.id) {
                            it.selected = true
                        }
                    })
                })
            })

        }
        // 选择商品
        $scope.chooseCb = function (item, index) {
            if (vm.widgets[vm.index].type == "productXml") {
                vm.tableData.forEach(function (it) {
                    it.selected=false
                    if (it.id == item.id) {
                        it.selected = true
                    }
                })
                return
            }
            item.selected = !item.selected;
        }
        // 全选
        $scope.chooseAll = function () {
            if (vm.widgets[vm.index].type == "productXml") {
                return
            }
            vm.selectAll = !vm.selectAll;
            for (var i = 0; i < vm.tableData.length; i++) {
                vm.tableData[i].selected = vm.selectAll;
            }
        }
        // 添加
        $scope.confirm = function () {
            for (var i = 0; i < vm.tableData.length; i++) {
                if (vm.tableData[i].selected) {
                    var obj = {};
                    obj.id = vm.tableData[i].id
                    obj.name = vm.tableData[i].categoryName
                    obj.price = vm.tableData[i].price
                    obj.description = vm.tableData[i].description
                    obj.img = vm.tableData[i].coverImg
                    obj.markingOutPrice = vm.tableData[i].markingOutPrice
                    vm.selectedList.push(obj)
                }
            }
            if (vm.widgets[vm.index].type == "productXml" && vm.selectedList.length > 1) {
                MessageService.error("商品模板只能选择一个商品");
                vm.selectedList = []
                return
            }
            if (vm.widgets[vm.index].type == "productXml") {
                for (var i = 0; i < vm.tableData.length; i++) {
                    if (vm.tableData[i].selected) {
                        vm.widgets[vm.index].productId = vm.tableData[i].id
                        getProducts(vm.widgets[vm.index])
                        break
                    }
                }
                console.log(vm.widgets[vm.index]);
                vm.selectedList = []
                vm.goodsList = false
                return
            }
            vm.widgets[vm.index].entities = vm.selectedList
            vm.selectedList = []
            vm.goodsList = false
        }

        // **************************分类弹窗内容***********************************
        vm.classifyList = false
        vm.tableData2 = []
        vm.goodsList2 = []
        vm.activeClassifyid = []//已选分类名
        $scope.classifyList = function () {
            Product.getCategoriesByProduct({ storeId: vm.store.id }, function (res) {
                vm.tableData2 = res
                vm.classifyList = true
            })

        }
        // 选择分类
        $scope.chooseCb2 = function (item, index) {
            var selectedTrue = true
            vm.widgets[vm.index].entities.forEach(function (ite) {
                if (ite.id == item.id) {
                    selectedTrue = false
                }
            })
            if (selectedTrue) {
                item.selected = !item.selected;
            }
        }
        // 全选
        $scope.chooseAll2 = function () {
            vm.selectAll2 = !vm.selectAll2;
            for (var i = 0; i < vm.tableData2.length; i++) {
                vm.tableData2[i].selected = vm.selectAll2;
            }
        }
        // 添加
        $scope.confirm2 = function () {
            vm.tableData2.forEach(function (item) {
                if (item.selected) {
                    // 一级分类商品
                    if (item.products) {
                        item.products.forEach(function (goods1) {
                            var obj = {};
                            obj.id = goods1.id
                            obj.name = goods1.categoryName
                            obj.price = goods1.price
                            obj.img = goods1.coverImg
                            vm.goodsList2.push(obj)
                        })
                    }

                    // 二级分类（数组）
                    if (item.categories) {
                        item.categories.forEach(function (categories) {
                            if (categories.products) {
                                categories.products.forEach(function (goods2) {
                                    var obj = {};
                                    obj.id = goods2.id
                                    obj.name = goods2.categoryName
                                    obj.price = goods2.price
                                    obj.img = goods2.coverImg
                                    vm.goodsList2.push(obj)
                                })
                            }
                        })
                    }
                }

            })
            vm.widgets[vm.index].entities = vm.goodsList2
            vm.classifyList = false
        }
        // 得到风格三的xml
        function getProducts(style) {
            style.themeList = [];
            Product.get({ id: style.productId }, function (resp) {
                style.productXmlLines = [];
                style.editorType = resp.editorType;
                style.productTitle = resp.productTitle;
                for (var x = 0; x < resp.itemValues.length; x++) {
                    var itemValue = resp.itemValues[x];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    for (var y = 0; y < itemValue.productXmlLines.length; y++) {
                        var line = itemValue.productXmlLines[y];
                        line.itemValuesPrice = itemValue.price;
                        if (!line.productXmlThemeId || line.cancelled) {
                            continue;
                        }

                        var arr = checkRepetition(line.productXmlThemeId, style);
                        if (arr.flag) {
                            style.themeList.push({
                                id: line.productXmlThemeId,
                                name: line.productXmlThemeName,
                                seq: line.productXmlThemeSeq,
                                productXmls: []
                            });
                            style.themeList[style.themeList.length - 1].productXmls.push(line);
                        } else {
                            style.themeList[arr.index].productXmls.push(line);
                        }

                        // line.price = itemValue.price;
                        style.productXmlLines.push(line);
                    }
                }

                style.themeList.unshift({
                    id: 0,
                    name: '全部',
                    seq: 0,
                    productXmls: style.productXmlLines
                })

                style.themeList.sort(function (a, b) {
                    return a.seq - b.seq;
                })

                if (style.themeList.length) {
                    style.themeI = 0;
                    style.themeOne = style.themeList[0];
                    console.log(style.themeOne)
                }
                paging(style)

            });
        }
        // 分页功能
        $scope.pagingLine=function(style,num){
            if (num==2) {
                vm.widgets[vm.index].lineNum='2'
            }
            if(num==3){
                vm.widgets[vm.index].lineNum='3'
            }
            paging(style)
        }
        $scope.pagingCol=function(style,num){
            if (num==2) {
                vm.widgets[vm.index].displayMode='lineOfTwo'
            }
            if(num==3){
                vm.widgets[vm.index].displayMode='lineOfThree'
            }
            paging(style)
        }
        function paging(style){
            if (style.displayMode=='lineOfTwo') {
                if (style.lineNum=='2') {
                    style.size = 4;
                }else{
                    style.size = 6;
                }
            }else{
                if (style.lineNum=='2') {
                    style.size = 6;
                }else{
                    style.size = 9;
                }
            }
            angular.forEach(style.themeList, function (theme, i) {
                theme.pageSize = Math.ceil(theme.productXmls.length / style.size);
                theme.pageI = 1
            })
        }

        // 检查是否存在重复--vm.themeList
        function checkRepetition(id, style) {
            var arr = {
                index: 0,
                flag: true
            }
            // var flag = true;
            if (style.themeList.length == 0) {
            } else {
                for (var i = 0; i < style.themeList.length; i++) {
                    if (style.themeList[i].id == id) {
                        arr.flag = false;
                        arr.index = i;
                    }
                }
            }
            return arr;
        }
        $scope.prevPage = function (style) {
            style.themeOne.pageI--;
            angular.forEach(style.themeList, function (theme, index) {
                if (style.themeI != index) {
                    theme.pageI = 1;
                }
            })
        }

        $scope.nextPage = function (style, title) {
            style.themeOne.pageI++;
            angular.forEach(style.themeList, function (theme, index) {
                if (style.themeI != index) {
                    theme.pageI = 1;
                }
            })
        }
        $scope.chooseTheme = function (theme, index, style) {
            style.themeOne = angular.copy(theme);
            style.themeI = index;
            console.log(style)
        }
        // 保存
        $scope.save = function () {
            console.log('这是保存的数据', vm.widgets);
            vm.data.widgets = JSON.stringify(vm.widgets)
            IndexStyleSettings.save(vm.data, function (res) {
                if (res.status == 200) {
                    MessageService.success("保存成功");
                    $state.go('home-page-style')
                } else {
                    MessageService.error(res.message || "保存失败");
                }

            })
        }
    }
})();