(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RegionTempDeleteController',RegionTempDeleteController);

    RegionTempDeleteController.$inject = ['$uibModalInstance', 'entity', 'RegionTemp'];

    function RegionTempDeleteController($uibModalInstance, entity, RegionTemp) {
        var vm = this;

        vm.regionTemp = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RegionTemp.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
