(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('LogisticsSetDialogController', LogisticsSetDialogController);

    LogisticsSetDialogController.$inject = ['$state', 'ParseLinks', 'paginationConstants', 'MessageService', '$scope', '$uibModal', 'Store', 'StoreConfig'];

    function LogisticsSetDialogController($state, ParseLinks, paginationConstants, MessageService, $scope, $uibModal, Store, StoreConfig) {
        var vm = this;
        vm.data = Store.getLogisticsSetting();
        vm.storeConfig = StoreConfig.byStoreIdAndType({type:"KDNTRACES"});

         $scope.open = function(flag){
            vm.storeConfig.open = flag;
        }

        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }


        //保存
        $scope.save = function () {
            if(!vm.data.userId){
                MessageService.error("注册名称不能为空");
                return
            }
            if(!vm.data.appKey){
                MessageService.error("注册密码不能为空");
                return
            }
            if (vm.storeConfig.type == null ||vm.storeConfig.type == "") {
                vm.storeConfig.type = "KDNTRACES";
            }
            StoreConfig.update(vm.storeConfig);
            Store.updateLogisticsSetting(vm.data, function (res) {
                MessageService.success("保存成功");
                $state.go('logisticsSet');
            })
        }
    }
})();
