(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DiyEditorSettingDialogController', DiyEditorSettingDialogController);

    DiyEditorSettingDialogController.$inject = ['$q', '$timeout','UidService', '$scope', 'MessageService', 'entity', '$stateParams', '$state', 'Qinius', 'StoreStyleSetting' ,'Store',
    'ListService', 'PasswordMarketing', 'PlatformWechatSetting','FontManagement','DiyEditorSetting','$localStorage'];

    function DiyEditorSettingDialogController ($q, $timeout,UidService, $scope, MessageService, entity, $stateParams, $state, Qinius, StoreStyleSetting,Store,
        ListService, PasswordMarketing, PlatformWechatSetting,FontManagement,DiyEditorSetting,$localStorage) {
        var vm = this;
        vm.data = entity.message;
        vm.clear = clear;
        vm.save = save;
        vm.store = Store.getCurrent();
        // 选中tab(模板,文字,素材,图片)
        vm.pageBox = null;
        //右侧边栏展示名
        vm.rightIndex = null;
        /**
         * imgself  默认icon 不会改变
         * img      显示默认icon或者自定义icon
         * isShow   是否展示
         * seq      排序
         * seqValye 排序设置名称（保存时判断）
         * value    icon名称
         * value2   是否展示（icon名）
         * */
        vm.posterBox=[
            {id: 'mubanshape', name:'模板', img:'content/images/editor/mubanshape.svg',isShow:false,seq:0,value:'templateIcon',value2:'templateShow',seqValye:'templateSeq',imgself:'content/images/editor/mubanshape.svg'},
            {id: 'wenzishape', name:'文字', img:'content/images/editor/wenzishape.svg',isShow:false,seq:1,value:'wordIcon',value2:'wordShow',seqValye:'wordSeq',imgself:'content/images/editor/wenzishape.svg'},
            {id: 'sucaishape', name:'素材', img:'content/images/editor/sucaishape.svg',isShow:false,seq:2,value:'clipartIcon',value2:'clipartShow',seqValye:'clipartSeq',imgself:'content/images/editor/sucaishape.svg'},
            {id: 'tupianshape', name:'图片', img:'content/images/editor/tupianshape.svg',isShow:false,seq:3,value:'imgIcon',value2:'imgShow',seqValye:'imgSeq',imgself:'content/images/editor/tupianshape.svg'},
            {id: 'photoshape', name:'添加', img:'content/images/editor/photoshape.svg',isShow:false,seq:4,value:'photoIcon',value2:'photoShow',seqValye:'photoSeq',imgself:'content/images/editor/photoshape.svg'},
            
        ]

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        FontManagement.getAll1({items: [], sort: ["id,asc"]}, function (res) {
            vm.fontList = angular.copy(res);
            vm.fontList1 = angular.copy(res);
            $scope.item = vm.fontList[0];
        });
        vm.imgItems = [];
        vm.fontItems = [];
        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if(!vm.data.storeId){vm.data.storeId=$localStorage.user.storeId}
            if(!vm.data.mainColor){vm.data.mainColor = '#ff6e33';}
            if(!vm.data.delWordColor){vm.data.delWordColor = '#0a0a0a';}
            if(!vm.data.wordStyleColor){vm.data.wordStyleColor = '#0a0a0a';}
            if(!vm.data.editWordColor){vm.data.editWordColor = '#0a0a0a';}
            if(!vm.data.templateColor){vm.data.templateColor = '#333333';}
            if(!vm.data.clipartColor){vm.data.clipartColor = '#333333';}
            if(!vm.data.wordColor){vm.data.wordColor = '#333333';}
            if(!vm.data.imgColor){vm.data.imgColor = '#333333';}
            if(!vm.data.changeImgColor){vm.data.changeImgColor = '#0a0a0a';}
            if(!vm.data.filterColor){vm.data.filterColor = '#0a0a0a';}
            if(!vm.data.rotationColor){vm.data.rotationColor = '#0a0a0a';}
            if(!vm.data.upColor){vm.data.upColor = '#0a0a0a';}
            if(!vm.data.downColor){vm.data.downColor = '#0a0a0a';}
            if(!vm.data.flipColor){vm.data.flipColor = '#0a0a0a';}
            if(!vm.data.restoreColor){vm.data.restoreColor = '#0a0a0a';}
            if(!vm.data.spreadColor){vm.data.spreadColor = '#0a0a0a';}
            if(!vm.data.retractColor){vm.data.retractColor = '#0a0a0a';}
            if(!vm.data.wordText){vm.data.wordText="文字"}
            if(!vm.data.templateText){vm.data.templateText="模板"}
            if(!vm.data.clipartText){vm.data.clipartText="素材"}
            if(!vm.data.imgText){vm.data.imgText="图片"}
            if (!vm.data.photoText) { vm.data.photoText = "添加"; }
            if (!vm.data.photoColor) { vm.data.photoColor = "#333333"; }
            if(vm.data.templateShow){
                vm.posterBox[0].isShow = true;
                vm.posterBox[0].seq = vm.data.templateSeq;
            }
            if(vm.data.wordShow){
                vm.posterBox[1].isShow = true;
                vm.posterBox[1].seq = vm.data.wordSeq;
            }
            if(vm.data.clipartShow){
                vm.posterBox[2].isShow = true;
                vm.posterBox[2].seq = vm.data.clipartSeq;
            }
            if(vm.data.imgShow){
                vm.posterBox[3].isShow = true;
                vm.posterBox[3].seq = vm.data.imgSeq;
            }
            if(vm.data.photoShow){
                vm.posterBox[4].isShow = true;
                vm.posterBox[4].seq = vm.data.photoSeq;
            }
            vm.posterBox.sort(function (a, b) {
                return a.seq - b.seq;
            })
            //图片子菜单
            vm.imgItems.push({object: 'changeImgSeq', rightIndex: 'changeImg', text: vm.data.changeImgText||'换图', color: vm.data.changeImgColor, icon: vm.data.changeImgIcon||'content/images/editor/changeImgIcon.svg', self: vm.data.changeImgSelf, seq: vm.data.changeImgSeq});
            vm.imgItems.push({object: 'filterSeq', rightIndex: 'filter', text: vm.data.filterText||'滤镜', color: vm.data.filterColor, icon: vm.data.filterIcon||'content/images/editor/filterIcon.svg', self: vm.data.filterSelf, seq: vm.data.filterSeq});
            vm.imgItems.push({object: 'rotationSeq', rightIndex: 'rotation', text: vm.data.rotationText||'旋转', color: vm.data.rotationColor, icon: vm.data.rotationIcon||'content/images/editor/rotationIcon.svg', self: vm.data.rotationSelf, seq: vm.data.rotationSeq});
            vm.imgItems.push({object: 'upSeq', rightIndex: 'up', text: vm.data.upText||'放大', color: vm.data.upColor, icon: vm.data.upIcon||'content/images/editor/upIcon.svg', self: vm.data.upSelf, seq: vm.data.upSeq});
            vm.imgItems.push({object: 'downSeq', rightIndex: 'down', text: vm.data.downText||'缩小', color: vm.data.downColor, icon: vm.data.downIcon||'content/images/editor/downIcon.svg', self: vm.data.downSelf, seq: vm.data.downSeq});
            vm.imgItems.push({object: 'flipSeq', rightIndex: 'flip', text: vm.data.flipText||'翻转', color: vm.data.flipColor, icon: vm.data.flipIcon||'content/images/editor/flipIcon.svg', self: vm.data.flipSelf, seq: vm.data.flipSeq});
            vm.imgItems.push({object: 'restoreSeq', rightIndex: 'restore', text: vm.data.restoreText||'还原', color: vm.data.restoreColor, icon: vm.data.restoreIcon||'content/images/editor/restoreIcon.svg', self: vm.data.restoreSelf, seq: vm.data.restoreSeq});
            vm.imgItems.push({object: 'spreadSeq', rightIndex: 'spread', text: vm.data.spreadText||'展开', color: vm.data.spreadColor, icon: vm.data.spreadIcon||'content/images/editor/spreadIcon.svg', self: vm.data.spreadSelf, seq: vm.data.spreadSeq});
            vm.imgItems.push({object: 'retractSeq', rightIndex: 'retract', text: vm.data.retractText||'缩回', color: vm.data.retractColor, icon: vm.data.retractIcon||'content/images/editor/retractIcon.svg', self: vm.data.retractSelf, seq: vm.data.retractSeq});
            vm.imgItems.sort(function (a, b) {
                return a.seq - b.seq;
            });
            vm.fontItems.push({object: 'editWordSeq', rightIndex: 'editWord', text: vm.data.editWordText||'编辑文字', color: vm.data.editWordColor, icon: vm.data.editWordIcon||'content/images/editor/editWordIcon.svg', self: vm.data.editWordIconSelf, seq: vm.data.editWordSeq});
            vm.fontItems.push({object: 'wordStyleSeq', rightIndex: 'wordStyle', text: vm.data.wordStyleText||'文字样式', color: vm.data.wordStyleColor, icon: vm.data.wordStyleIcon||'content/images/editor/wordStyleIcon.svg', self: vm.data.wordStyleIconSelf, seq: vm.data.wordStyleSeq});
            vm.fontItems.push({object: 'delWordSeq', rightIndex: 'delWord', text: vm.data.delWordText||'删除文字', color: vm.data.delWordColor, icon: vm.data.delWordIcon||'content/images/editor/delWordIcon.svg', self: vm.data.delWordIconSelf, seq: vm.data.delWordSeq});
            vm.fontItems.sort(function (a, b) {
                return a.seq - b.seq;
            });

            if(vm.data.templateSelf && vm.data.templateIcon){ $scope.changeSelf('templateSelf',1); }
            if(vm.data.clipartSelf && vm.data.clipartIcon){ $scope.changeSelf('clipartSelf',1); }
            if(vm.data.wordIconSelf && vm.data.wordIcon){ $scope.changeSelf('wordIconSelf',1); }
            if(vm.data.imgSelf && vm.data.imgIcon){ $scope.changeSelf('imgSelf',1); }
            if(vm.data.photoSelf && vm.data.photoIcon){ $scope.changeSelf('photoSelf',1); }
        })

        function getDragImage(objId) {
            for (var i = 0; i < vm.posterBox.length; i++) {
                if (objId == vm.posterBox[i].id) {
                    return vm.posterBox[i].img;
                }
            }
            return vm.posterBox[0].img;
        }

        window.dragStart = function (event) {
            var target = event.target;
            $(".diy-editor-poster-box").addClass('diy-drop-active')
            event.dataTransfer.setData("Text", target.id);
            if (target.firstElementChild) {
                event.dataTransfer.setDragImage(target.firstElementChild, 40, 40);
            }
        };

        var content = $(".diy-editor-poster-box")[0];
        content.ondragenter = ignoreDrag;
        // 拖动文件的鼠标指针位置放置区之上时发生
        content.ondragover = ignoreDrag;
        content.ondrop = drop;

        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
            var dataset = e.target.dataset;
        }

        function drop(e) {
            ignoreDrag(e);
            var data = e.dataTransfer.getData("Text");
            if (data.indexOf('mubanshape') != '-1') {
                selectTab('templateIcon')
                vm.data.templateShow = true;
            }else if (data.indexOf('wenzishape') != '-1') {
                selectTab('wordIcon')
                vm.data.wordShow = true;
            }else if (data.indexOf('sucaishape') != '-1') {
                selectTab('clipartIcon')
                vm.data.clipartShow = true;
            }else if (data.indexOf('tupianshape') != '-1') {
                selectTab('imgIcon')
                vm.data.imgShow = true;
            } else if (data.indexOf('photoshape') != '-1') {
                selectTab('photoIcon')
                vm.data.photoShow = true;
            }
            $(".diy-editor-poster-box").removeClass('diy-drop-active')
            $scope.$digest()
            console.log(vm.data)
        }

        function selectTab(attr){
            for (var i = 0; i < vm.posterBox.length; i++) {
                if(vm.posterBox[i].value == attr){
                    vm.posterBox[i].isShow = true;
                    break
                }
            }
        }

        $scope.editBox = function(box){
            vm.pageBox = box.value;
            vm.rightIndex = box.value;
            console.log(vm.rightIndex)
        }

        $scope.deleteTab = function(e,box){
            fn(e);
            box.isShow = false;
            vm.pageBox = null;
            vm.rightIndex = null;
        }

        $scope.changeSelf = function(attr,flag){
            if(flag){
                vm.data[attr] = true;
            }else{
                vm.data[attr] = false;
            }
            // 四个tab icon
            for (var i = 0; i < vm.posterBox.length; i++) {
                if(
                    (attr=='templateSelf'&&vm.posterBox[i].value=='templateIcon') || 
                    (attr=='clipartSelf'&&vm.posterBox[i].value=='clipartIcon') ||
                    (attr=='wordIconSelf'&&vm.posterBox[i].value=='wordIcon') || 
                    (attr=='imgSelf'&&vm.posterBox[i].value=='imgIcon') ||
                    (attr=='photoSelf'&&vm.posterBox[i].value=='photoIcon')
                ){
                    if(flag && vm.data[vm.posterBox[i].value]){
                        vm.posterBox[i].img = vm.data[vm.posterBox[i].value];
                    }else{
                        vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);
                    }
                    break
                }
            }
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
        window.updateImgFile = function ($event) {
            console.log($event)
            console.log($event.target)
            $timeout(function () {
                var file = $event.target.files[0];
                updateImg(file,$event.target.dataset.flag)
            });
        };

        function updateImg(file,attr) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(file);//创建Image的对象的url
            img.onload = function () {
                // 建议尺寸40px*40px
                var w = Math.abs(this.width - 40);
                var h = Math.abs(this.height - 40);
                if (w > 11 || h > 11) {
                    MessageService.error("建议图片尺寸：40px*40px");
                    return
                }
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'diy-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        console.log(data);
                        vm.data[attr] = $scope.domain+data.key;
                        if(attr=='wordIcon'){
                            for (var i = 0; i < vm.posterBox.length; i++) {
                                if(vm.posterBox[i].value=='wordIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        if(attr=='clipartIcon'){
                            for (var i = 0; i < vm.posterBox.length; i++) {
                                if(vm.posterBox[i].value=='clipartIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        if(attr=='templateIcon'){
                            for (var i = 0; i < vm.pageBox.length; i++) {
                                if(vm.posterBox[i].value=='templateIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        if(attr=='imgIcon'){
                            for (var i = 0; i < vm.posterBox.length; i++) {
                                if(vm.posterBox[i].value=='imgIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        if(attr=='photoIcon'){
                            for (var i = 0; i < vm.posterBox.length; i++) {
                                if(vm.posterBox[i].value=='photoIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        $scope.$digest();
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            }
        }

        $scope.removeFile = function(attr) {
            vm.data[attr] = null;
            if(attr=='wordIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='wordIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
            if(attr=='clipartIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='clipartIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
            if(attr=='templateIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='templateIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
            if(attr=='imgIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='imgIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
            if(attr=='photoIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='photoIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
        }

        $scope.addTextFont = function(){
            if(!vm.data.supportFonts){
                vm.data.supportFonts = [];
            }
            vm.data.supportFonts.push({fontManagement:null})
            console.log(vm.data.supportFonts)
        }

        $scope.delFont = function(index){
            vm.data.supportFonts.splice(index,1)
        }

        $scope.addColor = function(){
            if(!vm.data.supportFontColors){
                vm.data.supportFontColors = [];
            }
            vm.data.supportFontColors.push({color:'#333333'})
            console.log(vm.data.supportFonts)
        }

        $scope.delColor = function(index){
            vm.data.supportFontColors.splice(index,1)
        }

        $scope.chooseFont = function () {
            console.log(vm.data)
        }

        var sort = null;
        var sortArray = [];
        setTimeout(function () {
            sort = new Sortable(document.getElementById('my-ui-list1'), {
                swap: true,
                filter: '.filtered',
                animation: 150,
                onEnd: function (/**Event*/evt) {
                    console.log('onEnd.foo:', [evt.item, evt.from]);
                }
            });
            new Sortable(document.getElementById('my-ui-list2'), {
                swap: true,
                filter: '.filtered',
                animation: 150,
                onEnd: function (/**Event*/evt) {
                    console.log('onEnd.foo:', [evt.item, evt.from]);
                }
            });
            new Sortable(document.getElementById('my-ui-list3'), {
                swap: true,
                filter: '.filtered',
                animation: 150,
                onEnd: function (/**Event*/evt) {
                    console.log('onEnd.foo:', [evt.item, evt.from]);
                }
            });
        },500)

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('diy-editor-setting', null, { reload: 'diy-editor-setting' });
        }

        function save () {
            sortArray = [];
            angular.forEach($('#my-ui-list1').find('.slide-content1'), function (item, index) {
                var seq = item.getAttribute("data-sort-tag");
                var value = angular.copy(vm.posterBox[seq]);
                value.seq = index;
                sortArray.push(value)
            });
            angular.forEach($('#my-ui-list2').find('.slide-content1'), function (item, index) {
                var seq = item.getAttribute("data-sort-tag");
                var value = angular.copy(vm.imgItems[seq]);
                vm.data[value.object] = index;
            });
            angular.forEach($('#my-ui-list3').find('.slide-content1'), function (item, index) {
                var seq = item.getAttribute("data-sort-tag");
                var value = angular.copy(vm.fontItems[seq]);
                vm.data[value.object] = index;
            });
            console.log(vm.data)
            vm.posterBox = angular.copy(sortArray);
            angular.forEach(vm.posterBox,function (box) {
                if(box.isShow){
                    vm.data[box.value2] = true;
                    vm.data[box.seqValye] = box.seq;
                }else{
                    vm.data[box.value2] = false;
                }
            })
            console.log(vm.data)
            // sort.destroy();
            // return
            vm.isSaving = true;
            DiyEditorSetting.update(vm.data, onSaveSuccess, onSaveError);
            // return
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:navigationSettingUpdate', result);
            $state.go('diy-editor-setting', null, { reload: 'diy-editor-setting' });
            // $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        return
        vm.store = Store.getCurrent();
        vm.marketing = PasswordMarketing.getOne({type:"GroupBuy"});
        vm.platformWechatSetting = PlatformWechatSetting.get({})


    }
})();
