(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DiyEditorSettingCreateController', DiyEditorSettingCreateController);

    DiyEditorSettingCreateController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'DiyEditorSetting', 'MessageService','Store'];

    function DiyEditorSettingCreateController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, DiyEditorSetting, MessageService,Store) {
        var vm = this;
        vm.store = Store.getCurrent();
        vm.data = {};
        vm.cancel = cancel;
        vm.save = save;

        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if(!vm.data.editorType){
                MessageService.error("请选择适用编辑器");
                return
            }

            if(!vm.data.name){
                MessageService.error("请输入编辑器名称");
                return
            }
            console.log(vm.data)
            vm.isSaving = true;
            DiyEditorSetting.save(vm.data, onSaveSuccess, onSaveError);

            function onSaveSuccess (result) {
                MessageService.success("保存成功");
                $uibModalInstance.close(result);
                vm.isSaving = false;
            };

            function onSaveError () {
                MessageService.error("保存失败");
                vm.isSaving = false;
            };
        }
    }
})();
