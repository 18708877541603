(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GoodsShelfController', GoodsShelfController);

    GoodsShelfController.$inject = ['GoodsShelf', '$state', 'Producer',  'paginationConstants', 'pagingParams','MessageService','$scope', '$uibModal', 'ParseLinks'];

    function GoodsShelfController (GoodsShelf, $state, Producer,  paginationConstants, pagingParams,MessageService,$scope, $uibModal, ParseLinks) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                GoodsShelf.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        function loadAll () {
            var items = [];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
            }

            GoodsShelf.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.goodShelfs = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.producers, function (pro) {
                    ProducerManagement.findByProducerId({producerId: pro.id}, function (res) {
                        pro.products = res.products;
                    });
                })

                console.log(vm.producers)
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
