(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Redeems', Redeems);

    Redeems.$inject = ['$resource', 'DateUtils'];

    function Redeems($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/redeems/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validate = DateUtils.convertLocalDateFromServer(data.validate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "update": {
                method: "PUT"
            },
            "delete": {
                method: "DELETE"
            },
        });
    }
})();
