(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SubstitutionDialogController', SubstitutionDialogController);

    SubstitutionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Substitution', 'SubstitutionTask', 
    'StoreUser', 'MessageService', '$q', 'ListService', 'DateUtils'];

    function SubstitutionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Substitution, SubstitutionTask, 
        StoreUser, MessageService, $q, ListService, DateUtils) {
        var vm = this;

        vm.entity = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.substitutiontasks = SubstitutionTask.query();
        vm.users = StoreUser.getAllByCondition({items:[{key:"role.authorities.code", op:"=", value:"TASK_LIST"}]});


        $q.all([vm.users.$promise, vm.substitutiontasks.$promise]).then(function () {
            for (var i = vm.users.length - 1; i >= 0; i--) {
                var u = vm.users[i];
                vm.datePickerOpenStatus[u.name] = false;
                var result = ListService.inList2(u, vm.substitutiontasks, ["id"], ["userId"]); 
                if (result) {
                    u.taskQuantity = result.taskQuantity;
                    u.taskFailQuantity = result.taskFailQuantity;
                }else{
                    u.taskQuantity = 0;
                    u.taskFailQuantity = 0;
                }
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss(false);
        }

        function save () {
            var user = null;
            for (var i = vm.users.length - 1; i >= 0; i--) {
                var u = vm.users[i];
                if (u.openType) {
                    user = u;
                    break;
                }
            }
            if (user == null) {
                MessageService.error("请选择代排人员")
            }
            vm.isSaving = true;
            Substitution.assignmentBatch({ids:vm.entity, userId:user.id, userName:user.name, 
                requireDate: DateUtils.convertLocalDateToServer(user.requirePostBackDate)}, function (result) {
                $uibModalInstance.close(true);
                vm.isSaving = false;
            }, function (error) {
                vm.isSaving = false;
            })

        }

        

        function openCalendar (u) {
            vm.datePickerOpenStatus[u.name] = true;
        }
    }
})();
