(function() {
    'use strict';

    angular.module('backApp')
        .controller('ExpressModalController', ExpressModalController);

    ExpressModalController.$inject = ['$uibModalInstance', 'MessageService', 'entity', 'LogisticsCompany','$scope','$localStorage'];

    function ExpressModalController ($uibModalInstance, MessageService, entity, LogisticsCompany,$scope,$localStorage) {
        var vm = this;
        vm.type = entity.type;
        if(vm.type == 'add'){
            vm.express = {
                name: '',
                code: '',
                enabled: false
            };
        }
        if(vm.type == 'edit' || vm.type == 'check' ){
            vm.express = angular.copy(entity.data);
        }
        vm.wxExpressList = []

        getWxLogistics()

       function getWxLogistics(){
        LogisticsCompany.getWechatLogistics({storeId:$localStorage.user.storeId},function(res){
            console.log(res);
            vm.wxExpressList = res
        },function(err){
            console.log(err);
        })
       }

        vm.cancel = cancel;
        vm.save = save;
        vm.saveEdit = saveEdit;

        //取消
        function cancel() {
            $uibModalInstance.close(false);
        }
        //保存
        function save() {
            if(!vm.express.name){
                MessageService.error("名称不能为空");
                return
            }
            if(!vm.express.code){
                MessageService.error("编码不能为空");
                return
            }
            if(!vm.express.wechatDeliveryId) vm.express.wechatDeliveryName = null
            LogisticsCompany.save(vm.express, function (res) {
                $uibModalInstance.close(true);
            }, function (error) {
                MessageService.error("添加失败，请稍后重试！");
            });
        }

        //编辑
        function saveEdit() {
            if(!vm.express.name){
                MessageService.error("名称不能为空");
                return
            }
            if(!vm.express.code){
                MessageService.error("编码不能为空");
                return
            }
            LogisticsCompany.update(vm.express, function (res) {
                $uibModalInstance.close(true);
            }, function (error) {
                MessageService.error("添加失败，请稍后重试！");
            });
        }
    }
})();
