(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderRecombinationController',SalesOrderRecombinationController);

        SalesOrderRecombinationController.$inject = ['$uibModalInstance', '$scope', 'entity', 'SalesOrder', 'MessageService','Qinius','$q'];

    function SalesOrderRecombinationController($uibModalInstance, $scope, entity, SalesOrder, MessageService, Qinius,  $q) {
        var vm = this;

        vm.salesOrder = entity.data;
        vm.clear = clear;
        vm.confirm = confirm;
        vm.domains = Qinius.getDomain();
        vm.orderLine = null;
        $q.all([vm.domains.$promise, vm.salesOrder.$promise]).then(function() {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.salesOrder.orderLines.forEach(function(orderLine) {
                orderLine.selected = false;
            });
        });

        $scope.selected = function(id){
            var isSelected = false;
            vm.salesOrder.orderLines.forEach(function(orderLine) {
                if (orderLine.id == id && orderLine.selected) {
                    orderLine.selected = true;
                    isSelected = true;
                    vm.orderLine = angular.copy(orderLine);
                }else{
                    orderLine.selected = false;
                }
            });
            if (!isSelected) {
                vm.orderLine = null;
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function confirm () {
            if (vm.orderLine == null) {
                MessageService.error('请选择一个重组订单行')
                return
            }
            SalesOrder.reJustify({orderId:vm.salesOrder.id,orderLineId:vm.orderLine.id},function(res){
                if (res.status == 200) {
                    MessageService.success('操作成功')
                }else{
                    MessageService.error(res.message || '重组并推送失败')
                }
                $uibModalInstance.close(true);
            },function(err){
                MessageService.error(err.data.message || '接口报错请稍候重试')
            })
        }
    }
})();
