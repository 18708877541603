(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store', {
            parent: 'entity',
            url: '/store',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-detail.html',
                    controller: 'StoreDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })
        .state('small-link', {
            parent: 'store',
            url: '/small-link',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-small-link.html',
                    controller: 'StoreSmallLinkController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })

        .state('senior', {
            parent: 'store',
            url: '/senior',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-detail-senior.html',
                    controller: 'StoreDetailSeniorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })

        .state('senior-edit', {
            parent: 'store',
            url: '/senior/edit',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-detail-senior-dialog.html',
                    controller: 'StoreDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })
        .state('sales-setting', {
            parent: 'store',
            url: '/salesSetting',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-detail-salesSetting.html',
                    controller: 'StoreDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })
        .state('sales-setting-edit', {
            parent: 'store',
            url: '/salesSetting/edit?type',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-detail-salesSetting-dialog.html',
                    controller: 'StoreDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })


        .state('store.edit', {
            parent: 'store',
            url: '/edit',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'backApp.store.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store/store-dialog.html',
                    controller: 'StoreDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Store', function($stateParams, Store) {
                    return Store.getCurrent({}).$promise;
                }]
            }
        })
        .state('store.edit2', {
            parent: 'store',
            url: '/{id}/edit2',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/store/store-dialog2.html',
                    controller: 'StoreDialog2Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Store', function(Store) {
                            return Store.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('store', null, { reload: 'store' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('store.edit3', {
            parent: 'store',
            url: '/{id}/edit3',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/store/store-dialog3.html',
                    controller: 'StoreDialog3Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Store', function(Store) {
                            return Store.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('store', null, { reload: 'store' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
