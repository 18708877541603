(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RoleDialogController', RoleDialogController);

    RoleDialogController.$inject = ['$timeout', '$q', '$scope', 'Store', '$state', 'entity', 'Role',
        'Authority', 'MessageService', 'ListService', 'PasswordMarketing'];

    function RoleDialogController ($timeout, $q, $scope, Store, $state, entity, Role,
                                   Authority, MessageService, ListService, PasswordMarketing) {
        var vm = this;

        vm.role = entity;
        vm.clear = clear;
        vm.save = save;
        vm.store = Store.getCurrent();
        vm.marketings = PasswordMarketing.getAllByType(['Password','GroupBuy']);
        vm.authorities = Authority.getAllByCondition({items:[{key:"jhiType",op:"=",value:"STORE"}]});
        vm.authorities_2 = [];
        vm.mods = [];
        $q.all([vm.store.$promise,vm.role.$promise, vm.authorities.$promise, vm.marketings.$promise]).then(function(){
            vm.role.type = "STORE";
            if (vm.marketings) {
                for (var i = vm.marketings.length - 1; i >= 0; i--) {
                    var m = vm.marketings[i];
                    if (m.id) {
                        vm.groupBuyShow = true;
                        break;
                    }
                }
                
            }

            $scope.groupBy();
            //编辑
            if (vm.role.id) {
                for (var i = 0; i < vm.role.authorities.length; i++) {
                    var au =  vm.role.authorities[i];
                    var result = ListService.inList(au, vm.mods, ["module"]);
                    if (result) {
                        for (var j = result.authorities.length - 1; j >= 0; j--) {
                            var authority = result.authorities[j];
                            if(authority.id == au.id){
                                authority.selected = true;
                                break;
                            }
                        }
                    }
                }

                for (var i = vm.mods.length - 1; i >= 0; i--) {
                    var mod = vm.mods[i];
                    for (var j = mod.authorities.length - 1; j >= 0; j--) {
                        var au = mod.authorities[j];
                        if (!au.selected) {
                            mod.unAllSelect = true;
                            break;
                        }
                    }
                    if (mod.unAllSelect) {
                        mod.selected = false;
                    }else {
                        mod.selected = true;
                    }
                }
            };

        });

        $scope.chooseAuthorty = function(mod, au){
            au.selected = !au.selected;
            if (!au.selected) {
                mod.selected = false;
            }
            for (var i = mod.authorities.length - 1; i >= 0; i--) {
                var auth = mod.authorities[i];
                if (!auth.selected) {
                    mod.selected = false;
                    return;
                }
            }
            mod.selected = true;
        }

        $scope.chooseMod = function(mod){
            if (!mod) {
                return;
            }
            mod.selected = !mod.selected;
            for (var i = mod.authorities.length - 1; i >= 0; i--) {
                var au = mod.authorities[i];
                au.selected = mod.selected;
            }
        }


        $scope.groupBy = function(){
            vm.mods = [];
            if(vm.authorities == null || vm.authorities.length == 0 || vm.role.type == null || vm.role.type == ""){
                return null;
            }
            for (var i = vm.authorities.length - 1; i >= 0; i--) {
                var authority = vm.authorities[i];
                if (authority.jhiType != vm.role.type) {
                    continue;
                }
                // 若平台没开分店功能，则不显示
                if(!vm.store.branch&&authority.module=='分店'){
                    continue;
                }
                // 若平台没开口令营销功能，则不显示
                if(!vm.groupBuyShow&&authority.module=='营销'){
                    continue
                }
                var e = ListService.inList(authority,vm.mods ,["module"]);
                if(!e){
                    var r = {module:authority.module,authorities:[]};
                    vm.mods.push(r);
                }

                for (var j = vm.mods.length - 1; j >= 0; j--) {
                    var mod = vm.mods[j];
                    if(authority.module == mod.module){
                        authority.selected = false;
                        mod.authorities.push(authority);
                        break;
                    }
                }
            }
        };

        $scope.authoritySelected = function(authority){
            authority.selected = !authority.selected;

        };


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('role', null, {reload: 'role'});
        }

        $scope.toPost = function (){
            var postData  ={};
            postData.authorities = [];
            postData.id = vm.role.id;
            postData.name = vm.role.name;
            postData.type = vm.role.type;
            postData.number = vm.role.number;
            postData.cancelled = vm.role.cancelled;
            postData.storeId = vm.role.storeId;
            for (var i = vm.mods.length - 1; i >= 0; i--) {
                var mod = vm.mods[i];
                for (var j = mod.authorities.length - 1; j >= 0; j--) {
                    var au = mod.authorities[j];
                    if (au.selected) {
                        postData.authorities.push(au);
                    }
                }
            }
            return postData;
        };

        $scope.validate = function(){
            if(vm.role.name == null || vm.role.name == ""){
                MessageService.error("请输入名称");
                return false;
            }
            if(vm.role.type == null || vm.role.type == ""){
                MessageService.error("请输入类型");
                return false;
            }
            return true;
        };


        function save () {
            if(!$scope.validate()){
                return
            }
            var data = $scope.toPost();
            data.type = 'STORE';
            vm.isSaving = true;
            if (vm.role.id !== null) {
                Role.update(data, onSaveSuccess, onSaveError);
            } else {
                Role.save(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (res) {
            $scope.$emit('backApp:roleUpdate', res.data);
            MessageService.success("保存成功！");
            $state.go("role");
            $timeout(function (){
                window.location.reload();
            },50);
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }


    }
})();
