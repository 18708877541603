(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreSmallAppSettingDialogController', StoreSmallAppSettingDialogController);

    StoreSmallAppSettingDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'StoreSmallAppWechatSetting', 'MessageService', '$state'];

    function StoreSmallAppSettingDialogController ($timeout, $scope, $stateParams, entity, StoreSmallAppWechatSetting, MessageService, $state) {
        var vm = this; 
        vm.storeSmallAppSetting = entity;   
        vm.save = save;
        vm.cancel = cancel;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        }); 

        $scope.validate = function () {
            if (vm.storeSmallAppSetting.weChatKey == null || vm.storeSmallAppSetting.weChatKey =="") {
                MessageService.error("请录入商户key");
                return false;
            }
            if (vm.storeSmallAppSetting.weChatMchid == null || vm.storeSmallAppSetting.weChatMchid == "") {
                MessageService.error("请录入商户id");
                return false;
            } 
            return true;
        }

        function save () {
            if (!$scope.validate()) {
                return ;
            }
            vm.isSaving = true; 
            StoreSmallAppWechatSetting.update(vm.storeSmallAppSetting, onSaveSuccess, onSaveError); 
        }

        function cancel (){
            $state.go("store-wechat-setting");
        }

        function onSaveSuccess (result) {
            $state.go("store-wechat-setting",{},{reload:true});
            MessageService.success("保存成功");
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.success("保存失败");
            vm.isSaving = false;
        } 
    }
})();
