(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PaycodeAnalysisController', PaycodeAnalysisController);

    PaycodeAnalysisController.$inject = ['$scope', '$state', 'ParseLinks', 'MessageService', 'PayCode', 'Store','Principal'];

    function PaycodeAnalysisController ($scope, $state, ParseLinks, MessageService, PayCode, Store,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DATASTATISTICS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.downLoadFile = downLoadFile;

        vm.stores = Store.getAllByCondition({items:[]});
        vm.totalCount = 0;
        vm.totalActivatedCount = 0;
        vm.totalUsedCount = 0;

        getData();
        function getData() {
            vm.paycodes = PayCode.getAllByStore({}, function(data) {
                if (data.length > 0) {
                    for (var i = 0; i < data.length - 1; i++) {
                        vm.totalCount += Number(data[i].allNum);
                        vm.totalActivatedCount += Number(data[i].activatedNum);
                        vm.totalUsedCount += Number(data[i].usedNum);
                    }
                }
            });
        }

        function downLoadFile(){
            if(vm.storeId== null ||  vm.storeId == ""){
                MessageService.error("请选择商户");
                return;
            }

            if(vm.batchs== null ||  vm.batchs == ""){
                MessageService.error("请录入批次号");
                return;
            }
            window.open("service/api/pay-codes/payCodeAnalysisDownload?storeId=" + vm.storeId + "&batches="+ vm.batchs);
        }

    }
})();
