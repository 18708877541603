(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('entity', { abstract: true, parent: 'app'});
        $stateProvider.state('basicSettings', { abstract: true, parent: 'app'});
        $stateProvider.state('usersAuthority', { abstract: true, parent: 'app'});
        $stateProvider.state('templateManagement', { abstract: true, parent: 'app'});
        $stateProvider.state('salesManagement', { abstract: true, parent: 'app'});
        $stateProvider.state('shipManagement', { abstract: true, parent: 'app'});
        $stateProvider.state('bannerManagement', { abstract: true, parent: 'app'});
        $stateProvider.state('customerManagement', { abstract: true, parent: 'app'});
        $stateProvider.state('couponManagement', { abstract: true, parent: 'app'});
        $stateProvider.state('storeManagement', { abstract: true, parent: 'app'});
    }
})();
