(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupCommodityDialogController', GroupCommodityDialogController);

    GroupCommodityDialogController.$inject = ['$state','UidService', 'ProductPurchase', 'ParseLinks',  'paginationConstants', 'MessageService'
    ,'$scope','Qinius', '$q', 'Product', '$uibModal','entity','StoreGroupBuys', '$stateParams','DateUtils','PayCode', 'ExchangeCodes'];

    function GroupCommodityDialogController($state,UidService, ProductPurchase, ParseLinks,   paginationConstants, MessageService,
        $scope, Qinius, $q, Product, $uibModal,entity,StoreGroupBuys, $stateParams, DateUtils,PayCode, ExchangeCodes) {
        var vm = this;
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.dateformat = dateformat;
        vm.isPeriodOfValidity = false;
        vm.openCalendar = openCalendar;
        vm.isEdit = false;
        vm.data = {
            divideUpType:'AVERAGE',
            storeGroupBuyPayBatches:[]
        };
        vm.tabList = [
            {name:'商品信息', id:0},
            {name:'拼团配置', id:1},
            {name:'玩法设置', id:2}
        ];
        vm.tabId = 0;
        vm.typeList = [
            {name:'虚拟物品', id:"VIRTUAL"},
            {name:'实物', id:"REAL"}
        ];

        vm.isProduct = false;
        vm.products = Product.getAllByCondition4({items:[{key: "cancelled", op: "=", value: "false"},{key: "saleable", op: "=", value: "true"},{key: "productType", op: "=", value: "Standard"}]});
        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            if(entity === 'add'){
                vm.isEdit = false;
            }else {
                vm.isEdit = true;
                StoreGroupBuys.get({id:$stateParams.id}, function (res) {
                    vm.data = res;
                    // vm.data.periodOfValidity = DateUtils.convertLocalDateToServer(vm.data.periodOfValidity);
                    if(vm.data.goodsType === "REAL"){
                        vm.isProduct = true;
                        vm.data.storeGroupBuyPayBatches = [];
                        vm.itemValueLines = [];
                        for(var i=0; i<vm.products.length; i++){
                            if(vm.products[i].id == vm.data.productId){
                                for(var j=0; j<vm.products[i].itemValues.length; j++){
                                    var _itemValues = vm.products[i].itemValues[j];
                                    for(var k=0; k<_itemValues.itemValueLines.length; k++){
                                        vm.itemValueLines.push(_itemValues.itemValueLines[k])
                                    }
                                }
                            }
                        }
                    }else {
                        vm.isProduct = false;
                    }
                })
            }
        });
        $scope.tabCb = function (item) {
            vm.tabId = item;
        }
        $scope.typeChange = function(){
            vm.data.productId = "";
            vm.data.productName = "";
            vm.data.itemValueLineId = "";
            vm.data.itemValueLineSku = "";
            vm.data.itemValueLineSkuNumber = "";
            if(vm.data.goodsType === "REAL"){
                vm.isProduct = true;
                vm.data.storeGroupBuyPayBatches = [];
            }else {
                vm.isProduct = false;
            }
        }
        $scope.productChange = function(){
            vm.itemValueLines=[];
            vm.data.itemValueLineId = "";
            vm.data.itemValueLineSku = "";
            vm.data.itemValueLineSkuNumber = "";
            for(var i=0; i<vm.products.length; i++){
                if(vm.products[i].id == vm.data.productId){
                    for(var j=0; j<vm.products[i].itemValues.length; j++){
                        var _itemValues = vm.products[i].itemValues[j];
                        for(var k=0; k<_itemValues.itemValueLines.length; k++){
                            vm.itemValueLines.push(_itemValues.itemValueLines[k])
                        }
                    }
                }
            }
        }
        $scope.itemValueLineChange = function(){
            for(var i=0; i<vm.itemValueLines.length; i++){
                if(vm.itemValueLines[i].id == vm.data.itemValueLineId){
                    vm.data.itemValueLineSku = vm.itemValueLines[i].sku;
                    vm.data.itemValueLineSkuNumber = vm.itemValueLines[i].skuNumber;
                }
            }
        };

        //上传图片
        window.uploadPic = function ($event,item) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0], item);
            }
        };

        function qiniuUpload(file,item) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "group-"+ UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(item == 'cover'){
                        vm.data.cover = data.key;
                    }
                    if(item == 'detail'){
                        vm.data.detail = data.key;
                    }
                    if(item == 'banner'){
                        vm.data.storeGroupBuyBanners = vm.data.storeGroupBuyBanners ? vm.data.storeGroupBuyBanners : [];
                        vm.data.storeGroupBuyBanners.push({
                            seq:vm.data.storeGroupBuyBanners.length+1,
                            identifier:data.key,
                            provider:"qiniu"
                        });
                    }
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.removeFile = function (item, index) {
            if(item === 'cover'){
                vm.data.cover = "";
            }
            if(item === 'detail'){
                vm.data.detail = "";
            }
            if(item === 'banner'){
                vm.data.storeGroupBuyBanners.splice(index, 1)
            }
        }
        $scope.singleBuySelect = function(item){
            vm.data.singleBuy = item;
        }

        //玩法设置
        $scope.divideUpTypeSelect = function(item){
            vm.data.divideUpType = item;
            if(vm.data.divideUpType == 'AVERAGE'){
                vm.data.divideUpPercent = "";
                vm.data.divideUpPercent2 = "";
                vm.data.masterDivideUp = "";
            }
            if(vm.data.divideUpType == 'RANDOM'){
                vm.data.divideUpPercent2 = "";
                vm.data.divideUpMoney = "";
                vm.data.masterDivideUp = "";
            }
            if(vm.data.divideUpType == 'MASTER'){
                vm.data.divideUpPercent = "";
                vm.data.divideUpMoney = "";
            }
        }


        //卡卷导入
        vm.isAllSelected = false;
        $scope.addCard = function () {
            $uibModal.open({
                templateUrl: 'app/entities/group-commodity/modal/group-card.html',
                controller: 'GroupCardController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {};
                    }]
                }
            }).result.then(function(data) {
                //支付码
                if(data.payBatchType == "PAYCODE"){
                    PayCode.findByPayBatch({payBatchId:data.id}, function (res) {
                        if(res.length == 0){
                            return
                        }
                        var isNew = false;
                        var _index = "";
                        var _obj={
                            payBatchNumber:res[0].payBatch.number,
                            payBatchId:res[0].payBatch.id,
                            storeGroupBuyCodes:[],
                        };
                        for(var k=0; k<vm.data.storeGroupBuyPayBatches.length; k++){
                            if(vm.data.storeGroupBuyPayBatches[k].payBatchId == res[0].payBatch.id){
                                _index = k;
                                isNew = true;
                                _obj = vm.data.storeGroupBuyPayBatches[k];
                                break
                            }
                        }
                        for(var i=0; i<res.length; i++){
                            var _isTrue = false;
                            for(var j=0; j<_obj.storeGroupBuyCodes.length; j++){
                                if(_obj.storeGroupBuyCodes[j].entityId == res[i].id){
                                    _isTrue = true;
                                    break
                                }
                            }
                            if(!_isTrue){
                                var _obj1={
                                    entityId:res[i].id,
                                    entityCode:res[i].code,
                                    entityNumber:res[i].number,
                                    productName:res[i].payBatch.productName,
                                    codeType:"PayCode"
                                };
                                _obj.storeGroupBuyCodes.push(_obj1)
                            }
                        }
                        if(isNew){
                            vm.data.storeGroupBuyPayBatches[_index] = _obj;
                        }else {
                            vm.data.storeGroupBuyPayBatches.push(_obj)
                        }
                    })
                }
                //兑换码
                if(data.payBatchType == "EXCHANGECODE"){
                    ExchangeCodes.findByPayBatch({payBatchId:data.id}, function (res) {
                        if(res.length == 0){
                            return
                        }
                        var isNew = false;
                        var _index = "";
                        var _obj={
                            payBatchNumber:res[0].payBatch.number,
                            payBatchId:res[0].payBatch.id,
                            storeGroupBuyCodes:[],
                        };
                        for(var k=0; k<vm.data.storeGroupBuyPayBatches.length; k++){
                            if(vm.data.storeGroupBuyPayBatches[k].payBatchId == res[0].payBatch.id){
                                _index = k;
                                isNew = true;
                                _obj = vm.data.storeGroupBuyPayBatches[k];
                                break
                            }
                        }
                        for(var i=0; i<res.length; i++){
                            var _isTrue = false;
                            for(var j=0; j<_obj.storeGroupBuyCodes.length; j++){
                                if(_obj.storeGroupBuyCodes[j].entityId == res[i].id){
                                    _isTrue = true;
                                    break
                                }
                            }
                            if(!_isTrue){
                                var _obj1={
                                    entityId:res[i].id,
                                    entityCode:res[i].code,
                                    entityNumber:res[i].number,
                                    productName:res[i].payBatch.productName,
                                    codeType: "ExchangeCode"
                                };
                                _obj.storeGroupBuyCodes.push(_obj1)
                            }
                        }
                        if(isNew){
                            vm.data.storeGroupBuyPayBatches[_index] = _obj;
                        }else {
                            vm.data.storeGroupBuyPayBatches.push(_obj)
                        }
                        console.log(vm.data.storeGroupBuyPayBatches)
                    })
                }
            });
        }
        $scope.delete = function(item, index){
            if(item.storeGroupBuyCodes[index].orderNumber){
                return
            }
            item.storeGroupBuyCodes.splice(index, 1)
            if(item.storeGroupBuyCodes.length == 0){
                var pIndex = 0;
                for(var i=0; i<vm.data.storeGroupBuyPayBatches.length; i++){
                    if(vm.data.storeGroupBuyPayBatches[i].payBatchNumber == item.payBatchNumber){
                        pIndex = i;
                        break;
                    }
                }
                vm.data.storeGroupBuyPayBatches.splice(pIndex, 1)
            }
        }
        $scope.deleteAll = function(item, index){
            for(var i=vm.data.storeGroupBuyPayBatches.length-1; i>=0; i--){
                for(var j=vm.data.storeGroupBuyPayBatches[i].storeGroupBuyCodes.length-1; j>=0; j--){
                    if(vm.data.storeGroupBuyPayBatches[i].storeGroupBuyCodes[j].orderNumber){
                        return
                    }
                    if(vm.data.storeGroupBuyPayBatches[i].storeGroupBuyCodes[j].selected){
                        vm.data.storeGroupBuyPayBatches[i].storeGroupBuyCodes.splice(j, 1)
                        if(vm.data.storeGroupBuyPayBatches[i].storeGroupBuyCodes.length == 0){
                            vm.data.storeGroupBuyPayBatches.splice(i, 1)
                        }
                    }
                }
            }
        }
        $scope.payBatcheSelect = function(item){
            item.selected = !item.selected ? true : false
        }
        $scope.payBatcheSelectAll = function(){
            vm.isAllSelected = !vm.isAllSelected;
            for(var i=0; i<vm.data.storeGroupBuyPayBatches.length; i++){
                for(var j=0; j<vm.data.storeGroupBuyPayBatches[i].storeGroupBuyCodes.length; j++){
                    vm.data.storeGroupBuyPayBatches[i].storeGroupBuyCodes[j].selected = vm.isAllSelected;
                }
            }
        }

        //保存
        $scope.save = function () {
            if(!vm.data.goodsType){
                MessageService.error("商品类型不能为空");
                return
            }
            if(!vm.data.seq){
                MessageService.error("商品排序不能为空");
                return
            }
            if(!vm.data.name){
                MessageService.error("商品介绍不能为空");
                return
            }
            if(vm.data.goodsType == "REAL" && !vm.data.productId){
                MessageService.error("商品不能为空");
                return
            }
            if(vm.data.goodsType == "REAL" && !vm.data.itemValueLineId){
                MessageService.error("SKU不能为空");
                return
            }
            if(!vm.data.cover){
                MessageService.error("商品封面不能为空");
                return
            }
            if(!vm.data.storeGroupBuyBanners || vm.data.storeGroupBuyBanners.length === 0){
                MessageService.error("商品轮播图不能为空");
                return
            }
            if(!vm.data.detail){
                MessageService.error("商品详情图不能为空");
                return
            }
            if(!vm.data.price){
                MessageService.error("拼团价格不能为空");
                return
            }
            if(!vm.data.singlePrice){
                MessageService.error("原价不能为空");
                return
            }
            if(!vm.data.activityPerson || vm.data.activityPerson<2){
                MessageService.error("拼团人数必须大于1");
                return
            }

            if(!vm.data.stockQuantity && vm.data.goodsType != 'VIRTUAL'){
                MessageService.error("库存不能为空");
                return
            }
            if (vm.data.periodOfValidity !=null) {
                vm.data.periodOfValidity = DateUtils.convertLocalDateToServer(new Date(vm.data.periodOfValidity));
            }else {
                MessageService.error("拼团有效时间不能为空");
                return
            }
            if(vm.data.divideUpType == "AVERAGE" && !vm.data.divideUpMoney){
                MessageService.error("每人固定瓜分金额不能为空");
                return
            }
            if(vm.data.divideUpType == "RANDOM" && !vm.data.divideUpPercent){
                vm.data.divideUpPercent = "";
                MessageService.error("随机瓜分比例不能为空");
                return
            }
            if(vm.data.divideUpType == "MASTER" && !vm.data.divideUpPercent2){
                vm.data.divideUpPercent2 = "";
                MessageService.error("团长瓜分比例不能为空");
                return
            }
            if(vm.data.divideUpType == "MASTER" && !vm.data.masterDivideUp){
                vm.data.masterDivideUp = "";
                MessageService.error("开团人瓜分比例不能为空");
                return
            }
            if(vm.data.goodsType === "VIRTUAL" && (!vm.data.storeGroupBuyPayBatches || vm.data.storeGroupBuyPayBatches.length === 0)){
                MessageService.error("卡卷不能为空");
                return
            }
            if(!vm.isEdit){
                StoreGroupBuys.save(vm.data,function (res) {
                    MessageService.success("保存成功！");
                    $state.go("groupCommodity");
                }, function (error) {
                    MessageService.error("数据失败，请稍后重试！");
                })
            }else {
                StoreGroupBuys.update(vm.data,function (res) {
                    MessageService.success("保存成功！");
                    $state.go("groupCommodity");
                }, function (error) {
                    MessageService.error("数据失败，请稍后重试！");
                })
            }
        }



        function dateformat () {
            return 'yyyy-MM-dd';
        }
        function openCalendar (item) {
            vm.isPeriodOfValidity = true;
        }
    }
})();
