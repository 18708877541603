(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RegionTempController', RegionTempController);

    RegionTempController.$inject = ['$state', 'RegionTemp', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 
    '$scope', 'MessageService', '$stateParams'];

    function RegionTempController($state, RegionTemp, ParseLinks, AlertService, paginationConstants, pagingParams,
     $scope, MessageService, $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        vm.tempArr =[];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll =function(){
            $scope.selectAll = !$scope.selectAll;
            angular.forEach(vm.regions,function(item){
                item.select = $scope.selectAll;
            });
            vm.tempArr = vm.regions.filter(function(item){
                return item.select == true;
            });
        };

        $scope.chooseOne = function(data){
            data.select = !data.select;
            $scope.selectAll = vm.regions.every(function(item){
                return item.select;
            });
            vm.tempArr = vm.regions.filter(function(item){
                return item.select == true;
            });
        };


        loadAll();

        function loadAll () {
            var items= [];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search})
            }  
            RegionTemp.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
         
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.regions = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("区域获取失败");
            }
        }

         $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                RegionTemp.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        $scope.deleteRegion = function(){
            var ids = vm.tempArr.map(function(item){return item.id}).join(',');
            MessageService.confirm("确认要删除该运费么",function(){
                RegionTemp.delBatch({ids: ids},function () {
                    loadAll();
                });
            },function(){},true);
        };


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }
    }
})();
