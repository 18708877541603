(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('FilterOrderRecords', FilterOrderRecords)
        .factory('FilterPrices', FilterPrices);

    FilterOrderRecords.$inject = ['$resource', 'DateUtils'];
    FilterPrices.$inject = ['$resource', 'DateUtils'];

    function FilterOrderRecords ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/filter-order-records/:path/:id';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                params: {
                    path: 'createOrderRecord'
                }
            },
            'aliPay': {
                method: 'GET',
                params: {
                    path: 'aliPay'
                }
            },
            'wechatPay': {
                method: 'POST',
                params: {
                    path: 'wechatPay'
                }
            },
            'payByBalance': {
                method: 'POST',
                params: {
                    path: 'payByBalance'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "resolve":{
                method:"GET",
                params:{
                    path:"resolve"
                },
                isArray: false
            },
            "byStore":{
                method:"GET",
                params:{
                    path:"byStore"
                },
                isArray: true
            },
            "unresolved":{
                method:"POST",
                params:{
                    path:"unresolved"
                },
                isArray: false
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function FilterPrices ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/filter-prices/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getByStore":{
                method:"GET",
                params:{
                    path:"getByStore"
                }
            },
            "resolve":{
                method:"GET",
                params:{
                    path:"resolve"
                },
                isArray: false
            },
            "unresolved":{
                method:"POST",
                params:{
                    path:"unresolved"
                },
                isArray: false
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
