(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SubstitutionController', SubstitutionController);

    SubstitutionController.$inject = ['$scope', '$state', 'Substitution', 'MessageService', 'pagingParams', 'ListService','Principal',
    'ParseLinks', 'paginationConstants', 'DateUtils', 'SubstitutionSettings', '$q', '$uibModal', 'StoreUser'];

    function SubstitutionController ($scope, $state, Substitution,  MessageService, pagingParams, ListService,Principal,
        ParseLinks, paginationConstants, DateUtils, SubstitutionSettings, $q, $uibModal, StoreUser) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_RGASSIGN').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.users = StoreUser.getAllByCondition({items:[{key:"role.authorities.code", op:"=", value:"TASK_LIST"}]});

        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.status = pagingParams.status;
        vm.userId = pagingParams.userId?Number(pagingParams.userId):null;
        vm.startTime = pagingParams.startTime ? new Date(pagingParams.startTime):null;
        vm.endTime = pagingParams.endTime? new Date(pagingParams.endTime):null;
        vm.categoryId = pagingParams.categoryId?Number(pagingParams.categoryId):null;
        vm.productId = pagingParams.productId?Number(pagingParams.productId):null;

        vm.isOpen =false;
        $scope.selectTab ='';
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.selectedAll = false;
        vm.selectData = [];
        vm.categories = [];
        vm.products = [];

        $scope.refund = function(id){
            MessageService.confirm("确认该单据要执行退款操作？", function(){
                Substitution.payCancel({id:id}, function(data){
                    if (data.status == 200) {
                        MessageService.success("退款成功");
                        loadAll ();
                    }else{
                        MessageService.error("退款失败，请稍后再试");
                    }
                    
                }, function(){
                    MessageService.error("退款失败，请稍后再试");
                })
            }, function(){})
        }

        $scope.rejectRefund = function(id){
            MessageService.confirm("确认要拒绝该退款申请？", function(){
                Substitution.rejectRefund({id:id}, function(data){
                    if (data.status == 200) {
                        MessageService.success("拒绝成功");
                        loadAll ();
                    }else{
                        MessageService.error(data.message);
                    }
                    
                }, function(){
                    MessageService.error("退款失败，请稍后再试");
                })
            }, function(){})
        }

        function init (){
            // 商品品类
            SubstitutionSettings.getByStore({}, function (res) {
                angular.forEach(res.substitutionSettingLines, function (line) {
                    var category = {
                        id:line.categoryId,
                        name:line.categoryName,
                        products:[]
                    }
                    var result = ListService.inList(category, vm.categories, ["id"]);
                    if (!result) {
                        category.products.push({id:line.productId, name:line.productName});
                        vm.categories.push(category);
                     }else{
                        var p = {id:line.productId, name:line.productName};
                        var pResult = ListService.inList(p, result.products, ["id"]);
                        if (!pResult) {
                            result.products.push(p);
                        }
                     }
                })
                if (vm.categoryId != null && vm.categories != null) {
                    for (var i = vm.categories.length - 1; i >= 0; i--) {
                        var c = vm.categories[i];
                        if (c.id == vm.categoryId) {
                            vm.products = c.products;
                            break;
                        }
                    }
                    
                }
            })
        }

        $scope.changeCategory = function(){
            vm.products = [];
            if (vm.categories == null ) {
                return;
            }
            for (var i = vm.categories.length - 1; i >= 0; i--) {
                var category = vm.categories[i];
                if (category.id == vm.categoryId) {
                    vm.products = category.products;
                    return;
                }
            }
        }
        
        $scope.selectedAllCb = function(){
            vm.selectedAll = !vm.selectedAll;
            for(var i=0; i<vm.substitutions.length; i++){
                vm.substitutions[i].selected = vm.selectedAll;
            }
            substitutionsInit()
        };
        $scope.selectedOne = function(item){
            item.selected = !item.selected;
            substitutionsInit();
        };
        function substitutionsInit(){
            vm.selectData = [];
            for(var i=0; i<vm.substitutions.length; i++){
                if(vm.substitutions[i].status == 'CHECKCONFIRM'){
                    if(vm.substitutions[i].selected){
                        vm.selectData.push(vm.substitutions[i].id);
                    }
                }else{
                    vm.substitutions[i].selected =  false;
                }
            }
        }

        $scope.allocation = function(id){
            vm.selectData = [];
            vm.selectData.push(id)
            substitutionDialog()
        }
        $scope.allocationAll = function () {
            substitutionDialog()
        }
        function substitutionDialog(){
            if(vm.selectData.length > 0){
                $uibModal.open({
                    templateUrl: 'app/entities/substitution/substitution-dialog.html',
                    controller: 'SubstitutionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return vm.selectData;
                        }]
                    }
                }).result.then(function(data) {
                    if(data){
                        loadAll();
                        vm.selectData = [];
                    }
                });
            }
        }

        $scope.resetInfo = function () {
            vm.startTime = "";
            vm.endTime = "";
            vm.categoryId = "";
            vm.userId = "";
            vm.productId = "";
            vm.sourceType = "";
            vm.products = [];
        };

        if(vm.status == '' || vm.status == undefined ){
            $scope.selectTab = '';
        }else{
            $scope.selectTab = vm.status;
        }

        $scope.byStatus =function(status){
            if(status == '' || status == undefined){
                $scope.selectTab = '';
            }else {
                $scope.selectTab = status;
            }
            vm.status = status;
            search();
        };


        $scope.shrink = function(){
            vm.isOpen = !vm.isOpen;
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        } 
        $scope.screens = function(){
            search();
        };

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        } 


        function getItems() {
            var items=[];
            if (vm.endTime !=null) {
                items.push({key: "createdDate", op: "<=", value: new Date(Date.parse(vm.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            };
            if (vm.startTime !=null) {
                items.push({key: "createdDate", op: ">=", value: new Date(Date.parse(vm.startTime) -8 * 60 * 60 * 1000)});
            };
            //商品分类
            if (vm.categoryId !=null) {
                items.push({key:"categoryId",op:"=",value:vm.categoryId});
            };
            //商品名称
            if (vm.productId !=null) {
                items.push({key:"productId",op:"=",value:vm.productId});
            };
             
            if (vm.status !=null) {
                items.push({key:"status",op:"=",value:vm.status});
            }
            if (vm.userId !=null) {
                items.push({key:"assignTo",op:"=",value:vm.userId});
            }
            vm.userId
            
            return items;
        }


        function loadAll () {
            var items = getItems();
            if (pagingParams.search) {
                items.push({key:"number,productName",op:"like",value:pagingParams.search});
            } 
            Substitution.byCondition2({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return ["createdDate,desc"];
            }
        }


        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.allPagesCount = Math.ceil(vm.totalItems/20);
            vm.queryCount = vm.totalItems;
            vm.substitutions = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("获取数据失败");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

         function transition() {
            $state.transitionTo($state.$current, {
               page: vm.page,
               sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
               search: vm.currentSearch,
               status:vm.status,
               startTime: vm.startTime?DateUtils.convertLocalDateToServer(vm.startTime):"",
               endTime: vm.endTime?DateUtils.convertLocalDateToServer(vm.endTime):"",
               productId:vm.productId,
               categoryId:vm.categoryId,
               userId:vm.userId
            });
        }

        loadAll();
        init();
         
    }
})();
