(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShareTheHotBotController', ShareTheHotBotController);

        ShareTheHotBotController.$inject = ['$scope', 'HotWords', '$timeout', '$q','MessageService'];

    function ShareTheHotBotController($scope, HotWords, $timeout, $q, MessageService) {
        var vm = this;
        getStoreHotBot()
        vm.systemHotBot = []
        vm.customHotBot = []
        vm.addHotBotInput = false;
        var addHotBotInput = $('#addHotBotInput');
        vm.addHotBotValue = '';


        function getStoreHotBot(){
            HotWords.store({},function(res){
                vm.customHotBot = res.custom;
                vm.systemHotBot = res.platform;
            })
        }

        $scope.addHotBot = function(){
            vm.addHotBotInput = true;
            $timeout(function(){
                addHotBotInput.focus();
            })
        }

        $scope.addHotBotInput = function($event){
            var theEvent = window.event || $event;
            var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
            if (code == 13) {
                if (vm.addHotBotValue) {
                    HotWords.add({name:vm.addHotBotValue},function(res){
                        console.log(res);
                        if (res.status == 200) {
                            vm.addHotBotInput = false;
                            vm.addHotBotValue = '';
                            getStoreHotBot();
                            MessageService.success("添加成功");
                        }else{
                            MessageService.error("添加失败");
                        }

                    })
                }
            }
        }

        $scope.blurAddHotBotValue = function(){
            if (!vm.addHotBotValue) {
                vm.addHotBotInput = false;
            }
        }

        $scope.startStopHotBot = function(HotBot){
            HotWords.enabled({id:HotBot.id},function(res){
                if (res.status == 200) {
                    getStoreHotBot();
                    MessageService.success("修改成功");
                }else{
                    MessageService.error("修改失败");
                }
            })
        }

        $scope.deleteHotBot = function(HotBot){
            HotWords.delete({id:HotBot.id},function(res){
                if (res.status == 200) {
                    getStoreHotBot();
                    MessageService.success("删除成功");
                }else{
                    MessageService.error("删除失败");
                }
            })
        }
    }
})();