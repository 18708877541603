(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('register1', {
                parent: 'app2',
                url: '/register1',
                data: {
                    authorities: [],
                    pageTitle: 'register.title'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/account/register/register.html',
                        controller: 'RegisterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register');
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('storeType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('register_info', {
                parent: 'register',
                url: '/register_info',
                data: {
                    authorities: [],
                    pageTitle: 'register.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/account/register/register_info.html',
                        controller: 'RegisterInfoController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('register');
                                $translatePartialLoader.addPart('store');
                                $translatePartialLoader.addPart('storeType');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('register', {}, { reload: false });
                    }, function() {
                        $state.go('register');
                    });
                }]
            });
    }
})();
