(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pay-batch', {
            parent: 'entity',
            url: '/pay-batch?page&sort&search&assign',
            data: {
                authorities: ['ROLE_STORE_BATCHNUM'],
                pageTitle: 'backApp.payBatch.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-batch/pay-batches.html',
                    controller: 'PayBatchController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        assign:$stateParams.assign
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payBatch');
                    $translatePartialLoader.addPart('rangeType');
                    $translatePartialLoader.addPart('payCodeType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pay-batch.detail', {
            parent: 'pay-batch',
            url: '/{id}/detail',
            data: {
                authorities: ['ROLE_STORE_BATCHNUM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-batch/pay-batch-detail.html',
                    controller: 'PayBatchDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('payBatch');
                            $translatePartialLoader.addPart('payCode');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('payCodeType');
                            return $translate.refresh();
                        }],
                        entity: ['PayBatch', function(PayBatch) {
                            return PayBatch.get({
                                id: $stateParams.id
                            }).$promise;
                        }],
                    }
                }).result.then(function() {
                    $state.go('pay-batch', null, {
                        reload: 'pay-batch'
                    });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('pay-batch.new', {
            parent: 'pay-batch',
            url: '/new?type',
            data: {
                authorities: ['ROLE_STORE_BATCHNUM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                    controller: 'PayBatchDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                storeId: null,
                                storeName: null,
                                productId: null,
                                productName: null,
                                activated: null,
                                supplier: null,
                                periodOfValidity: null,
                                startDate: null,
                                used: null,
                                cancelled: null,
                                range: null,
                                type: null,
                                startNumber: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('pay-batch', null, { reload: 'pay-batch' });
                }, function() {
                    $state.go('pay-batch');
                });
            }]
        })
        .state('pay-batch.new2', {
                parent: 'pay-batch',
                url: '/new2?type',
                data: {
                    authorities: ['ROLE_STORE_BATCHNUM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                        controller: 'PayBatchDialogStoreSelfController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    number: null,
                                    storeId: null,
                                    storeName: null,
                                    productId: null,
                                    productName: null,
                                    activated: null,
                                    supplier: null,
                                    periodOfValidity: null,
                                    startDate: null,
                                    used: null,
                                    cancelled: null,
                                    range: null,
                                    type: null,
                                    startNumber: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('pay-batch', null, { reload: 'pay-batch' });
                    }, function() {
                        $state.go('pay-batch');
                    });
                }]
            })
        .state('pay-batch.edit', {
            parent: 'pay-batch',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_BATCHNUM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                    controller: 'PayBatchDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PayBatch', function(PayBatch) {
                            return PayBatch.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pay-batch', null, { reload: 'pay-batch' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pay-batch.edit2', {
            parent: 'pay-batch',
            url: '/{id}/edit2',
            data: {
                authorities: ['ROLE_STORE_BATCHNUM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-batch/pay-batch-update.html',
                    controller: 'PayBatchDialog2Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PayBatch', function(PayBatch) {
                            return PayBatch.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pay-batch', null, { reload: 'pay-batch' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pay-batch.editStoreSelf', {
                parent: 'pay-batch',
                url: '/{id}/editStoreSelf',
                data: {
                    authorities: ['ROLE_STORE_BATCHNUM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/pay-batch/pay-batch-dialog.html',
                        controller: 'PayBatchDialogStoreSelfController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PayBatch', function(PayBatch) {
                                return PayBatch.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('pay-batch', null, { reload: 'pay-batch' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
