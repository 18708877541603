(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderPicViewController', SalesOrderPicViewController);

    SalesOrderPicViewController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'SalesOrder', 'Qinius',
     'History', '$state', 'MessageService', 'Convert'];

    function SalesOrderPicViewController($scope, $q, $rootScope, $stateParams, $uibModalInstance, entity, SalesOrder, Qinius,
     History, $state, MessageService, Convert) {
        var vm = this;

        vm.salesOrder = entity;
        vm.clear = clear;
        vm.pics = [];
        vm.orderId = $stateParams.id;
        vm.domains = Qinius.getDomain();

        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        });


        $scope.downloadIamge = function(url) { 
            var x=new XMLHttpRequest();
            x.open("GET", url, true);
            x.responseType = 'blob';
            x.onload=function(e){
                var url = window.URL.createObjectURL(x.response)
                var a = document.createElement('a');
                a.href = url
                a.download = ''
                a.click()
            }
            x.send();
        }


        Convert.xmlToDocument({
            identifier: $stateParams.fileName,
            provider: 'qiniu'
        }, function (res) {
            vm.artDoc = res;
            if (res && res.pages && res.pages.page) {
                for (var i = res.pages.page.length - 1; i >= 0; i--) {
                    var p = res.pages.page[i];
                    if (p.backup) {
                        continue;
                    }
                    if (p.levels && p.levels.level) {
                        for (var j = p.levels.level.length - 1; j >= 0; j--) {
                            var l = p.levels.level[j];
                            if ( l.imagebox  && l.imagebox.image  && l.imagebox.image.resource && l.imagebox.image.resource.identifier) {
                                vm.pics.push(l.imagebox.image.resource.identifier);
                            }
                        }
                    }
                }
            }
        });

        function clear () {
            $(".listBGC").show();
            $(".modal-content").show();
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('backApp:salesOrderUpdate', function(event, result) {
            vm.salesOrder = result;
        });
        $scope.$on('$destroy', unsubscribe);
  

    }
})();
