(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('property', {
            parent: 'entity',
            url: '/property?page&sort&search',
            data: {
                authorities: ["ROLE_PROPERTY"],
                pageTitle: 'backApp.property.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property/properties.html',
                    controller: 'PropertyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('property');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('property-detail', {
                parent: 'property',
                url: '/property/{id}',
                data: {
                    authorities: ["ROLE_PROPERTY"]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/property/property-detail.html',
                        controller: 'PropertyDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('property');
                                return $translate.refresh();
                            }],
                            entity: ['Property', function(Property) {
                                return Property.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('property', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('property');
                    });
                }]
            })
        .state('property-detail.edit', {
            parent: 'property-detail',
            url: '/detail/edit',
            data: {
                authorities: ["ROLE_PROPERTY"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/property/property-dialog.html',
                    controller: 'PropertyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Property', function(Property) {
                            return Property.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('property.new', {
            parent: 'property',
            url: '/new',
            data: {
                authorities: ["ROLE_PROPERTY"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/property/property-dialog.html',
                    controller: 'PropertyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                cancelled: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('property', null, { reload: 'property' });
                }, function() {
                    $state.go('property');
                });
            }]
        })
        .state('property.edit', {
            parent: 'property',
            url: '/{id}/edit',
            data: {
                authorities: ["ROLE_PROPERTY"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/property/property-dialog.html',
                    controller: 'PropertyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Property', function(Property) {
                            return Property.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('property', null, { reload: 'property' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('property.delete', {
            parent: 'property',
            url: '/{id}/delete',
            data: {
                authorities: ["ROLE_PROPERTY"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/property/property-delete-dialog.html',
                    controller: 'PropertyDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Property', function(Property) {
                            return Property.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('property', null, { reload: 'property' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
