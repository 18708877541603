(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductController', ProductController);

    ProductController.$inject = ['$scope', '$q', '$state', 'Product', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', 'DateUtils','Principal','$uibModal'];

    function ProductController($scope, $q, $state, Product, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, DateUtils,Principal,$uibModal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_PRODUCTMANAGEMENT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.domains = Qinius.getDomain();

        $q.all([vm.domains.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        $scope.delete = function (id) {
            MessageService.confirm("entity.action.deleteConfirm", function () {
                Product.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            }, true);
        }

        $scope.updateAllSaleable = function(){
            MessageService.confirm("确认要一键上架所有商品？", function () {
                Product.updateAllSaleable({}, function () {
                    loadAll();
                });
            }, function () {
            });
        }

        $scope.updateSaleable = function (product) {
            if(product.saleable){
                MessageService.confirm("确认要下架当前商品吗？", function () {
                    Product.updateSaleable({id: product.id, saleable: !product.saleable}, function (data) {
                        if (data.status == 200) {
                            MessageService.success("下架成功");
                        }else{
                            MessageService.error(data.message);
                        }
                        loadAll();
                    },function (error) {
                    });
                }, function () {
                });
            } else {
                if ( !!product.periodOfValidity && DateUtils.convertLocalDateFromServer(product.periodOfValidity)  < new Date()) {
                    MessageService.error("商品过期，不可销售");
                    return;
                }
                if (product.prototype == "Store") {
                    Product.get({id: product.protoProductId}, function (res) {
                        if(res.saleable){
                            Product.updateSaleable({id: product.id, saleable: !product.saleable}, function (data) {
                                if (data.status == 200) {
                                    MessageService.success("销售成功");
                                }else{
                                    MessageService.error(data.message);
                                }
                                loadAll();
                            },function (error) {
                            });
                        }else {
                            MessageService.error("当前商品不可销售");
                        }
                    })
                } else {
                    Product.updateSaleable({id: product.id, saleable: !product.saleable}, function (data) {
                        if (data.status == 200) {
                            MessageService.success("销售成功");
                        }else{
                            MessageService.error(data.message);
                        }
                        loadAll();
                    },function (error) {
                    });
                }
            }
        }
        $scope.copyProduct = function (product){
            $uibModal.open({
                templateUrl: 'app/entities/product/modal/copy-product-modal.html',
                controller: 'CopyProductModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return product
                    }
                }
            }).result.then(function(res){
                if(res){
                    loadAll();
                }
            });
        }


        function loadAll() {
            if (pagingParams.search) {
                Product.byCondition({
                    items: [{key: "name", op: "like", value: pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Product.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("产品获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image").removeClass('hide1');
            $(".modal-content").addClass('opacity-zero');
            $(".origin-image img").attr('src', pic);
        }

        $scope.backTo = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        };

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStart = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOn, true);
            document.addEventListener("mouseup", moveImgEnd, true);
        }

        //move事件
        function moveImgOn(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEnd() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOn, true);
            document.removeEventListener("mouseup", moveImgEnd, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

    }
})();
