(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('PlatformALiSetting', PlatformALiSetting)
        .factory('AliMiniAppPurchases', AliMiniAppPurchases)
        .factory('StoreAliSetting', StoreAliSetting);

    StoreAliSetting.$inject = ['$resource', 'DateUtils'];
    AliMiniAppPurchases.$inject = ['$resource', 'DateUtils'];

    PlatformALiSetting.$inject = ['$resource', 'DateUtils'];

    function PlatformALiSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/platform-ali-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expiresTime = DateUtils.convertLocalDateFromServer(data.expiresTime);
                        data.reExpiresTime = DateUtils.convertLocalDateFromServer(data.reExpiresTime);
                    }
                    return data;
                }
            },
            'getOne': {
                method: 'GET',
                params: {
                    path: 'getOne'
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.expiresTime = DateUtils.convertLocalDateToServer(copy.expiresTime);
                    copy.reExpiresTime = DateUtils.convertLocalDateToServer(copy.reExpiresTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.expiresTime = DateUtils.convertLocalDateToServer(copy.expiresTime);
                    copy.reExpiresTime = DateUtils.convertLocalDateToServer(copy.reExpiresTime);
                    return angular.toJson(copy);
                }
            }
        });
    }

    function AliMiniAppPurchases ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/ali-mini-app-purchases/:path/:id';

        return $resource(resourceUrl, {}, {
            'findByCommodityOrderId': {
                method: 'GET',
                params: {
                    path: 'findByCommodityOrderId'
                }
            },
        })
    }
    function StoreAliSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-ali-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getOneByStoreId': {
                method: 'GET',
                params: {
                    path: 'getOneByStoreId'
                }
            },
            'updateKey': {
                method: 'POST',
                params: {
                    path: 'updateKey'
                }
            },
            'bindApp': {
                method: 'GET',
                params: {
                    path: 'bindApp'
                }
            },
            'auth': {
                method: 'GET',
                params: {
                    path: 'auth'
                }
            },
            "agentCreate":{
                method:'POST',
                params: {
                    path: 'agentCreate'
                }
            },
            "notify":{
                method:'get',
                params: {
                    path: 'notify'
                }
            },
             "cancelAgent":{
                method:'get',
                params: {
                    path: 'cancel'
                }
            },
            "categoryQuery":{
                method:'get',
                params: {
                    path: 'categoryQuery'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expiresTime = DateUtils.convertDateTimeFromServer(data.expiresTime);
                        data.refreshTokenExpiresTime = DateUtils.convertDateTimeFromServer(data.refreshTokenExpiresTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' }
        });
    }
})();
