(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('CommandDialogImportController', CommandDialogImportController);

    CommandDialogImportController.$inject = ['$timeout', '$scope','UidService', 'MessageService', 'entity', 'Command', 'Qinius', '$state', '$stateParams', '$q',
     'Upload', 'DateUtils','Store', 'CommandBatch', 'PasswordActivity'];

    function CommandDialogImportController($timeout, $scope,UidService, MessageService, entity, Command, Qinius, $state, $stateParams, $q,
     Upload, DateUtils,Store, CommandBatch, PasswordActivity) {
        var vm = this;
        vm.command = entity;
        vm.command.passwordActivityId = $stateParams.acId;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.passwordActivity = PasswordActivity.get({id:vm.command.passwordActivityId});
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();

        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.command.$promise,vm.store.$promise]).then(function () {
            vm.commandBatches = CommandBatch.getAllByCondition({items:[
                {key:"brand.id", op :"=", value:vm.passwordActivity.brandId},
                {key:"product.id", op :"=", value:vm.passwordActivity.productId}]}
                );

            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        });

        $scope.commandBatchChange = function(){
            var batch = $scope.getBatch();
            if (vm.command.commandBatchId && batch) {
                vm.command.type = batch.type;
                vm.command.usedType = batch.usedType;
                vm.command.dateEnable = batch.dateEnable;
                vm.command.endDate = DateUtils.convertDateTimeFromServer(batch.endDate) ;
                vm.command.startDate = DateUtils.convertDateTimeFromServer(batch.startDate) ;
            }else{
                vm.command.type = null;
                vm.command.usedType = null;
                vm.command.dateEnable = null;
                vm.command.endDate = null;
                vm.command.startDate = null;
            }
        }

        $scope.getBatch = function(){
            for (var i = vm.commandBatches.length - 1; i >= 0; i--) {
                var batch = vm.commandBatches[i];
                if (batch.id == vm.command.commandBatchId) {
                    return batch;
                }
            }
            return null;
        }


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("command", {acId: vm.command.passwordActivityId}, {reload: true})
        }

        $scope.valadata = function () {
            if (vm.command.dateEnable&&(vm.command.startDate == null || vm.command.startDate == "")) {
                MessageService.error("请选择开始时间");
                return false;
            }

            if (vm.command.adEnable&&(vm.command.adImage == null || vm.command.adImage == "")) {
                MessageService.error("请上传广告页");
                return false;
            }
            return true;
        }


        function save() {
            if (!$scope.valadata()) {
                return false;
            }
            vm.isSaving = true;
            if (vm.command.id !== null) {
                Command.update(vm.command, onSaveSuccess, onSaveError);
            } else {
                Command.save(vm.command, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:commandUpdate', result);
            // $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $state.go("command", {acId: vm.command.passwordActivityId}, {reload: true})
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'back-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.command.adImage = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("广告页上传失败");
                }
            });
        }

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        $scope.deletePic = function () {
            vm.command.adImage = null;
        }

        $scope.import = function () {
            if (vm.command.startDate == null || vm.command.startDate == "") {
                MessageService.error("请选择开始时间后再重新导入");
                return;
            }
            // if (vm.command.endDate == null || vm.command.endDate == "") {
            //     MessageService.error("请选择结束时间后再重新导入");
            //     return;
            // }

            var data =  {
                storeId: vm.store.id,
                file: vm.file,
                name: vm.fileName,
                passwordActivityId: vm.command.passwordActivityId,
                adEnable: vm.command.adEnable,
                enable: vm.command.enable,
                adImage: vm.command.adImage,
                commandBatchId: vm.command.commandBatchId,
                usedType:vm.command.usedType,
                dateEnable: vm.command.dateEnable,
                type:vm.command.type
            }
            if (vm.command.startDate != null && vm.command.startDate != "") {
               data.startDate =  DateUtils.convertLocalDateToServer(vm.command.startDate);
            }
            if (vm.command.endDate != null && vm.command.endDate != "") {
               data.endDate =  DateUtils.convertLocalDateToServer(vm.command.endDate);
            }


            Upload.upload({
                type: 'POST',
                url: 'manager/api/commands/importData', //上传路径
                data: data
            }).then(function successCallback(res) {
                if (res.status && res.status == 200) {
                    CommandBatch.used({id:vm.command.commandBatchId});
                    MessageService.success("保存成功！");
                    $state.go("command", {acId: vm.command.passwordActivityId} , {reload:true})
                }
            }, function errorCallback(response) {

                MessageService.error("文件导入，连接超时， 请稍后刷新页面确认是否成功");
            });
        }

        $scope.uploadFile = function (fileWatched) {
            vm.file = fileWatched;
            vm.fileName = fileWatched.name;

        };
    }
})();
