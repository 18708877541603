(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('lineToTheController', lineToTheController);

    lineToTheController.$inject = ['$scope', '$q', '$state', '$rootScope', 'StorePickLocations','Store'];

    function lineToTheController($scope, $q, $state, $rootScope,StorePickLocations,Store) {
        var vm = this;
        //自提地点
        vm.store=Store.getCurrent()
        vm.storePickLocations = [];
        getStorePickLocation();
        function getStorePickLocation() {
            StorePickLocations.getAllByCondition({ items: [] }, function (res) {
                vm.storePickLocations = res;
            })
        }
    }
})();
