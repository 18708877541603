(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreWithdrawalSettingDetailController', StoreWithdrawalSettingDetailController);

    StoreWithdrawalSettingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StoreWithdrawalSetting', 'Store','Principal','MessageService','$state'];

    function StoreWithdrawalSettingDetailController($scope, $rootScope, $stateParams, entity, StoreWithdrawalSetting, Store,Principal,MessageService,$state) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.store = Store.getCurrent({});
        vm.storeWithdrawalSetting = entity;

        var unsubscribe = $rootScope.$on('backApp:storeWithdrawalSettingUpdate', function(event, result) {
            vm.storeWithdrawalSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
