(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreAliSettingDialogController', StoreAliSettingDialogController);

    StoreAliSettingDialogController.$inject = ['$timeout', '$scope', 'UidService', '$stateParams', 'MessageService', 'entity', 'StoreAliSetting', '$state', 
    'Qinius', '$q', 'PlatformALiSetting', 'Store'];

    function StoreAliSettingDialogController ($timeout, $scope, UidService, $stateParams, MessageService, entity, StoreAliSetting, $state, 
        Qinius, $q, PlatformALiSetting, Store) {
        var vm = this;

        vm.storeAliSetting = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.tokens = Qinius.get();
         vm.store = Store.getCurrent();
        vm.platformALiSetting = PlatformALiSetting.getOne();

        $q.all([  vm.tokens.$promise, ]).then(function () {
            vm.token = vm.tokens.token;
        });


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go("store-ali-setting");
        }

        window.upload = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        $scope.removeFile = function(){
            vm.storeAliSetting.appLogo = null;
        }

        function qiniuUpload(file) {
            if (file.size/1024 > 256) {
                MessageService.error("logo文件不能超过256kb");
                return ;
            }
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'logo-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.storeAliSetting.appLogo = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }



        $scope.valadata = function(){
            if (vm.storeAliSetting.account == null || vm.storeAliSetting.account == "") {
                MessageService.error("请填入商户账号")
                return false;
            }

            if (vm.storeAliSetting.contactName == null || vm.storeAliSetting.contactName == "") {
                MessageService.error("请填入商户联系人")
                return false;
            }

            if (vm.storeAliSetting.contactMobile == null || vm.storeAliSetting.contactMobile == "") {
                MessageService.error("请填入商户联系方式")
                return false;
            }

            if (vm.storeAliSetting.appName == null || vm.storeAliSetting.appName == "") {
                MessageService.error("请填入小程名称")
                return false;
            }

            if (vm.storeAliSetting.appEnglishName == null || vm.storeAliSetting.appEnglishName == "") {
                MessageService.error("请填入小程英文名称")
                return false;
            }

            if (vm.storeAliSetting.appSlogan == null || vm.storeAliSetting.appSlogan == "") {
                MessageService.error("请填入小程序简介")
                return false;
            }

            if (vm.storeAliSetting.servicePhone == null || vm.storeAliSetting.servicePhone == "") {
                MessageService.error("请填入小程序客服联系方式")
                return false;
            }
            if (vm.storeAliSetting.serviceEmail == null || vm.storeAliSetting.serviceEmail == "") {
                MessageService.error("请填入小程序客服邮箱")
                return false;
            }

            if (vm.storeAliSetting.appDesc == null || vm.storeAliSetting.appDesc == "") {
                MessageService.error("请填入小程序描述")
                return false;
            }

            return true;
        }

        function save () {
            // if (!$scope.valadata()) {
            //     return;
            // }
            vm.isSaving = true;
            if (vm.storeAliSetting.id == null) {
                StoreAliSetting.save(vm.storeAliSetting, onSaveSuccess, onSaveError);
            }else{
                 StoreAliSetting.update(vm.storeAliSetting, onSaveSuccess, onSaveError);
            }

        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:storeAliSettingUpdate', result);
            MessageService.success("保存成功");
            $state.go("store-ali-setting",{}, {reload: true});
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }

        vm.datePickerOpenStatus.expiresTime = false;
        vm.datePickerOpenStatus.refreshTokenExpiresTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
