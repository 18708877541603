(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('BlendentModalController', BlendentModalController);

    BlendentModalController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity'];

    function BlendentModalController ($timeout, $scope, $stateParams, $uibModalInstance, entity) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.modelData = entity.modelData;
        vm.modelPath = entity.modelPath;
        vm.modelData1 = angular.copy(vm.modelData);

        $scope.colorChange = function () {
            vm.modelData1 = angular.copy(vm.modelData);
        }
        //保存
        function save() {
            $uibModalInstance.close(vm.modelData);
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();
