(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('mapDepot', {
            parent: 'entity',
            url: '/mapDepot',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepot'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot.html',
                    controller: 'MapDepotController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('mapDepotRecord', {
            parent: 'mapDepot',
            url: '/mapDepotRecord',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepot'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot-record.html',
                    controller: 'MapDepotRecordController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('mapDepotSettle', {
            parent: 'mapDepot',
            url: '/{id}/mapDepotSettle',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepot'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot-settle.html',
                    controller: 'MapDepotSettleController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                
            }
        })
        .state('mapDepotStatistice', {
            parent: 'mapDepot',
            url: '/mapDepotStatistice',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepot'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot-statistics.html',
                    controller: 'MapDepotStatisticeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                
            }
        })
        .state('mapDepotClear', {
            parent: 'mapDepot',
            url: '/mapDepotClear',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepotClear'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot-clear.html',
                    controller: 'MapDepotClearController',
                    controllerAs: 'vm'
                }
            },
        })
        .state('mapDepotClearRecord', {
            parent: 'mapDepot',
            url: '/mapDepotClearRecord',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepotClearRecord'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot-clear-record.html',
                    controller: 'MapDepotClearRecordController',
                    controllerAs: 'vm'
                }
            },
        })
        .state('customerPurchaseRecord', {
            parent: 'mapDepot',
            url: '/customerPurchaseRecord',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.customerPurchaseRecord'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/customer-purchase-record.html',
                    controller: 'CustomerPurchaseRecordController',
                    controllerAs: 'vm'
                }
            },
        })
        .state('mapDepotProtocol', {
            parent: 'mapDepot',
            url: '/mapDepotProtocol',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepotProtocol'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot-protocol.html',
                    controller: 'MapDepotProtocolController',
                    controllerAs: 'vm'
                }
            },
        })
        .state('mapDepotProtocolEdit', {
            parent: 'mapDepot',
            url: '/mapDepotProtocolEdit',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.mapDepotProtocol'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/map-depot/map-depot-protocol-edit.html',
                    controller: 'MapDepotProtocolController',
                    controllerAs: 'vm'
                }
            },
        })
    }

})();
