(function() {
    'use strict';
    angular
        .module('backApp')
        .controller('GoodsShelfDetailController', GoodsShelfDetailController);

    GoodsShelfDetailController.$inject = ['$timeout', '$scope', 'MessageService', 'entity', 'Producer', '$state', 'Product', '$q', 'ProducerManagement', 'GoodsShelf'];

    function GoodsShelfDetailController($timeout, $scope, MessageService, entity, Producer, $state, Product, $q, ProducerManagement, GoodsShelf) {
        var vm = this;

        vm.goodsShelf = entity;
        vm.clear = clear;
        vm.save = save;

        vm.producers = Producer.getAllByCondition({});

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('goods-shelf', null, { reload: 'goods-shelf' });
        }

        $scope.validate = function() {
            if (vm.goodsShelf.name == null || vm.goodsShelf.name == "") {
                MessageService.error("请输入货架名称");
                return false;
            }
             
            if (vm.goodsShelf.producerId == null || vm.goodsShelf.producerId == "") {
                MessageService.error("请输入选择生产商");
                return false;
            }
            return true;
        }

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            if (vm.goodsShelf.id == null) {
                GoodsShelf.createBatch(vm.goodsShelf, onSaveSuccess, onSaveError);
            }else{
                GoodsShelf.update(vm.goodsShelf, onSaveSuccess, onSaveError);
            }
        }

        $scope.producerChange = function(){
            if (vm.goodsShelf.producerId != null) {
                for(var i = 0; i<vm.producers.length; i++){
                    if (vm.producers[i].id == vm.goodsShelf.producerId) {
                        vm.goodsShelf.producerName = vm.producers[i].name;
                        return;
                    }
                }
            }else{
                 vm.goodsShelf.producerName = null;
            }
        }

        function onSaveSuccess(result) {
            $state.go('goods-shelf', null, {
                reload: 'goods-shelf'
            });
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
