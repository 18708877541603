(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('TaskbillFormDialogController', TaskbillFormDialogController);

    TaskbillFormDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'Store', '$state', '$uibModal', "MessageService", 'TaskbillForms', 'entity'];

    function TaskbillFormDialogController ($timeout, $scope, $stateParams, Store, $state, $uibModal, MessageService, TaskbillForms, entity) {
        var vm = this;

        vm.form = entity || {
        	name: "",
        	enabled: true,
        	remarks: "",
        	width: 100,
        	height: 100,
        	backgroundColor: "#FFFFFF",
        	items: []
        };
        vm.fields = [];
        vm.panel = {
        	scale: 1,
        	offsetX: 0,
        	offsetY: 0,
        	marginWidth: 10
        };
        vm.selected = null;
        vm.onFormSizeChange = onFormSizeChange;
        vm.close = close;
        vm.save = save;
        vm.onItemMousedown = onItemMousedown;

        init();

        function init() {
        	if (!vm.form.items) {
        		vm.form.items = [];
        	}
        	if (!vm.form.backgroundColor) {
        		vm.form.backgroundColor = "#FFFFFF";
        	}
        	TaskbillForms.fields({}, function (res) {
        		vm.fields = res || [];
        		buildForm(vm.fields);
        	});
        }

        function buildForm(fields) {
        	var pos = 0;
        	for (var i = 0; i < fields.length; i++) {
        		var f = fields[i];

        		var item = null;
        		for (var j = 0; j < vm.form.items.length; j++) {
        			var it = vm.form.items[j];
        			if (f.field == it.field) {
        				item = it;
        				break;
        			}
        		}
        		if (item == null) {
        			vm.form.items.push({
        				id: null,
        				label: f.label,
        				field: f.field,
        				enabled: f.enabled,
        				x: 0,
        				y: pos,
        				width: f.width,
        				height: f.height,
        				text: f.text,
        				fontSize: f.fontSize,
        				bold: f.bold,
        				demoValue: f.demoValue
        			});
        		} else {
        			item.label = f.label;
        			item.text = f.text;
        			item.demoValue = f.demoValue;
        		}
        		pos += 6;
        	}
        	$timeout(function() {
        		onFormSizeChange();	
        	}, 100);

        	registerEvents();
        }

        function registerEvents() {
        	window.addEventListener("resize", onResize);
        	window.addEventListener("mousedown", onMousedown);
        	window.addEventListener("mousemove", onMousemove);
        	window.addEventListener("mouseup", onMouseup);
        	window.addEventListener("mouseleave", onMouseleave);
        }

        function unregisterEvents() {
        	window.removeEventListener("resize", onResize);
        	window.removeEventListener("mousedown", onMousedown);
        	window.removeEventListener("mousemove", onMousemove);
        	window.removeEventListener("mouseup", onMouseup);
        	window.removeEventListener("mouseleave", onMouseleave);
        }

        function mm2px(val) {
        	return (val * 96 / 25.4) ;
        }
        function px2mm(val) {
        	return (val * 25.4 / 96);
        }

        function onResize(e) {
        	onFormSizeChange();
        }

        function onFormSizeChange(e) {
        	if (e) {
        		console.log(e);
        	}
        	if (!vm.form.width || !vm.form.height) {
        		return;
        	}
        	if (vm.form.width < 10 || vm.form.height < 10) {
        		return;
        	}
        	var client = document.getElementById("panel-client");
        	var clientWidth = client.clientWidth;
        	var clientHeight = client.clientHeight;
        	var marginWidth = vm.panel.marginWidth;
        	var formW = mm2px(vm.form.width);
        	var formH = mm2px(vm.form.height);
        	var scaleX = (clientWidth - marginWidth * 2) / formW;
        	var scaleY = (clientHeight - marginWidth * 2) / formH;
        	var scale = Math.min(scaleX, scaleY);
        	// var scale = 1;
        	vm.panel.scale = scale;
        	vm.panel.offsetX = (clientWidth - formW) / 2 - marginWidth;
        	vm.panel.offsetY = (clientHeight - formH) / 2 - marginWidth;

        	fixPositionForItems()
        }

        function fixPositionForItems() {
        	for (var i = 0; i < vm.form.items.length; i++) {
        		var it = vm.form.items[i];
        		if (it.x >= vm.form.width) {
        			it.x = 0;
        		}
        		if (it.y >= vm.form.height) {
        			it.y = 0;
        		}
        	}
        	$scope.$apply();
        }

        function close() {
        	unregisterEvents();
        	$state.go('taskbill-forms', null, { reload: false });
        }

        function validate() {
        	if (!vm.form.name) {
        		MessageService.error("请输入名称");
        		return false;
        	}
        	if (!vm.form.width || vm.form.width < 10) {
        		MessageService.error("请输入有效表单长");
        		return false;
        	}
        	if (!vm.form.height || vm.form.height < 10) {
        		MessageService.error("请输入有效表单宽");
        		return false;
        	}
        	return true;
        }

        function save() {
        	if (!validate()) {
        		return;
        	}
        	vm.isSaving = true;

        	if (!vm.form.id) {
        		TaskbillForms.create(vm.form, onSuccess, onFailed);
        	} else {
        		TaskbillForms.update(vm.form, onSuccess, onFailed);
        	}
        }

        function onSuccess(res) {
        	vm.isSaving = false;
        	MessageService.success("保存成功");
        	unregisterEvents();
        	$state.go('taskbill-forms', null, { reload: true });
        }

        function onFailed(error) {
        	vm.isSaving = false;
        	MessageService.error("保存失败")
        }

        function onMousedown($event) {
        	// console.log('form_mousedown', $event);
        	vm.selected = null;

        }

        function onItemMousedown($event, item) {
        	console.log('item_mousedown', $event, item);
        	vm.selected = {
        		x0: item.x,
        		y0: item.y,
        		mouseX0: $event.pageX,
        		mouseY0: $event.pageY,
        		item: item,
        		dragging: true
        	};
        	$event.preventDefault();
        	$event.stopPropagation();
        }

        function onMousemove($event) {
        	if (!vm.selected) {
        		return;
        	}
        	if (!vm.selected.dragging) {
        		return;
        	}
        	

        	var mouseX = $event.pageX;
        	var mouseY = $event.pageY;
        	var deltaX = (mouseX - vm.selected.mouseX0) / vm.panel.scale;
        	var deltaY = (mouseY - vm.selected.mouseY0) / vm.panel.scale;

        	var newX = vm.selected.x0 + px2mm(deltaX);
        	var newY = vm.selected.y0 + px2mm(deltaY);
        	if (newX < 0) {
        		newX = 0;
        	}
        	if (newY < 0) {
        		newY = 0;
        	}
        	if (newX + vm.selected.item.width > vm.form.width) {
        		newX = vm.form.width - vm.selected.item.width;
        		if (newX < 0) {
        			return;
        		}
        	}
        	if (newY + vm.selected.item.height > vm.form.height) {
        		newY = vm.form.height - vm.selected.item.height;
        		if (newY < 0) {
        			return;
        		}
        	}
        	

        	vm.selected.item.x = newX;
        	vm.selected.item.y = newY;
        	$timeout(function() {
        		$scope.$apply();
        	}, 100);
        }

        function onMouseup($event) {
        	if (!vm.selected) {
        		return;
        	}

        	// console.log("mouseup", $event);
        	vm.selected.dragging = false;
        	$scope.$apply();
        }

        function onMouseleave($event) {
        	if (!vm.selected) {
        		return;
        	}
        	// console.log("mouseleave", $event);
        	vm.selected.dragging = false;
        	$scope.$apply();
        }
    }
})();
