(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreEntrySettingController', StoreEntrySettingController);

    StoreEntrySettingController.$inject = ['$scope', '$q', '$state', '$rootScope', '$stateParams', 'Store',
    'PlatformAbutment', 'MessageService', 'PlatformWechatSetting','Principal', 'Qinius', 'StoreConfig', 'UidService'];

    function StoreEntrySettingController($scope, $q, $state, $rootScope, $stateParams, Store,
     PlatformAbutment, MessageService, PlatformWechatSetting,Principal, Qinius, StoreConfig, UidService) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.store = Store.getCurrent();
        vm.storeConfigs = StoreConfig.getAllByCondition({});
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        });

        vm.mobileShowImg = {
            type:'MOBILESHOWIMG',
            open: false,
            value:''
        };

        vm.PCShowImg = {
            type:'PCSHOWIMG',
            open: false,
            value:''
        };

        vm.imgShow = {
            type:'IMGSHOW',
            open: false,
            value:''
        };

        $q.all([vm.storeConfigs.$promise]).then(function () {
            if (vm.storeConfigs && vm.storeConfigs.length>0) {
                for (var i = vm.storeConfigs.length - 1; i >= 0; i--) {
                    var config = vm.storeConfigs[i];
                    if (config.type == 'MOBILESHOWIMG') {
                        vm.mobileShowImg = config;
                    }
                    if (config.type == 'PCSHOWIMG') {
                        vm.PCShowImg = config;
                    }
                    if (config.type == 'IMGSHOW') {
                        vm.imgShow = config;
                    }
                }
            }
        });

        function qiniuUpload(file, item) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'code-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(item == 'mobile'){
                        vm.mobileShowImg.value = data.key;
                        StoreConfig.update(vm.mobileShowImg);
                    }
                    if(item == 'PC'){
                        vm.PCShowImg.value = data.key;
                        StoreConfig.update(vm.PCShowImg);
                    }
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        window.upload = function ($event,item) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0],item);
            }
        }

        $scope.removeFile = function(item){
            if(item == 'mobile'){
                vm.mobileShowImg.value = '';
                StoreConfig.update(vm.mobileShowImg);
            }
            if(item == 'PC'){
                vm.PCShowImg.value = '';
                StoreConfig.update(vm.PCShowImg);
            }
        }

        $scope.startUsing = function () {
            StoreConfig.update(vm.imgShow);
        };
    }
})();
