(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('league', {
                parent: 'entity',
                url: '/league?page&sort&search',
                data: {
                    authorities: ['ROLE_STORE_JOIN_SETTING'],
                    pageTitle: 'backApp.title.league'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/league/league.html',
                        controller: 'LeagueController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    sort: {
                        value: 'id,desc',
                        squash: true
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Store', function($stateParams, Store) {
                        return Store.getCurrent({}).$promise;
                    }]
                }
            })
            .state('league-apply', {
                parent: 'league',
                url: '/league-apply?page&sort&search',
                data: {
                    authorities: ['ROLE_STORE_JOIN_SETTING'],
                    pageTitle: 'backApp.title.league-apply'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/league/league-apply.html',
                        controller: 'LeagueApplyController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Store', function($stateParams, Store) {
                        return Store.getCurrent({}).$promise;
                    }]
                }
            });
    }

})();
