(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('RecommendPic', RecommendPic);

    RecommendPic.$inject = ['$resource'];

    function RecommendPic ($resource) {
        var resourceUrl =  'manager/' + 'api/recommend-pics/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "delete":{
                method:"DELETE"
            },
            "batchCreateByStore":{
                method:"GET",
                params:{
                    path:"batchCreateByStore"
                }
            },
            "createByStore":{
                method:"POST",
                params:{
                    path:"createByStore"
                }
            },
            'update': { method:'PUT' },
        });
    }
})();
