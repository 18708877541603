(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('openAccount-commodity', {
                parent: 'entity',
                url: '/openAccount-commodity',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountCommodity'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-commodity/openAccount-commodity.html',
                        controller: 'OpenAccountCommodityController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                }
            })
        ;
    }

})();
