(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GiveNoticeModalController', GiveNoticeModalController);

    GiveNoticeModalController.$inject = ['$localStorage', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$q','$sce', 'VersionDescriptions', '$state'];

    function GiveNoticeModalController ($localStorage, $scope, $stateParams, $uibModalInstance, entity, $q,$sce, VersionDescriptions, $state) {
        var vm = this;
        vm.data = entity;
        $q.all([vm.data.$promise]).then(function(){
            if (!vm.data.read) {
                VersionDescriptions.read({id:vm.data.id}, function(){})
            }
            $scope.html = $sce.trustAsHtml(vm.data.content);
        })
        //返回
        $scope.cancel = function() {
           if (!vm.data.read) {
                $state.reload();
            } 
            $uibModalInstance.close();
        }
    }
})();
