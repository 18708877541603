(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customer-service-config', {
                parent: 'store',
                url: '/customer-service-config',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"],
                    pageTitle: 'global.menu.entities.customerServiceConfig'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/customer-service-config/customer-service-config.html',
                        controller: 'CustomerServiceConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('store');
                        return $translate.refresh();
                    }],
                    entity: ['Product', '$stateParams', function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
            .state('customer-service-config-edit', {
                parent: 'store',
                url: '/customer-service-config-edit',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"],
                    pageTitle: 'global.menu.entities.customerServiceConfig'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/customer-service-config/customer-service-config-edit.html',
                        controller: 'CustomerServiceConfigEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('store');
                        return $translate.refresh();
                    }],
                    entity: ['Product', '$stateParams', function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
    }

})();
