(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('VipLevelPageController', VipLevelPageController);

    VipLevelPageController.$inject = ['$state', '$timeout', 'UidService', '$q', 'Qinius', 'VipLevelSetting', '$scope', 'entity', 'MessageService'];

    function VipLevelPageController($state, $timeout, UidService, $q, Qinius, VipLevelSetting, $scope, entity, MessageService) {

        var vm = this;

        vm.removeFile = removeFile;
        vm.removeFile2 = removeFile2;
        vm.loadData = loadData;
        vm.save = save;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadData();
        });

        vm.timeList = [{name:"day", value:"天"},
                    {name:"week", value:"周"},
                    {name:"month", value:"月"}];

        function loadData () {
            vm.page = {
                'vipBackImg': null,
                'vipName': null,
                'vipLevel': null,
                'vipGrouthKey': null,
                'vipGrouthValue': null,
                'orderPreIntegral': null,
                'upgradePreIntegral': null,
                'orderGetIntegralKey': null,
                'orderGetIntegralVal': null,
                'vipWelfareEnable': null,
                'vipWelfareImg': null,
                'upgradeCondiEnable': null,
                'vipUpValue': null,
                'relegaCondiEnable': null,
                'vipSetting': {
                    'id': entity.id
                }
            };

            if (entity.type != 0) {
                VipLevelSetting.get({'id': entity.id}, function(res) {
                    vm.page = res;
                    vm.page.vipLevel = vm.page.vipLevel;
                    vm.page.vipGrouthKey = vm.page.vipGrouthKey;
                    vm.page.vipGrouthValue = vm.page.vipGrouthValue;
                    vm.page.orderGetIntegralKey = vm.page.orderGetIntegralKey;
                    vm.page.orderGetIntegralVal = vm.page.orderGetIntegralVal;
                    vm.page.orderPreIntegral = vm.page.orderPreIntegral;
                    vm.page.upgradePreIntegral = vm.page.upgradePreIntegral;
                    vm.page.vipUpValue = vm.page.vipUpValue;

                    vm.page.relegaPeriod = vm.page.relegaPeriod;
                    vm.page.transNum = vm.page.transNum;
                    vm.page.delGrouthVal1 = vm.page.delGrouthVal1;
                    vm.page.relegaPeriod2 = vm.page.relegaPeriod2;
                    vm.page.monetary = vm.page.monetary;
                    vm.page.delGrouthVal2 = vm.page.delGrouthVal2;
                });
            }

        }

        window.updateImgFile = function ($event, type) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "vip-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        if (type == 0) {
                            vm.page.vipBackImg = data.key;
                        } else if (type == 1) {
                            vm.page.vipWelfareImg = data.key;
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".dialog-form").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        function removeFile() {
            vm.page.vipBackImg = '';
        }

        function removeFile2() {
            vm.page.vipWelfareImg = '';
        }

        function save() {
            VipLevelSetting.saveVipLevelSet(vm.page, function(res) {
                $state.go("vip-level");
            });
        }

    }
})();
