(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountTypeController', OpenAccountTypeController);

    OpenAccountTypeController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', '$http', 'Store', '$q','SelfApplication'];

    function OpenAccountTypeController($state, $scope, $timeout, Qinius, $uibModal, $http, Store, $q,SelfApplication) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        vm.data=true;

        $scope.save = function () {
            if(vm.data){
                SelfApplication.createOnce({
                    type:'Self',
                    storeId:vm.store.id,
                    storeName:vm.store.name,
                }, function (res) {
                    $state.go('openAccount-subbranch')
                })
            }else{
                $state.go('openAccount-subbranch')
            }
        }
    }

})();
