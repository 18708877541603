(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SettingDialogController', SettingDialogController);

    SettingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'Backgrounds', 'MessageService',
    'Upload', 'UidService', 'ThemeClassify', 'Convert', '$localStorage'];

    function SettingDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, Backgrounds, MessageService,
        Upload, UidService, ThemeClassify, Convert, $localStorage) {
        var vm = this;

        vm.type = entity.state;
        if(entity.state == 'add'){
            vm.data = {
                themeClassifyId: '',
                provider: 'qiniu',
                identifier: ''
            }
        }
        vm.setting = ThemeClassify.getAllByCondition3({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'BG'}]});
        vm.domains = Qinius.getDomain();
        vm.cancel = cancel;
        vm.save = save;
        $q.all([vm.setting.$promise,vm.domains.$promise]).then(function(){
             $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if(entity.state != 'add'){
                vm.data = Backgrounds.get({id: entity.data.id});
            }

        })
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });


        window.updateImgFile = function ($event) {
            $scope.$digest();
            $timeout(function () {
                var file = $event.target.files[0];
                fileGet(file);
                function fileGet(file) {
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);

                    formData.append('key', "clipArt-" + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            vm.data.identifier = data.key;
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });

                }
            });
        }

        $scope.removeFile = function(file) {
            vm.data.identifier = "";
        };

        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if(!vm.data.themeClassifyId){
                MessageService.error("请选择背景分类");
                return
            }
            if(!vm.data.seq){
                MessageService.error("请输入排序");
                return
            }
            if(!vm.data.identifier){
                MessageService.error("请选择背景图片");
                return
            }
            vm.isSaving = true;
            if (vm.data.id != null) {
                Backgrounds.update(vm.data, onSaveSuccess, onSaveError);
            } else {
                Backgrounds.createByStore(vm.data, onSaveSuccess, onSaveError);
            }
            function onSaveSuccess (result) {
                MessageService.success("背景保存成功");
                $uibModalInstance.close(result);
                vm.isSaving = false;
            };

            function onSaveError () {
                MessageService.error("背景保存失败");
                vm.isSaving = false;
            };
        }
    }
})();
