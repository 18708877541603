(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductTitleDialogController', ProductTitleDialogController);

    ProductTitleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProductTitle', '$q', 'MessageService'];

    function ProductTitleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProductTitle, $q, MessageService) {
        var vm = this;

        vm.productTitle = entity;
        vm.clear = clear;
        vm.save = save;

        $q.all([vm.productTitle.$promse]).then(function(){
            if (!!vm.productTitle.id) {
                if (vm.productTitle.style == 'STYLEFIRST') {
                    vm.productTitle.style1 = true;
                    vm.productTitle.style2 = false;
                    vm.productTitle.style3 = false;
                    vm.productTitle.style4 = false;
                }else if (vm.productTitle.style == 'STYLESECOND'){
                    vm.productTitle.style1 = false;
                    vm.productTitle.style3 = false;
                    vm.productTitle.style2 = true;
                    vm.productTitle.style4 = false;
                }else if (vm.productTitle.style == 'STYLETHIRD'){
                    vm.productTitle.style1 = false;
                    vm.productTitle.style2 = false;
                    vm.productTitle.style3 = true;
                    vm.productTitle.style4 = false;
                }else if (vm.productTitle.style == 'STYLEFOURTH'){
                    vm.productTitle.style1 = false;
                    vm.productTitle.style2 = false;
                    vm.productTitle.style3 = false;
                    vm.productTitle.style4 = true;
                }
            }else{
                vm.productTitle.style1 = true;
                vm.productTitle.style2 = false;
                vm.productTitle.style3 = false;
                vm.productTitle.style4 = false;
            }
        })

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.styleChange = function(style){
            if (style == 'style1') {
                vm.productTitle.style1 = !vm.productTitle.style1;
                if (vm.productTitle.style1) {
                    vm.productTitle.style2 = false;
                    vm.productTitle.style3 = false;
                    vm.productTitle.style4 = false;
                }else{
                    vm.productTitle.style2 = true;
                }
            }else if  (style == 'style2') {
                 vm.productTitle.style2 = !vm.productTitle.style2;
                if (vm.productTitle.style2) {
                    vm.productTitle.style1 = false;
                    vm.productTitle.style3 = false;
                    vm.productTitle.style4 = false;
                }else{
                    vm.productTitle.style1 = true;
                }
            }else if  (style == 'style3') {
                vm.productTitle.style3 = !vm.productTitle.style3;
                if (vm.productTitle.style3) {
                    vm.productTitle.style1 = false;
                    vm.productTitle.style2 = false;
                    vm.productTitle.style4 = false;
                }else{
                    vm.productTitle.style1 = true;
                }
            }else if  (style == 'style4') {
                vm.productTitle.style4 = !vm.productTitle.style4;
                if (vm.productTitle.style4) {
                    vm.productTitle.style1 = false;
                    vm.productTitle.style2 = false;
                    vm.productTitle.style3 = false;
                }else{
                    vm.productTitle.style1 = true;
                }
            }
        }

        function save () {
            if (vm.productTitle.title == null) {
                MessageService.error("请录入标题");
                return;
            }
            if (vm.productTitle.style1) {
                vm.productTitle.style = 'STYLEFIRST'
            }else if (vm.productTitle.style2 ) {
                vm.productTitle.style = 'STYLESECOND'
            }else if (vm.productTitle.style3 ) {
                vm.productTitle.style = 'STYLETHIRD'
            }else if (vm.productTitle.style4 ) {
                vm.productTitle.style = 'STYLEFOURTH'
            }
            if (vm.productTitle.style == null) {
                MessageService.error("请选择风格");
                return;
            }
            vm.isSaving = true;
            if (vm.productTitle.id !== null) {
                ProductTitle.update(vm.productTitle, onSaveSuccess, onSaveError);
            } else {
                ProductTitle.save(vm.productTitle, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:productTitleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
