(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreAliSettingDetailController', StoreAliSettingDetailController);

    StoreAliSettingDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'previousState', 'entity', 'StoreAliSetting', 'PlatformALiSetting', 'MessageService',
    'Store', 'Upload', 'AliMiniAppPurchases'];

    function StoreAliSettingDetailController($scope, $q, $rootScope, $stateParams, previousState, entity, StoreAliSetting, PlatformALiSetting, MessageService,
    Store , Upload, AliMiniAppPurchases  ) {
        var vm = this;

        vm.storeAliSetting = entity;
        vm.previousState = previousState.name;

        vm.store = Store.getCurrent();
        vm.platformALiSetting = PlatformALiSetting.getOne();

        $q.all([vm.platformALiSetting.$promise, vm.storeAliSetting.$promise, vm.store.$promise]).then(function(){
            $scope.notifyUrl = encodeURIComponent(vm.platformALiSetting.redirectUri + "/#/store-ali-setting/auth_notify");
            vm.loading = false;
        })
        var fileWatched = null;

        window.upload = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                fileWatched = files[0]
            }
        }

        $scope.unAuth = function(){
            StoreAliSetting.cancelAgent({storeId:vm.store.id, batchNo:vm.storeAliSetting.batchNo}, function(data){
                MessageService.success(data.subMsg);
            }, function(){
                MessageService.error("取消事物失败");
            })
        } 

        $scope.bindApp = function(){
            StoreAliSetting.bindApp({storeId:vm.store.id, appId:vm.appId}, function(data){
                MessageService.success("绑定成功");
            }, function(error){
                MessageService.error("绑定失败");
            })
        } 


        
        $scope.categoryQuery = function(){
            StoreAliSetting.categoryQuery({}, function(data){
            }, function(){
            })
        } 

        $scope.auth2 = function(){
            window.location.href="https://openauth.alipay.com/oauth2/appToAppBatchAuth.htm?app_id="+vm.platformALiSetting.appId
            +"&application_type=TINYAPP&redirect_uri="+ $scope.notifyUrl;
        }


        var unsubscribe = $rootScope.$on('backApp:storeAliSettingUpdate', function(event, result) {
            vm.storeAliSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
