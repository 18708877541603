(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('storeDeliveryController', storeDeliveryController);

    storeDeliveryController.$inject = ['$scope', '$state', 'Region', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'StoreConfig','Principal'];

    function storeDeliveryController ($scope, $state, Region, ParseLinks, paginationConstants, pagingParams, MessageService, StoreConfig,Principal) {
        var vm = this;

        Principal.hasAuthority('ROLE_STORE_EXPRESS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        vm.tempArr =[];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll =function(){
            $scope.selectAll = !$scope.selectAll;
            angular.forEach(vm.regions,function(item){
                item.select = $scope.selectAll;
            });
            vm.tempArr = vm.regions.filter(function(item){
                return item.select == true;
            });
        };

        $scope.chooseOne = function(data){
            data.select = !data.select;
            $scope.selectAll = vm.regions.every(function(item){
                return item.select;
            });
            vm.tempArr = vm.regions.filter(function(item){
                return item.select == true;
            });
        };
        vm.storeConfigs = {
            open: false,
            type: "STOREFREIGHT"
        };
        StoreConfig.getAllByCondition({}, function (res) {
            for(var i=0; i<res.length; i++){
                if(res[i].type == "STOREFREIGHT"){
                    vm.storeConfigs = res[i];
                    if(vm.storeConfigs.open){
                        loadAll();
                    }
                }
            }
        });

        $scope.storeConfigChange = function(){
            if (!vm.storeConfigs.id) {
                StoreConfig.save(vm.storeConfigs, function(data){
                    vm.storeConfigs = data;
                    MessageService.success("保存成功！");
                    if(vm.storeConfigs.open){
                        loadAll();
                    }
                }, function(){
                    MessageService.error("保存失败！");
                })
            }else{
                StoreConfig.update(vm.storeConfigs, function(data){
                    vm.storeConfigs = data;
                    MessageService.success("保存成功！");
                    if(vm.storeConfigs.open){
                        loadAll();
                    }
                }, function(){
                    MessageService.error("保存失败！");
                })
            }
        }


        function loadAll() {
            var items = [{key:"freightType",op:"=",value:"STORE"}]
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
            }
            Region.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.regions = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("区域获取失败");
            }
        }

         $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Region.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        $scope.deleteRegion = function(){
            var ids = vm.tempArr.map(function(item){return item.id}).join(',');
            MessageService.confirm("确认要删除该运费么",function(){
                Region.delBatch({ids: ids},function () {
                    vm.tempArr = [];
                    loadAll();
                });
            },function(){},true);
        };


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.enableClick = function (item) {
            item.enable = !item.enable ? true:false;
            Region.update(item, function (res) {
                if(res.enable){
                    MessageService.success("已启用");
                }
                if(!res.enable){
                    MessageService.success("已停用");
                }
                loadAll();
            }, function (error) {});
        }
    }
})();
