(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CreditsExchangeController', CreditsExchangeController);

    CreditsExchangeController.$inject = ['$scope', '$state', 'Qinius', '$uibModal', '$q', 'VipSettings', 'Store','MembershipPackages','MessageService', 'pagingParams',
        'paginationConstants', 'Redeems', 'ParseLinks','Principal'];

    function CreditsExchangeController ($scope, $state, Qinius, $uibModal, $q, VipSettings, Store, MembershipPackages,MessageService, pagingParams,
                                        paginationConstants, Redeems, ParseLinks,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_MEMBERSHIPSYS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            init();
        });
        function init(){
            VipSettings.get({}, function (res) {
                vm.setting = res;
            })
        }
        $scope.startUsing = function(){
            VipSettings.update(vm.setting, function (res) {
                init();
            })
        };

        $scope.addCredits = function(){
            $uibModal.open({
                templateUrl: 'app/entities/creditsExchange/modal/integral-add.html',
                controller: 'IntegralAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'add'
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    loadAll();
                }
            });
        }
        $scope.editCredits = function(id){
            $uibModal.open({
                templateUrl: 'app/entities/creditsExchange/modal/integral-add.html',
                controller: 'IntegralAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            type: 'edit',
                            id: id
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    loadAll();
                }
            });
        }
        $scope.delete = function(id){
            MessageService.confirm("确定删除当前数据？",function(){
                Redeems.delete({id: id}, function (res) {
                    loadAll();
                    MessageService.success("删除成功");
                }, function () {
                    MessageService.error("删除失败");
                })
            },function(){},true);
        };
        $scope.putaway = function(item){
            var _txt = item.enable?'确定下架？':'确定上架？';
            MessageService.confirm(_txt,function(){
                item.enable = !item.enable;
                Redeems.update(item, function (res) {
                    loadAll();
                    MessageService.success("上架成功");
                })
            },function(){},true);

        }


        loadAll();
        function loadAll() {
            var items = []
            Redeems.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.data = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("积分列表获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }
    }
})();
