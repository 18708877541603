(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GuideInfoController', GuideInfoController);

    GuideInfoController.$inject = ['$state', 'BranchStoreManagement', 'Principal',  '$localStorage', 'MessageService'
        ,'$scope', 'DateUtils','SalesOrder','BranchStore', '$http'];

    function GuideInfoController($state, BranchStoreManagement, Principal,   $localStorage, MessageService,
                                      $scope, DateUtils,SalesOrder,BranchStore, $http) {
        var vm = this;

        vm.mybranch = false;
        // 判断权限
        if($localStorage.user.authorities.indexOf("ROLE_STORE_BRANCH_SHOP") > -1){
            BranchStore.getAllByCondition({
                items: [{key: "cancelled", op: "=", value: "false"},{key: "enabled", op: "=", value: "true"}]
            }, function (res) {
                vm.shops = res;
                vm.branchStoreId = vm.shops[0].id;
                save();
            });
        }else {
            if($localStorage.user.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP") > -1){
                vm.mybranch = true;
                BranchStoreManagement.findByUserId({userId:$localStorage.user.id}, function (res) {
                    vm.findByUser = res;
                    vm.branchStoreId = res.branchStore.id;
                    save();
                })
            }else {
                MessageService.error("不好意思，你不具有导购统计访问的权限！");
                $state.go("home-page");
            }
        }
        vm.startTime = "";
        vm.endTime = "";
        vm.times=[
            {
                name:'日统计',
                id:'day',
                timelist:['最近7日','最近15日','最近30日']
            },
            {
                name:'月统计',
                id:'month',
                timelist:['3个月','6个月','9个月','12个月']
            },
            {
                name:'季统计',
                id:'season',
                timelist:['第一季度','第二季度','第三季度','第四季度']
            }
        ];
        vm.time=vm.times[0].id;
        vm.timeChilds = vm.times[0].timelist;
        vm.timeChild = vm.timeChilds[0];
        $scope.timeCb = function (item) {
            vm.time=item.id;
            vm.timeChilds = item.timelist;
            vm.timeChild = vm.timeChilds[0];
            init()
            save()
        }
        $scope.timeChildCb = function(){
            if(!vm.timeChild){
                vm.timeChild = vm.timeChilds[0];
            }
            init()
            save()
        }
        $scope.branchStoreCb = function(){
            save()
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
            vm.timeChild = "";
        }
        init();
        function init() {

            if(vm.time == "day"){
                timeInit()
            }
            if(vm.time == "month"){
                var _time = 3;
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth()+1;
                var day = date.getDate();
                var nowDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);


                if(vm.timeChild == "3个月"){_time = 3};
                if(vm.timeChild == "6个月"){_time = 6};
                if(vm.timeChild == "9个月"){_time = 9};
                if(vm.timeChild == "12个月"){_time = 12};
                vm.startTime = new Date(timeInit1(nowDate, _time));
                vm.endTime = new Date(nowDate);
            }
            if(vm.time == "season"){
                var startTime = "";
                var endTime = "";
                var date = new Date();
                var year = date.getFullYear();
                if(vm.timeChild == "第一季度"){
                    startTime = year + '-01-01';
                    endTime = year + '-03-31';
                };
                if(vm.timeChild == "第二季度"){
                    startTime = year + '-04-01';
                    endTime = year + '-06-30';
                };
                if(vm.timeChild == "第三季度"){
                    startTime = year + '-07-01';
                    endTime = year + '-09-30';
                };
                if(vm.timeChild == "第四季度"){
                    startTime = year + '-10-01';
                    endTime = year + '-12-31';
                };
                vm.startTime = new Date(startTime);
                vm.endTime = new Date(endTime);
            }
        }

        $scope.search = function () {
            save()
        }
        $scope.download = function () {
            if(!vm.branchStoreId){
                MessageService.error("请选择门店");
                return
            }

            $http({
                url: 'service/api/sales-orders/exportGuideData',
                method: "GET",
                params: {
                    branchStoreId:vm.branchStoreId,
                    startTime:DateUtils.convertLocalDateToServer(vm.startTime),
                    endTime:DateUtils.convertLocalDateToServer(vm.endTime)
                },
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "导购统计" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);

            }).error(function (data, status, headers, config) {
            });
        }
        function save() {
            if(!vm.branchStoreId){
                MessageService.error("请选择门店");
                return
            }
            SalesOrder.getGuideData({
                branchStoreId:vm.branchStoreId,
                startTime:DateUtils.convertLocalDateToServer(vm.startTime),
                endTime:DateUtils.convertLocalDateToServer(vm.endTime)
            }, function (res) {
                if(res.status == 200){
                    vm.tableData = res.message;
                }else {
                    MessageService.error("数据获取失败，请稍后再试");
                }
            })
        }


        function timeInit(){
            var _time = 1;
            if(vm.timeChild == "最近7日"){_time = 7};
            if(vm.timeChild == "最近15日"){_time = 15};
            if(vm.timeChild == "最近30日"){_time = 30};

            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth()+1;
            var day = date.getDate();
            var nowDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);

            var lastDate = new Date(date - 1000 * 60 * 60 * 24 * _time);
            var lastY = lastDate.getFullYear();
            var lastM = lastDate.getMonth()+1;
            var lastD = lastDate.getDate();
            var LDate = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" +(lastD < 10 ? "0" + lastD : lastD);
            vm.startTime = new Date(LDate);
            vm.endTime = new Date(nowDate);
        }

        function timeInit1(date, monthNum) {
            var dateArr = date.split('-')
            var year = dateArr[0] //获取当前日期的年份
            var month = dateArr[1] //获取当前日期的月份
            var day = dateArr[2] //获取当前日期的日
            var days = new Date(year, month, 0)
            days = days.getDate() //获取当前日期中月的天数
            var year2 = year
            var month2 = parseInt(month) - monthNum;
            if (month2 <= 0) {
                year2 =
                    parseInt(year2) -
                    parseInt(month2 / 12 == 0 ? 1 : Math.abs(parseInt(month2 / 12)) + 1)
                month2 = 12 - (Math.abs(month2) % 12)
            }
            var day2 = day
            var days2 = new Date(year2, month2, 0)
            days2 = days2.getDate()
            if (day2 > days2) {
                day2 = days2
            }
            if (month2 < 10) {
                month2 = '0' + month2
            }
            var t2 = year2 + '-' + month2 + '-' + day2
            return t2
        }
    }
})();
