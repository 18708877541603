(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ForgetPasswordController', ForgetPasswordController);


    ForgetPasswordController.$inject = ['$translate', '$timeout', 'UidService', 'StoreUser', 'Qinius', 'Store', '$scope', '$state', 'MessageService', 'VerifyCode',
        '$q', '$interval', '$uibModal', '$localStorage', 'Auth', '$rootScope','PicManagements', 'StoreUser1'];

    function ForgetPasswordController($translate, $timeout, UidService, StoreUser, Qinius, Store, $scope, $state, MessageService, VerifyCode,
                                $q, $interval, $uibModal, $localStorage, Auth, $rootScope,PicManagements, StoreUser1) {
        var vm = this;

        vm.domains = Qinius.getDomain();
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.register = register;
        vm.store = {};
        vm.store.storeType = "Person";
        vm.store.incident = "新用户注册";
        vm.success = null;
        $(".wrap").hide();
        vm.agree = false;
        vm.formInput=null;
        vm.isSaving = false;
        vm.pic = PicManagements.getAllByCondition({items:[{key:"pageSite", op :"=", value:'login'}],sort: ["seq,asc"]});
        $q.all([vm.domains.$promise,vm.pic.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if(vm.pic && vm.pic.length>0){
                $timeout(function () {
                    new Swiper('.swiper-container', {
                        spaceBetween: 20,
                        autoplay:{
                            disableOnInteraction: false,
                        }
                    });
                },1000)
            }
        })

        $scope.formInputFocus = function (item) {
            vm.formInput=item;
        }
        $scope.formInputBlur = function () {
            vm.formInput=null;
        }

        $timeout(function () {
            angular.element('#name').focus();
        });

        var createModal = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };

        //用户协议
        $scope.tempInfo = function (temp) {
            createModal('app/account/register/register_info.html', 'RegisterInfoController').result.then(
                function (data) {
                    if(data){
                        vm.agree = true;
                    }else {
                        vm.agree = false;
                    }
                });
        };

        function register() {
            if (vm.store.phone == null || vm.store.phone == '') {
                MessageService.error("请输入正确的手机号码");
                return
            }
            if (vm.store.verifyCode == null || vm.store.verifyCode == '') {
                MessageService.error("请输入正确的验证码");
                return
            }

            if (!vm.password) {
                MessageService.error("请输入密码。");
                return
            }

            if (vm.password !== vm.confirmPassword) {
                MessageService.error("两次密码输入不一致。");
                return
            }
            StoreUser1.changePassword2({
                phone:vm.store.phone,
                code:vm.store.verifyCode,
                password:vm.password,
                oldPassword:vm.confirmPassword,
            }, function (data) {
                if(data.status == 200){
                    MessageService.success("重置密码成功");
                    $state.go("home");
                }else{
                    MessageService.error(data.message || "重置密码失败");
                }
            }, function () {
                MessageService.error("系统报错，请稍后重试！");
            });
        }


        $scope.paracont = "获取验证码";
        vm.disabled = false;
        //验证码的定时器
        var timer = null;
        $scope.getVerifyCode = function () {
            if (vm.store.phone == null || vm.store.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.store.phone.length != 11) {
                MessageService.error("请输入正确的手机号码");
                return;
            }
            if (vm.disabled) {
                return;
            }
            checkUser(function () {
                vm.countdown = 120;
                // 发送验证码到手机
                VerifyCode.getVerifyCode2({
                    phone: vm.store.phone,
                    incident: "新用户注册"
                }, function (msg) {
                    if(msg.status == 400){
                        MessageService.error(msg.message || "发送短信失败,请稍后重试");
                        return
                    }
                    clearInterval(timer);
                    timer = $interval(function () {
                        if (vm.countdown > 0) {
                            vm.disabled = true;
                            vm.countdown--;
                            $scope.paracont = "重新发送(" + vm.countdown + ")";
                        } else {
                            vm.disabled = false;
                            $interval.cancel(timer);
                            $scope.paracont = "重发验证码";
                        }
                    }, 1000);
                });
            })
        };

        $scope.agreeRegister = function () {
            vm.agree=!vm.agree;
        }
        //使用条款
        $scope.clause = function (data) {
            $uibModal.open({
                templateUrl: 'app/home/clause-modal.html',
                controller: 'ClauseModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            })
        }

        function checkUser(callback) {
            StoreUser.checkUser({
                items: [{key: "phone", op: "=", value: vm.store.phone}]
            }, function (data) {
                if (data.length > 0) {
                    callback()
                } else {
                    MessageService.error("该手机号并不存在");
                }
            }, function () {
                MessageService.error("校验用户名时出错");
            });
        }
    }
})();
