 (function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];
 function stateConfig($stateProvider) {
        $stateProvider
            .state('marketing', {
                parent: 'entity',
                url: '/marketing',
                data: {
                    authorities: ["ROLE_STORE_MARKETINGMANAGEMENT"],
                    pageTitle: 'global.menu.entities.marketing-management'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/marketing/marketing.html',
                        controller: 'MarketingController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('marketing');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            ;

    }

})();
