(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProducerDialogController', ProducerDialogController);

    ProducerDialogController.$inject = ['$timeout', '$scope', 'MessageService', 'entity', 'Producer', '$state', 'Product', '$q', 'ProducerManagement'];

    function ProducerDialogController($timeout, $scope, MessageService, entity, Producer, $state, Product, $q, ProducerManagement) {
        var vm = this;

        vm.producer = entity;
        vm.clear = clear;
        vm.save = save;

        vm.products = Product.getAllByCondition({
            items: [{key:"prototype",op:"in",value:"StoreSelf"}]
        });

        vm.allProducer = Producer.getAllByCondition({});
        vm.allProducerManagement =[];
        var proMang = ProducerManagement.getAllByCondition({});

        $q.all([vm.products.$promise, vm.allProducer.$promise,proMang.$promise]).then(function () {
            // 拿到所有已被选择的商品
            angular.forEach(proMang, function (item) {
                angular.forEach(item.products, function (p) {
                    vm.allProducerManagement.push(p)
                })
            })
            angular.forEach(vm.products, function (pro) {
                angular.forEach(vm.allProducerManagement, function (item) {
                    if (pro.id == item.id) {
                        pro.choosed = true;
                    }
                })
            })

            if (vm.producer.id) {
                vm.proList = [];
                ProducerManagement.findByProducerId({producerId: vm.producer.id}, function (res) {
                    vm.producerManagement = res;
                    angular.forEach(vm.products, function (pro) {
                        angular.forEach(vm.producerManagement.products, function (item) {
                            if (pro.id == item.id) {
                                pro.selected = true;
                                vm.proList.push(pro)
                                if(pro.choosed == true){
                                    pro.choosed = false;
                                }
                            }
                        })
                    })
                });
            }

            console.log(vm.products)
        })

        $scope.productSelected = function (item) {
            item.selected = !item.selected;
            vm.proList = [];
            for (var i = 0; i < vm.products.length; i++) {
                var pro = vm.products[i];
                if (pro.selected) {
                    vm.proList.push(pro)
                }
            }
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('producer', null, {reload: 'producer'});
        }

        $scope.validate = function(){
            if(vm.producer.name == null || vm.producer.name==""){
                MessageService.error("请输入生产商名称");
                return false;
            }
            if (vm.producer.accountNumber == null || vm.producer.accountNumber=="") {
                MessageService.error("请输入生产商账号");
                return false;
            };
            if (!vm.producer.id&&vm.producer.password == null || vm.producer.password=="") {
                MessageService.error("请输入密码");
                return
            }
            if (!vm.producer.id&&vm.confirmPassword == null || vm.confirmPassword=="") {
                MessageService.error("请输入密码");
                return
            }
            if (!vm.producer.id&&vm.producer.password !== vm.confirmPassword) {
                MessageService.error("两次密码输入不一致。");
                return
            }
            return true;
        }

        function save() {
            if(!$scope.validate()){
                return;
            }
            // return
            vm.isSaving = true;
            if (vm.producer.id !== null) {
                Producer.update(vm.producer, function (res) {
                    if (res.status == 200) {
                        ProducerManagement.update({
                            id:vm.producerManagement.id,
                            producerId: res.message.id,
                            products: vm.proList
                        }, onSaveSuccess)
                    }else{
                        vm.isSaving = false;
                        MessageService.error(res.message);
                    }
                }, onSaveError);
            } else {
                Producer.save(vm.producer, function (res) {
                    if (res.status == 200) {
                        ProducerManagement.save({
                            producerId: res.message.id,
                            products: vm.proList
                        }, onSaveSuccess)
                    }else{
                        vm.isSaving = false;
                        MessageService.error(res.message);
                    }

                }, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:producerUpdate', result);
            $state.go('producer', null, {reload: 'producer'});
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
