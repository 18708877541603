(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupManagementController', GroupManagementController);

    GroupManagementController.$inject = ['$scope', '$state', 'CustomerGroupBuy', 'paginationConstants', 'MessageService', 'ParseLinks', 'pagingParams', '$stateParams'];

    function GroupManagementController ($scope, $state, CustomerGroupBuy, paginationConstants, MessageService, ParseLinks, pagingParams, $stateParams) {
        var vm = this;
        vm.status = status;
        vm.statusOn = $stateParams.status||"";

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        // vm.searchQuery = "";
        //拼团成功，拼团失败，拼团中，已开奖，已完成，已取消
        // SUCCESS, FAIL, GROUPING, AWARDED, COMPLETED, CANCELLED
        vm.statusList = [
            {name:'全部拼团',id:""},
            // {name:'拼团新建',id:"NEW"},
            {name:'拼团中',id:"GROUPING"},
            {name:'拼团成功',id:"SUCCESS"},
            {name:'拼团完成',id:"COMPLETED"},
            {name:'拼团失败',id:"FAIL"},
        ];
        init();
        function init(){
            var items = [];
            if(vm.statusOn){
                items.push({key: "groupBuyStatus", op: "=", value: vm.statusOn})
            }
            if(vm.searchQuery){
                items.push({key: "number", op: "like", value: vm.searchQuery})
            }
            CustomerGroupBuy.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ["createdDate,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tableData = res;
                vm.page = pagingParams.page;
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        $scope.byStatus = function(item){
            vm.statusOn = item.id;
            // vm.page = 1;
            search();
        }

        function status(target) {
            switch (target) {
                case "SUCCESS":
                    return "拼团成功";
                case "FAIL":
                    return "拼团失败";
                case "GROUPING":
                    return "拼团中";
                case "COMPLETED":
                    return "已完成";
                case "CANCELLED":
                    return "已取消";
                case "NEW":
                    return "待支付团";
                case "OPENAWARD":
                    return "已开奖";
                default:
                    return "";
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status:vm.statusOn
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        //删除
        $scope.delete = function (item) {
            MessageService.confirm("确认要撤销该拼团吗？", function () {
                CustomerGroupBuy.delete({id: item.id}, function () {
                    init();
                });
            });
        };
    }
})();
