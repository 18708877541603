(function() {
    'use strict';

    angular
        .module('backApp')
        .factory('ThreeEnv', ThreeEnv);

    ThreeEnv.$inject = ['$q'];

    function ThreeEnv ($q) {
        var environments = [{
            id: 'comfy-cafe-1',
            name: 'Comfy Cafe',
            path: 'lib/three/env/comfy_cafe_1k.hdr',
            format: '.hdr'
        }, {
            id: 'comfy-cafe-2',
            name: 'Comfy Cafe 2K',
            path: 'lib/three/env/comfy_cafe_2k.hdr',
            format: '.hdr'
        }, {
            id: 'venice-sunset',
            name: 'Venice Sunset',
            path: 'lib/three/env/venice_sunset_1k.hdr',
            format: '.hdr'
        }];
        var envIndex = 0;

        var service = {
            getEnvMap: getEnvMap
        };

        return service;

        function getEnvMap (renderer) {
            if (envIndex < 0) {
                envIndex = 0;
            }
            if (envIndex >= environments.length) {
                envIndex = environments.length - 1;
            }
            var env = environments[envIndex];
            return getCubeMapTexture(renderer, env);
        }

        function getCubeMapTexture (renderer, environment) {
            var path = environment.path;

            var deferred = $q.defer();
            // no envmap
            if ( ! path ) {
                deferred.resolve( { envMap: null } );
                return deferred.promise;
            }
            new THREE.RGBELoader()
            .setDataType( THREE.HalfFloatType )
            .load( path,
                function ( texture ) {
                    var pmremGenerator = new THREE.PMREMGenerator( renderer );
                    pmremGenerator.compileEquirectangularShader();
                    const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
                    pmremGenerator.dispose();

                    var data = {envMap: envMap};
                    deferred.resolve(data);

                },
                undefined, // progress
                deferred.reject );

            return deferred.promise;

        }
    }
})();
