(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('order-setting', {
            parent: 'entity',
            url: '/order-setting',
            data: {
                authorities: ['ROLE_STORE'],
                pageTitle: '订单设置'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-setting/order-setting.html',
                    controller: 'OrderSettingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
