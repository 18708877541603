(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreProductPromotionDialogController', StoreProductPromotionDialogController);

    StoreProductPromotionDialogController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'Product',  'ListService', '$uibModalInstance',
        'MessageService', '$stateParams' ];

    function StoreProductPromotionDialogController($timeout, $scope, $state, $q, entity, Product,  ListService, $uibModalInstance,
        MessageService, $stateParams ) {
        var vm = this;
        vm.itemLine = entity;
        vm.clear = clear;
        vm.save = save;
         

        $scope.productSelected = function(p){
            p.selected = !p.selected;
        }

        vm.products = Product.getAllByCondition({items:[ {key:"productType", op:"=", value:"Standard"}]});
        
        $q.all([vm.products.$promise]).then(function () {
            if (vm.itemLine.products && vm.itemLine.products.length>=0) {
                for (var i = vm.itemLine.products.length - 1; i >= 0; i--) {
                    var p =  vm.itemLine.products[i];
                    var result = ListService.inList(p, vm.products, ["id"]);
                    if (result) {
                        result.selected = true;
                    }
                }
            }
        }).then(function () {
        });
 

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save() {
            vm.itemLine.products = [];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var p =  vm.products[i];
                if (p.selected) {
                    vm.itemLine.products.push(p);
                }
            }
            $uibModalInstance.close(vm.itemLine);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
