(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('after-sale-problem', {
            parent: 'entity',
            url: '/afterSaleProblem?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_QUESTIONLIST'],
                pageTitle: 'global.menu.entities.after-sale-problem'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/afterSaleProblem/after-sale-problem.html',
                    controller: 'AfterSaleProblemController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('afterSale');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('after-sale-problem.new', {
            parent: 'after-sale-problem',
            url: '/new',
            data: {
                authorities: ['ROLE_STORE_QUESTIONLIST']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/afterSaleProblem/after-sale-problem-dialog.html',
                    controller: 'AfterSaleProblemDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('afterSale');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        content: null,
                        enabled: null,
                        cancelled: null,
                        id: null
                    };
                }
            }
        })
         .state('after-sale-problem.edit', {
             parent: 'after-sale-problem',
             url: '/{id}/edit',
             data: {
                 authorities: ['ROLE_STORE_QUESTIONLIST']
             },
             views: {
                 'content@': {
                     templateUrl: 'app/entities/afterSaleProblem/after-sale-problem-edit-dialog.html',
                     controller: 'AfterSaleProblemEditController',
                     controllerAs: 'vm'
                 }
             },
             resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('afterSale');
                     $translatePartialLoader.addPart('global');
                     return $translate.refresh();
                 }],
                 entity: ['AfterSaleProblem','$stateParams', function(AfterSaleProblem,$stateParams) {
                     return AfterSaleProblem.get({id : $stateParams.id}).$promise;
                 }]
             }
         })
        ;
    }

})();
