(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('SkuSellLimit', SkuSellLimit)
        .factory('ProductSellLimit', ProductSellLimit);

    ProductSellLimit.$inject = ['$resource'];
    SkuSellLimit.$inject = ['$resource'];

    function SkuSellLimit ($resource) {
        var resourceUrl =  'manager/' + 'api/sku-sell-limits/:path/:id';
        return $resource(resourceUrl, {}, {
            'getWarning': {
                method: 'POST',
                params: {
                    path: 'getWarning'
                },
            }            
        });
    }

    function ProductSellLimit ($resource) {
        var resourceUrl =  'manager/' + 'api/product-sell-limits/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
            },
             
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
            },
        });
    }
})();
