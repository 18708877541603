(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InitWechatController', InitWechatController);

    InitWechatController.$inject = ['$scope', '$state', 'Store', 'StoreWechatSetting', 'MessageService', '$q', 'entity', 'PlatformWechatSetting', 'WechatAuth'];

    function InitWechatController ($scope, $state, Store, StoreWechatSetting, MessageService, $q, entity, PlatformWechatSetting, WechatAuth) {
        var vm = this;

        vm.store = Store.getCurrent();
        vm.pre_auth_code = WechatAuth.pre_auth_code();
        vm.platformWechatSetting = PlatformWechatSetting.get();
        vm.storeWechatSetting = entity;

        $q.all([vm.pre_auth_code.$promise, vm.platformWechatSetting.$promise, vm.storeWechatSetting.$promise, vm.store.$promise]).then(function () {
            if (vm.pre_auth_code.status == "200") {
                vm.preAuthCode =  vm.pre_auth_code.message;
            }
            $scope.notifyUrl = encodeURIComponent(vm.platformWechatSetting.backNotifyUrl + "/#/store/init-wechat-notify");
        })

        $scope.auth = function(){ 
            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="+vm.platformWechatSetting.appId
                +"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+ $scope.notifyUrl+"&auth_type=1";
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }

        $scope.next = function(){
            if (vm.store.storeType2 != null && vm.store.storeType2.indexOf('MINI')>=0) {
                $state.go("init-mini");
            } else{
                $state.go("init-payment");
            }
        }
    }
})();
