(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('lineToThe', {
                parent: 'entity',
                url: '/lineToThe',
                data: {
                    authorities: [],
                    pageTitle: 'backApp.title.lineToThe'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/lineToThe/lineToThe.html',
                        controller: 'lineToTheController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('storeType');
                        return $translate.refresh();
                    }],
                    entity: ['Store', function(Store) {
                            return Store.getCurrent().$promise;
                        }
                    ]
                }
            })
            .state('lineToThe-detail', {
                parent: 'lineToThe',
                url: '/lineToThe-detail',
                data: {
                    authorities: [],
                    pageTitle: 'backApp.title.lineToThe'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/lineToThe/lineToThe-detail.html',
                        controller: 'lineToTheDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('storeType');
                        return $translate.refresh();
                    }],
                    entity: ['Store', function(Store) {
                            return Store.getCurrent().$promise;
                        }
                    ]
                }
            })
    }

})();
