(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RoleDetailController', RoleDetailController);

    RoleDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'entity', 'Role', 'ListService','$state'];

    function RoleDetailController($scope, $q, $rootScope, $stateParams, entity, Role, ListService,$state) {
        var vm = this;
        vm.role = entity;
        vm.clear = clear;
        $q.all([vm.role.$promise]).then(function(){
            $scope.groupBy();
        });

        $scope.groupBy = function(){
            vm.mods = [];
            if(vm.role.authorities == null || vm.role.authorities.length == 0){
                return null;
            }
            for (var i = vm.role.authorities.length - 1; i >= 0; i--) {
                var authority = vm.role.authorities[i];
                var e = ListService.inList(authority,vm.mods ,["module"]);
                if(!e){
                    var r = {module:authority.module,authorities:[]};
                    vm.mods.push(r);
                } 

                for (var j = vm.mods.length - 1; j >= 0; j--) {
                    var mod = vm.mods[j];
                    if(authority.module == mod.module){
                        authority.selected = false;
                        mod.authorities.push(authority);
                        break;
                    }
                }
            }
        };
        function clear () {
            $state.go('role', null, {reload: 'role'});
        }
    }
 
})();
