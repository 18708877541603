(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('PasswordActivity', PasswordActivity)
        .factory('PasswordMarketing', PasswordMarketing)
        .factory('WechatMiniSettings', WechatMiniSettings);

    PasswordActivity.$inject = ['$resource'];
    PasswordMarketing.$inject = ['$resource'];
    WechatMiniSettings.$inject = ['$resource'];

    function PasswordActivity ($resource) {
        var resourceUrl =  'manager/' + 'api/password-activities/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            "changeStatue":{
                method:"GET",
                params:{
                    path:"changeStatue"
                }
            },
              'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
        });
    }

    function PasswordMarketing($resource) {
        var resourceUrl = 'manager/' + 'api/password-marketings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },

            'update': {method: 'PUT'},
            'getAllByType': {
                method: "POST",
                params: {
                    path: "getAllByTypeForStore"
                },
                isArray: true
            },
             
            'getOne': {
                method: "GET",
                params: {
                    path: "getOneForStore"
                }
            }
        });
    }

    function WechatMiniSettings($resource) {
        var resourceUrl = 'manager/' + 'api/wechat-mini-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findByStoreId': {
                method: "GET",
                params: {
                    path: "findByStoreId"
                }
            }
        });
    }
})();
