(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('giveNotice', {
            parent: 'entity',
            url: '/giveNotice',
            data: {
                authorities: ['ROLE_STORE_VERSION_DESCRIPTION'],
                pageTitle: 'backApp.giftVoucher.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/give-notice/give-notice.html',
                    controller: 'GiveNoticeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
