(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassSettingController', PassSettingController);

    PassSettingController.$inject = ['$scope', '$state', 'PassSetting', 'ParseLinks', 'MessageService', '$localStorage','entity','Qinius','$q','$timeout','UidService'];

    function PassSettingController($scope, $state, PassSetting, ParseLinks, MessageService, $localStorage,entity,Qinius,$q,$timeout,UidService) {
        var vm = this;

        vm.passSetting = entity;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        console.log(vm.passSetting);

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        window.uploadPic = function ($event) {
            $scope.$digest();
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "pass-"+ UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.passSetting.passCustomerPage = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        $scope.removeFile = function(file) {
            vm.passSetting.passCustomerPage = null;
        }

        $scope.save = function (){
            PassSetting.update(vm.passSetting,onSaveSuccess, onSaveError)
        }

        function onSaveSuccess (result) {
            vm.passSetting = PassSetting.get();
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }
    }
})();
