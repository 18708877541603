(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreDialog2Controller', StoreDialog2Controller);

    StoreDialog2Controller.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Store', 'MessageService'];

    function StoreDialog2Controller ($timeout, $scope, $stateParams, $uibModalInstance, entity, Store, MessageService) {
        var vm = this;

        vm.store = entity;
        vm.clear = clear;  
        vm.activation = activation;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        // 激活操作
        function activation(){
            if (vm.store.key == null|| vm.store.key == "") {
                MessageService.error("请输入激活码");
                return ;
            }; 
            MessageService.confirm(vm.store.activated?"你确定进行续时操作吗？":"你确定进行激活操作吗？", function () { 
                Store.activation({
                    id: vm.store.id, 
                    key: vm.store.key
                }, onSaveSuccess, function (){
                    MessageService.error("激活码存在错误");
                });
            }, function () { 
            }); 
        } 

        function onSaveSuccess (result) {
            $scope.$emit('backApp:storeUpdate', result);
            MessageService.success("信息更新成功");
            $uibModalInstance.close(result); 
        } 
        
    }
})();
