(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ThemeColorController', ThemeColorController);

    ThemeColorController.$inject = ['$scope', '$state', 'Store', 'MessageService','$q', 'StoreConfig'];

    function ThemeColorController ($scope, $state, Store, MessageService,$q, StoreConfig) {
        var vm = this;
        vm.store = Store.getCurrent();
        

        $q.all([vm.store.$promise]).then(function(){
            vm.themeColor ={storeId: vm.store.id, value:"Four", type:"Theme"};
            StoreConfig.getAllByTypes({
                "storeId":vm.store.id,
                "type":["Theme"]
            }, function(data){
                if(data.status == 200){
                    vm.storeConfigs = data.message;
                    if (vm.storeConfigs && vm.storeConfigs.length>0) {
                        for (var i = vm.storeConfigs.length - 1; i >= 0; i--) {
                            var config = vm.storeConfigs[i];
                           if (config == null) {
                                continue;
                            }
                            if (config.type == 'Theme') {
                                vm.themeColor = config;
                                break;
                            }
                        }
                    }
                }
            });

        });

        $scope.updateColor = function(color){
            vm.themeColor.value = color;
        }

        $scope.save = function(){
            vm.storeConfigs = new Array();
            vm.storeConfigs.push(vm.themeColor);
            StoreConfig.saveStoreConfigs(vm.storeConfigs, function(){
            MessageService.success('保存成功！');
            }, function(){
                MessageService.error('保存失败！');
            });
        }
    }
})();
