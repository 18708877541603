(function () {
    'use strict';
    angular
        .module('backApp')
        .factory('IndexStyleSettings', IndexStyleSettings)
        .factory('StoreGroupBuy', StoreGroupBuy)

    IndexStyleSettings.$inject = ['$resource'];
    StoreGroupBuy.$inject = ['$resource'];

    function IndexStyleSettings($resource) {
        var resourceUrl = 'manager/' + 'api/index-style-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            "delete": { method: "DELETE" },
            'query': { method: 'GET', isArray: true },
            'get': { method: 'GET' },
            'save': { method: 'POST' },
            'byCondition':{
                method:'POST',
                params: {
                    path: 'byCondition'
                },
            },
            'getOneEnable':{
                method:'GET',
                params: {
                    path: 'getOneEnable'
                },
            }
        });
    }
    function StoreGroupBuy ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-group-buys/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'save':{
                method: 'POST'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

})();
