(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupCardController', GroupCardController);

    GroupCardController.$inject = ['$localStorage', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PayBatch','MessageService'];

    function GroupCardController ($localStorage, $scope, $stateParams, $uibModalInstance, entity, PayBatch,MessageService) {
        var vm = this;
        init();
        function init(){
            vm.paybatchs2 = PayBatch.getAllByCondition({
                items: [{key: "used", op: "=", value: "true"}, {key: "storeId", op: "=", value: $localStorage.user.storeId}]
            });
        }
        $scope.save = function(){
            if(!vm.payBatch){
                MessageService.error("请选择批次号");
                return
            }
            $uibModalInstance.close(vm.payBatch);
        }
        //返回
        $scope.cancel = function() {
            $uibModalInstance.close();
        }
    }
})();
