(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ExpressCompanyController', ExpressCompanyController);

    ExpressCompanyController.$inject = ['$state', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', '$scope', '$uibModal', 'LogisticsCompany', '$localStorage','Principal'];

    function ExpressCompanyController($state, ParseLinks, paginationConstants, pagingParams, MessageService, $scope, $uibModal, LogisticsCompany, $localStorage, Principal) {

        var vm = this;
        Principal.hasAuthority('ROLE_STORE_LOGISTICS_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.transition = transition;
        vm.search = search;

        init()
        function init(){

            if (pagingParams.search) {
                LogisticsCompany.byCondition({
                    items:[{key:"name",op:"like",value:pagingParams.search},{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                LogisticsCompany.byCondition({
                    items:[{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.logisticsCompanies = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("物流公司获取失败");
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        };
        
        $scope.expressAdd = function () {
            $uibModal.open({
                templateUrl: 'app/entities/express-company/express.modal.html',
                controller: 'ExpressModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        var _obj = {
                            type: 'add'
                        }
                        return _obj
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        }
        //查看
        $scope.check = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/express-company/express.modal.html',
                controller: 'ExpressModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        var _obj = {
                            type: 'check',
                            data: data
                        }
                        return _obj
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        }
        //编辑
        $scope.edit = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/express-company/express.modal.html',
                controller: 'ExpressModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        var _obj = {
                            type: 'edit',
                            data: data
                        }
                        return _obj
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        }
        //搜索
        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        //删除
        $scope.delete = function(id){
            MessageService.confirm("确认要删除该快递公司?", function(){
                LogisticsCompany.delete({id:id}, function(data){
                    MessageService.success("删除成功");
                    init();
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){})
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
