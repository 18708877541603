(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('openAccount-modePayment', {
                parent: 'entity',
                url: '/openAccount-modePayment/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountPackage'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-modePayment/openAccount-modePayment.html',
                        controller: 'OpenAccountModePaymentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PackageVersion', function($stateParams, PackageVersion) {
                        return PackageVersion.get({id : $stateParams.id}).$promise;
                    }],
                }
            })
            .state('openAccount-modePayment-mini', {
                parent: 'entity',
                url: '/openAccount-modePayment-mini',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountModePaymentMini'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-modePayment/openAccount-modePayment-mini.html',
                        controller: 'OpenAccountModePaymentMiniController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('openAccount-modePayment-tencent', {
                parent: 'entity',
                url: '/openAccount-modePayment-tencent',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountModePaymentTencent'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-modePayment/openAccount-modePayment-tencent.html',
                        controller: 'OpenAccountModePaymentTencentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('openAccount-modePayment-web', {
                parent: 'entity',
                url: '/openAccount-modePayment-web',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountModePaymentWeb'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-modePayment/openAccount-modePayment-web.html',
                        controller: 'OpenAccountModePaymentWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('openAccount-modePayment-byte', {
                parent: 'entity',
                url: '/openAccount-modePayment-byte',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountModePaymentByte'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-modePayment/openAccount-modePayment-byte.html',
                        controller: 'OpenAccountModePaymentByteController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();
