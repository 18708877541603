(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('storeDelivery', {
            parent: 'entity',
            url: '/storeDelivery?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_EXPRESS'],
                pageTitle: 'backApp.title.storeDelivery'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-delivery/store-delivery.html',
                    controller: 'storeDeliveryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('region');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('storeDelivery.new', {
                parent: 'storeDelivery',
                url: '/new',
                data: {
                    authorities: ['ROLE_STORE_EXPRESS'],
                    pageTitle: '快递策略'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-delivery/store-delivery-dialog.html',
                        controller: 'storeDeliveryDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('region');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            name: null,
                            cancelled: false,
                            freight: null,
                            freightFree: null,
                            id: '',
                            freightType:'STORE',
                            provinces:[],
                            deliveryAreas:[]
                        };
                    },
                }
            })
            .state('storeDelivery.edit', {
                parent: 'storeDelivery',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_STORE_EXPRESS'],
                    pageTitle: '快递策略'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-delivery/store-delivery-dialog.html',
                        controller: 'storeDeliveryDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('region');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Region', function($stateParams, Region) {
                        return Region.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('storeDeliveryUrgent', {
                parent: 'storeDelivery',
                url: '/storeDeliveryUrgent',
                data: {
                    authorities: ['ROLE_STORE_EXPRESS'],
                    pageTitle: 'backApp.title.storeDeliveryUrgent'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-delivery/store-delivery-urgent.html',
                        controller: 'storeDeliveryUrgentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('region');
                        $translatePartialLoader.addPart('title');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         ;
    }

})();
