(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('store-theme-style', {
                parent: 'entity',
                url: '/themeStyle?page&sort&search',
                data: {
                    // authorities: ['ROLE_STYLE'],
                    pageTitle: 'backApp.role.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store-themeStyle/themeStyle.html',
                        controller: 'StoreThemeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('role');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('store-theme-style-detail', {
                parent: 'store-detail2',
                url: '/detail?flag',
                data: {
                    authorities: ['ROLE_STYLE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/store-themeStyle/themeStyle-dialog.html',
                        controller: 'StoreThemeStyleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    number: null,
                                    cancelled: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('store-theme-style', null, {reload: 'store-theme-style'});
                    }, function () {
                        $state.go('store-theme-style');
                    });
                }]
            }) ;
    }

})();
