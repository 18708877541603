(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomerServiceConfigController', CustomerServiceConfigController);

    CustomerServiceConfigController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'MessageService', 'Store', 'Qinius', 'Principal', '$state'];

    function CustomerServiceConfigController($scope, $q, $rootScope, $stateParams, MessageService, Store, Qinius, Principal, $state) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DIANPU').then(function(data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();

        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.store.$promise]).then(function() {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        })
    }
})();
