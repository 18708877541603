(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotController', MapDepotController)
        .filter('sizeTransition', function () {
            return function (size) {
                if (!size)
                    return 0;
                var num = 1000.00;
                if (size < num)
                    return size + "B";
                if (size < Math.pow(num, 2))
                    return (size / num).toFixed(2) + "KB"; //kb
                if (size < Math.pow(num, 3))
                    return (size / Math.pow(num, 2)).toFixed(2) + "MB"; //M
                if (size < Math.pow(num, 4))
                    return (size / Math.pow(num, 3)).toFixed(2) + "GB"; //G
                return (size / Math.pow(num, 4)).toFixed(2) + "TB"; //T
            }
        });

    MapDepotController.$inject = ['$scope', '$state', 'handlePackage', 'Store', 'MessageService', '$timeout', '$uibModal', 'StoreStorages', '$q', 'CustomerStorages'];

    function MapDepotController($scope, $state, handlePackage, Store, MessageService, $timeout, $uibModal, StoreStorages, $q, CustomerStorages) {
        var vm = this;
        vm.store = Store.getCurrent();
        vm.sumSize = 0;
        vm.useSize = 0;
        vm.percentage = 0;
        vm.months = [];
        vm.data1;
        for (var i = 1; i < 25; i++) {
            vm.months.push({
                name: i,
                id: i
            })
        }

        $q.all([vm.store.$promise]).then(function () {
            init()
            getAllByCondition1()
        })
        function init() {
            StoreStorages.byStoreId({ storeId: vm.store.id }, function (res) {
                vm.data = res;
                vm.sumSize = Number(res.initSize) + Number(res.buySize);
                vm.useSize = Number(res.usedSize);
                vm.percentage = vm.useSize / vm.sumSize;
            });
        }
        function getAllByCondition1() {
            handlePackage.getAllByCondition1({items: [{key: 'cancelled', op: '=', value: 'false'}], sort: ["seq,asc"]}, function (res) {
                vm.data1 = res.message;
            })
        }

        $scope.progressColor = function (size) {
            return progressColor(size)
        };

        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/map-depot/map-depot1.html',
                controller: 'MapDepot1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (result) {
                if (result) {
                    init()
                }
            });
        }

        $scope.setMeal = function () {
            $uibModal.open({
                templateUrl: 'app/entities/map-depot/map-depot2.html',
                controller: 'MapDepot2Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }],
                    entity: ['Evaluate', function (Evaluate) {
                        return {
                            name: '',
                            seq: '',
                        }
                    }]
                }
            }).result.then(function (result) {
                if (result) {
                    getAllByCondition1()
                    init()
                }
            });
        }
        // 删除套餐
        $scope.close = function (id) {
            MessageService.confirm("您确定要删除这项套餐吗？", function () {
                handlePackage.deletePackage({ id: id }, function () {
                    getAllByCondition1()
                    MessageService.success("删除成功");

                }, function (error) {
                    MessageService.error("删除失败");
                });
            });
        }
        // 修改默认
        $scope.alterDefault = function (id) {
            console.log(id);
            handlePackage.setDefault({ id: id }, function (res) {
                if (res.status == 200) {
                    getAllByCondition1()
                    MessageService.success("修改成功");
                }else{
                MessageService.error("修改失败");
                }
            }, function (error) {
                MessageService.error("修改失败");
            })
        }
    }
})();
