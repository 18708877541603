(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OperationManualRichTextController', OperationManualRichTextController);

        OperationManualRichTextController.$inject = ['$timeout', '$scope', "msgdata",'$stateParams', '$uibModalInstance', '$q', 'Qinius', 'StoreOperationManuals', 'MessageService','UidService','Store'];

    function OperationManualRichTextController($timeout, $scope,msgdata, $stateParams, $uibModalInstance, $q, Qinius,StoreOperationManuals , MessageService ,UidService,Store) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.data={}
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.data = StoreOperationManuals.get({id:msgdata.id});
        vm.store = Store.getCurrent();
        $q.all([vm.domains.$promise, vm.tokens.$promise , vm.data.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            messageEditor();
        })
        function messageEditor() {
            var E = window.wangEditor;
            var editor = new E('#messageEditor')
            editor.customConfig.onchange = function (html) {
                vm.data.content = html;
            };
            editor.customConfig.uploadImgServer = 'https://upload.qiniup.com/';
            editor.customConfig.uploadImgParams = {
                key: UidService.get(),
                token: vm.token,
                unique_names: false
            };
            editor.customConfig.uploadFileName = 'file';
            editor.customConfig.uploadImgHooks = {
                before: function (xhr, editor, files) {
                    editor.config.uploadImgParams.key= UidService.get();
                },
                customInsert: function (insertImg, result, editor) {
                    var url = $scope.domain + result.key;
                    insertImg(url);
                }
            };
            editor.customConfig.customAlert = function (info) {
                MessageService.error(info)
            };
            editor.create();
            editor.txt.html(vm.data.content);

        }
        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            console.log(vm.data);
            if (!vm.data.content) {
                MessageService.error("请输入标题内容");
                return
            }
            // 编辑
            StoreOperationManuals.update(vm.data,function(res){
                if (res.status==200) {
                    MessageService.success('保存成功')
                }else{
                    MessageService.error('保存失败')
                }
                $uibModalInstance.close();
            },function(error){
                MessageService.error('保存失败')
            })
            
           
        }
    }
})();
