(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountModuleController', OpenAccountModuleController);


    OpenAccountModuleController.$inject = ['$scope', '$state', 'Store', 'MessageService','$q', 'StoreUser','Authority', 'ListService','PasswordMarketing', '$timeout', '$localStorage'];

    function OpenAccountModuleController ($scope, $state, Store, MessageService,$q, StoreUser,Authority, ListService,PasswordMarketing, $timeout, $localStorage) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        vm.marketing = PasswordMarketing.getOne({type:'Password'});
        vm.modules=[
            {name:'支付码',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-1c5dab0d-420f-41d0-cb50-5a4601df61df',describe:'制作实体卡抵扣商品', bg:'http://pic-dev.momentsgo.com/clipArt-602d4282-4672-4a35-ddc1-98a6d3dad940'},
            {name:'用户',select:true,isShow:false,icon:'http://pic-dev.momentsgo.com/clipArt-654b42ad-2950-4d5b-848f-6d2681998aae',describe:'配置角色开设账号', bg:'http://pic-dev.momentsgo.com/clipArt-e23276ef-1118-40eb-9081-f77544f7629d'},
            {name:'商品',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-4624582f-2c03-4245-adca-4205a01eaf67',describe:'商品销售上下架', bg:'http://pic-dev.momentsgo.com/clipArt-9a5f0ba5-e3db-4c52-b0ba-21b93364f692'},
            {name:'商户',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-5b0e593e-2b11-47ca-e57d-579b21ec3d91',describe:'商户运营设置', bg:'http://pic-dev.momentsgo.com/clipArt-46845e09-53fa-4bd6-9bd3-9825aec1c9de'},
            {name:'充值',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-504af6d6-80ed-4feb-df84-607b17aad4e6',describe:'订购平台商品结算流量', bg:'http://pic-dev.momentsgo.com/clipArt-5a4008be-f551-49c6-de0b-cc05b51872ae'},
            {name:'客户',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-60934701-a0d3-4214-bf02-3d05d2d31c94',describe:'商户客户一览无余', bg:'http://pic-dev.momentsgo.com/clipArt-075eef10-62fa-47c9-9f2b-b56f11e82b9f'},
            {name:'订单',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-911dedb0-5332-4f64-f27e-1b30913d5cee',describe:'订单管理', bg:'http://pic-dev.momentsgo.com/clipArt-29a84393-4b8c-4897-c5cd-c3ca11b49f48'},
            {name:'统计',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-909ccd63-35e7-423c-b0b4-d28fbe625b91',describe:'数据统计实时查看', bg:'http://pic-dev.momentsgo.com/clipArt-53554b60-4a6f-436d-eaf5-3af337dc7863'},
            {name:'批次号',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-58769e93-6b89-43d8-ef8f-aefd8e33cf34',describe:'生码流程必备', bg:'http://pic-dev.momentsgo.com/clipArt-d11b0bf0-7af7-40b4-8c0f-857fb319c409'},
            {name:'发票',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-d97403c6-69b5-41eb-ed28-b68f3066d4b7',describe:'充值后索取发票', bg:'http://pic-dev.momentsgo.com/clipArt-e42bfc4b-ba18-4de2-c1d5-8124c45d86b5'},
            {name:'模板',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-0525b389-ea32-41d0-a051-e9a94b9f29d3',describe:'管理商品对应模板', bg:'http://pic-dev.momentsgo.com/clipArt-23c51ad6-234d-416e-b6b1-b7a8493ebe90'},
            {name:'售后',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-ff670c0c-cece-4c9d-de7e-ffdc59fdea24',describe:'自建品订单售后处理', bg:'http://pic-dev.momentsgo.com/clipArt-220d40ae-79f1-4038-a394-079cf04cb680'},
            {name:'首页',select:true,isShow:false,icon:'http://pic-dev.momentsgo.com/clipArt-c129eace-d59c-46a1-fe49-0b54de53ae0e',describe:'各种数据一览', bg:'http://pic-dev.momentsgo.com/clipArt-59d1c769-9e7a-416f-e959-8ec14c9c80a2'}
        ]
        vm.modules1=[];
        vm.count = 0;
        $q.all([vm.store.$promise, vm.marketing.$promise]).then(function(){
            if(vm.store.branch){
                vm.modules.push({name:'分店',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-c3b95057-c641-4cfd-d911-39ecc386dc60',describe:'二级分销管理', bg:'http://pic-dev.momentsgo.com/clipArt-be0f2a54-ca2c-4fb3-9255-50c60fab1762'});
            }
            if(vm.marketing.id && vm.marketing.enabled){
                vm.modules.push({name:'营销',select:false,isShow:true,icon:'http://pic-dev.momentsgo.com/clipArt-0256e825-e40b-4741-d364-ea86721a46cb',describe:'引流活动配置', bg:'http://pic-dev.momentsgo.com/clipArt-5cab57d3-1819-4d3f-bc82-3586f9fa4590'});
            }

            $timeout(function () {
                new Swiper('.swiper-container', {
                    slidesPerView: 3,
                    slidesPerColumn: 2,
                    spaceBetween : 20,
                    pagination: {
                        el: '.swiper-pagination',
                        dynamicBullets: true,
                    },
                });
            }, 500)
        })

        $scope.moduleClick = function (item) {
            item.select = !item.select;
            moduleInfo();
        }

        function moduleInfo(){
            vm.modules1=[];
            vm.modules.forEach(function (item) {
                if(item.select){
                    vm.modules1.push(item.name)
                }
            });
            vm.count = vm.modules1.length - 2;
        }
        $scope.goBack = function () {
            window.history.go(-1);
        }
        $scope.save = function () {
            if(!vm.modules1 || vm.modules1.length == 0){
                MessageService.error('请选择权限模块');
                return
            }
            vm.store.initialized = true;
            Store.update(vm.store);
            StoreUser.createDefaultRole({modules: vm.modules1}, function (res) {
                if (res.status == 200 || res.status == 201) {
                    $localStorage.authenticationToken = res.message;
                    $state.go('openAccount-commodity')
                }else{
                    MessageService.error(res.message);
                }

            })

        }
    }
})();
