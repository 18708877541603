(function () {
    'use strict';

    var InputViewControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/components/form/c-input.html',
        controllerAs: 'vm',
        bindings: {
            model: '=', //model
            width: '<', //input宽
            height: '<',//input高
            rightIcon: '<',//右边icon
            rightIconCursor: '<', //右边icon的手型
            rightBtn: '<',//右边文字
            leftBtn: '<',//左边文字
            paddingLeft:'<',//左边padding
            paddingRight:'<',//右边padding
            type:'<', //input的类型
            removeModel:'<',//是否显示删除input内容的按钮
            warning:'<',//红色边框并提示
            placeholder:'<',//placeholder
            onRighticonclick:'&',//右侧icon的回调事件
            onFocus:'&',//获取焦点事件
            onBlur:'&',//失去焦点事件
            onChange:'&',//change事件
            onKeydown:'&',//键盘按下事件
            onEnter:'&',//回车事件
            autofocus:'<', // 自动光标
            limitAmount:'<',//是否限制只能输入金额
            limitInt:'<',//限制只能输入正整数（输入数量等）
            limitFolderName:'<',//限制是否是文件夹包含的字符
            disabled:'='//是否禁用
        },

        controller: ['$scope', '$timeout', '$element', function ($scope, $timeout, $element) {
            var vm = this;
            vm.$onInit = function() {
                //删除按钮默认显示
                if(typeof vm.removeModel === "undefined"){
                    vm.removeModel = true;
                }
                if (vm.autofocus === true || vm.autofocus == 'autofocus') {
                    $timeout(function() {
                        var input = $element.find("input");
                        input && input.focus();
                    }, 100);
                }
            }
            $scope.removeModel = function (e) {
                if(vm.removeModel){
                    vm.model = "";
                    dispathChange();
                }
                if(vm.onRighticonclick){
                    vm.onRighticonclick();
                }
                $timeout(function() {
                    var input = $element.find("input");
                    input && input.focus();
                });
                fn(e)
            }
            function fn(e) {
                e = e || window.event;
                e.cancelBubble = true;
                e.stopPropagation();
                e.returnValue = false;
                e.preventDefault();
            }
            $scope.onFocus = function () {
                if(vm.onFocus){
                    vm.onFocus()
                }
            }
            $scope.onBlur = function () {
                if(vm.onBlur){
                    vm.onBlur()
                }
            }
            $scope.onChange = function () {
                dispathChange();
            }
            $scope.onKeyup = function (_this) {
                //限制只能输入金额
                if(vm.limitAmount){
                    vm.model = _this.target.value.replace(/[^\d.]/g,'').replace(/(\d)(\.)(\d*)(\2*)(\d*)/,'$1$2$3$5').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
                    dispathChange();
                }
                //限制只能输入正整数
                if(vm.limitInt){
                    vm.model = _this.target.value.replace(/^(0+)|[^\d]+/g,'');
                    dispathChange();
                }

                // 限制只能输入文件夹包含的字符
                if (vm.limitFolderName) {
                    vm.model = _this.target.value.replace(/[<>:"\/\\|?*]+/g, '');
                    dispathChange();
                }

            }
            var changeTimer = null;
            //触发change事件增加防抖，过滤多次触发
            function dispathChange(){
                if(changeTimer){
                    clearTimeout(changeTimer);
                    changeTimer = null;
                }
                changeTimer = setTimeout(function () {
                    $timeout(function(){
                        if(vm.onChange){
                            vm.onChange()
                        }
                    },10)
                },100)
                event.preventDefault();
            }
            $scope.onKeydown = function (e) {
                if(vm.onKeydown){
                    vm.onKeydown({
                        text: e
                    })
                }
                //回车事件
                if(e.key === "Enter" && vm.onEnter){
                    vm.onEnter();
                }
            }
        }]
    };
    angular.module('backApp')
        .component('inputView', InputViewControl);

})();
