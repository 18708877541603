(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayCodeDetailController', PayCodeDetailController);

    PayCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PayCode', '$q', 'Customer', '$uibModalInstance', 'MessageService','PayCodeRecord'];

    function PayCodeDetailController($scope, $rootScope, $stateParams, entity, PayCode, $q, Customer, $uibModalInstance, MessageService,PayCodeRecord) {
        var vm = this;

        vm.payCodeId = entity.id;
        console.log(vm.payCodeId)
        vm.payCodeRecord = [];
        if(vm.payCodeId !=null){
            vm.payCodeRecord = PayCodeRecord.get(
                {
                    codeId: vm.payCodeId
                }
            )
            console.log(vm.payCodeRecord)
        }

        vm.clear = clear;
        vm.unbinding = unbinding;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function unbinding(){
            MessageService.confirm("确认要解绑该支付码？",function(){
                PayCode.unbind({code:vm.payCode.code},function(data){
                    if (data.status == 200) {
                        MessageService.success("解绑成功");
                        $uibModalInstance.dismiss('cancel');
                    }else{
                       MessageService.error(data.message);
                    }
                },function(){
                    MessageService.error("解绑失败");
                })
            })
        }

        vm.payCode = entity;
        vm.customer = {};
        $q.all([vm.payCode.$promise]).then(function(){
            if (vm.payCode.usedUserId) {
                Customer.get({id:vm.payCode.usedUserId},function(data){
                    vm.customer = data;
                },function(){})
            }
        })


        var unsubscribe = $rootScope.$on('backApp:payCodeUpdate', function(event, result) {
            vm.payCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
