(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('share-setting', {
            parent: 'entity',
            url: '/share-setting?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_SHARESETTING'],
                pageTitle: 'backApp.shareSetting.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/share-setting/share-settings.html',
                    controller: 'ShareSettingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('shareSetting');
                    $translatePartialLoader.addPart('shareSettingType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }) 

        .state('share-setting-detail', {
            parent: 'entity',
            url: '/share-setting/{id}',
            data: {
                pageTitle: 'backApp.shareSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/share-setting/share-setting-detail.html',
                    controller: 'ShareSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('shareSetting');
                    $translatePartialLoader.addPart('shareSettingType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ShareSetting', function($stateParams, ShareSetting) {
                    return ShareSetting.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'share-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('share-setting-edit', {
            parent: 'entity',
            url: '/share-setting/{id}/edit',
            data: {
                pageTitle: 'backApp.shareSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/share-setting/share-setting-dialog.html',
                    controller: 'ShareSettingDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('shareSetting');
                    $translatePartialLoader.addPart('shareSettingType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ShareSetting', function($stateParams, ShareSetting) {
                    return ShareSetting.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'share-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('share-setting.new', {
            parent: 'share-setting',
            url: '/new',
            data: {
                pageTitle: 'backApp.shareSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/share-setting/share-setting-dialog.html',
                    controller: 'ShareSettingDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('shareSetting');
                    $translatePartialLoader.addPart('shareSettingType');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        type: null,
                        products:[],
                        coupons:[],
                        enable: null,
                        amount: null,
                        quantity: null,
                        usedAmount: null,
                        usedQuantity: null,
                        dateEnable: null,
                        startDate: null,
                        endDate: null,
                        id: null
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'share-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         ;
    }

})();
