(function() {
    'use strict';
    angular
        .module('backApp')
        .factory("Convert", Convert)
        .factory("Convert1", Convert1)
        .factory('Banner', Banner)
        .factory('BaseBanner', BaseBanner);

    Banner.$inject = ['$resource'];

    Convert.$inject = ['$resource'];

    Convert1.$inject = ['$resource'];

    BaseBanner.$inject = ['$resource'];

    function Convert ($resource) {
        var resourceUrl =  'fileserves/' + 'api/convert/:path/:storeId';

        return $resource(resourceUrl, {}, {
            'filterDemo': {
                method: 'GET',
                params: {
                    path: 'filterDemo'
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'xmlToImg'
                }
            },
            "xmlToDocument":{
                method:"GET",
                params:{
                    path:"xmlToDocument"
                }
            },
            "imageFormat":{
                method:"GET",
                params:{
                    path:"imageFormat"
                }
            },
            "imgFilter":{
                method: 'GET',
                params: {
                    path: "filter"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function Convert1 ($resource) {
        var resourceUrl =  'justify/' + 'api/:path/:storeId';

        return $resource(resourceUrl, {}, {
            'documentToImg': {
                method: 'POST',
                params: {
                    path: 'documentToImg'
                },
                isArray: true
            },
        });
    }

    function Banner ($resource) {
        var resourceUrl =  'manager/' + 'api/banners/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    if (data.productUrl !=null && !isNaN(data.productUrl)) {
                        data.productUrl = Number(data.productUrl);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
            'updateNumber':{
                method: 'GET',
                params: {
                    path: 'updateNumber'
                }
            }
        });
    }

    function BaseBanner ($resource) {
        var resourceUrl =  'manager/' + 'api/base-banners/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
