(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FilterRecordController', FilterRecordController);

    FilterRecordController.$inject = ['$scope', '$state', 'FilterOrderRecords', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'Store', '$http', '$stateParams', '$timeout', 'DateUtils'];

    function FilterRecordController ($scope, $state, FilterOrderRecords, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, Store, $http, $stateParams, $timeout, DateUtils) {
        var vm = this;
        vm.filterType = $stateParams.type;
        vm.store = Store.getCurrent({});
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search || "";
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.isPay = false;
        vm.page = 1;
        vm.statesList = [
            {name: '所有状态',id:''},
            {name: '已支付',id:'true'},
            {name: '待支付',id:'false'}
        ]

        loadAll();

        function loadAll () {
            var items = [
                {key:"filterType",op:"=",value: vm.filterType},
                {key:"paid",op:"=",value: true}
            ];
            if(vm.searchQuery){
                items.push({key:"number",op:"like",value:vm.searchQuery})
            }
            FilterOrderRecords.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ["createdDate,desc"]
            }, onSuccess, onError);
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.tableData = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("充值订单获取失败");
        }
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.searchQuery
            });
        }
        function search () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }
        $scope.filterType = function (item) {
            switch (item) {
                case 'PORTRAITCARTOON':
                    return "人像动漫化";
                    break;
                case 'IMAGESTYLECONVERT':
                    return "图像风格转换";
                    break;
                case 'SEGMENTATION':
                    return "人像抠图";
                    break;
                default:
                    return "";
            }
        };
    }
})();
