(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('goods-shelf', {
            parent: 'entity',
            url: '/goods-shelf?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_PRODUCERMANAGEMEN'],
                pageTitle: 'backApp.goodsShelf.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/goods-shelf/goods-shelf.html',
                    controller: 'GoodsShelfController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('goodsShelf');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        //批量
        .state('goods-shelf.new', {
            parent: 'goods-shelf',
            url: '/new',
            data: {
                authorities: ['ROLE_STORE_PRODUCERMANAGEMEN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/goods-shelf/goods-shelf-dialog.html',
                    controller: 'GoodsShelfDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        accountNumber: null,
                        password: null,
                        cancelled: null,
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('goodsShelf');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        //新增
        .state('goods-shelf.new2', {
            parent: 'goods-shelf',
            url: '/new2',
            data: {
                authorities: ['ROLE_STORE_PRODUCERMANAGEMEN'],
                pageTitle: 'backApp.goodsShelf.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/goods-shelf/goods-shelf-detail.html',
                    controller: 'GoodsShelfDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('goodsShelf');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        name: null,
                        producerId: null,
                        id: null
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'goods-shelf',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('goods-shelf.edit', {
            parent: 'goods-shelf',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_PRODUCERMANAGEMEN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/goods-shelf/goods-shelf-detail.html',
                    controller: 'GoodsShelfDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['GoodsShelf','$stateParams', function(GoodsShelf,$stateParams) {
                    return GoodsShelf.get({id : $stateParams.id}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('goodsShelf');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
        ;
    }

})();
