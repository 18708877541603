(function() {
    'use strict';
    angular
    .module('backApp')
    .factory('Brand', Brand);

    Brand.$inject = ['$resource'];

    function Brand ($resource) {
        var resourceUrl =  'manager/' + 'api/brands/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            "changeStatue":{
                method:"GET",
                params:{
                    path:"changeStatue"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
        });
    }
})();
