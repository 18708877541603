(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StoreOperationManuals', StoreOperationManuals);

        StoreOperationManuals.$inject = ['$resource'];

    function StoreOperationManuals ($resource) {
        var resourceUrl =  'manager/' + 'api/store-operation-manuals/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'get': {method: 'GET',},
            'update': { method:'PUT' },
            'save': { method:'POST' },
            'delete': { method:'DELETE' },
            
        });
    }
})();
