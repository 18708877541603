(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('share-page-config', {
                parent: 'store',
                url: '/share-page-config',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"],
                    pageTitle: 'global.menu.entities.sharePageConfig'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/share-page-config/share-page-config.html',
                        controller: 'SharePageConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('store');
                        return $translate.refresh();
                    }],
                    entity: ['SharePageConfig', '$stateParams', function(SharePageConfig, $stateParams) {
                        return SharePageConfig.getAllByCondition({items:[]});
                    }]
                }
            })
    }

})();