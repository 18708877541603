(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ExtensionSettingDialogController', ExtensionSettingDialogController);

    ExtensionSettingDialogController.$inject = ['UidService', '$state', '$timeout', '$scope', 'Qinius', '$q', 'entity', 'MessageService', 'Store', 'Principal'];

    function ExtensionSettingDialogController(UidService, $state, $timeout, $scope, Qinius, $q, entity, MessageService, Store, Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_BRANCH_SHOP').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.store = entity;
        console.log(vm.store)
        // return
        vm.clear = clear;
        vm.save = save;
        if (!vm.store.extensionSetting) {
            vm.store.extensionSetting = {};
        }
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        $q.all([vm.store.$promise, vm.tokens.$promise, vm.domains.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            if (vm.store.extensionSetting.pickMethod == undefined || vm.store.extensionSetting.pickMethod == null) {
                vm.pickMethod1 = false;
                vm.pickMethod2 = false;
            } else if (vm.store.extensionSetting.pickMethod == 'PERCENT') {
                vm.pickMethod1 = true;
                vm.pickMethod2 = false;
            } else if (vm.store.extensionSetting.pickMethod == 'MONEY') {
                vm.pickMethod1 = false;
                vm.pickMethod2 = true;
            }
              if (vm.store.extensionSetting.pickType == 'Product') {
                vm.pickType1 = true;
                vm.pickType2 = false;
            } else if (vm.store.extensionSetting.pickType == 'SalesOrder') {
                vm.pickType1 = false;
                vm.pickType2 = true;
            }
            getDirection();
        });

        function getDirection() {
            vm.icon = {x: 390, y: 170, width: 177, height: 184}
            vm.code = {x: 320, y: 680, width: 200, height: 200}
            vm.text = {x: 436, y: 400, width: 92, height: 22}
            var setting = vm.store.extensionSetting;
            if (vm.store.extensionSetting.backImg) {
                setTimeout(function () {
                    vm.ratio = $("#back-img").width() / 711;
                    if(setting.headX!=null){
                        vm.icon.x = setting.headX;
                        vm.icon.y = setting.headY;
                        vm.text.x = setting.nameX;
                        vm.text.y = setting.nameY-22;
                        vm.code.x = setting.qrCodeX;
                        vm.code.y = setting.qrCodeY;
                    }
                    console.log(vm.ratio)
                })
            }
        }

        // 素材鼠标事件
        var dragBox = {};
        var drag = false;
        $scope.moveStart = function (e, item) {
            fn(e);

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;
            dragBox.coordX = item.x * vm.ratio;
            dragBox.coordY = item.y * vm.ratio;
            dragBox.slideW = $("#back-img").width();
            dragBox.slideH = $("#back-img").height();
            drag = true;
            //鼠标移动
            window.onmousemove = function (e) {
                fn(e);
                $scope.$digest();
                if (drag) {
                    var clientX = e.clientX - dragBox.clientX;
                    var clientY = e.clientY - dragBox.clientY;

                    var offsetX = (dragBox.coordX + clientX);
                    var offsetY = (dragBox.coordY + clientY);
                    var imgWidth = item.width * vm.ratio;
                    var imgHeight = item.height * vm.ratio;

                    if (offsetX <= 0) {
                        offsetX = 0;
                    }

                    if (offsetX >= dragBox.slideW - imgWidth) {
                        offsetX = dragBox.slideW - imgWidth;
                    }

                    if (offsetY <= 0) {
                        offsetY = 0;
                    }

                    if (offsetY >= (dragBox.slideH - imgHeight)) {
                        offsetY = dragBox.slideH - imgHeight;
                    }
                    item.x = parseInt(offsetX / vm.ratio);
                    item.y = parseInt(offsetY / vm.ratio);

                }
                return false;
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                drag = false;
                dragBox = {};
                $event.stopPropagation();
                $scope.$digest();
            }
        };

        //防止事件冒泡，默认事件
        function fn(e) {
            // e = e || window.event;
            // e.cancelBubble = true;
            // e.stopPropagation();
            // e.returnValue = false;
            // e.preventDefault();
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        // 窗口宽高变化
        window.onresize = function () {
            vm.ratio = $("#back-img").width() / 711;
            $scope.$digest();
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go("extension-setting");
        }

        function save() {
            if (vm.store.extensionSetting.branchStorePercent + vm.store.extensionSetting.guidePercent > 100) {
                MessageService.error("分店提成+导购提成不能超过100%！")
                return
            }
            if (vm.pickMethod1) {
                vm.store.extensionSetting.pickMethod = "PERCENT";
            } else if (vm.pickMethod2) {
                vm.store.extensionSetting.pickMethod = "MONEY";
            }
            if (vm.pickType1) {
                vm.store.extensionSetting.pickType = "Product";
            } else if (vm.pickType2) {
                vm.store.extensionSetting.pickType = "SalesOrder";
            }
            // headX;头像x坐标 headY;头像y坐标
            // nameX;姓名x坐标 nameY;姓名y坐标
            // qrCodeX;二维码x坐标 qrCodeY;二维码y坐标
            if(vm.store.extensionSetting.backImg){
                vm.store.extensionSetting.headX = vm.icon.x;
                vm.store.extensionSetting.headY = vm.icon.y;
                vm.store.extensionSetting.nameX = vm.text.x;
                vm.store.extensionSetting.nameY = vm.text.y+22;
                vm.store.extensionSetting.qrCodeX = vm.code.x;
                vm.store.extensionSetting.qrCodeY = vm.code.y;
            }
            console.log(vm.store);
            // return
            if (vm.store.id !== null) {
                Store.update(vm.store, onSaveSuccess, onSaveError);
            } else {
                Store.create(vm.store, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            MessageService.success("操作完成");
            vm.isSaving = false;
            $state.go("extension-setting", {}, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $scope.clickPickMethod = function (attr) {

            if (attr == 'PERCENT') {
                vm.pickMethod2 = !vm.pickMethod1;
            } else {
                vm.pickMethod1 = !vm.pickMethod2;
            }
            console.log(attr)
        }


        $scope.clickPickType = function (attr) {
            if (attr == 'Product') {
                vm.pickType2 = !vm.pickType1;
            } else {
                vm.pickType1 = !vm.pickType2;
            }
        }

        // itemvalue封面图片修改
        window.uploadBackImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                // if (file.size / 1024 >= 1024) {
                //     MessageService.error("建议图片大小在1024K以内");
                //     return
                // }

                var url = window.URL || window.webkitURL;
                var img = new Image();              //手动创建一个Image对象
                img.src = url.createObjectURL(file);//创建Image的对象的url
                img.onload = function () {
                    // 建议尺寸711px*1130px,1024K以内
                    var w = Math.abs(this.width-711);
                    var h = Math.abs(this.height-1130);
                    // if(w>11||h>11){
                    //     MessageService.error("建议图片尺寸：711px*1130px");
                    //     return
                    // }
                    console.log("w: "+w+" h: "+h);
                    console.log('height:'+this.height+'----width:'+this.width)
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', 'back-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            vm.store.extensionSetting.backImg = data.key;
                            getDirection();
                            $scope.$digest();
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                }
            });
        };

        $scope.removeBackImg = function () {
            vm.store.extensionSetting.backImg = null;
            vm.store.extensionSetting.headX = null;
            vm.store.extensionSetting.headY = null;
            vm.store.extensionSetting.nameX = null;
            vm.store.extensionSetting.nameY = null;
            vm.store.extensionSetting.qrCodeX = null;
            vm.store.extensionSetting.qrCodeY = null;
        }


        $scope.goToReadImage = function () {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image").removeClass('hide1');
            $(".modal-content").addClass('opacity-zero');
            // $(".origin-image img").attr('src', pic);
        }

        $scope.backTo = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow", "auto");
        };

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow", "auto");
        }

        window.uploadEntryImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var url = window.URL || window.webkitURL;
                var img = new Image();              //手动创建一个Image对象
                img.src = url.createObjectURL(file);//创建Image的对象的url
                img.onload = function () {
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', 'back-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            vm.store.extensionSetting.entryImg = data.key;
                            getDirection();
                            $scope.$digest();
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                }
            });
        };

        $scope.changeShape = function (v) {
            vm.store.extensionSetting.circle = v;
            if(v){
                $(".headIcon").css("border-radius", "50%");
            }else {
                $(".headIcon").css("border-radius", "0%");
            }
        };
    }
})();
