(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ItemValueLinePackageController', ItemValueLinePackageController);

    ItemValueLinePackageController.$inject = ['Product','pagingParams','paginationConstants','ItemValueLinePackageView','ParseLinks','MessageService','$state', 'Principal'];

    function ItemValueLinePackageController(Product,pagingParams,paginationConstants,ItemValueLinePackageView,ParseLinks,MessageService,$state, Principal) {

        var vm = this;
        Principal.hasAuthority('ROLE_STORE_INVENTORY').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        vm.itemValueLinePackages = [];
        vm.products = Product.getAllByCondition({items:[]})||[];
        vm.productId = pagingParams.productId?Number(pagingParams.productId):null;

        loadAll();

        function loadAll() {
            var items = []
            if(vm.productId){
                items.push({key:"pId",op:"=",value:vm.productId});
            }

            ItemValueLinePackageView.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.itemValueLinePackageViews = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("库存获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                productId:vm.productId
            });
        }

        function search(searchQuery) {
            if (!vm.productId){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0  ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
    }
})();
