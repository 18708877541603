(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomersCheakBound', CustomersCheakBound);

        CustomersCheakBound.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MessageService', 'PayCode', 'VerifyCode',
        '$interval','pagingParams', 'paginationConstants','ParseLinks','Store', '$http', 'DateUtils','PayCodeRecord'];

    function CustomersCheakBound ($timeout, $scope, $stateParams, $uibModalInstance, entity, MessageService, PayCode, VerifyCode,
        $interval,pagingParams, paginationConstants,ParseLinks, Store, $http, DateUtils,PayCodeRecord) {
        var vm = this;
        vm.close = close;
        vm.storeUserId = entity
        vm.loadPage = loadPage;
        vm.itemsPerPage = 10;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.page = 0;
        vm.store = Store.getCurrent();
        vm.payCodeRecord =[];
        if(vm.storeUserId !=null){
            vm.payCodeRecord = PayCodeRecord.get(
                {
                    userId: vm.storeUserId
                }
            )
            console.log(vm.payCodeRecord)
        }
        loadAll();
        function loadAll() {
            PayCode.byCondition4({
                items: [{key:"usedUserId",op:"=",value:vm.storeUserId},{key: "used", op: "=", value: "false"}],
                page: vm.page,
                size: vm.itemsPerPage,
                // sort: ['id','desc']
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.data = data;
                vm.page = vm.page;
            }
            function onError(error) {
                MessageService.error("用户支付码列表查询失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
        }

        vm.transition = function(){
            loadAll();
        }


        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.reverse = true;
        }

        function close () {
            $uibModalInstance.dismiss(false);
        }
        $scope.delete = function(code){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                PayCode.delete({code: code},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };


        $scope.unbinding = function (code){
            MessageService.confirm("确认要解绑该兑换码？",function(){
                PayCode.unbind({code:code},function(data){
                    if (data.status == 200) {
                        MessageService.success("解绑成功");
                        loadAll();
                        $uibModalInstance.dismiss('cancel');
                    }else{
                       MessageService.error(data.message);
                    }
                },function(){
                    MessageService.error("解绑失败");
                })
            })
        }

        $scope.export = function () {
            var params = "storeId=" + vm.store.id + "&usedUserId=" + vm.storeUserId + "&used=" + false;
            $http({
                url: 'service/api/pay-codes/exportDate?' + params,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "支付码" + DateUtils.convertLocalDateToServer(Date.now()) + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
            // window.open("service/api/pay-codes/exportDate?" + params);
        }
    }
})();
