(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayOrderDetail1Controller', PayOrderDetail1Controller);

    PayOrderDetail1Controller.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'PayOrder', '$http', 'MessageService', '$sce'];

    function PayOrderDetail1Controller ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, PayOrder, $http, MessageService, $sce) {
        var vm = this;
        vm.store = Store.getCurrent({});
        vm.pay = entity;
        vm.cancel = cancel;
        vm.isPay = false;
        vm.payOrderId = "";


        $scope.recharge = function() {
            if (vm.store.balance !=0 && vm.store.balance >= 100000000) {
                MessageService.error("当前账户余额较多，请消费后再充值");
                return;
            }
            vm.pay.amount = Math.floor(vm.pay.amount * 100) / 100;
            clearInterval($scope.timer);
            var data = {
                type: vm.pay.type,
                amount: vm.pay.amount,
                name: "商户充值"
            }
            PayOrder.createPayOrder2(data ,function(result){
                vm.payOrderId = result.id;
                if (result.type == "WeChat") {
                    $http({
                        url: '/manager/api/pay-orders/createQRCode/'+vm.payOrderId,
                        method: 'POST',
                        responseType: 'blob'
                    }).success(function(res) {
                        vm.isPay = true;
                        var blob = new Blob([res], {
                            type: "image/jpeg"
                        });
                        $scope.image = URL.createObjectURL(blob);
                        //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                        $scope.timer = setInterval(function() {
                            PayOrder.get({
                                id: vm.payOrderId
                            }, function(val) {
                                if (!!val && val.paid) {
                                    clearInterval($scope.timer);
                                    MessageService.success("支付完成");
                                    vm.isPay = false;
                                    $state.go('pay-order');
                                }
                            }, function() {});
                        }, 2000);
                    })
                }else{
                    PayOrder.aliPay({
                        id:result.id,
                        returnUrl:window.location.href
                    },function(data){
                        if(!!data && data.status == 200){
                            $scope.trustHtml = $sce.trustAsHtml(data.message);
                        }
                    },function(){
                    });
                }
            },function(){
                MessageService.error("创建充值订单失败，请稍后再试");
            })
        }

        $scope.savePay = function () {
            PayOrder.get({
                id: vm.payOrderId
            }, function(val) {
                clearInterval($scope.timer);
                vm.isPay = false;
                if (!!val && val.paid) {
                    $state.go('pay-order');
                    MessageService.success("支付完成");
                }else {
                    MessageService.error("支付失败");
                }
            }, function() {
                MessageService.error("支付失败1");
            });
        }
        $scope.cancelPay = function () {
            clearInterval($scope.timer);
            vm.isPay = false;
        }

        $scope.delete = function () {
            MessageService.confirm("确认取消当前支付信息吗？",function(){
                PayOrder.delete({id: vm.pay.id},function () {
                    $state.go('pay-order')
                    MessageService.success('取消成功');
                });
            },function(){},true);
        }


        //返回
        function cancel() {
            $state.go('pay-order')
        }


        vm.type = type;
        function type(target) {
            switch (target) {
                case "WeChat":
                    return "微信";
                default:
                    return "";
            }
        }
    }
})();
