(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('lineToTheDetailController', lineToTheDetailController);
        lineToTheDetailController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', '$uibModal', 'StorePickLocations'];
    
        function lineToTheDetailController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams,   $uibModal, StorePickLocations) {
            var vm = this;
    
            vm.store = entity;
            vm.selectTab = 1;
            //代运营 保存
            vm.save2 = save2; 
    
            var flag = true;
            window.showSkuList = function (e) {
                fn(e);
                var targ = e.target ? e.target : e.srcElement;
                var index = targ.dataset.index;
                if(flag){
                    flag = false;
                    $(".paybatch-proName").addClass('text-decoration-underline');
                    $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
                }
            }
    
            window.hideSkuList = function (e) {
                fn(e);
                $(".paybatch-tip").hide();
                $(".paybatch-proName").removeClass('text-decoration-underline');
                flag = true;
            }
    
            //防止事件冒泡，默认事件
            function fn(e) {
                e = e || window.event;
                e.cancelBubble = true;
                e.stopPropagation();
                e.returnValue = false;
                e.preventDefault();
            } 
    
            $timeout(function (){
                angular.element('.form-group:eq(1)>input').focus();
            });
            $q.all([vm.store.$promise]).then(function(){

            });
                 
    
            function save2 () {
                if(vm.store.selfLift && (!vm.storePickLocations || vm.storePickLocations.length == 0)){
                    MessageService.error("请添加自提地址");
                    return
                }
                Store.update(vm.store , onSaveSuccess2, onSaveError);
            }
    
            function onSaveSuccess2 (result) {
                $scope.$emit('backApp:storeUpdate', result);
                vm.isSaving = false;
                MessageService.success("保存成功！");
    
                $state.go("lineToThe", null, { reload: 'lineToThe' });
            }
    
            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("保存失败！");
            }
    
            //自提地点
            vm.storePickLocations = [];
            getStorePickLocation();
            function getStorePickLocation(){
                StorePickLocations.getAllByCondition({items:[]}, function (res) {
                    vm.storePickLocations = res;
                })
            }
            $scope.addSelf = function () {
                $uibModal.open({
                    templateUrl: 'app/entities/lineToThe/self-modal.html',
                    controller: 'SelfModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function() {
                            return {};
                        }]
                    }
                }).result.then(function(res) {
                    console.log(res)
                    if(res){
                        getStorePickLocation();
                    }
                });
            }
            $scope.editSelf = function (data) {
                $uibModal.open({
                    templateUrl: 'app/entities/lineToThe/self-modal.html',
                    controller: 'SelfModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function() {
                            return data;
                        }]
                    }
                }).result.then(function(res) {
                    console.log(res)
                    if(res){
                        getStorePickLocation();
                    }
                });
            }
            $scope.deleteSelf = function (data) {
                StorePickLocations.delete({id: data.id}, function (res) {
                    MessageService.success("删除成功");
                    getStorePickLocation();
                },function (error) {
                    MessageService.error("删除失败");
                })
            }
        }
    })();
    