(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountModePaymentByteController', OpenAccountModePaymentByteController);

    OpenAccountModePaymentByteController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', 'StoreByteDances', 'Store', 'MessageService', 'UidService','$q'];

    function OpenAccountModePaymentByteController($state, $scope, $timeout, Qinius, $uibModal, StoreByteDances, Store, MessageService, UidService,$q) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.StoreByteDance = StoreByteDances.storeGetOne();

        $q.all([vm.StoreByteDance.$promise]).then(function () {
        });

        $scope.save = function () {
            if(!vm.StoreByteDance.authorizerAppId){
                MessageService.error("appId不能为空");
                return
            }
            if(!vm.StoreByteDance.authorizerSecret){
                MessageService.error("secret不能为空");
                return
            }
            if (vm.StoreByteDance.id) {
                StoreByteDances.update(vm.StoreByteDance,function (res) {
                    MessageService.success("配置成功");
                    $scope.skip();
                },function (error) {
                    MessageService.error("配置失败，请稍后重试！");
                });
            }else{
                StoreByteDances.save(vm.StoreByteDance,function (res) {
                    MessageService.success("配置成功");
                    $scope.skip();
                },function (error) {
                    MessageService.error("配置失败，请稍后重试！");
                });
            }
        }

     
        $scope.skip = function () {
            $state.go('openAccount-style');
        }
        $scope.goBack = function () {
            window.history.go(-1);
        }
    }

})();
