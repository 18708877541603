(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('FontXmls', FontXmls);

    FontXmls.$inject = ['$resource'];

    function FontXmls ($resource) {
        var resourceUrl =  'manager/' + 'api/font-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete': {
                method: 'DELETE'
            }, 
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            }, 
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            "approve":{
                method:"GET",
                params:{
                    path:"approve"
                }
            },
            "reject":{
                method:"GET",
                params:{
                    path:"reject"
                },
            },
            "receive":{
                method:"POST",
                params:{
                    path:"receive"
                },
            }
        });
    }
})();
