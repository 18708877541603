(function () {
    'use strict';
    angular
        .module('backApp')
        .factory('StoreAbutments', StoreAbutments);

    StoreAbutments.$inject = ['$resource'];

    function StoreAbutments($resource) {
        var resourceUrl = 'manager/' + 'api/store-abutments/:path/:id';

        return $resource(resourceUrl, {}, {
            'getCategoryAndProductDetails': {
                method: 'GET',
                params: {
                    path: 'getCategoryAndProductDetails'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
            'getOne': {
                method: 'GET',
                params: {
                    path: 'getOne'
                }
            },
            'save': {
                method: 'POST'
            },
            'getCategories': {
                method: 'GET',
                params: {
                    path: 'getCategories'
                }
            },
            'getProducts': {
                method: 'GET',
                params: {
                    path: 'getProducts'
                }
            },
            'getSkuSpecs': {
                method: 'GET',
                params: {
                    path: 'getSkuSpecs'
                }
            }
        });
    }
})();
