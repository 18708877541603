(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ScantlingAnalysisController', ScantlingAnalysisController);

    ScantlingAnalysisController.$inject = ['$scope', '$state', 'ParseLinks', 'MessageService', 'SalesOrder', 'XlsxService', 'Product', 'Store', 'DateUtils','ProductXml','$http','Principal'];

    function ScantlingAnalysisController ($scope, $state, ParseLinks, MessageService, SalesOrder, XlsxService, Product, Store, DateUtils,ProductXml,$http,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DATASTATISTICS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.datePickerOpenStatus = {};
        vm.openCalendar =  openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.transition = transition;
        vm.search = search;
        vm.clear = clear;
        vm.downLoadFile = downLoadFile;
        vm.startDate = "";
        vm.endDate = "";
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.data = {
            storeId:'',
            startDate:'',
            endDate:'',
            productType:'',
            page:0,
            pageSize:20
        };
        vm.page = 1;

        vm.productTypes = [
            {name:'标准印品', id:'StandardPrint'},
            {name:'标准通品', id:'Standard'},
            {name:'ip衍生品', id:'IPDerive'}
        ];
        Store.getCurrent({}, function (res) {
            vm.data.storeId = res.id;
            init();
        });
        function init() {
            vm.tableData = [];
            vm.data.page = vm.page-1;
            vm.data.startDate = DateUtils.convertLocalDateToServer(vm.startDate);
            vm.data.endDate = DateUtils.convertLocalDateToServer(vm.endDate);
            SalesOrder.byConditionPageForProductType(vm.data,function (res) {
                if(res.status == 200){
                    vm.totalItems = res.message.count;
                    vm.tableData = res.message.productVMS;
                }
            })
        }
        function transition() {
            init();
        }
        function search() {
            vm.page = 1;
            init();
        }
        function clear() {
            vm.page = 1;
            vm.data = {
                startDate:'',
                endDate:'',
                productType:'',
                page:0,
                pageSize:20
            };
            vm.startDate = "";
            vm.endDate = "";
            init();
        }
        function downLoadFile(){
            vm.data.page = vm.page-1;
            vm.data.startDate = DateUtils.convertLocalDateToServer(vm.data.startDate);
            vm.data.endDate = DateUtils.convertLocalDateToServer(vm.data.endDate);
            $http({
                url: 'service/api/sales-orders/byConditionPageForProductType/download',
                method: "GET",
                params: vm.data,
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "标品定价统计" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
                }).error(function (data, status, headers, config) {
            });
        }
        vm.productTypeStatus = productTypeStatus;
        function productTypeStatus(target) {
            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "StandardPrint":
                    return "标准印品";
                case "Standard":
                    return "标准通品";
                case "IPDerive":
                    return "ip衍生品";
                default:
                    return "";
            }
        }
    }
})();
