(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('WithdrawPutController', WithdrawPutController);

    WithdrawPutController.$inject = ['$state', 'ProductPurchase', 'ParseLinks',  'paginationConstants', 'MessageService'
    ,'$scope', 'CashWalletApplications', 'Principal'];

    function WithdrawPutController($state, ProductPurchase, ParseLinks,   paginationConstants, MessageService,
        $scope, CashWalletApplications,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_RECHARGE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.jump = jump;
        vm.search = search;
        vm.status = status;
        vm.statusOn = "";
        vm.searchQuery = "";
        vm.statusList = [
            {name:'全部',id:""},
            {name:'待处理',id:"Created"},
            {name:'已通过',id:"Approved"},
            {name:'已作废',id:"Rejected"},
        ];
        init();
        function init(){
            var items = [];
            if(vm.statusOn){
                items.push({key: "status", op: "=", value: vm.statusOn})
            }
            if(vm.searchQuery){
                items.push({key: "guide.customer.realName", op: "like", value: vm.searchQuery})
            }
            CashWalletApplications.byCondition({
                items: items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tableData = res;
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        function transition(){
            init()
        }
        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            init();
            vm.jumpText = "";
        }

        $scope.shopCb = function () {
            console.log(vm.shopId)
        };
        function search(){
            vm.page = 1;
            init();
        }
        $scope.byStatus = function(item){
            vm.statusOn = item.id;
            vm.page = 1;
            init();
        }
        //通过
        $scope.pass = function (item) {
            CashWalletApplications.approve({id:item.id}, function (res) {
                if(res.status == 200){
                    MessageService.success("通过成功");
                    vm.page = 1;
                    init();
                }else {
                    MessageService.error(res.message);
                }
            }, function (error) {
                MessageService.error("通过失败，请稍后重试");
            });
        }
        //拒绝
        $scope.refuse = function (item) {
            CashWalletApplications.reject({id:item.id}, function (res) {
                MessageService.success("已拒绝");
                vm.page = 1;
                init();
            }, function (error) {
                MessageService.error("拒绝失败，请稍后重试");
            });
        };
        //线下发放
        $scope.offLineGrant = function (item) {
            CashWalletApplications.offLineGrant({id:item.id}, function (res) {
                MessageService.success("操作成功");
                vm.page = 1;
                init();
            }, function (error) {
                MessageService.error("操作失败，请稍后重试");
            });
        };
        //全选
        vm.selectAll = false;
        $scope.chooseAll = function () {
            vm.selectAll = !vm.selectAll;
            for(var i=0; i<vm.tableData.length; i++){
                vm.tableData[i].selected = vm.selectAll;
            }
            chooseInit()
        }
        $scope.chooseCb = function (item) {
            item.selected = !item.selected ? true : false;
            chooseInit()
        }
        function chooseInit() {
            vm.pitch = [];
            for(var i=0; i<vm.tableData.length; i++){
                if(vm.tableData[i].selected){
                    vm.pitch.push(vm.tableData[i].id)
                }
            }
            if(vm.pitch.length == vm.tableData.length){
                vm.selectAll = true;
            }else {
                vm.selectAll = false;
            }
        }

        $scope.passAllCb = function () {
            CashWalletApplications.batchApprove({ids:vm.pitch.join(",")}, function (res) {
                if(res.status == 200){
                    MessageService.success("批量审核通过");
                    vm.page = 1;
                    init();
                }else {
                    MessageService.error(res.message);
                }
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }


        function status(target) {
            switch (target) {
                case "Creating":
                    return "新建";
                case "Created":
                    return "已创建";
                case "Approved":
                    return "已通过";
                case "Cancelled":
                    return "已取消";
                case "Rejected":
                    return "已拒绝";
                case "Fail":
                    return "失败";
                default:
                    return "";
            }
        }
    }
})();
