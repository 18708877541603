(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Pass', Pass)
        .factory('MembershipOrder', MembershipOrder)
        .factory('PassMemberSetting', PassMemberSetting);

    Pass.$inject = ['$resource'];
    PassMemberSetting.$inject = ['$resource'];
    MembershipOrder.$inject = ['$resource'];

    function Pass ($resource) {
         var resourceUrl =  'service/' + 'api/passes/:path/:id';

         return $resource(resourceUrl, {}, {
               'byCondition': {
                 method: 'POST',
                 params: {
                     path: 'byCondition'
                 },
                 isArray: true
             },
             "getAllByCondition":{
                 method:"POST",
                 params:{
                     path:"getAllByCondition1"
                 },
                 isArray: true
             },
         });
     }

    function MembershipOrder ($resource) {
         var resourceUrl =  'service/' + 'api/pass-membership-orders/:path/:id';

         return $resource(resourceUrl, {}, {
               'byCondition': {
                 method: 'POST',
                 params: {
                     path: 'byCondition1'
                 },
                 isArray: true
             },
             "getAllByCondition":{
                 method:"POST",
                 params:{
                     path:"getAllByCondition1"
                 },
                 isArray: true
             },
         });
     }

     function PassMemberSetting ($resource) {
         var resourceUrl =  'manager/' + 'api/pass-membership-settings/:path/:id';

         return $resource(resourceUrl, {}, {
             'query': { method: 'GET', isArray: true},
             'get': {
                 method: 'GET',
                 params: {
                     path: "getByStore"
                 },
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'update': { method:'PUT' },
         });
     }
})();
