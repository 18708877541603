(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreRechargeSettingDialogController', StoreRechargeSettingDialogController);

    StoreRechargeSettingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'StoreRechargeSetting', 'MessageService'];

    function StoreRechargeSettingDialogController ($timeout, $scope, $stateParams, $state, entity, StoreRechargeSetting, MessageService) {
        var vm = this;

        vm.storeRechargeSetting = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('store-recharge-setting', null, { reload: 'store-recharge-setting' });
        }

        $scope.validate = function(){
            if(vm.storeRechargeSetting.name == null || vm.storeRechargeSetting.name==""){
                MessageService.error("请输入名称");
                return false;
            }
            if(vm.storeRechargeSetting.amount == null || vm.storeRechargeSetting.amount==""){
                MessageService.error("请输入充值金额");
                return false;
            }
            if(vm.storeRechargeSetting.donation == null || vm.storeRechargeSetting.donation==""){
                MessageService.error("请输入赠送金额");
                return false;
            }
            if(vm.storeRechargeSetting.seq == null || vm.storeRechargeSetting.seq==""){
                MessageService.error("请输入排序");
                return false;
            }

            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.storeRechargeSetting.id !== null) {
                StoreRechargeSetting.update(vm.storeRechargeSetting, onSaveSuccess, onSaveError);
            } else {
                StoreRechargeSetting.save(vm.storeRechargeSetting, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:storeRechargeSettingUpdate', result);
            $state.go('store-recharge-setting', null, { reload: 'store-recharge-setting' });
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
