(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DataHelperController', DataHelperController);

    DataHelperController.$inject = ['$scope', '$state', '$q', 'MessageService','Principal'];

    function DataHelperController ($scope, $state, $q, MessageService,Principal) {
        var vm = this;

        Principal.hasAuthority('ROLE_STORE_DATASTATISTICS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.imgs=['case-1.png','case-2.png','case-3.png','case-4.png']
    }
})();
