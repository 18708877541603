(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShoppingGuideController', ShoppingGuideController);

    ShoppingGuideController.$inject = ['$state', 'ProductPurchase', 'ParseLinks',  'paginationConstants', 'MessageService'
    ,'$scope', 'Guide', '$uibModal','BranchStore', 'Principal','BranchStoreManagement', '$localStorage'];

    function ShoppingGuideController($state, ProductPurchase, ParseLinks,   paginationConstants, MessageService,
        $scope, Guide, $uibModal,BranchStore, Principal,BranchStoreManagement, $localStorage) {
        var vm = this;
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.jump = jump;
        vm.search = search;
        vm.status = status;
        vm.shops=[];
        // 判断权限
        if($localStorage.user.authorities.indexOf("ROLE_STORE_BRANCH_SHOP") > -1){
            vm.branchStoreId = "";
            init();
        }else {
            if($localStorage.user.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP") > -1){
                subbranchInit();
            }else {
                MessageService.error("不好意思，你不具有导购管理访问的权限！");
                $state.go("home-page");
            }
        }
        BranchStore.getAllByCondition({
            items: [{key: "cancelled", op: "=", value: "false"},{key: "enabled", op: "=", value: "true"}]
        }, function (res) {
            vm.shops = res;
        });

        function subbranchInit() {
            BranchStoreManagement.findByUserId({userId:$localStorage.user.id}, function (res) {
                vm.findByUser = res;
                vm.branchStoreId = res.branchStore.id;
                init();
            })
        }
       
        function init(){
            var items = [];
            if(vm.branchStoreId){
                items.push({key: "branchStore.id", op: "=", value: vm.branchStoreId})
            }
            if(vm.searchQuery){
                items.push({key: "name", op: "like", value: vm.searchQuery})
            }
            Guide.byCondition({
                items: items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tableData = res;
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        function transition(){
            init()
        }
        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            init();
            vm.jumpText = "";
        }

        $scope.shopCb = function () {
            console.log(vm.shopId)
        };
        function search(){
            vm.page = 1;
            init();
        }
        //编辑
        $scope.edit = function (item) {
            $state.go('shoppingGuide-dialog',{id:item.id})
        };
         //查看
        $scope.view = function (item) {
            $state.go('shoppingGuide-view',{id:item.id})
        };
        //通过
        $scope.pass = function (item) {
            Guide.approve({id:item.id}, function (res) {
                MessageService.success("通过成功");
                vm.page = 1;
                init();
            }, function (error) {
                MessageService.error("通过失败，请稍后重试");
            });
        }
        //拒绝
        $scope.refuse = function (item) {
            Guide.reject({id:item.id}, function (res) {
                MessageService.success("已拒绝");
                vm.page = 1;
                init();
            }, function (error) {
                MessageService.error("拒绝失败，请稍后重试");
            });
        }


        function status(target) {
            switch (target) {
                case "Creating":
                    return "新建";
                case "Created":
                    return "已创建";
                case "Approved":
                    return "已通过";
                case "Cancelled":
                    return "已离职";
                case "Rejected":
                    return "已拒绝";
                case "Fail":
                    return "失败";
                default:
                    return "";
            }
        }
    }
})();
