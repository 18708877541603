(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('invoice-location', {
                parent: 'entity',
                url: '/invoice-location',
                data: {
                    authorities: ["ROLE_STORE_INVOICE_EXPRESS"],
                    pageTitle: 'global.menu.entities.ilocationManagement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice-location/invoice-location.html',
                        controller: 'InvoiceLocationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('invoiceLocation');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invoice-location.new', {
                parent: 'invoice-location',
                url: '/new',
                data: {
                    authorities: ["ROLE_STORE_INVOICE_EXPRESS"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice-location/invoice-location-page.html',
                        controller: 'InvoiceLocationPageController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invoiceLocation');
                        return $translate.refresh();
                    }],
                    entity: ['InvoiceLocation', function(InvoiceLocation) {
                        return {
                            title: null,
                            img: null,
                            status: null,
                            cancelled: null,
                            id: null
                        };
                    }]
                }
            })
            .state('invoice-location-detail', {
                parent: 'invoice-location',
                url: '/invoice-location/{id}',
                data: {
                    authorities: ["ROLE_STORE_INVOICE_EXPRESS"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice-location/invoice-location-page.html',
                        controller: 'InvoiceLocationPageController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invoiceLocation');
                        return $translate.refresh();
                    }],
                    entity: ['InvoiceLocation', '$stateParams', function(InvoiceLocation, $stateParams) {
                        return InvoiceLocation.get({
                            id: $stateParams.id
                        }).$promise;
                    }]
                }
            })

    }

})();
