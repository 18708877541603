(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', '$scope', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'MessageService', 'Accounts'];

    function UserManagementController(Principal, $scope, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService, MessageService, Accounts) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.shrink = shrink;
        vm.titleNavbar = titleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;


        loadAll();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });


        $scope.resetPasswords = function (id) {
            MessageService.confirm("global.action.reset", function () {
                Accounts.get({id: id}, function (msg) {
                    AlertService.success("password.messages.resetSuccess");
                })
            }, function () {
            }, true);

        };

        function setActive(user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function shrink() {
            vm.isNavbarCollapsed = true;
        }

        function titleNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function loadAll() {
            if (pagingParams.search) {
                User.byCondition({
                    items: [{
                        key: "login",
                        op: "like",
                        value: pagingParams.search
                    }, {
                        key: "scope",
                        op: "=",
                        value: "back"
                    }],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                User.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    items: [{
                        key: "scope",
                        op: "=",
                        value: "back"
                    }],
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.users = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $scope.delete = function (id) {
            MessageService.confirm("global.action.delete", function () {
                User.delete({
                    id: id
                }, function () {
                    loadAll();
                });
            }, function () {
            }, true);
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
