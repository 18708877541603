(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InitStepOneController', InitStepOneController);

    InitStepOneController.$inject = ['$scope', '$state','UidService', 'Store', 'Qinius', 'MessageService', '$q', '$timeout', '$uibModal'];

    function InitStepOneController ($scope, $state,UidService, Store, Qinius, MessageService, $q, $timeout, $uibModal) {
        var vm = this;

        vm.saveStep = saveStep;
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            if(vm.store.numberRule!=null&&vm.store.numberRule.length==4){
                vm.disable = true;
            }
            // 更新 初始化
            vm.store.initialized = true;
            Store.update(vm.store);
        });

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'step-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    imgSrop($scope.domain + data.key, 300, 160)
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        function imgSrop(url, width, height){
            $uibModal.open({
                templateUrl: 'app/entities/initialization/modal/img-crop.html',
                controller: 'ImgCropController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            url: url,
                            width: width,
                            height: height
                        };
                    }]
                }
            }).result.then(function(data) {
                vm.store.menuLogo = data;
            });
        }

        $scope.removeFile = function() {
            vm.store.menuLogo = null;
        }

        function saveStep() {
            if(vm.store.storeType2==null){
                MessageService.error("商户未设置商户类型");
                return
            }

            if (vm.store.numberRule == null || vm.store.numberRule.length<4 || vm.store.numberRule.length>4) {
                MessageService.error("输入的订单号前缀不符合规则");
                return;
            }
            if (/^([0-9]+)$/.test(vm.store.numberRule.substring(1)) && /[a-zA-Z]+/.test(vm.store.numberRule.substring(0,1))) {
            }else{
                MessageService.error("输入的订单号前缀不符合规则");
                return;
            }
            Store.getAllByCondition({
                items: [{key:"numberRule", op:"=", value:vm.store.numberRule}]
            }, function(data){
                if (vm.store.id !== null) {
                    if ((data.length ==1 && data[0].id != vm.store.id) || data.length>1) {
                        MessageService.error("订单号前缀重复，请重新设定");
                    }else{
                        vm.isSaving = true;
                        Store.update(vm.store, onSaveSuccess, onSaveError);
                    }
                } else {
                    if (data.length >0) {
                        MessageService.error("订单号前缀重复，请重新设定");
                    }else{
                        vm.isSaving = true;
                        Store.save(vm.store, onSaveSuccess, onSaveError);
                    }

                }
            }, function(){
                MessageService.error("检查单号前缀失败，请稍后再试");
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            $scope.jumpOver();
        }

        $scope.jumpOver = function () {
            if(vm.store.storeType2==null){
                $state.go("init-payment", null, { reload: true });
                return
            }
            if(vm.store.storeType2.indexOf('WECHAT')>=0){
                $state.go("init-wechat", null, { reload: true });
                return;
            }else if(vm.store.storeType2.indexOf('MINI')>=0){
                $state.go("init-mini", null, { reload: true });
                return;
            }else{
                $state.go("init-payment", null, { reload: true });
            }
        }

        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }

        window.showTip = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            $(".step-tip-text"+index).show();
        }

        $(".step-tip-text").hide();
        window.hideTip = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            $(".step-tip-text"+index).hide();
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

    }
})();
