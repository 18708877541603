(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'User','$uibModalInstance'];

    function UserManagementDetailController ($stateParams, User, $uibModalInstance) {
        var vm = this;

        vm.load = load;
        vm.user = {};
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.load($stateParams.id);

        function load (id) {
            User.get({id: id,scope:'back'}, function(result) {
                vm.user = result;
            });
        }
    }
})();
