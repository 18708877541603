(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('BannerDialogController', BannerDialogController);

    BannerDialogController.$inject = ['$timeout', '$q', '$scope', '$uibModalInstance', '$state', 'entity', 'Banner', 'MessageService', 'Qinius',
        'UidService', 'BaseBanner', 'Product','Store'];

    function BannerDialogController ($timeout, $q, $scope, $uibModalInstance, $state, entity, Banner, MessageService, Qinius,
                                     UidService, BaseBanner, Product,Store) {
        var vm = this;
        vm.store = Store.getCurrent();
        vm.banner = entity;
        vm.clear = clear;
        vm.save = save;
        vm.status = vm.banner.status;
        vm.saleableCheck = saleableCheck;
        vm.pageIndex = 0;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.baseBanners = BaseBanner.getAllByCondition({items: [{key: "available", op: "=", value: "true"}],sort:['seq,asc']})
        vm.banners = Banner.getAllByCondition({sort:['number,desc']})||[];
        vm.products =  Product.getAllByCondition({items:[{key:"saleable", op :"=", value:"true"}]});
        $q.all([vm.domains.$promise, vm.tokens.$promise,vm.banner.$promise,vm.baseBanners.$promise, vm.products.$promise]).then(function(){
            vm.token = vm.tokens.token;
            if (vm.banner.id && vm.baseBanners.length>0) {
                for (var i = vm.baseBanners.length - 1; i >= 0; i--) {
                    var b =vm.baseBanners[i];
                    if (b.img == vm.banner.img) {
                        b.selected = true;
                    }
                }
            }
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.allPages = Math.ceil(vm.baseBanners.length/6);
        })

        //模板背景图更改
        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "banner-"+ UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        console.log(data);
                        vm.banner.img = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function saleableCheck(num) {
            console.log(num);
            if (num == 1) {
                vm.status = false;
            } else {
                vm.status = true;
            }
        }

        $scope.validate = function(){
            if (vm.banner.img == null || vm.banner.img=="") {
                MessageService.error("请上传展示图片");
                return false;
            };
            return true;
        }

        function save () {
            vm.banner.status = vm.status;
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.banner.id !== null) {
                Banner.update(vm.banner, onSaveSuccess, onSaveError);
            } else {
                vm.banner.number = 1;
                if(vm.banners.length){
                    vm.banner.number = vm.banners[0].number-0+1;
                }
                Banner.save(vm.banner, onSaveSuccess, onSaveError);
            }
        }

        $scope.removeFile = function(file) {
            vm.banner.img = null;
            vm.baseBanners[vm.banner.imgIndex].selected = false;
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:bannerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        //获取选中商品
        $scope.chooseImg = function (data,index) {
            for(var i=0; i<vm.baseBanners.length; i++){
                var banner = vm.baseBanners[i];
                if(data.id==banner.id){
                    data.selected = !data.selected;
                }else{
                    banner.selected = false;
                }
            }

            if(data.selected){
                vm.banner.img = data.img;
                vm.banner.imgIndex = index;
            }else{
                vm.banner.img = null;
            }
        }

        $scope.pageNext = function () {
            vm.pageIndex++;
        }
        $scope.pagePrev = function () {
            vm.pageIndex--;
        }
    }
})();
