(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('AfterSaleProblemDialogController', AfterSaleProblemDialogController);

    AfterSaleProblemDialogController.$inject = ['$timeout', '$scope', '$localStorage', '$state', 'entity',
    'AfterSaleProblem', 'MessageService'];

    function AfterSaleProblemDialogController($timeout, $scope, $localStorage, $state, entity,
        AfterSaleProblem, MessageService) {
        var vm = this;

        vm.afterSaleProblem = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addRows = addRows;
        vm.delete0 = delete0;
        vm.afterProblemList = [];

        var af = {
            'cancelled': false,
            'content': null,
            'createdBy': null,
            'createdDate': null,
            'enabled': true,
            'id': null,
            'lastModifiedBy': null,
            'lastModifiedDate': null,
            'sort': null,
            added: true,
            'superiorId': null
        }

        vm.afterProblemList.push(af);

        function clear() {
            $state.go('after-sale-problem', null, { reload: 'after-sale-problem' });
        }

        function save() {

            if (vm.afterSaleProblem.sort < 1) {
                MessageService.error('序号不得小于1');
                return;
            }
            for (var i = vm.afterProblemList.length - 1; i >= 0; i--) {
                var l = vm.afterProblemList[i];
                if (!l.sort) {
                    MessageService.error('序号不能为零或者空');
                    return;
                }
                if (!l.content) {
                    MessageService.error('内容不能为空');
                    return;
                }
            }
            vm.isSaving = true;
            AfterSaleProblem.problemStoreCreate({
                afterSaleProblem: vm.afterSaleProblem,
                list: vm.afterProblemList,
                storeId: $localStorage.user.storeId
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:afterSaleProblemUpdate', result);
            $state.go('after-sale-problem', null, { reload: 'after-sale-problem' });
            vm.isSaving = false;
            MessageService.success('操作成功');
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error('操作失败');
        }

        function addRows() {
            var af = {
                'cancelled': false,
                'content': null,
                'createdBy': null,
                'createdDate': null,
                'enabled': true,
                'id': null,
                'lastModifiedBy': null,
                'lastModifiedDate': null,
                'sort': null,
                added: true,
                'superiorId': vm.afterSaleProblem.id
            }

            vm.afterProblemList.push(af);
        }

        function delete0(index) {
            vm.afterProblemList.splice(index, 1);
        }
    }
})();
