(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('VipLevelController', VipLevelController);

    VipLevelController.$inject = ['$state', 'VipLevel', '$scope', 'Qinius', '$q', 'VipLevelSetting', 'MessageService','Principal'];

    function VipLevelController($state, VipLevel, $scope, Qinius, $q, VipLevelSetting, MessageService,Principal) {

        var vm = this;
        Principal.hasAuthority('ROLE_STORE_MEMBERSHIPSYS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });

        vm.loadAll = loadAll;
        vm.openBox = openBox;
        vm.saveAlias = saveAlias;
        vm.del = del;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        });

        loadAll();
        function loadAll () {
            vm.vipSetting = VipLevel.get();
        }

        function openBox() {
            VipLevel.saveVipLevel(vm.vipSetting, function(res) {});
        }

        function saveAlias() {
            VipLevel.saveVipLevel(vm.vipSetting, function(res) {
            });
        }

        function del(id) {
            VipLevelSetting.del({'id': id}, function(res) {
                MessageService.success('删除成功！');
                loadAll();
            });
        }

    }
})();
