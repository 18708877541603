(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupOrderController', GroupOrderController);

    GroupOrderController.$inject = ['$scope', '$state', 'CustomerGroupBuy', 'paginationConstants', 'MessageService', 'ParseLinks','pagingParams','$stateParams', 'GroupBuyOrders'];

    function GroupOrderController ($scope, $state, CustomerGroupBuy, paginationConstants, MessageService, ParseLinks,pagingParams,$stateParams,GroupBuyOrders) {
        var vm = this;
        vm.status = status;
        vm.statusOn = $stateParams.status||"REAL";
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;


        vm.statusList = [
            {name:'实物',id:"REAL"},
            {name:'虚拟卡券',id:"VIRTUAL"}
        ];
        init();
        function init(){
            var items = [];
            if(vm.statusOn){
                items.push({key: "customerGroupBuy.goodsType", op: "=", value: vm.statusOn})
            }
            if(vm.searchQuery){
                items.push({key: "orderNumber", op: "like", value: vm.searchQuery})
            }
            GroupBuyOrders.byCondition1({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ["createdDate,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.tableData = res;
                // angular.forEach(res,function (item) {
                //     angular.forEach(item.groupBuyOrders,function (order) {
                //         if(item.userId == order.userId){
                //             order.isLeader = true;
                //         }
                //         order.name = item.name;
                //         order.singleBuy = item.singleBuy;
                //         order.goodsType = item.goodsType;
                //         order.groupBuyStatus = item.groupBuyStatus;
                //         vm.tableData.push(order)
                //     })
                // })
                console.log(vm.tableData)
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        $scope.byStatus = function(item){
            vm.statusOn = item.id;
            search();
        }

        function status(target) {
            switch (target) {
                case "SUCCESS":
                    return "拼团成功";
                case "FAIL":
                    return "拼团失败";
                case "GROUPING":
                    return "拼团中";
                case "OPENAWARD":
                    return "已开奖";
                case "COMPLETED":
                    return "已完成";
                case "CANCELLED":
                    return "已取消";
                case "NEW":
                    return "待支付";
                default:
                    return "";
            }
        }

        $scope.orderStatus = function (item){
            if (!item.paid) {
                return "未支付";
            }
            if (item.paid || item.status=='Refund') {
                return "已支付";
            }

            if (item.status=='Refund') {
                return "已退款";
            }

            if (item.status=='RefundFail') {
                return "退款失败";
            }

            if (item.status=='Divided') {
                return "已瓜分";
            }

            if (item.status=='DividedFail') {
                return "瓜分失败";
            }
        }

        $scope.fahuo = function (item) {
            console.log(item)
            // return
            GroupBuyOrders.createSalesOrder({id:item.id},function (res) {
                if(res.status==200){
                    MessageService.success(res.message)
                }else{
                    MessageService.error(res.message)
                }
            })
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status:vm.statusOn
            });
        }

        function search(searchQuery) {
            console.log(searchQuery)
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
    }
})();
