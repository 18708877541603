(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('History', History)
        .factory('ExpressHistory', ExpressHistory)
        .factory('StoreUsers', StoreUsers)
        .factory('SalesOrder', SalesOrder);

    SalesOrder.$inject = ['$resource'];
    StoreUsers.$inject = ['$resource'];

    ExpressHistory.$inject = ['$resource'];

    History.$inject = ['$resource'];


    function History ($resource) {
        var resourceUrl =  'service/' + 'api/histories/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            }
            // ----------销售分析使用----end-------
        });
    }
    function StoreUsers ($resource) {
        var resourceUrl =  'security/' + 'api/store-users/:path/:id';
        return $resource(resourceUrl, {}, {
            'account': {
                method: 'GET',
                params: {
                    path: 'account'
                },
            },
        });
    }


    function ExpressHistory ($resource) {
        var resourceUrl =  'service/' + 'api/order-express-histories/:path';
        return $resource(resourceUrl, {}, {
            'byOrderId':{
                method: 'GET',
                params: {
                    path: 'byOrderId'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            }
        });
    }


    function SalesOrder ($resource) {
        var resourceUrl =  'service/' + 'api/sales-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'doCheck':{
                method: 'GET',
                    params: {
                    path: 'doCheck'
                }
            },
            'getStatusData':{
                method: 'GET',
                    params: {
                    path: 'getStatusData'
                }
            },
            'sendDeliveryMsg':{
                method: 'GET',
                    params: {
                    path: 'sendDeliveryMsg'
                }
            },
            'deliveryCodeCheck':{
                method: 'GET',
                    params: {
                    path: 'deliveryCodeCheck'
                }
            },
            'updateExpress':{
                method: 'POST',
                    params: {
                    path: 'updateExpress'
                }
            },
            'updateByHandlerExpress':{
                method: 'GET',
                    params: {
                    path: 'updateByHandlerExpress'
                }
            },
            'getTotalData':{
                method: 'GET',
                    params: {
                    path: 'getTotalData'
                }
            },
            'getBranchStoreOrderData':{
                method: 'GET',
                params: {
                    path: 'getBranchStoreOrderData'
                }
            },
            'byConditionPageForProductType':{
                method: 'GET',
                params: {
                    path: 'byConditionPageForProductType'
                }
            },
            'getBranchStoreProductData':{
                method: 'GET',
                    params: {
                    path: 'getBranchStoreProductData'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'byConditionPage': {
                method: 'GET',
                params: {
                    path: 'byConditionPage'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            "updateDelivery":{
                method:"POST",
                params:{
                    path:"updateDelivery"
                }
            },
            "delivery":{
                method:"GET",
                params:{
                    path:"delivery"
                }
            },
            'postForOrder': {
                method: 'GET',
                params: {
                    path: 'postForOrder'
                }
            },
            'allPostForOrder': {
                method: 'GET',
                params: {
                    path: 'allPostForOrder'
                }
            },
            "supportReject":{
                method: 'GET',
                params: {
                    path: 'supportReject'
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getHomeNum': {
                method: 'GET',
                params: {
                    path: 'getHomeNum'
                },
                isArray: true
            },
            "getHomePayAmount":{
                method:"GET",
                params:{
                    path:"getHomePayAmount"
                }
            },
            "getHome2Map":{
                method:"GET",
                params:{
                    path:"getHome2Map"
                }
            },
            "getHomeProduct": {
                method:"GET",
                params: {
                    path:"getHome2Product"
                }
            },
            "checked": {
                method:"GET",
                params: {
                    path:"checked"
                }
            },
            // ----------销售分析使用-----------
            "getOrderNumAndSalesNum": {
                method: "GET",
                params: {
                    path: "getOrderNumAndSalesNum"
                }
            },
            "getOrderNums": {
                method: "GET",
                params: {
                    path: "getOrderNums"
                }
            },
            "yesterdayDate": {
                method: "GET",
                params: {
                    path: "yesterdayDate"
                }
            },
            "getListPage": {
                method: "GET",
                params: {
                    path: "getListPage"
                }
            },
            "getListPage2": {
                method: "GET",
                params: {
                    path: "getListPage2"
                }
            },
            "dateList": {
                method: "GET",
                params: {
                    path: "DateList"
                }
            },
            "getSalesNums": {
                method: "GET",
                params: {
                    path: "getSalesNums"
                }
            },
            "downloadFile": {
                method: "GET",
                params: {
                    path: "downloadFile"
                }
            },
            // ----------销售分析使用----end-------
            "reJustify": {
                method: "GET",
                params: {
                    path: "reJusify"
                }
            },
            "justify": {
                method: "GET",
                params: {
                    path: "justify"
                }
            },
            "productXmlAnalysis2": {
                method: "GET",
                params: {
                    path: "productXmlAnalysis2"
                }
            },
            "productAnalysis2": {
                method: "GET",
                params: {
                    path: "productAnalysis2"
                }
            },
            "rejects": {
                method: "GET",
                params: {
                    path: "rejects"
                }
            },
            "reject": {
                method: "GET",
                params: {
                    path: "reject"
                }
            },
            "approveRefund": {
                method: "GET",
                params: {
                    path: "approveRefund"
                }
            },
            "rejectRefund": {
                method: "GET",
                params: {
                    path: "rejectRefund"
                }
            },
            "getGuideData": {
                method: "GET",
                params: {
                    path: "getGuideData"
                }
            },
            "exportGuideData": {
                method: "GET",
                params: {
                    path: "exportGuideData"
                }
            },
            "download": {
                method: "GET",
                params: {
                    path: "byConditionPage/download"
                }
            },
            'recompose': {
                method: 'GET',
                params: {
                    path: 'recompose'
                }
            },
            'reJustify': {
                method: 'GET',
                params: {
                    path: 'reJustify'
                }
            },
            'forceCheck': {
                method: 'GET',
                params: {
                    path: 'forceCheck'
                }
            }
        });
    }
})();
