(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ExchangeCodeConfig', ExchangeCodeConfig)
        .factory('ExchangeCode', ExchangeCode)
        .factory('ExchangeCodes', ExchangeCodes);

    ExchangeCodeConfig.$inject = ['$resource', 'DateUtils'];
    ExchangeCode.$inject = ['$resource', 'DateUtils'];
    ExchangeCodes.$inject = ['$resource', 'DateUtils'];

    function ExchangeCode ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/exchange-code/:path/:code';

        return $resource(resourceUrl, {}, {
            'createByPayBatches':{
                method: 'GET',
                params: {
                    path: 'createByPayBatches'
                }
            },
            'batchDelete': {
                method: 'GET',
                params: {
                    path: 'batchDelete'
                }
            },
            "payCodeAnalysis":{
                method: 'GET',
                params: {
                    path: 'payCodeAnalysis'
                }
            },
            "unbind":{
                method: 'GET',
                params: {
                    path: 'unbind'
                }
            },
            "exchangeCodeDataByBatch":{
                method: 'GET',
                params: {
                    path: 'exchangeCodeDataByBatch'
                }
            },

            'activateByBatch': {
                method: 'GET',
                params: {
                    path: 'activateByBatch'
                }
            },
            'allByTimeSlot2': {
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot2'
                },
                isArray: true
            },
            'getAllByTimeSlot3':{
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot3'
                }
            },
            "payCodeAnalysisDownload":{
                method: 'GET',
                params: {
                    path: 'payCodeAnalysisDownload'
                }
            },
            'getBuyDateByYesterDay': {
                method: 'GET',
                params: {
                    path: 'getBuyDateByYesterDay'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            'batchCreate': {
                method: 'POST',
                params: {
                    path: 'batchCreate'
                }
            },
            'getAllByStore': {
                method: 'GET',
                params: {
                    path: 'getAllByStore'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getAllByTimeSlot': {
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function ExchangeCodeConfig ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/exchange_code_config/:path/:id';

        return $resource(resourceUrl, {}, {
            'save':{
                method: 'POST'
            },
            "get":{
                method:"GET",
                params:{
                    path:"storeId"
                }
            },
            'update': { method:'PUT' }
        });
    }
    function ExchangeCodes ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/exchange-codes/:path/:code';

        return $resource(resourceUrl, {}, {
            'findByPayBatch':{
                method: 'GET',
                params: {
                    path: 'findByPayBatch'
                },
                isArray: true
            }
        });
    }
})();
