(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DepositController', DepositController);

    DepositController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Guide', 'BranchStore', 'Tariffs'];

    function DepositController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, Guide, BranchStore, Tariffs) {
        var vm = this;
        vm.data = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.amount = "";
        //确定
        function save() {
            if(!vm.amount){
                MessageService.error("请输入提现金额");
                return
            }
            BranchStore.updateWithdrawal({id: vm.data.id, amount:vm.amount}, function (res) {
                MessageService.success("提现成功");
                $uibModalInstance.close(true);
            }, function (error) {
                MessageService.error("提现失败");
            })
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();
