(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RowArtificialController', RowArtificialController);

    RowArtificialController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$q',
     'MessageService', 'Store', 'DateUtils', 'entity', 'SubstitutionSettings','Principal'];

    function RowArtificialController ($state, $timeout, $scope, $stateParams, $q,
         MessageService, Store, DateUtils, entity, SubstitutionSettings,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.store = Store.getCurrent({});

        $q.all([vm.store.$promise ]).then(function () {
            init()
        });
        function init() {
            SubstitutionSettings.getByStore({}, function (res) {
                if(!res.id){
                    SubstitutionSettings.save({
                        enable: false,
                        products: []
                    },function (res) {
                        init()
                    })
                }else {
                    vm.data = res;
                }
            })
        }


        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
    }
})();
