(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShareTheBackground', ShareTheBackground);

    ShareTheBackground.$inject = ['$scope', 'ShareTheBac', '$timeout', 'UidService', 'ShareTheBac2', '$q', '$rootScope', '$stateParams', 'MessageService', 'Store', 'Qinius', 'Principal', '$state'];

    function ShareTheBackground($scope, ShareTheBac, $timeout, UidService, ShareTheBac2, $q, $rootScope, $stateParams, MessageService, Store, Qinius, Principal, $state) {
        var vm = this;
        $scope.shareBgc;
        $scope.radio = ''
        $scope.putShareBgc;
        $scope.defaultImg = 'http://pic-dev.momentsgocdn.com/back-a624bd17-5b17-43ee-c387-1abe40a20a38'
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        vm.getStore = ShareTheBac2.account();
        $q.all([vm.getStore.$promise, vm.tokens.$promise, vm.domains.$promise]).then(function() {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            $scope.getList()
        });
        $scope.getList = function() {
                ShareTheBac.byStoreId({ storeId: vm.getStore.storeId }, function(res) {
                    $scope.shareBgc = res.message;
                    if ($scope.shareBgc.default) {
                        $scope.radio = 0
                        $scope.shareBgc.bcImg = ''
                    } else {
                        $scope.radio = 1
                    }
                })
            }
            // itemvalue封面图片修改
        window.uploadBackImg = function($event) {
            $timeout(function() {
                var file = $event.target.files[0];
                var url = window.URL || window.webkitURL;
                var img = new Image(); //手动创建一个Image对象
                img.src = url.createObjectURL(file); //创建Image的对象的url
                img.onload = function() {
                    var w = Math.abs(this.width - 750);
                    var h = Math.abs(this.height - 1200);
                    console.log("w: " + w + " h: " + h);
                    console.log('height:' + this.height + '----width:' + this.width)
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', 'back-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function(data) {
                            $timeout(function() {
                                $scope.shareBgc.bcImg = $scope.domain + data.key;
                            })
                        },
                        error: function(request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                }
            });
        };

        // 保存数据
        $scope.putShareBgc = function() {
            if ($scope.radio == 0) {
                $scope.shareBgc.default = true
                $scope.shareBgc.bcImg = $scope.defaultImg
            } else {
                $scope.shareBgc.default = false
            }
            if ($scope.shareBgc.bcImg == '') {
                MessageService.error('请上传背景图片');
                return
            }
            ShareTheBac.putShareBgc($scope.shareBgc, function(res) {
                if (res.status == 200) {
                    MessageService.success('保存成功')
                    $scope.getList()
                } else {
                    MessageService.error(res.message == null ? '保存失败' : res.message);
                }
            })
        }
    }
})();
