(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('SelfApplicationController', SelfApplicationController);

    SelfApplicationController.$inject = ['$scope', '$state', 'SelfApplication', '$q', 'AlertService', 'paginationConstants', 'Category', 'MessageService', 
    'Store','Principal'];

    function SelfApplicationController($scope, $state, SelfApplication, $q, AlertService, paginationConstants, Category, MessageService,
     Store,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });

        vm.categories = Category.getAllByCondition({});
        vm.selfApplications = SelfApplication.getAllByCondition({});

        vm.store = Store.getCurrent({});
        loadAll();
        function loadAll() {
            vm.status = 'Creating';
            vm.type = 'Self';
            vm.description = null;
            vm.flag = false;

            $q.all([vm.categories.$promise, vm.selfApplications.$promise]).then(function () {
                if (vm.selfApplications.length == 1 && vm.selfApplications[0].status == 'Rejected') {
                    vm.flag = true;
                }
                angular.forEach(vm.selfApplications, function (self, i) {
                    vm.status = self.status;
                    if (vm.selfApplications.length > 1||vm.selfApplications.length == 1 && vm.selfApplications[0].status == 'Approved') {
                        vm.type = 'Category';
                    }
                })
            })
        }


        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }


        $scope.isSubmitApp = false;
        $scope.applyForSelfApplication = function () {
            $scope.isSubmitApp = !$scope.isSubmitApp;
        }

        $scope.submitApplication = function () {
            if (vm.flag) {
                vm.selfApplications[0].type='Self';
                vm.selfApplications[0].status='Created';
                SelfApplication.update(vm.selfApplications[0], onSaveSuccess, onSaveError);
            } else {
                SelfApplication.save({
                    number: null,
                    type: 'Self',
                    description: null
                }, onSaveSuccess, onSaveError);
            }

        }

        $scope.submitCategories = function () {
            if(vm.description==null){
                MessageService.error("请输入正确的品类！");
                return
            }
            SelfApplication.save({
                number: null,
                type: 'Category',
                description: vm.description
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:selfApplicationsUpdate', result);
            vm.isSaving = false;
            $scope.isSubmitApp = !$scope.isSubmitApp;
            MessageService.success("保存成功");
            $state.go('self-application', {reload: true});
            vm.categories = Category.getAllByCondition({});
            vm.selfApplications = SelfApplication.getAllByCondition({});
        }

        function onSaveError() {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }

        $scope.isAgree = false;
        $scope.agreeContent = function () {
            $scope.isAgree = !$scope.isAgree;
        }
    }
})();
