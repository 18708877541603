(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PasswordActivitySettingController', PasswordActivitySettingController);

    PasswordActivitySettingController.$inject = ['$timeout', '$scope', 'UidService', '$stateParams', '$q', 'MessageService', 'Qinius', '$rootScope', '$state','WechatMiniSettings','$localStorage'];

    function PasswordActivitySettingController ($timeout, $scope, UidService, $stateParams, $q, MessageService, Qinius, $rootScope, $state,WechatMiniSettings,$localStorage) {
        var vm = this;
        vm.save = save;
        vm.clear = clear;
        vm.view = true;
        if ($rootScope && $rootScope.toState && $rootScope.toState.name == 'password-setting-edit') {
            vm.view = false;
        }
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.wechatMiniSettings = WechatMiniSettings.findByStoreId({storeId:$localStorage.user.storeId});
        $q.all([vm.wechatMiniSettings.$promise, vm.domains.$promise, vm.tokens.$promise ]).then(function (){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        });

        window.uploadPic = function (event,tag) {
            var files = event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0],tag);
            }
        }

        function clear(){
            if ($rootScope.toState.name == 'password-setting-edit') {
                $state.go("password-setting");
            }else{
                $state.go("marketing");
            }
        }

        function qiniuUpload(file,tag) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'activity-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.wechatMiniSettings[tag] = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.deletePic = function (tag) {
            console.log(tag)
            vm.wechatMiniSettings[tag] = null;
        }

        function save () {
            vm.isSaving = true;
            WechatMiniSettings.update(vm.wechatMiniSettings, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:passwordActivityUpdate', result);
            $state.go("password-setting");
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }
    }
})();
