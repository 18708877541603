(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayBatchDialog2Controller', PayBatchDialog2Controller);

    PayBatchDialog2Controller.$inject = ['$timeout', '$scope', '$localStorage', '$uibModalInstance', 'entity', 'PayBatch', 'Store', 'Product', 'MessageService', '$q',
    'ItemValueLinePackageView', 'ProductPurchase', 'ItemValueLinePackage', '$stateParams','ListService', 'PayBatchChangeApplication'];

    function PayBatchDialog2Controller ($timeout, $scope, $localStorage, $uibModalInstance, entity, PayBatch, Store, Product, MessageService, $q,
                                       ItemValueLinePackageView, ProductPurchase, ItemValueLinePackage, $stateParams,ListService, PayBatchChangeApplication) {
        var vm = this;

        vm.payBatch = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save2 = save2;// 已分配 编辑保存
        vm.isShow = false;
        vm.isAdd = false;

        vm.select2Options = {
            allowClear: true,
            formatNoMatches: "没有匹配到数据"
        };

        var productNameLater='',productSkuLater='',productPurchaseId=null,packageId=null,isGift=true;

        if(vm.payBatch.prototype=='StoreSelf'){
            vm.products = Product.getAllByCondition4({items: [{key: "prototype", op: "=", value: 'StoreSelf'},{key: "saleable", op: "=", value: true}]})||[];
        }else{
            // vm.products = Product.getAllByCondition4({items: [{key: "prototype", op: "=", value: 'Store'},{key: "saleable", op: "=", value: true}]})||[];
            vm.products = Product.grounded({})||[];
            if(vm.payBatch.purchaseType!='GIFTPURCHASE'){
                isGift = false;
            }
        }

        $q.all([vm.payBatch.$promise, vm.products.$promise]).then(function(){
            if(isGift&&vm.payBatch.payBatchType=='PAYCODE'&&vm.payBatch.used){
                vm.isAdd = true;
                vm.proList =[];
                for (var i = vm.products.length - 1; i >= 0; i--) {
                    var pro = vm.products[i];
                    if (pro.itemValues&&!pro.cancelled) {
                        for (var j = pro.itemValues.length - 1; j >= 0; j--) {
                            var p = pro.itemValues[j];
                            if (p.itemValueLines&&!p.cancelled) {
                                for (var x = p.itemValueLines.length - 1; x >= 0; x--) {
                                    var ivl = p.itemValueLines[x];
                                    var cate = {id:pro.id, name:pro.name, itemValueLines:[], selected :false};
                                    var result = ListService.inList(cate, vm.proList, ["id"]);
                                    if (result) {
                                        result.itemValueLines.push(ivl);
                                    }else{
                                        cate.itemValueLines.push(ivl);
                                        vm.proList.push(cate);
                                    }
                                }
                            }
                        }
                    }
                }

                //平台品
                if(vm.payBatch.productPurchaseId){
                    productPurchaseId = vm.payBatch.productPurchaseId;
                    ProductPurchase.get({id:vm.payBatch.productPurchaseId},function (res) {
                        vm.payBatch.itemValueLines = res.productPurchaseLines;
                        vm.selectedAll = true;
                        vm.oldSelectedAll = true;
                        if(vm.payBatch.itemValueLines && vm.payBatch.itemValueLines.length>0){
                            for(var i=0; i<vm.proList.length; i++){
                                vm.proList[i].selected = true;
                                vm.proList[i].isOld = true;
                                for(var j=0; j<vm.proList[i].itemValueLines.length; j++){
                                    for(var k=0; k<vm.payBatch.itemValueLines.length; k++){
                                        if(vm.payBatch.itemValueLines[k].itemValueLineId == vm.proList[i].itemValueLines[j].id){
                                            vm.proList[i].itemValueLines[j].selected = true;
                                            vm.proList[i].itemValueLines[j].isOld = true;
                                        }
                                    }
                                    if(!vm.proList[i].itemValueLines[j].selected){
                                        vm.proList[i].selected = false;
                                        vm.proList[i].isOld = false;
                                    }
                                }
                                if(!vm.proList[i].selected){
                                    vm.selectedAll = false;
                                    vm.oldSelectedAll = false;
                                }
                            }
                        }else {
                            vm.selectedAll = false;
                            for (var j = vm.proList.length - 1; j >= 0; j--) {
                                vm.proList[j].selected = false;
                                vm.proList[j].isOld = false;
                            }
                        }
                    })
                }else if(vm.payBatch.packageId){
                    //自建品
                    packageId = vm.payBatch.packageId;
                    ItemValueLinePackage.get({id:vm.payBatch.packageId},function (res) {
                        vm.payBatch.itemValueLines = res.itemValueLines;
                        vm.selectedAll = true;
                        vm.oldSelectedAll = true;
                        if(vm.payBatch.itemValueLines && vm.payBatch.itemValueLines.length>0){
                            for(var i=0; i<vm.proList.length; i++){
                                vm.proList[i].selected = true;
                                vm.proList[i].isOld = true;
                                for(var j=0; j<vm.proList[i].itemValueLines.length; j++){
                                    for(var k=0; k<vm.payBatch.itemValueLines.length; k++){
                                        if(vm.payBatch.itemValueLines[k].id == vm.proList[i].itemValueLines[j].id){
                                            vm.proList[i].itemValueLines[j].selected = true;
                                            vm.proList[i].itemValueLines[j].isOld = true;
                                        }
                                    }
                                    if(!vm.proList[i].itemValueLines[j].selected){
                                        vm.proList[i].selected = false;
                                        vm.proList[i].isOld = false;
                                    }
                                }
                                if(!vm.proList[i].selected){
                                    vm.selectedAll = false;
                                    vm.oldSelectedAll = false;
                                }
                            }
                        }else {
                            vm.selectedAll = false;
                            for (var j = vm.proList.length - 1; j >= 0; j--) {
                                vm.proList[j].selected = false;
                                vm.proList[j].isOld = false;
                            }
                        }
                    })
                }
            }

        })

        $scope.selectAll = function(){
            if(vm.oldSelectedAll){
                MessageService.error("原有产品不能删减,已勾选了全部商品");
                return;
            }
            vm.selectedAll = !vm.selectedAll;
            for (var i = vm.proList.length - 1; i >= 0; i--) {
                var ca = vm.proList[i];
                if(!ca.isOld){
                    ca.selected = vm.selectedAll;
                    if (ca.itemValueLines) {
                        for (var j = ca.itemValueLines.length - 1; j >= 0; j--) {
                            var p = ca.itemValueLines[j];
                            if(!p.isOld){
                                p.selected = vm.selectedAll
                            }
                        }
                    }
                }
            }
        }

        $scope.productAll = function(pro){
            if(pro.isOld){
                MessageService.error("原有产品不能删减");
                return;
            }
            pro.selected = !pro.selected;
            if (pro.itemValueLines) {
                for (var i = pro.itemValueLines.length - 1; i >= 0; i--) {
                    var p = pro.itemValueLines[i];
                    p.selected = pro.selected;
                }
            }
            for (var i = vm.proList.length - 1; i >= 0; i--) {
                var arr = vm.proList[i];
                if (!arr.selected) {
                    vm.selectedAll = false;
                    return;
                }
            }
            vm.selectedAll = true;
        }

        $scope.selectProduct = function(pro, ivl){
            if(ivl.isOld){
                MessageService.error("原有产品不能删减");
                return;
            }
            ivl.selected = !ivl.selected;
            for (var i = pro.itemValueLines.length - 1; i >= 0; i--) {
                var p = pro.itemValueLines[i];
                if (!p.selected) {
                    pro.selected = false;
                    vm.selectedAll = false;
                    return;
                }
            }
            pro.selected = true;
            for (var i = vm.proList.length - 1; i >= 0; i--) {
                var arr = vm.proList[i];
                if (!arr.selected) {
                    vm.selectedAll = false;
                    return;
                }
            }
            vm.selectedAll = true;
        }

        $scope.validate = function() {
            if (vm.payBatch.number == null || vm.payBatch.number == "") {
                MessageService.error('请输入批次号');
                return false;
            }
            if (vm.payBatch.payBatchType == null || vm.payBatch.payBatchType == "") {
                MessageService.error('请选择类型');
                return false;
            }
            if (vm.payBatch.productPurchaseId == null || vm.payBatch.productPurchaseId == "") {
                MessageService.error('请选择订购申请');
                return false;
            }
            if(vm.payBatch.payBatchType == 'PAYCODE' && vm.payBatch.purchaseType =='COMMODITYPURCHASE'){
                if (vm.payBatch.productId == null || vm.payBatch.productId == "") {
                    MessageService.error("请选择商品");
                    return false;
                }
                if (vm.payBatch.itemValueLineId == null || vm.payBatch.itemValueLineId == "") {
                    MessageService.error("请选择sku");
                    return false;
                }
            }
            if (vm.payBatch.quantity == null || vm.payBatch.quantity == "") {
                MessageService.error('请输入生成数');
                return false;
            }

            if (vm.payBatch.remark == null || vm.payBatch.remark == "") {
                MessageService.error('请输入用途');
                return false;
            }

            if (!vm.payBatch.startNumber) {
                MessageService.error("起始卡号不能为空");
                return false;
            }
            if (/^([0-9]+)$/.test(vm.payBatch.startNumber.substring(1)) && /[a-zA-Z]+/.test(vm.payBatch.startNumber.substring(0,1))) {
            }else{
                MessageService.error("输入的起始卡号不符合规则");
                return false;
            }
            if (vm.payBatch.startNumber.length>15) {
                MessageService.error("起始卡号不能超过15个字符");
                return false;
            }
            if ( vm.payBatch.periodOfValidity == null || vm.payBatch.periodOfValidity == "") {
                MessageService.error("请输入有效截止时间");
                return false;
            }
            if ( vm.payBatch.startDate == null || vm.payBatch.startDate == "") {
                MessageService.error("请输入有效开始时间");
                return false;
            }
            if (vm.payBatch.periodOfValidity.getTime() < new Date().getTime()) {
                MessageService.error("有效期不能是当天或之前的日期");
                return false;
            }
            // if(vm.payBatch.payBatchType == 'PAYCODE'&&vm.payBatch.length==null||vm.payBatch.length==''){
            //     MessageService.error("请选择卡密位数");
            //     return false;
            // }
            return true;
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save2 () {
            vm.isSaving = true;
            vm.payBatch.prototype = 'Store';
            if(vm.isShow){
                var obj={
                    payBatchId:vm.payBatch.id,
                    productPurchaseId:productPurchaseId,
                    packageId:packageId,
                    productNamePrevious:vm.payBatch.productName,
                    productSkuPrevious:vm.payBatch.productSku,
                    productNameLater:'',
                    productSkuLater:'',
                    itemValueLineIds:[]

                };
                angular.forEach(vm.proList,function (pro) {
                    var isChoose = false;
                    angular.forEach(pro.itemValueLines,function (ivl,i) {
                        if(ivl.selected&&!ivl.isOld){
                            isChoose = true;
                            productSkuLater+=pro.name+'('+ivl.sku+');'
                            obj.itemValueLineIds.push({itemValueLineId:ivl.id})
                        }
                    })
                    if(isChoose){
                        productNameLater+=pro.name+';';
                    }
                })

                if(productNameLater!=''&&productSkuLater!=''){
                    obj.productNameLater = vm.payBatch.productName+";"+productNameLater.substring(0,productNameLater.length-1);
                    obj.productSkuLater = vm.payBatch.productSku+";"+productSkuLater.substring(0,productSkuLater.length-1);
                }else{
                    MessageService.error("请选择新增的产品");
                    return
                }

                PayBatchChangeApplication.save(obj, function (res) {
                    PayBatch.update2(vm.payBatch, onSaveSuccess, onSaveError);
                });

                console.log(obj)
                console.log(vm.proList)
            }else{
                PayBatch.update2(vm.payBatch, onSaveSuccess, onSaveError);
            }
            // PayBatch.update2(vm.payBatch, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:payBatchUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('添加成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');
            MessageService.error("系统请求超时，请稍后刷新系统，确认是否添加成功");
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.addProducts = function () {
            vm.isShow = !vm.isShow;
        }
    }
})();
