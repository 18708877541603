(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ProductTitle', ProductTitle)
        .factory('ProductTitleConfig', ProductTitleConfig)
        .factory('ProductTitleStyle', ProductTitleStyle);

    ProductTitle.$inject = ['$resource'];
    ProductTitleStyle.$inject = ['$resource'];
    ProductTitleConfig.$inject = ['$resource'];

    function ProductTitle ($resource) {
        var resourceUrl =  'manager/' + 'api/product-titles/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'showOrNotShow': {
                method: 'GET',
                params: {
                    path: 'showOrNotShow'
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function ProductTitleStyle ($resource) {
        var resourceUrl =  'manager/' + 'api/product-title-styles/:path/:productId';

        return $resource(resourceUrl, {}, {
            "getProducts":{
                method: 'POST',
                params: {
                    path: 'getProducts'
                },
                isArray: true
            }
        });
    }

     function ProductTitleConfig ($resource) {
        var resourceUrl =  'manager/' + 'api/product-title-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'storeId': {
                method: 'GET',
                params: {
                    path: 'storeId'
                },
            },
            'update': { method:'PUT' }
        });
    }
})();
