(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapPageController', MapPageController);

    MapPageController.$inject = ['$state', 'SalesOrder', 'Customer'];

    function MapPageController($state, SalesOrder, Customer) {
        var vm = this;
        vm.enterFullScreen = enterFullScreen;
        vm.exitFullScreen = exitFullScreen;
        vm.fullFlag = false;

        getHomeNum();
        function getHomeNum() {
        	$(".navbar").hide();
        	$(".sidebar1").hide();
        	$(".sidebar2").hide();
        	$(".sidebar2-shrink").hide();
        }
        
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('main'));

        // 指定图表的配置项和数据
		var data = [];
		var proportion = 0; 
		function convertData () {
		    var res = [];
			SalesOrder.getHome2Map({items:[]}, function (ress) {
				vm.dataList = ress.message;
        		data = ress.message;
        		vm.citylist = ress.message;
        		if (data != null) {
        			// 显示数据按照已有数据的最大值比例显示
        			var maxNum = 0;	// 数据中的最大值
        			// 地图的最大显示值建议为300
        			var mapMaxNum = 300;
        			for (var i = 0; i < data.length; i++) {
        				var geoCoord = citylist[0][data[i].split('-')[0]];
        				if (geoCoord) {
        					var num = Number(Number(data[i].split('-')[1]).toFixed(2));
        					if (num > maxNum) {
        						maxNum = num;
        					}
        				}
        			}

        			// 比例
        			proportion = (mapMaxNum / maxNum).toFixed(2);
        			// 拼接数据
				    for (var i = 0; i < data.length; i++) {
				        var geoCoord = citylist[0][data[i].split('-')[0]];
				        if (geoCoord) {
				        	var num = (Number(Number(data[i].split('-')[1]).toFixed(2)) * proportion).toFixed(2);
				            res.push({
				                name: data[i].split('-')[0],
				                value: geoCoord.concat(num)
				            });
				        }
				    }
        		}
			    // 由于是异步操作所以在获取到数据的时候，刷新地图
			    refreshData(res);
        	})
		    return res;
		};

		var option = {
		    backgroundColor: '#F1F2F7',
		    title: {
		        text: ' | 地区销售排名情况',
		        top: 'bottom',
		        textStyle: {
		            color: '#FF5001'
		        }
		    },
		    tooltip : {
		        trigger: 'item',
		        backgroundColor: '#FF5001',
		        formatter: function (params, ticket, callback) {
				    var num = 0.00;
		        	if (params.value[2] > 0) {
		        		num = (params.value[2] / proportion).toFixed(2);
		        	} 
				    return "<div style='color: #000, text-align: center;'>" + params.name + "</b><br>    成交额¥" + num + "</div>";
				}
		    },
		    legend: {
		        orient: 'vertical',
		        y: 'bottom',
		        x:'right',
		        data:[''],
		        textStyle: {
		            color: '#fff'
		        }
		    },
		    geo: {
		        map: 'china',
		        label: {
		            emphasis: {
		                show: false
		            }
		        },
		        // roam: true,
		        zoom: 1.2,
		        itemStyle: {
		            normal: {
		                areaColor: '#FFFFFF',
		                borderColor: '#111'
		            },
		            emphasis: {
		                areaColor: '#FFFFFF'
		            }
		        }
		    },
		    series : [
		        {
		            name: '',
		            type: 'scatter',
		            coordinateSystem: 'geo',
		            data: convertData(),
		            symbolSize: function (val) {
		                return val[2] / 10;
		            },
		            showEffectOn: 'render',
		            hoverAnimation: true,
		            rippleEffect: {
		            	brushType: 'stroke'
		            },
		            label: {
		                normal: {
		                    formatter: '{b}',
		                    position: 'right',
		                    show: false
		                },
		                emphasis: {
		                    show: true
		                }
		            },
		            itemStyle: {
		                normal: {
		                    color: '#FF5001'
		                }
		            }
		        },
		        {
		            name: '',
		            type: 'effectScatter',
		            coordinateSystem: 'geo',
		            data: convertData(data.sort(function (a, b) {
		                return b.value - a.value;
		            }).slice(0, 6)),
		            symbolSize: function (val) {
		                return val[2] / 10;
		            },
		            showEffectOn: 'render',
		            rippleEffect: {
		                brushType: 'stroke'
		            },
		            hoverAnimation: true,
		            label: {
		                normal: {
		                    formatter: '{b}',
		                    position: 'right',
		                    show: false			// 是否显示城市名
		                }
		            },
		            itemStyle: {
		                normal: {
		                    color: '#FF5001',
		                    shadowBlur: 10,
		                    shadowColor: '#333'
		                }
		            },
		            zlevel: 1
		        }
		    ]
		};

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);

        //进入全屏
		function enterFullScreen() {
			var de = document.documentElement;
			if (de.requestFullscreen) {
                de.requestFullscreen();
            } else if (de.mozRequestFullScreen) {
                de.mozRequestFullScreen();
            } else if (de.webkitRequestFullScreen) {
                de.webkitRequestFullScreen();
            }
            vm.fullFlag = true;
        }
         //退出全屏
         function exitFullScreen() {
            var de = document;
            if (de.exitFullscreen) {
                 de.exitFullscreen();
            } else if (de.mozCancelFullScreen) {
                de.mozCancelFullScreen();
            } else if (de.webkitCancelFullScreen) {
                 de.webkitCancelFullScreen();
            }
            vm.fullFlag = false;
        }

        function refreshData(data){
     		if(!myChart){
          		return;
     		}
		    //更新数据
		    var option = myChart.getOption();
		    option.series[0].data = data;
		    option.series[1].data =  data.sort(function (a, b) {
		    	    return b.value - a.value;
		        }).slice(0, 6);
		    myChart.setOption(option);    
		}
    }
})();
