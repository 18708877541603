(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ProductXmls', ProductXmls)
        .factory('Article', Article)
        .factory('ProductXmls1', ProductXmls1);

    Article.$inject = ['$resource'];
    ProductXmls.$inject = ['$resource'];
    ProductXmls1.$inject = ['$resource'];

    function ProductXmls ($resource) {
        var resourceUrl =  'manager/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "findOne":{
                method:"GET",
                params:{
                    path:"findOne"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        // if (data.modelBlendent) {
                        //     data.modelBlendent = angular.fromJson(data.modelBlendent);
                        // }
                        // if (data.modelData) {
                        //      data.modelData = angular.fromJson(data.modelData);
                        // }
                    }
                    return data;
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
            "showPic":{
                method:"POST",
                params:{
                    path:"showPic"
                },
            },
            "approve":{
                method:"GET",
                params:{
                    path:"approve"
                }
            },
            "themeClassify":{
                method:"GET",
                params:{
                    path:"saveTheme"
                }
            },
            "batchApprove":{
                method:"GET",
                params:{
                    path:"batchApprove"
                }
            },
            "batchEdit":{
                method:"GET",
                params:{
                    path:"batchEdit"
                }
            },
            "batchDelete":{
                method:"GET",
                params:{
                    path:"batchDelete"
                }
            },
            "updateProductXml":{
                method:"GET",
                params:{
                    path:"updateSourceModel",
                },

            },
            "save":{
                method:'POST' ,
                // transformRequest: function (data) {
                //     if (data) {
                //         if (data.modelBlendent) {
                //             data.modelBlendent = angular.toJson(data.modelBlendent);
                //         }
                //         if (data.modelData) {
                //             data.modelData = angular.toJson(data.modelData);
                //         }
                //     }
                //     return angular.toJson(data);
                // }
            },
            'update': {
                method:'PUT' ,
                // transformRequest: function (data) {
                //     if (data) {
                //         if (data.modelBlendent) {
                //             data.modelBlendent = angular.toJson(data.modelBlendent);
                //         }
                //         if (data.modelData) {
                //             data.modelData = angular.toJson(data.modelData);
                //         }
                //     }
                //     return angular.toJson(data);
                // }
            }
        });
    }
    function ProductXmls1 ($resource) {
        var resourceUrl =  'fileserves/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            "upgradeProductXml":{
                method:"GET",
                params:{
                    path:"upgradeProductXml"
                }
            }
        });

    }
    function Article ($resource) {
        var resourceUrl =  'service/' + 'api/articles/:path/:id';
        return $resource(resourceUrl, {}, {
            "invalidByProductXmlId":{
                method:"GET",
                params:{
                    path:"invalidByProductXmlId"
                }
            }
        });

    }
})();
