(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OperationManualController', OperationManualController);

    OperationManualController.$inject = ['$scope', '$q', '$state', 'StoreOperationManuals', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', 'Principal', '$uibModal','Store'];

    function OperationManualController($scope, $q, $state, StoreOperationManuals, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, Principal, $uibModal,Store) {
        var vm = this;
        // Principal.hasAuthority('ROLE_STORE_DIANPU').then(function (data) {
        //     if (!data) {
        //         MessageService.error("不好意思，你不具有访问权限！");
        //         $state.go("home");
        //     }
        // });
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.store = Store.getCurrent();
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.jump = jump
        // 删除
        $scope.delete = function (item) {
            MessageService.confirm("entity.action.deleteConfirm", function () {
                StoreOperationManuals.delete({ id: item.id }, function () {
                    loadAll();
                });
            }, function () { }, true);
        }
        loadAll()
        function loadAll() {
            StoreOperationManuals.byCondition({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ["seq,asc"]
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.items = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("操作手册获取失败");
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: ["seq,asc"],
            });
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        // 创建标题
        $scope.createEditor = function (edit) {
            $uibModal.open({
                templateUrl: 'app/entities/operation-manual/operation-manual-create.html',
                controller: 'OperationManualCreateController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    msgdata: function () {
                        if (edit) {
                            return { id: edit };
                        } else {
                            return {}
                        }

                    }
                }
            }).result.then(function () {
                loadAll()
            });
        }
        // 编辑内容
        $scope.richTextEditor = function (edit) {
            $uibModal.open({
                templateUrl: 'app/entities/operation-manual/operation-manual-richText.html',
                controller: 'OperationManualRichTextController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    msgdata: function () {
                        return { id: edit }
                    }
                }
            }).result.then(function (item) {
                if (item) {
                    loadAll()
                }
            });
        }
        // 预览
        $scope.preview = function (item) {
            $uibModal.open({
                templateUrl: 'app/entities/operation-manual/operation-manual-preview.html',
                controller: 'OperationManualPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    msgdata: function () {
                        return { id: item.id };
                    }
                }
            }).result.then(function (item) {

            });
        }
    }
})();
