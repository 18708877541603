(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ExpressDialogController', ExpressDialogController);

    ExpressDialogController.$inject = ['$q', '$state', '$scope', 'MessageService', 'entity', 'ExpressLocation', '$uibModalInstance'];

    function ExpressDialogController($q, $state, $scope, MessageService, entity, ExpressLocation, $uibModalInstance) {

        var vm = this;
        vm.clear = clear;
        vm.save = save;

        vm.express = entity;

        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        vm.provinces = [{"code":"","sheng":"","di":"","xian":"","name":"请选择省份","level":1}];
        vm.citys = [{"code":"","sheng":"","di":"","xian":"","name":"请选择城市","level":2}];
        vm.districts = [{"code":"","sheng":"","di":"","xian":"","name":"请选择区县","level":3}];

        // 关闭弹出
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.toPostData = function() {
            var postData = {};
            postData.id = vm.express.id;
            postData.consignor = vm.express.consignor;
            postData.phone = vm.express.phone;
            postData.province = vm.province == null ? '' : vm.province.name;
            postData.city = vm.city == null ? '' : vm.city.name;
            postData.district = vm.district == null ? '' : vm.district.name;
            postData.address = vm.express.address;
            // postData.expressName = vm.express.expressName;
            // postData.expressPassword = vm.express.expressPassword;

            if (postData.province == '请选择省份') {
                postData.province = null;
            }
            if (postData.city == '请选择城市') {
                postData.city = null;
            }
            if (postData.district == '请选择区县') {
                postData.district = null;
            }
            return postData;
        }

        $scope.validate = function(data) {
            if (data.consignor == null || data.consignor == "") {
                MessageService.error("请输入寄件人名称");
                vm.isSaving = false;
                return false;
            };

            if (data.phone == null || data.phone == "") {
                MessageService.error("请输入寄件人手机电话");
                vm.isSaving = false;
                return false;
            };

            // if(!(/^(0|86|17951)?(13[0-9]|15[012356789]|17[3678]|18[0-9]|14[57]|166|198|199)[0-9]{8}$/.test(data.phone))){
            //      MessageService.error("手机号码非法");
            //     return false;
            // }

            if (data.province == null || data.province == "") {
                MessageService.error("请输入寄件人省份");
                vm.isSaving = false;
                return false;
            };

            if (data.city == null || data.city == '') {
                MessageService.error('请输入寄件人城市');
                vm.isSaving = false;
                return false;
            }

            if (data.district == null || data.district == "") {
                MessageService.error("请输入寄件人区县");
                vm.isSaving = false;
                return false;
            };

            if (data.address == null || data.address == "") {
                MessageService.error("请输入寄件人地址");
                vm.isSaving = false;
                return false;
            };

            return true;
        }

        function save() {
            vm.isSaving = true;
            var data = $scope.toPostData();
            if (!$scope.validate(data)) {
                vm.isSaving = false;
                return;
            };
            console.log(data);
            if (vm.express.id != null) {
                ExpressLocation.update(data, onSaveSuccess, onSaveError);
            } else {
                ExpressLocation.save(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            MessageService.success("操作成功");
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            MessageService.error("操作失败");
            vm.isSaving = false;
        }

        loadData();
        function loadData() {
            if (vm.express != null && vm.express.id != null) {
                for (var i = 0; i < cityJson.length; i++) {
                    if (cityJson[i].level == 1) {
                        vm.provinces.push(cityJson[i]);
                    }

                    if ((cityJson[i].level == 1 && cityJson[i].name == vm.express.province) 
                        || (cityJson[i].level == 2 && cityJson[i].name == vm.express.city) 
                        || (cityJson[i].level == 3 && cityJson[i].name == vm.express.district)) {
                        if (cityJson[i].level == 1) {
                            vm.province = cityJson[i];
                        }
                        if (cityJson[i].level == 2) {
                            vm.city = cityJson[i];
                        }
                        if (cityJson[i].level == 3) {
                            vm.district = cityJson[i];
                        }
                    }
                }

                vm.citys.push(vm.city);
                vm.districts.push(vm.district);
            } else {
                vm.provinces = [{"code":"","sheng":"","di":"","xian":"","name":"请选择省份","level":1}];
                for (var i = 0; i < cityJson.length; i++) {
                    if (cityJson[i].level == 1) {
                        vm.provinces.push(cityJson[i]);
                    }
                }
            }
        }

        function changeProvince() {
            vm.citys = [{"code":"","sheng":"","di":"","xian":"","name":"请选择城市","level":2}];
            vm.districts = [{"code":"","sheng":"","di":"","xian":"","name":"请选择区县","level":3}];
            for (var i = 0; i < cityJson.length; i++) {
                if (cityJson[i].level == 2 && cityJson[i].code.substr(0, 2) == vm.province.code.substr(0, 2)) {
                    vm.citys.push(cityJson[i]);
                }
            }
        }

        function changeCity() {
            vm.districts = [{"code":"","sheng":"","di":"","xian":"","name":"请选择区县","level":3}];
            for (var i = 0; i < cityJson.length; i++) {
                if (cityJson[i].level == 3 && vm.city != null && cityJson[i].code.substr(0, 4) == vm.city.code.substr(0, 4)) {
                    vm.districts.push(cityJson[i]);
                }
            }
        }
    }
})();
