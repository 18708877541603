(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CouponDialogController', CouponDialogController);

    CouponDialogController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Coupon', 'Product', 'ListService', 'MessageService'];

    function CouponDialogController($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, Coupon, Product, ListService, MessageService) {
        var vm = this;

        vm.coupon = entity;
        if(vm.coupon.id == null || !vm.coupon.accessTopLimit || vm.coupon.accessTopLimit == 0){
            vm.coupon.accessTopLimit = 1;
        }

        vm.clear = clear;
        vm.saveRule = saveRule;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.products = Product.getAllByCondition({});

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        $q.all([vm.coupon.$promise, vm.products.$promise]).then(function() {
            vm.selectedAll = true;
            vm.categories =[];
            if (vm.products && vm.products.length > 0) {
                for (var i = vm.products.length - 1; i >= 0; i--) {
                    var t = vm.products[i];
                    var cate = {id:t.categoryStoreId, name:t.categoryStoreName, products:[], selected :true};
                    var result = ListService.inList(cate, vm.categories, ["id"]);
                    if (result) {
                        result.products.push(t);
                    }else{
                        cate.products.push(t);
                        vm.categories.push(cate);
                    }
                }
            }
            console.log(vm.selectedAll)
            if(vm.coupon.products && vm.coupon.products.length>0){
                for(var i=0; i<vm.categories.length; i++){
                    vm.categories[i].selected = true;
                    for(var j=0; j<vm.categories[i].products.length; j++){
                        for(var k=0; k<vm.coupon.products.length; k++){
                            if(vm.coupon.products[k].id == vm.categories[i].products[j].id){
                                vm.categories[i].products[j].selected = true;
                            }
                        }
                        if(!vm.categories[i].products[j].selected){
                            vm.categories[i].selected = false;
                        }
                    }
                    if(!vm.categories[i].selected){
                        vm.selectedAll = false;
                    }
                }
            }else {
                vm.selectedAll = false;
                for (var j = vm.categories.length - 1; j >= 0; j--) {
                    vm.categories[j].selected = false;
                }
            }
            console.log(vm.selectedAll)
        });

        $scope.categoryShow = function(category){
            category.show = ! category.show;
        }

        $scope.selectProduct = function(category, p){
            p.selected = !p.selected;
            for (var i = category.products.length - 1; i >= 0; i--) {
                var p = category.products[i];
                if (!p.selected) {
                    category.selected = false;
                    vm.selectedAll = false;
                    return;
                }
            }
            category.selected = true;
            for (var i = vm.categories.length - 1; i >= 0; i--) {
                var cat = vm.categories[i];
                if (!cat.selected) {
                    vm.selectedAll = false;
                    return;
                }
            }
            vm.selectedAll = true;
        }

        $scope.categoryAll = function(category){
            category.selected = !category.selected;
            if (category.products) {
                for (var i = category.products.length - 1; i >= 0; i--) {
                    var p = category.products[i];
                    p.selected = category.selected;
                }
            }
            for (var i = vm.categories.length - 1; i >= 0; i--) {
                var cat = vm.categories[i];
                if (!cat.selected) {
                    vm.selectedAll = false;
                    return;
                }
            }
            vm.selectedAll = true;
        }

        $scope.selectAll = function(){
            vm.selectedAll = !vm.selectedAll;
            for (var i = vm.categories.length - 1; i >= 0; i--) {
                var ca = vm.categories[i];
                ca.selected = vm.selectedAll;
                if (ca.products) {
                    for (var j = ca.products.length - 1; j >= 0; j--) {
                        var p = ca.products[j];
                        p.selected = vm.selectedAll
                    }
                }
            }
        }


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.toPostData = function() {
            var postData = {};
            postData.id = vm.coupon.id;
            postData.name = vm.coupon.name;
            postData.type = vm.coupon.type;
            postData.description = vm.coupon.description;
            postData.basePrice = vm.coupon.basePrice;
            postData.reducePrice = vm.coupon.reducePrice;
            postData.startTime = vm.coupon.startTime;
            postData.endTime = vm.coupon.endTime;
            postData.enabled = vm.coupon.enabled;
            postData.sendRule = vm.coupon.sendRule;
            postData.totalQuantity = vm.coupon.totalQuantity;
            postData.discount = vm.coupon.discount;
            postData.maxReduce = vm.coupon.maxReduce;
            postData.invalidDay = vm.coupon.invalidDay;
            postData.accessTopLimit = vm.coupon.accessTopLimit;
            postData.products = [];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var t = vm.products[i];
                if (t.selected) {
                    postData.products.push({
                        id: t.id,
                        name: t.name
                    });
                };
            };
            return postData;
        }

        $scope.sendRuleChange = function(){
            if (vm.coupon.sendRule == "Send" || vm.coupon.sendRule == "Share") {
                vm.coupon.totalQuantity = 0;
            }
        }

        $scope.validate = function(data) {
            if (data.name == null || data.name == "") {
                MessageService.error("请输入名称");
                vm.isSaving = false;
                return false;
            };

            if (data.type == null || data.type == "") {
                MessageService.error("请选择类型");
                vm.isSaving = false;
                return false;
            };

            if ((data.reducePrice == null || data.reducePrice == "")&& data.type!="DiscountReduce") {
                MessageService.error("请输入优惠金额");
                vm.isSaving = false;
                return false;
            };
            if ((data.basePrice == null || data.basePrice == "")&& (data.type=="DiscountReduce" || data.type=="FullReduce")) {
                MessageService.error("请输入满减金额");
                vm.isSaving = false;
                return false;
            };
            if ((data.discount == null || data.discount == "")&& data.type=="DiscountReduce") {
                MessageService.error("请输入需要打折数");
                vm.isSaving = false;
                return false;
            };
            if ((data.maxReduce == null || data.maxReduce == "")&& data.type=="DiscountReduce") {
                MessageService.error("请输入最多优惠金额");
                vm.isSaving = false;
                return false;
            };
            if (data.startTime == null || data.startTime == '') {
                MessageService.error('请输入开始时间');
                vm.isSaving = false;
                return false;
            }

            if (data.endTime == null || data.endTime == "") {
                MessageService.error("请输入结束时间");
                vm.isSaving = false;
                return false;
            };

            if (data.startTime > data.endTime) {
                MessageService.error("开始时间大于结束时间");
                vm.isSaving = false;
                return false;
            }

            if (data.products == null || data.products.length <= 0) {
                MessageService.error("请设置优惠券适用商品");
                vm.isSaving = false;
                return false;
            };

            return true;
        }

        $scope.changeLimit = function() {
            if(!vm.coupon.accessTopLimit || vm.coupon.accessTopLimit <= 0){
                vm.coupon.accessTopLimit = 1;
            }
        }

        function save() {
            vm.isSaving = true;
            var data = $scope.toPostData();
            if (!$scope.validate(data)) {
                return;
            };
            if (vm.coupon.id !== null) {
                Coupon.update(data, onSaveSuccess, onSaveError);
            } else {
                Coupon.save(data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:couponUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function saveRule() {
            if (vm.coupon.sendRule == null) {
                MessageService.error("请设置发放规则");
                return;
            };
            if ((vm.coupon.sendRule == 'Get' || vm.coupon.sendRule == 'Orders_Get' || vm.coupon.sendRule == 'Registered_Get') &&
                (vm.coupon.totalQuantity == null || vm.coupon.sendRule == "")) {
                MessageService.error("请设置优惠券数量");
                return;
            };

            Coupon.setRule({
                id: vm.coupon.id,
                rule: vm.coupon.sendRule,
                quantity: vm.coupon.totalQuantity
            }, function(result) {
                MessageService.success("设置成功，请及时修改上架状态");
                $scope.$emit('backApp:couponUpdate', result);
                $uibModalInstance.close(result);
            }, function() {
                MessageService.error("设置失败");
            });
        }

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
