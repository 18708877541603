(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('PriceListDetailConditionController', PriceListDetailConditionController);
    PriceListDetailConditionController.$inject = ['$scope', '$uibModalInstance', 'MessageService', '$stateParams', 'entity'];

    function PriceListDetailConditionController( $scope, $uibModalInstance, MessageService, $stateParams, entity) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.line = entity;
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.addPricecondition = function(){
            var data = {
                added:true,
            }
            if (vm.line.priceConditions == null) {
                vm.line.priceConditions = [];
            }
            vm.line.priceConditions.push(data);
        }

        $scope.delete = function(condition){
            if (condition.added) {
                var ind = vm.line.priceConditions.indexOf(condition);
                if (ind >= 0) {
                    vm.line.priceConditions.splice(ind, 1);
                }
            }else{
                condition.deleted = true;
            }
        }

        $scope.validate = function(){
            for (var i = vm.line.priceConditions.length - 1; i >= 0; i--) {
                var cond =  vm.line.priceConditions[i];
                if (!cond.startQuantity) {
                    MessageService.error("请录入起始数量");
                    return false;
                }
                if (!cond.endQuantity) {
                    MessageService.error("请录入结束数量");
                    return false;
                }
                if (cond.priceType == null) {
                    MessageService.error("请选择价格类型")
                    return false;
                }

                if (cond.price == null) {
                    MessageService.error("请录入价格")
                    return false;
                }
                if (vm.line.lowestPrice!=null&&cond.price < vm.line.lowestPrice) {
                    MessageService.error("价格不能小于最低成交价 ￥"+vm.line.lowestPrice)
                    return false;
                }
            }
            return true;
        }

        function save() {
            if (!$scope.validate()) {
                return;
            }
            $uibModalInstance.close(vm.line);
        }
    }

})();
