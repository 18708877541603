(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountStyleController', OpenAccountStyleController);


    OpenAccountStyleController.$inject = ['$scope', '$state', 'Store', 'MessageService','$q','$uibModal'];

    function OpenAccountStyleController ($scope, $state, Store, MessageService,$q, $uibModal) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        vm.selectStyle ='';

        $q.all([vm.store.$promise]).then(function(){
            if(vm.store.skin == '' || vm.store.skin == null || vm.store.skin == undefined){
                vm.selectStyle = 'one';
            }else{
                vm.selectStyle =vm.store.skin;
            }
            console.log(vm.selectStyle)
            console.log(vm.store)
        });

        $scope.themeSelectd = function(str){
            MessageService.confirm("确定要启用吗？（启用后需要重新修改移动端导航配置中的公众号路径）",function(){
                save(str);
                vm.selectStyle = str;
            },function(){},true);
        };
        function save (str) {
            Store.updateSkin({
                id:vm.store.id,
                skin:str
            }, onSuccess, onError);
        }
        function onSuccess () {
            vm.isSaving = false;
            MessageService.success('启用成功！');
        }

        function onError () {
            vm.isSaving = false;
            MessageService.success('启用失败！')
        }

        $scope.preview =function(data){
            $uibModal.open({
                templateUrl: 'app/entities/themeStyle/themeStyle-dialog.html',
                controller: 'themeStyleDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            number: null,
                            cancelled: null,
                            id: null
                        };
                    }
                }
            });
        };

        $scope.save = function () {
            $state.go('openAccount-module');
        }
        $scope.goBack = function () {
            window.history.go(-1);
        }
    }
})();
