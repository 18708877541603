(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SkuSellLimitController', SkuSellLimitController);

    SkuSellLimitController.$inject =  ['$state', 'SkuSellLimit', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', '$scope', 'Principal'];

    function SkuSellLimitController ($state, SkuSellLimit, ParseLinks, MessageService, paginationConstants, pagingParams, $scope, Principal) {
        var vm = this;

        var vm = this;
        Principal.hasAuthority('ROLE_PRODUCT_SELL_LIMIT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
         
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        $scope.selectAll = false;

        loadAll();

        function loadAll () {
            var items  = [];
            if (!!vm.searchQuery) {
                items.push({key:"productName,productNumber", op:"like", value:vm.searchQuery})
            }
            SkuSellLimit.getWarning({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                items: items,
                sort:sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                if (data.status && data.status != 200) {
                    MessageService.error("获取数据失败");
                    return;
                }
                vm.productSellLimits = data.message;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("获取数据失败");
            }
        }
         
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
 

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0  ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

    }
})();