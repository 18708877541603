(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotClearController', MapDepotClearController);

    MapDepotClearController.$inject = ['MessageService', "$scope" ,'$uibModal', 'CustomerStorageCleanSchedules'];

    function MapDepotClearController(MessageService, $scope , $uibModal , CustomerStorageCleanSchedules) {
        var vm = this;

        vm.data = {
            boundary : 12 ,//准备删除多久之前的  单位 月
            delay : 7,//执行时间
            exemptOrdered : 90 ,//多久之前下过单的
            exemptLogin : 30 ,//多久之前登录的 单位天
            executed : false, //是否执行
            noticed : true , //是否短信通知
            cleanedNoticed :true,
            ordered:true,
            login:true,
        }

        vm.immediatelyClear=false;
        
        vm.statesList = [
            {name: '3', id : 3},
            {name: '6', id : 6},
            {name: '12', id : 12}
        ]

        $scope.add = function(num , sign){
            if (sign == 'first' && num) {
                vm.data.exemptOrdered++
            }
            if (sign == 'first' && !num) {
                vm.data.exemptOrdered = 1
            }
            if (sign == 'second' && num) {
                vm.data.exemptLogin++
            }
            if (sign == 'second' && !num) {
                vm.data.exemptLogin = 1
            }
            if (!vm.data.noticed) {
                return
            }
            if (sign == 'third' && num) {
                vm.data.delay++
            }
            if (sign == 'third' && !num) {
                vm.data.delay = 1
            }
        }

        $scope.minus = function(num , sign){
            if (sign == 'first' && num && num > 1) {
                vm.data.exemptOrdered--
            }
            if (sign == 'second'  && num && num > 1) {
                vm.data.exemptLogin--
            }
            if (!vm.data.noticed) {
                return
            }
            if (sign == 'third' && num && num > 1) {
                vm.data.delay--
            }
        }

        $scope.creationClear = function () {
            if (!vm.data.boundary) {
                MessageService.error('请选择删除几个月之前的图片');
                return
            }
            if (vm.data.ordered && !vm.data.exemptOrdered) {
                MessageService.error('请输入下单后几天内的用户不清理');
                return
            }
            if (vm.data.login && !vm.data.exemptLogin) {
                MessageService.error('请输入登录后几天内的用户不清理');
                return
            }
            
            $uibModal.open({
                templateUrl: 'app/entities/map-depot/map-depot-clear-dialog.html',
                controller: 'MapDepotClearDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }],
                    entity: ['CustomerStorageCleanSchedules', function (CustomerStorageCleanSchedules) {
                        return null;
                    }]
                }
            }).result.then(function (result) {
                create();
            });
        }

        function create(){
            CustomerStorageCleanSchedules.create(vm.data , function(res){
                if (res.status == 200) {
                    MessageService.success('创建清理任务成功')
                }
            })
        }
    }
})();