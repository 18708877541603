(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('substitution', {
            parent: 'entity',
            url: '/substitution?page&sort&search&status&startTime&endTime&productId&categoryId&userId',
            data: {
                authorities: ['ROLE_STORE_RGASSIGN'],
                pageTitle: 'backApp.substitution.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/substitution/substitutions.html',
                    controller: 'SubstitutionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        status: $stateParams.status,
                        startTime: $stateParams.startTime,
                        endTime:$stateParams.endTime,
                        productId:$stateParams.productId,
                        categoryId:$stateParams.categoryId,
                        userId:$stateParams.userId
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('substitution');
                    $translatePartialLoader.addPart('substitutionStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                    }]
            }
        })

        .state('substitution2', {
            parent: 'entity',
            url: '/substitution2?page&sort&search&status',
            data: {
                authorities: ['ROLE_STORE_RGASSIGN'],
                pageTitle: 'backApp.substitution.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/substitution/substitutions2.html',
                    controller: 'SubstitutionController2',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        status: $stateParams.status,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('substitution');
                    $translatePartialLoader.addPart('substitutionStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                    }]
            }
        })

        .state('substitution-detail', {
            parent: 'entity',
            url: '/substitution/{id}',
            data: {
                authorities: ['ROLE_STORE_RGASSIGN'],
                pageTitle: 'backApp.substitution.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/substitution/substitution-detail.html',
                    controller: 'SubstitutionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('substitution');
                    $translatePartialLoader.addPart('substitutionStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Substitution', function($stateParams, Substitution) {
                    return Substitution.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'substitution',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         
        .state('substitution.edit', {
            parent: 'substitution',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_RGASSIGN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/substitution/substitution-dialog.html',
                    controller: 'SubstitutionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Substitution', function(Substitution) {
                            return Substitution.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('substitution', null, { reload: 'substitution' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         ;
    }

})();
