(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('IntegralAcqSettingController', IntegralAcqSettingController);

    IntegralAcqSettingController.$inject = ['$state', '$q', 'IntegralAcqSetting', '$scope', 'MessageService','Principal'];

    function IntegralAcqSettingController($state, $q, IntegralAcqSetting, $scope, MessageService,Principal) {

        var vm = this;
        Principal.hasAuthority('ROLE_STORE_MEMBERSHIPSYS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });

        vm.save = save;

        loadData();
        function loadData() {
            vm.integralAcqSetting = IntegralAcqSetting.get();
        }

        function save() {
            IntegralAcqSetting.save(vm.integralAcqSetting, function(res) {
                MessageService.success("保存成功！");
            });
        }        
    }
})();
