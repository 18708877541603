(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('guide-info', {
            parent: 'entity',
            url: '/guide-info',
            data: {
                authorities: ['ROLE_STORE_BRANCH_SHOP','ROLE_STORE_MY_BRANCH_SHOP'],
                pageTitle: 'global.menu.entities.guideInfo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/guide-info/guide-info.html',
                    controller: 'GuideInfoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
