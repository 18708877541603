(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductAnalysisController', ProductAnalysisController);

    ProductAnalysisController.$inject = ['$scope', '$state', 'ParseLinks', 'MessageService', 'SalesOrder', 'XlsxService', 'Product', 'Store',
     'DateUtils', '$http', 'Principal',"$q"];

    function ProductAnalysisController ($scope, $state, ParseLinks, MessageService, SalesOrder, XlsxService, Product, Store,
     DateUtils, $http, Principal, $q) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DATASTATISTICS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.openCalendar = openCalendar;
        vm.changeType = changeType;
        vm.changeTime = changeTime;
        vm.findByTime = findByTime;
        vm.downLoadFile = downLoadFile;
        vm.prodcutChange = prodcutChange;
        vm.page = 1;
        vm.store = Store.getCurrent();
        vm.products = Product.getAllByCondition({items:[{key: "prototype", op: "in", value: "Store;StoreSelf"}]});

        vm.pageType = 0;
        vm.dateType = 0;

        vm.changeTopType = changeTopType;
        vm.changeDateType = changeDateType;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function changeTopType(seq){
            vm.pageType = seq;
            changeType();
        }

        function prodcutChange(){
            if (vm.productId !=null) {
                changeType();
            }
        }

        function changeDateType(seq){
            vm.dateType = seq;
            changeType();
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function downLoadFile(){
            /** 日统计情况：需要计算开始和结束的时间
                月统计情况：需要计算开始和结束的月份
                季度统计下：不要计算开始和结束的时间，但是要传是第几季度 **/
            // 结束时间
            if (vm.pageType == 1 && vm.productId == null) {
                MessageService.error("请选择产品");
                return;
            }
            var endTime = ""; 
            var startTime = ""; 
            
            if (vm.dateType == 0) {
                endTime = new Date(vm.endTime); 
                startTime = new Date(vm.startTime);
            }
            if (vm.dateType == 1) {//月统计
                 // 上个月底
                var lastMonth = new Date();
                lastMonth.setDate(1);
                lastMonth.setDate(lastMonth.getDate() - 1);
                endTime = lastMonth;
                startTime = new Date();
                if (vm.time.key == 1) {
                    startTime.setMonth(startTime.getMonth()-3);
                }
                if (vm.time.key == 2) {
                    startTime.setMonth(startTime.getMonth()-6);
                }
                if (vm.time.key == 3) {
                    startTime.setMonth(startTime.getMonth()-9);
                }
                if (vm.time.key == 4) {
                    startTime.setMonth(startTime.getMonth()-12);
                } 
                startTime.setDate(1);
            }

            if (vm.dateType == 2) {
                if (vm.time.key == 1) {
                    startTime = new Date().getFullYear() + '-01-01';
                    endTime = new Date().getFullYear() + '-03-31';
                } 
                if (vm.time.key == 2) {
                    startTime = new Date().getFullYear() + '-04-01';
                    endTime = new Date().getFullYear() + '-06-30';
                } 
                if (vm.time.key == 3) {
                    startTime = new Date().getFullYear() + '-07-01';
                    endTime = new Date().getFullYear() + '-09-31';
                } 
                if (vm.time.key == 4) {
                    startTime = new Date().getFullYear() + '-10-01';
                    endTime = new Date().getFullYear() + '-12-31';
                }
            }  
            if (vm.dateType != 2) {
                endTime = DateUtils.convertLocalDateToServer(endTime);
                startTime = DateUtils.convertLocalDateToServer(startTime);
            }
            var type = vm.dateType==0? "day":"";
            if (vm.pageType == 0) {
                var params={
                    startTime:startTime,
                    endTime:endTime,
                    storeId:vm.store.id
                }
                $http({
                    url: 'service/api/sales-orders/productAnalysisDownload2',
                    method: "GET",
                    params: params,
                    headers: {
                        'Content-type': 'application/json',
                    },
                    responseType: 'blob'
                }).success(function (data, status, headers, config) {
                    var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                    var objectUrl = URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display:none');
                    a.setAttribute('href', objectUrl);
                    var filename = "商品统计" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                    a.setAttribute('download', filename);
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                    document.body.removeChild(a);

                }).error(function (data, status, headers, config) {
                });
                
                // window.open("service/api/sales-orders/productAnalysisDownload2?startTime=" + startTime + "&endTime="+ endTime+ "&storeId="+vm.store.id);
            }else{
                var params={
                    startTime:startTime,
                    endTime:endTime,
                    storeId:vm.store.id,
                    productId:vm.productId
                }
                $http({
                    url: 'service/api/sales-orders/productXmlAnalysisDownload2',
                    method: "GET",
                    params: params,
                    headers: {
                        'Content-type': 'application/json',
                    },
                    responseType: 'blob'
                }).success(function (data, status, headers, config) {
                    var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                    var objectUrl = URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display:none');
                    a.setAttribute('href', objectUrl);
                    var filename = "商品模板统计" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                    a.setAttribute('download', filename);
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                    document.body.removeChild(a);

                }).error(function (data, status, headers, config) {
                });
                // window.open("service/api/sales-orders/productXmlAnalysisDownload2?startTime=" + startTime + "&endTime="+ endTime+ "&storeId="+vm.store.id+"&productId=" + vm.productId);
            }
        }

        $scope.selectPage = function(number){
            console.log('234');
        }

        $q.all([vm.store.$promise, vm.products.$promise]).then(function(){
            changeType();
        })

        // 时间部分默认数据
        vm.times = {time1 : {key: 1, value: '最近7日'}, time2 : {key: 2, value: '最近15日'}, time3 : {key: 3, value: '最近30日'}};
        vm.time = vm.times.time1;
        function seriesData(startTime, endTime) {
            console.log(startTime + "------------" + endTime);
            if (vm.pageType == 0) {
                SalesOrder.productAnalysis2({
                    storeId: vm.store.id,
                    startTime: startTime,
                    endTime: endTime,
                    type: vm.dateType==0?"day":"",
                }, function (data) {
                    vm.res1 = data.data1;

                    // 处理类型
                    var legendName = [];
                    var legendQuantity = [];
                    if (vm.res1.length > 0) {
                        for (var i = 0; i < vm.res1.length; i++) {
                            legendName.push(vm.res1[i].productName);
                            legendQuantity.push(vm.res1[i].quantity);
                        }
                    }

                    var myChart = echarts.init(document.getElementById("main-Analysis"));
                    myChart.clear();    // 清空图表，防止缓存
                    // 指定图表的配置项和数据
                    var option = {
                        title: {
                            text: "",
                            textStyle: {
                                color: "#FF5001",
                                fontSize: 17
                            }
                        },
                        tooltip : {
                            trigger: 'axis'
                        },
                        xAxis: {
                            show:false,
                            type: 'category',
                            data: legendName
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: legendQuantity,
                            type: 'bar',
                            barMaxWidth:30
                        }]
                    }
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option);
                }, function (error) {
                    MessageService.error("服务器异常，请稍后重试");
                });
            }else{
                if (vm.productId == null) {
                    MessageService.error("请选择产品");
                    return;
                }
               SalesOrder.productXmlAnalysis2({
                    storeId: vm.store.id,
                    startTime: startTime,
                    endTime: endTime,
                    productId:vm.productId,
                    type: vm.dateType==0?"day":"",
                }, function (data) {
                    vm.res1 = data.data1;
                    // 处理类型
                    var legendName = [];
                    var legendQuantity = [];
                    if (vm.res1.length > 0) {
                        for (var i = 0; i < vm.res1.length; i++) {
                            legendName.push(vm.res1[i].productXmlName);
                            legendQuantity.push(vm.res1[i].quantity);
                        }
                    }

                    var myChart = echarts.init(document.getElementById("main-Analysis"));
                    myChart.clear();    // 清空图表，防止缓存
                    // 指定图表的配置项和数据
                    var option = {
                        title: {
                            text: "",
                            textStyle: {
                                color: "#FF5001",
                                fontSize: 17
                            }
                        },
                        tooltip : {
                            trigger: 'axis'
                        },
                        xAxis: {
                            show:false,
                            type: 'category',
                            data: legendName
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: legendQuantity,
                            type: 'bar',
                            barMaxWidth:30
                        }]
                    }
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option);
                }, function (error) {
                    MessageService.error("服务器异常，请稍后重试");
                });
            }
        }

        // 时间部分选择联动处理
        function changeType () {
            if (vm.dateType == 0) {
                vm.times = {
                    time1 : {key: 1, value: '最近7日'},
                    time2 : {key: 2, value: '最近15日'},
                    time3 : {key: 3, value: '最近30日'}
                };
                vm.time = vm.times.time1;
            }
            if (vm.dateType == 1) {
                vm.times = {
                    time1 : {key: 1, value: '3个月'},
                    time2 : {key: 2, value: '6个月'},
                    time3 : {key: 3, value: '9个月'},
                    time4 : {key: 4, value: '12个月'}
                };
                vm.time = vm.times.time1;
            }
            if (vm.dateType == 2) {
                vm.times = {
                    time1 : {key: 1, value: '第一季度'},
                    time2 : {key: 2, value: '第二季度'},
                    time3 : {key: 3, value: '第三季度'},
                    time4 : {key: 4, value: '第四季度'}
                };
                vm.time = vm.times.time1;
            }
            changeTime();
        }

        // 选择时间下拉框
        function changeTime () {

            /** 日统计情况：需要计算开始和结束的时间
                月统计情况：需要计算开始和结束的月份
                季度统计下：不要计算开始和结束的时间，但是要传是第几季度 **/

             // 结束时间
            var endTime = "";
            // 开始时间
            var startTime = new Date();
            // 上个月底
            if (vm.dateType == 0) {
                if (vm.time.key == 1) {
                    startTime.setDate(startTime.getDate()-7);
                }
                if (vm.time.key == 2) {
                    startTime.setDate(startTime.getDate()-15);
                }
                if (vm.time.key == 3) {
                    startTime.setDate(startTime.getDate()-30);
                }
                endTime = new Date(); 
            }

            if (vm.dateType == 1) {//月统计
                 // 上个月底
                var lastMonth = new Date();
                lastMonth.setDate(1);
                lastMonth.setDate(lastMonth.getDate() - 1);
                endTime = lastMonth;
                if (vm.time.key == 1) {
                    startTime.setMonth(startTime.getMonth()-3);
                }
                if (vm.time.key == 2) {
                    startTime.setMonth(startTime.getMonth()-6);
                }
                if (vm.time.key == 3) {
                    startTime.setMonth(startTime.getMonth()-9);
                }
                if (vm.time.key == 4) {
                    startTime.setMonth(startTime.getMonth()-12);
                } 
                startTime.setDate(1);
            }


            if (vm.dateType == 2) {
                if (vm.time.key == 1) {
                    startTime = new Date().getFullYear() + '-01-01';
                    endTime = new Date().getFullYear() + '-03-31';
                } 
                if (vm.time.key == 2) {
                    startTime = new Date().getFullYear() + '-04-01';
                    endTime = new Date().getFullYear() + '-06-30';
                } 
                if (vm.time.key == 3) {
                    startTime = new Date().getFullYear() + '-07-01';
                    endTime = new Date().getFullYear() + '-09-31';
                } 
                if (vm.time.key == 4) {
                    startTime = new Date().getFullYear() + '-10-01';
                    endTime = new Date().getFullYear() + '-12-31';
                }
            }  
            if (vm.dateType != 2) {
                vm.endTime = endTime;
                vm.startTime = startTime;
                endTime = DateUtils.convertLocalDateToServer(endTime);
                startTime = DateUtils.convertLocalDateToServer(startTime); 
            }else{
                vm.endTime = "";
                vm.startTime = "";
            }

            seriesData(startTime, endTime);
        }

        function findByTime() {
            if (vm.dateType == '2') {
                return;
            }
            if (vm.startTime == '' || vm.startTime == undefined || vm.startTime == null) {
                MessageService.error('请选择开始日期');
                return;
            }
            if (vm.endTime == '' || vm.endTime == undefined || vm.endTime == null) {
                MessageService.error('请选择结束日期');
                return;
            }

            // 时间区间判断
            // 日统计最长时间周期31天；月统计时间周期不超过24个月；季统计时间周期不超过8季度
            if (vm.dateType == 1) {
                var date3 = vm.endTime.getMonth() - vm.startTime.getMonth();
                if (date3 > 24) {
                    MessageService.error("月统计时间周期不超过24个月");
                    return;
                }
            } else if (vm.dateType == 0) {
                var date3 = vm.endTime.getTime() - vm.startTime.getTime();
                var days = Math.floor(date3 / (24 * 3600 * 1000));
                if (days >= 31) {
                    MessageService.error("日统计最长时间周期31天");
                    return;
                }
            }

            var startTime = vm.startTime;
            var endTime = vm.endTime;

            if (vm.dateType == 1) {//月统计
                 // 上个月底
                var lastMonth = new Date();
                lastMonth.setDate(1);
                lastMonth.setDate(lastMonth.getDate() - 1);
                endTime = lastMonth;
                if (vm.time.key == 1) {
                    startTime.setMonth(startTime.getMonth()-3);
                }
                if (vm.time.key == 2) {
                    startTime.setMonth(startTime.getMonth()-6);
                }
                if (vm.time.key == 3) {
                    startTime.setMonth(startTime.getMonth()-9);
                }
                if (vm.time.key == 4) {
                    startTime.setMonth(startTime.getMonth()-12);
                } 
                startTime.setDate(1);
            }

            endTime = DateUtils.convertLocalDateToServer(endTime);
            startTime = DateUtils.convertLocalDateToServer(startTime);

            seriesData(startTime, endTime);
        }

       
    }
})();
