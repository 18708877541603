(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('openAccount-secretKey', {
                parent: 'entity',
                url: '/openAccount-secretKey',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountSecretKey'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-secretKey/openAccount-secretKey.html',
                        controller: 'OpenAccountSecretKeyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();
