(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('logisticsSet', {
            parent: 'entity',
            url: '/logisticsSet',
            data: {
                authorities: ["ROLE_STORE_LOGISTICS_SETTING"],
                pageTitle: 'backApp.title.logisticsSet'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/logisticsSet/logisticsSet.html',
                    controller: 'LogisticsSetController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('title');
                    return $translate.refresh();
                }]
            }
        })
        .state('logisticsSetDialog', {
            parent: 'entity',
            url: '/logisticsSetDialog',
            data: {
                authorities: ["ROLE_STORE_LOGISTICS_SETTING"],
                pageTitle: '物流设置'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/logisticsSet/logisticsSet-dialog.html',
                    controller: 'LogisticsSetDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('logisticsPrice', {
                parent: 'entity',
                url: '/logisticsPrice',
                data: {
                    authorities: ["ROLE_STORE_LOGISTICS_SETTING"],
                    pageTitle: 'backApp.title.logisticsPrice'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logisticsSet/logisticsPrice.html',
                        controller: 'LogisticsPriceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('title');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();
