(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('openAccount-mini', {
                parent: 'entity',
                url: '/openAccount-mini',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountTencent'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-mini/openAccount-mini.html',
                        controller: 'OpenAccountMiniController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'StoreSmallAppWechatSetting', function($stateParams, StoreSmallAppWechatSetting) {
                        return StoreSmallAppWechatSetting.getEntityByStoreId().$promise;
                    }],
                }
            })
            .state('openAccount-mini-detail', {
                parent: 'entity',
                url: '/openAccount-mini-detail?auth_code&expires_in',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.openAccountTencent'
                },
                views: {
                    'content2@': {
                        templateUrl: 'app/entities/openAccount/openAccount-mini/openAccount-mini.html',
                        controller: 'OpenAccountMiniController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('storeWechatSetting');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'WechatAuth', function($stateParams, WechatAuth) {
                        return WechatAuth.auth_notify2({auth_code:$stateParams.auth_code,expires_in:$stateParams.expires_in}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'store-wechat-setting',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
        ;
    }

})();
