(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StatisticalStoreController', StatisticalStoreController);

    StatisticalStoreController.$inject = ['$scope', '$state', 'MessageService', 'Store'];

    function StatisticalStoreController ($scope, $state, MessageService, Store) {
        var vm = this;

        vm.times = {time1 : {key: 1, value: '最近7日'}, time2 : {key: 2, value: '最近15日'}, time3 : {key: 3, value: '最近30日'}};
        vm.time = vm.times.time1;

        vm.openCalendar = openCalendar;
        vm.fondByTime = fondByTime;
        vm.changeTime = changeTime;
        vm.dateformat = dateformat;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        // 昨日新开店铺
        vm.newStoreNum = 0;
        // 全部商铺数量
        vm.allStoreNum = 0;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        // 画图部分
        function echarts_init(date_arr, datas) {
            vm.date_arr = date_arr;
            vm.datas = datas;
            var myChart = echarts.init(document.getElementById("main"));
            // 指定图表的配置项和数据
            var option = {
                xAxis: {
                    type: 'category',
                    data: vm.date_arr
                },
                tooltip: {
                    trigger: 'axis'
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: vm.datas,
                    type: 'line',
                    lineStyle:{
                        normal: {
                            color: '#FF5001'
                        }
                    },
                    areaStyle: {
                        normal: {
                            color: '#FF5001'
                        }
                    },
                }]
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        }


        function fondByTime() {
            if (vm.dataType == 'quarter') {
                return;
            }
            if (vm.startTime == '' || vm.startTime == undefined || vm.startTime == null) {
                MessageService.error('请选择开始日期');
                return;
            }
            if (vm.endTime == '' || vm.endTime == undefined || vm.endTime == null) {
                MessageService.error('请选择结束日期');
                return;
            }

            // 时间区间判断
            var date3 = vm.endTime.getTime() - vm.startTime.getTime();
            var days = Math.floor(date3 / (24 * 3600 * 1000));
            if (days >= 31) {
                MessageService.error("日统计最长时间周期31天");
                return;
            }

            var startTime = vm.startTime;
            var endTime = new Date(vm.endTime);
            startTime = startTime.getFullYear() + "-" + ((startTime.getMonth() + 1) > 9 ? (startTime.getMonth() + 1) : "0" + (startTime.getMonth() + 1))
                        + "-" + (startTime.getDate() > 9 ? startTime.getDate() : ("0" + startTime.getDate()));
            if (endTime == null) {
                endTime = new Date(vm.endTime);
            }
            if (endTime != '' && endTime != null) {
                endTime = endTime.getFullYear() + "-" + ((endTime.getMonth() + 1) > 9 ? (endTime.getMonth() + 1) : "0" + (endTime.getMonth() + 1)) + "-" + endTime.getDate();
            }
            // console.log(startTime+ "---" + endTime);
            seriesData(startTime, endTime);
        }


        // 选择时间下拉框
        changeTime();
        function changeTime () {
            /** 日统计情况：需要计算开始和结束的时间 **/
            // 今天
            var today = new Date();
            // 结束时间
            var endTime = "";
            // 开始时间
            var startTime = new Date();

            // 解释为什么要加10天，因为每个月总会差别2~4天 ，就索性加10天
            if (vm.time.key == 1) {
                startTime.setDate(today.getDate() - 7);
            } else if (vm.time.key == 2) {
                startTime.setDate(today.getDate() - 15);
            } else if (vm.time.key == 3) {
                startTime.setDate(today.getDate() - 30);
            } else if (vm.time.key == 4) {
                // 不存在
            }

            // 日统计的结束时间是年月日---季统计的最后时间也显示为昨天
            endTime = new Date(today.setDate(today.getDate() - 1));
            vm.endTime = endTime;
            endTime = endTime.getFullYear() + "-" + ((endTime.getMonth() + 1) > 9 ? (endTime.getMonth() + 1) : "0" +
                (endTime.getMonth() + 1)) + "-" + endTime.getDate();

            vm.startTime = startTime;
            startTime = startTime.getFullYear() + "-" + ((startTime.getMonth() + 1) > 9 ? (startTime.getMonth() + 1) : "0" + (startTime.getMonth() + 1))
                        + "-" + (startTime.getDate() > 9 ? startTime.getDate() : ("0" + startTime.getDate()));

            seriesData(startTime, endTime);
        }

        function seriesData(startTime, endTime) {
            Store.getNum({}, function (data) {
                vm.newStoreNum = data.message.newStoreNum;
                vm.allStoreNum = data.message.allStoreNum;
            });
            Store.getNum4Time({
                "startTime": startTime,
                "endTime": endTime
            }, function(data) {
                vm.datas = data.message;
                vm.date_arr = getMonthBetween(startTime, endTime);
                echarts_init(vm.date_arr, vm.datas)
            });
        }

        //获得区间内所有日期
        function getMonthBetween(startTime, endTime){
            // 日期段内所有日期的处理
            var bd = new Date(startTime + " 00:00:01"),be = new Date(endTime + " 23:59:59");
            var bd_time = bd.getTime(), be_time = be.getTime(),time_diff = be_time - bd_time;

            vm.date_arr = [];
            for (var i = 0; i <= time_diff; i += 86400000) {
                var ds = new Date (bd_time + i);
                vm.date_arr.push(ds.getFullYear() + "-" + ((ds.getMonth() + 1) > 9 ? (ds.getMonth() + 1) : "0"+(ds.getMonth() + 1)) + '-'
                    + (ds.getDate() > 9 ? ds.getDate() : "0" + ds.getDate()));
            }

            return vm.date_arr;
        }

    }
})();
