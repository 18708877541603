(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ClipArtDetailController', ClipArtDetailController);

    ClipArtDetailController.$inject = ['$scope', '$rootScope', '$q', '$stateParams', '$uibModalInstance', 'entity', 'Qinius'];

    function ClipArtDetailController($scope, $rootScope, $q, $stateParams, $uibModalInstance, entity, Qinius) {
        var vm = this;
        vm.clipArt = entity;

        // 获取qiniu服务器
        vm.domains = Qinius.getDomain(); 
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/'; 
        })

        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('platformApp:productUpdate', function(event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
