(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('BannerController', BannerController);

    BannerController.$inject = ['$scope', '$q', '$state', 'Banner', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', 'Convert', '$http', 'StoreConfig','Principal','Store'];

    function BannerController ($scope, $q, $state, Banner, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, Convert, $http, StoreConfig,Principal,Store) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DIANPU').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.store = Store.getCurrent();
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.tabFlag =1;
        vm.isSort = false;
        vm.bannerSize = {
            type:'BANNERSIZE',
            value: ''
        };

        vm.jump = jump

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        $scope.delete = function(banner){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Banner.delete({id: banner.id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        function getStoreConfig(){
            StoreConfig.getAllByCondition({
            }, onSuccess, onError)
            function onSuccess(datas) {
                angular.forEach(datas, function (data, i) {
                    if(data.type == "BANNERSIZE"){
                        vm.bannerSize.value = data.value
                    }
                    return;
                });
            }
            function onError() {
                MessageService.error("优惠券设置获取失败");
            }
        }

        function loadAll () {
            getStoreConfig();
            if (pagingParams.search) {
                Banner.byCondition({
                    items: [{key:"number",op:"=",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Banner.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'number') {
                    result.push('number');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.banners = data;
                if (vm.banners.length >0) {
                    vm.banners = vm.banners.sort( function(a, b){
                        return a.number - b.number;
                    })
                }
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("轮播图获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' +(vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'number';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'number';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Banner.deleteBatch({ids: $scope.batchArr},function () {
                    MessageService.success("删除成功");
                    $scope.selectOne = false;
                    $scope.selectAll = false;
                    loadAll();
                },function () {
                    MessageService.error("删除失败");
                })
            },function(){},true);

        }

        $scope.sortNumber = function (banner1, attr) {
            var index = vm.banners.indexOf(banner1);
            if (index<0) {
                return;
            }
            var banner2 = null;
            if(attr=='up'){
                banner2 = vm.banners[index-1];
                if (banner2.number == banner1.number) {
                    banner2.number = banner2.number+1;
                }
            }else{
                banner2 = vm.banners[index+1];
                if (banner2.number == banner1.number) {
                    banner2.number = banner2.number - 1;
                    // if (banner2.number<0) {
                    //     banner2.number = 0;
                    // }
                }
            }
            Banner.updateNumber({
                bannerId1:banner1.id,
                number1:banner2.number,
                bannerId2:banner2.id,
                number2:banner1.number
            },function () {
                loadAll();
            })
        }

        $scope.typeUpdate = function(int){
            vm.bannerSize.value = int;
            updateConfig();
        }

        function updateConfig() {
            StoreConfig.update(vm.bannerSize);
        }
    }
})();
