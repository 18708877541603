(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('HomeClearController', HomeClearController);

    HomeClearController.$inject = ['$scope', '$state', 'MessageService', 'SalesOrder', 'WechatUserAnalysis', '$sessionStorage', 'Store'];

    function HomeClearController($scope, $state, MessageService, SalesOrder, WechatUserAnalysis, $sessionStorage, Store) {
        var vm = this;

    }
})();
