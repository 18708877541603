(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreSmallLinkController', StoreSmallLinkController);

    StoreSmallLinkController.$inject = ['$scope', '$q', 'entity', 'Store', 'MessageService', 'Product', '$state', '$uibModal','Principal', 'WechatAuth'];

    function StoreSmallLinkController($scope, $q, entity, Store, MessageService, Product, $state, $uibModal,Principal,WechatAuth) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });

        vm.store = entity;
        //保存
        vm.save2 = save2;
        vm.copyUrl = copyUrl;
        vm.copyUrl2 = copyUrl2;

        vm.smallLink = WechatAuth.getSmallLink();
        vm.products =  Product.getAllByCondition({items:[{key:"saleable", op :"=", value:"true"}]});
        
        $scope.automaticDeduction = function(flag){
            vm.store.automaticDeduction = flag;
            vm.save2();
        }

        $q.all([ vm.store.$promise, vm.smallLink.$promise, vm.products.$promise]).then(function () {
            if (vm.smallLink.status && vm.smallLink.status !=200 ) {
                MessageService.error("小程序配置获取失败，请联系管理员");
            }
            var url = vm.smallLink.message +vm.store.id;
            //https://link.marketflow.cn/mt/1?path=home
            vm.settings = [
                {index:1, key:"home", value:"首页", address:url+"?path=home", action:"复制地址"},
                {index:2, key:"center", value:"个人中心", address:url+"?path=center", action:"复制地址"},
                {index:3, key:"paycode", value:"卡包", address:url+"?path=paycode", action:"复制地址"},
                {index:4, key:"customerService", value:"添加客服", address:url+"?path=customerService", action:"复制地址"},
                {index:5, key:"productDetail", value:"商品详情页", address:"", action:"设置参数"}
                ]
        });

        $scope.productChange = function(){
            if (!vm.productId) {
                return;
            }
            vm.setting.address = vm.smallLink.message +vm.store.id +"?path=productDetail&productId="+vm.productId;
        }

        
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        function copyUrl2(setting) {
            if (setting == null) {
                return;
            }
            vm.productId = null;
            vm.setting = setting;
            vm.showProduct = true;
             
           
        }

        $scope.close = function(){
            vm.setting = null;
            vm.productId = null;
            vm.showProduct = false;
        }

        $scope.confirm = function(){
           if (!vm.productId) {
                MessageService.error("请选择商品");
                return;
            }
            document.getElementById("productUrlSpan").focus();
            document.getElementById("productUrlSpan").select();
            var tag = document.execCommand("Copy"); //执行浏览器复制命令
            if (tag) {
                MessageService.success("复制成功");
                vm.setting = null;
                vm.productId = null;
                vm.showProduct = false;
            } else {
                MessageService.error("复制失败，请Ctrl+C复制。");
            };
        }


        function copyUrl(setting) {
            if (setting == null) {
                return;
            }
            document.getElementById("productUrlSpan"+setting.index).focus();
            document.getElementById("productUrlSpan"+setting.index).select();
            var tag = document.execCommand("Copy"); //执行浏览器复制命令
            if (tag) {
                MessageService.success("复制成功");
            } else {
                MessageService.error("复制失败，请Ctrl+C复制。");
            };
        }


        function save2() {
            vm.isSaving = true;
            if (vm.store.id !== null) {
                Store.update(vm.store, onSaveSuccess2, onSaveError);
            } else {
                Store.save(vm.store, onSaveSuccess2, onSaveError);
            }
        }

        function onSaveSuccess2(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }

         
    }
})();
