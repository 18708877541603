(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MemberRecordController', MemberRecordController);

    MemberRecordController.$inject = ['$scope', '$state', 'Qinius', '$uibModal', '$q', 'VipSettings', 'Store','MembershipPackages','MessageService', 'pagingParams',
        'paginationConstants', 'PackageRecords', 'ParseLinks','Principal'];

    function MemberRecordController ($scope, $state, Qinius, $uibModal, $q, VipSettings, Store, MembershipPackages,MessageService, pagingParams,
                                     paginationConstants, PackageRecords, ParseLinks,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_MEMBERSHIPSYS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        });

        loadAll();
        function loadAll() {
            if (pagingParams.search) {
                PackageRecords.byCondition({
                    items: [{key:"name",op:"like",value:pagingParams.search},{key:"paid",op:"=",value:'true'}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                PackageRecords.byCondition({
                    items: [{key:"paid",op:"=",value:'true'}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.data = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("会员注册记录列表获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }
    }
})();
