(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('PasswordActivityController', PasswordActivityController);

    PasswordActivityController.$inject = ['$scope', '$state', 'PasswordActivity', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Store'];

    function PasswordActivityController ($scope, $state, PasswordActivity, ParseLinks, AlertService, paginationConstants, pagingParams, Store) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.store = Store.getCurrent();

        $scope.downloadMiniQrCode2 = function(){
            if (!vm.store.id) {
                MessageService.error("商户信息加载失败请稍后再试");
                return;
            }
            window.open("/fileserves/api/download/downloadMiniQrCode2?storeId=" + vm.store.id+"&saleActivity=command");
        }

        loadAll();
        $scope.changeState = function (id) {
            PasswordActivity.changeStatue({id: id}, function () {
                loadAll();
            })
        }
         $scope.backTo = function(){
            sessionStorage.setItem("lmenu", 'a_marketing');
            $state.go("marketing");
            vm.lmenu = "a_marketing";
 
        }

        function loadAll () {
            if (pagingParams.search) {
             PasswordActivity.byCondition({
                items: [{key: "name", op: "=", value:pagingParams.search}],
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
         } else {
            PasswordActivity.byCondition({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.passwordActivities = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
    }

    function loadPage(page) {
        vm.page = page;
        vm.transition();
    }

    function transition() {
        $state.transitionTo($state.$current, {
            page: vm.page,
            sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            search: vm.currentSearch
        });
    }

    function search(searchQuery) {
        if (!searchQuery) {
            return vm.clear();
        }
        vm.links = null;
        vm.page = 1;
        vm.predicate = 'id';
        vm.reverse = false;
        vm.currentSearch = searchQuery;
        vm.transition();
    }

    function clear() {
        vm.links = null;
        vm.page = 1;
        vm.predicate = 'id';
        vm.reverse = true;
        vm.currentSearch = null;
        vm.transition();
    }

    function jump() {
        vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
        loadPage(vm.page);
    }

}
})();
