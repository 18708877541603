(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('CustomerPayOrder', CustomerPayOrder);

    CustomerPayOrder.$inject = ['$resource'];

    function CustomerPayOrder ($resource) {
        var resourceUrl =  'manager/' + 'api/customer-pay-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAllByCondition': {
                params:{
                    path:"getAllByCondition"
                },
                method: 'POST', 
                isArray: true
            },
            'byCondition': {
                params:{
                    path:"byCondition2"
                },
                method: 'POST', 
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
