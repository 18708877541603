(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('self-application', {
            parent: 'store',
            url: '/self-application',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'backApp.selfApplication.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/self-application/self-applications.html',
                    controller: 'SelfApplicationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('selfApplication');
                    $translatePartialLoader.addPart('selfApplicationType');
                    $translatePartialLoader.addPart('status');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('self-application-detail', {
            parent: 'entity',
            url: '/self-application/{id}',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'backApp.selfApplication.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/self-application/self-application-detail.html',
                    controller: 'SelfApplicationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('selfApplication');
                    $translatePartialLoader.addPart('selfApplicationType');
                    $translatePartialLoader.addPart('status');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SelfApplication', function($stateParams, SelfApplication) {
                    return SelfApplication.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'self-application',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('self-application-detail.edit', {
            parent: 'self-application-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/self-application/self-application-dialog.html',
                    controller: 'SelfApplicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SelfApplication', function(SelfApplication) {
                            return SelfApplication.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('self-application.new', {
            parent: 'self-application',
            url: '/new',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/self-application/self-application-dialog.html',
                    controller: 'SelfApplicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                number: null,
                                description: null,
                                cancelled: null,
                                type: null,
                                status: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('self-application', null, { reload: 'self-application' });
                }, function() {
                    $state.go('self-application');
                });
            }]
        })
        .state('self-application.edit', {
            parent: 'self-application',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/self-application/self-application-dialog.html',
                    controller: 'SelfApplicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SelfApplication', function(SelfApplication) {
                            return SelfApplication.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('self-application', null, { reload: 'self-application' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        ;
    }

})();
