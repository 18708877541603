(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PhoneEditorController', PhoneEditorController);

        PhoneEditorController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MessageService', 'StoreUser1', 'VerifyCode', '$interval' , 'StoreUser'];

    function PhoneEditorController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MessageService, StoreUser1, VerifyCode, $interval, StoreUser) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.data = angular.copy(entity);
        vm.data.phone = null;
        
        $scope.paracont = "获取验证码";
        vm.disabled = false;
        //验证码的定时器
        var timer = null;
        var reg_tel = /^1[0-9]{10}$/
        $scope.getVerifyCode = function () {
            
            if (vm.disabled) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            VerifyCode.getVerifyCode2({
                phone: vm.data.phone,
                incident: "修改手机号"
            }, function (msg) {
                if(msg.status == 400){
                    MessageService.error(msg.message || "发送短信失败,请稍后重试");
                    return
                }
                clearInterval(timer);
                timer = $interval(function () {
                    if (vm.countdown > 0) {
                        vm.disabled = true;
                        vm.countdown--;
                        $scope.paracont = "重新发送(" + vm.countdown + ")";
                    } else {
                        vm.disabled = false;
                        $interval.cancel(timer);
                        $scope.paracont = "重发验证码";
                    }
                }, 1000);
            });
        };

        $scope.verify = function(){
            if (!reg_tel.test(vm.data.phone)) {
                MessageService.error("请输入正确的手机号");
                return
            }
            var _data = {phone:vm.data.phone}
            StoreUser.checkUser2(_data, function (data) {
                if (!data.message) {
                    MessageService.error("该手机号已存在");
                }else{
                    $scope.getVerifyCode()
                }
            }, function () {
                MessageService.error("校验用户名时出错");
            });
        }

        function save () {
            if (!reg_tel.test(vm.data.phone)) {
                MessageService.error("请输入正确的手机号");
                return
            }
            if (!vm.data.code) {
                MessageService.error("请输入验证码");
                return
            }
            vm.isSaving = true;
            StoreUser1.update({id:vm.data.id,phone:vm.data.phone,code:vm.data.code},onSaveSuccess,onSaveError)
        }

        function onSaveSuccess (res) {
            console.log(res);
            MessageService.success("手机号更新成功");
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("验证码错误");
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss(false);
        }

    }
})();
