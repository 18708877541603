(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pass-product', {
            parent: 'miraclePass',
            url: '/pass-product?page&sort&search',
            data: {
                pageTitle: 'backApp.passProduct.home.title'
            },
            views: {
                'miraclePass': {
                    templateUrl: 'app/entities/pass-product/pass-products.html',
                    controller: 'PassProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id, desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('passProduct');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
         
        .state('pass-product-add', {
            parent: 'entity',
            url: '/pass-product/add',
            data: {
                authorities: [],
                pageTitle: 'backApp.passProduct.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pass-product/pass-product-new.html',
                    controller: 'PassProductNewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('passProduct');
                    return $translate.refresh();
                }],
                 
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'pass-product',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         ;
    }

})();
