(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InvoiceLocationPageController', InvoiceLocationPageController);

    InvoiceLocationPageController.$inject = ['$scope', '$state', 'entity', 'InvoiceLocation',  'MessageService', '$q', 'LocationCity',
        'LocationDistrict', 'LocationProvice'
    ];

    function InvoiceLocationPageController($scope, $state, entity, InvoiceLocation,   MessageService, $q, LocationCity,
        LocationDistrict, LocationProvice) {
        var vm = this;

        vm.invoiceLocation = entity;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        vm.clear = clear;
        vm.save = save;

        function changeProvince() {
            LocationCity.findByProvince({
                provinceId: vm.province.id
            }, function(data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({
                cityId: vm.city.id
            }, function(data) {
                vm.districts = data;
            });
        }

        $q.all([vm.invoiceLocation.$promise]).then(function() {
            LocationProvice.query({}, function(data) {
                vm.provinces = data;
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.invoiceLocation.province) {
                        vm.province = p;
                        break;
                    }
                }
                if (!!vm.invoiceLocation.id) {
                    vm.districts = [];
                    LocationCity.findByProvince({
                        provinceId: vm.province.id
                    }, function(data) {
                        vm.citys = data;
                        for (var i = vm.citys.length - 1; i >= 0; i--) {
                            var c = vm.citys[i];
                            if (c.name == vm.invoiceLocation.city) {
                                vm.city = c;
                                break;
                            }
                        }
                        if (vm.city != null) {
                            LocationDistrict.findByCities({
                                cityId: vm.city.id
                            }, function(data) {
                                vm.districts = data;
                                for (var i = vm.districts.length - 1; i >= 0; i--) {
                                    var di = vm.districts[i];
                                    if (di.name == vm.invoiceLocation.area) {
                                        vm.district = di;
                                    }
                                }
                            });
                        }
                    });
                }
            });

        });

        // 关闭弹出
        function clear() {
            $state.go("invoice-location");
        }

        $scope.validate = function(data) {
            if (vm.invoiceLocation.name == null || vm.invoiceLocation.name == "") {
                MessageService.error("请输入收件人姓名");
                vm.isSaving = false;
                return false;
            };

            if (vm.invoiceLocation.province == null || vm.invoiceLocation.province == "") {
                MessageService.error("请选择省份");
                vm.isSaving = false;
                return false;
            };

            if (vm.invoiceLocation.city == null || vm.invoiceLocation.city == '') {
                MessageService.error('请选择城市');
                vm.isSaving = false;
                return false;
            }

            if (vm.invoiceLocation.area == null || vm.invoiceLocation.area == "") {
                MessageService.error("请选择区县");
                vm.isSaving = false;
                return false;
            };

            if (vm.invoiceLocation.address == null || vm.invoiceLocation.address == "") {
                MessageService.error("请输入街道地址");
                vm.isSaving = false;
                return false;
            };

            if (vm.invoiceLocation.postCode == null || vm.invoiceLocation.postCode == "") {
                MessageService.error("请输入邮编");
                vm.isSaving = false;
                return false;
            };

            if (vm.invoiceLocation.phone == null || vm.invoiceLocation.phone == "") {
                MessageService.error("请输入手机电话");
                vm.isSaving = false;
                return false;
            };

            // if (!(/^(0|86|17951)?(13[0-9]|15[012356789]|17[3678]|18[0-9]|14[57]|166|198|199)[0-9]{8}$/.test(vm.invoiceLocation.phone))) {
            //     MessageService.error("手机号码非法");
            //     return false;
            // }


            return true;
        }

        $scope.toPostData = function() { 
            vm.invoiceLocation.province = vm.province == null ? '' : vm.province.name;
            vm.invoiceLocation.city = vm.city == null ? '' : vm.city.name;
            vm.invoiceLocation.area = vm.district == null ? '' : vm.district.name; 
        }

        function save() {
            $scope.toPostData();
            if (!$scope.validate()) {
                return;
            };
            vm.isSaving = true;
            InvoiceLocation.update(vm.invoiceLocation, function(result) {
                MessageService.success("操作成功");
                $state.go("invoice-location");
            }, function(error) {
                vm.isSaving = false;
                MessageService.error("操作失败");
            })
        }
    }
})();