(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductPurchaseOrderController', ProductPurchaseOrderController);

    ProductPurchaseOrderController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$q', 'ProductPurchase',
    'Product', 'MessageService', 'Store', 'DateUtils', 'CommodityPrice', 'Category', 'entity', '$uibModal'];

    function ProductPurchaseOrderController ($state, $timeout, $scope, $stateParams, $q, ProductPurchase,
        Product, MessageService, Store, DateUtils, CommodityPrice, Category, entity, $uibModal) {
        var vm = this;
        vm.isDisabled = $stateParams.type == 'look' ? false : true;
        vm.purchaseId = $stateParams.id;
        vm.isSaving = false;
        vm.products = [];
        vm.data = {
            productPurchaseLines: [],
            amount: '',
            price: '',
            discount: '',
            discountAmount: '',
            productPeriodOfValidity: '',
            balance: '',
            quantity: '',
            purchaseType: ''
        }
        Store.getCurrent({}, function (res) {
            vm.store = res;
            vm.data.productPeriodOfValidity = res.periodOfValidity;
            vm.data.balance = res.balance;
            vm.data.storeId = res.id;
            vm.data.storeName = res.name;
        });
        if(!entity){
            vm.item =  JSON.parse(localStorage.getItem("itemValue"));
            vm.itemValues = vm.item.itemValue;
            vm.data.purchaseType = vm.item.purchaseType;
            productInit()
        }else {
            vm.data = entity;
            formatting()
        }

        function formatting() {
            vm.products = []
            for(var i=0; i<vm.data.productPurchaseLines.length; i++){
                var _itemValueLineProductId = vm.data.productPurchaseLines[i].itemValueLineProductId;
                var _itemValueLineProductName = vm.data.productPurchaseLines[i].itemValueLineProductName;
                var _product = {
                    productId: _itemValueLineProductId,
                    productName: _itemValueLineProductName,
                    itemValueLines:  []
                }
                var _isResult = vm.products.some(function (item, index) {
                    if(item.productId==_itemValueLineProductId){
                        vm.products[index].itemValueLines.push(vm.data.productPurchaseLines[i])
                        return true
                    }
                })
                if(!_isResult){
                    _product.itemValueLines.push(vm.data.productPurchaseLines[i])
                    vm.products.push(_product)
                }
            }
        }

        //获取商品
        function productInit(){
            for(var i=0; i<vm.itemValues.length; i++){
                var productId = vm.itemValues[i].id;
                var productName = vm.itemValues[i].name;
                (function(productId, productName) {
                    Product.get({id: productId},function(data){
                        var _product = {
                            productId: productId,
                            productName: productName,
                            itemValueLines: []
                        }
                        for(var i=0; i<data.itemValues.length; i++){
                            if(!data.itemValues[i].cancelled){
                                for(var j=0; j<data.itemValues[i].itemValueLines.length; j++){
                                    if(!data.itemValues[i].itemValueLines[j].cancelled){
                                        var _itemValueLines = {
                                            quantity: '',
                                            itemValueLineId: data.itemValues[i].itemValueLines[j].id,
                                            sku: data.itemValues[i].itemValueLines[j].sku,
                                            skuNumber: data.itemValues[i].itemValueLines[j].skuNumber,
                                            productPurchaseId: '',
                                            commodityPriceId: '',
                                            itemValueLineProductId:productId,
                                            itemValueLineProductName:productName,
                                            price: ''
                                        };
                                        _product.itemValueLines.push(_itemValueLines)
                                    }
                                }
                            }
                        }
                        vm.products.push(_product)
                        console.log(vm.products)
                    })
                })(productId, productName)
            }
        }
        //删除商品
        $scope.delect = function(data){
            for(var i=0; i<vm.products.length; i++){
                for(var j=0; j<vm.products[i].itemValueLines.length; j++){
                    if(vm.products[i].itemValueLines[j].itemValueLineId == data.itemValueLineId){
                        vm.products[i].itemValueLines.splice(j, 1);
                    }
                }
            }
            // productPurchaseLines();
        }
        //查看商品定价
        $scope.pricing = function(data){
            $uibModal.open({
                templateUrl: 'app/entities/product-purchase/modal/pricing.html',
                controller: 'PricingController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            data: data,
                            storeId: vm.store.id
                        };
                    }]
                }
            });
        }

        //计算价格
        $scope.imputedPrice = function () {
            //格式化数据
            productPurchaseLines();
        }
        //格式化数据
        function productPurchaseLines() {
            vm.data.productPurchaseLines = [];
            for(var i=0; i<vm.products.length; i++){
                for(var j=0; j<vm.products[i].itemValueLines.length; j++){
                    if(!vm.products[i].itemValueLines[j].quantity || vm.products[i].itemValueLines[j].quantity < 0){
                        MessageService.error("订购数量只能输入大于0的正整数");
                        return false
                    }
                    vm.data.productPurchaseLines.push(vm.products[i].itemValueLines[j])
                }
            }
            ProductPurchase.calculate({purchaseType:vm.data.purchaseType, productPurchaseLines: vm.data.productPurchaseLines},function (res) {
                if(res.status == 500){
                    MessageService.error(res.message);
                    return false
                }
                if(res.status == 200){
                    vm.data.productPurchaseLines = res.message.productPurchaseLines;
                    vm.data.amount = res.message.amount;
                    vm.data.discount = res.message.discount;
                    vm.data.discountAmount = res.message.discountAmount;
                    vm.data.balance = res.message.balance;
                    vm.data.quantity = res.message.quantity;
                    vm.data.payAmount = res.message.payAmount;
                    formatting()
                    for(var i=0; i<res.message.productPurchaseLines.length; i++){
                        vm.data.quantity = Number(res.message.productPurchaseLines[i].quantity) + Number(vm.data.quantity)
                    }
                }
            })
        }

        //提交
        $scope.save = function () {
            //格式化数据
            vm.data.productPurchaseLines = [];
            if(vm.products.length < 1){
                MessageService.error("请添加订购的商品");
                return
            }
            for(var i=0; i<vm.products.length; i++){
                if(vm.products[i].itemValueLines.length < 1){
                    MessageService.error(vm.products[i].productName + "下的商品不能为空");
                    return false
                }
                for(var j=0; j<vm.products[i].itemValueLines.length; j++){
                    if(!vm.products[i].itemValueLines[j].quantity || vm.products[i].itemValueLines[j].quantity < 0){
                        MessageService.error("订购数量只能输入大于0的正整数");
                        return false
                    }
                    vm.data.productPurchaseLines.push(vm.products[i].itemValueLines[j])
                }
            }
            ProductPurchase.calculate({purchaseType:vm.data.purchaseType, productPurchaseLines: vm.data.productPurchaseLines},function (res) {
                if(res.status == 500){
                    MessageService.error(res.message);
                    return false
                }
                if(res.status == 200){
                    vm.data.productPurchaseLines = res.message.productPurchaseLines;
                    vm.data.amount = res.message.amount;
                    // vm.data.price = res.message.price;
                    vm.data.discount = res.message.discount;
                    vm.data.discountAmount = res.message.discountAmount;
                    vm.data.balance = res.message.balance;
                    vm.data.quantity = res.message.quantity;
                    vm.data.payAmount = res.message.payAmount;
                    formatting()
                    for(var i=0; i<res.message.productPurchaseLines.length; i++){
                        vm.data.quantity = Number(res.message.productPurchaseLines[i].quantity) + Number(vm.data.quantity)
                    }
                    if(vm.data.amount > vm.store.balance){
                        MessageService.error("商户余额不足，请尽快充值");
                        return false
                    }
                    vm.isSaving = true;
                    setTimeout(function () {
                        if(!vm.data.id){
                            ProductPurchase.save(vm.data,function (res) {
                                vm.isSaving = false;
                                MessageService.success("添加成功");
                                $state.go('product-purchase');
                            }, function (error) {
                                vm.isSaving = false;
                                MessageService.error("添加失败，请稍后重试！");
                            })
                        }else {
                            ProductPurchase.update(vm.data,function (res) {
                                vm.isSaving = false;
                                MessageService.success("编辑成功");
                                $state.go('product-purchase');
                            }, function (error) {
                                vm.isSaving = false;
                                MessageService.error("编辑失败，请稍后重试！");
                            })
                        }
                    }, 500)
                }
            })
        }
        //返回商品页
        $scope.reverse = function () {
            var _products = [];
            for(var i=0; i<vm.products.length; i++){
                var _obj = {
                    id: vm.products[i].productId,
                    name: vm.products[i].productName
                }
                _products.push(_obj)
            }
            $state.go('product-purchase-new',{
                itemValues:_products
            });
            console.log(_products)
        }
        //编辑
        $scope.edit = function () {
            $state.go('product-purchase-order-edit', {id: vm.purchaseId})
        }

        //监听是否离开页面
        $scope.$on('$destroy', function() {
            localStorage.removeItem('itemValue');
        });
    }
})();
