(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

        .state('wechat-red-packet-setting', {
            parent: 'share-setting',
            url: '/wechat-red-packet-setting',
            data: {
                authorities: [],
                pageTitle: 'backApp.wechatRedPacketSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wechat-red-packet-setting/wechat-red-packet-setting-detail.html',
                    controller: 'WechatRedPacketSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wechatRedPacketSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WechatRedPacketSetting', function($stateParams, WechatRedPacketSetting) {
                    return WechatRedPacketSetting.get().$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'wechat-red-packet-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('wechat-red-packet-setting.edit', {
            parent: 'wechat-red-packet-setting',
            url: '/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wechat-red-packet-setting/wechat-red-packet-setting-dialog.html',
                    controller: 'WechatRedPacketSettingDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['WechatRedPacketSetting', function(WechatRedPacketSetting) {
                        return WechatRedPacketSetting.get().$promise;
                    }
                ]
            }
        })
        .state('wechat-red-packet-setting.new', {
            parent: 'wechat-red-packet-setting',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wechat-red-packet-setting/wechat-red-packet-setting-dialog.html',
                    controller: 'WechatRedPacketSettingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                enable: null,
                                wechatKey: null,
                                wechatAppid: null,
                                wechatCertificate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('wechat-red-packet-setting', null, { reload: 'wechat-red-packet-setting' });
                }, function() {
                    $state.go('wechat-red-packet-setting');
                });
            }]
        })
         ;
    }

})();
