(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MarketingController', MarketingController);

    MarketingController.$inject = ['$scope', '$q', '$rootScope', '$state', 'PasswordMarketing', 'Store', 'ListService', 'Principal', 'MessageService'];

    function MarketingController($scope, $q, $rootScope, $state, PasswordMarketing, Store, ListService, Principal, MessageService) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_MARKETINGMANAGEMENT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });

        vm.clear = clear;

        vm.marketingConfigs = PasswordMarketing.getAllByType(["Password", "GroupBuy", "WePass"]);

        $q.all([vm.marketingConfigs.$promise]).then(function(){
            if(vm.marketingConfigs && vm.marketingConfigs.length > 0){
                for (var i = vm.marketingConfigs.length - 1; i >= 0; i--) {
                    var cof = vm.marketingConfigs[i];
                    if (cof == null) {
                        continue;
                    }
                    if (cof.type == "Password") {
                        vm.marketingStore = cof;
                    }
                    if (cof.type == "GroupBuy") {
                        vm.marketingGroup = cof;
                    }
                    if (cof.type == "WePass") {
                        vm.wePassStore = cof;
                    }
                }
            }
            if (vm.marketingStore && vm.marketingStore.id) {
                vm.show = true;
            }
            if (vm.marketingGroup && vm.marketingGroup.id) {
                vm.show1 = true;
            }
            if (vm.wePassStore && vm.wePassStore.id) {
                vm.show2 = true;
            }
        })

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.marketingManagement = function(id){
            sessionStorage.setItem("lmenu", "a_marketing_management");
            $state.go("password-activity");
            function test(){
              $state.reload();
            }
            setTimeout(test,400);
        }
        $scope.marketingGroup = function(id){
            sessionStorage.setItem("lmenu", "a_marketing_group");
            $state.go("groupOverview");
            function test(){
              $state.reload();
            }
            setTimeout(test,400);
        }

        $scope.miraclePass = function(id){
            $state.go("pass");
            sessionStorage.setItem("lmenu", "a_pass");
            function test(){
              $state.reload();
            }
            setTimeout(test,400);
        }
        $scope.passMember = function(id){
            $state.go("memberList");
        }

        var unsubscribe = $rootScope.$on('backApp:categoryUpdate', function(event, result) {
            vm.marketing = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
