(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StatisticalSalesService', StatisticalSalesService)
        .factory('StatisticalSales', StatisticalSales);

    StatisticalSalesService.$inject = ['$resource'];
    StatisticalSales.$inject = ['$resource'];

    function StatisticalSales ($resource) {
        var resourceUrl =  'manager/' + 'api/statisticalSales/:path/:id';

        return $resource(resourceUrl, {}, {
            // ----------销售分析使用----end-------
            "yesterdayDate": {
                method: "GET",
                params: {
                    path: "yesterdayDate"
                }
            }
        });
    }

    function StatisticalSalesService ($resource) {
        var resourceUrl =  'service/' + 'api/pay-codes/:path/:id';

        return $resource(resourceUrl, {}, {
            "yesterdayDateAbled": {
                method: 'GET',
                params: {
                    path: 'getDateByYesterDay'
                }
            },
            "getAllByTimeSlot": {
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot'
                },
                isArray: true
            }
        });
    }
})();
