(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayCodeDialog2Controller', PayCodeDialog2Controller);

    PayCodeDialog2Controller.$inject = ['$scope', '$q', '$uibModalInstance', 'entity', 'PayCode', 'MessageService', 'Upload', 'Store', 'ProductPurchase', 'PayBatch', 'DateUtils'];

    function PayCodeDialog2Controller ($scope, $q, $uibModalInstance, entity, PayCode, MessageService, Upload, Store, ProductPurchase, PayBatch, DateUtils) {
        var vm = this;
        vm.payCode = entity;
        vm.clear = clear;
        vm.save = save;
        vm.purchases = ProductPurchase.getAllByCondition2({items:[{key:"status",value:"Approved",op:"="}]});

        $q.all([vm.purchases.$promise]).then(function(){

        })

        $scope.purchaseSelected = function(purchase){
            for (var i = vm.purchases.length - 1; i >= 0; i--) {
                var p = vm.purchases[i];
                if (p.selected && p.id != purchase.id) {
                    p.selected = false;
                }
            }
            vm.purchase = purchase;
            $scope.getPayBatchByPurchase(vm.purchase);

        }

        $scope.getPayBatchByPurchase = function(purchase){
            PayBatch.getAllByCondition(
                {items:[
                    {key:"used", op:"=", value:"false"},
                    {key:"status", op:"=", value:"Approved"},
                    {key:"storeId", op:"=",value:purchase.storeId},
                    {key:"productName", op:"=",value:purchase.productName},
                    {key:"range", op:"=",value:"Product"},
                    {key:"quantity", op:"=",value:purchase.quantity}
                    ]
                } , function(data){
                vm.paybatchs = data;
            },function(){
                MessageService.error("批次号获取失败")
            });
        }

        $scope.payBatchChange = function(){
            if (vm.payCode.payBatchId == null) {
                vm.payCode = {};
            }else{
                var p = $scope.findPayBatchById(vm.payCode.payBatchId, vm.paybatchs);
                if (p == null) {
                    return;
                }
                vm.payCode.type = p.type ;
                vm.payCode.range = p.range ;
                vm.payCode.productId = p.productId ;
                vm.payCode.productName = p.productName ;
                vm.payCode.amount = p.amount;
                vm.payCode.startDate = p.startDate;
                vm.payCode.periodOfValidity = p.periodOfValidity;
                vm.payCode.storeName = p.storeName ;
                vm.payCode.storeId = p.storeId ;
                vm.payCode.batch = p.number ;
                vm.payCode.supplier = p.supplier ;
                vm.payCode.payBatchId = p.id;
                vm.payCode.number = p.startNumber;
            }
        }
        $scope.findPayBatchById = function(id, datas){
            if (id == null || datas == null || datas.length == 0) {
                return null;
            }
            for (var i = datas.length - 1; i >= 0; i--) {
                var p = datas[i];
                if (p.id  == id) {
                    return p;
                }
            }
            return null;
        }



        $scope.validate = function() {
            if (vm.purchase == null) {
                MessageService.error("请选择生码项");
                return false;
            }
            if (vm.payCode.batch == null || vm.payCode.batch == "") {
                MessageService.error("请输入批次号");
                return false;
            };
            if (vm.payCode.supplier == null || vm.payCode.supplier == '') {
                MessageService.error('请输入卡片供应商');
                return false;
            }
            if (/^([0-9]+)$/.test(vm.payCode.number.substring(1)) && /[a-zA-Z]+/.test(vm.payCode.number.substring(0,1))) {
            }else{
                 MessageService.error("输入的起始卡号不符合规则");
                 return false;
            }
            return true;
        };

        function save() {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            ProductPurchase.complete(
                {id:vm.purchase.id, batch:vm.payCode.batch, supplier:vm.payCode.supplier,
                    number:vm.payCode.number, payBatchId:vm.payCode.payBatchId, periodOfValidity:vm.payCode.periodOfValidity,startDate:vm.payCode.startDate, amount:vm.payCode.amount} ,
                 onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.payCode = {}
            vm.purchases = ProductPurchase.getAllByCondition2({items:[{key:"status",value:"Approved",op:"="}]});
            vm.paybatchs = [];
            vm.purchase = null;
            vm.isSaving = false;
            MessageService.success('添加成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("添加失败,输入数据可能非法！");
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
