(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MessageNotificationDeleteController',MessageNotificationDeleteController);

    MessageNotificationDeleteController.$inject = ['$uibModalInstance', 'entity', 'MessageNotification','Store'];

    function MessageNotificationDeleteController($uibModalInstance, entity, MessageNotification,Store) {
        var vm = this;
        vm.store = Store.getCurrent();
        vm.messageNotification = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MessageNotification.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
