(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreThirdShopController', StoreThirdShopController);

    StoreThirdShopController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'ThirdStores', 'ParseLinks',  'paginationConstants'];

    function StoreThirdShopController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, ThirdStores, ParseLinks,   paginationConstants) {
        var vm = this;
        vm.store = Store.getCurrent();
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        init();
        vm.transition = transition;
        vm.data = [];
        function init(){
            ThirdStores.byCondition({
                items:[] ,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,asc"]
            }, function (data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.data = data;
            }, function (){
                MessageService.error("数据获取失败");
            });
        }
        function transition() {
            init()
        }

        $scope.delete =function(abutment){
            MessageService.confirm("确认要删除该数据", function(){
                ThirdStores.del({id:abutment.id},function(data){
                    MessageService.success("删除成功");
                    init();
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){

            })
        }
    }
})();
