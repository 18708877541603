(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ImgCropController', ImgCropController);

    ImgCropController.$inject = ['$timeout', '$scope', 'UidService', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService', 'Qinius', '$q', "Vert"];

    function ImgCropController ($timeout, $scope, UidService, $stateParams, $uibModalInstance, entity, $state, MessageService, Qinius, $q, Vert) {


        var vm = this;
        vm.tokens = Qinius.get();
        vm.data = {
            url: entity.url,
            width: entity.width,
            height: entity.height
        }

        $q.all([vm.tokens.$promise, vm.data.$promise]).then(function(){
            vm.token = vm.tokens.token;
            init();
        })
        var jcropApi;
        function init(){
            $timeout(function () {
                $('#element_id').Jcrop({
                    allowSelect: true,
                    baseClass: 'jcrop',
                    boxWidth: 900,
                    boxHeight: 500,
                    allowResize: true,
                    aspectRatio: vm.data.width/vm.data.height
                }, function() {
                    jcropApi = this;
                    console.log(jcropApi.tellSelect())
                });
            },100)
        }
        $scope.cropRemove = function(){
            $uibModalInstance.close();
        };
        $scope.cropSave = function(){
            var _tellSelect = jcropApi.tellSelect();
            var _getScaleFactor = jcropApi.getScaleFactor();
            if(_tellSelect.w == 0 || _tellSelect.h == 0){
                MessageService.error("请截取图片宽高");
                return
            }
            Vert.cutImage({
                url: vm.data.url,
                x: _tellSelect.x,
                y: _tellSelect.y,
                width: _tellSelect.w,
                height: _tellSelect.h,
                scale: 1
            }, function (res) {
                if(res.status == 200){
                    $uibModalInstance.close(res.message);
                }
            });
        }
    }
})();



//         var vm = this;
//         vm.cancel = cancel;
//         Qinius.get({}, function (res) {
//             vm.token = res.token;
//         });
//         vm.data = {
//             url: entity.url,
//             width: entity.width,
//             height: entity.height
//         }


//         init();
//         function init(){
//             $timeout(function () {
//                 var el = document.getElementById('imgCrop');
//                 vm.resize = new Croppie(el, {
//                     viewport: { width: vm.data.width, height: vm.data.height },
//                     // boundary: { width: 700, height: 350 },
//                     showZoomer: false,
//                     enableOrientation: true
//                 });
//                 vm.resize.bind({
//                     url: vm.data.url
//                 });
//             })
//         }
//         $scope.cropRotate = function(){
//             vm.resize.rotate(-90);
//         }
//         $scope.cropRemove = function(){
//             $uibModalInstance.close();
//         };
//         $scope.cropSave = function(){
//             vm.resize.result('base64').then(function(blob) {
//                 var formData = new FormData();
//                 formData.append('file', dataURLtoBlob(blob));
//                 formData.append('unique_names', false);
//                 formData.append('key', 'crop-' + UidService.get());
//                 formData.append('token', vm.token);
//                 $.ajax({
//                     cache: true,
//                     type: "POST",
//                     url: 'https://upload.qiniup.com/',
//                     data: formData,
//                     async: true,
//                     contentType: false,
//                     processData: false,
//                     success: function (data) {
//                         $uibModalInstance.close(data.key);
//                         $scope.$apply();
//                     },
//                     error: function (request) {
//                         MessageService.error("图片上传失败");
//                     }
//                 });
//             });
//         }
//         function dataURLtoBlob(dataurl) {
//             var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
//                 bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
//             while (n--) {
//                 u8arr[n] = bstr.charCodeAt(n);
//             }
//             return new Blob([u8arr], { type: mime });
//         }
//         //返回
//         function cancel() {
//             $uibModalInstance.close();
//         }
//     }
// })();
