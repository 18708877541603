
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DialogConfigDialogController', DialogConfigDialogController);
    DialogConfigDialogController.$inject = ['$timeout', '$q', '$scope', '$uibModalInstance', '$state', 'entity', 'DialogConfig', 'MessageService', 'Qinius',
        'UidService', 'Coupon', 'DateUtils'];

    function DialogConfigDialogController ($timeout, $q, $scope, $uibModalInstance, $state, entity, DialogConfig, MessageService, Qinius,
                                     UidService, Coupon, DateUtils) {
        var vm = this;
        vm.dialogConfig = entity;
        vm.clear = clear;
        vm.save = save;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        var currentTime = DateUtils.convertLocalDateToServer(new Date());

        vm.coupons =  Coupon.getAllByCondition({items:[{key:"sendRule", value:"Get", op:"="},{key:"enabled", value:"true", op:"="},{key:"endTime",op:">=",value: currentTime}]});
        $q.all([vm.domains.$promise, vm.tokens.$promise,vm.dialogConfig.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        $scope.dialogTargetChange = function(){
            if (vm.dialogConfig.dialogTarget != "COUPON_GET") {
                vm.dialogConfig.couponId = null;
            }
            if (vm.dialogConfig.dialogTarget == "COUPON_GET") {
                vm.dialogConfig.dialogType ='EVERYIN';
            }
        }

        //模板背景图更改
        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "dialogConfig-"+ UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        console.log(data);
                        vm.dialogConfig.dialogImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function saleableCheck(num) {
            console.log(num);
            if (num == 1) {
                vm.status = false;
            } else {
                vm.status = true;
            }
        }

        $scope.validate = function(){
            if (vm.dialogConfig.dialogImg == null || vm.dialogConfig.dialogImg=="") {
                MessageService.error("请上传展示图片");
                return false;
            };

            if (vm.dialogConfig.dialogTarget == "COUPON_GET" && !vm.dialogConfig.couponId ) {
                MessageService.error("请选择优惠券");
                return false;
            }
            return true;
        }

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.dialogConfig.id !== null) {
                DialogConfig.update(vm.dialogConfig, onSaveSuccess, onSaveError);
            } else {
                DialogConfig.save(vm.dialogConfig, onSaveSuccess, onSaveError);
            }
        }

        $scope.removeFile = function(file) {
            vm.dialogConfig.dialogImg = null; 
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:dialogConfigUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
