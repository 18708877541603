(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DockingSetController', DockingSetController);

    DockingSetController.$inject = ['$timeout', '$scope', '$state', 'StoreAbutments', 'MessageService','Principal'];

    function DockingSetController ($timeout, $scope, $state, StoreAbutments, MessageService,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.save = save;
        init();
        function init() {
            StoreAbutments.getOne({}, function (res) {
                vm.data = res ? res : {};
            })
        }

        function save() {
            if(!vm.data.skuMethod){
                MessageService.error("商品sku方法名不能为空");
                return
            }
            if(!vm.data.categoryMethod){
                MessageService.error("分类方法名不能为空");
                return
            }
            if(!vm.data.productMethod){
                MessageService.error("商品方法名不能为空");
                return
            }
            if(!vm.data.productSkuMethod){
                MessageService.error("sku方法名不能为空");
                return
            }
            if(!vm.data.orderMethod){
                MessageService.error("下单方法名不能为空");
                return
            }
            if(!vm.data.domain){
                MessageService.error("域名不能为空");
                return
            }
            if(!vm.data.type){
                MessageService.error("对接类型不能为空");
                return
            }
            if(!vm.data.appId){
                MessageService.error("appId不能为空");
                return
            }
            if(!vm.data.appSecret){
                MessageService.error("appSecret不能为空");
                return
            }

            if(vm.data.id){
                StoreAbutments.update(vm.data, function (res) {
                    init();
                    MessageService.success("设置成功");
                })
            }else{
                StoreAbutments.save(vm.data, function (res) {
                    init();
                    MessageService.success("设置成功");
                })
            }
        }
    }
})();
