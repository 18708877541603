(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('MessageNotification', MessageNotification);

    MessageNotification.$inject = ['$resource', 'DateUtils'];

    function MessageNotification ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/message-notifications/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            'getAllByCondition2': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition2'
                },
                isArray: true
            },
            'createByBack': {
                method: 'POST',
                params: {
                    path: 'createByBack'
                },
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validate = DateUtils.convertLocalDateFromServer(data.validate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validate = DateUtils.convertLocalDateToServer(copy.validate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
