(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Consumption', Consumption);

    Consumption.$inject = ['$resource'];

    function Consumption ($resource) {
        var resourceUrl =  'manager/' + 'api/consumption-lists/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getPriceByProductAndStore': {
                method: 'GET',
                params: {
                    path: 'getPriceByProductAndStore'
                }
            }
        })
    }
})();
