(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountHomeController', OpenAccountHomeController);

    OpenAccountHomeController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', '$http', 'FontManagement', '$q'];

    function OpenAccountHomeController($state, $scope, $timeout, Qinius, $uibModal, $http, FontManagement, $q) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
    }

})();
