(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreWithdrawalSettingDialogController', StoreWithdrawalSettingDialogController);

    StoreWithdrawalSettingDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'StoreWithdrawalSetting', '$state', 
    'MessageService', 'Store','Principal'];

    function StoreWithdrawalSettingDialogController ($timeout, $scope, $stateParams, entity, StoreWithdrawalSetting, $state, 
        MessageService, Store,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });

        vm.store = Store.getCurrent({});

        vm.storeWithdrawalSetting = entity;
        vm.save = save;

        vm.cancel = cancel;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function cancel (){
            $state.go("store-withdrawal-setting-detail");
        }

        $scope.valedate = function(){

            if (vm.storeWithdrawalSetting.minAmount == null || vm.storeWithdrawalSetting.minAmount<0.01) {
                MessageService.error("最小提现额 必须大于0.01");
                return false;
            }
            if (vm.storeWithdrawalSetting.commission == null ||  vm.storeWithdrawalSetting.commission<0 || vm.storeWithdrawalSetting.commission> 100) {
                MessageService.error("提现手续费不是有效值");
                return false;
            }
            return true;
        }

         
        function save () {
            if (!$scope.valedate()) {
                return;
            }
            vm.isSaving = true;
            if (vm.storeWithdrawalSetting.id != null) {
                StoreWithdrawalSetting.update(vm.storeWithdrawalSetting, onSaveSuccess, onSaveError);
            } else {
                StoreWithdrawalSetting.save(vm.storeWithdrawalSetting, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:storeWithdrawalSettingUpdate', result);
            vm.isSaving = false;
            $state.go("store-withdrawal-setting-detail", {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
