(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('PassBankController', PassBankController);

    PassBankController.$inject = ['$scope', '$state','BackupPassCategory','ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', '$localStorage'];

    function PassBankController ($scope, $state,BackupPassCategory,ParseLinks, paginationConstants, pagingParams, MessageService, $localStorage) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function loadAll(){
            if (pagingParams.search) {
                BackupPassCategory.byCondition({
                    items: [{key:"name",op:"like",value:pagingParams.search},{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }else {
                BackupPassCategory.byCondition({
                    items: [{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.categoryList = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("口令分类获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.changeStatue = function (category){
            category.enable = !category.enable;
            BackupPassCategory.changeStatue({id: category.id})
        }

        $scope.delete = function(id){
            MessageService.confirm("你确定要删除这条口令分类吗",function(){
                BackupPassCategory.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true);
        }
}
})();
