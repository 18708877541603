(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('BranchStoreUploadController', BranchStoreUploadController);

    BranchStoreUploadController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ListService',
     'MessageService', 'BranchStore', 'Upload', 'Store'];

    function BranchStoreUploadController($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, ListService, 
        MessageService, BranchStore, Upload, Store) {
        var vm = this;

        vm.coupon = entity;
        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.store = Store.getCurrent();
        $q.all([vm.store.$promise]).then(function(){
            // 先转字符串，后转回对象
            vm.store2 = JSON.parse(JSON.stringify(vm.store));
        });

        window.updateFile = function ($event) {
            // $timeout(function () {
            //     var file = $event.target.files[0];
            //     var getBase64 = function (file, callback) {
            //         var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
            //         reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
            //         reader.onload = function () { //  成功读取
            //             $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
            //         };
            //         reader.onerror = function (error) { //  出错
            //         };
            //     };
            //     getBase64(file, function (result) {
            //     });
                
            //     BranchStore.upload({file: file}, function(data) {
            //         clear();
            //     })
            // });
        };
 
        function save() {
            vm.isSaving = true;
            var file = document.getElementById("fileId").files[0];

            var getBase64 = function (file, callback) {
                var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                reader.onload = function () { //  成功读取
                    $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                };
                reader.onerror = function (error) { //  出错
                };
            };
            getBase64(file, function (result) {
            });

            console.log(vm.store2.id);

            Upload.upload({
                type: 'POST',
                url: 'manager/api/branch-store/uploadFile', //上传路径
                data: {
                    file: file,
                    storeId: vm.store2.id,
                    name: file.name

                }
            }).then(function successCallback(res) {
                if(res.status && res.status == 200){
                    MessageService.success("操作成功");
                    $uibModalInstance.close();
                }
            }, function errorCallback(response) {
                $uibModalInstance.close();
                MessageService.error("文件导入失败，请稍后重试");
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:couponUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();