
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FilterController', FilterController);

    FilterController.$inject = ['$scope','$state', 'MessageService','FilterOrderRecords','Principal'];

    function FilterController($scope,$state, MessageService,FilterOrderRecords,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_RECHARGE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.filterList=[
            {img:'content/images/filter1.png',type:''},
            {img:'content/images/filter2.png',type:'selfie_anime'},
            {img:'content/images/filter3.png',type:'wave'},
            {img:'content/images/filter4.png',type:'warm'},
            {img:'content/images/filter5.png',type:'scream'},
            {img:'content/images/filter6.png',type:'gothic'},
            {img:'content/images/filter7.png',type:'lavender'},
        ];
        $scope.filterChoose = function (item) {
            switch (item) {
                case 'selfie_anime':
                    return "人像动漫化";
                    break;
                case 'warm':
                    return "浪漫巴黎";
                    break;
                case 'wave':
                    return "金阳破浪";
                    break;
                case 'lavender':
                    return "紫藤奇缘";
                    break;
                case 'scream':
                    return "挪威森林";
                    break;
                case 'gothic':
                    return "情迷罗马";
                    break;
                default:
                    return "原画";
            }
        };
        $scope.filterType = function (item) {
            switch (item) {
                case 'PORTRAITCARTOON':
                    return "人像动漫化";
                    break;
                case 'IMAGESTYLECONVERT':
                    return "图像风格转换";
                    break;
                case 'SEGMENTATION':
                    return "人像抠图";
                    break;
                default:
                    return "";
            }
        };

        FilterOrderRecords.byStore({}, function (res) {
            vm.filter = res;
        })
    }
})();
