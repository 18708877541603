(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('PayCode', PayCode)
        .factory('PayCodeRecord', PayCodeRecord);

    PayCode.$inject = ['$resource', 'DateUtils'];
    PayCodeRecord.$inject = ['$resource', 'DateUtils'];

    function PayCode ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/pay-codes/:path/:code';

        return $resource(resourceUrl, {}, {
            'createByPayBatches':{
                method: 'GET',
                params: {
                    path: 'createByPayBatches'
                }
            },
            'batchDelete': {
                method: 'GET',
                params: {
                    path: 'batchDelete'
                }
            },
            "payCodeAnalysis":{
                method: 'GET',
                params: {
                    path: 'payCodeAnalysis'
                }
            },
            "unbind":{
                method: 'GET',
                params: {
                    path: 'unbind'
                }
            },
            "payCodeDataByBatch":{
                method: 'GET',
                params: {
                    path: 'payCodeDataByBatch'
                }
            },

            'activateByBatch': {
                method: 'GET',
                params: {
                    path: 'activateByBatch'
                }
            },
            'allByTimeSlot2': {
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot2'
                },
                isArray: true
            },
            'getAllByTimeSlot3':{
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot3'
                }
            },
            "payCodeAnalysisDownload":{
                method: 'GET',
                params: {
                    path: 'payCodeAnalysisDownload'
                }
            },
            'getBuyDateByYesterDay': {
                method: 'GET',
                params: {
                    path: 'getBuyDateByYesterDay'
                }
            },
            'updateRemark': {
                method: 'GET',
                params: {
                    path: 'updateRemark'
                }
            },
            'byCondition2': {
                method: 'GET',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            'byCondition4': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            'byCondition3': {
                method: 'POST',
                params: {
                    path: 'byCondition3'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            'batchCreate': {
                method: 'POST',
                params: {
                    path: 'batchCreate'
                }
            },
            'getAllByStore': {
                method: 'GET',
                params: {
                    path: 'getAllByStore'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getAllByTimeSlot': {
                method: 'GET',
                params: {
                    path: 'getAllByTimeSlot'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            "findByPayBatch":{
                method:"GET",
                params:{
                    path:"findByPayBatch"
                },
                isArray: true
            },
        });
    }

    function PayCodeRecord($resource, DateUtils){
        var resourceUrl =  'service/' + 'api/pay-code-records/:path/:id';

        return $resource(resourceUrl, {},{
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertLocalDateFromServer(data.createdDate);
                    }
                    return data;
                }
            },
        })
    }
})();
