(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayBatchDialogController', PayBatchDialogController);

    PayBatchDialogController.$inject = ['$timeout', '$scope', '$localStorage', '$uibModalInstance', 'entity', 'PayBatch', 'Store', 'Product', 'MessageService', '$q',
    'ItemValueLinePackageView', 'ProductPurchase', 'ItemValueLinePackage', '$stateParams', "DateUtils"];

    function PayBatchDialogController ($timeout, $scope, $localStorage, $uibModalInstance, entity, PayBatch, Store, Product, MessageService, $q,
                                       ItemValueLinePackageView, ProductPurchase, ItemValueLinePackage, $stateParams, DateUtils) {
        var vm = this;

        vm.type = $stateParams.type;
        vm.payBatch = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.save2 = save2;// 已分配 编辑保存
        vm.store = Store.getCurrent();
        vm.loading = true;
        vm.numberList = [
            {id:6,number:'6位'},
            {id:7,number:'7位'},
            {id:8,number:'8位'},
            {id:9,number:'9位'},
            {id:10,number:'10位'},
            {id:11,number:'11位'},
            {id:12,number:'12位'}
        ]
        vm.select2Options = {
            allowClear: true,
            formatNoMatches: "没有匹配到数据"
        };

        vm.exchangeCodePurchase = ProductPurchase.getAllByCondition({items:[{key: "status", op: "=", value: "Completed"},{key: "purchaseType", op: "=", value: "EXCHANGEPURCHASE"}]});
        vm.paycodePurchase = ProductPurchase.getAllByCondition({items:[{key: "status", op: "=", value: "Completed"},{key: "purchaseType", op: "in", value: "COMMODITYPURCHASE;GIFTPURCHASE"}]});

        $scope.payBatchTypeChange = function(){
            vm.purchases = [];
            if (vm.payBatch.payBatchType == null) {
                return;
            }
            if (vm.payBatch.payBatchType == 'PAYCODE') {
                vm.purchases = vm.paycodePurchase;
            }
            if (vm.payBatch.payBatchType == 'EXCHANGECODE') {
                vm.purchases = vm.exchangeCodePurchase;
                // vm.payBatch.length = null;
                vm.payBatch.freightFree = false;
            }
            vm.productPurchaseLines = [];
            for (var i = vm.purchases.length - 1; i >= 0; i--) {
                var p = vm.purchases[i];
                if (p.cancelled) {
                    continue;
                }
                if (p.id == vm.payBatch.productPurchaseId) {
                    vm.productPurchaseLines = angular.copy(p.productPurchaseLines)
                    formatting();
                    vm.itemValueLines = [];
                    for (var j = vm.products.length - 1; j >= 0; j--) {
                        var product = vm.products[j];
                        if (product.cancelled) {
                            continue;
                        }
                        if (product.productId == vm.payBatch.productId) {
                            vm.itemValueLines = angular.copy(product.itemValueLines);
                            break;
                        }
                    }
                    break;
                }
            }
        }

        $q.all([vm.payBatch.$promise, vm.store.$promise, vm.exchangeCodePurchase.$promise, vm.paycodePurchase.$promise]).then(function(){
            vm.loading = false;
            if(vm.store.unique==null){
                MessageService.error("请先设置商户标识");
                return
            }
            var nowYear = new Date().getFullYear();
            var nowMouth = new Date().getMonth()+1;
            var nowDay = new Date().getDate();
            if(nowMouth<10){
                nowMouth=0+''+nowMouth;
            }
            if(nowDay<10){
                nowDay=0+''+nowDay;
            }

            vm.uniqueTimeStr = vm.store.unique+""+nowYear+""+nowMouth+""+nowDay;
            vm.payBathAll = PayBatch.getAllByCondition({items: [{key: "storeId", op: "=", value: $localStorage.user.storeId}, {key:"number", op:"like", value:vm.uniqueTimeStr}]})||[];
            vm.payBatch.storeId = vm.store.id;
            vm.payBatch.storeName = vm.store.name;
            // 编辑
            if(vm.payBatch.purchaseType=='COMMODITYPURCHASE'){
                ItemValueLinePackage.getPackage({
                    purchaseId:vm.payBatch.productPurchaseId,
                    itemValueLineId:vm.payBatch.itemValueLineId,
                },function (data) {
                    vm.payBatch.packageId = data.id;
                    vm.maxStockQuantity = data.stockQuantity;
                })
            }else if(vm.payBatch.purchaseType=='GIFTPURCHASE' || vm.payBatch.purchaseType=='EXCHANGEPURCHASE'){
                ItemValueLinePackage.getPackage({
                    purchaseId:vm.payBatch.productPurchaseId
                },function (data) {
                    vm.payBatch.packageId = data.id;
                    vm.maxStockQuantity = data.stockQuantity;
                })
            }

            if(vm.payBatch.id){
                $scope.payBatchTypeChange();
            }

        })

        $scope.rangeChange = function(){
            vm.payBatch.productId = null;
            vm.payBatch.productName = null;
        }

        $scope.purchaseChange = function(){
            if (vm.payBatch.payBatchType == null) {
                MessageService.error("请先选择批次号类型");
                return;
            }
            if (vm.payBatch.productPurchaseId == null || vm.purchases == null || vm.purchases.length == 0) {
                vm.payBatch.productPurchaseId == null;
                return;
            }
            vm.productPurchaseLines = [];
            for (var i = vm.purchases.length - 1; i >= 0; i--) {
                var p = vm.purchases[i];
                if (p.cancelled) {
                    continue;
                }
                if (p.id == vm.payBatch.productPurchaseId) {
                    vm.productPurchaseLines = angular.copy(p.productPurchaseLines)
                    vm.payBatch.purchaseType = p.purchaseType;
                    formatting();
                    vm.maxStockQuantity = null;
                    vm.payBatch.itemValueLineId = null;
                    vm.payBatch.productId = null;
                    vm.itemValueLines = [];
                    if(vm.payBatch.purchaseType=='GIFTPURCHASE' || vm.payBatch.purchaseType=='EXCHANGEPURCHASE'){
                        ItemValueLinePackage.getPackage({
                            purchaseId:vm.payBatch.productPurchaseId
                        },function (data) {
                            vm.payBatch.packageId = data.id;
                            vm.maxStockQuantity = data.stockQuantity;
                            // vm.payBatch.packageName = data.productPurchaseName;
                        })
                    }
                    break;
                }
            }
        }

        function formatting() {
            vm.products = []
            for(var i=0; i<vm.productPurchaseLines.length; i++){
                var _itemValueLineProductId = vm.productPurchaseLines[i].itemValueLineProductId;
                var _itemValueLineProductName = vm.productPurchaseLines[i].itemValueLineProductName;
                var _product = {
                    productId: _itemValueLineProductId,
                    productName: _itemValueLineProductName,
                    itemValueLines:  []
                }
                var _isResult = vm.products.some(function (item, index) {
                    if(item.productId==_itemValueLineProductId){
                        vm.products[index].itemValueLines.push(vm.productPurchaseLines[i])
                        return true
                    }
                })
                if(!_isResult){
                    _product.itemValueLines.push(vm.productPurchaseLines[i])
                    vm.products.push(_product)
                }
            }
            console.log(vm.products)
        }

        $scope.productChange = function(){
            vm.payBatch.productName = null;
            if (vm.payBatch.productId == null || vm.products == null || vm.products.length == 0) {
                vm.payBatch.productId == null;
                return;
            }
            vm.itemValueLines = [];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var p = vm.products[i];
                if (p.cancelled) {
                    continue;
                }
                if (p.productId == vm.payBatch.productId) {
                    vm.payBatch.productName = p.productName;
                    vm.itemValueLines = angular.copy(p.itemValueLines);
                    break;
                }
            }
        }

        $scope.skuChange = function () {
            ItemValueLinePackage.getPackage({
                purchaseId:vm.payBatch.productPurchaseId,
                itemValueLineId:vm.payBatch.itemValueLineId,
            },function (data) {
                vm.payBatch.packageId = data.id;
                vm.maxStockQuantity = data.stockQuantity;
                vm.payBatch.productSku = data.itemValueLines[0].sku;
            })
        }

        $scope.generateCode = function (e) {
            fn(e);

            var random = Math.floor(Math.random()*9000) + 1000;
            confirmNumber(random);

        }
        function confirmNumber( random) {
            var str = vm.uniqueTimeStr+""+random;
            if(!vm.payBathAll.length){
                vm.payBatch.number = str;
                return
            }
            angular.forEach(vm.payBathAll,function (p,i) {
                if(p.number.indexOf(str)>=0){
                    random = Math.floor(Math.random()*9000) + 1000;
                    confirmNumber(random);
                    return
                }
                if(i==vm.payBathAll.length-1){
                    vm.payBatch.number = str;
                    return
                }
            })
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
        $scope.validate = function() {


            if (vm.payBatch.number == null || vm.payBatch.number == "") {
                MessageService.error('请输入批次号');
                return false;
            }
            if (vm.payBatch.payBatchType == null || vm.payBatch.payBatchType == "") {
                MessageService.error('请选择类型');
                return false;
            }
            if (vm.payBatch.productPurchaseId == null || vm.payBatch.productPurchaseId == "") {
                MessageService.error('请选择订购申请');
                return false;
            }
            if(vm.payBatch.payBatchType == 'PAYCODE' && vm.payBatch.purchaseType =='COMMODITYPURCHASE'){
                if (vm.payBatch.productId == null || vm.payBatch.productId == "") {
                    MessageService.error("请选择商品");
                    return false;
                }
                if (vm.payBatch.itemValueLineId == null || vm.payBatch.itemValueLineId == "") {
                    MessageService.error("请选择sku");
                    return false;
                }
            }
            if (vm.payBatch.quantity == null || vm.payBatch.quantity == "") {
                MessageService.error('请输入生成数');
                return false;
            }else if(vm.maxStockQuantity!=null){
                if(vm.payBatch.quantity>vm.maxStockQuantity){
                    MessageService.error('剩余库存小于生成数');
                    return false;
                }
            }
console.log("vm.maxStockQuantity: "+vm.maxStockQuantity)
            if (vm.payBatch.remark == null || vm.payBatch.remark == "") {
                MessageService.error('请输入用途');
                return false;
            }

            if (!vm.payBatch.startNumber) {
                MessageService.error("起始卡号不能为空");
                return false;
            }
            if (/^([0-9]+)$/.test(vm.payBatch.startNumber.substring(1)) && /[a-zA-Z]+/.test(vm.payBatch.startNumber.substring(0,1))) {
            }else{
                MessageService.error("输入的起始卡号不符合规则");
                return false;
            }
            if (vm.payBatch.startNumber.length>15) {
                MessageService.error("起始卡号不能超过15个字符");
                return false;
            }
            if ( vm.payBatch.periodOfValidity == null || vm.payBatch.periodOfValidity == "") {
                MessageService.error("请输入有效截止时间");
                return false;
            }
            if ( vm.payBatch.periodOfValidity == null || vm.payBatch.periodOfValidity == "") {
                MessageService.error("请输入有效开始时间");
                return false;
            }
            if (vm.payBatch.periodOfValidity.getTime() < new Date().getTime()) {
                MessageService.error("有效期不能是当天或之前的日期");
                return false;
            }
            // if(vm.payBatch.payBatchType == 'PAYCODE'&&vm.payBatch.length==null||vm.payBatch.length==''){
            //     MessageService.error("请选择卡密位数");
            //     return false;
            // }
            return true;
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.payBatch.prototype = 'Store';
            console.log(vm.payBatch)
            if(!$scope.validate()){
                return;
            }

            if(vm.payBatch.purchaseType=='GIFTPURCHASE' || vm.payBatch.purchaseType=='EXCHANGEPURCHASE'){
                var proName = "";
                var skuName = "";
                angular.forEach(vm.products,function (pro) {
                    proName+=pro.productName+';';
                    angular.forEach(pro.itemValueLines,function (line) {
                        skuName+=pro.productName+'('+line.sku+');'
                    })
                })
                vm.payBatch.productName = proName.substring(0,proName.length-1);
                vm.payBatch.productSku = skuName.substring(0,skuName.length-1);
            }

            PayBatch.getAllByCondition({
                items: [{key:"number", op:"=", value:vm.payBatch.number}]
            }, function(data){
                if (vm.payBatch.id !== null) {
                    if ((data.length ==1 && data[0].id != vm.payBatch.id) || data.length>1) {
                        MessageService.error("批次号重复，请重新设定批次号");
                    }else{
                        vm.isSaving = true;

                        vm.payBatch.startDate = DateUtils.convertLocalDateToServer(vm.payBatch.startDate);
                        vm.payBatch.periodOfValidity = DateUtils.convertLocalDateToServer(vm.payBatch.periodOfValidity);
                        PayBatch.update(vm.payBatch, onSaveSuccess, onSaveError);
                    }
                } else {
                    if (data.length >0) {
                        MessageService.error("批次号重复，请重新设定批次号");
                    }else{
                        vm.isSaving = true;
                        PayBatch.save(vm.payBatch, onSaveSuccess, onSaveError);
                    }

                }
            }, function(){
                MessageService.error("检查批次号失败，请稍后再试");
            });


        }

        function save2 () {
            vm.isSaving = true;
            vm.payBatch.prototype = 'Store';
            vm.payBatch.startDate = DateUtils.convertLocalDateToServer(vm.payBatch.startDate);
            vm.payBatch.periodOfValidity = DateUtils.convertLocalDateToServer(vm.payBatch.periodOfValidity);
            PayBatch.update2(vm.payBatch, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:payBatchUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('添加成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');
            MessageService.error("系统请求超时，请稍后刷新系统，确认是否添加成功");
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.periodOfValidity = false;
        vm.datePickerOpenStatus.startDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
