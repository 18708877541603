(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountSecretKeyController', OpenAccountSecretKeyController);

    OpenAccountSecretKeyController.$inject = ['$state', '$scope', '$timeout', 'SecretKey', '$uibModal', '$http', 'Store', 'MessageService','$q'];

    function OpenAccountSecretKeyController($state, $scope, $timeout, SecretKey, $uibModal, $http, Store, MessageService, $q) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        $q.all([vm.store.$promise]).then(function(){
            if(vm.store.activated){
                $state.go('openAccount-commercial');
            }
        })
        vm.secretKey="";

        $scope.save = function () {
            if(!vm.secretKey){
                MessageService.error("开户秘钥不能为空");
                return
            }
            SecretKey.use({key:vm.secretKey}, function (res) {
                if(res){
                    $state.go('openAccount-commercial')
                }else{
                    MessageService.error(res);
                }
            },function (error) {
                MessageService.error(error.data.message);
            })
        }
    }

})();
