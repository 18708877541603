(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MouldDialogController', MouldDialogController);

    MouldDialogController.$inject = ['$timeout', '$scope', 'MessageService', '$state', 'entity', 'Mould', 'Category', 'Property', '$q', 'ListService', 'UidService', '$localStorage'];

    function MouldDialogController($timeout, $scope, MessageService, $state, entity, Mould, Category, Property, $q, ListService, UidService, $localStorage) {
        var vm = this;

        vm.mould = entity;
        console.log(vm.mould)
        vm.clear = clear;
        vm.save = save;
        vm.categories = Category.storeGetAllByCondition({
            items: [{key: "usable", op: "=", value: "true"}]
        });

        vm.properties = Property.getAllByCondition({
            items: [{key: "propertyType", op: "=", value: "Default"},{key: "storeId", op: "=", value: $localStorage.user.storeId}]
        });

        vm.expandedProperties = Property.getAllByCondition({
            items: [{key: "propertyType", op: "=", value: "Expanded"},{key: "storeId", op: "=", value: $localStorage.user.storeId}]
        });


        $scope.validate = function(){
            if (vm.mould.name == null || vm.mould.name == "") {
                MessageService.error("请输入名称")
                return false;
            }

            if (vm.mould.categoryId == null || vm.mould.categoryId == "") {
                MessageService.error("请选择分类")
                return false;
            }

            if (vm.mould.mouldProperties == null || vm.mould.mouldProperties.length == 0) {
                MessageService.error("请选择属性")
                return false;
            }
            
            if (vm.mould.defaultObjectProperties&&vm.mould.defaultObjectProperties.length) {
                for (var i = 0; i < vm.mould.defaultObjectProperties.length; i++) {
                    var item = vm.mould.defaultObjectProperties[i];
                    if(!item.propertyId){
                        MessageService.error("请选择规格属性");
                        return false;
                        // break;
                    }
                }

                //筛选出重复的  比较propertyId
                vm.arr1 = uniqeByKeys(vm.mould.defaultObjectProperties, ['propertyId']);
                console.log(vm.arr1)
                if(vm.arr1.length!=vm.mould.defaultObjectProperties.length){
                    MessageService.error("请删除重复规格")
                    return false;
                }
            }

            if (vm.mould.expandedObjectProperties&&vm.mould.expandedObjectProperties.length) {
                for (var i = 0; i < vm.mould.expandedObjectProperties.length; i++) {
                    var item = vm.mould.expandedObjectProperties[i];
                    if(!item.propertyId){
                        MessageService.error("请选择扩展规格属性");
                        return false;
                        // break;
                    }
                }

                //筛选出重复的  比较propertyId
                vm.arr2 = uniqeByKeys(vm.mould.expandedObjectProperties, ['propertyId']);
                console.log(vm.arr2)
                if(vm.arr2.length!=vm.mould.expandedObjectProperties.length){
                    MessageService.error("请删除重复扩展规格")
                    return false;
                }
            }

            return true;
        }

        function obj2key(obj, keys) {
            var n = keys.length,
                key = [];
            while (n--) {
                key.push(obj[keys[n]]);
            }
            return key.join('|');
        }

        //去重操作
        function uniqeByKeys(array, keys) {
            var arr = [];
            var hash = {};
            for (var i = 0, j = array.length; i < j; i++) {
                if(!array[i].cancelled&&!array[i].deleted){
                    var k = obj2key(array[i], keys);
                    if (!(k in hash)) {
                        hash[k] = true;
                        arr.push(array[i]);
                    }
                }else{
                    arr.push(array[i]);
                }

            }
            return arr;
        }

        $q.all([vm.properties.$promise, vm.expandedProperties.$promise, vm.categories.$promise]).then(function () {
            vm.mould.defaultObjectProperties = [];
            vm.mould.expandedObjectProperties = [];
            if (vm.mould.id && vm.mould.mouldProperties != null) {
                for (var i = vm.mould.mouldProperties.length - 1; i >= 0; i--) {
                    var ob = vm.mould.mouldProperties[i];
                    if (ob.propertyType == "Default") {
                        vm.mould.defaultObjectProperties.push(ob);
                    } else if (ob.propertyType == "Expanded") {
                        vm.mould.expandedObjectProperties.push(ob);
                    }
                }
            }
            console.log(vm.mould)
        })


        $scope.addItemValue = function () {
            var objectValue = {
                added: true,
                deleted: false,
                uuid: UidService.get(),
                propertyType: 'Default'
            };
            vm.mould.defaultObjectProperties.push(objectValue)
        }

        $scope.addExpandedItemValue = function () {

            var objectValue = {
                added: true,
                deleted: false,
                uuid: UidService.get(),
                propertyType: 'Expanded',
            };
            vm.mould.expandedObjectProperties.push(objectValue)
        }

        $scope.deleteValue = function (value, properties) {
            if (value == null) {
                return;
            }

            if (!value.added) {
                value.deleted = true;
            } else {
                var ind = properties.indexOf(value);
                if (ind >= 0) {
                    properties.splice(ind, 1);
                }
            }
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        })

        function clear() {
            $state.go('mould', null, {reload: 'mould'});
        }

        function save() {

            vm.mould.mouldProperties = [];
            if (vm.mould.defaultObjectProperties &&  vm.mould.defaultObjectProperties.length>0 ) {
                for (var i = vm.mould.defaultObjectProperties.length - 1; i >= 0; i--) {
                    var op = vm.mould.defaultObjectProperties[i];
                    vm.mould.mouldProperties.push(op);
                }
            }
            if (vm.mould.expandedObjectProperties && vm.mould.expandedObjectProperties.length>0) {
                for (var i = vm.mould.expandedObjectProperties.length - 1; i >= 0; i--) {
                    var op = vm.mould.expandedObjectProperties[i];
                    vm.mould.mouldProperties.push(op);
                }
            }
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            if (vm.mould.id !== null) {
                Mould.update(vm.mould, onSaveSuccess, onSaveError);
            } else {
                Mould.save(vm.mould, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('platformApp:mouldUpdate', result);
            $state.go('mould', null, {reload: 'mould'});
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }


    }
})();
