(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('WechatRedPacketSettingDialogController', WechatRedPacketSettingDialogController);

    WechatRedPacketSettingDialogController.$inject = ['$timeout','UidService', '$scope', '$stateParams',  'entity', 'WechatRedPacketSetting',
     'MessageService', '$state', 'Qinius', '$q'];

    function WechatRedPacketSettingDialogController ($timeout,UidService, $scope, $stateParams,   entity, WechatRedPacketSetting,
     MessageService, $state, Qinius, $q) {
        var vm = this;

        vm.wechatRedPacketSetting = entity;
        vm.clear = clear;
        vm.save = save;
        vm.tokens = Qinius.get();

        $q.all([  vm.tokens.$promise, ]).then(function () {
            vm.token = vm.tokens.token;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
             $state.go("wechat-red-packet-setting", null, { reload:  true });
        }

        window.upload = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        $scope.removeFile = function(){
            vm.wechatRedPacketSetting.wechatCertificate = null;
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'wechat-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.wechatRedPacketSetting.wechatCertificate = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }


        function save () {
            vm.isSaving = true;
            if (vm.wechatRedPacketSetting.id !== null) {
                WechatRedPacketSetting.update(vm.wechatRedPacketSetting, onSaveSuccess, onSaveError);
            } else {
                WechatRedPacketSetting.save(vm.wechatRedPacketSetting, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('backApp:wechatRedPacketSettingUpdate', result);
            MessageService.success("保存成功");
            $state.go("wechat-red-packet-setting", null, { reload:  true });

        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }


    }
})();
