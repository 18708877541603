(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountTencentController', OpenAccountTencentController);

    OpenAccountTencentController.$inject = ['$scope', '$state', 'Store', 'StoreWechatSetting', 'MessageService', '$q', 'entity', 'PlatformWechatSetting', 'WechatAuth'];

    function OpenAccountTencentController ($scope, $state, Store, StoreWechatSetting, MessageService, $q, entity, PlatformWechatSetting, WechatAuth) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();

        vm.storeWechatSetting = entity;
        vm.store = Store.getCurrent();
        vm.pre_auth_code = WechatAuth.pre_auth_code();
        vm.platformWechatSetting = PlatformWechatSetting.get();

        $q.all([vm.pre_auth_code.$promise, vm.platformWechatSetting.$promise, vm.storeWechatSetting.$promise, vm.store.$promise]).then(function () {
            if (vm.pre_auth_code.status == "200") {
                vm.preAuthCode =  vm.pre_auth_code.message;
            }
            $scope.notifyUrl = encodeURIComponent(vm.platformWechatSetting.backNotifyUrl + "/#/openAccount-tencent-detail");
        })

        $scope.auth = function(){
            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="+vm.platformWechatSetting.appId
                +"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+ $scope.notifyUrl+"&auth_type=1";
        }

        $scope.save = function(){
            $state.go("openAccount-modePayment-tencent");
        }
    }
})();
