(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('JoinController', JoinController);

    JoinController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'StoreApplies'];

    function JoinController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, StoreApplies) {
        var vm = this;
        vm.store = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.storeList = [];
        vm.saveData = {
            storeName: vm.store.name,
            status: vm.store.status,
            applyType: 'JOIN',
            parentStoreId: ""
        };

        //获取所有上级商户
        Store.getAllByCondition({
            items: [{key:"bigStore",op:"=",value: 'true'}]
        }, function (res) {
            res.forEach(function (item) {
                console.log(item)
                if(!item.cancelled){
                    vm.storeList.push(item)
                }
            })
        })

        $scope.chooseOne = function (item) {
            item.select = !item.select;
            if(item.select){
                vm.saveData.parentStoreId = item.id;
                vm.saveData.parentStoreName = item.name;
                vm.storeList.forEach(function (data) {
                    if(item.id != data.id){
                        data.select = false;
                    }
                })
            }else {
                vm.saveData.parentStoreId = "";
                vm.saveData.parentStoreName = "";
            }
        }


        //确定
        function save() {
            if(!vm.saveData.parentStoreId){
                MessageService.error("请选择要加入的商户");
                return
            }
            StoreApplies.save(vm.saveData, function (res) {
                MessageService.success("加盟申请已提交！");
                $uibModalInstance.close();
            }, function (error) {
                $uibModalInstance.close();
            })
        }

        //返回
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
