(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StoreApplies', StoreApplies);
    StoreApplies.$inject = ['$resource'];
    function StoreApplies ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-applies/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition":{
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method: 'GET',
                params: {
                    path: 'getAllByCondition2'
                },
                isArray: true
            },
            'save': {method: 'POST'},
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'approve': {
                method: 'GET',
                params: {
                    path: 'approve'
                }
            },
            'reject': {
                method: 'GET',
                params: {
                    path: 'reject'
                }
            },
            'batchApprove': {
                method: 'GET',
                params: {
                    path: 'batchApprove'
                }
            },
            'batchReject': {
                method: 'GET',
                params: {
                    path: 'batchReject'
                }
            },
        });
    }
})();
