(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CategoryDialogController', CategoryDialogController);

    CategoryDialogController.$inject = ['$timeout', '$q', '$scope', '$state', 'entity', 'Qinius', 'Category',  'MessageService', 'UidService'];

    function CategoryDialogController ($timeout, $q, $scope, $state, entity, Qinius, Category,  MessageService, UidService) {
        var vm = this;

        vm.category = entity;
        vm.clear = clear;
        vm.save = save;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.category.$promise]).then(function(){
            var items = [];
            if (!!vm.category.id) {
               items.push({key:"id", op :"!=", value:vm.category.id},{key:"haveSuperior", op :"=", value:false}) 
            }
            vm.categories = Category.storeGetAllByCondition({items: items});

            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        //模板背景图更改
        window.updateImgFile = function ($event) {
            $scope.$digest();
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "category-"+ UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.category.imgUrl = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear () {
            $state.go('category', null, { reload: 'category' });
        }

        $scope.validate = function(){
            if(vm.category.name == null || vm.category.name==""){
                MessageService.error("请输入分类名称");
                return false;
            }

            if(vm.category.seq == null || vm.category.seq < 0){
                MessageService.error("优先值不能小于零");
                return false;
            }

            if (vm.category.imgUrl == null || vm.category.imgUrl=="") {
                MessageService.error("请上传展示图片");
                return false;
            };
            if(vm.category.usable == null || vm.category.usable==""){
                vm.category.usable = false;
            }
            if(!vm.category.haveSuperior){
                vm.category.categoryId=null;
                vm.category.categoryName=null;
            }
            return true;
        }
        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.category.id !== null) {
                Category.update(vm.category, onSaveSuccess, onSaveError);
            } else {
                Category.categoryStoreCreate(vm.category, onSaveSuccess, onSaveError);
            }
        }


        $scope.removeFile = function(file) {
            vm.category.imgUrl = null;
        }


        function onSaveSuccess (result) {
            $scope.$emit('backApp:categoryUpdate', result);
            $state.go('category', null, { reload: 'category' });
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }

        $scope.haveSuperiorChange = function () {
            if(vm.category.haveSuperior){
                vm.category.hot = false;
            }
        }


    }
})();
