(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GiftController', GiftController);

    GiftController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService', 'MembershipPackages','$uibModal', 'Coupon'];

    function GiftController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService, MembershipPackages, $uibModal, Coupon) {
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.coupon = [];
        if(entity.type == 'add'){
            vm.gift = {
                seq: '',
                name: '',
                productName: '',
                quantity: '',
                price: '',
                number: '',
                coupons:[],
                residueQuantity:''
            }
            init();
        }
        if (entity.type == 'edit'){
            MembershipPackages.get({id:entity.id}, function (res) {
                vm.gift = res;
                init();
            })
        }
        function init(){
            Coupon.getAllByCondition({
                items: [
                    {key: "cancelled", op: "=", value: "false"},
                    {key: "enabled", op: "=", value: "true"},
                    {key: "sendRule", op: "=", value: "Vip"}
                ]
            },function (res) {
                angular.forEach(res, function (item) {
                    for(var i=0; i<vm.gift.coupons.length; i++){
                        if(item.id == vm.gift.coupons[i].id){
                            item.selected = true;
                        }
                    }
                    vm.coupon.push(item)
                })
            })
        }
        $scope.chooseOne = function(item){
            item.selected = !item.selected;
            couponInit()
        };
        function couponInit(){
            vm.gift.coupons = [];
            for(var i=0; i<vm.coupon.length; i++){
                if(vm.coupon[i].selected){
                    vm.gift.coupons.push(vm.coupon[i])
                }
            }
            vm.gift.quantity = vm.gift.coupons.length;
        }

        // $scope.$watch('vm.gift.quantity', function(newValue, oldValue) {
        //     if(newValue){
        //         if(!checkFloatNum(newValue)){
        //             vm.gift.quantity = oldValue;
        //             return
        //         }
        //         if(newValue > oldValue){
        //             var _i = newValue - oldValue;
        //             for(var i=0; i<_i; i++){
        //                 vm.gift.coupons.push({})
        //             }
        //         }
        //         if(newValue < oldValue){
        //             var _i = newValue - 1;
        //             for(var i=vm.gift.coupons.length-1; i>_i; i--){
        //                 vm.gift.coupons.splice(i,1)
        //             }
        //         }
        //     }else {
        //         if(vm.gift){
        //             vm.gift.coupons=[]
        //         }
        //     }
        // });
        // $scope.couponsCb = function (item, index) {
        //     $uibModal.open({
        //         templateUrl: 'app/entities/memberRegistration/modal/gift-coupon.html',
        //         controller: 'GiftCouponController',
        //         controllerAs: 'vm',
        //         backdrop: 'static',
        //         resolve: {
        //             entity: [function() {
        //                 return {
        //                     data:item
        //                 };
        //             }]
        //         }
        //     }).result.then(function(data) {
        //         if(data){
        //             vm.gift.coupons[index] = data;
        //         }
        //         console.log(vm.gift.coupons)
        //     });
        // }

        function save() {
            if(entity.type == 'add'){
                MembershipPackages.save(vm.gift, function (res) {
                    $uibModalInstance.close(true);
                })
            }
            if(entity.type == 'edit'){
                MembershipPackages.update(vm.gift, function (res) {
                    $uibModalInstance.close(true);
                })
            }
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
        function checkFloatNum(str){
            var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
            if (!reg_zs.test(str)) {
                return false;
            }
            return true;
        }
    }
})();
