(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ExpressLocation', ExpressLocation);

    ExpressLocation.$inject = ['$resource', 'DateUtils'];

    function ExpressLocation($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/expressLocation/:path/:id';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'getOne': {
                method: 'GET',
                params: {
                    path: 'getExpressLocation'
                }
            },
            'getLocation': {
                method: 'GET',
                params: {
                    path: "all"
                },
            },
            'expressCode': {
                method: 'POST',
                params: {
                    path: 'code'
                }
            },
            'del': {
                method: 'DELETE'
            },
            'changeStatus': {
                method: 'GET',
                params: {
                    path: 'changeStatus'
                }
            }

        });
    }
})();