(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SharePageTitleController', SharePageTitleController);

    SharePageTitleController.$inject = ['$scope', '$uibModalInstance', '$timeout', 'UidService', 'SharePageConfig', '$q', '$rootScope', 
    '$stateParams', 'MessageService', 'Store', 'Qinius', 'Principal', '$state', 'entity'];

    function SharePageTitleController($scope, $uibModalInstance, $timeout, UidService, SharePageConfig, $q, $rootScope, 
        $stateParams, MessageService, Store, Qinius, Principal, $state, entity) {
        var vm = this;
        vm.config = entity;
        vm.cancel = cancel;
        vm.save = save;
        $q.all([vm.config.$promise]).then(function() {
            vm.title = angular.copy(vm.config.title) ;
        });


        function cancel() { 
            $uibModalInstance.dismiss('cancel');
        }
        function save() {
            vm.config.title = vm.title;
            $uibModalInstance.close(vm.config);
        }

        $scope.addParam = function(p){
            if (p == null) {
                return;
            }
            $scope.caretPosition = 0;
            var obj = document.getElementById("title");
            if (document.selection) {
                obj.focus();
                var oSel = document.selection.createRange();
                oSel.moveStart('character', -obj.value.length);
                $scope.caretPosition = oSel.text.length;
            }else if (obj.selectionStart || obj.selectionStart == '0'){
                $scope.caretPosition = obj.selectionStart;
            }
            console.log($scope.caretPosition);
            vm.title = vm.title.slice(0,$scope.caretPosition)+ p.value+vm.title.slice($scope.caretPosition);
            
        }

        vm.params = [{
            value:"#userName#",
            key:"用户名"
        },
        {
            value:"#productName#",
            key:"商品标题"
        },
        {
            value:"#articleName#",
            key:"作品名"
        }]

         
        

        // 保存数据
        $scope.putShareBgc = function() {
            if ($scope.radio == 0) {
                $scope.shareBgc.default = true
                $scope.shareBgc.bcImg = $scope.defaultImg
            } else {
                $scope.shareBgc.default = false
            }
            if ($scope.shareBgc.bcImg == '') {
                MessageService.error('请上传背景图片');
                return
            }
            ShareTheBac.putShareBgc($scope.shareBgc, function(res) {
                if (res.status == 200) {
                    MessageService.success('保存成功')
                } else {
                    MessageService.error(res.message == null ? '保存失败' : res.message);
                }
            })
        }
    }
})();