(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SubstitutionController2', SubstitutionController2);

    SubstitutionController2.$inject = ['$scope', '$state', 'Substitution', 'Category', 'Product', 'MessageService', 'pagingParams', 'Principal',
    'ParseLinks', 'paginationConstants', 'DateUtils', 'Upload', 'Account', '$q', 'Qinius', 'UidService'];

    function SubstitutionController2 ($scope, $state, Substitution, Category, Product, MessageService, pagingParams, Principal,
        ParseLinks, paginationConstants, DateUtils, Upload, Account, $q, Qinius, UidService ) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_RGASSIGN').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.selectedAll = false;
        vm.selectData = [];

        vm.progress = 0;
        vm.style = 'blue';

        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.status = pagingParams.status;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        $scope.selectTab ='';
        vm.user = Account.get();
        vm.loading = false;


        if(vm.status == '' || vm.status == undefined ){
            $scope.selectTab = '';
        }else{
            $scope.selectTab = vm.status;
        }

        $scope.byStatus =function(status){
            if(status == '' || status == undefined){
                $scope.selectTab = '';
            }else {
                $scope.selectTab = status;
            }
            vm.status = status;
            search();
        };

        // 商品品类
        vm.categorys = Category.getAllByCondition({items:[]});
        vm.products = Product.getAllByCondition({
            items: [{key:"prototype",op:"in",value:"Store;StoreSelf"}]
        });


        $scope.screens = function(){
            search();
        };

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }

        $scope.uploadFile = function (fileWatched , id) {
            vm.file = fileWatched;
            vm.fileName = fileWatched.name;

            // var key =  UidService.get()+ '.pdf';
            // var observable = qiniu.upload(
            //     vm.file,
            //     key,
            //     vm.token,
            //     {fname: vm.fileName, params: {}, mimeType: null},
            //     {useCdnDomain: true,region: qiniu.region.z0})
            // var observer = {
            //       next(res){
            //         // total.loaded: number，已上传大小，单位为字节。
            //         // total.total: number，本次上传的总量控制信息，单位为字节，注意这里的 total 跟文件大小并不一致。
            //         // total.percent: number，当前上传进度，范围：0～100。
            //         vm.progress = res.total.percent;
            //         $scope.$apply();
            //        console.log(res.total.percent);
            //        debugger;
            //       },
            //       error(err){
            //         console.log(err);
            //         MessageService.error("上传错误，请稍后再试")
            //        console.log(2);
            //       },
            //       complete(res){
            //         debugger;
            //         MessageService.success("上传结束")
            //       }
            //     }
            // var subscription  = observable.subscribe(observer)

            var formData = new FormData();
            formData.append('file', vm.file);
            formData.append('unique_names', false);
            formData.append('key', 'substitutions-' + UidService.get()+ '.pdf');
            formData.append('token', vm.token);
            vm.loading = true;
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    Upload.upload({
                        type: 'POST',
                        url: 'service/api/substitutions/upload/'+ id, //上传路径
                        data: {
                            file: fileWatched,
                            key: $scope.domain + data.key
                        }
                    }).then(function successCallback(res) {
                        vm.loading = false;
                        if(res.status && res.status == 200){
                            loadAll();
                            MessageService.success("操作成功");
                        }
                    }, function errorCallback(response) {
                        vm.loading = false;
                        MessageService.error("文件导入失败，请稍后重试");
                    });
                },
                error: function (request) {
                    vm.loading = false;
                    MessageService.error("文件上传失败");
                }
            });
        };
        window.uploadFile1 = function () {
            var _sum = document.getElementById("batchUpload").files.length;
            for(var i=0; i<document.getElementById("batchUpload").files.length; i++){
                vm.loading = true;
                var file = document.getElementById("batchUpload").files[i];
                (function (file, i) {
                    vm.file = file;
                    var _index = angular.copy(i);
                    _index++
                    vm.fileName = file.name.substring(0,file.name.indexOf('.'));

                    var formData = new FormData();
                    formData.append('file', vm.file);
                    formData.append('unique_names', false);
                    formData.append('key', 'substitutions-' + UidService.get()+ '.pdf');
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            Upload.upload({
                                type: 'POST',
                                url: 'service/api/substitutions/upload2', //上传路径
                                data: {
                                    file: file,
                                    key: $scope.domain + data.key,
                                    number:vm.fileName
                                }
                            }).then(function successCallback(res) {
                                vm.loading = false;
                                if(res.status && res.status == 200 && _index == _sum){
                                    loadAll();
                                    MessageService.success("操作成功");
                                }
                            }, function errorCallback(response) {
                                if(_index == _sum){
                                    vm.loading = false;
                                    MessageService.error("文件导入失败，请稍后重试");
                                }
                            });
                        },
                        error: function (request) {
                            vm.loading = false;
                            MessageService.error("文件上传失败");
                        }
                    });
                })(file, i)
            }
        };

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        function getItems() {
            var items=[{key:"assignTo", op:"=", value:vm.user.id}];
            if (vm.status !=null) {
                items.push({key:"status",op:"=",value:vm.status});
            }
            return items;
        }

        function loadAll () {
            var items = getItems();
            if (pagingParams.search) {
                items.push({key:"number,productName",op:"like",value:pagingParams.search});
            }
            Substitution.byCondition2({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return ["createdDate,desc"];
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.allPagesCount = Math.ceil(vm.totalItems/20);
            vm.queryCount = vm.totalItems;
            vm.substitutions = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("获取数据失败");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
               page: vm.page,
               sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
               search: vm.currentSearch,
               status:vm.status,
            });
        }

        $scope.downFile = function(number){
            window.open("fileserves/api/download/substitutionDownloadPic2?number="+number);
        };
        $q.all([vm.user.$promise, vm.domains.$promise, vm.tokens.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
           loadAll();
        })

        $scope.selectedAllCb = function () {
            vm.selectedAll = !vm.selectedAll;
            for(var i=0; i<vm.substitutions.length; i++){
                vm.substitutions[i].selected = vm.selectedAll;
            }
            substitutionsInit();
        }
        $scope.selectedOne = function(item){
            item.selected = !item.selected ? true : false;
            substitutionsInit();
        };
        function substitutionsInit(){
            vm.selectData = [];
            for(var i=0; i<vm.substitutions.length; i++){
                if(vm.substitutions[i].status == 'PROCESSING'){
                    if(vm.substitutions[i].selected){
                        vm.selectData.push(vm.substitutions[i]);
                    }
                }
            }
        }
        $scope.downloadAll = function () {
            if(vm.selectData.length == 0){
                MessageService.error("请选择有效的任务列表");
                return
            }
            var _number = [];
            for(var i=0; i<vm.selectData.length; i++){
                _number.push(vm.selectData[i].number)
            }
            _number = _number.toString();
            window.open("fileserves/api/download/substitutionDownloadPic3?numbers="+_number);
        }
    }
})();
