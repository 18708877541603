(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FontManagementController', FontManagementController);

    FontManagementController.$inject = ['$scope', '$state', 'FontManagement', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 
    '$localStorage'];

    function FontManagementController ($scope, $state, FontManagement, ParseLinks, paginationConstants, pagingParams, MessageService, $localStorage) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;

        loadAll();

        $scope.changeType = function(font){
            if (font == null) {
                return;
            }
            MessageService.confirm("确认要修改该数据的类型？", function(){
                var type = "";
                if (font.type = null || font.type == "" || font.type == "all") {
                    type = "wechat";
                }else{
                    type = "all";
                }
                FontManagement.updateType({id:font.id, type:type},function(data){
                    MessageService.success("类型修改成功");
                    loadAll();
                }, function(){
                        MessageService.error("类型修改失败，请稍后再试");
                })
            },function(){})
        }

        $scope.delete = function(id){
            MessageService.confirm("确认要删除该数据？",function(){
                FontManagement.delete({id: id},function () {
                    loadAll();
                });
            },function(){});
        };

        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            if (pagingParams.search) {
                FontManagement.byCondition({
                    items:[{key:"family",value:pagingParams.search, op:"like"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                FontManagement.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, 
                onSuccess, onError);

            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.fontManagements = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                console.log(2);
                MessageService.error("字体获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
    }
})();
