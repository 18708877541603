(function () {
    'use strict';
    angular
        .module('backApp')
        .factory('Qinius', Qinius)
        .factory('CategoryStore', CategoryStore)
        .factory('Category', Category);

    Category.$inject = ['$resource'];
    CategoryStore.$inject = ['$resource'];
    Qinius.$inject = ['$resource'];

    function Qinius($resource) {
        var resourceUrl = 'manager/' + 'api/qiniu/:path/:storeId';
        var service = $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'upload-token'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getDomain': {
                method: 'GET',
                params: {
                    path: 'bucket-domains1'
                }
            },
        });
        return service;
    }
    function CategoryStore($resource) {
        var resourceUrl = 'manager/'+'api/categories/store/mini/:path';
        var service = $resource(resourceUrl, {}, {
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            
        });
        return service;
    }

    function Category($resource) {
        var resourceUrl = 'manager/' + 'api/categories/:store/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "categoryStoreCreate": {
                method: "POST",
                params: {
                    path: "store"
                },
            },
            'storeByCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition',
                    store:"store"
                },
                isArray: true
            },
            "storeGetAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition",
                    store:"store"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }

})();
