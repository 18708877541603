(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('logo-setting', {
                parent: 'store',
                url: '/logo-setting',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"],
                    pageTitle: 'global.menu.entities.LOGOsetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logo-setting/logo-setting.html',
                        controller: 'LogoSettingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('store');
                        return $translate.refresh();
                    }],
                    entity: ['Product', '$stateParams', function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
            .state('logo-setting-edit', {
                parent: 'store',
                url: '/logo-setting-edit',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"],
                    pageTitle: 'global.menu.entities.LOGOsetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/logo-setting/logo-setting-edit.html',
                        controller: 'LogoSettingEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('store');
                        return $translate.refresh();
                    }],
                    entity: ['Product', '$stateParams', function(Product, $stateParams) {
                        return null;
                    }]
                }
            })
            .state('logo-setting-page', {
                parent: 'store',
                url: '/logo-setting/{type}',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/logo-setting/logo-setting-page.html',
                        controller: 'LogoSettingPageController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return $stateParams.type;
                            }
                        }
                    }).result.then(function() {
                        $state.go('logo-setting', null, { reload: true });
                    }, function() {
                        $state.go('logo-setting');
                    });
                }]
            });
    }

})();