(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ShareTheBac', ShareTheBac)
        .factory('ShareTheBac2', ShareTheBac2);

    ShareTheBac.$inject = ['$resource'];
    ShareTheBac2.$inject = ['$resource'];

    function ShareTheBac($resource) {
        var resourceUrl = 'manager/' + 'api/preview-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            'byStoreId': {
                method: 'GET',
                params: {
                    path: 'byStoreId'
                }
            },

            'byCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'byCondition'
                }
            },
            'putShareBgc': {
                method: 'put',
            }
        })
    }

    function ShareTheBac2($resource) {
        var resourceUrl = 'security/' + 'api/store-users/:path/:id';
        return $resource(resourceUrl, {}, {
            'account': {
                method: 'GET',
                params: {
                    path: 'account'
                }
            },
        })
    }
})();