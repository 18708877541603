(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RowArtificialDetailController', RowArtificialDetailController);

    RowArtificialDetailController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$q',
    'Product', 'MessageService', 'Store', 'DateUtils', 'entity', 'SubstitutionSettings','Principal'];

    function RowArtificialDetailController ($state, $timeout, $scope, $stateParams, $q,
        Product, MessageService, Store, DateUtils, entity, SubstitutionSettings,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.cancel = cancel;
        vm.save = save;
        vm.store = Store.getCurrent({});
        vm.isSaving = false;
        vm.productList = [];

        vm.products = Product.getAllByCondition({})

        $q.all([vm.store.$promise, vm.products.$promise  ]).then(function () {
            init();
        });
        function init() {
            SubstitutionSettings.getByStore({}, function (res) {
                vm.data = res;
            });
        }
        
         $scope.addLine = function () {
            if (vm.data.substitutionSettingLines == null) {
                vm.data.substitutionSettingLines = [];
            }
            var line = {
                added: true,
                deleted: false,
            };
            vm.data.substitutionSettingLines.push(line)
        }


        $scope.deleteLine = function (line) {
            if (line == null) {
                return;
            }
            if (!line.added) {
                line.deleted = true;
            } else {
                var ind = vm.data.substitutionSettingLines.indexOf(line);
                if (ind >= 0) {
                    vm.data.substitutionSettingLines.splice(ind, 1);
                }
            }
        };


        //保存
        function save() {
            SubstitutionSettings.update(vm.data, function (res) {
                $state.go('row-artificial');
                MessageService.success("保存成功");
            })
        }
        //返回
        function cancel() {
            $state.go('row-artificial')
        }

        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
    }
})();
