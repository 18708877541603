(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('PassOrder', PassOrder)
        .factory('PassUser', PassUser)
        .factory('Pass', Pass);

    PassUser.$inject = ['$resource'];
    Pass.$inject = ['$resource'];
    PassOrder.$inject = ['$resource'];

    function PassUser ($resource) {
        var resourceUrl =  'manager/' + 'api/pass-users/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
        });
    }

    function PassOrder ($resource) {
        var resourceUrl =  'service/' + 'api/pass-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
        });
    }

    function Pass ($resource) {
        var resourceUrl =  'service/' + 'api/passes/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
        });
    }
})();
