(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-ali-setting', {
            parent: 'entity',
            url: '/store-ali-setting',
            data: {
                pageTitle: 'backApp.storeAliSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-ali-setting/store-ali-setting-detail.html',
                    controller: 'StoreAliSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeAliSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreAliSetting', function($stateParams, StoreAliSetting) {
                    return StoreAliSetting.getOneByStoreId().$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'store-ali-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('store-ali-setting-auth', {
            parent: 'store-ali-setting',
            url: '/auth_notify?app_id&app_auth_code',
            data: {
                pageTitle: 'backApp.storeAliSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-ali-setting/store-ali-setting-detail.html',
                    controller: 'StoreAliSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeAliSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreAliSetting', function($stateParams, StoreAliSetting) {
                    return StoreAliSetting.auth({appAuthCode: $stateParams.app_auth_code}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'store-ali-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })


        .state('store-ali-setting-detail', {
            parent: 'store-ali-setting',
            url: '/store-ali-setting/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'backApp.storeAliSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-ali-setting/store-ali-setting-detail.html',
                    controller: 'StoreAliSettingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeAliSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreAliSetting', function($stateParams, StoreAliSetting) {
                    return StoreAliSetting.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'store-ali-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('store-ali-setting.edit', {
            parent: 'store-ali-setting',
            url: '/edit/{id}',
            data: {
                pageTitle: 'backApp.storeAliSetting.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-ali-setting/store-ali-setting-dialog.html',
                    controller: 'StoreAliSettingDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('storeAliSetting');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StoreAliSetting', function($stateParams, StoreAliSetting) {
                    return StoreAliSetting.getOneByStoreId().$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'store-ali-setting',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         ;
    }

})();
