
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('OrderSettingController', OrderSettingController);

    OrderSettingController.$inject = ['$q', '$scope', '$state','$stateParams', 'MessageService', 'OrderSetting'];

    function OrderSettingController($q, $scope, $state,$stateParams, MessageService, OrderSetting) {
        var vm = this;

        vm.enabledChange = enabledChange;
        vm.save = save;

        vm.enabled = true;
        vm.timeNum = 1;
        function enabledChange(num) {
            if (num == 1) {
                vm.enabled = true;
            } else {
                vm.enabled = false;
            }
        }

        vm.storeSrtting = OrderSetting.get({platformFlag: '0'});
        vm.platformSrtting = OrderSetting.get({platformFlag: '1'});
        $q.all([vm.storeSrtting.$promise, vm.platformSrtting.$promise]).then(function() {
            if (vm.storeSrtting.id != null) {
                vm.enabled = vm.storeSrtting.enabled;
                vm.timeNum = vm.storeSrtting.duration;
            } else {
                if (vm.platformSrtting.id != null) {
                    vm.timeNum = vm.platformSrtting.duration;
                }
            }
        });

        function save() {
            vm.storeSrtting.duration = vm.timeNum;
            vm.storeSrtting.enabled = vm.enabled;
            vm.storeSrtting.platformFlag = false;
            vm.storeSrtting.storeId = 0;

            OrderSetting.update(vm.storeSrtting, function(data){
                MessageService.success("操作成功");
            });
        }

    }
})();
