(function () {
    'use strict';
    angular
        .module('backApp')
        .factory('StoragePrices', StoragePrices)
        .factory('StoragePrices1', StoragePrices1)
        .factory('StoragePayOrders', StoragePayOrders)
        .factory('CustomerStorages', CustomerStorages)
        .factory('StoreStorages', StoreStorages)
        .factory('handlePackage', handlePackage)
        .factory('CustomerStorageSnapshots', CustomerStorageSnapshots)
        .factory('CustomerStoragePayOrders', CustomerStoragePayOrders)
        .factory('CustomerStorageCleanSchedules', CustomerStorageCleanSchedules)
        .factory('CustomerClearRecords', CustomerClearRecords)

    StoragePrices.$inject = ['$resource'];
    StoragePrices1.$inject = ['$resource'];
    StoragePayOrders.$inject = ['$resource'];
    CustomerStorages.$inject = ['$resource'];
    StoreStorages.$inject = ['$resource'];
    handlePackage.$inject = ['$resource'];
    CustomerStorageSnapshots.$inject = ['$resource'];
    CustomerStoragePayOrders.$inject = ['$resource'];
    CustomerStorageCleanSchedules.$inject = ['$resource'];
    CustomerClearRecords.$inject = ['$resource'];
    function handlePackage($resource) {
        var resourceUrl = 'manager/' + 'api/store-storage-prices/:path/:id';
        return $resource(resourceUrl, {}, {
            'CreatePackage': {
                method: "POST",
            },
            'updatePackage':{
                method: "POST",
            },
            'getAllByCondition1':{
                method: "POST",
                params: {
                    path: "getAllByCondition1"
                }
            },
            'deletePackage':{
                method: "DELETE",
            },
            'setDefault':{
                method: "GET",
                params: {
                    path: "setDefault"
                },
            }
        })
    }
    function StoragePrices($resource) {
        var resourceUrl = 'manager/' + 'api/storage-prices/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
           
            "byStoreId": {
                method: "GET",
                params: {
                    path: "byStoreId"
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            "byCondition2": {
                method: "POST",
                params: {
                    path: "byCondition2"
                },
                isArray: true
            },
            "byCondition3": {
                method: "POST",
                params: {
                    path: "byCondition3"
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "getAllByCondition2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                },
                isArray: true
            },
            "getAllByCondition3": {
                method: "POST",
                params: {
                    path: "getAllByCondition3"
                },
                isArray: true
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE"
            },
            "themeClassify": {
                method: "GET",
                params: {
                    path: "saveTheme"
                }
            },
            "batchCreate": {
                method: "GET",
                params: {
                    path: "batchCreate"
                }
            },
            "batchCreateByStore": {
                method: "GET",
                params: {
                    path: "batchCreateByStore"
                }
            },
            'update': { method: 'PUT' },
        });
    }
    function StoragePrices1($resource) {
        var resourceUrl = 'manager/' + 'api/storage-prices/:path';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byStoreId": {
                method: "GET",
                params: {
                    path: "byStoreId"
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            "byCondition2": {
                method: "POST",
                params: {
                    path: "byCondition2"
                },
                isArray: true
            },
            "byCondition3": {
                method: "POST",
                params: {
                    path: "byCondition3"
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "getAllByCondition2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                },
                isArray: true
            },
            "getAllByCondition3": {
                method: "POST",
                params: {
                    path: "getAllByCondition3"
                },
                isArray: true
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE"
            },
            "themeClassify": {
                method: "GET",
                params: {
                    path: "saveTheme"
                }
            },
            "batchCreate": {
                method: "GET",
                params: {
                    path: "batchCreate"
                }
            },
            "batchCreateByStore": {
                method: "GET",
                params: {
                    path: "batchCreateByStore"
                }
            },
            'update': { method: 'PUT' },
        });
    }
    function StoragePayOrders($resource) {
        var resourceUrl = 'manager/' + 'api/storage-pay-orders/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "wechatPay": {
                method: "GET",
                params: {
                    path: "wechatPay"
                }
            },
            "createOrder": {
                method: "POST",
                params: {
                    path: "createOrder"
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            "byCondition2": {
                method: "POST",
                params: {
                    path: "byCondition2"
                },
                isArray: true
            },
            "byCondition3": {
                method: "POST",
                params: {
                    path: "byCondition3"
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            "getAllByCondition2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                },
                isArray: true
            },
            "getAllByCondition3": {
                method: "POST",
                params: {
                    path: "getAllByCondition3"
                },
                isArray: true
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE"
            },
            "themeClassify": {
                method: "GET",
                params: {
                    path: "saveTheme"
                }
            },
            "batchCreate": {
                method: "GET",
                params: {
                    path: "batchCreate"
                }
            },
            "batchCreateByStore": {
                method: "GET",
                params: {
                    path: "batchCreateByStore"
                }
            },
            'update': { method: 'PUT' },
        });
    }
    function CustomerStorages($resource) {
        var resourceUrl = 'manager/' + 'api/customer-storages/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byStoreId": {
                method: "get",
                params: {
                    path: "byStoreId"
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            "byCondition2": {
                method: "POST",
                params: {
                    path: "byCondition2"
                },
                isArray: true
            },
            "byCondition3": {
                method: "POST",
                params: {
                    path: "byCondition3"
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "getAllByCondition2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                },
                isArray: true
            },
            "getAllByCondition3": {
                method: "POST",
                params: {
                    path: "getAllByCondition3"
                },
                isArray: true
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE"
            },
            "themeClassify": {
                method: "GET",
                params: {
                    path: "saveTheme"
                }
            },
            "batchCreate": {
                method: "GET",
                params: {
                    path: "batchCreate"
                }
            },
            "fixCustomerUsedSize": {
                method: "GET",
                params: {
                    path: "fixCustomerUsedSize"
                }
            },
            'update': { method: 'PUT' },
        });
    }
    function StoreStorages($resource) {
        var resourceUrl = 'manager/' + 'api/store-storages/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byStoreId": {
                method: "get",
                params: {
                    path: "byStoreId"
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            "byCondition2": {
                method: "POST",
                params: {
                    path: "byCondition2"
                },
                isArray: true
            },
            "byCondition3": {
                method: "POST",
                params: {
                    path: "byCondition3"
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "getAllByCondition2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                },
                isArray: true
            },
            "getAllByCondition3": {
                method: "POST",
                params: {
                    path: "getAllByCondition3"
                },
                isArray: true
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE"
            },
            "themeClassify": {
                method: "GET",
                params: {
                    path: "saveTheme"
                }
            },
            "batchCreate": {
                method: "GET",
                params: {
                    path: "batchCreate"
                }
            },
            "batchCreateByStore": {
                method: "GET",
                params: {
                    path: "batchCreateByStore"
                }
            },
            'update': { method: 'PUT' },
        })
    }
    function CustomerStorageSnapshots($resource) {
        var resourceUrl = 'manager/' + 'api/customer-storage-snapshots/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
            },
        });
    }
    function CustomerStoragePayOrders($resource) {
        var resourceUrl = 'manager/' + '/api/customer-storage-pay-orders/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
            },
        });
    }
    function CustomerStorageCleanSchedules($resource) {
        var resourceUrl = 'service/' + 'api/customer-storage-clean-schedules/:path/:id';
        return $resource(resourceUrl, {}, {
            'construct': {
                method: 'GET',
                params: {
                    path: 'construct'
                },
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
            },
            "calculating2":{
                method: 'Get',
                params: {
                    path: 'calculating2'
                },
            },
            "check":{
                method: 'Get',
                params: {
                    path: 'check'
                },
            },
            "delete": {
                method: "DELETE"
            },
            'create': {
                method: 'Get',
                params: {
                    path: 'create'
                },
            },

        });
    }
    function CustomerClearRecords($resource) {
        var resourceUrl = 'manager' + '/api/customer-clear-records/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'clear': {
                method: 'GET',
                params: {
                    path: 'clear'
                },
            },
        });
    }
})();
