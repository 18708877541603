(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('row-artificial', {
            parent: 'store',
            url: '/row-artificial',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/row-artificial/row-artificial.html',
                    controller: 'RowArtificialController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }]
            }
        })
        .state('row-artificial-detail', {
            parent: 'store',
            url: '/row-artificial-detail',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/row-artificial/row-artificial-detail.html',
                    controller: 'RowArtificialDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
