(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('groupCommodity', {
            parent: 'entity',
            url: '/groupCommodity?page&sort&search',
            data: {
                authorities: ["ROLE_STORE_MARKETINGMANAGEMENT"],
                pageTitle: 'global.menu.entities.group-commodity'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/group-commodity/group-commodity.html',
                    controller: 'GroupCommodityController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('groupCommodity.add', {
            parent: 'groupCommodity',
            url: '/add',
            data: {
                authorities: ["ROLE_STORE_MARKETINGMANAGEMENT"],
                pageTitle: 'global.menu.entities.group-commodity'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/group-commodity/group-commodity-dialog.html',
                    controller: 'GroupCommodityDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: [function() {
                    return 'add';
                }]
            }
        })
        .state('groupCommodity.edit', {
            parent: 'groupCommodity',
            url: '/edit/{id}',
            data: {
                authorities: ["ROLE_STORE_MARKETINGMANAGEMENT"],
                pageTitle: 'global.menu.entities.group-commodity'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/group-commodity/group-commodity-dialog.html',
                    controller: 'GroupCommodityDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: [function() {
                    return 'edit';
                }]
            }
        })
        ;
    }

})();
