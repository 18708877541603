(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customer-pay-order', {
            parent: 'entity',
            url: '/customer-pay-order?page&sort&search&startTime&endTime&type&paid',
            data: {
                authorities: ["ROLE_STORE_RECHARGE_SETTING"],
                pageTitle: 'global.menu.entities.payOrder'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/customerPayOrder/customer-pay-order.html',
                    controller: 'CustomerPayOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        startTime: $stateParams.startTime,
                        endTime: $stateParams.endTime,
                        type: $stateParams.type,
                        paid: $stateParams.paid
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payOrder');
                    $translatePartialLoader.addPart('settlementMethod');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('customer-pay-order.edit', {
            parent: 'pay-order-detail',
            url: '/detail/edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-order/pay-order-dialog.html',
                    controller: 'PayOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CustomerPayOrder', function(CustomerPayOrder) {
                            return CustomerPayOrder.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            .state('customer-pay-order.detail', {
                parent: 'customer-pay-order',
                url: '/{id}/detail',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.storeRecharge'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/customerPayOrder/customer-pay-order-detail.html',
                        controller: 'CustomerPayOrderDetailController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['CustomerPayOrder', '$stateParams', function(CustomerPayOrder, $stateParams) {
                        return CustomerPayOrder.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
         ;
    }

})();
