
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('EvaluateDetailController', EvaluateDetailController);

    EvaluateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Evaluate'];

    function EvaluateDetailController($scope, $rootScope, $stateParams, $uibModalInstance, entity, Evaluate) {
        var vm = this;

        vm.evaluate = entity;

        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();