(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StorageRackDialogController', StorageRackDialogController);

    StorageRackDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StorageRack', 'MessageService'];

    function StorageRackDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StorageRack, MessageService) {
        var vm = this;

        vm.storageRack = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if (vm.storageRack.code == null || vm.storageRack.code == "") {
                MessageService.error("请输入编码");
                return false;
            }
            return true;
        }

        function save () {
            if (!$scope.validate()) {
                return;
            }
            var items = [{key:"code", op:"=", value:vm.storageRack.code}];
            StorageRack.byCondition({items:items},function(data){
                if (data.length == 0 || data.length == 1) {
                    if (data.length == 0) {
                        vm.isSaving = true;
                        if (vm.storageRack.id !== null) {
                            StorageRack.update(vm.storageRack, onSaveSuccess, onSaveError);
                        } else {
                            StorageRack.save(vm.storageRack, onSaveSuccess, onSaveError);
                        }
                    }else{
                        if ((vm.storageRack.id !== null && data[0].id !== vm.storageRack.id) || vm.storageRack.id == null) {
                            MessageService.error("货架编码不能重复");
                        }else{
                           if (vm.storageRack.id !== null) {
                                StorageRack.update(vm.storageRack, onSaveSuccess, onSaveError);
                            } else {
                                StorageRack.save(vm.storageRack, onSaveSuccess, onSaveError);
                            } 
                        } 
                    }
                }else{
                    MessageService.error("货架编码不能重复");
                }
            },function(data){
                MessageService.error("请求数据失败")
            })

            
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:storageRackUpdate', result);
            MessageService.success("保存成功")
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("保存失败")
            vm.isSaving = false;
        }


    }
})();
