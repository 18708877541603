(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('NavigationSetting', NavigationSetting)
        .factory('StoreStyleSetting', StoreStyleSetting);

    NavigationSetting.$inject = ['$resource'];
    StoreStyleSetting.$inject = ['$resource'];

    function NavigationSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/navigation-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            "used":{
                method:"GET",
                params:{
                    path:"used"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function StoreStyleSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/store-style-settings/:id/:storeId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
