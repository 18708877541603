
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FilterOrderController', FilterOrderController);

    FilterOrderController.$inject = ['$scope','$state', 'MessageService','FilterPrices','$localStorage','$stateParams','Store', 'FilterOrderRecords', '$sce', '$http'];

    function FilterOrderController($scope,$state, MessageService,FilterPrices,$localStorage,$stateParams,Store, FilterOrderRecords, $sce, $http) {
        var vm = this;
        vm.filterType = $stateParams.type;
        vm.filterPriceRules = null;
        vm.prepare = false;
        vm.weChatCode = "";
        vm.orderData = null;
        vm.payMode= [
            {name: '余额支付', value: 'balance'},
            {name: '微信支付', value: 'wechat'},
            {name: '支付宝', value: 'alipay'},
        ];
        vm.pay = 'balance';

        vm.store = Store.getCurrent();
        FilterPrices.getByStore({storeId: $localStorage.user.storeId}, function (res) {
            if(res.status == 200){
                for(var i=0; i<res.message.filterPriceLines.length; i++){
                    if(res.message.filterPriceLines[i].filterType == vm.filterType){
                        vm.filterOrder = res.message.filterPriceLines[i];
                    }
                }
                for(var i=0; i<vm.filterOrder.filterPriceRules.length; i++){
                    vm.filterOrder.filterPriceRules[i].selected = false;
                    vm.filterOrder.filterPriceRules[i].quantity = 1;
                }
            }
        });
        $scope.filterSubtract = function(item){
            item.quantity--;
            if(item.quantity<1){
                item.quantity = 1;
            }
            imputedPrice();
        };
        $scope.filterAdd = function(item){
            item.quantity++;
            imputedPrice();
        };
        $scope.choose = function (item) {
            for(var i=0; i<vm.filterOrder.filterPriceRules.length; i++){
                if(vm.filterOrder.filterPriceRules[i].id != item.id){
                    vm.filterOrder.filterPriceRules[i].selected = false;
                }else {
                    vm.filterOrder.filterPriceRules[i].selected = !vm.filterOrder.filterPriceRules[i].selected;
                }
            }
            imputedPrice();
        };
        $scope.paySelect = function (item) {
            vm.pay = item.value
        }

        function imputedPrice(){
            vm.filterPriceRules = null;
            for(var i=0; i<vm.filterOrder.filterPriceRules.length; i++){
                if(vm.filterOrder.filterPriceRules[i].selected){
                    vm.filterPriceRules = vm.filterOrder.filterPriceRules[i];
                    break
                }
            }
            if(!vm.filterPriceRules){
                return
            }
            vm.filterPriceRules.totalPrices = vm.filterPriceRules.quantity * vm.filterPriceRules.price;
        }

        //确定
        $scope.save = function (){
            if(!vm.filterPriceRules){
                MessageService.error("请选择订购商品");
                return
            }
            if(vm.pay == 'balance'){
                if(vm.filterPriceRules.totalPrices > vm.store.balance){
                    MessageService.error("余额不足！");
                    return;
                }
            }
            FilterOrderRecords.save({
                filterPriceRuleId: vm.filterPriceRules.id,
                quantity: vm.filterPriceRules.quantity,
                payMethod: vm.pay
            }, function (res) {
                vm.orderData = res;
                if(vm.pay == 'balance'){
                    balance(res);
                }
                if(vm.pay == 'wechat'){
                    weChat(res);
                }
                if(vm.pay == 'alipay'){
                    aliPay(res);
                }
            })
        }

        function balance(item){
            FilterOrderRecords.payByBalance({
                orderId: item.id,
                amount: item.amount
            }, function (res) {
                FilterOrderRecords.get({
                    id: item.id
                }, function(val) {
                    if (!!val && val.paid) {
                        clearInterval($scope.timer);
                        MessageService.success("支付完成");
                        $state.go("filter");
                    }else {
                        MessageService.success("支付失败");
                    }
                }, function() {});
            })
        }
        function weChat(item){
            $http({
                url: '/manager/api/filter-order-records/wechatPay/'+item.id,
                method: 'POST',
                responseType: 'blob'
            }).success(function(res) {
                vm.prepare = true;
                var blob = new Blob([res], {
                    type: "image/jpeg"
                });
                vm.weChatCode = URL.createObjectURL(blob);
                //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                // $scope.timer = setInterval(function() {
                //     FilterOrderRecords.get({
                //         id: vm.orderData.id
                //     }, function(val) {
                //         if (!!val && val.paid) {
                //             clearInterval($scope.timer);
                //             MessageService.success("支付完成");
                //             $state.go("filter");
                //         }
                //     }, function() {});
                // }, 2000);
            }).error(function (error) {
                MessageService.error("生成支付信息失败");
            });
        }
        function aliPay(item){
            FilterOrderRecords.aliPay({
                id: item.id,
                returnUrl:window.location.href
            }, function (res) {
                if(!!res && res.status == 200){
                    $scope.trustHtml = $sce.trustAsHtml(res.message);
                }
            })
        }

        $scope.paymentSave = function(){
            FilterOrderRecords.get({
                id: vm.orderData.id
            }, function(val) {
                if (!!val && val.paid) {
                    clearInterval($scope.timer);
                    MessageService.success("支付完成");
                    $state.go("filter");
                }else {
                    MessageService.success("未支付");
                }
            }, function() {});
        }
        $scope.paymentHide = function(){
            FilterOrderRecords.get({
                id: vm.orderData.id
            }, function(val) {
                if (!!val && val.paid) {
                    clearInterval($scope.timer);
                    MessageService.success("支付完成");
                    $state.go("filter");
                }else {
                    vm.prepare = false;
                }
            }, function() {});
        }
        $scope.$on("$destroy", function() {
            if($scope.timer){
                clearInterval($scope.timer);
            }
        });




        $scope.filterChoose = function (item) {
            switch (item) {
                case 'selfie_anime':
                    return "人像动漫化";
                    break;
                case 'warm':
                    return "浪漫巴黎";
                    break;
                case 'wave':
                    return "金阳破浪";
                    break;
                case 'lavender':
                    return "紫藤奇缘";
                    break;
                case 'scream':
                    return "挪威森林";
                    break;
                case 'gothic':
                    return "情迷罗马";
                    break;
                default:
                    return "原画";
            }
        };
        $scope.filterType = function (item) {
            switch (item) {
                case 'PORTRAITCARTOON':
                    return "人像动漫化";
                    break;
                case 'IMAGESTYLECONVERT':
                    return "图像风格转换";
                    break;
                case 'SEGMENTATION':
                    return "人像抠图";
                    break;
                default:
                    return "";
            }
        };
    }
})();
