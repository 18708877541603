(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductUpdateCategoryController',ProductUpdateCategoryController);

    ProductUpdateCategoryController.$inject = ['$uibModalInstance', 'entity', 'Category', '$q', 'MessageService', '$scope'];

    function ProductUpdateCategoryController($uibModalInstance, entity, Category, $q, MessageService, $scope) {
        var vm = this;

        vm.ground = entity;
        vm.clear = clear;
        vm.save = save;
        vm.categoryId = null;

        vm.categories = Category.storeGetAllByCondition({items:[{key:"usable", op:"=", value:"true"}]})
        
        $q.all([vm.categories.$promise]).then(function(){
            
        })

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.selectedCategory = function(data){
            vm.categoryId = data.id;
        }

        function save() {
            vm.ground.categoryId = vm.categoryId
            $uibModalInstance.close(vm.ground);
        } 

 
    }
})();
