(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductPutawayController', ProductPutawayController);

    ProductPutawayController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$q', 'ProductPurchase','Principal',
    'Product', 'MessageService', 'Store', 'DateUtils', 'CommodityPrice', 'Category', 'Qinius', '$localStorage', 'ParseLinks', '$uibModal'];

    function ProductPutawayController ($state, $timeout, $scope, $stateParams, $q, ProductPurchase,Principal,
        Product, MessageService, Store, DateUtils, CommodityPrice, Category, Qinius, $localStorage, ParseLinks, $uibModal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_PLATFORMCHOOSE').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });

        vm.classifyList = [{name: '全部', id: ''}];
        vm.classify = {name: '全部', id: ''};
        vm.products = [];
        vm.itemValueLines = [];
        vm.item = [];
        vm.domains = Qinius.getDomain();
        vm.page = 1;
        vm.search = search;

        $q.all([vm.domains.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            //获取分类
            Category.getAllByCondition({
                items:[{key: "usable", op: "=", value: true}],
                sort: ['seq,asc'],
            }, function (res) {
                for(var i=0; i<res.length; i++){
                    if(!res[i].cancelled){
                        vm.classifyList.push(res[i]);
                    }
                }
                vm.classifyList = vm.classifyList.sort(sortList("seq"));
                productInit()
            })
        });
        $scope.classifyCb = function (data) {
            vm.classify = data;
            vm.page = 1;
            productInit()
        }

        //获取商品
        function productInit(){
            vm.products = [];
            var items = [{key: "prototype", op: "=", value: 'Original'},
                {key: "saleable", op: "=", value: "true"},
                {key: "custom", op: "=", value: "false"}];
            if(vm.classify.id){
                items.push({key:"category.id",op:"=",value: vm.classify.id})
            }
            if(vm.keyword){
                items.push({key:"name",op:"like",value: vm.keyword})
            }
            Product.getAllByCondition2({
                items: items,
                sort: ['seq,asc'],
            },function(data){
                for(var i=0; i<data.length; i++){
                    if(!data[i].cancelled){
                        vm.products.push(data[i])
                    }
                }
                Store.getCurrent({}, function (res) {
                    var _products = [];
                    for(var i1=0; i1<res.products.length; i1++){
                        if(!res.products[i1].cancelled){
                            if((res.products[i1].categoryId == vm.classify.id || !vm.classify.id)  && res.products[i1].saleable){
                                _products.push(res.products[i1])
                            }
                        }
                    }

                    for(var i=0; i<_products.length; i++){
                        var _isResult = vm.products.some(function (item) {
                            if(item.id == _products[i].id){
                                return true
                            }
                        })
                        if(!_isResult){
                            vm.products.push(_products[i])
                        }
                    }
                    Product.grounded({storeId: res.id}, function (items) {
                        for(var i2=0; i2<items.length; i2++){
                            for(var i1=vm.products.length-1; i1>=0; i1--){
                                if(vm.products[i1].id == items[i2].id){
                                    vm.products[i1].grounded = true;
                                }
                            }
                        }

                        for(var i=0; i<vm.products.length; i++){
                            for(var j=0; j<vm.item.length; j++){
                                if(vm.item[j].id == vm.products[i].id){
                                    vm.products[i].selected = true;
                                }
                            }
                        }
                        var nums = 20; //每页出现的数量
                        var pages = Math.ceil(vm.products.length/nums); //得到总页数
                        var thisDate = function(curr){
                            //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                            var str = [], last = curr*nums - 1;
                            last = last >= vm.products.length ? (vm.products.length-1) : last;
                            for(var i = (curr*nums - nums); i <= last; i++){
                                str.push(vm.products[i]);
                            }
                            return str;
                        };

                        //调用分页
                        laypage({
                            cont: 'c-paging',
                            curr: vm.page, //初始化当前页
                            pages: pages,
                            skip: true, //是否开启跳页
                            first: false,
                            last: false,
                            jump: function(obj){
                                $timeout(function () {
                                    vm.products1=thisDate(obj.curr);
                                    vm.products1 = vm.products1.sort(sortList("seq"));
                                    vm.page = obj.curr;
                                })
                            }
                        });
                    })
                });
            })
        }
        function search() {
            vm.page = 1;
            productInit()
        }
        //获取选中商品
        $scope.itemValueLinesCb = function (data) {
            if (data.grounded) {
                return;
            }
            data.selected = !data.selected ? true : false;
            if(!data.selected){
                for(var i=0; i<vm.item.length; i++){
                    if(vm.item[i].id == data.id){
                        vm.item.splice(i, 1);
                    }
                }
            }else {
                vm.item.push(data)
            }
        }

        //返回
        $scope.skip = function () {
            $state.go('product')
        }

        var createModal = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:item,
                }
            });
        };


        //保存
        $scope.save = function () {
            if(vm.item.length == 0){
                MessageService.error("请选择需要的商品！");
                return
            }
            var _ids = [];
            for(var i=0; i<vm.item.length; i++){
                _ids.push(vm.item[i].id)
            }
            var ground = {
                ids:_ids
            }
            createModal('app/entities/product/product-update-category.html', 'ProductUpdateCategoryController', ground).result.then(
            function (data) {
                if (!!data.categoryId) {
                    Product.grounding({ids: data.ids, categoryId: data.categoryId}, function (res) {
                        $state.go('product')
                    })

                }else{
                    Product.grounding({ids: data.ids}, function (res) {
                        $state.go('product')
                    })
                }
            });
        }
    }
})();
