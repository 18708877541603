(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SharePageConfigController', SharePageConfigController);

    SharePageConfigController.$inject = ['$scope', '$uibModal', '$timeout', 'UidService', 'SharePageConfig', '$q', '$rootScope',
    '$stateParams', 'MessageService', 'Store', 'Qinius', 'Principal', '$state', 'entity'];

    function SharePageConfigController($scope, $uibModal, $timeout, UidService, SharePageConfig, $q, $rootScope,
        $stateParams, MessageService, Store, Qinius, Principal, $state, entity) {
        var vm = this;
        vm.configsEntity = entity;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        $q.all([  vm.tokens.$promise, vm.domains.$promise, vm.configsEntity.$promise]).then(function() {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            vm.configs = vm.configsEntity.message;
            if (vm.configs == null) {
                vm.configs = [];
            }
            var home = false;
            var exchange = false;
            var productDetail = false;
            var orderShare = false;
            var invite = false;
            var payCode = false;
            var giftVoucher = false;
            var shoppingGuideShare = false;
            var galleryShare = false;
            var operationManualsShare = false;
            var groupBuyShare = false;

            for (var i = vm.configs.length - 1; i >= 0; i--) {
                var config = vm.configs[i];
                config.uuid = UidService.get();
                if (config.page == "home") {
                    home = true;
                    continue;
                }
                if (config.page == "orderShare") {
                    orderShare = true;
                    continue;
                }
                if (config.page == "productDetail") {
                    productDetail = true;
                    continue;
                }
                if (config.page == "shoppingGuideShare") {
                    shoppingGuideShare = true;
                    continue;
                }
                if (config.page == "invite") {
                    invite = true;
                    continue;
                }
                if (config.page == "payCode") {
                    payCode = true;
                    continue;
                }
                if (config.page == "giftVoucher") {
                    giftVoucher = true;
                    continue;
                }
                if (config.page == "galleryShare") {
                    galleryShare = true;
                    continue;
                }
                if (config.page == "operationManualsShare") {
                    operationManualsShare = true;
                    continue;
                }
                if(config.page == "groupBuyShare"){
                    groupBuyShare = true;
                    continue;
                }
            }
            if (!home) {
                vm.configs.push({page:"home", title:"", img:"", uuid :UidService.get()});
            }
            if (!orderShare) {
                vm.configs.push({page:"orderShare", title:"", img:"", uuid :UidService.get()});
            }
            if (!productDetail) {
                vm.configs.push({page:"productDetail", title:"", img:"", uuid :UidService.get()});
            }
            if (!shoppingGuideShare) {
                vm.configs.push({page:"shoppingGuideShare", title:"", img:"", uuid :UidService.get()});
            }
            if (!invite) {
                vm.configs.push({page:"invite", title:"", img:"", uuid :UidService.get()});
            }
            if (!payCode) {
                vm.configs.push({page:"payCode", title:"", img:"", uuid :UidService.get()});
            }
            if (!giftVoucher) {
                vm.configs.push({page:"giftVoucher", title:"", img:"", uuid :UidService.get()});
            }
            if (!galleryShare) {
                vm.configs.push({page:"galleryShare", title:"", img:"", uuid :UidService.get()});
            }
            if (!operationManualsShare) {
                vm.configs.push({page:"operationManualsShare", title:"", img:"", uuid:UidService.get()});
            }
            if (!groupBuyShare) {
                vm.configs.push({page:"groupBuyShare", title:"", img:"", uuid:UidService.get()});
            }

        });

        $scope.updateTitle = function (config) {
            createModal2('app/entities/share-page-config/share-page-title.html', 'SharePageTitleController', config).result.then(
                function (data) {
                    config = data
                });
        }

        var createModal2 = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                controllerAs: 'vm',
                // backdrop: 'static',
                size: 'lg',
                keyboard: true,
                // windowClass: "jh-modal-lg",
                // controllerAs: 'vm',
                resolve: {
                    entity: item,
                }
            });
        };

        $scope.removeFile = function(config){
            if (config == null) {
                return;
            }
            config.img = null;
        }

        window.upImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "share-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.configs.length - 1; i >= 0; i--) {
                            var item = vm.configs[i];
                            if (item.cancelled) {
                                continue;
                            }
                            if (item.uuid == uuid) {
                                item.img = $scope.domain + data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.getPage = function(target) {
            if (target == null) {
                return;
            }
            switch (target) {
                case "home":
                    return "首页";
                case "shoppingGuideShare":
                    return "导购邀请";
                case "productDetail":
                    return "商品详情";
                case "orderShare":
                    return "下单分享";
                case "invite":
                    return "邀请好友";
                case "payCode":
                    return "兑换页-支付码转赠";
                case "giftVoucher":
                    return "兑换页-礼物券转赠";
                case "galleryShare":
                    return "图库分享";
                case "operationManualsShare":
                    return "操作手册分享";
                case "groupBuyShare":
                    return "拼团分享";
                default:
                    return "";
            }
        }


        // 保存数据
        $scope.save = function(config,index) {
            SharePageConfig.save(config, function(res){
                if (res.status == 200) {
                    config = res.message;
                    config.edit = false;
                    vm.configs[index] = config;
                    MessageService.success('保存成功')
                } else {
                    MessageService.error(res.message == null ? '保存失败' : res.message);
                }
            })
        }
    }
})();
