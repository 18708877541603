(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ClauseModalController', ClauseModalController);

    ClauseModalController.$inject = ['$uibModalInstance', 'PlatformWechatSetting', '$q', '$sce'];

    function ClauseModalController ($uibModalInstance, PlatformWechatSetting, $q, $sce) {
        var vm = this;
        vm.cancel = cancel;
        vm.useTerms = "";
        vm.platformSetting = PlatformWechatSetting.get();
        $q.all([vm.platformSetting.$promise]).then(function(){
            vm.useTerms = $sce.trustAsHtml(vm.platformSetting.useTerms);
        })
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
