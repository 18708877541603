(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StoreWechatSetting', StoreWechatSetting)
        .factory("StoreSmallAppWechatSetting", StoreSmallAppWechatSetting)
        .factory('PlatformWechatSetting', PlatformWechatSetting)
        .factory('WechatAuth', WechatAuth)
        .factory('StoreByteDances', StoreByteDances)
        .factory('StoreJdSettings', StoreJdSettings);


    StoreWechatSetting.$inject = ['$resource', 'DateUtils'];

    StoreSmallAppWechatSetting.$inject = ['$resource'];

    PlatformWechatSetting.$inject = ['$resource'];

    WechatAuth.$inject = ['$resource'];
    StoreByteDances.$inject = ['$resource'];
    StoreJdSettings.$inject = ['$resource'];

    function StoreJdSettings ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-jd-settings/:path';

        return $resource(resourceUrl, {}, {
            "auth":{
                method:"GET",
                params:{
                    path:"auth"
                }
             },
            "auth_notify":{
                method:"GET",
                params:{
                    path:"auth_notify"
                }
             },
            'get': { method:'GET' },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            "getOne":{
                method:"GET",
                params:{
                    path:"getOne"
                }
             },
             "storeGetOne":{
                method:"GET",
                params:{
                    path:"storeGetOne"
                }
             }
        });
    }
    function StoreByteDances ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-byte-dances/:path';

        return $resource(resourceUrl, {}, {
            "auth_notify":{
                method:"GET",
                params:{
                    path:"auth_notify"
                }
             },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            "getOne":{
                method:"GET",
                params:{
                    path:"getOne"
                }
             },
             "storeGetOne":{
                method:"GET",
                params:{
                    path:"storeGetOne"
                }
             }
        });
    }
    function WechatAuth ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'wx/:path';

        return $resource(resourceUrl, {}, {
            "auth_notification":{
                method:"POST",
                params:{
                    path:"auth_notification"
                }
            },
            "pre_auth_code":{
                method:"POST",
                params:{
                    path:"pre_auth_code"
                }
            },
            "auth_notify":{
                method:"GET",
                params:{
                    path:"auth_notify"
                }
             },
             "auth_notify2":{
                method:"GET",
                params:{
                    path:"auth_notify2"
                }
             },
             "getSmallLink":{
                method:"GET",
                params:{
                    path:"getSmallLink"
                }
             }
        });
    }

    function PlatformWechatSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/platform-wechat-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                params:{
                    path:"byStore"
                }

            },
            'byPlatFrom': {
                method: 'GET',
                params:{
                    path:"byPlatFrom"
                }

            },
            'update': { method:'PUT' }
        });
    }


    function StoreSmallAppWechatSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-small-app-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expiresTime = DateUtils.convertDateTimeFromServer(data.expiresTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            "getEntityByStoreId":{
                method:"GET",
                params:{
                    path:"getEntityByStoreId"
                }
            }
        });
    }

    function StoreWechatSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-wechat-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "getByStoreId":{
                method:"GET",
                params:{
                    path:"getEntityByStoreId"
                }
            },
            "getPrivateWechatSetting":{
                method:"GET",
                params:{
                    path:"getPrivateWechatSetting"
                }
            },
            'update': { method:'PUT' },
            'createMenu':{
                method:'POST',
                params:{
                    path:"createMenu"
                }
            },
            'getMenu': {
                method: 'GET',
                params: {
                    path: "getMenu"
                }
            },
            "getPublicWechatSetting":{
                method:"GET",
                params:{
                    path:"getPublicWechatSetting"
                }
            }
        });
    }


})();
