(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupManagementDetailController', GroupManagementDetailController);

    GroupManagementDetailController.$inject = ['$timeout', '$scope', '$localStorage', '$uibModalInstance', 'entity', 'PayBatch', 'Store', 'Product', 'MessageService', '$q',
    'CustomerGroupBuy', 'ProductPurchase', 'ItemValueLinePackage', '$stateParams', 'ListService'];

    function GroupManagementDetailController ($timeout, $scope, $localStorage, $uibModalInstance, entity, PayBatch, Store, Product, MessageService, $q,
                                         CustomerGroupBuy, ProductPurchase, ItemValueLinePackage, $stateParams, ListService) {
        var vm = this;
        vm.isLeader = $stateParams.isLeader;
        vm.customerGroupBuy = entity;
        vm.clear = clear;
        vm.store = Store.getCurrent();
        // vm.customerGroupBuy = CustomerGroupBuy.get({id : entity.customerGroupBuyId});

        $q.all([vm.store.$promise]).then(function(){
            console.log(vm.customerGroupBuy)


        })
        function clear () {
            $uibModalInstance.close();
        }
    }
})();
