(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountVersionsController', OpenAccountVersionsController);

    OpenAccountVersionsController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', '$q', 'Store', 'MessageService'];

    function OpenAccountVersionsController($state, $scope, $timeout, Qinius, $uibModal, $q, Store, MessageService) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.tencent=false;
        vm.program=false;
        vm.pc=false;
        vm.store = Store.getCurrent();
        $q.all([vm.store.$promise]).then(function() {
            if(vm.store.activated){
                $state.go('openAccount-commercial');
            }
            if(vm.store.storeType2){
                vm.pc = vm.store.storeType2.indexOf('WEB') > -1 ? true : false;
                vm.program = vm.store.storeType2.indexOf('MINI') > -1 ? true : false;
                vm.tencent = vm.store.storeType2.indexOf('WECHAT') > -1 ? true : false;
                vm.bytedance = vm.store.storeType2.indexOf('BYTEDANCE') > -1 ? true : false;
                vm.ali = vm.store.storeType2.indexOf('ALI') > -1 ? true : false;
            }
        });

        $scope.save = function () {
            var storeType = "";
            if(!vm.tencent && !vm.program && !vm.pc){
                MessageService.error("请至少选择一种");
                return
            }
            if(vm.pc){
                storeType += 'WEB;'
            }
            if(vm.program){
                storeType += 'MINI;'
            }
            if(vm.tencent){
                storeType += 'WECHAT;'
            }
            if(vm.bytedance){
                storeType += 'BYTEDANCE;'
            }
            if(vm.ali){
                storeType += 'ALI;'
            }
            vm.store.storeType2 = storeType;
            Store.update(vm.store, function () {
                $state.go('openAccount-package')
            },function (error) {
                MessageService.error("设置失败，请稍后重试！");
            })
        }
    }

})();
