(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-title', {
            parent: 'entity',
            url: '/product-title?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TITLE'],
                pageTitle: 'backApp.productTitle.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-title/product-titles.html',
                    controller: 'ProductTitleController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productTitle');
                    $translatePartialLoader.addPart('styleType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-title-detail', {
            parent: 'product-title',
            url: '/{id}/detail',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TITLE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-title/product-title-detail.html',
                    controller: 'ProductTitleDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProductTitle', function(ProductTitle) {
                            return ProductTitle.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-title', null, { reload: 'product-title' });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('product-title.new', {
            parent: 'product-title',
            url: '/new',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TITLE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-title/product-title-dialog.html',
                    controller: 'ProductTitleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                sort: null,
                                title: null,
                                style: null,
                                show: null,
                                cancelled: null,
                                use: null,
                                hits: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('product-title', null, { reload: 'product-title' });
                }, function() {
                    $state.go('product-title');
                });
            }]
        })
        .state('product-title.edit', {
            parent: 'product-title',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_PRODUCT_TITLE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-title/product-title-dialog.html',
                    controller: 'ProductTitleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProductTitle', function(ProductTitle) {
                            return ProductTitle.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-title', null, { reload: 'product-title' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
