(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayBatchChangeApplicationDetailController', PayBatchChangeApplicationDetailController);

    PayBatchChangeApplicationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PayBatchChangeApplication'];

    function PayBatchChangeApplicationDetailController($scope, $rootScope, $stateParams, previousState, entity, PayBatchChangeApplication) {
        var vm = this;

        vm.payBatchChangeApplication = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('backApp:payBatchChangeApplicationUpdate', function(event, result) {
            vm.payBatchChangeApplication = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
