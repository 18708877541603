(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductXmlBatchEditController', ProductXmlBatchEditController);
    ProductXmlBatchEditController.$inject = ['$scope', 'Category', 'UidService', '$uibModalInstance', 'MessageService', '$stateParams', 'ProductXmls',
        'ThemeClassify', 'Store', 'entity',   '$q', '$uibModal', '$localStorage'];

    function ProductXmlBatchEditController($scope, Category, UidService, $uibModalInstance, MessageService, $stateParams, ProductXmls,
                                         ThemeClassify, Store, entity,  $q, $uibModal, $localStorage) {
        //编辑第三方的模板 弹出窗口

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.ids = entity;
        
        $q.all([ ]).then(function () {
        });
        ThemeClassify.getAllByCondition3({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'XML'},{key: "storeId", op: "=", value: $localStorage.user.storeId}]},function (res) {
            if(res){
                vm.themeClassifyList = res;
            } 
        });

        Category.storeGetAllByCondition({items: [{key: "usable", op: "=", value: "true"}]},function (res) {
            if(res){
                vm.categories = res;
            } 
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function () {
            if (vm.categoryId == null || vm.categoryId == "") {
                MessageService.error("请选择分类");
                return false;
            }
            if (vm.themeId == null || vm.themeId == "") {
                MessageService.error("请选择模板分类");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            ProductXmls.batchEdit({ids:vm.ids, categoryId:vm.categoryId, themeId:vm.themeId, 
                type:"Currency" } , function (res) {
                if (res) {
                    MessageService.success("保存成功");
                    $uibModalInstance.close(res);
                }  
            }, function () {
                MessageService.error("保存失败");
            });
        }

        

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
 
    }

})();
