(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('MHistory', MHistory)
        .factory('Guide', Guide);

    Guide.$inject = ['$resource'];
    MHistory.$inject = ['$resource'];

     function MHistory ($resource) {
        var resourceUrl =  'manager/' + 'api/histories/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'byCondition'
                }
            },
            'getAllByCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'getAllByCondition'
                }
            },
        })
    }

    function Guide ($resource) {
        var resourceUrl =  'manager/' + 'api/guides/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPriceByProductAndStore': {
                method: 'GET',
                params: {
                    path: 'getPriceByProductAndStore'
                }
            },
            'updateAmount': {
                method: 'GET',
                params: {
                    path: 'updateAmount'
                }
            },

            
            'byCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'byCondition'
                }
            },
            'getAllByCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'getAllByCondition'
                }
            },
            'approve': {
                method: 'GET',
                params: {
                    path: 'approve'
                }
            },
            'reject': {
                method: 'GET',
                params: {
                    path: 'reject'
                }
            },
            'exchangeCustomer': {
                method: 'GET',
                params: {
                    path: 'exchangeCustomer'
                }
            },
            'update': { method:'PUT' }
        })
    }
})();
