(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ConsumptionController', ConsumptionController);

    ConsumptionController.$inject = ['$state', 'Consumption', 'ParseLinks',  'paginationConstants', 'pagingParams', 'MessageService', '$scope', '$http', 'DateUtils','Principal'];

    function ConsumptionController($state, Consumption, ParseLinks,   paginationConstants, pagingParams, MessageService, $scope, $http, DateUtils,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_RECHARGE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.transition = transition;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.type = type;
        vm.openCalendar = openCalendar;
        vm.search = search;
        vm.clear = clear;
        vm.dateformat = dateformat;

        vm.datePickerOpenStatus = {
            startTime: false,
            endTime: false
        };

        function dateformat () {
            return 'yyyy-MM-dd';
        }
        vm.mannerList = [
            {name: '销售订单',id:'SALESORDER'},
            {name: '支付凭证',id:'PAYORDER'},
            {name: '商品订购',id:'PRODUCTPURCHASE'},
            {name: '订单流量',id:'SALESORDER2'},
            {name: '快递打单',id:'ORDEREXPRESS'},
            {name: '短信扣费',id:'TPLMESSAGE'},
            {name: '图片存储',id:'STORAGE'},
            {name: '运费扣除',id:'DELIVERY'},
        ];
        vm.data = {
            startTime: '',
            endTime: '',
            classType: '',
            searchQuery: ''
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        loadAll();
        function loadAll () {
            var items = []; 

            if(vm.data.startTime){
                items.push({key:"classDate",op:">=",value:new Date(DateUtils.convertLocalDateToServer(vm.data.startTime) -8 * 60 * 60 * 1000)})
            }
            if(vm.data.endTime){
                items.push({key:"classDate",op:"<=",value:new Date(Date.parse(DateUtils.convertLocalDateToServer(vm.data.endTime)) + 16 * 60 * 60 * 1000 - 1000)});
            }
            if(vm.data.classType){
                items.push({key:"classType",op:"=",value:vm.data.classType})
            }
            if(vm.data.searchQuery){
                items.push({key:"classNumber",op:"like",value:vm.data.searchQuery})
            }
            Consumption.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.consumption = data;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search (searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            // vm.currentSearch = searchQuery;
            // vm.transition();
            loadAll()
        }

        function clear() {
            vm.data = {
                startTime: '',
                endTime: '',
                classType: '',
                searchQuery: ''
            }
            loadAll()
        }

        function type(target) {
            switch (target) {
                case "SALESORDER":
                    return "销售订单";
                case "PAYORDER":
                    return "支付凭证";
                case "PRODUCTPURCHASE":
                    return "商品订购";
                case "SALESORDER2":
                    return "订单流量";
                case "ORDEREXPRESS":
                    return "快递打单";
                case "TPLMESSAGE":
                    return "短信扣费";
                case "STORAGE":
                    return "图片存储";
                case "DELIVERY":
                    return "运费扣除";
                default:
                    return "";
            }
        }

        $scope.download = function () {
            if(!vm.consumption.length){
                MessageService.error("暂无消费明细");
                return
            }
            var items=[]
            if(vm.data.startTime){
                items.push({key:"classDate",op:">=",value:new Date(DateUtils.convertLocalDateToServer(vm.data.startTime) -8 * 60 * 60 * 1000)})
            }
            if(vm.data.endTime){
                items.push({key:"classDate",op:"<=",value:new Date(Date.parse(DateUtils.convertLocalDateToServer(vm.data.endTime)) + 16 * 60 * 60 * 1000 - 1000)});
            }
            if(vm.data.classType){
                items.push({key:"classType",op:"=",value:vm.data.classType})
            }
            // if(vm.data.searchQuery){
            //     items.push({key:"classNumber",op:"like",value:vm.data.searchQuery})
            // }
            $http({
                url: 'manager/api/consumption-lists/exportData',
                method: "POST",
                data: {
                    items: items
                },
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "消费明细" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);

            }).error(function (data, status, headers, config) {
            });
        }
    }
})();
