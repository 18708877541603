(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductTitleDetailController', ProductTitleDetailController);

    ProductTitleDetailController.$inject =  ['$timeout', '$scope', '$uibModal', '$uibModalInstance', 'entity', 'ProductTitle', '$q', 'UidService', 'MessageService',
    'Qinius', "Product", 'ProductTitleStyle', "Category", "ListService"];

    function ProductTitleDetailController ($timeout, $scope, $uibModal, $uibModalInstance, entity, ProductTitle, $q, UidService, MessageService,
        Qinius, Product, ProductTitleStyle , Category, ListService) {
        var vm = this;

        vm.choose = true;
        vm.productTitle = entity;
        vm.classifyList = [{name:'全部', id: ''}];
        vm.classifyData = {name:'全部', id: ''};
        vm.layoutList = [
            {name:'2*2',value:'TwoByTwo'},
            {name:'2*3',value:'TwoByThree'}
        ];
        vm.keyword = "";
        vm.clear = clear;
        vm.save = save;
        vm.parentCategoryId = null;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.productsAllB = ProductTitleStyle.getProducts({ items: [{key: "saleable", op: "=", value: "true"}, {key: "prototype", op: "in", value: "Store;StoreSelf"}]});
        vm.categories = Category.storeGetAllByCondition({items:[{key:"usable", value:"true", op:"="},{key:"haveSuperior", value:"false", op:"="}]});
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.categories.$promise,  vm.productTitle.$promse, vm.productsAllB.$promise]).then(function(){
            vm.productsAll = angular.copy(vm.productsAllB);
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if (vm.productTitle.parentCategoryId) {
                vm.parentCategoryId = vm.productTitle.parentCategoryId;
            }

            if (vm.productTitle.id && vm.productTitle.style == 'STYLEFOURTH'
                && !!vm.productTitle.productStyleCategories && vm.productTitle.productStyleCategories.length>0) {
                angular.forEach(vm.productTitle.productStyleCategories, function(productStyleCategory){
                    var items=[
                        {key: "saleable", op: "=", value: "true"},
                        {key: "prototype", op: "in", value: "Store;StoreSelf"},
                        {key: "categoryStore.id", op: "=", value: productStyleCategory.categoryId}
                    ]
                    if (productStyleCategory.productTitleStyles) {
                        angular.forEach(productStyleCategory.productTitleStyles, function(st){
                            st.uuid = UidService.get();
                        })
                    }
                    ProductTitleStyle.getProducts({
                        items: items,
                        sort: ["seq,asc"]
                    }, function (res) {
                        productStyleCategory.products = res;
                    });
                })
            }

            if (vm.productTitle.productTitleStyles) {
                for (var i = vm.productTitle.productTitleStyles.length - 1; i >= 0; i--) {
                    var s = vm.productTitle.productTitleStyles[i];
                    s.uuid = UidService.get();
                }
            }

            for (var i = 0; i < vm.productsAll.length; i++) {
                var p = vm.productsAll[i];
                var arr = vm.classifyList.find(function(value, index, arr) {
                    return value.id == p.categoryId;
                })
                if(!arr){
                    vm.classifyList.push({
                        id:p.categoryId,
                        name:p.categoryName
                    })
                }
            }
        })

        $scope.parentCategoryChange= function(){
            if (!!vm.productTitle.productStyleCategories) {
                for (var i = vm.productTitle.productStyleCategories.length - 1; i >= 0; i--) {
                    var cate = vm.productTitle.productStyleCategories[i];
                    if (!!cate.id) {
                        cate.cancelled = true;
                    }else{
                        var inde1 =  vm.productTitle.productStyleCategories.indexOf(cate);
                        if (inde1>=0) {
                            vm.productTitle.productStyleCategories.splice(inde1,1);
                        }
                    }
                }
            }else{
                vm.productTitle.productStyleCategories = [];
            }

            if (vm.productTitle.parentCategoryId == null) {
                return;
            }

            Category.storeGetAllByCondition({items:[
                {key:"usable", value:"true", op:"="},
                {key:"haveSuperior", value:"true", op:"="},
                {key:"category.id", value:vm.productTitle.parentCategoryId, op:"="}]}, function(data){
                    vm.subCategories = data;
                    if (vm.subCategories!= null && vm.subCategories.length>0) {
                        for (var i = vm.subCategories.length - 1; i >= 0; i--) {
                            var c = vm.subCategories[i];
                            var productStyleCategory = {
                                categoryId:c.id,
                                categoryName:c.name,
                                cancelled: false,
                                productTitleStyles:[]
                            }
                            vm.productTitle.productStyleCategories.push(productStyleCategory);
                        }
                    }
                });
        }

        $scope.showAdd = function(ca){
            var count = 0;
            if (ca.productTitleStyles) {
                for (var i = ca.productTitleStyles.length - 1; i >= 0; i--) {
                    var style = ca.productTitleStyles[i];
                    if (!style.cancelled) {
                        count++;
                    }
                }
            }
            return count<4;
        }

        $scope.deleteStyle = function(style){
            if (!style.added) {
                style.cancelled = true;
                style.deleted = true;
            } else {
                if (vm.productTitle.style == 'STYLEFOURTH' ) {
                    angular.forEach(vm.productTitle.productStyleCategories, function(ca){
                        if (ca.productTitleStyles) {
                            var ind = ca.productTitleStyles.indexOf(style);
                            if (ind >= 0) {
                                ca.productTitleStyles.splice(ind, 1);
                            }
                        }
                    })
                }else{
                    var ind = vm.productTitle.productTitleStyles.indexOf(style);
                    if (ind >= 0) {
                        vm.productTitle.productTitleStyles.splice(ind, 1);
                    }
                }
            }
        }

        $scope.productChange =function (style, productStyleCategory){
            if (style.productId == null) {
                style.lowestPrice = null;
            }else{
                if (productStyleCategory && productStyleCategory.productTitleStyles) {
                    for (var i = productStyleCategory.productTitleStyles.length - 1; i >= 0; i--) {
                        var s= productStyleCategory.productTitleStyles[i];
                        if (s == style || s.cancelled) {
                            continue;
                        }
                        if (s.productId == style.productId) {
                            style.productId = null;
                            MessageService.error("请勿重复选择商品");
                            return;
                        }
                    }
                }
                // ProductTitleStyle.getLowestPrice({productId:style.productId}, function(data){
                //     if (data.status == 200) {
                //         style.lowestPrice = data.message;
                //     }else{
                //         MessageService.error("获取数据失败")
                //     }
                // }, function(){
                //     MessageService.error("获取数据失败")
                // })
            }

        }

        $scope.removeFile2 = function(style){
            style.img = null;
        }


        window.styleImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "style-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.productTitle.productTitleStyles.length - 1; i >= 0; i--) {
                            var style = vm.productTitle.productTitleStyles[i];
                            if (style.cancelled || style.deleted) {
                                continue;
                            }
                            if (style.uuid == uuid) {
                                style.img = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        window.styleImgFile2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "style-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.productTitle.productStyleCategories.length - 1; i >= 0; i--) {
                            var ca = vm.productTitle.productStyleCategories[i];
                            if (ca.productTitleStyles == null || ca.productTitleStyles.length == 0) {
                                continue;
                            }
                            for (var j = ca.productTitleStyles.length - 1; j >= 0; j--) {
                                var style = ca.productTitleStyles[j];
                                if (style.cancelled || style.deleted) {
                                    continue;
                                }
                                if (style.uuid == uuid) {
                                    style.img = data.key;
                                    break
                                }
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.addFourStyle = function(productStyleCategory){
            if (productStyleCategory.productTitleStyles == null) {
                productStyleCategory.productTitleStyles= [];
            }
            productStyleCategory.productTitleStyles.push({added:true, cancelled:false, uuid:UidService.get()});
            categoryProductInit(productStyleCategory);
        }

        $scope.addStyle = function(){
            if (vm.productTitle.productTitleStyles == null) {
                vm.productTitle.productTitleStyles= [];
            }
            vm.productTitle.productTitleStyles.push({added:true, uuid:UidService.get(), productTitleId:vm.productTitle.id});
        }

        //新建商品
        $scope.chooseProduct = function (style) {
            vm.currentStyleLine = style;
            vm.choose = false;
            $scope.showAble();
        }

        //获取指定分类下的商品
        function categoryProductInit(productStyleCategory){
            if (productStyleCategory.products && productStyleCategory.products.length>0) {
                return;
            }
            var items=[
                {key: "saleable", op: "=", value: "true"},
                {key: "prototype", op: "in", value: "Store;StoreSelf"},
                {key: "categoryStore.id", op: "=", value: productStyleCategory.categoryId}
            ]
            if(vm.keyword){
                items.push({key: "name", op: "like", value: vm.keyword})
            }
            ProductTitleStyle.getProducts({
                items: items,
                sort: ["seq,asc"]
            }, function (res) {
                productStyleCategory.products = res;
            });
        }


        $scope.cancelChoose = function(){
            vm.choose = true;
            vm.keyword = null;
        }
        //点击分类
        $scope.classifyCb = function (data) {
            vm.classifyData = data;
            $scope.showAble();
        }

        //点击商品
        $scope.productCb = function (data) {
            data.select = !data.select;
            angular.forEach(vm.productsAll, function (p) {
                if (p.id != data.id) {
                    p.select = false;
                }
            })
            if (data.select) {
                vm.productOn = angular.copy(data);
            }else{
                vm.productOn = null;
            }
        }

        // 查询
        $scope.select = function () {
            $scope.showAble();
        }


        $scope.showAble = function(){
            vm.productsAll = angular.copy(vm.productsAllB);
            if(vm.productsAll == null || vm.productsAll.length == 0){
                return;
            }
            angular.forEach(vm.productsAll, function (p) {
                p.show = true;
            });

            if (vm.productTitle.productTitleStyles) {
                if (vm.productTitle.productTitleStyles) {
                    angular.forEach(vm.productsAll, function (p) {
                        // 所有行置于未选择状态
                        var result = ListService.inList2(p, vm.productTitle.productTitleStyles, ["id"], ["productId"]);
                        if (result != null) {
                            p.show = false;
                        }
                        if (vm.currentStyleLine!= null && result!= null && vm.currentStyleLine.productId == result.productId) {
                            p.show = true;
                            p.select = true;
                        }
                        if (vm.classifyData && vm.classifyData.id && vm.classifyData.id !=p.categoryId) {
                            p.show = false;
                        }
                        if (!!vm.keyword && p.name.indexOf(vm.keyword)<0) {
                            p.show = false;
                        }
                    });
                }
            }
        }

        $scope.saveChoose = function () {
            vm.currentStyleLine.productId = null;
            vm.currentStyleLine.productName = null;
            angular.forEach(vm.productsAll, function (p) {
                if(p.select){
                    vm.currentStyleLine.productId = p.id;
                    vm.currentStyleLine.productName = p.name;
                    //为了使历史数据 不可销售 作废的不显示
                    vm.currentStyleLine.saleable = p.saleable;
                    vm.currentStyleLine.productCancelled = p.cancelled;
                    if(vm.productTitle.style!='STYLETHIRD'){
                        vm.currentStyleLine.img = p.coverImg;
                    }
                    if(vm.productTitle.style=='STYLESECOND'){
                        vm.currentStyleLine.lowestPrice = p.price;
                        $scope.productChange(vm.currentStyleLine, null);
                    }
                }
            })
            vm.choose = true;
            vm.keyword = null;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function save () {
            if (vm.productTitle.productTitleStyles) {
                if (vm.productTitle.style != 'STYLEFOURTH') {
                    for (var i = vm.productTitle.productTitleStyles.length - 1; i >= 0; i--) {
                        var s = vm.productTitle.productTitleStyles[i];
                        if (s.cancelled) {
                            continue;
                        }
                        if (s.sort == null) {
                            MessageService.error("请录入排序");
                            return;
                        }
                        if (vm.productTitle.style != 'STYLETHIRD' && s.img == null) {
                            MessageService.error("请配置照片");
                            return;
                        }
                        if (s.productId == null) {
                            MessageService.error("请关联商品");
                            return;
                        }
                    }
                }

            }
            vm.isSaving = true;
            ProductTitle.update(vm.productTitle, onSaveSuccess, onSaveError);

        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:productTitleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();

