(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('RegionMySuffixDialogController', RegionMySuffixDialogController);

    RegionMySuffixDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'entity', 'Region', 'Province', 'MessageService', '$stateParams', 'LocationProvice', '$uibModal'];

    function RegionMySuffixDialogController($timeout, $scope, $q, $state, entity, Region, Province, MessageService, $stateParams, LocationProvice, $uibModal) {
        var vm = this;

        vm.region = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.tempId = $stateParams.tempId;
        vm.billingRuleList = [
            {name: '按件计费', id: 'COSTBYPIECE'}
        ]
        if (vm.tempId == null) {
            return;
        }

        if (vm.region.id == null) {
            vm.region.id = 0;
        }

        function cancel() {
            $state.go("regionmySuffix");
        }

        $q.all([vm.region.$promise]).then(function () {
            vm.provices = LocationProvice.other4Temp({tempId: vm.tempId, regionId:vm.region.id});
            deliveryAreaInfo();
        });

        $scope.address = function () {
            $uibModal.open({
                templateUrl: 'app/entities/store-delivery/address-modal.html',
                controller: 'AddressModal1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return {
                            deliveryAreas: vm.region.deliveryAreas,
                            provices: vm.provices
                        }
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    vm.region.deliveryAreas = backData;
                    deliveryAreaInfo()
                }
            });
        }
        function deliveryAreaInfo(){
            vm.deliveryAreas=[];
            vm.region.deliveryAreas.forEach(function (item) {
                var isProvince = false;
                for(var i=0; i<vm.deliveryAreas.length; i++){
                    if(vm.deliveryAreas[i].id == item.provinceId){
                        isProvince = true;
                        break
                    }
                }
                if(!isProvince){
                    vm.deliveryAreas.push({
                        id: item.provinceId,
                        name: item.provinceName,
                        citys:[]
                    })
                }
            })
            vm.region.deliveryAreas.forEach(function (item) {
                vm.deliveryAreas.forEach(function (deliveryArea) {
                    if(deliveryArea.id == item.provinceId){
                        var isCity = false;
                        for(var i=0; i<deliveryArea.citys.length; i++){
                            if(deliveryArea.citys[i].id == item.cityId){
                                isCity = true;
                                break
                            }
                        }
                        if(!isCity){
                            deliveryArea.citys.push({
                                id: item.cityId,
                                name: item.cityName,
                                districts:[]
                            })
                        }
                    }
                })
            })
            vm.region.deliveryAreas.forEach(function (item) {
                vm.deliveryAreas.forEach(function (deliveryArea) {
                    if(deliveryArea.id == item.provinceId){
                        deliveryArea.citys.forEach(function (city) {
                            if(city.id == item.cityId){
                                var isDistrict = false;
                                for(var i=0; i<city.districts.length; i++){
                                    if(city.districts[i].id == item.districtId){
                                        isDistrict = true;
                                        break
                                    }
                                }
                                if(!isDistrict){
                                    city.districts.push({
                                        id: item.districtId,
                                        name: item.districtName
                                    })
                                }
                            }
                        })
                    }
                })
            })
            console.log(vm.deliveryAreas)
        }

        $scope.validate = function (data) {
            var regular = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
            var integer = /^[1-9]\d*$/;
            if (data.name == null || data.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            if (data.billingRule == null || data.billingRule == ""&&data.billingRule!=0) {
                MessageService.error("请选择计费规则");
                return false;
            }else {
                if (!integer.test(data.pieceQuantity)) {
                    MessageService.error("计费方式的件数必须大于0的正整数");
                    return false;
                }
                if (!regular.test(data.pieceFreight)) {
                    MessageService.error("计费方式的邮费不能为负数");
                    return false;
                }
                if (!integer.test(data.pieceAddQuantity)) {
                    MessageService.error("计费方式的增加件数必须大于0的正整数");
                    return false;
                }
                if (!regular.test(data.pieceAddFreight)) {
                    MessageService.error("计费方式的增加运费不能为负数");
                    return false;
                }
                if(data.fullReduceAble && !data.fullReduceQuantity){
                    MessageService.error("请输入满免条件");
                    return false;
                }
            }
            if (data.deliveryAreas == null || data.deliveryAreas.length == 0) {
                MessageService.error("请勾选区域");
                return false;
            }
            return true;
        };


        function save() {
            if (!$scope.validate(vm.region)) {
                return;
            }
            vm.isSaving = true;
            if (vm.region.id !== null && vm.region.id != 0) {
                Region.update(vm.region, onSaveSuccess, onSaveError);
            } else {
                if (vm.region.id == 0) {
                    vm.region.id = null;
                }
                vm.region.regionTempId = vm.tempId;
                Region.save(vm.region, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            $state.go("regionmySuffix");
            MessageService.success("信息更新成功");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
