(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PasswordActivityDialogController', PasswordActivityDialogController);

    PasswordActivityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'PasswordActivity', 'Product', 'Brand'];

    function PasswordActivityDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, PasswordActivity, Product, Brand) {
        var vm = this;

        vm.passwordActivity = entity;
        vm.clear = clear;
        vm.save = save;
        vm.products = Product.getAllByCondition({items:[]});
        
        vm.brands = Brand.getAllByCondition({items:[{key: "enabled", op: "=", value: "true"}]});

        $q.all([vm.passwordActivity.$promise, vm.brands.$promise,vm.products.$promise]);
    

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            console.log(vm.passwordActivity);
             vm.isSaving = true;
             if (vm.passwordActivity.id !== null) {
                 PasswordActivity.update(vm.passwordActivity, onSaveSuccess, onSaveError);
             } else {
                 PasswordActivity.save(vm.passwordActivity, onSaveSuccess, onSaveError);
             }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:passwordActivityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
