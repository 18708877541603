(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RemarkSetController', RemarkSetController);

    RemarkSetController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MessageService', 'PayCode'];

    function RemarkSetController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MessageService, PayCode) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.data = angular.copy(entity);

        function save () {
            if(!vm.data.remark){
                MessageService.error("备注不能为空");
                return
            }
            vm.isSaving = true;
            PayCode.updateRemark({code:vm.data.code, remark: vm.data.remark},onSaveSuccess,onSaveError)
        }

        function onSaveSuccess () {
            MessageService.success("备注设置成功");
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("备注设置失败")
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss(false);
        }

    }
})();
