(function() {
    'use strict';

    angular
        .module('backApp')
        .factory('Password', Password);

    Password.$inject = ['$resource'];

    function Password($resource) {
        var resourceUrl = 'security/' + 'api/store-users/changePassword/:id';
        return $resource(resourceUrl, {}, {
            'save': { method:'POST' }
        });
    }
})();
