(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ThemeClassifyDetailController', ThemeClassifyDetailController);

    ThemeClassifyDetailController.$inject = ['$state', 'entity', 'MessageService', '$uibModalInstance', 'ThemeClassify'];

    function ThemeClassifyDetailController($state, entity, MessageService, $uibModalInstance, ThemeClassify) {

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        if(entity.state == 'add'){
            vm.themeClassify = {
                type: entity.classIfyType,
                name: ''
            }
            console.log(vm.themeClassify)
        }else {
            vm.themeClassify = angular.copy(entity.data);
        }


        function clear () {
            $uibModalInstance.close();
        };

        function save () {
            if(!vm.themeClassify.name){
                MessageService.error("请输入名称");
                return
            }
            if(vm.themeClassify.name == "推荐"){
                MessageService.error("不能使用'推荐'作为分类名字");
                return
            }
            if(!vm.themeClassify.seq){
                MessageService.error("请输入排序");
                return
            }
            vm.isSaving = true;
            vm.themeClassify.used=true
            ThemeClassify.save(vm.themeClassify, function(result){
                MessageService.success("操作完成");
                vm.isSaving = false;
                $uibModalInstance.close(result);
            },function(){
                MessageService.error("保存失败");
                vm.isSaving = false;
            });
        }
    }
})();
