(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreThirdOrderDialogController', StoreThirdOrderDialogController);

    StoreThirdOrderDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'PlatformAbutment','ThirdStores'];

    function StoreThirdOrderDialogController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, PlatformAbutment, ThirdStores) {
        var vm = this;
        vm.data = entity;
        vm.store = Store.getCurrent();
        vm.platforms = []
        PlatformAbutment.getAll({items:[{key:"enabled", op :"=", value:true}]}, function (res) {
            vm.platforms = res;
        }, function (error) {
            MessageService.error(error.data.message);
        })
        $scope.save = function() {
            if(!vm.data.name){
                MessageService.error("名称不能为空");
                return
            }
            if(!vm.data.platformAbutmentId){
                MessageService.error("请选择来源");
                return
            }
            vm.isSaving = true;
            ThirdStores.save(vm.data , onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $state.go('store-third-shop')
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }
        $scope.goBack = function (){
            $state.go('store-third-shop')
        }
    }
})();
