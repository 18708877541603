(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ExchangeRecord', ExchangeRecord);

    ExchangeRecord.$inject = ['$resource', 'DateUtils'];

    function ExchangeRecord($resource, DateUtils) {
        var resourceUrl = 'service/' + 'api/exchange-record/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertLocalDateFromServer(data.startTime);
                        data.endTime = DateUtils.convertLocalDateFromServer(data.endTime);
                    }
                    return data;
                }
            }
        });
    }
})();