(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$q', '$state', '$scope', 'Auth', 'Principal', 'ProfileService', 'LoginService', "$uibModal", '$localStorage', 'BranchStoreManagements', 'Store',
    'Qinius', 'VersionDescriptions', 'MessageService'];

    function NavbarController($q, $state, $scope, Auth, Principal, ProfileService, LoginService, $uibModal, $localStorage, BranchStoreManagements, Store,
        Qinius, VersionDescriptions, MessageService) {
        var vm = this;
        var pageName = null;
        var arr = null;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = $localStorage.user;
        vm.menu = $localStorage.menu1;

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.shrink = shrink;
        vm.sidebar2Shrik = sidebar2Shrik;
        vm.titleNavbar = titleNavbar;
        vm.passwords = passwords;
        vm.collapseNavbar = collapseNavbar;
        vm.lmenuClick = lmenuClick;
        vm.accountBranch = null;
        vm.unReadCount = 0;

        vm.store = Store.getCurrent();
        vm.domains = Qinius.getDomain();
        vm.isflow = false;

        var $logo = $(".navbar-logo");
        var $bar1 = $(".sidebar1-btn");
        var $side1 = $(".sidebar1");
        var $bar2 = $(".sidebar2-btn");
        var $side2 = $(".sidebar2");
        var isHide = false;
        var $navbar = $(".navbar-wrap");

        setTimeout(function() {
            if (window.location.href.indexOf('homeClear') !=-1) {
                if ($side1[0] && $side1[0].children[1]) {
                    var childs = $side1[0].children[1];
                    for (var i = 0; i< childs.childElementCount; i++) {
                        var li = childs.children[i];
                        if (li.attributes["style"] && li.attributes["style"].value == "display: none;") {
                            continue;
                        }
                        console.log(li.attributes["id"])
                        if (li.attributes["id"]) {
                            console.log(li.attributes["id"].nodeValue)
                            lmenuClick(li.attributes["id"].nodeValue)
                            return;
                        }
                    }
                }
            }
        }, 100);

        //需要接口调用
        VersionDescriptions.getAllByCondition({items:[{key:"initial", op:"=", value:"false"},{key:"enable", op:"=",value:"true"}]}, function(data){
            if (data && data.length>0) {
                for (var i = data.length - 1; i >= 0; i--) {
                    if (!data[i].read) {
                        vm.unReadCount++;
                    }
                }
            }
        });

        $scope.jzMessageLeave = function(){
            vm.jzMessageShow = false;
        };

        $scope.jzMessageOver = function(e){
            if(!vm.jzMessageShow){
                vm.jzMessageShow = true;
                 VersionDescriptions.getAllByCondition({items:[{key:"initial", op:"=", value:"false"},{key:"enable", op:"=",value:"true"}]}, function(data){
                    if (data && data.length>0) {
                        vm.jzMessage = data.sort(function(a, b){
                            return a.seq- b.seq;
                        });
                    }

                });
            }
        };

        $scope.read = function (data) {
            VersionDescriptions.get({ id: item.id }, function(res) {
                if (res) {
                    $uibModal.open({
                        templateUrl: 'app/entities/give-notice/modal/give-notice-modal.html',
                        controller: 'GiveNoticeModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        resolve: {
                            entity: [function() {
                                return res;
                            }]
                        }
                    });
                }
            })

        };

        //全部已读
        $scope.readBatch = function () {
            var _ids = [];
            if(vm.jzMessage.length> 0){
                for(var i=0; i<vm.jzMessage.length; i++){
                    if (!vm.jzMessage[i].read) {
                        _ids.push(vm.jzMessage[i].id);
                    }
                }
                var ids = _ids.join(",");
                VersionDescriptions.readBatch({ids:ids}, function (res) {
                    $state.reload();
                })
            }
        };

        $q.all([vm.store.$promise, vm.domains.$promise]).then(function(){
            vm.menulogo = vm.store.menuLogo;
            // 测试
            // vm.menulogo = 'logo-7a0d1ad9-d702-4fcd-99ed-419d2166d0e9';
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';

            if(vm.account.authorities.indexOf("ROLE_STORE_BRANCH_SHOP")!=-1){

            }else if(vm.account.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP")!=-1){
                // // 我的分店权限
                vm.storeDivShow = true;
                BranchStoreManagements.findByUserId({userId:vm.account.id}, function(data){
                    if (data.id) {
                        vm.accountBranch = data;
                    }
                })
            };
            if(vm.store.authorityType && vm.store.authorityType.indexOf("PRODUCT") != -1){
                vm.isflow = true;
            }
        })
          $scope.backTo = function(){
            sessionStorage.setItem("lmenu", 'a_marketing');
            $state.go("marketing");
            vm.lmenu = "a_marketing";

        }

        // 菜单刷新后定位
        vm.lmenu = sessionStorage.getItem("lmenu") == null ? 'a_home' : sessionStorage.getItem("lmenu");
        if (vm.lmenu == "null" || vm.lmenu == 'a_home') {
            sessionStorage.setItem("lmenu", "a_home");
            lmenuClick('a_home');
        }
        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
            $(".titleNavbar").eq(vm.menu).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');

        });

        window.showSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left');
        };

        window.hideSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left');
        };

        function lmenuClick(a_id) {
            vm.lmenu = a_id;
            sessionStorage.setItem("lmenu", a_id);
            // 首页直接显示
            // if (a_id == "a_home") {
            //     return;
            // }
            setTimeout(function() {
                if(a_id=='a_branchManagement'){
                    if(vm.account.authorities.indexOf("ROLE_STORE_BRANCH_SHOP")!=-1){
                        $state.go("branch-store",{reload:true});
                    }else if(vm.account.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP")!=-1){
                        if (vm.accountBranch && vm.accountBranch.id) {
                            $state.go("branch-store-detail",{id:vm.accountBranch.branchStore.id},{reload:true});
                        }

                    }
                    return;
                }

                 if ($side2[0] && $side2[0].children[0]) {
                    var childs = $side2[0].children[0];
                    for (var i = 0; i< childs.childElementCount; i++) {
                        var li = childs.children[i];
                        if (li.attributes["style"] && li.attributes["style"].value == "display: none;") {
                            continue;
                        }

                        if (li.attributes["ui-sref"]) {
                            console.log(li.attributes["ui-sref"].value)
                            $state.go(li.attributes["ui-sref"].value);
                            return;
                        }
                    }
                 }
             }, 100);
        }

        vm.marketingGroup = marketingGroup;
        function marketingGroup() {
            vm.lmenu = 'a_marketing';
            $state.go("marketing");
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            sessionStorage.setItem("lmenu", null);
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }


        var createModal = function(url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: true,
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                }
            });
        };

        vm.show_logo = true;
        function shrink() {
            $navbar.toggleClass("sidebar-collapse");
            $side2.addClass('transition');
            if (isHide) {
                if ($navbar.hasClass("sidebar-collapse")) {
                    $bar1.removeClass('btn1-shrik1').addClass("btn1-shrik2");
                    $navbar.toggleClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
                    $(".wrap").removeClass("wrap-mar130").removeClass("wrap-mar160").toggleClass("wrap-mar60");
                } else {
                    $bar1.removeClass('btn1-shrik2').addClass("btn1-shrik1");
                    $(".wrap").removeClass("wrap-mar160").removeClass("wrap-mar60").toggleClass("wrap-mar130");
                    $navbar.toggleClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                }
            } else {$bar1.toggleClass('btn1-shrik1').toggleClass("btn1-shrik2");
                $(".wrap").toggleClass("wrap-mar160");
            }

            // 首页特殊处理
            if (vm.lmenu == 'a_home') {
                if ($("#home-page").hasClass("wrap-mar-0-80")) {
                    $("#home-page").removeClass("wrap-mar-0-80");
                    vm.home_page_shrink = false;
                } else {
                    $("#home-page").addClass("wrap-mar-0-80");
                    vm.home_page_shrink = true;
                }
            }

            $logo.addClass('transition');
            $logo.toggleClass("navbar-logo3");
            if ($logo.hasClass("navbar-logo3")) {
                vm.show_logo = false;
            } else {
                vm.show_logo = true;
            }
            return false;
        }

        $scope.resetPassword = function(){
            createModal('app/account/password/password.html', 'PasswordController').result.then(
                function(data) {
                });
        };

        function titleNavbar(i) {
            var $titleNavbar = $(".titleNavbar");
            var $menu1 = $(".menu1");
            // click时清空一级菜单样式
            $titleNavbar.find($(".navbar-title")).css({
                background: '#3c434d'
            });
            $titleNavbar.find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
            //若二级菜单为隐藏状态：
            if ($titleNavbar.eq(i).find($menu1).is(':hidden')) {
                //箭头运动状态向下
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');
                // click时改变一级菜单背景色和字体色
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                //显示当前二级菜单
                $titleNavbar.eq(i).find($menu1).slideDown(400);

                $localStorage.menu1 =i;
                //隐藏其他二级菜单
                $titleNavbar.eq(i).siblings().find($(".menu1")).hide();

            } else {
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                $titleNavbar.eq(i).find($menu1).slideUp(400);
                $titleNavbar.eq(i).siblings().find($menu1).hide();
            }
        }

        function sidebar2Shrik() {
            if(!($(".sidebar2-shrink").hasClass('shidebar2-shrink-left')||$(".sidebar2-shrink").hasClass('shidebar2-shrink-left2'))){
                return
            }

            // 首页特殊处理
            if (vm.lmenu == 'a_home') {
                // 一级菜单缩进
                if (vm.home_page_shrink2) {
                    isHide = !isHide;
                }
                if (vm.home_page_shrink) {
                    if ($("#home-page").hasClass("wrap-mar-0-190")) {
                        $("#home-page").removeClass("wrap-mar-0-190");
                    } else {
                        $("#home-page").addClass("wrap-mar-0-190");
                    }
                } else {
                    if ($("#home-page").hasClass("wrap-mar-0-110")) {
                        $("#home-page").removeClass("wrap-mar-0-110");
                    } else {
                        $("#home-page").addClass("wrap-mar-0-110");
                    }
                }
            }

            $side2.removeClass('transition');
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
            isHide = !isHide;

            if(isHide){
                $(".sidebar2").addClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik1').addClass("btn2-shrik2");
                vm.home_page_shrink2 = true;
            }else {
                $(".sidebar2").removeClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik2').addClass("btn2-shrik1");
                vm.home_page_shrink2 = false;
            }
            if ($navbar.hasClass("sidebar-collapse")) {
                $(".wrap").toggleClass("wrap-mar160").toggleClass("wrap-mar60");
                $navbar.removeClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
            } else {
                $navbar.removeClass("sidebar-collapse2").removeClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                $(".wrap").removeClass("wrap-mar160").toggleClass("wrap-mar130");
            }
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function passwords() {
            delete $localStorage.pageName;
            pageName = window.location.hash;
            arr = pageName.split('#')[1].substring(1);
            $localStorage.pageName = arr
        }

        $scope.goModele = function () {
            if(!vm.store.productXmlKey){
                MessageService.error("请配置模板工具key");
                return
            }
            if(!vm.account.phone){
                MessageService.error("请先绑定手机号");
                return
            }
            var url = vm.store.productXmlUrl + '/platform/#/homeFirst?key=' + vm.store.productXmlKey + '&userId=' + vm.account.id + '&scope=' + vm.account.scope + '&phone=' + vm.account.phone + '&uuid=' + vm.account.uuid;
            window.open(url);
        }
    }
})();
