(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountCommercialController', OpenAccountCommercialController);

    OpenAccountCommercialController.$inject = ['$state', '$scope','UidService', '$timeout', 'Qinius', '$uibModal', '$q', 'Store', 'MessageService'];

    function OpenAccountCommercialController($state, $scope,UidService, $timeout, Qinius, $uibModal, $q, Store, MessageService) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            if(vm.store.numberRule!=null&&vm.store.numberRule.length==4){
                vm.disable = true;
            }
            Store.update(vm.store);
        });

        $scope.save = function () {
            if(!vm.store.storeType2){
                MessageService.error("商户未设置商户类型");
                return
            }
            if(!vm.store.name){
                MessageService.error("商户名称不能为空");
                return
            }
            if (vm.store.numberRule == null || vm.store.numberRule.length<4 || vm.store.numberRule.length>4) {
                MessageService.error("订单前缀不符合规则");
                return;
            }
            if (!/^([0-9]+)$/.test(vm.store.numberRule.substring(1)) && !/[a-zA-Z]+/.test(vm.store.numberRule.substring(0,1))) {
                MessageService.error("订单前缀不符合规则");
                return;
            }
            if(!vm.store.unique){
                MessageService.error("商户标识不能为空");
                return;
            }
            if (!/^[0-9a-zA-Z]{5}$/.test(vm.store.unique)) {
                MessageService.error("商户标识由字母或数字组成的5位数编码");
                return;
            }
            Store.getAllByCondition({
                items: [{key:"numberRule", op:"=", value:vm.store.numberRule}]
            }, function(data){
                if (vm.store.id !== null) {
                    if ((data.length ==1 && data[0].id != vm.store.id) || data.length>1) {
                        MessageService.error("订单号前缀重复，请重新设定");
                    }else{
                        vm.isSaving = true;
                        Store.update(vm.store, onSaveSuccess, onSaveError);
                    }
                } else {
                    if (data.length >0) {
                        MessageService.error("订单号前缀重复，请重新设定");
                    }else{
                        vm.isSaving = true;
                        Store.save(vm.store, onSaveSuccess, onSaveError);
                    }

                }
            }, function(){
                MessageService.error("检查单号前缀失败，请稍后再试");
            });
        };
        function onSaveSuccess(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            jumpOver();
        }
        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }
        function jumpOver() {
            if(vm.store.storeType2.indexOf('WECHAT')>=0){
                $state.go("openAccount-tencent");
                return;
            }else if(vm.store.storeType2.indexOf('MINI')>=0){
                $state.go("openAccount-mini");
                return;
            }else if(vm.store.storeType2.indexOf('WEB')>=0){
                $state.go('openAccount-modePayment-web');
            }else if(vm.store.storeType2.indexOf('BYTEDANCE')>=0){
                $state.go('openAccount-modePayment-byte');
            } else{
                $state.go('openAccount-style');
            }
        }


        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        };

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'logo-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store.menuLogo = data.key
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.goBack = function () {
            window.history.go(-1);
        }
    }

})();
