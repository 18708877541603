(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('MessageNotify', MessageNotify);

    MessageNotify.$inject = ['$resource', 'DateUtils'];

    function MessageNotify ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/message-templates/:path/:id';

        return $resource(resourceUrl, {}, {
            //'query': { method: 'GET', isArray: true},
            'query': { method: 'POST',
                       isArray: true,
                       params:{
                path:"byCondition"
                       }},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            },
            'send': {
                method: 'get',
                params:{
                    path:"send"
                }
            },
            'revert': {
                method: 'get',
                params:{
                    path:"revert"
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
