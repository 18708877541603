(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomersHistoryDialogController', CustomersHistoryDialogController);

    CustomersHistoryDialogController.$inject = ['$scope', '$q', '$state','$rootScope', '$stateParams', '$uibModalInstance', 'MHistory'];

    function CustomersHistoryDialogController($scope, $q, $state,$rootScope, $stateParams, $uibModalInstance, MHistory) {
        var vm = this;
        vm.clear = clear;

        vm.customerId = $stateParams.id;
        vm.historys = [];
        if (vm.customerId != null ) {
            vm.historys = MHistory.getAllByCondition({items:[{key:"classId", op:"=", value: vm.customerId},{key:"className", op:'=', value:"Customer"}],sort:["createdDate,desc"]});
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
