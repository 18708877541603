(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('UserAnalysisController', UserAnalysisController);

    UserAnalysisController.$inject = ['$scope', '$state', 'MessageService', 'WechatUserAnalysis', 'DateUtils', 'XlsxService'];

    function UserAnalysisController($scope, $state, MessageService, WechatUserAnalysis, DateUtils, XlsxService) {
        var vm = this;
        vm.openCalendar = openCalendar; 
        vm.changeTime = changeTime;
        vm.changeTopType = changeTopType; 
        vm.begin_date = null;
        vm.end_date = null; 
        vm.pageType = 0;  
        var ref_date_datas = [];
        var cancel_user_datas = [];
        var cumulate_user_datas = [];
        var new_user_datas = []; 
        vm.downLoadFile = downLoadFile;
        vm.sheetName = "新关注人数";
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false; 
        vm.times = {
            time1: {
                key: 1,
                value: '最近7日'
            },
            time2: {
                key: 2,
                value: '最近15日'
            },
            time3: {
                key: 3,
                value: '最近30日'
            }
        };
        vm.time = vm.times.time1; 

        function downLoadFile(){
            var dates = []; 
            if (vm.pageType == 0) { 
                for (var i = ref_date_datas.length - 1; i >= 0; i--) {
                    dates.push({
                        "日期":ref_date_datas[i],
                        "人数":new_user_datas[i] 
                    }) 
                };  
            }else if (vm.pageType == 1) { 
                for (var i = ref_date_datas.length - 1; i >= 0; i--) {
                    dates.push({
                        "日期":ref_date_datas[i],
                        "人数":cancel_user_datas[i] 
                    }) 
                }; 
            }else if (vm.pageType == 2) { 
                for (var i = ref_date_datas.length - 1; i >= 0; i--) {
                    dates.push({
                        "日期":ref_date_datas[i],
                        "人数":cumulate_user_datas[i] 
                    }) 
                };  
            };
            XlsxService.xlsxOut(dates, vm.sheetName , "用户分析");
            
        }

        $scope.searchDate = function(){ 
            if (((new Date()) - vm.endTime)/1000/3600/24 < 1) {
                MessageService.error("结束时间最早只能选择昨天");
                return;
            };
            if (vm.endTime - vm.startTime<0) {
                MessageService.error("开始时间不能晚于结束时间");
                return;
            };
            if ((vm.endTime-vm.startTime)/1000/3600/24>30) {
                MessageService.error("查询时间不能超过31天");
                return; 
            };
            vm.begin_date = DateUtils.convertLocalDateToServer(vm.startTime);
            vm.end_date =  DateUtils.convertLocalDateToServer(vm.endTime); 
            WechatUserAnalysis.getUserSummaryDate({begin_date: vm.begin_date, end_date:vm.end_date}, function (data) { 
                if (data && data.list ) { 
                    ref_date_datas = [];
                    cancel_user_datas = [];
                    cumulate_user_datas = [];
                    new_user_datas = [];

                    for (var i = 0; i< data.list.length ; i++) {
                        var d = data.list[i]; 
                        ref_date_datas.push(d.ref_date);
                        cancel_user_datas.push(d.cancel_user);
                        cumulate_user_datas.push(d.cumulate_user);
                        new_user_datas.push(d.new_user);
                    };
                    if (vm.pageType == 0) {
                        vm.sheetName = "新关注人数";
                        echarts_init(ref_date_datas, vm.sheetName, new_user_datas);
                    }else if (vm.pageType == 1) {
                        vm.sheetName = "取消关注人数";
                        echarts_init(ref_date_datas, vm.sheetName, cancel_user_datas);
                    }else if (vm.pageType == 2) {
                        vm.sheetName = "累积关注人数";
                        echarts_init(ref_date_datas, vm.sheetName, cumulate_user_datas);
                    };
                };
            },function(){
                MessageService.error("服务器异常，请稍后重试");
            });
        } 

        function initData() {
            // 昨日的订单数和销售额
            WechatUserAnalysis.getTodayData({}, function(data) {
                if (data && data.list && data.list.length > 0) {
                    vm.yesterday = data.list[0];
                };
            });
            //初始的时候  最近七日
            vm.endTime= new Date();
            vm.startTime = new Date(); 
            vm.endTime.setDate(vm.endTime.getDate() - 1); 
            vm.startTime.setDate(vm.startTime.getDate() - 7);   
            $scope.searchDate();
        } 

        function changeTopType(num) {
            //选择显示数据
            vm.pageType = num;  
            if (vm.pageType == 0) {
                vm.sheetName = "新关注人数";
                echarts_init(ref_date_datas, vm.sheetName, new_user_datas);
            }else if (vm.pageType == 1) {
                vm.sheetName = "取消关注人数";
                echarts_init(ref_date_datas, vm.sheetName, cancel_user_datas);
            }else if (vm.pageType == 2) {
                vm.sheetName = "累积关注人数";
                echarts_init(ref_date_datas, vm.sheetName, cumulate_user_datas);
            };
        }  
        
        // 画图部分
        function echarts_init(ref_dates, name, datas) {
            var myChart = echarts.init(document.getElementById("main-userAnalysis"));
            // 指定图表的配置项和数据
            var option = {
                xAxis: {
                    type: 'category',
                    data: ref_dates,

                },
                yAxis: {
                    minInterval : 1,
                    type: 'value'
                },
                tooltip: {
                        trigger: 'axis'
                    },
                series: [{ 
                    name: name,
                    data: datas,
                    type: 'line',
                    areaStyle: {normal: {}},
                }]
            }; 
            // 使用刚指定的配置项和数据显示图表。  
            myChart.setOption(option);
        }

        // 选择时间下拉框
        function changeTime() {
            vm.endTime= new Date();
            vm.startTime = new Date(); 
            vm.endTime.setDate(vm.endTime.getDate() - 1); 
            if (vm.time.key == 1) {
                vm.startTime.setDate(vm.startTime.getDate() - 7); 
            } else if (vm.time.key == 2) { 
                vm.startTime.setDate(vm.startTime.getDate() - 15); 
            } else if (vm.time.key == 3) { 
                vm.startTime.setDate(vm.startTime.getDate() - 30); 
            }  
            $scope.searchDate();
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // 初始化页面数据
        initData();
    }
})();