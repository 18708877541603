(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StorePaymentSetting', StorePaymentSetting)
        .factory('StorePickLocations', StorePickLocations);

    StorePaymentSetting.$inject = ['$resource'];
    StorePickLocations.$inject = ['$resource'];

    function StorePaymentSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/store-payment-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCurrent': {
                method: 'GET',
                params: {
                    path: 'getCurrent'
                },
            },
            'update': { method:'PUT' }
        });
    }
    function StorePickLocations ($resource) {
        var resourceUrl =  'manager/' + 'api/store-pick-locations/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCurrent': {
                method: 'GET',
                params: {
                    path: 'getCurrent'
                },
            },
            'getAllByCondition': {
                method: 'POST',
                isArray: true,
                params: {
                    path: 'getAllByCondition'
                },
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' }
        });
    }
})();
