(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShareSettingDetailController', ShareSettingDetailController);

    ShareSettingDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'ShareSetting', 'Product'];

    function ShareSettingDetailController($scope, $rootScope, $state, $stateParams, previousState, entity, ShareSetting, Product) {
        var vm = this;

        vm.shareSetting = entity;
        vm.clear = clear;
        vm.previousState = previousState.name;
        function clear(){
            $state.go("share-setting")
        }
        var unsubscribe = $rootScope.$on('backApp:shareSettingUpdate', function(event, result) {
            vm.shareSetting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
