(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Customer', Customer)
        .factory('Coupons', Coupons)
        .factory('MHistory', MHistory);

    Customer.$inject = ['$resource', 'DateUtils'];
    Coupons.$inject = ['$resource', 'DateUtils'];
    MHistory.$inject = ['$resource', 'DateUtils'];

    function MHistory ($resource) {
        var resourceUrl =  'manager/' + 'api/histories/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            }
            // ----------销售分析使用----end-------
        });
    }

    function Customer ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/customers/:path/:id';

        return $resource(resourceUrl, {}, {
            'getToken': {
                method: 'GET',
                params: {
                    path: 'getToken'
                }
            },
            "getAllByCondition":{
                method:"POST",
                isArray: true,
                params:{
                    path:"getAllByCondition"
                }
            },
            "byCondition3":{
                method:"POST",
                isArray: true,
                params:{
                    path:"byCondition3"
                }
            },
            "byCondition":{
                method:"POST",
                isArray: true,
                params:{
                    path:"byCondition"
                }
            },
            "getInviteData":{
                method:"GET",
                params:{
                    path:"getInviteData"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastLogin = DateUtils.convertDateTimeFromServer(data.lastLogin);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            "todayNum":{
                method:"GET",
                params:{
                    path:"todayNum"
                }
            },
            "setInactive":{
                method:"GET",
                params:{
                    path:"setInactive"
                }
            }
        });
    };
    function Coupons ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/coupons/:path/:id';

        return $resource(resourceUrl, {}, {
            "assignSendCouponToCustomer":{
                method:"GET",
                params:{
                    path:"assignSendCouponToCustomer"
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            }
        });
    }
})();
