(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductViewController', ProductViewController);

    ProductViewController.$inject = ['$scope', 'entity', 'Qinius', '$http', '$q', 'ImageService', '$uibModalInstance'];

    function ProductViewController($scope, entity, Qinius, $http, $q, ImageService, $uibModalInstance) {
        var vm = this;
        vm.counts = 0;
        vm.textCounts = 0;
        vm.article = angular.copy(entity);

        var artDoc = angular.copy(vm.article.document);

        /**
         * mm换算成px
         * @param mm
         * @returns {number}
         */
        var unitTransform = function (mm) {
            return Math.ceil(mm * 3);
        };

        $scope.isShow = false;
        $scope.isFirst = true;
        $scope.isLast = false;
        var $li = $(".templateView-carousel");
        var $leftBtn = $(".leftBtn");
        var $rightBtn = $(".rightBtn");
        var $backBtn = $("#back-btn");


        $scope.k = 0;
        $scope.t = 0;
        $scope.isText = false;

        var allWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $scope.dHeight = vm.dHeight = ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 100) / 1.8;
        $scope.dWidth = vm.dWidth = (allWidth - 220) / 1.8;
        $scope.template = {
            pages: {
                page: [{}]
            }
        };
        $scope.document = {
            pages: [{}]
        };

        angular.forEach(artDoc.pages.page, function (page) {
            vm.counts += page.imageboxes.imagebox.length;
            vm.textCounts += page.textboxes.textbox.length;
        });
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        $scope.domains = Qinius.getDomain();
        $(".modal-content").addClass('opacity0');
        getAll(artDoc);
        function getAll(doc) {
            $q.all([$scope.domains.$promise, vm.article.$promise]).then(function () {
                $scope.domain = 'http://' + $scope.domains.domains[0] + '/';
                $scope.len = doc.pages.page.length;
                //取得xml文档内容
                vm.reprint = doc.reprint;
                if (vm.reprint == 'UAD') {
                    $scope.dHeight = vm.dHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 100;
                }
                localStorage.setItem('reprint', vm.reprint);
                $scope.maxW = doc.pages.page[0].mediabox.width;
                $scope.minW = doc.pages.page[0].mediabox.width;
                $scope.maxH = doc.pages.page[0].mediabox.height;
                $scope.minH = doc.pages.page[0].mediabox.height;
                $(".modal-content").addClass('opacity0');
                $scope.getDetail(doc, 0);
            });
        }

        $scope.getDetail = function (document, index) {
            var obj = document.pages.page[index];

            $(".carousel-wrapper").height(document.pages.page[0].mediabox.height * (document.pages.page.length + 1.7));
            var bgIdentifier = 'content/images/white.png';
            $scope.template = document;
            // $scope.template.styleScale = (vm.dWidth * 0.45 / (unitTransform(obj.mediabox.width)));
            if (obj.background && obj.background.resource && obj.background.resource.identifier) {
                bgIdentifier = $scope.domain + obj.background.resource.identifier + '?imageMogr2/thumbnail/!40p';
            }
            var isMaxWidth = getScale($scope.dWidth, $scope.dHeight, obj.mediabox.width, obj.mediabox.height, index);

            $scope.template.pages.page[index] = {
                bgImg: bgIdentifier,
                background: angular.copy(obj.background),
                barcode: angular.copy(obj.barcode),
                barcodeRotation : {
                    angle: obj.barcodeRotation?obj.barcodeRotation.angle:0
                },
                mediabox: angular.copy(obj.mediabox),
                textboxes: angular.copy(obj.textboxes),
                imageboxes: angular.copy(obj.imageboxes),
                imagearts: {
                    imageart: []
                },
                centerRotation:obj.centerRotation?true:false,
                isMaxWidth: isMaxWidth,
                spread: obj.spread,
                'thumbnailH': isMaxWidth ? $scope.dWidth * 0.8 * unitTransform(obj.mediabox.height) / unitTransform(obj.mediabox.width) : $scope.dHeight * 0.7 * unitTransform(obj.mediabox.width) / unitTransform(obj.mediabox.height),
                pageScaleH: 1,
                pageScaleW: 1
            };

            // 无翻页效果
            if (document.pages.page.length < 2 || vm.reprint == 'OTHER') {
                $scope.template.pages.page[index].styleScale = (vm.dWidth * 0.7 / (unitTransform(obj.mediabox.width)));
            } else {
                $scope.template.pages.page[index].styleScale = 1;
            }

            if (obj.barcode) {
                $scope.template.pages.page[index].barcode = getGeometry(obj.barcode, index)
            }

            if (obj.imagearts) {
                angular.forEach(obj.imagearts.imageart, function (art, i) {
                    $scope.template.pages.page[index].imagearts.imageart[i] = {
                        geometry: getGeometry(art.geometry, index),
                        resource: angular.copy(art.resource),
                        rotation: {
                            angle: -art.rotation.angle
                        },
                        imgUrl: $scope.domain + art.resource.identifier + '?imageMogr2'
                    };
                });
            }

            if (obj.textboxes.textbox.length > 0) {
                angular.forEach(obj.textboxes.textbox, function (text, i) {
                    $scope.template.pages.page[index].textboxes.textbox[i] = {
                        geometry: getGeometry(text.geometry, index),
                        style: angular.copy(text.style),
                        identifier: text.identifier,
                        rotation: {
                            angle: -text.rotation.angle
                        },
                        text: text.text,
                        styles: 'NORMAL'
                    };
                    var tempText = $scope.template.pages.page[index].textboxes.textbox[i];
                    if (tempText.style.bold && tempText.style.italic) {
                        tempText.styles = 'BOLD_ITALIC';
                    } else if (tempText.style.bold) {
                        tempText.styles = 'BOLD';
                    } else if (tempText.style.italic) {
                        tempText.styles = 'ITALIC';
                    } else {
                        tempText.styles = 'NORMAL';
                    }
                    toImg(tempText, $scope.template.pages.page[index].styleScale, index, i);
                });
            }
            if (obj.imageboxes.imagebox.length > 0) {
                angular.forEach(obj.imageboxes.imagebox, function (img, i) {
                    $scope.template.pages.page[index].imageboxes.imagebox[i] = {
                        geometry: getGeometry(img.geometry, index),
                        mask: angular.copy(img.mask),
                        imgUrl: 'content/images/shattered.png',
                        maskUrl: 'content/images/shattered.png',
                        rotation: angular.copy(img.rotation),
                        heightM: img.geometry.height,
                        widthM: img.geometry.width,
                        offsetXM: img.geometry.x,
                        offsetYM: img.geometry.y,
                        scaleW: 1,
                        scaleH: 1
                    };

                    var imageboxes = $scope.template.pages.page[index].imageboxes.imagebox[i];
                    if (img.mask.resource.identifier) {
                        imageboxes.maskUrl = $scope.domain + img.mask.resource.identifier;
                    }


                    if (img.image && img.image.resource.identifier) {       //qiniu
                        imageboxes.imgUrl = $scope.domain + img.image.resource.identifier;
                        // imgScale(imageboxes, index, i, maskUrl, imageboxes.image.offsetX, imageboxes.image.offsetY);
                    }
                });
            }

            if (index < document.pages.page.length - 1) {
                $scope.getDetail(document, index + 1);
            } else {
                $scope.isLeftRotation = !$scope.template.pages.page[0].centerRotation;
                angular.forEach($scope.template.pages.page, function (page, m) {
                    angular.forEach(page.imageboxes.imagebox, function (image, n) {
                        imgScale(image, m, n, image.maskUrl);
                    })
                })
            }
            if (vm.counts == 0) {
                var arr = angular.copy($scope.template);
                angular.forEach(arr.pages.page, function (obj, index) {
                    getArr(arr, obj, index);
                });
            }
        };

        function getScale(screenW, screenH, pageW, pageH) {
            var newH = angular.copy(screenH);
            if (vm.reprint == 'UAD' && screenW * 0.7 * pageH / pageW * 2 > screenH) {
                newH = (newH - 100) / 2;
            }

            if (vm.reprint == 'UAD') {
                if (screenW / newH < pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    return false;
                }
            } else {
                if (screenW / newH > pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    return false;
                }
            }
        }

        function getScale2(screenW, screenH, pageW, pageH) {
            var newH = angular.copy(screenH);
            if (vm.reprint == 'UAD' && screenW * 0.7 * pageH / pageW * 2 > screenH) {
                newH /= 2;
            }

            if (vm.reprint == 'UAD') {
                if (screenW / newH < pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    // return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    // return false;
                }
            } else {
                if (screenW / newH > pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    // return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    // return false;
                }
            }

            // $scope.isMaxWidth = true;
        }

        function getGeometry(obj, index) {
            return {
                height: unitTransform(obj.height) * $scope.template.pages.page[index].styleScale,
                width: unitTransform(obj.width) * $scope.template.pages.page[index].styleScale,
                x: unitTransform(obj.x) * $scope.template.pages.page[index].styleScale,
                y: unitTransform(obj.y) * $scope.template.pages.page[index].styleScale
            }
        }

        function imgScale(images, index, id, mask) {
            var scaleW = 1, scaleH = 1;
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = images.imgUrl;
            newImg.onload = function () {
                var w = newImg.width;
                var h = newImg.height;
                var maskWidth = images.geometry.width;
                var maskHight = images.geometry.height;
                if ((newImg.width <= newImg.height || maskWidth <= maskHight) && (newImg.width / newImg.height < maskWidth / maskHight) || newImg.width >= newImg.height && maskWidth >= maskHight && (newImg.width / newImg.height < maskWidth / maskHight)) {
                    newImg.width = maskWidth;
                    newImg.height = maskWidth * h / w;
                } else {
                    newImg.height = maskHight;
                    newImg.width = maskHight * w / h;
                }

                scaleW = newImg.width / w;
                scaleH = newImg.height / h;

                maskd('content/images/shattered.png', mask, 'source-in', 0, 0, maskWidth, maskHight, function (src) {
                    images.blobUrl = src;
                    $scope.k++;
                    if (index == 0) {
                        // $scope.loading = false;
                        // $(".page-loading").hide();
                        // $li.show();
                    }
                    if ($scope.k == vm.counts && $scope.t == vm.textCounts) {
                        var arr = angular.copy($scope.template);
                        angular.forEach(arr.pages.page, function (obj, index) {
                            getArr(arr, obj, index);
                        });
                    }
                });
            };
        }


        function maskd(src, maskUrl, attr, x0, y0, width, height, callback) {
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.width = width;
            newImg.height = height;
            newImg.onload = function () {
                newImg.onload = null;
                var masks = document.createElement('img');
                masks.crossOrigin = "Anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = width;
                    masks.height = height;
                    imagecanvas.width = width;
                    imagecanvas.height = height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = attr;

                    imagecontext.drawImage(newImg, x0, y0, width, height);


                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
                masks.src = maskUrl;
            };
            newImg.src = src;
        }

        function toImg(text, scale) {

            text.geometry.width /= scale;
            text.geometry.height /= scale;

            $http({
                url: '/editor/fileserves/api/convert/textToImg',
                method: 'POST',
                data: {
                    identifier: text.identifier,
                    text: text.text,
                    fontSize: text.style.size,
                    style: text.styles,
                    height: text.geometry.height,
                    width: text.geometry.width,
                    color: text.style.color,
                    family: text.style.font,
                    spacingHeight: text.style.spacingHeight||0,
                    spacingWidth: text.style.spacingWidth||0
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                text.geometry.width = text.geometry.width * scale;
                text.geometry.height = text.geometry.height * scale;

                var createObjectURL = function (blob) {
                    return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
                };
                var fileURL = URL.createObjectURL(data);

                text.picCode = text.pic = fileURL;
                text.identifier = text.identifier || $scope.defaultText.path;
                $scope.t++;
                if ($scope.k == vm.counts && $scope.t == vm.textCounts) {
                    $scope.isText = true;
                    var arr = angular.copy($scope.template);
                    angular.forEach(arr.pages.page, function (obj, index) {
                        getArr(arr, obj, index);
                    });
                }
            }).error(function () {
                console.log("textToImg error");
            });
        }

        function getArr(arrs, obj, index) {
            var w = obj.mediabox.width;
            var h = obj.mediabox.height;
            if (obj.spread) {
                if (w / 2 > $scope.maxW) {
                    $scope.maxW = w / 2;
                }
                if (w / 2 < $scope.minW) {
                    $scope.minW = w / 2;
                }
            } else {
                if (w > $scope.maxW) {
                    $scope.maxW = w;
                }
                if (w < $scope.minW) {
                    $scope.minW = w;
                }
            }

            if (h > $scope.maxH) {
                $scope.maxH = h;
            }
            if (w < $scope.minH) {
                $scope.minH = h;
            }
            $scope.document.pages[index] = {
                arr: obj,
                arr2: '',
                multiple: 0.45,
                multiple2: 0.45,
                width: w,
                height: h,
                spread: obj.spread,
                'marginLeft': 0,
                'thumbnail': 1,
                'thumbnail2': 1,
                id: obj.id,
                mediabox: angular.copy(obj.mediabox)
            };
            setBgImg(index, arrs, obj);
            if (index == $scope.len - 1 && vm.reprint != 'OTHER') {
                var page = $scope.document.pages;
                getScale2($scope.dWidth, $scope.dHeight, page[0].mediabox.width, page[0].mediabox.height);
                //第一个page宽度 和 最大page宽度 的比值
                // var pageW = page[0].mediabox.width;
                // if(page[0].spread){
                //     pageW/= 2;
                // }
                // if(pageW>0){
                //     page[0].thumbnail =(pageW / $scope.maxW).toFixed(2)
                // }
                getPageThumbnail(page[0],page[0].spread);
                for (var j = 1; j < page.length; j++) {
                    page[j].halfWidth = ($scope.dWidth * page[j].multiple).toFixed(2);
                    //每个page宽度 和 最大page宽度 的比值
                    getPageThumbnail(page[j],page[0].spread);

                    if (j + 1 != page.length) {
                        getPageThumbnail(page[j + 1],page[j + 1].spread);
                    }
                    //跨页
                    if (page[j].spread == true) {
                        //左右翻页的清空
                        if (vm.reprint != 'LAR') {
                            if (!$scope.uadHeight) {
                                $scope.uadHeight = page[j - 1].mediabox.height * 2
                            }
                            // page[j].marginTop = $scope.dWidth * 0.7 * ($scope.uadHeight - page[j].mediabox.height) * page[j].thumbnail / page[j].mediabox.width / 2;
                            if ($scope.isMaxWidth) {
                                var newW = $scope.dWidth * 0.7
                            } else {
                                var newW = ($scope.dHeight - 100) / 2 * page[j].mediabox.width / page[j].mediabox.height
                            }
                            page[j].marginTop = newW * ($scope.uadHeight - page[j].mediabox.height) * page[j].thumbnail / page[j].mediabox.width / 2;

                        }

                    } else if (page[j].spread == false && (page[j + 1] || []).spread == false && (j + 2) != page.length) {
                        // console.log(j);
                        var arr = page[j + 1];
                        var arr0 = page[j];
                        page[j] = {
                            id: arr0.id,
                            width: arr0.mediabox.width,
                            height: arr0.mediabox.height,
                            arr: arr0.arr,
                            spread: arr0.spread,
                            multiple: arr0.multiple,
                            thumbnail: arr0.thumbnail,
                            marginLeft: arr0.halfWidth - $scope.dWidth * arr.multiple * arr.thumbnail,
                            id2: arr.id,
                            width2: arr.mediabox.width,
                            height2: arr.mediabox.height,
                            arr2: arr.arr,
                            spread2: arr.spread,
                            multiple2: arr.multiple,
                            thumbnail2: arr.thumbnail
                        };
                        page.splice(j + 1, 1);
                        $scope.uadHeight = arr.mediabox.height + arr0.mediabox.height;
                    }
                    if (j == page.length - 1) {
                        $scope.document.pages = page;
                        $scope.isShow = true;
                        $(".save-page").hide();

                        angular.forEach(page, function (paged) {
                            if (vm.reprint == 'UAD') {
                                $scope.getPageWaHUad(paged, true);
                                $scope.getPageWaHUad(paged, false);
                            }
                            if (vm.counts > 0 && !$scope.isText) {
                                $scope.$apply(function () {
                                    $scope.document.pages = page;
                                    if ($scope.toggle.now) {
                                        Page.init();
                                    }
                                })
                            }
                        });

                        $scope.$watch('toggle.now', function () {
                            if ($scope.toggle.now) {
                                Page.init();
                            }
                        });
                        $(".un-last:parent").css({width: '50%!important'})
                    }
                }
                $scope.document.pages = page;
            }
            if (vm.reprint == 'OTHER' || $scope.template.pages.page.length < 2) {
                $(".save-page").hide();
                $(".rightBtn").hide();
            }
        }

        function getPageThumbnail(page,spread) {
            var pageW = page.mediabox.width;
            if(spread){
                pageW/= 2;
            }
            if(pageW>0){
                page.thumbnail =(pageW / $scope.maxW).toFixed(2)
            }
        }

        function setBgImg(index, documents, obj) {
            // if ((index - 1) > 0 && documents.pages[index].identifier && (index - 1) < (documents.pages.length - 2) && (obj.spread == false && documents.pages[index - 2].spread == false && (index - 2) != 0 && (documents.pages[index - 2].spread2) || (obj.spread == false && (documents.pages[index] || []).spread == false))) {
            //     $scope.template.pages[index - 1].bgImg2 = $scope.domain + documents.pages[index].identifier;
            // }
            if (index <= 2) {
                return
            }
            if (!documents.pages.page[index].identifier) {
                return
            }
            if (index >= (documents.pages.page.length - 1)) {
                return
            }
            if (obj.spread) {
                return
            }
            if (!documents.pages.page[index - 2].spread && (documents.pages.page[index - 2].spread2)) {
                $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
                return
            }
            if (!(documents.pages.page[index] || []).spread) {
                $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
            }
        }

        $scope.getLiW = function (slide) {
            // vm.reprint=='UAD'?!isMaxWidth?(dHeight-100)/2*slide.width/slide.height:dWidth*0.7:!isMaxWidth?(dHeight-100)*(slide.width/slide.height+(slide.arr2?slide.width2/slide.height2:slide.width/slide.height)):''
            if (vm.reprint == 'UAD') {
                if ($scope.isMaxWidth) {
                    return $scope.dWidth * 0.7
                } else {
                    return ($scope.dHeight - 100) / 2 * slide.width / slide.height
                }
            } else {
                if ($scope.isMaxWidth) {
                    // return $scope.dWidth * 0.9
                    return ''
                } else {
                    var h = slide.arr2 ? slide.width2 / slide.height2 : slide.width / slide.height;
                    return ($scope.dHeight - 100) * (slide.width / slide.height + h)
                }

            }
        };
        $scope.getUlW = function () {
            // vm.reprint=='UAD'?!isMaxWidth?(dHeight-100)/2*document.pages[0].width/document.pages[0].height:dWidth*0.7:dWidth*0.9
            if (vm.reprint == 'UAD') {
                if ($scope.isMaxWidth) {
                    return $scope.dWidth * 0.7
                } else {
                    return ($scope.dHeight - 100) / 2 * $scope.document.pages[0].width / $scope.document.pages[0].height
                }
            } else {
                return $scope.dWidth * 0.9
            }
        };

        $scope.getPageWaHLar = function (slide, flag, marginFlag) {
            if (vm.reprint != 'LAR') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var w = 0;
                if (!marginFlag) {
                    if (!slide.arr2) {
                        return
                    }

                    if ($scope.isMaxWidth) {
                        w = $scope.dWidth * slide.multiple2 * slide.thumbnail2;
                    } else {
                        w = ($scope.dHeight - 100) * slide.width2 / slide.height2 * slide.thumbnail2
                    }

                    slide.arr2.pageScaleW = w / unitTransform(slide.width2);
                } else {
                    if ($scope.isMaxWidth) {
                        w = $scope.dWidth * slide.multiple * slide.thumbnail;
                    } else {
                        w = ($scope.dHeight - 100) * slide.width / slide.height * slide.thumbnail;
                    }

                    slide.arr.pageScaleW = w / unitTransform(slide.width);

                }
                if (marginFlag && $scope.maxW != $scope.minW) {
                    if (slide.spread) {
                        slide.marginLeft = ($scope.dWidth * 0.9 - w) / 2;
                    } else {
                        slide.marginLeft = ($scope.dWidth * 0.45 - w);
                    }
                }
                return w;
            } else {
                var h = 0;
                if (!marginFlag) {
                    if (!slide.arr2) {
                        return
                    }

                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * slide.multiple2 * slide.height2 * slide.thumbnail2 / slide.width2
                    } else {
                        h = $scope.dHeight - 100
                    }

                    slide.arr2.pageScaleH = h / unitTransform(slide.height2);
                } else {
                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * slide.multiple * slide.height * slide.thumbnail / slide.width
                    } else {
                        h = $scope.dHeight - 100
                    }

                    slide.arr.pageScaleH = h / unitTransform(slide.height);
                }
                return h;
            }
        };
        $scope.getPageWaHUad = function (slide, flag) {
            if (vm.reprint != 'UAD') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var w1 = 0, w2 = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        w2 = $scope.dWidth * 0.7 * slide.thumbnail2
                    }
                    else {
                        w2 = ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2
                    }
                    slide.w2 = w2;
                    slide.arr2.w = w2;
                    slide.arr2.pageScaleW = w2 / unitTransform(slide.width2);

                }
                if ($scope.isMaxWidth) {
                    w1 = $scope.dWidth * 0.7 * slide.thumbnail
                } else {
                    w1 = ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail
                }
                slide.w1 = w1;
                slide.arr.w = w1;
                slide.arr.pageScaleW = w1 / unitTransform(slide.width);
            } else {
                var h1 = 0, h2 = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        h2 = $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2;
                    } else {
                        h2 = ($scope.dHeight - 100) / 2;
                    }
                    slide.h2 = h2;
                    slide.arr2.h = h2;
                    slide.arr2.pageScaleH = h2 / unitTransform(slide.height2);

                }
                if ($scope.isMaxWidth) {
                    h1 = $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                } else {
                    h1 = ($scope.dHeight - 100) / 2;
                }
                slide.h1 = h1;
                slide.arr.h = h1;
                slide.arr.pageScaleH = h1 / unitTransform(slide.height);
            }
        };

        $scope.getPageWaHUad1 = function (slide, flag) {
            if (vm.reprint != 'UAD') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var w = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        w = $scope.dWidth * 0.7 * slide.thumbnail2
                    }
                    else {
                        w = ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2
                    }
                    slide.w2 = w;
                    slide.arr2.w = w;
                    slide.arr2.pageScaleW = w / unitTransform(slide.width2);

                } else {
                    if ($scope.isMaxWidth) {
                        w = $scope.dWidth * 0.7 * slide.thumbnail
                    } else {
                        w = ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail
                    }
                    slide.w1 = w;
                    slide.arr.w = w;
                    slide.arr.pageScaleW = w / unitTransform(slide.width);
                }
                // var w = slide.bgImg2 ? !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2 : $scope.dWidth * 0.7 * slide.thumbnail2 : !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail : $scope.dWidth * 0.7 * slide.thumbnail;
                return w;
            } else {
                var h = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2;
                    } else {
                        h = ($scope.dHeight - 100) / 2;
                    }
                    slide.h2 = h;
                    slide.arr2.h = h;
                    slide.arr2.pageScaleH = h / unitTransform(slide.height2);

                } else {
                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                    } else {
                        h = ($scope.dHeight - 100) / 2;
                    }
                    slide.h1 = h;
                    slide.arr.h = h;
                    slide.arr.pageScaleH = h / unitTransform(slide.height);

                }
                // var h = slide.bgImg2 ? !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 : $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2 : !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 : $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                return h;
            }
        };
        $scope.getPageScaleUad = function (slide, flag) {
            if (vm.reprint != 'UAD') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var pageScaleW = 0;
                if (slide.arr2) {
                    slide.arr2.pageScaleW = pageScaleW = slide.arr2.w / unitTransform(slide.width2);

                } else {
                    slide.arr.pageScaleW = pageScaleW = slide.arr.w / unitTransform(slide.width);
                }
                return pageScaleW;
            } else {
                var pageScaleH = 0;
                if (slide.arr2) {
                    slide.arr2.pageScaleH = pageScaleH = slide.arr2.h / unitTransform(slide.height2);

                } else {
                    slide.arr.pageScaleH = pageScaleH = slide.arr.h / unitTransform(slide.height);

                }
                // var h = slide.bgImg2 ? !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 : $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2 : !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 : $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                return pageScaleH;
            }
        };

        $scope.toggle = {
            now: false
        };
        var Page = (function () {
            var pageI = 0;
            var config = {
                    $bookBlock: $('#bb-bookblock'),
                    $navNext: $('#bb-nav-next'),
                    $navPrev: $('#bb-nav-prev'),
                    $slide1: $('#slide1'),
                    $slide2: $('#slide2')
                },
                init = function () {
                    config = {
                        $bookBlock: $('#bb-bookblock'),
                        $navNext: $('#bb-nav-next'),
                        $navPrev: $('#bb-nav-prev'),
                        $slide1: $('#slide1'),
                        $slide2: $('#slide2')
                    };
                    if (vm.reprint == 'LAR') {
                        config.$bookBlock.bookblock({
                            speed: 800,
                            shadowSides: 0.8,
                            shadowFlip: 0.7,
                            len: $scope.document.pages.length
                        });
                    } else {
                        $(".bb-item").addClass('lar-noShadow');
                        config.$bookBlock.bookblock({
                            orientation: 'horizontal',
                            speed: 700
                        });
                    }
                    initEvents();
                },
                initEvents = function () {
                    var $slides = config.$bookBlock.children();

                    // add navigation events
                    config.$navNext.on('click touchstart', function () {
                        config.$bookBlock.bookblock('next');
                        pageI = localStorage.getItem('pageI');
                        nextShow(pageI - 1);
                        return false;
                    });

                    config.$navPrev.on('click touchstart', function () {
                        config.$bookBlock.bookblock('prev');
                        pageI = localStorage.getItem('pageI');
                        prevShow(pageI - 0 + 1);
                        return false;
                    });

                    // add swipe events
                    $slides.on({
                        'click': function (event) {
                            fn(event);
                            var index = event.currentTarget.dataset.index;
                            var page = $scope.document.pages[index];

                            var mar = (allWidth - $(".modal-content").width()) / 2;
                            var w = $(".view-article").width() / 2 + mar;

                            if (vm.reprint == 'LAR') {
                                if (event.clientX > (w)) {
                                    getPageWidth(index - 0 + 1, 'next', config);
                                    nextShow(index);
                                } else {
                                    getPageWidth(index - 1, 'prev', config);
                                    prevShow(index);
                                }
                            } else if (vm.reprint == 'UAD') {
                                // 120px---->bb-bg 上方高度
                                if (event.pageY > 120 + $(".bb-item").eq(index).height() / 2) {
                                    //最后一页的时候禁止点击
                                    if (index == $scope.document.pages.length - 1) {
                                        return
                                    }
                                    config.$bookBlock.bookblock('next');
                                    nextShow(index);
                                } else {
                                    config.$bookBlock.bookblock('prev');
                                    prevShow(index);
                                }

                                if ($scope.document.pages[index].spread == true) {
                                    $(".bb-horizontal .bb-page").css({top: '50.1%'})
                                }
                            }
                            return false;
                        }
                    });
                };

            return {init: init};

        })();

        function nextShow(index) {
            var len = $scope.document.pages.length;
            $(".leftBtn").show();
            if (index - 0 + 1 == len - 1) {
                $(".rightBtn").hide();
            }
        }

        function prevShow(index) {
            $(".rightBtn").show();
            if (index - 1 == 0) {
                $(".leftBtn").hide();
            }
        }

        function getPageWidth(index, attr, config) {
            if (!$scope.isMaxWidth) {
                var page = $scope.document.pages[index];
                var halfWidth = page.arr2 ? page.width2 / page.height2 : page.width / page.height;
                var w = ($scope.dHeight - 100) * (page.width / page.height + halfWidth);
                // console.log(w);
                $li.width(w);
                $("#bb-bookblock").width(w);
            } else {
                $li.width($("#bb-bookblock").width());
            }
            config.$bookBlock.bookblock(attr);
        }

        $scope.backTo = function () {
            $uibModalInstance.dismiss('cancel');

            $(".listBGC").show();
            $(".modal-content").show().removeClass('opacity0');
        };

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
    }
})();
