(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('PayDetailController', PayDetailController);

    PayDetailController.$inject = ['$scope', '$state', 'MessageService', '$uibModalInstance', 'entity'];

    function PayDetailController($scope, $state, MessageService, $uibModalInstance, entity) {
        var vm = this;
        vm.salesOrder = entity;
        vm.clear = clear;
        console.log(entity)

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
