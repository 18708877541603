(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CouponDetailController', CouponDetailController);

    CouponDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Coupon'];

    function CouponDetailController($scope, $rootScope, $stateParams, $uibModalInstance, entity, Coupon) {
        var vm = this;

        vm.coupon = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('backApp:couponUpdate', function(event, result) {
            vm.coupon = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
