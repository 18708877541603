(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('coupon', {
            parent: 'entity',
            url: '/coupon?page&sort&search',
            data: {
                authorities: ["ROLE_STORE_OUPON"],
                pageTitle: 'backApp.coupon.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/coupon/coupons.html',
                    controller: 'CouponController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('coupon');
                    $translatePartialLoader.addPart('couponType');
                    $translatePartialLoader.addPart('sendRule');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('coupon-detail', {
            parent: 'coupon',
            url: '/coupon/{id}',
            data: {
                authorities: ['ROLE_STORE_OUPON']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupon/coupon-detail.html',
                    controller: 'CouponDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('coupon');
                            $translatePartialLoader.addPart('couponType');
                            $translatePartialLoader.addPart('sendRule');
                            return $translate.refresh();
                        }],
                        entity: ['Coupon', function(Coupon) {
                            return Coupon.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('coupon', null, {
                        reload: 'coupon'
                    });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('coupon.setRule', {
            parent: 'coupon',
            url: '/{id}/setRule',
            data: {
                authorities: ['ROLE_STORE_OUPON']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupon/coupon-set-rule.html',
                    controller: 'CouponDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Coupon', function(Coupon) {
                            return Coupon.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('coupon', null, {
                        reload: 'coupon'
                    });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('coupon.new', {
            parent: 'coupon',
            url: '/new',
            data: {
                authorities: ['ROLE_STORE_OUPON']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupon/coupon-dialog.html',
                    controller: 'CouponDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function() {
                            return {
                                name: null,
                                type: null,
                                description: null,
                                basePrice: null,
                                reducePrice: null,
                                startTime: null,
                                endTime: null,
                                enabled: null,
                                sendQuantity: null,
                                usedQuantity: null,
                                totalQuantity: null,
                                sendRule: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('coupon', null, {
                        reload: 'coupon'
                    });
                }, function() {
                    $state.go('coupon');
                });
            }]
        })

        .state('coupon.edit', {
            parent: 'coupon',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_OUPON']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupon/coupon-dialog.html',
                    controller: 'CouponDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Coupon', function(Coupon) {
                            return Coupon.get({
                                id: $stateParams.id
                            }).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('coupon', null, {
                        reload: 'coupon'
                    });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
