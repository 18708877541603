(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', '$timeout', 'UidService', 'StoreUser', 'Qinius', 'Store', '$scope', '$state', 'MessageService', 'VerifyCode',
        '$q', '$interval', '$uibModal', '$localStorage', 'Auth', '$rootScope','PicManagements'];

    function RegisterController($translate, $timeout, UidService, StoreUser, Qinius, Store, $scope, $state, MessageService, VerifyCode,
                                $q, $interval, $uibModal, $localStorage, Auth, $rootScope,PicManagements) {
        var vm = this;

        vm.domains = Qinius.getDomain();
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.register = register;
        vm.store = {};
        vm.store.storeType = "Person";
        vm.store.incident = "新用户注册";
        vm.success = null;
        $(".wrap").hide();
        vm.agree = false;
        vm.formInput=null;
        vm.isSaving = false;
        vm.pic = PicManagements.getAllByCondition({items:[{key:"pageSite", op :"=", value:'register'}],sort: ["seq,asc"]});
        $q.all([vm.domains.$promise,vm.pic.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if(vm.pic && vm.pic.length>0){
                $timeout(function () {
                    new Swiper('.swiper-container', {
                        spaceBetween: 20,
                        autoplay:{
                            disableOnInteraction: false,
                        }
                    });
                },1000)
            }
        })

        $scope.formInputFocus = function (item) {
            vm.formInput=item;
        }
        $scope.formInputBlur = function () {
            vm.formInput=null;
        }

        $timeout(function () {
            angular.element('#name').focus();
        });

        var createModal = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };

        //用户协议
        $scope.tempInfo = function (temp) {
            createModal('app/account/register/register_info.html', 'RegisterInfoController').result.then(
                function (data) {
                    if(data){
                        vm.agree = true;
                    }else {
                        vm.agree = false;
                    }
                });
        };

        $scope.validate = function () {
            if (vm.store.name == null || vm.store.name == "") {
                MessageService.error("请输入商店名称");
                return false;
            }
            ;

            if (vm.store.storeType == nu || vm.store.storeType == "") {
                MessageService.error("请选择注册者主体类型");
            }
            ;
            return true;
        }

        function register() {
            if (vm.store.phone == null || vm.store.phone == '') {
                MessageService.error("请输入正确的手机号码");
                return
            }
            if (vm.store.verifyCode == null || vm.store.verifyCode == '') {
                MessageService.error("请输入正确的验证码");
                return
            }

            if (vm.password !== vm.confirmPassword) {
                MessageService.error("两次密码输入不一致。");
                return
            }

            VerifyCode.check({
                phone: vm.store.phone,
                code: vm.store.verifyCode,
            }, function (msg) {
                if (msg.status == 200 && msg.message == true) {
                    vm.store.langKey = $translate.use();
                    vm.doNotMatch = null;
                    vm.error = null;
                    vm.errorUserExists = null;
                    vm.errorEmailExists = null;
                    checkUser(function () {
                        vm.store.roleId = '2';
                        vm.store.activated = 0;
                        vm.isSaving = true;
                        vm.store.userName = vm.userName;
                        vm.store.password = vm.password;
                        Store.create(vm.store, function (response) {
                            vm.store = response;
                            if(response.initialized){
                                if (!response.activated) {
                                    MessageService.info("请等待平台管理员审核");
                                    $state.go('home');
                                    vm.isSaving = false;
                                    return;
                                }
                            }
                            login()
                        }, function (response) {
                            vm.success = null;
                            if (response.status === 400 && response.data === 'login already in use') {
                                vm.errorUserExists = 'ERROR';
                            } else if (response.status === 400 && response.data === 'email address already in use') {
                                vm.errorEmailExists = 'ERROR';
                            } else {
                                vm.error = 'ERROR';
                            }
                            vm.isSaving = false;
                        });
                    })
                } else {
                    MessageService.error("验证码输入有误");
                }
            }, function () {
                MessageService.error("验证码输入有误");
            });
        }

        function checkUser(callback) {
            StoreUser.checkUser2({
                phone: vm.store.phone,
                username: vm.userName,
            }, function (data) {
                if (!data.message) {
                    MessageService.error("该手机号或者用户名已存在");
                } else {
                    callback()
                }
            }, function () {
                MessageService.error("校验用户名时出错");
            });
        }

        $scope.paracont = "获取验证码";
        vm.disabled = false;
        //验证码的定时器
        var timer = null;
        $scope.getVerifyCode = function () {
            if (vm.store.phone == null || vm.store.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.store.phone.length != 11) {
                MessageService.error("请输入11位的手机号");
                return;
            }
            if (vm.disabled) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            VerifyCode.getVerifyCode2({
                phone: vm.store.phone,
                incident: "新用户注册"
            }, function (msg) {
                if(msg.status == 400){
                    MessageService.error(msg.message || "发送短信失败,请稍后重试");
                    return
                }
                clearInterval(timer);
                timer = $interval(function () {
                    if (vm.countdown > 0) {
                        vm.disabled = true;
                        vm.countdown--;
                        $scope.paracont = "重新发送(" + vm.countdown + ")";
                    } else {
                        vm.disabled = false;
                        $interval.cancel(timer);
                        $scope.paracont = "重发验证码";
                    }
                }, 1000);
            });
        };

        function login() {
            Auth.login({
                username: vm.userName,
                password: vm.password,
                rememberMe: true
            }).then(function () {
                vm.isSaving = false;
                $(".wrap").show().css({margin: '48px 10px 10px 230px'});
                delete $localStorage.menu1;
                delete $localStorage.menu2;
                delete $localStorage.pageName;
                $rootScope.$broadcast('authenticationSuccess');
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
                $state.go("register2");
            }).catch(function (error) {
                if (error && error.data && error.data["com.deltazo.westeros.security.UserNotActivatedException"]) {
                    MessageService.error("用户未激活");
                }else{
                    MessageService.error("用户名或密码不正确");
                }
                vm.isSaving = false;
            });
        }

        $scope.agreeRegister = function () {
            vm.agree=!vm.agree;
        }
        //使用条款
        $scope.clause = function (data) {
            $uibModal.open({
                templateUrl: 'app/home/clause-modal.html',
                controller: 'ClauseModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            })
        }
    }
})();
