
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('EvaluateController', EvaluateController);

    EvaluateController.$inject = ['$scope', '$state','$stateParams','Principal', 'MessageService', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Evaluate'];

    function EvaluateController($scope, $state,$stateParams,Principal, MessageService, ParseLinks, paginationConstants, pagingParams, Evaluate) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.loadPage = loadPage;
        vm.changeStatue = changeStatue;
        vm.delete = del;
        vm.jump = jump;
        vm.refused = refused;
        vm.approval = approval;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.statue = pagingParams.statue;

        vm.checkboxFlag = checkboxFlag;
        vm.idss = [];
        loadAll();
        function loadAll () {
            var items=[];
            if (vm.statue !=null && vm.statue ==1) {
                items.push({key:"enabled",op:"=",value:'true'});
                items.push({key:"refused",op:"=",value:'false'});
            }else if(vm.statue !=null && vm.statue ==2){
                items.push({key:"enabled",op:"=",value:'true'});
                items.push({key:"refused",op:"=",value:'true'});
            };
            if (pagingParams.search) {
                items.push({key:"content",op:"like",value:pagingParams.search});
                Evaluate.byCondition({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Evaluate.byCondition({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                // var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                // if (vm.predicate !== 'id') {
                //     result.push('id');
                // }
                // console.log(result);
                var result = ["enabled, asc"];
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.evaluates = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("评论列表获取失败");
            }
        };

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;

            vm.statue = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
        function changeStatue (status){
            vm.statue = status;
            loadAll();
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                statue:vm.statue
            });
        }
        function del (id) {
            var ids = [];
            ids.push(id);
            console.log(ids);
            Evaluate.update({
                ids: ids,
                type: 0
            }, function(data){
                loadAll();
                MessageService.success("操作完成");
            }, function(error){
                MessageService.error("操作失败");
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function refused () {
            if (vm.idss.length > 0) {
                Evaluate.update({
                    ids: vm.idss,
                    type: 2
                }, function(data){
                    loadAll();
                    MessageService.success("操作完成");
                }, function(error){
                    MessageService.error("操作失败");
                });
            } else {
                MessageService.error("没有勾选项");
            }
        };

        function approval () {
            console.log(vm.idss);
            if (vm.idss.length > 0) {
                Evaluate.update({
                    ids: vm.idss,
                    type: 1
                }, function(data){
                    loadAll();
                    MessageService.success("操作完成");
                }, function(error){
                    MessageService.error("操作失败");
                });
            } else {
                MessageService.error("没有勾选项");
            }
        };

        // 处理复选框结果数据
        function checkboxFlag(evaluate){
            console.log(evaluate);
            if(evaluate.cancelled == false && evaluate.enabled == false &&  evaluate.refused == false ){
                var flag = $("#"+evaluate.id).prop("checked");
                if(flag){
                    vm.idss.push(evaluate.id)
                } else {
                    var index = vm.idss.indexOf(evaluate.id);
                    vm.idss.splice(index, 1);
                }
            }else{
                $("#"+evaluate.id).prop("checked",false);
                MessageService.error("该选项已经审核过");
            }

        }
    }
})();
