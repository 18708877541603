(function(){
    'user strict';
    angular.module('backApp')
    .directive('fileread', fileread);

    function fileread() {
        var directive = {
            restrict: 'A',
            scope: false,
            link: linkFunc
        };
        return directive;
        function linkFunc(scope, element, attrs, parentCtrl) {
            element.bind("change", function(changeEvent) {
                scope.$parent.upLoading = true;
                scope.uploadFile(changeEvent.target.files[0], changeEvent.target.id);
            });
        }
    }
})();


