(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreThirdPageController', StoreThirdPageController);

    StoreThirdPageController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'PlatformAbutment'];

    function StoreThirdPageController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, PlatformAbutment) {
        var vm = this;
        vm.store = Store.getCurrent();
        init();
        function init(){
            vm.abutments = PlatformAbutment.getAll({items:[]});
        }

        $scope.delete =function(abutment){
            MessageService.confirm("确认要删除该数据", function(){
                PlatformAbutment.del({id:abutment.id},function(data){
                    MessageService.success("删除成功");
                    init();
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){

            })
        }
        $scope.platform = function (item){
            switch (item) {
                case 'RRD':
                    return '人人店接口';
                case 'DY':
                    return '抖店接口';
                case 'WLN':
                    return '万里牛接口';
                case 'WDT':
                    return '旺店通接口';
                default:
                    return item
            }
        }
    }
})();
