(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MessageNotificationDialogController', MessageNotificationDialogController);

    MessageNotificationDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'MessageNotification', 'MessageService', '$state','Store'];

    function MessageNotificationDialogController ($timeout, $scope, $stateParams, entity, MessageNotification, MessageService, $state,Store) {
        var vm = this;
        vm.store = Store.getCurrent();
       vm.messageNotification = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go("message-notification", {reload:true})
        }

        $scope.validata = function(){
            if (vm.messageNotification.content == null || vm.messageNotification.content == "") {
                MessageService.error("请输入通知内容")
                return false;
            }
            if (vm.messageNotification.seq == null || vm.messageNotification.seq == "") {
                MessageService.error("请输入排序")
                return false;
            }
            if (vm.messageNotification.pageType == null || vm.messageNotification.pageType == "") {
                MessageService.error("请选择展示页面")
                return false;
            }
            if (vm.messageNotification.validate == null || vm.messageNotification.validate == "") {
                MessageService.error("请选择有效日期")
                return false;
            }
            return true;
        }

        function save () {
            if (!$scope.validata()) {
                return;
            }
            vm.isSaving = true;
            if (vm.messageNotification.id !== null) {
                MessageNotification.update(vm.messageNotification, onSaveSuccess, onSaveError);
            } else {
                MessageNotification.createByBack(vm.messageNotification, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:messageNotificationUpdate', result);
            $state.go("message-notification", {reload:true})
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.validate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
