(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('PriceListDialogConditionController', PriceListDialogConditionController);
    PriceListDialogConditionController.$inject = ['$scope', '$uibModalInstance', 'MessageService', '$stateParams', 'entity'];

    function PriceListDialogConditionController( $scope, $uibModalInstance, MessageService, $stateParams, entity) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.line = entity;
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.addPricecondition = function(){
            var data = {
                added:true,
            }
            if (vm.line.priceConditions == null) {
                vm.line.priceConditions = [];
            }
            vm.line.priceConditions.push(data);
        }

        $scope.delete = function(condition){
            if (condition.added) {
                var ind = vm.line.priceConditions.indexOf(condition);
                if (ind >= 0) {
                    vm.line.priceConditions.splice(ind, 1);
                }
            }else{
                condition.deleted = true;
            }
        }

        $scope.validate = function(){
            if(vm.line.priceConditions&&vm.line.priceConditions.length){
                vm.line.priceConditions.sort(compare('startQuantity'))
            }else{
                MessageService.error("请添加价格行");
                return false;
            }
            for (var i = vm.line.priceConditions.length - 1; i >= 0; i--) {
                var cond =  vm.line.priceConditions[i];
                if (!cond.startQuantity) {
                    MessageService.error("请录入起始数量");
                    return false;
                }
                if (!cond.endQuantity) {
                    MessageService.error("请录入结束数量");
                    return false;
                }
                if (cond.priceType == null) {
                    MessageService.error("请选择价格类型")
                    return false;
                }

                if (cond.price == null) {
                    MessageService.error("请录入价格")
                    return false;
                }
                if (vm.line.lowestPrice!=null&&cond.price < vm.line.lowestPrice) {
                    MessageService.error("价格不能小于最低成交价 ￥"+vm.line.lowestPrice)
                    return false;
                }
             }
            return true;
        }

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        function save() {
            if (!$scope.validate()) {
                return;
            }
            var _priceConditions = [];
            vm.line.priceConditions.forEach(function (item) {
                if(!item.deleted && !item.cancelled){
                    _priceConditions.push(item)
                }
            })
            for (var i = 0; i < _priceConditions.length-1; i++) {
                var cond =  _priceConditions[i];
                var cond2 = _priceConditions[i+1];
                if(cond.endQuantity+1!=cond2.startQuantity){
                    MessageService.error("请录入正确的阶梯数量")
                    return
                }
            }
            vm.line.priceConditions = _priceConditions;

            $uibModalInstance.close(vm.line);
        }
    }

})();
