
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InvoiceLocationController', InvoiceLocationController);

    InvoiceLocationController.$inject = ['$q', '$scope', '$state','$stateParams', 'InvoiceLocation',  'MessageService', 'Principal'];

    function InvoiceLocationController($q, $scope, $state, $stateParams, InvoiceLocation,  MessageService, Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_INVOICE_EXPRESS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.del = del;
        vm.changeStatue = changeStatue;

        function loadAll () {
            InvoiceLocation.getAll({}, function(data){
                vm.ilocations = data;
            });
        };

        vm.ilocations = InvoiceLocation.getAll({});
        $q.all([vm.ilocations.$promise]).then(function() {
        }); 


        function changeStatue (obj){
            if (obj.enable) {
                obj.enable = false;
            } else {
                obj.enable = true;
            }
            console.log(obj);
            InvoiceLocation.update(obj, function(){
                $state.go('invoice-location', null, { reload: true});
            })
        };

        function del (id) {
            console.log(id);
            InvoiceLocation.del({id: id}, function(){
                loadAll();
            })
        }
    }
})();
