(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('font-management', {
            parent: 'entity',
            url: '/font-management?page&sort&search',
            data: {
                authorities: ["ROLE_FONT_MANAGEMENT"],
                pageTitle: 'editorApp.fontManagement.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/font-management/font-managements.html',
                    controller: 'FontManagementController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fontManagement');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('font-management-detail', {
            parent: 'font-management',
            url: '/font-management/{id}',
            data: {
                authorities: ["ROLE_FONT_MANAGEMENT"],
                pageTitle: 'editorApp.fontManagement.detail.title'
            },
            onEnter:['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
                $uibModal.open({
                    templateUrl: 'app/entities/font-management/font-management-detail.html',
                    controller: 'FontManagementDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        
                        entity: ['FontManagement', function(FontManagement) {
                            return FontManagement.get({id : $stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fontManagement');
                            $translatePartialLoader.addPart('style');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('font-management', null, { reload: 'font-management' });
                }, function() {
                    $state.go('font-management');
                });
            }] 
        })
         
        .state('font-management.new', {
            parent: 'font-management',
            url: '/new',
            data: {
                authorities: ["ROLE_FONT_MANAGEMENT"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/font-management/font-management-dialog.html',
                    controller: 'FontManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                family: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('font-management', null, { reload: 'font-management' });
                }, function() {
                    $state.go('font-management');
                });
            }]
        })
        .state('font-management.edit', {
            parent: 'font-management',
            url: '/{id}/edit',
            data: {
                authorities: ["ROLE_FONT_MANAGEMENT"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/font-management/font-management-dialog.html',
                    controller: 'FontManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['FontManagement', function(FontManagement) {
                            return FontManagement.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('font-management', null, { reload: 'font-management' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         ;
    }

})();
