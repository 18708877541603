(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountPaymentModeController', OpenAccountPaymentModeController);

    OpenAccountPaymentModeController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', '$http', 'FontManagement', '$q', 'Store'];

    function OpenAccountPaymentModeController($state, $scope, $timeout, Qinius, $uibModal, $http, FontManagement, $q, Store) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.data = false;
        vm.store = Store.getCurrent();
        $q.all([vm.store.$promise]).then(function(){
            if(vm.store.activated){
                $state.go('openAccount-commercial');
            }
        })

        $scope.save = function () {
            if(vm.data){
                $state.go('openAccount-secretKey');
            }else{
                $state.go('openAccount-versions');
            }
        }
    }

})();
