(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductTitleController', ProductTitleController);

    ProductTitleController.$inject = ['$scope', '$state', 'ProductTitle', 'MessageService', 'pagingParams', 'paginationConstants', 'ParseLinks',
     'ProductTitleConfig', 'Principal'];

    function ProductTitleController ($scope, $state, ProductTitle, MessageService, pagingParams, paginationConstants, ParseLinks,
        ProductTitleConfig, Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_PRODUCT_TITLE').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        loadAll();

        // $scope.startUsing = function(){
        //      ProductTitleConfig.update(vm.productTitleConfig, function(data){
        //         vm.productTitleConfig = data;
        //     }, function(data){
        //         MessageService.error("数据请求失败");
        //     })
        // }

        function loadAll () {

            // ProductTitleConfig.storeId({}, function(data){
            //     vm.productTitleConfig = data;
            // }, function(data){
            //     MessageService.error("数据请求失败");
            // })

            var items  =[];
            if (pagingParams.search) {
                items.push({key:"title", op:"like", value:pagingParams.search});
            }
            ProductTitle.byCondition({
                page: pagingParams.page - 1,
                items:items,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productTitles = data;
                vm.page = pagingParams.page;
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
            }
            function onError(error) {
                MessageService.error("数据请求失败");
            }
        }

        $scope.showOrNotShow = function(productTitle){
            ProductTitle.showOrNotShow({id:productTitle.id}, function(data){
                loadAll();
            }, function(data){
                MessageService.error("数据请求失败");
            })
        }

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                ProductTitle.delete({id: id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

    }
})();
