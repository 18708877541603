(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StoreWithdrawalSetting', StoreWithdrawalSetting);

    StoreWithdrawalSetting.$inject = ['$resource'];

    function StoreWithdrawalSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/store-withdrawal-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'byStoreId': { 
                params:{
                    path:"byStoreId"
                },
                method: 'GET',
            },
             
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
