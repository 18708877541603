(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('exchange-history', {
                parent: 'branch-stores-customer',
                url: '/branch-stores-customer/{id}',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customers-history-dialog.html',
                        controller: 'CustomersHistoryDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }],
                            entity: ['Customer', function(Customer) {
                                return Customer.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                       window.history.back();
                    }, function() {
                        window.history.back();
                    });
                }]
            });
             
    }

})();
