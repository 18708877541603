(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('RegisterInfoController', RegisterInfoController);


    RegisterInfoController.$inject = ['$uibModalInstance'];

    function RegisterInfoController($uibModalInstance) {
        var vm = this;

        vm.save = save;
        vm.clear = clear;

        function save() {
            $uibModalInstance.close(true);
        }

        function clear() {
            $uibModalInstance.close(false);
        }
    }
})();
