(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreTikTokSettingController', StoreTikTokSettingController);

    StoreTikTokSettingController.$inject = ['$scope', '$timeout', '$q' ,'Store', '$stateParams', 'MessageService', '$localStorage', 'StoreByteDances', '$http'];

    function StoreTikTokSettingController($scope, $timeout, $q, Store, $stateParams, MessageService, $localStorage, StoreByteDances, $http) {
        var vm = this;
        vm.store = Store.getCurrent();
        init();
        function init(){
            StoreByteDances.storeGetOne({},function (res) {
                if(res.status == 400){
                    MessageService.error(res.message);
                    return
                }
                vm.data = res ? res : {};
            });
        }
        $scope.save = function () {
            if(!vm.data.authorizerAppId){
                MessageService.error("appId不能为空");
                return
            }
            if(!vm.data.authorizerSecret){
                MessageService.error("appSecret不能为空");
                return
            }
            if(vm.data.id){
                StoreByteDances.update(vm.data, function (res) {
                    MessageService.success("设置成功");
                    init();
                })
            }else{
                StoreByteDances.save(vm.data, function (res) {
                    MessageService.success("设置成功");
                    init();
                })
            }
        }

        $scope.downloadByteDanceQrCode = function(){
            $http({
                url: 'fileserves/api/download/downloadByteDanceQrCode?storeId=' + vm.store.id,
                data:{
                    storeId: vm.store.id
                },
                method: "GET",
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/octet-stream;charset=ISO8859-1"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "商户字节小程序二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);

            }).error(function (data, status, headers, config) {
            });
        }
    }
})();
