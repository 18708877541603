(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountPackageController', OpenAccountPackageController);

    OpenAccountPackageController.$inject = ['$state', '$scope', 'MessageService', '$timeout', '$uibModal', '$http', 'Store', '$q','PackageVersion'];

    function OpenAccountPackageController($state, $scope, MessageService, $timeout, $uibModal, $http, Store, $q, PackageVersion) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        vm.id=null;
        $q.all([vm.store.$promise]).then(function(){
            if(vm.store.activated){
                $state.go('openAccount-commercial');
            }
            PackageVersion.getByPort({ports:vm.store.storeType2}, function (res) {
                vm.data = res;
                $timeout(function () {
                    new Swiper('.swiper-container', {
                        slidesPerView: 3,
                        spaceBetween : 20,
                        pagination: {
                            el: '.swiper-pagination',
                            dynamicBullets: true,
                        },
                    });
                },1000)
            })
        })
        $scope.selectC = function(item){
            vm.id = item.id;
        }
        $scope.save = function () {
            if(!vm.id){
                MessageService.error('请选择套餐');
                return
            }
            $state.go('openAccount-modePayment',{id: vm.id});
        }
    }

})();
