(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PricingController', PricingController);

    PricingController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Category', 'PaginationUtil', 'Tariffs'];

    function PricingController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, Category, PaginationUtil, Tariffs) {
        var vm = this;
        vm.commodityPrice = entity.data;
        vm.storeId = entity.storeId
        vm.cancel = cancel;
        vm.products = [];
        vm.pricingStrategy = [
            {name: '标准定价', id: 'Standard'},
            {name: '阶梯定价', id: 'Ladder'},
            // {name: '梯度定价', id: 'Grads'},
        ];
        vm.conditions = [
            {name: '数量', id: 'Quantity'},
            // {name: '价格', id: 'Price'}
        ];
        vm.priceTypes = [
            {name: '单价', id: 'Price'},
            {name: '一口价', id: 'Fixed'}
        ];

        Tariffs.findByItemValueLineId({
            itemValueLineId: vm.commodityPrice.itemValueLineId,
            storeId: vm.storeId
        }, function (res) {
            vm.pricing = res;
        })

        //返回
        function cancel() {
            $uibModalInstance.close();
        }


        vm.priceRuleStatus = priceRuleStatus;
        function priceRuleStatus(target) {
            switch (target) {
                case "Standard":
                    return "标准定价";
                case "Ladder":
                    return "阶梯定价";
                case "Grads":
                    return "梯度定价";
                default:
                    return "";
            }
        }
        vm.conditionsStatus = conditionsStatus;
        function conditionsStatus(target) {
            switch (target) {
                case "Quantity":
                    return "数量";
                default:
                    return "";
            }
        }
        vm.priceTypeStatus = priceTypeStatus;
        function priceTypeStatus(target) {
            switch (target) {
                case "Price":
                    return "单价";
                case "Fixed":
                    return "一口价";
                default:
                    return "";
            }
        }
    }
})();
