(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StoreSmallAppMessageTemplate', StoreSmallAppMessageTemplate);

    StoreSmallAppMessageTemplate.$inject = ['$resource'];

    function StoreSmallAppMessageTemplate ($resource) {
        var resourceUrl =  'manager/' + 'api/store-small-app-message-templates/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getWechatTemplateList":{
                method:"GET",
                params:{
                    path:"getWechatTemplateList"
                },
            },
            "getSmallAppTemplateList":{
                method:"GET",
                params:{
                    path:"getSmallAppTemplateList"
                },
            },
            'update': { method:'PUT' }
        });
    }
})();
