(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('NavigationSettingDialogController', NavigationSettingDialogController);

    NavigationSettingDialogController.$inject = ['$q', '$timeout','UidService', '$scope', 'MessageService', 'entity', '$stateParams', '$state', 'Qinius', 'StoreStyleSetting' ,'Store',
    'ListService', 'PasswordMarketing', 'PlatformWechatSetting'];

    function NavigationSettingDialogController ($q, $timeout,UidService, $scope, MessageService, entity, $stateParams, $state, Qinius, StoreStyleSetting,Store,
        ListService, PasswordMarketing, PlatformWechatSetting) {
        var vm = this;

        vm.store = Store.getCurrent();
        vm.navigationSettings = entity.navigationSettings||[];
        vm.navigationSetting = {};
        vm.index = vm.navigationSettings.length;
        vm.marketing = PasswordMarketing.getOne({type:"GroupBuy"});
        vm.platformWechatSetting = PlatformWechatSetting.get({})
        angular.forEach(vm.navigationSettings,function (nav,index) {
            if(nav.id==$stateParams.id){
                vm.navigationSetting = nav;
                vm.index = index;
            }
        })
        vm.clear = clear;
        vm.save = save;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        // product------/store/#/{storeId}/{pid}/product
        // "风格一：category------/store/#/{storeId}/category
        // 风格二：categoryTwo------/store/#/{storeId}/categoryTwo
        // 风格三：categoryThree------/store/#/{storeId}/categoryThree"
        // "风格一：customer------/store/#/{storeId}/customer
        // 风格二（三）：customerTwo------/store/#/{storeId}/customerTwo"
        // article------/store/#/{storeId}/article?page&sort
        //     gallery------/editor/#/{storeId}/gallery
        // redeem-code------/store/#/{storeId}/redeem-code
        // redeem-code------/store/#/{storeId}/redeem-code
        // redeem-code------/store/#/{storeId}/redeem-code
        // redeem-code------/store/#/{storeId}/redeem-code
        // cart------/store/#/{storeId}/cart
        // "afterList------/store/#/{storeId}/customer/{pageType}/afterList
        // pageType:
        //     全部订单:3
        // 处理中:1
        // 已完成:2"
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.store.$promise,vm.platformWechatSetting.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            var edtiorUrl = vm.platformWechatSetting.editorNotifyUrl+'/#/';
            var storeUrl = vm.platformWechatSetting.storeNotifyUrl+'/#/';
            vm.pathArr = [
                {id:0,name:'首页',pathSmallApp:'pages/home/startPage/startPage',pathWechat:storeUrl+vm.store.id+(vm.store.skin=='two'?'/2':'')},
                {id:1,name:'指定商品详情页',pathSmallApp:'pages/home/productIntroduce/productIntroduce?jsonStr={"id":1}&fromType=startPage',pathWechat:storeUrl+vm.store.id+'/{pid}/product'},
                {id:2,name:'分类列表界面',pathSmallApp:'pages/category/categoryListPage/categoryListPage',pathWechat:storeUrl+vm.store.id+(vm.store.skin=='two'?'/categoryTwo':'/category')},
                {id:3,name:'个人中心页',pathSmallApp:'pages/information/myInformation/myInformation',pathWechat:storeUrl+vm.store.id+(vm.store.skin=='two'?'/customerTwo':'/customer')},
                {id:4,name:'作品列表页',pathSmallApp:'pages/information/myProductList/myProductList',pathWechat:storeUrl+vm.store.id+'/article'},
                {id:5,name:'云相册界面',pathSmallApp:'pages/information/photoGallery/photoGallery',pathWechat:edtiorUrl+vm.store.id+'/gallery'},
                {id:6,name:'我的卡包界面',pathSmallApp:'pages/portfolio/myCard/myCard',pathWechat:storeUrl+vm.store.id+'/redeem-code'},
                {id:7,name:'购物车界面',pathSmallApp:'pages/shop/shoppingCar/shoppingCar',pathWechat:storeUrl+vm.store.id+'/cart'},
                {id:8,name:'售后服务列表界面',pathSmallApp:'pages/information/afterServiceList/afterServiceList',pathWechat:storeUrl+vm.store.id+'/customer/1/afterList'}
            ]
            if (vm.marketing.id) {
                vm.pathArr.push({id:9,name:'拼团',pathSmallApp:'pages/group/groupAll/groupAll',pathWechat:storeUrl+vm.store.id+'/group-buy'});
            }
            console.log(vm.pathArr)

            if(vm.navigationSetting.pathSmallApp){
                angular.forEach(vm.pathArr,function (arr) {
                    if(arr.pathSmallApp==vm.navigationSetting.pathSmallApp){
                        arr.choose = true;
                    }
                })
            }

            if(!vm.navigationSetting.colorUnselected){
                vm.navigationSetting.colorUnselected = '#B0B0B0';
            }
            if(!vm.navigationSetting.colorSelected){
                vm.navigationSetting.colorSelected = '#f0ad4e';
            }
        })

        $scope.selectPath = function (path) {
            path.choose = !path.choose;
            angular.forEach(vm.pathArr,function (arr) {
                if(path.id!=arr.id){
                    arr.choose = false;
                }
            })
            if(path.choose){
                vm.navigationSetting.pathSmallApp = path.pathSmallApp;
                vm.navigationSetting.pathWechat = path.pathWechat;
            }
        }


        //iconSelected更改
        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                updateImg(file,'iconSelected')
            });
        };

        //iconUnselected更改
        window.updateImgFile2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                updateImg(file,'iconUnselected')
            });
        };

        function updateImg(file,attr) {
            var getBase64 = function (file, callback) {
                var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                reader.onload = function () {       //  成功读取
                    $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                };
                reader.onerror = function (error) {     //  出错
                };
            };
            getBase64(file, function (result) {
            });
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'nav-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    console.log(data);
                    vm.navigationSetting[attr] = $scope.domain+data.key;
                },
                error: function (request) {
                    MessageService.error("文件上传失败");
                }
            });
        }

        $scope.removeFile = function(attr) {
            vm.navigationSetting[attr] = null;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('navigation-setting', null, { reload: 'navigation-setting' });
        }

        function save () {
            console.log(vm.navigationSetting)
            vm.navigationSetting.storeStyleSettingId =entity.id;
            vm.navigationSetting.storeId =vm.store.id;
            entity.navigationSettings[vm.index] = vm.navigationSetting;
            vm.isSaving = true;
            StoreStyleSetting.update(entity, onSaveSuccess, onSaveError);
            // return
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:navigationSettingUpdate', result);
            $state.go('navigation-setting', null, { reload: 'navigation-setting' });
            // $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
