(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Producer', Producer)
        .factory('ProducerManagement', ProducerManagement);

    Producer.$inject = ['$resource'];
    ProducerManagement.$inject = ['$resource'];

    function Producer ($resource) {
        var resourceUrl =  'security/' + 'api/producers/:path/:id';

        return $resource(resourceUrl, {}, {
            'resetPassword':{
                method:"GET",
                params:{
                    path:"resetPassword"
                }
            },
            'byCondition':{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray: true
            },
            'getAllByCondition':{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function ProducerManagement ($resource) {
        var resourceUrl =  'manager/' + 'api/producer-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            'findByProducerId':{
                method:"GET",
                params:{
                    path:"findByProducerId"
                }
            },
            'getAllByCondition':{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
