(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PropertyDialogController', PropertyDialogController);

    PropertyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Property', 'MessageService'];

    function PropertyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Property, MessageService ) {
        var vm = this;

        vm.property = entity;
        vm.clear = clear;
        // vm.save = save;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if(vm.property.name == null || vm.property.name==""){
                MessageService.error("请输入属性名称");
                return false;
            }
            return true;
        };

        $scope.addValue = function(){
            var value = {id:null,value:null,cancelled:false,added:true}
            if (vm.property.propertyValues == null) {
                vm.property.propertyValues = [];
            }
            vm.property.propertyValues.push(value);
        };

        $scope.deleteValue = function(value){
            if(value == null || vm.property.propertyValues == null || vm.property.propertyValues.length == 0){
                return;
            }
            if(value.added){
                var inde1 = vm.property.propertyValues.indexOf(value);
                vm.property.propertyValues.splice(inde1,1);
            }else{
                value.cancelled= true;
            }
        };


        window.save = function() {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.property.id !== null) {
                Property.update(vm.property, onSaveSuccess, onSaveError);
            } else {
                Property.save(vm.property, onSaveSuccess, onSaveError);
            }
        };

        function onSaveSuccess (result) {
            $scope.$emit('backApp:propertyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }


        window.addPropertys=function(event){
            var e = event || window.event;
            if(e && e.keyCode==13){ // enter 键
                var value = {id:null,value:e.target.value,cancelled:false,added:true};
                if (vm.property.propertyValues == null) {
                    vm.property.propertyValues = [];
                }
                vm.property.propertyValues.push(value);
                $("#field_value").val('');
            }
        };

    }
})();
