(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('JointDialogController', JointDialogController);

    JointDialogController.$inject = ['$state', 'ParseLinks', 'paginationConstants', 'MessageService', '$scope', '$uibModal', 'Store'];

    function JointDialogController($state, ParseLinks, paginationConstants, MessageService, $scope, $uibModal, Store) {
        var vm = this;
        vm.data = Store.getCurrent();

        //保存
        $scope.save = function () {
            if(!vm.data.productXmlUrl){
                MessageService.error("模板工具回调路径不能为空");
                return
            }
            if(!vm.data.productXmlKey){
                MessageService.error("模板工具key不能为空");
                return
            }
            Store.update(vm.data,  function (res) {
                MessageService.success("保存成功");
                $state.go('joint');
            },  function (error) {
                MessageService.error("保存失败");
            });
        }
    }
})();
