(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepot2Controller', MapDepot2Controller)

    MapDepot2Controller.$inject = ['$scope', '$state', 'Store', 'MessageService', '$timeout', 'StoragePayOrders', '$q', '$uibModalInstance','entity','StoragePrices1','$http','handlePackage'];

    function MapDepot2Controller($scope, $state, Store, MessageService, $timeout, StoragePayOrders, $q, $uibModalInstance, entity,StoragePrices1,$http,handlePackage) {
        var vm = this;
        vm.data =entity;

        vm.save = function(){
            if(!vm.data.seq){
                MessageService.error("请输入排序");
                return
            }
            if(!vm.data.space){
                MessageService.error("请输入扩容量");
                return
            }
            if(!vm.data.originalPrice){
                MessageService.error("请输入原价");
                return
            }
            if(!vm.data.price){
                MessageService.error("请输入现价");
                return
            }
            if(!vm.data.periodOfValidity){
                MessageService.error("请输入有效期");
                return
            }
            // 大于0
            if(vm.data.seq<=0){
                MessageService.error("排序不能小于0");
                return
            }
            if(vm.data.space<=0){
                MessageService.error("扩容量不能小于0");
                return
            }
            if(vm.data.originalPrice<=0){
                MessageService.error("原价不能小于0");
                return
            }
            if(vm.data.price<=0){
                MessageService.error("现价不能小于0");
                return
            }
            if(vm.data.periodOfValidity<=0){
                MessageService.error("有效期不能小于0");
                return
            }
            vm.data.space=vm.data.space*1000*1000*1000
            vm.isSaving = true;
            if (vm.data.id != null) {
                handlePackage.updatePackage(vm.data, onSaveSuccess, onSaveError);
            } else {
                handlePackage.CreatePackage(vm.data, onSaveSuccess, onSaveError);
            }
            function onSaveSuccess (result) {
                MessageService.success("套餐保存成功");
                $uibModalInstance.close(result);
                vm.isSaving = false;
            };

            function onSaveError () {
                MessageService.error("套餐保存失败");
                vm.isSaving = false;
            };
        }
        //返回
        $scope.cancel = function() {
            $uibModalInstance.close(false);
        }

    }
})();
