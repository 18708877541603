(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('home-page-style', {
                parent: 'theme-style',
                url: '/home-page-style',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"],
                    pageTitle: 'global.menu.entities.storeStyleSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/home-page-style/home-page-style.html',
                        controller: 'HomePageStyleController',
                        controllerAs: 'vm'
                    }
                },
            })
        .state('home-page-style-edit', {
                parent: '',
                url: '/home-page-style-edit/{id}',
                data: {
                    authorities: ["ROLE_STORE_DIANPU"],
                    pageTitle: 'global.menu.entities.storeStyleSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/home-page-style/home-page-style-edit.html',
                        controller: 'HomePageStyleEditController',
                        controllerAs: 'vm'
                    }
                },
            });
    }

})();
