(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('HomePageStyleController', HomePageStyleController);

    HomePageStyleController.$inject = ['$scope', '$q', 'StoreStyleSetting', '$stateParams', 'MessageService', 'Qinius', 'StoreConfig', 'PasswordMarketing',
        'ListService', 'IndexStyleSettings', 'Principal', '$state', 'pagingParams', 'paginationConstants', '$uibModal', 'ParseLinks','Store'];

    function HomePageStyleController($scope, $q, StoreStyleSetting, $stateParams, MessageService, Qinius, StoreConfig, PasswordMarketing,
        ListService, IndexStyleSettings, Principal, $state, pagingParams, paginationConstants, $uibModal, ParseLinks,Store) {
        var vm = this;
        vm.store = Store.getCurrent();
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.jump = jump
        // 删除
        $scope.delete = function (item) {
            MessageService.confirm("entity.action.deleteConfirm", function () {
                IndexStyleSettings.delete({ id: item.id }, function () {
                    loadAll();
                });
            }, function () {}, true);
        }

        loadAll()
        function loadAll() {
            IndexStyleSettings.byCondition({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.items = data.message;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("首页样式列表获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
        // 创建新的一项
        $scope.createEditor = function (edit) {
            $uibModal.open({
                templateUrl: 'app/entities/home-page-style/home-page-style-new.html',
                controller: 'HomePageStyleNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                }
            }).result.then(function () {
                loadAll()
            });
        }

        // 是否启用
        vm.enable = function (item) {
            // console.log(item.enable);
            // if (!item.enable) {
            //     MessageService.info("当前样式已启用，请勿重复点击");
            //     item.enable=true
            //     return
            // }
            // item.enable = !item.enable
            item.widgets=JSON.stringify(item.widgets)
            IndexStyleSettings.save(item, function (res) {
                if (res.status==200) {
                    MessageService.success("修改成功");
                    loadAll()
                }else{
                    MessageService.error(res.message||"修改失败");
                }
                
            })
        }
    }
})();
