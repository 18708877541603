(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('AfterSaleDialogController', AfterSaleDialogController);

    AfterSaleDialogController.$inject = ['$state', '$scope', 'LocationProvice', '$stateParams', 'entity', 'Qinius', '$q', 'AfterSale',
        'MessageService', '$timeout', 'UidService', 'LocationCity', 'LocationDistrict', 'Upload', 'SalesOrder', 'PlatformWechatSetting','Customer'];

    function AfterSaleDialogController($state, $scope, LocationProvice, $stateParams, entity, Qinius, $q, AfterSale,
                                       MessageService, $timeout, UidService, LocationCity, LocationDistrict, Upload, SalesOrder, PlatformWechatSetting,Customer) {
        var vm = this;

        vm.afterSale = entity;
        vm.afterSaleDetailList = vm.afterSale.afterSaleDetailList.sort();
        vm.status = $stateParams.status;
        vm.userProblem = vm.afterSaleDetailList[vm.afterSaleDetailList.length - 1];

        vm.clear = clear;
        vm.save = save;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        vm.edit = false;

        function clear() {
            $state.go('after-sale', {}, {reload: true});
        }

        vm.provinces = LocationProvice.query();
        $scope.byStatus = function (status) {
            vm.status = status;
            localStorage.setItem('status', status);
            $state.go('after-sale', {}, {reload: true});
        };
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.provinces.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;// 初始化城市：省市区

            console.log(vm.afterSale);
        })

        $scope.getCities = function (location) {
            if (location.pro != null) {
                LocationCity.findByProvince({
                    provinceId: location.pro.id
                }, function (data) {
                    location.citys = data;
                    for (var z = location.citys.length - 1; z >= 0; z--) {
                        var c = location.citys[z];
                        if (c.name == location.city) {
                            location.ci = c;
                            break;
                        }
                    }
                    $scope.getDistricts(location);
                });
            }
        }

        $scope.getDistricts = function (location) {
            if (location.ci != null) {
                LocationDistrict.findByCities({
                    cityId: location.ci.id
                }, function (data) {
                    location.districts = data;
                    for (var y = location.districts.length - 1; y >= 0; y--) {
                        var di = location.districts[y];
                        if (di.name.replace("　", "") == location.district.replace("　", "")) {
                            location.dis = di;
                            break;
                        }
                    }
                });
            }
        }

        $scope.uploadFile = function (fileWatched) {
            vm.file = fileWatched;
            vm.fileName = fileWatched.name;
            Upload.upload({
                type: 'POST',
                url: 'service/api/sales-orders/importLocation', //上传路径
                data: {
                    id: vm.afterSale.salesOrder.id,
                    file: vm.file,
                    name: vm.fileName
                }
            }).then(function successCallback(res) {
                vm.afterSale.salesOrder = res.data;
                for (var j = 0; j < vm.afterSale.salesOrder.locations.length; j++) {
                    if (vm.afterSale.salesOrder.locations[j].deleted) {
                        continue;
                    }
                    var location = vm.afterSale.salesOrder.locations[j];
                    for (var i = vm.provinces.length - 1; i >= 0; i--) {
                        var p = vm.provinces[i];
                        if (p.name == location.province) {
                            location.pro = p;
                            break;
                        }
                    }
                    location.districts = [];
                    location.citys = [];
                    $scope.getCities(location);
                    if (vm.afterSale.salesOrder.id !== null) {
                        SalesOrder.update(vm.afterSale.salesOrder, onSaveSuccess, onSaveError);
                    } else {
                        SalesOrder.save(vm.afterSale.salesOrder, onSaveSuccess, onSaveError);
                    }
                }
            }, function errorCallback(response) {
                MessageService.error("文件导入失败");
            });
        };

        $scope.editAddress = function (location, $index) {
            vm.currentLocation = angular.copy(location);
            vm.index = angular.copy($index);
            vm.edit = true;
            for (var i = vm.provinces.length - 1; i >= 0; i--) {
                var p = vm.provinces[i];
                if (p.name == vm.currentLocation.province) {
                    vm.currentLocation.pro = p;
                    break;
                }
            }
            vm.currentLocation.districts = [];
            vm.currentLocation.citys = [];
            $scope.getCities(vm.currentLocation);
        }

        $scope.saveAddress = function (location) {
            if (location.ci == null || location.pro == null || location.dis == null) {
                MessageService.error("请选择省市区数据");
                return;
            } else {
                location.province = location.pro.name;
                location.city = location.ci.name;
                location.district = location.dis.name;
            }
            if (vm.afterSale.salesOrder.locations.length > 0 && location.quantity != null && location.quantity <= 0) {
                MessageService.error("收货地址收货数量不能小于零");
                return;
            }
             if (!location.phone) {
                MessageService.error("请录入手机号码");
                return;
            }
            vm.afterSale.salesOrder.locations[vm.index] = angular.copy(location);
            vm.edit = false;
            if (vm.afterSale.salesOrder.id !== null) {
                SalesOrder.update(vm.afterSale.salesOrder, onSaveSuccess, onSaveError);
            } else {
                SalesOrder.save(vm.afterSale.salesOrder, onSaveSuccess, onSaveError);
            }
        }

        $scope.modalHide = function () {
            vm.edit = false;
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:salesOrderUpdate', result);
            MessageService.success("保存成功");
            vm.isSaving = false;
        }

        function onSaveError() {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }

        // 改变省份
        function changeProvince(location) {
            LocationCity.findByProvince({provinceId: location.pro.id}, function (data) {
                location.citys = data;
                location.districts = [];
            });
        }

        // 改变城市
        function changeCity(location) {
            if (location.ci == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: location.ci.id}, function (data) {
                location.districts = data;
            });
        }

        $scope.goToView = function (line, orderArticle) {
            PlatformWechatSetting.get({}, function (res) {
                if(!res.editorNotifyUrl){
                    MessageService.error("请设置editorNotifyUrl");
                    return;
                }
                Customer.getToken({id: line.userId}, function (res1) {
                    if(res1.status == 200){
                        window.open(res.editorNotifyUrl + '/#/' + line.storeId + '/webView2/' + orderArticle.id + '?source=external&authorization=' + res1.message);
                    }else{
                        MessageService.error(res1.message || "获取token失败，请稍后再试！");
                    }
                })
            })
        };

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".listDetail").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".listDetail").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow", "auto");
        }

        function save() {
            vm.afterSale.statue = 'Treated'
            vm.afterSale.photo = vm.detailImgs.join();
            vm.afterSale.type = "1";
            vm.afterSale.describe = vm.describe;
            AfterSale.update(vm.afterSale, function (result) {
                MessageService.success("操作完成");
                $state.go('after-sale', {}, {reload: true});
            }, function (error) {
                MessageService.error("操作失败");
            });
        }

        $scope.removeFile = function (field) {
            var index = 0;
            for (var i = vm.detailImgs.length - 1; i >= 0; i--) {
                if (vm.detailImgs[i] == field) {
                    index = i;
                }
            }
            vm.detailImgs.splice(index, 1);
        };

        vm.detailImgs = [];
        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'after-' + UidService.get());
                formData.append('token', vm.token);
                $("#file").val("");
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        var flag = true;        //是否可以添加
                        if (vm.detailImgs.length > 0 && vm.detailImgs.length < 5) {
                            for (var i = vm.detailImgs.length - 1; i >= 0; i--) {
                                if (vm.detailImgs[i] == data.key) {
                                    // 不建议上传相同的图片，没有意义
                                    flag = false;
                                }
                            }
                        }
                        if (vm.detailImgs.length + 1 > 4) {
                            MessageService.error("最多上传四张附件");
                            return;
                        }
                        if (flag) {
                            vm.detailImgs.push(data.key);
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.download = function (line) {
            if (line.filePath) {
                var pathUrl = line.filePath;
                var xhr = new XMLHttpRequest();
                xhr.open("GET", pathUrl, true);
                xhr.responseType = 'blob';
                xhr.onload=function(e){
                    var url = window.URL.createObjectURL(xhr.response)
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = '';
                    a.click();
                }
                xhr.send();
            }
            else {
                MessageService.error("文件URL为空, 请重新组版");
            }
        }

        $scope.canCompose = function(line) {
            if (!line.orderArticle || !line.orderArticle.fileName) {
                return false;
            }
            if (line.orderArticle.productType == 'StandardPrint' || line.orderArticle.productType == 'Standard') {
                return false;
            }
            return true;
        }

        $scope.recompose = function(line) {
            var orderId = vm.afterSale.salesOrder.id;
            var lineId = line.id;

            SalesOrder.recompose({
                orderId: orderId,
                lineId: lineId
            }, function (res) {
                MessageService.success("发送组版请求成功");
                line.recomposeStatus = "Running";
                line.recomposeMessage = "";
            }, function (res) {
                MessageService.error(res.data.message || "发送组版请求失败");
            });
        }
    }
})();
