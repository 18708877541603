(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductPurchaseDialogController', ProductPurchaseDialogController);

    ProductPurchaseDialogController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$q', 'entity', 'ProductPurchase', 
    'Product', 'MessageService', 'Store', 'DateUtils', 'CommodityPrice', 'Category', 'Qinius', '$localStorage', 'ParseLinks'];

    function ProductPurchaseDialogController ($state, $timeout, $scope, $stateParams, $q, entity, ProductPurchase, 
        Product, MessageService, Store, DateUtils, CommodityPrice, Category, Qinius, $localStorage, ParseLinks) {
        var vm = this;

        vm.classifyList = [{name: '全部', id: ''}];
        vm.classify = {name: '全部', id: ''};
        vm.products = [];
        vm.itemValueLines = [];
        vm.search = search;
        vm.item = [];
        vm.domains = Qinius.getDomain();
        vm.item = $stateParams.itemValues || [];
        vm.page = 1;
        vm.classifyList  = Category.getAllByCondition({items:[{key:"usable", op:"=", value:"true"}]});

        vm.groundedProduct = Product.grounded({});

        $q.all([vm.domains.$promise, vm.classifyList.$promise , vm.groundedProduct.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            //获取分类
            productInit()
        });

        $scope.classifyCb = function (data) {
            vm.classify = data;
            vm.page = 1;
            productInit()
        }

        //获取商品
        function productInit(){
            vm.products = [];
            for (var i = vm.groundedProduct.length - 1; i >= 0; i--) {
                var p = vm.groundedProduct[i];
                if(p.cacenlled || !p.saleable){
                    continue;
                }
                if (vm.classify.id && p.categoryId != vm.classify.id) {
                    continue;
                }
                if(vm.keyword && (p.name.toLowerCase()).indexOf(vm.keyword.toLowerCase()) <0 ){
                    continue;
                }
                 
                vm.products.push(p)
            }

            var nums = 20; //每页出现的数量
            var pages = Math.ceil(vm.products.length/nums); //得到总页数
            var thisDate = function(curr){
                //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                var str = [], last = curr*nums - 1;
                last = last >= vm.products.length ? (vm.products.length-1) : last;
                for(var i = (curr*nums - nums); i <= last; i++){
                    str.push(vm.products[i]);
                }
                return str;
            };

            //调用分页
            laypage({
                cont: 'c-paging',
                curr: vm.page, //初始化当前页
                pages: pages,
                skip: true, //是否开启跳页
                first: false,
                last: false,
                jump: function(obj){
                    $timeout(function () {
                        vm.products1=thisDate(obj.curr);
                        vm.page = obj.curr;
                    })
                }
            });
        }

        $scope.selectProduct = function(data){
            vm.selectProductBtn = data;
            vm.page = 1;
            productInit()
        }

        //获取选中商品
        $scope.itemValueLinesCb = function (data) {
            data.selected = !data.selected ? true : false;
            if(!data.selected){
                for(var i=0; i<vm.item.length; i++){
                    if(vm.item[i].id == data.id){
                        vm.item.splice(i, 1);
                    }
                }
            }else {
                vm.item.push(data)
            }
        }

        function search() {
            productInit()
        }

        //商品订购
        $scope.goodsOrder = function () {
            if(vm.item.length == 0){
                MessageService.error("请选择订购的商品");
                return
            }
            var _data = {
                itemValue: vm.item,
                purchaseType: 'COMMODITYPURCHASE'
            }
            var _itemValue = JSON.stringify(_data);
            localStorage.setItem("itemValue",_itemValue);
            $state.go('product-purchase-order');
        }

        //礼包订购
        $scope.goodsOrder1 = function () {
            if(vm.item.length == 0){
                MessageService.error("请选择订购的商品");
                return
            }
            var _data = {
                itemValue: vm.item,
                purchaseType: 'GIFTPURCHASE'
            }
            var _itemValue = JSON.stringify(_data);
            localStorage.setItem("itemValue",_itemValue);
            $state.go('product-purchase-order1');
        }

        //兑换码订购
        $scope.goodsOrder2 = function () {
            if(vm.item.length == 0){
                MessageService.error("请选择订购的商品");
                return
            }
            var _data = {
                itemValue: vm.item,
                purchaseType: 'EXCHANGEPURCHASE'
            }
            var _itemValue = JSON.stringify(_data);
            localStorage.setItem("itemValue",_itemValue);
            $state.go('product-purchase-order2');
        }
    }
})();
