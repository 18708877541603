(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-third-shop', {
            parent: 'store',
            url: '/store-third-shop',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-third-shop/store-third-shop.html',
                    controller: 'StoreThirdShopController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
            }
        })
        .state('store-third-shop-add', {
            parent: 'store',
            url: '/store-third-shop-add',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-third-shop/store-third-shop-dialog.html',
                    controller: 'StoreThirdShopDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$localStorage', function($localStorage) {
                    return {
                        enabled: true,
                    };
                }]
            }
        })
        .state('store-third-shop-edit', {
            parent: 'store',
            url: '/store-third-shop-edit?id',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-third-shop/store-third-shop-dialog.html',
                    controller: 'StoreThirdShopDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ThirdStores', function($stateParams, ThirdStores) {
                    return ThirdStores.get({id:$stateParams.id}).$promise;
                }]
            }
        });
    }

})();
