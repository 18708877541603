(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-purchase', {
            parent: 'entity',
            url: '/product-purchase?page&sort&search',
            data: {
                authorities: ["ROLE_STORE_PURCHASE"],
                pageTitle: 'backApp.productPurchase.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-purchase/product-purchases.html',
                    controller: 'ProductPurchaseController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                     $translatePartialLoader.addPart('purchaseStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-purchase-detail', {
            parent: 'product-purchase',
            url: '/product-purchase/{id}',
            data: {
                authorities: ["ROLE_STORE_PURCHASE"],
                pageTitle: 'backApp.productPurchase.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-purchase/product-purchase-detail.html',
                    controller: 'ProductPurchaseDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                    return ProductPurchase.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'product-purchase',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         
        .state('product-purchase-new', {
            parent: 'product-purchase',
            url: '/new',
            params:{itemValues: null},
            data: {
                authorities: ["ROLE_STORE_PURCHASE"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-purchase/product-purchase-dialog.html',
                    controller: 'ProductPurchaseDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: function() {
                    return {
                        quantity: null,
                        status: null,
                        id: null
                    };
                }
            }
        })

        .state('product-purchase-edit', {
            parent: 'product-purchase',
            url: '/{id}/edit',
            data: {
                authorities: ["ROLE_STORE_PURCHASE"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-purchase/product-purchase-dialog.html',
                    controller: 'ProductPurchaseDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                    return ProductPurchase.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('product-purchase-order', {
            parent: 'product-purchase',
            url: '/order',
            data: {
                authorities: ["ROLE_STORE_PURCHASE"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-purchase/product-purchase-order.html',
                    controller: 'ProductPurchaseOrderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', function($stateParams) {
                    return
                }]
            }
        })
        .state('product-purchase-order-edit', {
            parent: 'product-purchase',
            url: '/order/edit/{id}',
            data: {
                authorities: ["ROLE_STORE_PURCHASE"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-purchase/product-purchase-order.html',
                    controller: 'ProductPurchaseOrderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPurchase');
                    $translatePartialLoader.addPart('purchaseStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                    return ProductPurchase.get({id : $stateParams.id}).$promise;
                }]
            }
        })
            .state('product-purchase-order-look', {
                parent: 'product-purchase',
                url: '/order/look/{id}/{type}',
                data: {
                    authorities: ["ROLE_STORE_PURCHASE"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-purchase/product-purchase-order.html',
                        controller: 'ProductPurchaseOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                        return ProductPurchase.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('product-purchase-order1', {
                parent: 'product-purchase',
                url: '/order1',
                data: {
                    authorities: ["ROLE_STORE_PURCHASE"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-purchase/product-purchase-order1.html',
                        controller: 'ProductPurchaseOrder1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function($stateParams) {
                        return
                    }]
                }
            })
            .state('product-purchase-order1-edit', {
                parent: 'product-purchase',
                url: '/order1/edit/{id}',
                data: {
                    authorities: ["ROLE_STORE_PURCHASE"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-purchase/product-purchase-order1.html',
                        controller: 'ProductPurchaseOrder1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                        return ProductPurchase.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('product-purchase-order1-look', {
                parent: 'product-purchase',
                url: '/order1/look/{id}/{type}',
                data: {
                    authorities: ["ROLE_STORE_PURCHASE"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-purchase/product-purchase-order1.html',
                        controller: 'ProductPurchaseOrder1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                        return ProductPurchase.get({id : $stateParams.id}).$promise;
                    }]
                }
            })

            .state('product-purchase-order2', {
                parent: 'product-purchase',
                url: '/order2',
                data: {
                    authorities: ["ROLE_STORE_PURCHASE"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-purchase/product-purchase-order2.html',
                        controller: 'ProductPurchaseOrder2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function($stateParams) {
                        return
                    }]
                }
            })
            .state('product-purchase-order2-edit', {
                parent: 'product-purchase',
                url: '/order2/edit/{id}',
                data: {
                    authorities: ["ROLE_STORE_PURCHASE"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-purchase/product-purchase-order2.html',
                        controller: 'ProductPurchaseOrder2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                        return ProductPurchase.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('product-purchase-order2-look', {
                parent: 'product-purchase',
                url: '/order2/look/{id}/{type}',
                data: {
                    authorities: ["ROLE_STORE_PURCHASE"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-purchase/product-purchase-order2.html',
                        controller: 'ProductPurchaseOrder2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productPurchase');
                        $translatePartialLoader.addPart('purchaseStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductPurchase', function($stateParams, ProductPurchase) {
                        return ProductPurchase.get({id : $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
