(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StorageRackDialog2Controller', StorageRackDialog2Controller);

    StorageRackDialog2Controller.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StorageRack', 'MessageService'];

    function StorageRackDialog2Controller ($timeout, $scope, $stateParams, $uibModalInstance, entity, StorageRack, MessageService) {
        var vm = this;

        vm.storageRack = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if (vm.storageRack.quantity == null || vm.storageRack.quantity == "") {
                MessageService.error("请输入数量");
                return false;
            }
            if (vm.storageRack.start == null || vm.storageRack.start == "") {
                MessageService.error("请输入起始编码");
                return false;
            }
            return true;
        }

        function save () {
            if (!$scope.validate()) {
                return;
            } 
            StorageRack.batchCreate({quantity:vm.storageRack.quantity, start: vm.storageRack.start, pre:vm.storageRack.pre}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:storageRackUpdate', result);
            MessageService.success("保存成功")
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("保存失败")
            vm.isSaving = false;
        }


    }
})();
