(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomerServiceConfigEditController', CustomerServiceConfigEditController);

    CustomerServiceConfigEditController.$inject = ['$scope', '$q', 'UidService', '$rootScope', '$state', 'MessageService', 'Store', 'Qinius', '$uibModal'];

    function CustomerServiceConfigEditController($scope, $q, UidService, $rootScope, $state, MessageService, Store, Qinius, $uibModal) {
        var vm = this;
        vm.save = save;
        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.store.$promise]).then(function() {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        })

        window.uploadPic = function($event, attr) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0], attr);
            }
        }

        function qiniuUpload(file, attr) {
            vm.attr = attr;
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'customer-service-config-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function(data) {
                    imgSrop($scope.domain + data.key, 300, 300)
                    $scope.$apply();
                    // Store.update(vm.store, onSaveSuccess, onSaveError);
                },
                error: function(request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        function imgSrop(url, width, height) {
            $uibModal.open({
                templateUrl: 'app/entities/initialization/modal/img-crop.html',
                controller: 'ImgCropController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            url: url,
                            width: width,
                            height: height
                        };
                    }]
                }
            }).result.then(function(data) {
                vm.store.customerServiceQRCode = data;
            });
        }

        function onSaveSuccess(result) {
            MessageService.success("信息更新成功");
            $state.go("customer-service-config", null, { reload: 'customer-service-config' });
            vm.isSaving = false;
        }

        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }


        $scope.clear = function() {
            $state.go("customer-service-config", null, { reload: 'customer-service-config' });
        }

        function save() {
            Store.update(vm.store, onSaveSuccess, onSaveError);
        }

    }
})();
