(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderController', SalesOrderController);

    SalesOrderController.$inject = ['$scope', 'BranchStoreManagements',  '$state', 'SalesOrder', 'ParseLinks', 'paginationConstants', '$http', 'Guide', '$uibModal',
        'pagingParams', 'MessageService', '$stateParams', 'DateUtils', 'Qinius','$localStorage', 'BranchStore','$q','Category', 'Product','Store','Principal','StoreUsers'];

    function SalesOrderController ($scope, BranchStoreManagements, $state, SalesOrder, ParseLinks, paginationConstants, $http , Guide, $uibModal,
                                   pagingParams, MessageService, $stateParams, DateUtils, Qinius,$localStorage, BranchStore, $q, Category, Product,Store,Principal,StoreUsers) {
        var vm = this;

        Principal.hasAuthority('ROLE_STORE_ORDERMANAGE').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.deleted = pagingParams.deleted;
        vm.composition = pagingParams.composition;
        vm.noComposition = pagingParams.noComposition;
        vm.productName = pagingParams.productName;
        vm.status = pagingParams.status;
        vm.customer = pagingParams.customer;
        vm.startTime = pagingParams.startTime ? new Date(pagingParams.startTime):null;
        vm.endTime = pagingParams.endTime? new Date(pagingParams.endTime):null;
        vm.sourceType = pagingParams.sourceType;
        vm.prototype = pagingParams.prototype;
        vm.categoryId = pagingParams.categoryId?Number(pagingParams.categoryId):null;
        vm.payRefundStatus = pagingParams.payRefundStatus;
        vm.transactionId = pagingParams.transactionId;
        vm.recipientsPhone = pagingParams.recipientsPhone;

        vm.number = pagingParams.number;
        vm.phone = pagingParams.phone?Number(pagingParams.phone):null;
        vm.deliveryNumber = pagingParams.deliveryNumber;
        vm.code = pagingParams.code;
        vm.giftCode = pagingParams.giftCode;
        vm.deliveryType = pagingParams.deliveryType;
        vm.deliveryCode = pagingParams.deliveryCode;

        if($stateParams.branchStoreId&&$stateParams.branchStoreId!="null"){
            vm.branchStoreId = Number($stateParams.branchStoreId)||null;
        }else{
            vm.branchStoreId = $stateParams.branchStoreId
        }
        vm.inviterId = $stateParams.inviterId?Number($stateParams.inviterId):null;
        if(vm.inviterId!=null&&vm.branchStoreId!=null&&vm.branchStoreId!="null"){
            getGuides();
        }

        vm.jump = jump;
        vm.reJustify = reJustify;
        vm.reject = reject;
        vm.dateformat = dateformat;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        vm.isOpen =false;
        $scope.selectTab ='';

        vm.statutsTabs = [
            { key:"", value:"全部订单", search:"" },
            { key:"Created", value:"待支付" },
            { key:"Submitted", value:"待提交"},
            { key:"Unchecked", value:"待核验"},
            { key:"CheckedError", value:"核验失败" },
            { key:"CheckIng", value:"待审核"},
            { key:"Paid", value:"待生产", search:"Paid" },
            { key:"CombinationError", value:"组版失败"},
            { key:"OutFailed", value:"推生产失败"},
            { key:"Producing", value:"生产中"},
            { key:"ToBeDelivery", value:"待发货"},
            { key:"Receiving", value:"发货中"},
            { key:"ToTake", value:"待取件"},
            { key:"AllRefund", value:"退款订单"},
            //{ key:"Approved", value:"退款完成"},
            { key:"Received", value:"已签收" },
            { key:"Completed", value:"交易完成" },
            { key:"Cancelled", value:"交易取消" },
            { key:"Deleted", value:"交易删除" }
            ]

        vm.payRefundStatusList=[
            {key:"None",value:"全部"},
            {key:"Required",value:"申请退款"},
            {key:"Approved",value: "退款完成"},
            {key:"Failed",value: "退款失败"},
            {key:"Rejected", value: "拒绝退款"}
        ]

        if(vm.status == '' || vm.status == undefined ){
            $scope.selectTab = '';
        }else{
            $scope.selectTab = vm.status;
        }
        if (!!vm.deleted) {
            $scope.selectTab = 'Deleted';
        }
        if(!!vm.payRefundStatus){
            $scope.selectTab = 'AllRefund';
        }
        if (!!vm.composition) {
            $scope.selectTab = 'CombinationError';
        }

        vm.sourceTypes = [{
            name:"WECHAT",
            value:"公众号"
        },{
            name:"MINI",
            value:"小程序"
        },{
            name:"WEB",
            value:"网页端"
        },{
            name:"ALI",
            value:"支付宝"
        },{
            name:"BYTEDANCE",
            value:"抖音小程序"
        },{
            name:"JD",
            value:"京东小程序"
        }];


        vm.prototypes = [{
            name:"Store",
            value:"平台"
        },{
            name:"StoreSelf",
            value:"自运营"
        }];

        vm.deliveryTypes = [{
            name:"Delivery",
            value:"普通快递"
        },{
            name:"Delivery_Quick",
            value:"加急快递"
        },{
            name:"Self",
            value:"线下自提"
        }];

        $scope.pushscm = function(salesOrder){
            if (salesOrder == null) {
                return;
            };
            MessageService.confirm("确认订单要推入供应链？", function() {
                SalesOrder.postForOrder({
                    id: salesOrder.id
                }, function(data) {
                    if (data.status ==200 && data.message) {
                        MessageService.success("执行推入成功");
                    }else{
                        MessageService.error("已加入生产队列，请稍后查看推入结果");
                    }
                    loadAll();
                }, function() {
                    MessageService.error("执行推入失败");
                })
            }, function() {})
        }

        $scope.allPostForOrder = function(){
            MessageService.confirm("确认要将所有失败订单推入供应链？", function() {
                SalesOrder.allPostForOrder({}, function(data) {
                    MessageService.success("执行推入成功");
                    loadAll();
                }, function() {
                    MessageService.error("执行推入失败");
                })
            }, function() {})
        }

        $scope.checked = function(id){
            SalesOrder.checked({id: id}, function(data) {
                MessageService.success("订单更新成功");
                loadAll();
            }, function () {
                MessageService.error("订单更新失败");
            });
        }
        // 强行核验
        $scope.handleForceCheck = function(id){
            console.log(id);
            MessageService.confirm("确认要将该订单强行核验？", function() {
                SalesOrder.forceCheck({id: id}, function(data) {
                    MessageService.success("订单强行核验成功");
                    loadAll();
                }, function () {
                    MessageService.error("订单强行核验失败");
                });
            }, function() {})
        }

        // 商品品类
        vm.categorys = Category.storeGetAllByCondition({items:[{key: "usable", op: "=", value: "true"}]});

        vm.products = Product.getAllByCondition({
            items: [{key:"prototype",op:"in",value:"Store;StoreSelf"}]
        });
        // 用户信息获取storeId
        vm.userInfo=StoreUsers.account();
        vm.store = Store.getCurrent();
        vm.account = $localStorage.user;

        // 分店管理
        if(vm.account.authorities.indexOf("ROLE_STORE_BRANCH_SHOP")!=-1){
            if(!$stateParams.branchStoreId){
                vm.branchStoreId = "";
            }
            vm.branchStores = BranchStore.getAllByCondition({items: [{key: "cancelled", op: "=", value: "false"},{key: "enabled", op: "=", value: "true"}]});
            $q.all([vm.categorys.$promise,vm.store.$promise, vm.branchStores.$promise,vm.userInfo.$promise]).then(function() {
                vm.statusDataList = SalesOrder.getStatusData({storeId:vm.userInfo.storeId});
                var obj = {id:"null",name:'总店'}
                vm.branchStores.unshift(obj);
                loadAll();
            });
        }else if(vm.account.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP")!=-1){
            // 我的分店权限
            vm.storeDivShow = true;
            vm.accountBranch = BranchStoreManagements.findByUserId({userId:vm.account.id});
            $q.all([vm.categorys.$promise, vm.store.$promise, vm.accountBranch.$promise, vm.userInfo.$promise]).then(function() {

                if(vm.accountBranch&&vm.accountBranch.branchStore){
                    vm.branchStoreId = vm.accountBranch.branchStore.id;
                }
                vm.statusDataList = SalesOrder.getStatusData({storeId:vm.userInfo.storeId, branchStoreId:vm.branchStoreId});
                vm.branchStores = [];
                loadAll();
            });
        }else{
            $q.all([vm.categorys.$promise,vm.store.$promise,  vm.userInfo.$promise]).then(function() {
                vm.statusDataList = SalesOrder.getStatusData({storeId:vm.userInfo.storeId});
                vm.branchStores =[];
                loadAll();
            });
        }


        $scope.delivery = function(data,id) {
            if (id == null) {
                return;
            };
            console.log(data)
            createModal('app/entities/sales-order/sales-order-delivery-dialog.html', 'SalesOrderDeliveryController', data).result.then(function () {
                loadAll();
            });
        }

        var createModal = function (url, controller,data) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            });
        };

        $scope.changeCategory = function() {
            if (vm.categoryId != null) {
                Product.getAllByCondition({
                    items: [{key:"categoryStore.id",op:"=",value:vm.categoryId}]
                }, function(data1) {
                    vm.products = data1;
                });
            }
        };

        $scope.isSotreSelfProduct = function(so){
            for (var i = so.orderLines.length - 1; i >= 0; i--) {
                var l = so.orderLines[i];
                if ( l.prototype == "Store") {
                    return false;
                }
            }
            return true;
        }

        $scope.changeBranchStore = function() {
            console.log(vm.branchStoreId)
            if(vm.branchStoreId=="null"){
                vm.isbranchStore = true;
                return
            }

            vm.isbranchStore = null;
            if (vm.branchStoreId != null&&vm.branchStoreId!="null") {
                getGuides();
            }
        }

        function getGuides(){
            Guide.getAllByCondition({
                items: [{key:"branchStore.id",op:"=",value:vm.branchStoreId},{key:"status",op:"=",value:"Approved"}]
            }, function(data1) {
                vm.guides = data1;
            });
        }

        $scope.shrink = function(){
            vm.isOpen = !vm.isOpen;
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        $scope.getComposition =function(){
            $scope.selectTab = 'CombinationError';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.payRefundStatus = "";
            vm.composition = 'CombinationError';
            search();
        };


        $scope.byStatus =function(status){
            if(status == '' || status == undefined){
                $scope.selectTab = '';
            }else {
                $scope.selectTab = status;
            }
            if (status == "CombinationError") {
                $scope.getComposition();
                return;
            }

            if (status == "Deleted") {
                $scope.getDeleted();
                return;
            }

            if (status == "AllRefund"){
                $scope.AllRefund();
                return
            }

            /*if (status == "Required") {
                $scope.getRefund();
                return;
            }
            if (status == "Approved") {
                $scope.getRefundApproved();
                return;
            }*/

            if(status === 'Paid'){
                vm.noComposition = 'noComposition';
            }else {
                vm.noComposition = '';
            }
            vm.deleted = "";
            vm.composition = '';
            vm.payRefundStatus = "";
            vm.status = status;
            search();
        };

        $scope.getDeleted =function(){
            $scope.selectTab = 'Deleted';
            vm.status = "";
            vm.composition = '';
            vm.noComposition = '';
            vm.payRefundStatus = '';
            vm.deleted = 'Deleted';
            search();
        };

        $scope.AllRefund = function(){
            $scope.selectTab = 'AllRefund';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            vm.payRefundStatus = 'None';
            search();
        };

        $scope.chooseStatus =function (){
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            search();
        }

        /*$scope.getRefund = function(){
            $scope.selectTab = 'Required';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            vm.payRefundStatus = 'Required';
            search();
        };

        $scope.getRefundApproved = function(){
            $scope.selectTab = 'Approved';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            vm.payRefundStatus = 'Approved';
            search();
        };*/

        $scope.screens = function(){
            search(vm.currentSearch);
        };

        $scope.resetInfo = function(){
            vm.startTime = null;
            vm.endTime = null;
            vm.categoryId = null;
            vm.productName = null;
            vm.customer = null;
            vm.sourceType = null;
            vm.prototype = null;
            vm.branchStoreId = null;
            vm.isbranchStore = null;
            vm.inviterId = null;
            vm.number = null;
            vm.phone = null;
            vm.deliveryNumber = null;
            vm.code = null;
            vm.giftCode = null;
            vm.transactionId = null;
            vm.recipientsPhone = null;
            vm.composition = null;
            vm.noComposition = null;
            vm.deliveryType = null;
            vm.deliveryCode = null;
            vm.page = 1;
            search();
        };

        $scope.checkOrderLineState = function(order){
            var flag = false;
            if (order.status != 'Paid') {
                return flag;
            }
            if (order.orderLines.length > 0) {
                for (var i = order.orderLines.length - 1; i >= 0; i--) {
                    var line = order.orderLines[i];
                    if (line != null && line.productType != 'StandardPrint'  &&
                        line.productType != 'Standard' && (line.status == 'CombinationError' || line.status == 'New' || order.status == "Paid" )){
                        return true;
                    }
                }
            }
            return flag;
        };

        function getItems() {
            var items=[];
            if (vm.endTime !=null) {
                items.push({key: "createdDate", op: "<=", value: new Date(Date.parse(vm.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            };
            if (vm.startTime !=null) {
                items.push({key: "createdDate", op: ">=", value: new Date(Date.parse(vm.startTime) -8 * 60 * 60 * 1000)});
            };

            //商品分类
            if (vm.categoryId !=null) {
                items.push({key:"orderLines.orderArticle.categoryStoreId",op:"=",value:vm.categoryId});
            };
            //商品名称
            if (vm.productName !=null) {
                items.push({key:"orderLines.productName",op:"like",value:vm.productName});
            };
            //订单平台
            if (vm.sourceType !=null) {
                items.push({key:"sourceType",op:"=",value:vm.sourceType});
            };
            //订单来源
            if (vm.prototype !=null) {
                items.push({key:"orderLines.orderArticle.prototype",op:"=",value:vm.prototype});
            };
            if (vm.status !=null) {
                if(vm.status == 'ToTake'){
                    items.push({key:"deliveryType",op:"=",value:'Self'});
                    items.push({key:"deliveryStatus",op:"!=",value:'Claimed'});
                }else{
                    items.push({key:"status",op:"=",value:vm.status});
                }
            }
            if (vm.deleted =='Deleted') {
                items.push({key:"cancelled",op:"=",value:"true"});
            }
            if (vm.payRefundStatus == 'None') {
                items.push({key:"payRefundStatus",op:"!=",value:vm.payRefundStatus});
            }else if(vm.payRefundStatus){
                items.push({key:"payRefundStatus",op:"=",value:vm.payRefundStatus});
            }

            if (vm.customer) {
                items.push({key:"createdBy",op:"like",value:vm.customer});
            }

            if (vm.composition =='CombinationError') {
                items.push({key: "orderLines.status", op: "=", value: "CombinationError"});
                items.push({key:"status",op:"=",value:"Paid"});
            }
            // if (vm.noComposition =='noComposition') {
            //     items.push({key: "orderLines.status", op: "!=", value: "CombinationError"});
            // }
            if(vm.branchStoreId){
                if(vm.branchStoreId=='null'){
                    vm.inviterId = null;
                    items.push({key:"branchStoreId",op:"isNull"});
                }else{
                    items.push({key:"branchStoreId",op:"=",value:vm.branchStoreId});
                    vm.isbranchStore = null;
                }
            }else{
                vm.isbranchStore = null;
            }

            if(vm.inviterId){
                items.push({key:"inviterId",op:"=",value:vm.inviterId});
            }

            //客户电话
            if (vm.phone !=null) {
                items.push({key:"locations.phone",op:"like",value:vm.phone});
            };
            //订单编号
            if (vm.number !=null) {
                items.push({key:"number",op:"like",value:vm.number});
            };
            //快递单号
            if (vm.deliveryNumber !=null) {
                items.push({key:"deliveryNumber",op:"like",value:vm.deliveryNumber});
            };
            //支付码 礼物券
            if (vm.code !=null) {
                items.push({key:"orderLines.payCodes.code,productLines.payCodes.code",op:"=",value:vm.code});
            };
            if (vm.giftCode !=null) {
                items.push({key:"orderLines.giftVouchers.code,productLines.giftVouchers.code",op:"=",value:vm.giftCode});
            };
            if (vm.transactionId !=null) {
                items.push({key:"transactionId",op:"like",value:vm.transactionId});
            };
            if (vm.recipientsPhone !=null) {
                items.push({key:"locations.phone",op:"=",value:vm.recipientsPhone});
            };
            if (vm.status != 'ToTake' && vm.deliveryType !=null) {
                items.push({key:"deliveryType",op:"=",value:vm.deliveryType});
            };
            if (vm.deliveryCode !=null) {
                items.push({key:"deliveryCode",op:"=",value:vm.deliveryCode});
            };

            return items;
        }

        function loadAll () {
            var items = getItems();


            if (pagingParams.search) {
                items.push({key:"locations.contact,orderLines.orderArticle.name",op:"like",value:pagingParams.search});
            }
            if (vm.deleted == 'Deleted') {
                SalesOrder.byCondition2({
                    items:items,
                    available:false,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }else{
                SalesOrder.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return ["createdDate,desc"];
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.allPagesCount = Math.ceil(vm.totalItems/20);
            vm.queryCount = vm.totalItems;
            vm.salesOrders = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("订单获取失败");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status:vm.status,
                deleted: vm.deleted,
                startTime: vm.startTime?DateUtils.convertLocalDateToServer(vm.startTime):"",
                endTime: vm.endTime?DateUtils.convertLocalDateToServer(vm.endTime):"",
                sourceType:vm.sourceType,
                productName:vm.productName,
                customer:vm.customer,
                categoryId:vm.categoryId,
                prototype:vm.prototype,
                payRefundStatus:vm.payRefundStatus,
                branchStoreId:vm.branchStoreId,
                isbranchStore:vm.isbranchStore,
                composition: vm.composition,
                noComposition: vm.noComposition,
                inviterId:vm.inviterId,
                number:vm.number,
                phone:vm.phone,
                deliveryNumber:vm.deliveryNumber,
                code:vm.code,
                giftCode: vm.giftCode,
                transactionId:vm.transactionId,
                recipientsPhone: vm.recipientsPhone,
                deliveryType: vm.deliveryType,
                deliveryCode: vm.deliveryCode
            });
        }

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        function reJustify(salesOrder) {
            salesOrder.action = true;
            SalesOrder.justify({id: salesOrder.id}, function (data) {
                MessageService.success("订单文件组版中...");
                loadAll();
            }, function () {
                MessageService.error("订单文件组版失败");
            });
        }

        function reject(id) {
            createModal2('app/entities/sales-order/support-reject-dialog.html', 'SupportRejectOrderController').result.then(function (info) {
                SalesOrder.supportReject({id: id, reason:info}, function(data) {
                    MessageService.success("订单驳回成功");
                    loadAll();
                }, function () {
                    MessageService.error("订单驳回失败");
                });
           });
        }

        var createModal2 = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {

                    }]
                }
            });
        };



        $scope.approveRefund = function (salesorder) {
            var _salesorder = angular.copy(salesorder);
            _salesorder.action = true;
            MessageService.confirm("确定要通过该请求？",function(){
                SalesOrder.approveRefund({id: _salesorder.id}, function(data) {
                    MessageService.success("退款成功");
                    loadAll();
                }, function () {
                    MessageService.error("退款失败");
                });
            });
        }

        $scope.rejectRefund = function (salesorder) {
            var _salesorder = angular.copy(salesorder);
            _salesorder.action = true;
            MessageService.confirm("确定要拒绝该请求？",function(){
                SalesOrder.rejectRefund({id: _salesorder.id}, function(data) {
                    MessageService.success("拒绝退款成功");
                    loadAll();
                }, function () {
                    MessageService.error("拒绝退款成功");
                });
            });

        }

        $scope.checkOrderLinecombinationError = function(order){
            if (order.status != 'Paid') {
                return false;
            }
            if (order.orderLines.length > 0) {
                for (var i = order.orderLines.length - 1; i >= 0; i--) {
                    var line = order.orderLines[i];
                    if (line.status == 'CombinationError') {
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.combinationError = function (salesOrder) {
            $http({
                url: 'justify/api/downloadErrorLog',
                method: "GET",
                params: {
                    orderNumber:salesOrder.number
                },
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "组版失败日志_" +salesOrder.number+ ".zip";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function () {
                MessageService.error("下载失败，请稍后重试！");
            });
        }

        $scope.watchPayDetail = function (order) {
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/pay-detail.html',
                controller: 'PayDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                // size: 'lg',
                resolve: {
                    entity: [function() {
                        return SalesOrder.get({id:order.id}).$promise;
                    }]
                }
            }).result.then(function(item) {

            });
        }

        //通知取件
        $scope.informPickUp = function (salesOrder) {
            SalesOrder.sendDeliveryMsg({id: salesOrder.id}, function (res) {
                MessageService.success("短信发送成功");
                loadAll()
            }, function (error) {
                MessageService.error("短信发送失败");
            });
        }
        //确认核销
        $scope.deliveryCodeCheck = function (salesOrder) {
            salesOrder.action = true;
            SalesOrder.deliveryCodeCheck({id: salesOrder.id}, function (res) {
                MessageService.success("确认核销成功");
                loadAll()
            }, function (error) {
                MessageService.error("确认核销失败");
            });
        }
        //订单手动核验
        $scope.doCheck = function (salesOrder) {
            salesOrder.action = true;
            SalesOrder.doCheck({id: salesOrder.id}, function (res) {
                MessageService.info(res.message);
                loadAll()
            }, function (error) {
                MessageService.error("手动核验失败");
            });
        }
        // 重组并推送
        $scope.recombinationAndPush = function (data,id){
            if (id == null) {
                return;
            };
            createModal('app/entities/sales-order/sales-order-recombination-dialog.html', 'SalesOrderRecombinationController', data).result.then(function () {
                loadAll();
            });
        }
    }
})();
