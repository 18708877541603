(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('CommodityPrice', CommodityPrice)
        .factory('ProductPurchase', ProductPurchase)
        .factory('Tariffs', Tariffs);

    CommodityPrice.$inject = ['$resource'];
    ProductPurchase.$inject = ['$resource'];
    Tariffs.$inject = ['$resource'];

    function CommodityPrice ($resource) {
        var resourceUrl =  'manager/' + 'api/commodity-prices/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPriceByProductAndStore': {
                method: 'GET',
                params: {
                    path: 'getPriceByProductAndStore'
                }
            }
        })
    }

    function ProductPurchase ($resource) {
        var resourceUrl =  'manager/' + 'api/product-purchases/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'submit': {
                method: 'GET',
                params: {
                    path: 'submit'
                }
            },

            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': {method: 'POST'},
            'dateList': {
                method: 'GET',
                    params: {
                    path: 'DateList'
                }
            },
            'calculate': {
                method: 'POST',
                    params: {
                    path: 'calculate'
                }
            }
        });
    }
    function Tariffs ($resource) {
        var resourceUrl =  'manager/' + 'api/tariffs/:path/:id';

        return $resource(resourceUrl, {}, {
            'findByItemValueLineId': {
                method: 'GET',
                params: {
                    path: 'findByItemValueLineId'
                }
            }
        });
    }
})();
