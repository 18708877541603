(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('CashRecordController', CashRecordController);

    CashRecordController.$inject = ['$scope', '$state'];

    function CashRecordController ($scope, $state) {
        var vm = this;
        
}
})();
