(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('CopyProductModalController', CopyProductModalController);

    CopyProductModalController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'MessageService', 'Product', '$uibModalInstance'];

    function CopyProductModalController($timeout, $scope, $state, $q, entity, MessageService, Product, $uibModalInstance) {
        var vm = this;
        vm.selected = true;
        vm.product = entity;
        $scope.clear = function(){
            $uibModalInstance.dismiss();
        }
        $scope.save = function(){
            vm.isSaving = true;
            Product.copy({
                id:vm.product.id,
                productXmlLine: vm.selected
            },function (res){
                if(res && res.id){
                    MessageService.success("复制成功");
                    $state.go('product.edit2',{id:res.id});
                    $uibModalInstance.close(true);
                }else{
                    MessageService.error(res.message || "复制失败");
                }
                vm.isSaving = false;
            },function (error){
                MessageService.error(error.data.message || "复制失败");
                vm.isSaving = false;
            })
        }
    }
})();
