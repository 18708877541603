(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayBatchDialogStoreSelfController', PayBatchDialogStoreSelfController);

    PayBatchDialogStoreSelfController.$inject = ['$timeout', '$scope', '$localStorage', '$uibModalInstance', 'entity', 'PayBatch', 'Store', 'Product', 'MessageService', '$q',
    'ItemValueLinePackageView', 'ProductPurchase', 'ItemValueLinePackage', '$stateParams', 'ListService', 'DateUtils'];

    function PayBatchDialogStoreSelfController ($timeout, $scope, $localStorage, $uibModalInstance, entity, PayBatch, Store, Product, MessageService, $q,
                                       ItemValueLinePackageView, ProductPurchase, ItemValueLinePackage, $stateParams, ListService, DateUtils) {
        var vm = this;
        vm.type = 'StoreSelf';
        vm.payBatch = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.save2 = save2;// 已分配 编辑保存
        vm.store = Store.getCurrent();
        vm.loading = false;
        vm.numberList = [
            {id:6,number:'6位'},
            {id:7,number:'7位'},
            {id:8,number:'8位'},
            {id:9,number:'9位'},
            {id:10,number:'10位'},
            {id:11,number:'11位'},
            {id:12,number:'12位'}
        ]
        
        vm.products = Product.getAllByCondition4({items: [{key: "prototype", op: "=", value: 'StoreSelf'}]})||[];

        $scope.payBatchTypeChange = function(){
            /**
             * 新建自建品批次号
             * 如果类型是支付码则对应库存包类型是礼包订购；
             * 如果类型是兑换码则对应库存包类型是兑换订购
             */
            if (vm.payBatch.payBatchType == 'PAYCODE') {
                vm.payBatch.purchaseType = 'GIFTPURCHASE';
            }

            if (vm.payBatch.payBatchType == 'EXCHANGECODE') {
                vm.payBatch.purchaseType = 'EXCHANGEPURCHASE';
                // vm.payBatch.length = null;
                vm.payBatch.freightFree = false;
            }
        }

        $q.all([vm.payBatch.$promise, vm.store.$promise, vm.products.$promise]).then(function(){
            if(vm.store.unique==null){
                MessageService.error("请先设置商户标识");
                return
            }
            var nowYear = new Date().getFullYear();
            var nowMouth = new Date().getMonth()+1;
            var nowDay = new Date().getDate();
            if(nowMouth<10){
                nowMouth=0+''+nowMouth;
            }
            if(nowDay<10){
                nowDay=0+''+nowDay;
            }

            vm.uniqueTimeStr = vm.store.unique+""+nowYear+""+nowMouth+""+nowDay;
            vm.payBathAll = PayBatch.getAllByCondition({items: [{key: "storeId", op: "=", value: $localStorage.user.storeId}, {key:"number", op:"like", value:vm.uniqueTimeStr}]})||[];  
            vm.payBatch.storeId = vm.store.id;
            vm.payBatch.storeName = vm.store.name;
            vm.proList =[];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var pro = vm.products[i];
                if (pro.itemValues&&!pro.cancelled) {
                    for (var j = pro.itemValues.length - 1; j >= 0; j--) {
                        var p = pro.itemValues[j];
                        if (p.itemValueLines&&!p.cancelled) {
                            for (var x = p.itemValueLines.length - 1; x >= 0; x--) {
                                var ivl = p.itemValueLines[x];
                                var cate = {id:pro.id, name:pro.name, itemValueLines:[], selected :false};
                                var result = ListService.inList(cate, vm.proList, ["id"]);
                                if (result) {
                                    result.itemValueLines.push(ivl);
                                }else{
                                    cate.itemValueLines.push(ivl);
                                    vm.proList.push(cate);
                                }
                            }
                        }
                    }
                }
            }
            console.log(vm.proList)

            if(vm.payBatch.id){
                ItemValueLinePackage.get({id:vm.payBatch.packageId},function (res) {
                    vm.payBatch.itemValueLines = res.itemValueLines;
                    vm.selectedAll = true;
                    if(vm.payBatch.itemValueLines && vm.payBatch.itemValueLines.length>0){
                        for(var i=0; i<vm.proList.length; i++){
                            vm.proList[i].selected = true;
                            for(var j=0; j<vm.proList[i].itemValueLines.length; j++){
                                for(var k=0; k<vm.payBatch.itemValueLines.length; k++){
                                    if(vm.payBatch.itemValueLines[k].id == vm.proList[i].itemValueLines[j].id){
                                        vm.proList[i].itemValueLines[j].selected = true;
                                    }
                                }
                                if(!vm.proList[i].itemValueLines[j].selected){
                                    vm.proList[i].selected = false;
                                }
                            }
                            if(!vm.proList[i].selected){
                                vm.selectedAll = false;
                            }
                        }
                    }else {
                        vm.selectedAll = false;
                        for (var j = vm.proList.length - 1; j >= 0; j--) {
                            vm.proList[j].selected = false;
                        }
                    }
                })


            }
            // 编辑
            if(vm.payBatch.purchaseType=='COMMODITYPURCHASE'){
                // ItemValueLinePackage.getPackage({
                //     purchaseId:vm.payBatch.productPurchaseId,
                //     itemValueLineId:vm.payBatch.itemValueLineId,
                // },function (data) {
                //     vm.payBatch.packageId = data.id;
                //     vm.maxStockQuantity = data.stockQuantity;
                // })
            }else if(vm.payBatch.purchaseType=='GIFTPURCHASE' || vm.payBatch.purchaseType=='EXCHANGEPURCHASE'){
                // ItemValueLinePackage.getPackage({
                //     purchaseId:vm.payBatch.productPurchaseId
                // },function (data) {
                //     vm.payBatch.packageId = data.id;
                //     vm.maxStockQuantity = data.stockQuantity;
                // })
            }

            if(vm.payBatch.id){
                $scope.payBatchTypeChange();
            }

        })

        $scope.selectAll = function(){
            vm.selectedAll = !vm.selectedAll;
            for (var i = vm.proList.length - 1; i >= 0; i--) {
                var ca = vm.proList[i];
                ca.selected = vm.selectedAll;
                if (ca.itemValueLines) {
                    for (var j = ca.itemValueLines.length - 1; j >= 0; j--) {
                        var p = ca.itemValueLines[j];
                        p.selected = vm.selectedAll
                    }
                }
            }
        }

        $scope.productAll = function(pro){
            pro.selected = !pro.selected;
            if (pro.itemValueLines) {
                for (var i = pro.itemValueLines.length - 1; i >= 0; i--) {
                    var p = pro.itemValueLines[i];
                    p.selected = pro.selected;
                }
            }
            for (var i = vm.proList.length - 1; i >= 0; i--) {
                var arr = vm.proList[i];
                if (!arr.selected) {
                    vm.selectedAll = false;
                    return;
                }
            }
            vm.selectedAll = true;
        }

        $scope.selectProduct = function(pro, ivl){
            ivl.selected = !ivl.selected;
            for (var i = pro.itemValueLines.length - 1; i >= 0; i--) {
                var p = pro.itemValueLines[i];
                if (!p.selected) {
                    pro.selected = false;
                    vm.selectedAll = false;
                    return;
                }
            }
            pro.selected = true;
            for (var i = vm.proList.length - 1; i >= 0; i--) {
                var arr = vm.proList[i];
                if (!arr.selected) {
                    vm.selectedAll = false;
                    return;
                }
            }
            vm.selectedAll = true;
        }

        $scope.rangeChange = function(){
            vm.payBatch.productId = null;
            vm.payBatch.productName = null;
        }

        $scope.productChange = function(){
            vm.payBatch.productName = null;
            if (vm.payBatch.productId == null || vm.products == null || vm.products.length == 0) {
                vm.payBatch.productId == null;
                return;
            }
            vm.itemValueLines = [];
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var p = vm.products[i];
                if (p.cancelled) {
                    continue;
                }
                if (p.productId == vm.payBatch.productId) {
                    vm.payBatch.productName = p.productName;
                    vm.itemValueLines = angular.copy(p.itemValueLines);
                    break;
                }
            }
        }

        $scope.skuChange = function () {
            ItemValueLinePackage.getPackage({
                purchaseId:vm.payBatch.productPurchaseId,
                itemValueLineId:vm.payBatch.itemValueLineId,
            },function (data) {
                vm.payBatch.packageId = data.id;
                vm.maxStockQuantity = data.stockQuantity;
                vm.payBatch.productSku = data.itemValueLines[0].sku;
            })
        }

        $scope.generateCode = function (e) {
            fn(e);
            var random = Math.floor(Math.random()*9000) + 1000;
            confirmNumber(random);

        }
        function confirmNumber(random) {
            var str = vm.uniqueTimeStr+""+random;
            if(!vm.payBathAll.length){
                vm.payBatch.number = str;
                return
            }
            angular.forEach(vm.payBathAll,function (p,i) {
                if(p.number.indexOf(str)>=0){
                    random = Math.floor(Math.random()*9000) + 1000;
                    confirmNumber(random);
                    return
                }
                if(i==vm.payBathAll.length-1){
                    vm.payBatch.number = str;
                    return
                }
            })
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
        $scope.validate = function() {
            if ( vm.payBatch.periodOfValidity == null || vm.payBatch.periodOfValidity == "") {
                MessageService.error("请输入有效时间");
                return false;
            }

            if ( vm.payBatch.startDate == null || vm.payBatch.startDate == "") {
                MessageService.error("请输入有效时间");
                return false;
            }


            if (vm.payBatch.periodOfValidity.getTime() < new Date().getTime()) {
                MessageService.error("支付码有效期不能是当天或之前的日期");
                return false;
            }

            if (vm.payBatch.payBatchType == null || vm.payBatch.payBatchType == "") {
                MessageService.error("请选择类型");
                return false;
            }


            if (vm.payBatch.remark == null || vm.payBatch.remark == "") {
                MessageService.error('请输入用途');
                return false;
            }
             
            if (vm.payBatch.number == null || vm.payBatch.number == "") {
                MessageService.error('请输入批次号');
                return false;
            }

            if (vm.payBatch.quantity == null || vm.payBatch.quantity == "") {
                MessageService.error('请输入将要生成支付码数量');
                return false;
            }

            if (vm.payBatch.startNumber == null || vm.payBatch.startNumber == "") {
                MessageService.error('请输入起始卡号');
                return false;
            }

            if (/^([0-9]+)$/.test(vm.payBatch.startNumber.substring(1)) && /[a-zA-Z]+/.test(vm.payBatch.startNumber.substring(0,1))) {
            }else{
                MessageService.error("输入的起始卡号不符合规则");
                return false;
            }
            if (vm.payBatch.startNumber.length>15) {
                MessageService.error("起始卡号不能超过15个字符");
                return false;
            }
            // if(vm.payBatch.payBatchType == 'PAYCODE'&&vm.payBatch.length==null||vm.payBatch.length==''){
            //     MessageService.error("请选择卡密位数");
            //     return false;
            // }
            return true;
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.payBatch.prototype = 'StoreSelf';
            vm.payBatch.itemValueLines = [];
            var proName = "";
            var skuName = "";
            angular.forEach(vm.proList,function (pro) {
                var isChoose = false;
                angular.forEach(pro.itemValueLines,function (ivl,i) {
                    if(ivl.selected){
                        isChoose = true;
                        vm.payBatch.itemValueLines.push({
                            id:ivl.id,
                            sku:ivl.sku,
                            skuNumber:ivl.skuNumber
                        })
                        skuName+=pro.name+'('+ivl.sku+');'
                    }
                })
                if(isChoose){
                    proName+=pro.name+';';
                }
            })
            vm.payBatch.productName = proName.substring(0,proName.length-1);
            vm.payBatch.productSku = skuName.substring(0,skuName.length-1);

            if (vm.payBatch.productName == null || vm.payBatch.productName == "") {
                MessageService.error('请选择商品');
                return false;
            }

            console.log(vm.payBatch)
            // return
            if(!$scope.validate()){
                return;
            }
            vm.payBatch.startDate = DateUtils.convertLocalDateToServer(vm.payBatch.startDate);
            vm.payBatch.periodOfValidity = DateUtils.convertLocalDateToServer(vm.payBatch.periodOfValidity);

            PayBatch.getAllByCondition({
                items: [{key:"number", op:"=", value:vm.payBatch.number}]
            }, function(data){
                if (vm.payBatch.id !== null) {
                    if ((data.length ==1 && data[0].id != vm.payBatch.id) || data.length>1) {
                        MessageService.error("批次号重复，请重新设定批次号");
                    }else{
                        vm.isSaving = true;
                        PayBatch.storeSelfProduct(vm.payBatch, onSaveSuccess, onSaveError);
                    }
                } else {
                    if (data.length >0) {
                        MessageService.error("批次号重复，请重新设定批次号");
                    }else{
                        vm.isSaving = true;
                        PayBatch.storeSelfProduct(vm.payBatch, onSaveSuccess, onSaveError);
                    }

                }
            }, function(){
                MessageService.error("检查批次号失败，请稍后再试");
            });


        }

        function save2 () {
            // vm.isSaving = true;
            // PayBatch.update2(vm.payBatch, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:payBatchUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success('添加成功');
        }

        function onSaveError () {
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');
            MessageService.error("系统请求超时，请稍后刷新系统，确认是否添加成功");
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
