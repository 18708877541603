(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('taskbill-forms', {
            parent: 'entity',
            url: '/taskbill-forms?page&sort&search',
            data: {
                authorities: ['ROLE_TASK_BILL_FORM'],
                pageTitle: 'backApp.taskbillForm.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/taskbill-form/taskbill-form-list.html',
                    controller: 'TaskbillFormsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('taskbillForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('taskbill-forms.new', {
            parent: 'taskbill-forms',
            url: '/new',
            data: {
                authorities: ["ROLE_TASK_BILL_FORM"],
                pageTitle: 'backApp.taskbillForm.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/taskbill-form/taskbill-form-dialog.html',
                    controller: 'TaskbillFormDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('taskbillForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function() {
                    return {
                        name: "",
                        width: 100,
                        height: 100,
                        backgroundColor: "#FFFFFF",
                        enabled: true,
                        items: []
                    };
                }
            }

        })
        .state('taskbill-forms.edit', {
            parent: 'taskbill-forms',
            url: '/{id}/edit',
            data: {
                authorities: ["ROLE_TASK_BILL_FORM"],
                pageTitle: 'backApp.taskbillForm.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/taskbill-form/taskbill-form-dialog.html',
                    controller: 'TaskbillFormDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('taskbillForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['TaskbillForms', '$stateParams', function(TaskbillForms, $stateParams) {
                    return TaskbillForms.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        ;
    }

})();
