(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotSettleController', MapDepotSettleController);

        MapDepotSettleController.$inject = ['$scope','$stateParams', '$state', 'Store','MessageService', '$timeout', '$uibModal', 'paginationConstants','$q', 'CustomerStorageSnapshots', 'ParseLinks'];

    function MapDepotSettleController ($scope, $stateParams , $state, Store, MessageService, $timeout, $uibModal, paginationConstants,$q, CustomerStorageSnapshots, ParseLinks ) {
        var vm = this;
        vm.page = 1;
        vm.loadPage = loadPage;
        vm.jump = jump;
        vm.transition = transition;
        vm.storageStoreId = $stateParams.id;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        init();
        function init(){
            var items = [{key: "userId", op: "=", value: vm.storageStoreId}];
            CustomerStorageSnapshots.byCondition1({
                items: items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tableData = res.message;
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            init()
        }
        function transition() {
            init()
        }
    }
})();
