(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('CashWalletApplications', CashWalletApplications);

    CashWalletApplications.$inject = ['$resource'];

    function CashWalletApplications ($resource) {
        var resourceUrl =  'manager/' + 'api/cash-wallet-applications/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPriceByProductAndStore': {
                method: 'GET',
                params: {
                    path: 'getPriceByProductAndStore'
                }
            },
            'byCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'byCondition'
                }
            },
            'getAllByCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'getAllByCondition'
                }
            },
            'approve': {
                method: 'GET',
                params: {
                    path: 'approve'
                }
            },
            'reject': {
                method: 'GET',
                params: {
                    path: 'reject'
                }
            },
            'offLineGrant': {
                method: 'GET',
                params: {
                    path: 'offLineGrant'
                }
            },
            'exchangeCustomer': {
                method: 'GET',
                params: {
                    path: 'exchangeCustomer'
                }
            },
            'batchApprove': {
                method: 'GET',
                params: {
                    path: 'batchApprove'
                }
            },
            'getAmount': {
                method: 'GET',
                params: {
                    path: 'getAmount'
                }
            },
            'update': { method:'PUT' }
        })
    }
})();
