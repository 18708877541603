(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SelfModalController', SelfModalController);

    SelfModalController.$inject = ['$uibModalInstance', 'LocationProvice', '$q', 'LocationCity', 'LocationDistrict','$scope', 'MessageService','StorePickLocations', 'Store', 'entity'];

    function SelfModalController ($uibModalInstance, LocationProvice, $q, LocationCity, LocationDistrict, $scope, MessageService,StorePickLocations, Store, entity) {
        var vm = this;
        vm.cancel = cancel;
        vm.store = Store.getCurrent();
        vm.data = angular.copy(entity);
        vm.provinces = [];
        vm.citys = [];
        vm.districts = [];
        vm.province = {};
        vm.city = {};
        vm.district = {};
        vm.provinces = LocationProvice.query();

        $q.all([vm.provinces.$promise]).then(function(){
            console.log()
            if(entity){
                if(entity.province){
                    vm.provinces.forEach(function (province) {
                        if(entity.province == province.name){
                            vm.province = province;
                            $scope.provinceChange();
                        }
                    })
                }
            }
        })
        $scope.provinceChange = function() {
            if(vm.province.id){
                LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                    vm.citys = data;
                    vm.districts = [];
                    if(entity.city){
                        vm.citys.forEach(function (city) {
                            if(entity.city == city.name){
                                vm.city = city;
                                $scope.cityChange();
                            }
                        })
                    }
                });
            }else{
                vm.citys = [];
                vm.districts = [];
                vm.city = {};
                vm.district = {};
            }
        }

        $scope.cityChange = function() {
            if (!vm.city || !vm.city.id) {
                vm.districts = [];
                vm.district = {};
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
                if(entity.district){
                    vm.districts.forEach(function (district) {
                        if(entity.district == district.name){
                            vm.district = district;
                            entity = {}
                        }
                    })
                }
            });
        };

        $scope.save = function () {
            vm.data.province = vm.province.name;
            vm.data.city = vm.city.name;
            vm.data.district = vm.district.name;
            vm.data.storeId = vm.store.id;
            if(!vm.data.province || !vm.data.city || !vm.data.district){
                MessageService.error("请选择地址");
                return
            }
            if(!vm.data.contact){
                MessageService.error("请输入联系名称");
                return
            }
            if(!vm.data.phone){
                MessageService.error("请输入联系电话");
                return
            }
            if(vm.data.id){
                StorePickLocations.update(vm.data, function (res) {
                    if(res.status == 200){
                        MessageService.success("编辑自提地点成功");
                        $uibModalInstance.close(true);
                    }else{
                        MessageService.error("编辑地址失败");
                    }
                })
            }else{
                StorePickLocations.save(vm.data, function (res) {
                    if(res.status == 200){
                        MessageService.success("创建自提地点成功");
                        $uibModalInstance.close(true);
                    }else{
                        MessageService.error("新增地址失败");
                    }
                })
            }
        };

        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
