(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('GoodsShelf', GoodsShelf) ;

    GoodsShelf.$inject = ['$resource'];

    function GoodsShelf ($resource) {
        var resourceUrl =  'manager/' + 'api/goods-shelves/:path/:id';

        return $resource(resourceUrl, {}, {
            'create':{
                method:"POST",
                params:{
                    path:"create"
                }
            },
            'createBatch':{
                method:"POST",
                params:{
                    path:"create-batch"
                },
                isArray:true
            },
            'byCondition':{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray: true
            },
            'getAllByCondition':{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
