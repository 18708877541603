(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('MemberOrderController', MemberOrderController);

    MemberOrderController.$inject = ['$scope', '$state','MembershipOrder','pagingParams','paginationConstants','ParseLinks'];

    function MemberOrderController ($scope, $state,MembershipOrder,pagingParams,paginationConstants,ParseLinks) {
        var vm = this;
        vm.status = status;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.membershipOrders = [];


        loadAll();

        function loadAll() {
            var items=[];
            if(vm.searchQuery){
                items.push({key: "number", op: "like", value: vm.searchQuery})
            }
            items.push({key: "cancelled", op: "=", value: false})
            MembershipOrder.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: ["createdDate,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                for (var i = 0; i <res.length; i++){
                    var order = res[i]
                    if (order.cancelled){
                        order.orderStatus = "已取消";
                    }else {
                        if (!order.paid){
                            order.orderStatus = "未支付";
                        }else {
                            order.orderStatus = "已支付";
                        }
                    }
                    if (order.packageName == 'weeklyCard'){
                        order.packageName = "周卡"
                    }else if (order.packageName == 'monthlyCard'){
                        order.packageName = "月卡"
                    }else if (order.packageName == 'quarterlyCard'){
                        order.packageName = "季卡"
                    }else if (order.packageName == 'yearlyCard'){
                        order.packageName = "年卡"
                    }
                    vm.membershipOrders.push(order);
                }
                console.log(vm.membershipOrders)
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status:vm.statusOn
            });
        }

        function search(searchQuery) {
            console.log(searchQuery)
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
    }
})();
