(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderExportDialogController', SalesOrderExportDialogController);

    SalesOrderExportDialogController.$inject = ['$http', '$timeout', '$localStorage', '$stateParams',  '$q', '$uibModalInstance', '$scope',
     'Product', 'Store', 'MessageService', 'Category', 'DateUtils', 'BranchStore', 'BranchStoreManagements'];

    function SalesOrderExportDialogController ($http, $timeout, $localStorage, $stateParams, $q, $uibModalInstance, $scope,
      Product, Store, MessageService, Category, DateUtils, BranchStore, BranchStoreManagements) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.openCalendar = openCalendar;
        vm.dateformat = dateformat;
        vm.export = exportData;
        vm.account = $localStorage.user;

        vm.clear = clear;
        vm.categorys = Category.storeGetAllByCondition({items:[]});
        vm.products = Product.getAllByCondition({items: []});
        vm.storeId = $localStorage.user.storeId;
        vm.store = Store.getCurrent();
        console.log(vm.storeId)

        vm.accountBranch =[];
        vm.branchStores =[];
        // 分店管理
        if(vm.account.authorities.indexOf("ROLE_STORE_BRANCH_SHOP")!=-1){
            vm.branchStores = BranchStore.getAllByCondition({items: [{key: "cancelled", op: "=", value: "false"},{key: "enabled", op: "=", value: "true"}]});
        }else if(vm.account.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP")!=-1){
            // // 我的分店权限
            vm.accountBranch = BranchStoreManagements.findByUserId({userId:vm.account.id})
        }

        vm.sourceTypes = [{
            name:"WECHAT",
            value:"公众号"
        },{
            name:"MINI",
            value:"小程序"
        },{
            name:"WEB",
            value:"网页端"
        },{
            name:"ALI",
            value:"支付宝"
        },{
            name:"BYTEDANCE",
            value:"抖音小程序"
        },{
            name:"JD",
            value:"京东小程序"
        }];

        $q.all([vm.categorys.$promise,vm.products.$promise,vm.store.$promise,vm.accountBranch.$promise,vm.branchStores.$promise]).then(function() {
            if(vm.accountBranch&&vm.accountBranch.branchStore){
                vm.branchStore = vm.accountBranch.branchStore;
                vm.extensionSetting = vm.branchStore.extensionSetting;
            }
            console.log(vm.accountBranch)
        });

        $scope.clickBranchStore = function(){
            vm.extensionSetting = vm.branchStore.extensionSetting;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        function exportData(){
            if (!vm.endTime || !vm.startTime) {
                MessageService.error("请录入下单时间");
                return;
            }
            if ((vm.endTime -vm.startTime)/24/3600/1000>31) {
                MessageService.error("下单时间间距不能超过31天");
                return;
            }
            var json_str = getItems();
            $http({
                url: 'service/api/sales-orders/downloadFile?items=' + JSON.stringify(json_str),
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "订单详情" + DateUtils.convertLocalDateToServer(Date.now()) + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});

        }

        function getItems() {
            var items=[];
            if (vm.startTime !=null) {
                items.push({key: "createdDate", op: ">=", value: DateUtils.convertLocalDateToServer(vm.startTime.setDate((vm.startTime.getDate()-1)))+"T16:00:00.000Z"});
            };
            if (vm.endTime !=null) {
                items.push({key: "createdDate", op: "<=", value: DateUtils.convertLocalDateToServer(vm.endTime)+"T15:59:59.000Z"});
            };

            //商品分类
            if (vm.categoryId !=null) {
                items.push({key:"orderLines.orderArticle.categoryStoreId",op:"=",value:vm.categoryId});
            };
            //商品名称
            if (vm.productName !=null) {
                items.push({key:"orderLines.orderArticle.productName",op:"=",value:vm.productName});
            };
            //商户名
            if (vm.storeId !=null) {
                items.push({key:"storeId",op:"=",value:vm.storeId});
            };
            //订单来源
            if (vm.sourceType !=null) {
                items.push({key:"sourceType",op:"=",value:vm.sourceType});
            };
            if (vm.status !=null && vm.status !="") {
                items.push({key:"status",op:"=",value:vm.status});
            };
            if(vm.branchStore!=null){
                items.push({key:"branchStoreId",op:"=",value:vm.branchStore.id});
            }
            if (vm.customer) {
                items.push({key:"createdBy",op:"like",value:vm.customer});
            }

            return items;
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }




    }
})();
