(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('LogisticsPriceController', LogisticsPriceController);

    LogisticsPriceController.$inject = ['$state', '$q', 'paginationConstants', 'MessageService', '$scope', '$uibModal', 'ExpressServiceConfig', 'StoreConfig'];

    function LogisticsPriceController($state, $q, paginationConstants, MessageService, $scope, $uibModal, ExpressServiceConfig, StoreConfig) {
        var vm = this;
        vm.data = ExpressServiceConfig.getByStore();
        vm.storeConfigs = StoreConfig.getAllByCondition({items: [{key: "type", op: "=", value: "ORDEREXPRESS"}]});
        // vm.storeCoupon = StoreConfig.byStoreIdAndTypeByStore({type: 'ORDEREXPRESS',storeId:vm.storeId});


        $q.all([vm.data.$promise,vm.storeConfigs.$promise]).then(function() {
            vm.storeOrder = {
                type:'ORDEREXPRESS',
                open: true
            };
            console.log(vm.storeConfigs)
            if(!vm.storeConfigs.length||!vm.storeConfigs[0].open){
                vm.isOrder = false;
            }else{
                vm.isOrder = true;
            }
        });

        //保存
        $scope.save = function () {
            StoreConfig.save(vm.storeOrder, function(data){
                // config = data;
                vm.isOrder = true;
                MessageService.success("保存成功！");
            }, function(){
                MessageService.error("保存失败！");
            })
        }


    }
})();
