(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('MiraclePassController', MiraclePassController);

    MiraclePassController.$inject = ['$scope', '$state'];

    function MiraclePassController ($scope, $state) {
        var vm = this;
        vm.nav = [
            {name:'口令列表',id:'pass'},
            {name:'口令订单',id:'passOrder'},
            // {name:'提现记录',id:'cashRecord'},
            {name:'口令商品池',id:'pass-product'},
            {name:'口令库',id:'passBank'},
            {name:'口令设置',id:'pass-setting'}
        ]
        setTimeout(function(){
            $scope.selectNav('pass')
        });
        vm.navActiveId = $state.current.name || 'passList';
        $scope.selectNav = function(id){
            vm.navActiveId = id;
            $state.go(id);
        }
        $scope.backTo = function(){
            sessionStorage.setItem("lmenu", 'a_marketing');
            $state.go("marketing");
            vm.lmenu = "a_marketing";
            function test(){
              $state.reload();
            }
            setTimeout(test,400);
        }
}
})();
