(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('OrderSetting', OrderSetting);

    OrderSetting.$inject = ['$resource', 'DateUtils'];

    function OrderSetting($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/timer-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            "updateStoreTimerSetting":{
                method: 'PUT',
                params:{
                    path:"updateStoreTimerSetting"
                }
            },
            "getStoreOrderSetting2":{
                method: 'GET',
                params:{
                    path:"getStoreOrderSetting2"
                }
            },
            'update': {
                method: 'PUT'
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getOrderSetting'
                }
            }
        });
    }
})();
