(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreSmallAppMessageTemplateController', StoreSmallAppMessageTemplateController);

    StoreSmallAppMessageTemplateController.$inject = ['$scope', '$state', 'StoreSmallAppMessageTemplate', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', 'Store'];

    function StoreSmallAppMessageTemplateController ($scope, $state, StoreSmallAppMessageTemplate, ParseLinks, MessageService, paginationConstants, pagingParams, Store) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.showAdd = true;
        vm.templates = [];
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.wechatTemplates = StoreSmallAppMessageTemplate.getWechatTemplateList();
        vm.smallAppTemplates = StoreSmallAppMessageTemplate.getSmallAppTemplateList();
        vm.store = Store.getCurrent();

        vm.editorId = null;

        loadAll();

        $scope.templateIdChange = function(temp){
            if (!vm.templates) {
                return;
            }
            for (var i = vm.templates.length - 1; i >= 0; i--) {
                var t = vm.templates[i];
                if (t.template_id == temp.templateId || t.priTmplId == temp.templateId ) {
                    temp.name = t.title;
                    return;
                }
            }
        }

        $scope.sourceChange = function(temp){
            if (temp.source == 'wechat') {
                if (vm.wechatTemplates != null && !!vm.wechatTemplates.template_list) {
                    vm.templates =  vm.wechatTemplates.template_list;
                }else{
                    vm.templates = [];
                }

            }else{
                if (vm.smallAppTemplates != null && !!vm.smallAppTemplates.data) {
                    vm.templates = vm.smallAppTemplates.data;
                }else{
                    vm.templates = [];
                }

            }

        }


        $scope.deleteTemp = function(temp){
            MessageService.confirm("确认要删除该数据", function(){
                StoreSmallAppMessageTemplate.delete({id:temp.id},function(data){
                    MessageService.success("删除成功");
                    loadAll();
                })
            })
        }

        $scope.editTemp = function(temp){
            vm.editorId = temp.id
            $scope.sourceChange(temp);
            vm.showAdd = false;
        }

        $scope.validate = function(temp){

            if (!temp.templateId) {
                MessageService.error("请选择消息模板");
                return false;
            }

            if (!temp.type) {
                MessageService.error("选择发送消息类型");
                return false;
            }
            return true;
        }

        $scope.addTemplated = function(){
            vm.showAdd = false;
            if (vm.storeSmallAppMessageTemplates == null) {
                vm.storeSmallAppMessageTemplates = [];
            }
            vm.storeSmallAppMessageTemplates.push({added:true})
        }

        $scope.saveTemp = function(temp){
            if (!$scope.validate(temp)) {
                return;
            }
            if (temp.added) {
                StoreSmallAppMessageTemplate.save(temp, function(data){
                    vm.showAdd = true;
                    vm.editorId = null;
                    loadAll ();
                },function(){})
            }else{
                 StoreSmallAppMessageTemplate.update(temp, function(data){
                    vm.showAdd = true;
                    vm.editorId = null;
                    loadAll ();
                },function(){})
            }
        }

        function loadAll () {
            StoreSmallAppMessageTemplate.getAllByCondition({
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                // vm.totalItems = headers('X-Total-Count');
                // vm.queryCount = vm.totalItems;
                vm.storeSmallAppMessageTemplates = res;
                // vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
