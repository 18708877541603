(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('Register2Controller', Register2Controller);


    Register2Controller.$inject = ['$translate', '$timeout', 'UidService', 'Qinius', 'Store', '$scope', '$state', 'MessageService',
        '$q','PicManagements'];

    function Register2Controller($translate, $timeout, UidService, Qinius, Store, $scope, $state, MessageService,
                                $q,  PicManagements) {
        var vm = this;

        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.save = save;
        vm.store = Store.getCurrent();;
        $(".wrap").hide();
        vm.formInput=null;
        vm.isSaving = false;

        vm.pic = PicManagements.getAllByCondition({items:[{key:"pageSite", op :"=", value:'register'}],sort: ["seq,asc"]});
        $q.all([vm.domains.$promise,vm.pic.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            if(vm.pic && vm.pic.length>0){
                $timeout(function () {
                    new Swiper('.swiper-container', {
                        spaceBetween: 20,
                        autoplay:{
                            disableOnInteraction: false,
                        }
                    });
                },1000)
            }
        })


        $scope.formInputFocus = function (item) {
            vm.formInput=item;
        }
        $scope.formInputBlur = function () {
            vm.formInput=null;
        }
        $scope.selectType = function (type) {
            vm.store.storeType = type;
        }

        $timeout(function () {
            angular.element('#name').focus();
        });

        $scope.validatePerson = function () {
            if (vm.store.contact == null || vm.store.contact == "") {
                MessageService.error("请输入注册姓名");
                return false;
            }

            if (vm.store.idCard == null || vm.store.idCard == "") {
                MessageService.error("请输入证件号码");
                return false;
            }
            if (vm.store.corporationCardFront == null || vm.store.corporationCardFront == "") {
                MessageService.error("请上传证件正面照");
                return false;
            }
            if (vm.store.corporationCardBack == null || vm.store.corporationCardBack == "") {
                MessageService.error("证件反面照");
                return false;
            }
            return true;
        };

        $scope.validateCompany = function () {
            if (vm.store.companyName == null || vm.store.companyName == "") {
                MessageService.error("请输入公司名称");
                return false;
            }

            if (vm.store.corporation == null || vm.store.corporation == "") {
                MessageService.error("请输入法人代表姓名");
                return false;
            }

            if (vm.store.companyCode == null || vm.store.companyCode == "") {
                MessageService.error("请输入企业营业执照编号");
                return false;
            }

            if (vm.store.businessLicenseImg == null || vm.store.businessLicenseImg == "") {
                MessageService.error("请上传营业执照");
                return false;
            }
            return true;
        };

        function save() {
            if (vm.store.storeType == 'Company') {
                if (!$scope.validateCompany()) {
                    return;
                }
                vm.store.idCard = null;
            } else {
                if (!$scope.validatePerson()) {
                    return;
                }
                vm.store.companyName = null;
                vm.store.companyCode = null;
                vm.store.businessLicenseImg = null;
            }

            Store.update(vm.store, function(data){
                $state.go('openAccount-home');
            }, function(){
            })
            
        }
      
        window.updateImgFile = function ($event,attr) {
            console.log(attr);
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0],attr);
            }
        }

        function qiniuUpload(file,attr) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "register-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store[attr] = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        //remove背景图
        $scope.removeFile = function(attr) {
            vm.store[attr] = null;
        };
    }
})();
