(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupOverviewController', GroupOverviewController);

    GroupOverviewController.$inject = ['$state', 'ProductPurchase', 'ParseLinks',  'paginationConstants', 'MessageService'
    ,'$scope', 'StoreGroupBuys', 'Qinius', 'CustomerGroupBuy'];

    function GroupOverviewController($state, ProductPurchase, ParseLinks,   paginationConstants, MessageService,
        $scope, StoreGroupBuys, Qinius, CustomerGroupBuy) {
        var vm = this;

        CustomerGroupBuy.getNum({}, function (res) {
            vm.data = res.message;
        })
    }
})();
