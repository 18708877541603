(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('PromotionCommissionController', PromotionCommissionController);

    PromotionCommissionController.$inject = ['$scope', '$state'];

    function PromotionCommissionController ($scope, $state) {
        var vm = this;
        
    }
})();
