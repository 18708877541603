(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StorePaymentSettingDetailController', StorePaymentSettingDetailController);

    StorePaymentSettingDetailController.$inject = ['$scope', '$q', '$state', '$rootScope', '$stateParams', 'entity', 'Store',
    'StoreWechatSetting', 'StoreSmallAppWechatSetting', 'StorePaymentSetting','Principal','MessageService', 'StorePickLocations'];

    function StorePaymentSettingDetailController($scope, $q, $state, $rootScope, $stateParams, entity, Store,
        StoreWechatSetting, StoreSmallAppWechatSetting, StorePaymentSetting,Principal,MessageService, StorePickLocations) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });

        vm.store = entity;
        vm.selectTab = 1;
        vm.storePaymentSetting = StorePaymentSetting.getCurrent();

        $scope.payMentOPshow = function(index) {
            vm.selectTab = index;
        }

        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        $q.all([vm.store.$promise]).then(function(){
            console.log(vm.store)
            if (vm.store.payType) {
                if (vm.store.payType.indexOf("paycode")>= 0 ) {
                    vm.store.paycode = true;
                }
                if (vm.store.payType.indexOf("wechat")>= 0 ) {
                    vm.store.wechat = true;
                }
                if (vm.store.payType.indexOf("underLine")>= 0 ) {
                    vm.store.underLine = true;
                }
                if (vm.store.payType.indexOf("giftVoucher")>= 0 ) {
                    vm.store.giftVoucher = true;
                }
                if (vm.store.payType.indexOf("balancePay")>= 0 ) {
                    vm.store.balancePay = true;
                }
            }
            if (vm.store.storeType2) {
                if (vm.store.storeType2.indexOf("WECHAT" )>=0) {
                    vm.store.storeTypeWechat = true;
                }
                if (vm.store.storeType2.indexOf("MINI" )>=0) {
                    vm.store.storeTypeMini = true;
                }
                if (vm.store.storeType2.indexOf("THIRD" )>=0) {
                    vm.store.storeTypeThird = true;
                }
            }

            vm.wechatSetting = StoreWechatSetting.getByStoreId({storeId:vm.store.id});
            vm.miniSetting = StoreSmallAppWechatSetting.getEntityByStoreId({storeId:vm.store.id});
            $q.all([vm.miniSetting.$promise , vm.wechatSetting.$promise]).then(function(){
                if (!vm.wechatSetting.id) {
                    vm.selectTab = 2;
                }
                if (!vm.miniSetting.id) {
                    vm.selectTab = 3;
                }
            });

        });

        //自提地点
        vm.storePickLocations = [];
        getStorePickLocation();
        function getStorePickLocation(){
            StorePickLocations.getAllByCondition({items:[]}, function (res) {
                vm.storePickLocations = res;
            })
        }

        var unsubscribe = $rootScope.$on('backApp:storeUpdate', function(event, result) {
            vm.store = result;
        });
        $scope.$on('$destroy', unsubscribe);
        // 粘贴
        vm.copyUrl2=function(){
            var Url2 = document.getElementById("biao1");
            Url2.select(); // 选择对象 
            document.execCommand("Copy"); // 执行浏览器复制命令 
            MessageService.success("已复制好，可贴粘");
        }
    }
})();
