(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('Register1Controller', Register1Controller);


    Register1Controller.$inject = ['$translate', '$timeout','UidService', 'StoreUser', 'Qinius', 'Store', '$scope', '$state', 'MessageService', 'VerifyCode',
        '$q', '$interval', '$uibModal'];

    function Register1Controller($translate, $timeout,UidService, StoreUser, Qinius, Store, $scope, $state, MessageService, VerifyCode,
                                $q, $interval, $uibModal) {
        var vm = this;

        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.register = register;
        vm.save = save;
        vm.store = {};
        vm.store.storeType = "Person";
        vm.store.incident = "新用户注册";
        vm.success = null;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        $(".wrap").hide();
        vm.step = 1;
        vm.agree = false;

        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        })

        vm.datePickerOpenStatus.durationOfOperationTo = false;

        $scope.selectType = function (type) {
            vm.store.storeType = type;
        }

        $timeout(function () {
            angular.element('#name').focus();
        });

        var createModal = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };

        //用户协议
        $scope.tempInfo = function (temp) {
            createModal('app/account/register/register_info.html', 'RegisterInfoController').result.then(
                function (data) {
                    if(data){
                        vm.agree = true;
                    }else {
                        vm.agree = false;
                    }
                });
        };

        $scope.validate = function () {
            if (vm.store.name == null || vm.store.name == "") {
                MessageService.error("请输入商店名称");
                return false;
            }
            ;

            if (vm.store.storeType == nu || vm.store.storeType == "") {
                MessageService.error("请选择注册者主体类型");
            }
            ;
            return true;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function register() {
            if (vm.store.phone == null || vm.store.phone == '') {
                MessageService.error("请输入正确的手机号码");
                return
            }
            if (vm.store.verifyCode == null || vm.store.verifyCode == '') {
                MessageService.error("请输入正确的验证码");
                return
            }

            if (vm.store.password !== vm.confirmPassword) {
                MessageService.error("两次密码输入不一致。");
                return
            }

            VerifyCode.check({
                phone: vm.store.phone,
                code: vm.store.verifyCode,
            }, function (msg) {
                if (msg.status == 200 && msg.message == true) {
                    vm.store.langKey = $translate.use();
                    vm.doNotMatch = null;
                    vm.error = null;
                    vm.errorUserExists = null;
                    vm.errorEmailExists = null;
                    StoreUser.checkUser({
                        items: [{key: "name", op: "=", value: vm.store.userName}]
                    }, function (data) {
                        if (data.length > 0) {
                            MessageService.error("该账号已经存在，请选择其他用户名");
                        } else {
                            // 进入实名认证
                            vm.step = 2;
                        }
                    }, function () {
                        MessageService.error("校验用户名时出错");
                    });
                } else {
                    MessageService.error("验证码输入有误");
                }
            }, function () {
                MessageService.error("验证码输入有误");
            });
        }


        $scope.paracont = "获取验证码";
        vm.disabled = false;
        //验证码的定时器
        var timer = null;
        $scope.getVerifyCode = function () {
            if (vm.store.phone == null || vm.store.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.store.phone.length != 11) {
                MessageService.error("请输入正确的手机号码");
                return;
            }
            if (vm.disabled) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            VerifyCode.getVerifyCode2({
                phone: vm.store.phone,
                incident: "新用户注册"
            }, function (msg) {
                clearInterval(timer);
                timer = $interval(function () {
                    if (vm.countdown > 0) {
                        vm.disabled = true;
                        vm.countdown--;
                        $scope.paracont = "重新发送(" + vm.countdown + ")";
                    } else {
                        vm.disabled = false;
                        $interval.cancel(timer);
                        $scope.paracont = "重发验证码";
                    }
                }, 1000);
            });
        };

        $scope.validatePerson = function () {
            if (vm.store.corporation == null || vm.store.corporation == "") {
                MessageService.error("请输入注册姓名");
                return false;
            }

            if (vm.store.idCard == null || vm.store.idCard == "") {
                MessageService.error("请输入证件号码");
                return false;
            }
            if (vm.store.corporationCardFront == null || vm.store.corporationCardFront == "") {
                MessageService.error("请上传证件正面照");
                return false;
            }
            if (vm.store.corporationCardBack == null || vm.store.corporationCardBack == "") {
                MessageService.error("证件反面照");
                return false;
            }

            return true;
        };

        $scope.validateCompany = function () {
            if (vm.store.companyName == null || vm.store.companyName == "") {
                MessageService.error("请输入公司名称");
                return false;
            }

            if (vm.store.contact == null || vm.store.contact == "") {
                MessageService.error("请输入联系人信息");
                return false;
            }

            if (vm.store.address == null || vm.store.address == "") {
                MessageService.error("请输入地址信息");
                return false;
            }

            if (vm.store.corporation == null || vm.store.corporation == "") {
                MessageService.error("请输入法人代表姓名");
                return false;
            }

            if (vm.store.corporationCardFront == null || vm.store.corporationCardFront == "") {
                MessageService.error("请上传证件正面照");
                return false;
            }
            if (vm.store.corporationCardBack == null || vm.store.corporationCardBack == "") {
                MessageService.error("证件反面照");
                return false;
            }

            if (vm.store.companyCode == null || vm.store.companyCode == "") {
                MessageService.error("请输入企业营业执照编号");
                return false;
            }

            if (vm.store.businessLicenseImg == null || vm.store.businessLicenseImg == "") {
                MessageService.error("请上传营业执照");
                return false;
            }

            return true;
        };

        function save() {
            if (vm.store.storeType == 'Company') {
                if (!$scope.validateCompany()) {
                    return;
                }
                vm.store.idCard = null;
            } else {
                if (!$scope.validatePerson()) {
                    return;
                }
                vm.store.companyName = null;
                vm.store.contact = null;
                vm.store.address = null;
                vm.store.companyCode = null;
                vm.store.businessLicenseImg = null;
            }
            vm.store.roleId = '2';
            vm.store.activated = 0;
            Store.create(vm.store, function (response) {
                MessageService.success("注册成功，请在审核通过后登录。");
                $state.go('home');
            }, function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                } else if (response.status === 400 && response.data === 'email address already in use') {
                    vm.errorEmailExists = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                }
            });

        }

        window.updateImgFile = function ($event,attr) {
            console.log(attr);
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0],attr);
            }
        }

        function qiniuUpload(file,attr) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'store-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store[attr] = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        //remove背景图
        $scope.removeFile = function(attr) {
            vm.store[attr] = null;
        };

        $scope.agreeRegister = function () {
            vm.agree=!vm.agree;
        }
    }
})();
