(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('item-value-line-package', {
            parent: 'entity',
            url: '/item-value-line-package?page&sort&search&productId',
            data: {
                authorities: ['ROLE_STORE_INVENTORY'],
                pageTitle: 'backApp.itemValueLinePackage.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/item-value-line-package/item-value-line-packages.html',
                    controller: 'ItemValueLinePackageController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        productId:$stateParams.productId
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('itemValueLinePackage');
                    $translatePartialLoader.addPart('purchaseType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('item-value-line-package-detail', {
                parent: 'item-value-line-package',
                url: '/{id}/{date}?page&sort&search&startTime&endTime',
                data: {
                    authorities: ['ROLE_BILLING_DETAIL'],
                    pageTitle: 'backApp.itemValueLinePackage.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/item-value-line-package/item-value-line-package-detail.html',
                        controller: 'ItemValueLinePackageDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            startTime: $stateParams.startTime,
                            endTime:$stateParams.endTime,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('itemValueLinePackage');
                        $translatePartialLoader.addPart('purchaseType');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'item-value-line-package',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('item-value-line-package-detail.edit', {
            parent: 'item-value-line-package-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/item-value-line-package/item-value-line-package-dialog.html',
                    controller: 'ItemValueLinePackageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ItemValueLinePackage', function(ItemValueLinePackage) {
                            return ItemValueLinePackage.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            .state('item-value-line-package.new', {
            parent: 'item-value-line-package',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/item-value-line-package/item-value-line-package-dialog.html',
                    controller: 'ItemValueLinePackageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                quantity: null,
                                stockQuantity: null,
                                locked: null,
                                packageType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('item-value-line-package', null, { reload: 'item-value-line-package' });
                }, function() {
                    $state.go('item-value-line-package');
                });
            }]
        })
            .state('item-value-line-package.edit', {
            parent: 'item-value-line-package',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/item-value-line-package/item-value-line-package-dialog.html',
                    controller: 'ItemValueLinePackageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ItemValueLinePackage', function(ItemValueLinePackage) {
                            return ItemValueLinePackage.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('item-value-line-package', null, { reload: 'item-value-line-package' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            .state('item-value-line-package.delete', {
            parent: 'item-value-line-package',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/item-value-line-package/item-value-line-package-delete-dialog.html',
                    controller: 'ItemValueLinePackageDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ItemValueLinePackage', function(ItemValueLinePackage) {
                            return ItemValueLinePackage.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('item-value-line-package', null, { reload: 'item-value-line-package' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
