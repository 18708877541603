(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesAnalysisController', SalesAnalysisController);

    SalesAnalysisController.$inject = ['$scope', '$state', 'ParseLinks', 'MessageService', 'Da', 'SalesOrder', 'XlsxService'];

    function SalesAnalysisController ($scope, $state, ParseLinks, MessageService, Da, SalesOrder, XlsxService) {
        var vm = this;
        vm.openCalendar = openCalendar;
        vm.changeType = changeType;
        vm.changeTime = changeTime;
        vm.changeTopType = changeTopType;
        vm.fondByTime = fondByTime;
        vm.downLoadFile = downLoadFile;

        // 当前日期
        var today = new Date();
        today.setDate(today.getDate() - 1);
        var afterDay = today.getFullYear() + "-" + ((today.getMonth() + 1) > 9 ? (today.getMonth() + 1) : "0" + (today.getMonth() + 1)) + "-" + today.getDate();

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        // 初始化页面数据
        initData();
        function initData() {
            // 昨日的订单数和销售额
            SalesOrder.getOrderNumAndSalesNum({}, function (data) {
                if (data.message == null) {
                    vm.orderSize = 0;
                    vm.salesMoney = 0.0;
                } else {
                    var str = data.message.split(",");
                    vm.orderSize = str[0];
                    vm.salesMoney = str[1];
                }
            });
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.pageType = 0;
        function changeTopType(num) {
            vm.pageType = num;
            changeTime();
        }

        //获得区间内所有日期  
        function getMonthBetween(start, end){  
            var result = [];  
            var s = start.split("-");  
            var e = end.split("-");  
            var min = new Date();  
            var max = new Date();  
            min.setFullYear(s[0],s[1]);  
            max.setFullYear(e[0],e[1]);  
              
            var curr = min;  
            while(curr <= max){  
                var month = curr.getMonth();
                if (month == 0) {
                    result.push((curr.getFullYear() - 1) + "-12");
                } else {
                    result.push(curr.getFullYear() + "-" + (month < 10 ? ("0"+month) : month));  
                }
                curr.setMonth(month + 1);  
            }  
            return result;  
        }

        // 时间部分默认数据
        vm.dataType = 'day';
        vm.times = {time1 : {key: 1, value: '最近7日'}, time2 : {key: 2, value: '最近15日'}, time3 : {key: 3, value: '最近30日'}};
        vm.time = vm.times.time1;
        function seriesData(startTime, endTime) {
            if (endTime == '' || endTime == null) {
                endTime = vm.endTime;
            }
            if (vm.dataType == 'quarter') {
                // 季度
                var td = new Date();
                if (vm.timeKey == 1) {
                    startTime = td.getFullYear() + '-01-01';
                    endTime = td.getFullYear() + '-03-31';
                } else if (vm.timeKey == 2) {
                    startTime = td.getFullYear() + '-04-01';
                    endTime = td.getFullYear() + '-06-30';
                } else if (vm.timeKey == 3) {
                    startTime = td.getFullYear() + '-07-01';
                    endTime = td.getFullYear() + '-09-30';
                } else if (vm.timeKey == 4) {
                    startTime = td.getFullYear() + '-10-01';
                    endTime = td.getFullYear() + '-12-30';
                }
                vm.startTime = startTime;
                vm.endTime = endTime;
            }
            
            var bd = new Date(startTime),be = new Date(vm.endTime);
            var bd_time = bd.getTime(), be_time = be.getTime(),time_diff = be_time - bd_time;  
            vm.date_arr = [];
            if (vm.dataType == 'day') {
                for (var i = 0; i <= time_diff; i += 86400000) {  
                        var ds = new Date (bd_time + i);

                        vm.date_arr.push(ds.getFullYear() + "-" + ((ds.getMonth() + 1) > 9 ? (ds.getMonth() + 1) : "0"+(ds.getMonth() + 1)) + '-' 
                            + (ds.getDate() > 9 ? ds.getDate() : "0" + ds.getDate()))  
                }
            } else if (vm.dataType == 'month' || vm.dataType == 'quarter') {
                    vm.date_arr = getMonthBetween(startTime, endTime);
                    if (vm.dataType == 'quarter') {
                        var endTime0 = new Date();
                        endTime0 = new Date(endTime0.setDate(endTime0.getDate() - 1));
                        vm.startTime = startTime;
                        vm.endTime = endTime;
                    }
                }

            vm.datas = [];
            if (vm.pageType == 0) {
                // 获取订单数ju
                SalesOrder.getOrderNums({
                    startTime: startTime,
                    endTime: vm.endTime,
                    type: vm.dataType
                }, function (data) {
                    if (data.message != null) {
                        vm.datas = data.message.data;
                        echarts_init(vm.date_arr, vm.datas);
                    }
                });
            } else if (vm.pageType == 1) {
                // 获取销售额数据
                SalesOrder.getSalesNums({
                    startTime: startTime,
                    endTime: vm.endTime,
                    type: vm.dataType
                }, function (data) {
                    vm.datas = data.message.data;
                    echarts_init(vm.date_arr, vm.datas);
                });
            }
        }

        // 画图部分
        function echarts_init(date_arr, datas) {
            var myChart = echarts.init(document.getElementById("main"));
                // 指定图表的配置项和数据
                var option = {
                    xAxis: {
                        type: 'category',
                        data: date_arr
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: datas,
                        type: 'line',
                        lineStyle:{
                            normal: {
                                color: '#FF5001'
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: '#FF5001'
                            }
                        },
                    }]
                };

                // 使用刚指定的配置项和数据显示图表。  
                myChart.setOption(option);
        }

        // 时间部分选择联动处理
        changeType('day');
        function changeType (type) {
            vm.dataType = type;

            if (type == 'month') {
                vm.times = {
                    time1 : {key: 1, value: '3个月'},
                    time2 : {key: 2, value: '6个月'},
                    time3 : {key: 3, value: '9个月'},
                    time4 : {key: 4, value: '12个月'}
                };
                vm.time = vm.times.time1;

            } else if (type == 'day') {
                vm.times = {
                    time1 : {key: 1, value: '最近7日'},
                    time2 : {key: 2, value: '最近15日'},
                    time3 : {key: 3, value: '最近30日'}
                };
                vm.time = vm.times.time1;
            } else if (type == 'quarter') {
                vm.times = {
                    time1 : {key: 1, value: '第一季度'},
                    time2 : {key: 2, value: '第二季度'},
                    time3 : {key: 3, value: '第三季度'},
                    time4 : {key: 4, value: '第四季度'}
                };
                vm.time = vm.times.time1;
            }
            changeTime();
        }

        // 选择时间下拉框
        function changeTime () {
            /** 日统计情况：需要计算开始和结束的时间
                月统计情况：需要计算开始和结束的月份
                季度统计下：不要计算开始和结束的时间，但是要传是第几季度 **/

            // 今天
            var today = new Date();
            // 结束时间
            var endTime = "";
            // 开始时间
            var startTime = new Date();
            // 上个月底
            var td = new Date();
            td = new Date(td.setDate(1));
            var lastMonth = new Date(td.setDate(td.getDate() - 1));

            // 解释为什么要加10天，因为每个月总会差别2~4天 ，就索性加10天
            if (vm.time.key == 1) {
                if (vm.dataType == 'month') {
                    // 最近三个月，上个月月底为结束时间，往前推三个月
                    startTime = new Date(startTime.setMonth(lastMonth.getMonth() - 3));
                    startTime = new Date(startTime.setDate(lastMonth.getDate() + 10));
                } else if (vm.dataType == 'day') {
                    startTime.setDate(new Date().getDate() - 7);
                } else if (vm.dataType == 'quarter') {
                    // 第一季度： 当年的季度
                    vm.timeKey = 1;
                }
            } else if (vm.time.key == 2) {
                if (vm.dataType == 'month') {
                    // 最近六个月，上个月月底为结束时间，往前推六个月
                    startTime = new Date(startTime.setMonth(lastMonth.getMonth() - 6));
                    startTime = new Date(startTime.setDate(lastMonth.getDate() + 10));
                } else if (vm.dataType == 'day') {
                    startTime.setDate(new Date().getDate() - 15);
                } else if (vm.dataType == 'quarter') {
                    // 第二季度： 当年的季度
                    vm.timeKey = 2;
                }
            } else if (vm.time.key == 3) {
                if (vm.dataType == 'month') {
                    // 最近九个月，上个月月底为结束时间，往前推九个月
                    startTime = new Date(startTime.setMonth(lastMonth.getMonth() - 9));
                    startTime = new Date(startTime.setDate(lastMonth.getDate() + 10));
                } else if (vm.dataType == 'day') {
                    startTime.setDate(new Date().getDate() - 30);
                } else if (vm.dataType == 'quarter') {
                    // 第三季度： 当年的季度
                    vm.timeKey = 3;
                }
            } else if (vm.time.key == 4) {
                if (vm.dataType == 'month') {
                    // 最近十二个月，上个月月底为结束时间，往前推十二个月
                    startTime = new Date(startTime.setMonth(lastMonth.getMonth() - 12));
                    startTime = new Date(startTime.setDate(lastMonth.getDate() + 10));
                } else if (vm.dataType == 'day') {
                    // 不存在
                } else if (vm.dataType == 'quarter') {
                    // 第四季度： 当年的季度
                    vm.timeKey = 4;
                }
            }
            if (vm.dataType != 'month') {
                // 日统计的结束时间是年月日---季统计的最后时间也显示为昨天
                endTime = today;
                endTime = new Date(endTime.setDate(endTime.getDate() - 1));
            } else {
                endTime = lastMonth;
            }
            vm.endTime = endTime;
            endTime = lastMonth.getFullYear() + "-" + ((lastMonth.getMonth() + 1) > 9 ? (lastMonth.getMonth() + 1) : "0" + 
                (lastMonth.getMonth() + 1)) + "-" + lastMonth.getDate();

            vm.startTime = startTime;
            startTime = startTime.getFullYear() + "-" + ((startTime.getMonth() + 1) > 9 ? (startTime.getMonth() + 1) : "0" + (startTime.getMonth() + 1)) 
                        + "-" + (startTime.getDate() > 9 ? startTime.getDate() : ("0" + startTime.getDate()));

            seriesData(startTime, endTime);
        }

        // 点击查询
        function fondByTime () {
            if (vm.startTime == '' || vm.startTime == undefined || vm.startTime == null) {
                MessageService.error('请选择开始日期');
                return;
            }
            if (vm.endTime == '' || vm.endTime == undefined || vm.endTime == null) {
                MessageService.error('请选择结束日期');
                return;
            }

            // 时间区间判断
            // 日统计最长时间周期31天；月统计时间周期不超过24个月；季统计时间周期不超过8季度
            if (vm.dataType == 'month') {
                var date3 = vm.endTime.getMonth() - vm.startTime.getMonth();
                if (date3 > 24) {
                    MessageService.error("月统计时间周期不超过24个月");
                    return;
                }
            } else if (vm.dataType == 'day') {
                var date3 = vm.endTime.getTime() - vm.startTime.getTime();
                var days = Math.floor(date3 / (24 * 3600 * 1000));
                if (days >= 31) {
                    MessageService.error("日统计最长时间周期31天");
                    return;
                }
            } else if (vm.dataType == 'quarter') {
                var date3 = vm.endTime.getMonth() - vm.startTime.getMonth();
                if (date3 > 24) {
                    MessageService.error("季统计时间周期不超过8季度");
                    return;
                }
            }

            var startTime = vm.startTime;
            var endTime = new Date(vm.endTime);
            startTime = startTime.getFullYear() + "-" + ((startTime.getMonth() + 1) > 9 ? (startTime.getMonth() + 1) : "0" + (startTime.getMonth() + 1)) 
                        + "-" + (startTime.getDate() > 9 ? startTime.getDate() : ("0" + startTime.getDate()));
            if (endTime == null) {
                endTime = new Date(vm.endTime);
            }
            if (endTime != '' && endTime != null) {
                endTime = endTime.getFullYear() + "-" + ((endTime.getMonth() + 1) > 9 ? (endTime.getMonth() + 1) : "0" + (endTime.getMonth() + 1)) + "-" + endTime.getDate();
            }
            seriesData(startTime, endTime);
        }

        // 下载文件
        function downLoadFile() {
            var tabData = [];
            var sheetName = '';
            if (vm.pageType == 0) {
                sheetName = "订单数";
            } else {
                sheetName = "销售额";
            }

            for (var i = 0; i < vm.date_arr.length; i++) {
                tabData.push({"日期": vm.date_arr[i], "数量": vm.datas[i]});
            }
            XlsxService.xlsxOut(tabData, sheetName, "销售分析");
        }
    }
})();
