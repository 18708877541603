(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PasswordSetController', PasswordSetController);

    PasswordSetController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MessageService', 'StoreUser'];

    function PasswordSetController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MessageService, StoreUser) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.data = angular.copy(entity);
        console.log(entity)

        function save () {
            if(!vm.data.password){
                MessageService.error("密码不能为空");
                return
            }
            if (vm.data.password !== vm.confirmPassword) {
                MessageService.error("两次密码输入不一致。");
                return
            }
            vm.isSaving = true;
            StoreUser.resetPassword({id:vm.data.id,password:vm.confirmPassword},onSaveSuccess,onSaveError)
        }

        function onSaveSuccess () {
            MessageService.success("密码重置成功");
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("密码重置失败")
            $uibModalInstance.close(true);
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss(false);
        }

    }
})();
