(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('JointController', JointController);

    JointController.$inject = ['$state', 'ParseLinks', 'paginationConstants', 'MessageService', '$scope', '$uibModal', 'Store','Principal'];

    function JointController($state, ParseLinks, paginationConstants, MessageService, $scope, $uibModal, Store, Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DESIGNER_CONNETCING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.data = Store.getCurrent();

        //保存
        $scope.save = function () {
            console.log(vm.data)
        }
    }
})();
