(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductViewArticleController', ProductViewArticleController);

    ProductViewArticleController.$inject = ['$scope', '$stateParams', 'Qinius', '$http', '$q', 'ImageService', '$uibModalInstance', 'Vert',
    'FontManagement', 'ItemValue'];

    function ProductViewArticleController($scope, $stateParams, Qinius, $http, $q, ImageService, $uibModalInstance, Vert,
        FontManagement, ItemValue) {
        var vm = this;
        vm.counts = 0;
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            return arrDPI;
        }

        var unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            // return (mm * 3) - 0;
            return mm / 25.4 * vm.windowDpiW;
        };
        var unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            // return (px * 1 / 3).toFixed(2) - 0;
            return px / vm.windowDpiW * 25.4;
        };

        $scope.isShow = false;
        $scope.isFirst = true;
        $scope.isLast = false;
        var $li = $(".templateView-carousel");
        var $leftBtn = $(".leftBtn");
        var $rightBtn = $(".rightBtn");
        var $backBtn = $("#back-btn");
        vm.fontuuids = [];


        $scope.k = 0;
        $scope.t = 0;
        $scope.isText = false;
        $scope.isPull = true;

        var allWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $scope.dHeight = vm.dHeight = ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 100) / 1.8;
        $scope.dWidth = vm.dWidth = (allWidth - 220) / 1.8;
        $scope.template = {
            pages: {
                page: [{}]
            }
        };
        $scope.document = {
            pages: [{}]
        };
        var artDoc = null;
        $scope.len = 0;

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        $scope.domains = Qinius.getDomain();
        $(".modal-content").addClass('opacity0');

        vm.itemValue = ItemValue.get({id:$stateParams.itemValueId});

        Vert.xmlToDocument({
            identifier: $stateParams.fileName,
            provider: 'qiniu'
        }, function (res) {
            console.log(res);
            vm.article = angular.copy(res);
            artDoc = angular.copy(res);
            angular.forEach(artDoc.pages.page, function (page) {
                if (page.backup) {
                    return
                }
                $scope.len++;
                angular.forEach(page.levels.level,function (level) {
                    if(level.imagebox){
                        vm.counts++;
                    }
                })
            });

            getAll(res);
        });
        function getAll(doc) {
            $q.all([$scope.domains.$promise,  vm.itemValue.$promise]).then(function () {
                $scope.domain = 'http://' + $scope.domains.domains[0] + '/';
                //取得xml文档内容
                if(vm.itemValue.bindingType=='Left2'){
                    $scope.isPull = false;
                    vm.itemValue.bindingType = 'Left'
                }
                vm.bindingtype = vm.itemValue.bindingType;
                if (vm.bindingtype == 'Top') {
                    $scope.dHeight = vm.dHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 100;
                }
                localStorage.setItem('bindingtype', vm.bindingtype);
                $scope.maxW = doc.pages.page[0].mediabox.width;
                $scope.minW = doc.pages.page[0].mediabox.width;
                $scope.maxH = doc.pages.page[0].mediabox.height;
                $scope.minH = doc.pages.page[0].mediabox.height;
                $(".modal-content").addClass('opacity0');
                var arrUse = {
                    pages: {
                        page: []
                    }
                };
                var isPoster = false;
                angular.forEach(doc.pages.page, function (page) {
                    if(page.scene){
                        isPoster = true;
                    }
                    if (!page.backup) {
                        arrUse.pages.page.push(page);
                    }
                });

                if(isPoster){
                    vm.isPoster = true;
                    editorInit1(doc)
                }else {
                    vm.isPoster = false;
                    $scope.getDetail(arrUse, 0);
                }
                // $scope.getDetail(doc, 0);
            });
        }
        function transitionPx(item) {
            return item * vm.windowDpiW / 25.4;
        }
        function editorInit1(doc){
            vm.windowH = 960;
            vm.windowW = 960;
            $scope.template = angular.copy(doc);
            angular.forEach($scope.template.pages.page, function (page, index) {

                page.editbox = {
                    width: transitionPx(page.editbox.width),
                    height: transitionPx(page.editbox.height),
                    x: transitionPx(page.editbox.x),
                    y: transitionPx(page.editbox.y)
                };
                page.mediabox = {
                    width: transitionPx(page.mediabox.width),
                    height: transitionPx(page.mediabox.height),
                    x: transitionPx(page.mediabox.x),
                    y: transitionPx(page.mediabox.y)
                };
                page.trimbox = {
                    width: transitionPx(page.trimbox.width),
                    height: transitionPx(page.trimbox.height),
                    x: transitionPx(page.trimbox.x),
                    y: transitionPx(page.trimbox.y)
                };
                page.custom = {};
                if(page.scene){
                    page.scene.geometry = {
                        width: transitionPx(page.scene.geometry.width),
                        height: transitionPx(page.scene.geometry.height),
                        x: transitionPx(page.scene.geometry.x),
                        y: transitionPx(page.scene.geometry.y)
                    };
                    page.scene.imagebox.geometry = {
                        width: transitionPx(page.scene.imagebox.geometry.width),
                        height: transitionPx(page.scene.imagebox.geometry.height),
                        x: transitionPx(page.scene.imagebox.geometry.x),
                        y: transitionPx(page.scene.imagebox.geometry.y)
                    };
                    if(page.scene.resource){
                        getBackgroundSource(page.scene.resource,page.scene.geometry.width,page.scene.geometry.height,page.scene.resource);
                    }

                    if(vm.windowH / page.scene.geometry.height >= vm.windowW / page.scene.geometry.width ){
                        page.custom.ratio = vm.windowW / page.scene.geometry.width * 0.8;
                    }else {
                        page.custom.ratio = vm.windowH / page.scene.geometry.height * 0.8;
                    }
                    page.custom.width = page.scene.geometry.width;
                    page.custom.height = page.scene.geometry.height;
                }else {
                    if(vm.windowH / page.mediabox.height >= vm.windowW / page.mediabox.width ){
                        page.custom.ratio = vm.windowW / page.mediabox.width * 0.8;
                    }else {
                        page.custom.ratio = vm.windowH / page.mediabox.height * 0.8;
                    }
                    page.custom.width = page.mediabox.width;
                    page.custom.height = page.mediabox.height;
                }
                //条形码
                if(page.barcode){
                    page.barcode.geometry = {
                        width: transitionPx(page.barcode.geometry.width),
                        height: transitionPx(page.barcode.geometry.height),
                        x: transitionPx(page.barcode.geometry.x),
                        y: transitionPx(page.barcode.geometry.y)
                    };
                }

                //背景
                if(page.background.resource.identifier){
                    getBackgroundSource(page.background.resource,page.mediabox.width,page.mediabox.height,page.background.resource);
                    page.background.offsetx = transitionPx(page.background.offsetx);
                    page.background.offsety = transitionPx(page.background.offsety);
                }
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart){
                        level.imageart.offsetx = transitionPx(level.imageart.offsetx);
                        level.imageart.offsety = transitionPx(level.imageart.offsety);
                        level.imageart.geometry = {
                            width: transitionPx(level.imageart.geometry.width),
                            height: transitionPx(level.imageart.geometry.height),
                            x: transitionPx(level.imageart.geometry.x),
                            y: transitionPx(level.imageart.geometry.y)
                        };
                        level.imageart.rotation.angle = -level.imageart.rotation.angle;
                        if(level.imageart.resource.identifier){
                            getBackgroundSource(level.imageart.resource,level.imageart.geometry.width,level.imageart.geometry.height,level.imageart.resource);
                        }
                    }
                    if(level.imagebox){
                        level.imagebox.geometry = {
                            width: transitionPx(level.imagebox.geometry.width),
                            height: transitionPx(level.imagebox.geometry.height),
                            x: transitionPx(level.imagebox.geometry.x),
                            y: transitionPx(level.imagebox.geometry.y)
                        };
                        level.imagebox.rotation.angle = -level.imagebox.rotation.angle;

                        level.imagebox.bokehtypes = level.imagebox.bokehtype;
                        if(level.imagebox.image){
                            level.imagebox.image.offsetx = transitionPx(level.imagebox.image.offsetx);
                            level.imagebox.image.offsety = transitionPx(level.imagebox.image.offsety);
                            if(level.imagebox.image.resource.identifier){
                                getBackgroundSource1(level.imagebox.image.resource,level.imagebox.geometry.width,level.imagebox.geometry.height,level.imagebox.image, level.imagebox.bokehtypes)
                            }
                        }
                        if(level.imagebox.blurredrectangle){
                            level.imagebox.blurredrectangle = {
                                width: transitionPx(level.imagebox.blurredrectangle.width),
                                height: transitionPx(level.imagebox.blurredrectangle.height),
                                x: transitionPx(level.imagebox.blurredrectangle.x),
                                y: transitionPx(level.imagebox.blurredrectangle.y)
                            };
                        }
                    }
                    if(level.textbox){
                        level.textbox.geometry = {
                            width: transitionPx(level.textbox.geometry.width),
                            height: transitionPx(level.textbox.geometry.height),
                            x: transitionPx(level.textbox.geometry.x),
                            y: transitionPx(level.textbox.geometry.y)
                        };
                        level.textbox.rotation.angle = -level.textbox.rotation.angle;
                        if(level.textbox.fontuuid){
                            //下载字体
                            getFontCSS(level.textbox.fontuuid)
                        }
                        if (level.textbox.style.bold && level.textbox.style.italic) {
                            level.textbox.styles = 'BOLD_ITALIC';
                        } else if (level.textbox.style.bold) {
                            level.textbox.styles = 'BOLD';
                        } else if (level.textbox.style.italic) {
                            level.textbox.styles = 'ITALIC';
                        } else {
                            level.textbox.styles = 'NORMAL';
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = angular.copy(level.textdocument);
                        level.textdocument = {
                            background: textdocument.background,
                            geometry: {
                                width: transitionPx(textdocument.geometry.width),
                                height: transitionPx(textdocument.geometry.height),
                                x: transitionPx(textdocument.geometry.x),
                                y: transitionPx(textdocument.geometry.y)
                            },
                            rotation: {
                                angle:-textdocument.rotation.angle||0
                            },
                            current: 'textdocument',
                            levels: angular.copy(textdocument.levels),
                            lock: true,
                            transparency: textdocument.transparency,
                            movable: textdocument.movable,
                            useradded: textdocument.useradded,
                            editable: textdocument.editable,
                            scale:textdocument.geometry.width/page.mediabox.width
                        }

                        var backg = textdocument.background;
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height;
                        if(backg.resource.identifier){
                            getBackgroundSource(backg.resource,pageW,pageH,backg);
                            backg.offsetx = transitionPx(backg.offsetx);
                            backg.offsety = transitionPx(backg.offsety);
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var art = lev.imageart;
                                var newImg = new Image();
                                newImg.crossOrigin = "Anonymous";
                                newImg.src = art.resource.identifier + '?imageMogr2';
                                newImg.onload = function () {
                                    level.textdocument.levels.level[l].imageart = {
                                        geometry:{
                                            width: transitionPx(art.geometry.width),
                                            height: transitionPx(art.geometry.height),
                                            x: transitionPx(art.geometry.x),
                                            y: transitionPx(art.geometry.y)
                                        },
                                        rotation: angular.copy(art.rotation),
                                        type: art.type,
                                        resource: {
                                            identifier:art.resource.identifier,
                                            provider:art.resource.provider,
                                            width:newImg.width,
                                            height:newImg.height
                                        },
                                        scale:art.scale,
                                        offsetx: transitionPx(art.offsetx),
                                        offsety: transitionPx(art.offsety),
                                        current: 'imageart',
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        useradded: art.useradded,
                                        editable: art.editable,
                                    }
                                };
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                level.textdocument.levels.level[l].textbox = {
                                    geometry: {
                                        width: transitionPx(text.geometry.width),
                                        height: transitionPx(text.geometry.height),
                                        x: transitionPx(text.geometry.x),
                                        y: transitionPx(text.geometry.y)
                                    },
                                    identifier: text.identifier,
                                    rotation: angular.copy(text.rotation),
                                    angle: angular.copy(text.angle),
                                    status: angular.copy(text.status),
                                    style: angular.copy(text.style),
                                    type: angular.copy(text.type),
                                    text: angular.copy(text.text),
                                    fontStyle: [{
                                        style: '',
                                        path: text.identifier
                                    }],
                                    current: 'textbox',
                                    lock: true,
                                    transparency: text.transparency,
                                    fontuuid:text.fontuuid,
                                    version:text.version
                                }
                                getFontCSS(text.fontuuid)
                            }
                        })
                    }
                })
            })
            $(".save-page").hide();
        }

        $scope.getDetail = function (document, index) {
            var obj = document.pages.page[index];

            $(".carousel-wrapper").height(document.pages.page[0].mediabox.height * (document.pages.page.length + 1.7));

            $scope.template = document;
            var bgIdentifier = null;
            if (obj.background && obj.background.resource && obj.background.resource.identifier) {
                bgIdentifier = obj.background.resource.identifier + '?imageMogr2/thumbnail/!40p';
            }
            var isMaxWidth = getScale($scope.dWidth, $scope.dHeight, obj.mediabox.width, obj.mediabox.height, index);
            if(obj.background&&obj.background.color){
                obj.color = obj.background.color;
            }else if(obj.scene){
                obj.color = 'none';
            }else{
                obj.color = '#fff';
            }
            $scope.template.pages.page[index] = {
                background: angular.copy(obj.background),
                color:obj.color,
                scene:obj.scene,
                bgImg: bgIdentifier,
                barcode: angular.copy(obj.barcode),
                barcodeM: angular.copy(obj.barcode),
                barcodeRotation: {
                    angle: obj.barcodeRotation ? obj.barcodeRotation.angle : 0
                },
                mediabox: angular.copy(obj.mediabox),
                mediaboxPx: {},
                levels:angular.copy(obj.levels),
                isMaxWidth: isMaxWidth,
                spread: obj.spread,
                centerrotation: obj.centerrotation ? true : false,
                'thumbnailH': isMaxWidth ? $scope.dWidth * 0.8 * unitTransform(obj.mediabox.height) / unitTransform(obj.mediabox.width) : $scope.dHeight * 0.7 * unitTransform(obj.mediabox.width) / unitTransform(obj.mediabox.height),
                pageScaleH: 1,
                pageScaleW: 1
            };

            // $scope.template.pages.page[index].background.offsetx = unitTransform(obj.background.offsetx)
            // $scope.template.pages.page[index].background.offsety = unitTransform(obj.background.offsetx)

            // 无翻页效果
            if (document.pages.page.length < 2 || vm.bindingtype == 'None') {
                $scope.template.pages.page[index].styleScale = (vm.dWidth * 0.7 / (unitTransform(obj.mediabox.width)));
            } else {
                $scope.template.pages.page[index].styleScale = 1;
            }


            // 得到压缩比例后的最高page的高度
            if ($scope.maxPageHeight < $scope.template.pages.page[index].thumbnailH) {
                $scope.maxPageHeight = $scope.template.pages.page[index].thumbnailH;
            }
            if (obj.barcode) {
                $scope.template.pages.page[index].barcode.geometry = getGeometry(obj.barcode.geometry, index)
            }
            $scope.template.pages.page[index].mediaboxPx = getGeometry(obj.mediabox, index)
            $scope.template.pages.page[index].ratio = $scope.template.pages.page[index].mediaboxPx.width / ($scope.template.pages.page[index].mediabox.width/ 25.4 * vm.windowDpiW)

            var background = $scope.template.pages.page[index].background;
            var w = $scope.template.pages.page[index].mediaboxPx.width,
                h = $scope.template.pages.page[index].mediaboxPx.height;

            if (background&&background.resource) {
                getBackgroundSource(obj.background.resource,w,h,background)
                background.offsetXM = angular.copy(background.offsetx);
                background.offsetYM = angular.copy(background.offsety);
                background.offsetx = unitTransform(background.offsetx)* $scope.template.pages.page[index].styleScale
                background.offsety = unitTransform(background.offsety)* $scope.template.pages.page[index].styleScale
            }

            angular.forEach(obj.levels.level,function (level,l) {
                var pageLevel = $scope.template.pages.page[index].levels.level[l];
                if (level.imageart && level.imageart.geometry) {
                    var art = level.imageart;
                    pageLevel.imageart = {
                        geometry: getGeometry(art.geometry, index),
                        rotation: {
                            angle: -art.rotation.angle
                        },
                        type: art.type,
                        resource: {
                            identifier:art.resource.identifier,
                            provider:art.resource.provider
                        },
                        scale:art.scale,
                        widthM: art.geometry.width,
                        heightM: art.geometry.height,
                        XM: art.geometry.x,
                        YM:art.geometry.y,
                        offsetXM: angular.copy(art.offsetx),
                        offsetYM: angular.copy(art.offsety),
                        offsetx: unitTransform(art.offsetx)* $scope.template.pages.page[index].styleScale,
                        offsety: unitTransform(art.offsety)* $scope.template.pages.page[index].styleScale,
                        current: 'imageart',
                        levelI: l,
                        pageI: index,
                        transparency: art.transparency,
                        onlyshow: art.onlyshow,
                        movable: art.movable,
                        editable: art.editable,
                        flip: art.flip,
                    }
                    var newImg = new Image();
                    newImg.crossOrigin = "Anonymous";
                    newImg.onload = function () {
                        newImg.onload = null;
                        pageLevel.imageart.resource.width = newImg.width;
                        pageLevel.imageart.resource.height = newImg.height;
                    };
                    newImg.src = art.resource.identifier;
                }
                if (level.textbox && level.textbox.geometry) {
                    var text = level.textbox;
                    pageLevel.textbox = {
                        geometry: getGeometry(text.geometry, index),
                        identifier: text.identifier,
                        style: angular.copy(text.style),
                        rotation: {
                            angle: -text.rotation.angle
                        },
                        text: text.text,
                        heightM: text.geometry.height,
                        widthM: text.geometry.width,
                        xM: text.geometry.x,
                        yM: text.geometry.y,
                        styles: 'NORMAL',
                        type: text.type,
                        status: text.status,
                        templateType: vm.edit ? text.type : '',
                        levelI:l,
                        transparency: text.transparency,
                        lineheight: text.style.lineheight,
                        letterspacing: text.style.letterspacing,
                        movable: text.movable,
                        editable: text.editable,
                        fontuuid: text.fontuuid,
                        version:text.version
                    };
                    if(text.fontuuid){
                        //下载字体
                        if (vm.fontuuids.indexOf(text.fontuuid) == -1) {
                            vm.fontuuids.push(text.fontuuid);
                            getFontCSS(text.fontuuid)
                        }
                    }
                }

                if(level.imagebox){
                    var img = level.imagebox;
                    pageLevel.imagebox = {
                        geometry: getGeometry(img.geometry, index),
                        mask: angular.copy(img.mask),
                        imgUrl: 'content/images/shattered.png',
                        maskUrl: 'content/images/shattered.png',
                        rotation: angular.copy(img.rotation),
                        scaleW: 1,
                        scaleH: 1,
                        levelI:l,
                        bokehtype: img.bokehtype,
                        flip: img.flip,
                        image: {
                            resource: {
                                identifier: img.image ? img.image.resource.identifier : '',
                                provider: img.image ? img.image.resource.provider : 'qiniu'
                            },
                            rotation: angular.copy(img.rotation),
                            parameter: (!img.image) || img.image.parameter == null ? [] : angular.fromJson(img.image.parameter),
                            filter: (!img.image) || img.image.filter == null ? '' : img.image.filter,
                            offsetx: img.image.offsetx == 0 ? 0 : (unitTransform(img.image.offsetx) * $scope.template.pages.page[index].styleScale),
                            offsety: img.image.offsety == 0 ? 0 : (unitTransform(img.image.offsety) * $scope.template.pages.page[index].styleScale),
                            angle: img.image && img.image.angle ? -img.image.angle : 0,
                            scale: (!img.image) || img.image.scale == 1 ? 1 : img.image.scale
                        }
                    };

                    if (img.bokehtype!='None') {
                        pageLevel.imagebox.blurredrectangle = getGeometry(img.blurredrectangle, index)
                    }

                    if (img.mask.resource.identifier&&img.mask.resource.identifier!=null&&img.mask.resource.identifier!="") {
                        pageLevel.imagebox.maskUrl = img.mask.resource.identifier + '?imageMogr2';
                    }


                    if (img.image && img.image.resource.identifier) {       //qiniu
                        // imageboxes.imgUrl = $scope.domain + img.image.resource.identifier + '?imageMogr2/auto-orient/rotate/' + img.image.angle;
                        pageLevel.imagebox.imgUrl = img.image.resource.identifier + '?imageMogr2/auto-orient/thumbnail/!40p';
                        if (pageLevel.imagebox.image.angle != 0) {
                            pageLevel.imagebox.imgUrl += '/rotate/' + pageLevel.imagebox.image.angle
                        }
                    }
                }
                if (level.textdocument && level.textdocument.geometry) {
                    var textdocument = level.textdocument;
                    pageLevel.textdocument = {
                        background: textdocument.background,
                        geometry: getGeometry(textdocument.geometry,index),
                        widthM: textdocument.geometry.width,
                        heightM: textdocument.geometry.height,
                        offsetXM: textdocument.geometry.x,
                        offsetYM: textdocument.geometry.y,
                        rotation: {
                            angle:-textdocument.rotation.angle||0
                        },
                        current: 'textdocument',
                        levels: angular.copy(textdocument.levels),
                        levelI: l,
                        pageI: index,
                        lock: true,
                        transparency: textdocument.transparency,
                        movable: textdocument.movable,
                        editable: textdocument.editable
                    }

                    var backg = pageLevel.textdocument.background;
                    var pageW = pageLevel.textdocument.geometry.width,
                        pageH = pageLevel.textdocument.geometry.height;
                    if(backg.resource.identifier){
                        getBackgroundSource(backg.resource,pageW,pageH,backg)
                        backg.offsetx = unitTransform(backg.offsetx)* $scope.template.pages.page[index].styleScale
                        backg.offsety = unitTransform(backg.offsety)* $scope.template.pages.page[index].styleScale
                        backg.offsetXM = angular.copy(level.textdocument.background.offsetx);
                        backg.offsetYM = angular.copy(level.textdocument.background.offsety);
                    }

                    angular.forEach(textdocument.levels.level, function (lev, n) {
                        if (lev.imageart && lev.imageart.geometry) {
                            var art = lev.imageart;
                            pageLevel.textdocument.levels.level[n].imageart = {
                                geometry: getGeometry(art.geometry,index),
                                rotation: {
                                    angle: -art.rotation.angle
                                },
                                type: art.type,
                                resource: {
                                    identifier:art.resource.identifier,
                                    provider:art.resource.provider
                                },
                                scale:art.scale,
                                widthM: art.geometry.width,
                                heightM: art.geometry.height,
                                XM: art.geometry.x,
                                YM:art.geometry.y,
                                offsetXM: angular.copy(art.offsetx),
                                offsetYM: angular.copy(art.offsety),
                                offsetx: unitTransform(art.offsetx)* $scope.template.pages.page[index].styleScale,
                                offsety: unitTransform(art.offsety)* $scope.template.pages.page[index].styleScale,
                                current: 'imageart',
                                levelI: l,
                                pageI: index,
                                lock: false,
                                transparency: art.transparency,
                                onlyshow: art.onlyshow,
                                movable: art.movable,
                                editable: art.editable,
                            }
                            var newImg = new Image();
                            newImg.crossOrigin = "Anonymous";
                            newImg.onload = function () {
                                newImg.onload = null;
                                pageLevel.textdocument.levels.level[n].imageart.resource.width = newImg.width;
                                pageLevel.textdocument.levels.level[n].imageart.resource.height = newImg.height;
                            };
                            newImg.src = art.resource.identifier;
                        }
                        if (lev.textbox && lev.textbox.geometry) {
                            var text = lev.textbox;
                            pageLevel.textdocument.levels.level[n].textbox = {
                                geometry: getGeometry(text.geometry, index),
                                identifier: text.identifier,
                                rotation: {
                                    angle: -text.rotation.angle
                                },
                                angle: angular.copy(text.angle),
                                status: angular.copy(text.status),
                                style: angular.copy(text.style),
                                type: angular.copy(text.type),
                                text: angular.copy(text.text),
                                fontStyle: [{
                                    style: '',
                                    path: text.identifier
                                }],
                                widthM: text.geometry.width,
                                heightM: text.geometry.height,
                                offsetXM: text.geometry.x,
                                offsetYM: text.geometry.y,
                                current: 'textbox',
                                levelI: n,
                                pageI: index,
                                lock: true,
                                transparency: text.transparency,
                                lineheight: text.style.lineheight,
                                letterspacing: text.style.letterspacing,
                                fontuuid:text.fontuuid,
                                version:text.version
                            }
                            if(text.fontuuid){
                                //下载字体
                                if (vm.fontuuids.indexOf(text.fontuuid) == -1) {
                                    vm.fontuuids.push(text.fontuuid);
                                    getFontCSS(text.fontuuid)
                                }
                            }
                        }
                    })
                }
            })

            if (index < document.pages.page.length - 1) {
                $scope.getDetail(document, index + 1);
            } else {
                $scope.isLeftRotation = !$scope.template.pages.page[0].centerrotation;
                angular.forEach($scope.template.pages.page, function (page, m) {
                    angular.forEach(page.levels.level,function (level,l) {
                        if(level.imagebox){
                            imgScale(level.imagebox, m, l, level.imagebox.maskUrl, level.imagebox.image.offsetx, level.imagebox.image.offsety);
                        }
                    })
                })
            }
            if (vm.counts == 0) {
                var arr = angular.copy($scope.template);
                angular.forEach(arr.pages.page, function (obj, index) {
                    getArr(arr, obj, index);
                });
            }
        };

        function getBackgroundSource(res,pageW,pageH,arr) {
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.onload = function () {
                newImg.onload = null;
                var bgW = newImg.width;
                var bgH = newImg.height;
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                arr.width = w;
                arr.height = h;
                arr.type = 'Pic';
                arr.adaptation = adaptation;
            };
            newImg.src = res.identifier;
        }


        function getBackgroundSource1(res,pageW,pageH,item, bokehtypes) {
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = res.identifier + '?imageMogr2/auto-orient/rotate/' + item.angle+'/thumbnail/!40p';
            newImg.onload = function () {
                var imgR = pageW/pageH;
                var imgR1 = newImg.width/newImg.height;
                if(bokehtypes == 'None'){
                    if(imgR >= imgR1){
                        item.resource.adaptation = 'Height';
                        item.width= pageW;
                        item.height= pageW/newImg.width*newImg.height;
                    }else {
                        item.resource.adaptation = 'Width';
                        item.width= pageH/newImg.height * newImg.width;
                        item.height= pageH;
                    }
                }else {
                    if(imgR >= imgR1){
                        item.resource.adaptation = 'Height';
                        item.width= pageW;
                        item.height= pageW/newImg.width*newImg.height;
                        item.offsetx= 0;
                        item.offsety= (pageH - pageW/newImg.width*newImg.height) / 2;
                    }else {
                        item.resource.adaptation = 'Width';
                        item.width= pageH/newImg.height*newImg.width;
                        item.height= pageH;
                        item.offsetx= (pageW - pageH/newImg.height*newImg.width) / 2;
                        item.offsety= 0;
                    }
                }
                $scope.$digest();
            };
        }

        function getFontCSS(item){
            FontManagement.getCss({uuid: item}, function (res) {
                var style=document.createElement("style");
                style.type='text/css';
                try{
                    style.appendChild(document.createTextNode(res.message));
                }catch(ex){
                    style.stylesheet.cssText=res.message;
                }
                var Head=document.getElementsByTagName("head")[0];
                Head.appendChild(style);
            });
        }

        function getScale(screenW, screenH, pageW, pageH) {
            var newH = angular.copy(screenH);
            if (vm.bindingtype == 'Top' && screenW * 0.7 * pageH / pageW * 2 > screenH) {
                newH = (newH - 100) / 2;
            }

            if (vm.bindingtype == 'Top') {
                if (screenW / newH < pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    return false;
                }
            } else {
                if (screenW / newH > pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    return false;
                }
            }
        }

        function getScale2(screenW, screenH, pageW, pageH) {
            var newH = angular.copy(screenH);
            if (vm.bindingtype == 'Top' && screenW * 0.7 * pageH / pageW * 2 > screenH) {
                newH /= 2;
            }

            if (vm.bindingtype == 'Top') {
                if (screenW / newH < pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    // return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    // return false;
                }
            } else {
                if (screenW / newH > pageW / pageH) {
                    //宽定，高不定
                    $scope.isMaxWidth = true;
                    // return true;
                } else {
                    //高定，宽不定
                    $scope.isMaxWidth = false;
                    // return false;
                }
            }

            // $scope.isMaxWidth = true;
        }

        function getGeometry(obj, index) {
            return {
                height: unitTransform(obj.height) * $scope.template.pages.page[index].styleScale,
                width: unitTransform(obj.width) * $scope.template.pages.page[index].styleScale,
                x: unitTransform(obj.x) * $scope.template.pages.page[index].styleScale,
                y: unitTransform(obj.y) * $scope.template.pages.page[index].styleScale
            }
        }

        function imgScale(images, index, id, mask, x, y, flag) {
            var scaleW = 1, scaleH = 1;
            var newImg = document.createElement('img');
            newImg.setAttribute("crossOrigin", 'Anonymous');
            newImg.src = images.imgUrl;
            newImg.onload = function () {
                var w = newImg.width;
                var h = newImg.height;
                var maskWidth = images.geometry.width;
                var maskHight = images.geometry.height;
                if ((newImg.width <= newImg.height || maskWidth <= maskHight) && (newImg.width / newImg.height < maskWidth / maskHight) || newImg.width >= newImg.height && maskWidth >= maskHight && (newImg.width / newImg.height < maskWidth / maskHight)) {
                    newImg.width = maskWidth;
                    newImg.height = maskWidth * h / w;
                } else {
                    newImg.height = maskHight;
                    newImg.width = maskHight * w / h;
                }

                scaleW = newImg.width / w;
                scaleH = newImg.height / h;
                x = x / scaleW;
                y = y / scaleH;

                var img = images.imgUrl;
                if (images.bokehtype == 'Transparent') {
                    maskd3(img, images.imgUrl, images, function (src) {
                        images.blobSmall = src;
                        dropPicFinished();
                    });

                } else if (images.bokehtype == 'Blurred') {
                    maskd3(img, images.imgUrl, images, function (src) {
                        images.blobSmall = src;
                    });

                    img = img + '/blur/40x1000';
                    maskd(img, mask, 'source-in', x, y, maskWidth, maskHight, scaleW, scaleH, images.image.scale||1, function (src) {
                        images.blobUrl = src;
                        dropPicFinished();
                    });

                } else {
                    maskd(img, mask, 'source-in', x, y, maskWidth, maskHight, scaleW, scaleH, images.image.scale||1, function (src) {
                        images.blobUrl = src;
                        dropPicFinished();
                    });
                }
            };
            newImg.onerror = function () {
                images.imgUrl = images.image.resource.identifier + '?imageMogr2/auto-orient';
                if (images.image.angle != 0) {
                    images.imgUrl += '/rotate/' + images.image.angle
                }
                if (!flag) {
                    imgScale(images, index, id, mask, x, y, true);
                } else {
                    $scope.loading = false;
                    $(".page-loading").hide();
                    $li.show();
                    $scope.isShow = true;
                    $(".save-page").hide();
                }

            }
        }

        function dropPicFinished() {
            $scope.k++;
            if ($scope.k == vm.counts) {
                angular.forEach($scope.template.pages.page, function (obj, index) {
                    getArr($scope.template, obj, index);
                });
            }
        }

        function maskd3(img, src, imagebox, callback) {
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var geo = imagebox.geometry;
            var blurredRec = imagebox.blurredrectangle;
            var newImg = document.createElement('img');
            // newImg.crossOrigin = "Anonymous";
            newImg.setAttribute("crossOrigin", 'Anonymous');
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                newImg.width = blurredRec.width;
                newImg.height = blurredRec.height;
                var masks = document.createElement('img');
                // masks.crossOrigin = "Anonymous";
                masks.setAttribute("crossOrigin", 'Anonymous');
                masks.src = imagebox.maskUrl;

                masks.onload = function () {
                    masks.width = geo.width;
                    masks.height = geo.height;
                    imagecanvas.width = geo.width;
                    imagecanvas.height = geo.height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, geo.width, geo.height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.drawImage(newImg, blurredRec.x, blurredRec.y, blurredRec.width, blurredRec.height);

                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
            };
        }

        function maskd(src, maskUrl, attr, x0, y0, width, height, scaleW, scaleH, scale, callback) {
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.setAttribute("crossOrigin", 'Anonymous');
            newImg.src = src;
            newImg.width = width;
            newImg.height = height;
            if (newImg.complete) {
                var masks = document.createElement('img');
                masks.setAttribute("crossOrigin", 'Anonymous');
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = width;
                    masks.height = height;
                    imagecanvas.width = width;
                    imagecanvas.height = height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = attr;

                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.scale(scale, scale);
                    imagecontext.drawImage(newImg, x0, y0);


                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
                masks.src = maskUrl;
                return
            }
            newImg.onload = function () {
                newImg.onload = null;
                var masks = document.createElement('img');
                masks.setAttribute("crossOrigin", 'Anonymous');
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = width;
                    masks.height = height;
                    imagecanvas.width = width;
                    imagecanvas.height = height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = attr;

                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.scale(scale, scale);
                    imagecontext.drawImage(newImg, x0, y0);


                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
                masks.src = maskUrl;
            };
            newImg.src = src;
        }

        function getArr(arrs, obj, index) {
            var w = obj.mediabox.width;
            var h = obj.mediabox.height;
            if (obj.spread) {
                if (w / 2 > $scope.maxW) {
                    $scope.maxW = w / 2;
                }
                if (w / 2 < $scope.minW) {
                    $scope.minW = w / 2;
                }
            } else {
                if (w > $scope.maxW) {
                    $scope.maxW = w;
                }
                if (w < $scope.minW) {
                    $scope.minW = w;
                }
            }

            if (h > $scope.maxH) {
                $scope.maxH = h;
            }
            if (w < $scope.minH) {
                $scope.minH = h;
            }
            $scope.document.pages[index] = {
                arr: obj,
                arr2: '',
                multiple: 0.45,
                multiple2: 0.45,
                width: w,
                height: h,
                spread: obj.spread,
                'marginLeft': 0,
                'thumbnail': 1,
                'thumbnail2': 1,
                id: obj.id,
                mediabox: angular.copy(obj.mediabox)
            };
            setBgImg(index, arrs, obj);
            if (index == $scope.len - 1 && vm.bindingtype != 'None') {
                var page = $scope.document.pages;
                getScale2($scope.dWidth, $scope.dHeight, page[0].mediabox.width, page[0].mediabox.height);
                //第一个page宽度 和 最大page宽度 的比值
                // var pageW = page[0].mediabox.width;
                // if(page[0].spread){
                //     pageW/= 2;
                // }
                // if(pageW>0){
                //     page[0].thumbnail =(pageW / $scope.maxW).toFixed(2)
                // }
                getPageThumbnail(page[0], page[0].spread);
                var x = 1;
                if(!$scope.isPull){
                    x = 0;
                }

                for (var j = x; j < page.length; j++) {
                    page[j].halfWidth = ($scope.dWidth * page[j].multiple).toFixed(2);
                    //每个page宽度 和 最大page宽度 的比值
                    getPageThumbnail(page[j], page[0].spread);

                    if (j + 1 != page.length) {
                        getPageThumbnail(page[j + 1], page[j + 1].spread);
                    }
                    //跨页
                    if (page[j].spread == true) {
                        //左右翻页的清空
                        if (vm.bindingtype != 'Left') {
                            if (!$scope.uadHeight) {
                                $scope.uadHeight = page[j - 1].mediabox.height * 2
                            }
                            // page[j].marginTop = $scope.dWidth * 0.7 * ($scope.uadHeight - page[j].mediabox.height) * page[j].thumbnail / page[j].mediabox.width / 2;
                            if ($scope.isMaxWidth) {
                                var newW = $scope.dWidth * 0.7
                            } else {
                                var newW = ($scope.dHeight - 100) / 2 * page[j].mediabox.width / page[j].mediabox.height
                            }
                            page[j].marginTop = newW * ($scope.uadHeight - page[j].mediabox.height) * page[j].thumbnail / page[j].mediabox.width / 2;

                        }

                    } else if (page[j].spread == false && (page[j + 1] || []).spread == false && (!$scope.isPull||(j + 2) != page.length)) {
                        // console.log(j);
                        var arr = page[j + 1];
                        var arr0 = page[j];
                        page[j] = {
                            id: arr0.id,
                            width: arr0.mediabox.width,
                            height: arr0.mediabox.height,
                            arr: arr0.arr,
                            spread: arr0.spread,
                            multiple: arr0.multiple,
                            thumbnail: arr0.thumbnail,
                            marginLeft: arr0.halfWidth - $scope.dWidth * arr.multiple * arr.thumbnail,
                            id2: arr.id,
                            width2: arr.mediabox.width,
                            height2: arr.mediabox.height,
                            arr2: arr.arr,
                            spread2: arr.spread,
                            multiple2: arr.multiple,
                            thumbnail2: arr.thumbnail
                        };
                        page.splice(j + 1, 1);
                        $scope.uadHeight = arr.mediabox.height + arr0.mediabox.height;
                    }
                    if (j == page.length - 1) {
                        $scope.document.pages = page;
                        $scope.isShow = true;
                        $(".save-page").hide();

                        angular.forEach(page, function (paged) {
                            if (vm.bindingtype == 'Top') {
                                $scope.getPageWaHUad(paged, true);
                                $scope.getPageWaHUad(paged, false);
                            }
                            if (vm.counts > 0 && !$scope.isText) {
                                $scope.$apply(function () {
                                    $scope.document.pages = page;
                                    if ($scope.toggle.now) {
                                        Page.init();
                                    }
                                })
                            }
                        });

                        $scope.$watch('toggle.now', function () {
                            if ($scope.toggle.now) {
                                Page.init();
                            }
                        });
                        $(".un-last:parent").css({width: '50%!important'})
                    }
                }
                $scope.document.pages = page;
                console.log(page)
            }
            if (vm.bindingtype == 'None' || $scope.template.pages.page.length < 2) {
                $scope.isShow = true;
                $(".save-page").hide();
                $(".rightBtn").hide();
            }
        }

        function getPageThumbnail(page, spread) {
            var pageW = page.mediabox.width;
            if (spread) {
                pageW /= 2;
            }
            if (pageW > 0) {
                page.thumbnail = (pageW / $scope.maxW).toFixed(2)
            }
        }

        function setBgImg(index, documents, obj) {
            // if ((index - 1) > 0 && documents.pages[index].identifier && (index - 1) < (documents.pages.length - 2) && (obj.spread == false && documents.pages[index - 2].spread == false && (index - 2) != 0 && (documents.pages[index - 2].spread2) || (obj.spread == false && (documents.pages[index] || []).spread == false))) {
            //     $scope.template.pages[index - 1].bgImg2 = $scope.domain + documents.pages[index].identifier;
            // }
            if (index <= 2) {
                return
            }
            if (!documents.pages.page[index].identifier) {
                return
            }
            if (index >= (documents.pages.page.length - 1)) {
                return
            }
            if (obj.spread) {
                return
            }
            if (!documents.pages.page[index - 2].spread && (documents.pages.page[index - 2].spread2)) {
                $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
                return
            }
            if (!(documents.pages.page[index] || []).spread) {
                $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
            }
        }

        $scope.getLiW = function (slide) {
            if (vm.bindingtype == 'Top') {
                if(slide.w2&&slide.w2>slide.w1){
                    $(".ul-width").width(slide.w2);
                }else{
                    $(".ul-width").width(slide.w1)
                }
            } else {
                if(slide.pageW2){
                    return slide.pageW2-0+slide.pageW1;
                }else{
                    if(slide.spread){
                        // $(".ul-width").width(slide.pageW1)
                    }else {
                        $(".ul-width").width(slide.pageW1*2)
                    }
                }
            }
        };
        $scope.getUlW = function () {
            if (vm.bindingtype == 'Top') {
                if ($scope.isMaxWidth) {
                    return $scope.dWidth * 0.7
                } else {
                    return ($scope.dHeight - 100) / 2 * $scope.document.pages[0].width / $scope.document.pages[0].height
                }
            } else {
                if($scope.document.pages[0].pageW2){
                    return $scope.document.pages[0].pageW2-0+$scope.document.pages[0].pageW1
                }else{
                    return $scope.document.pages[0].pageW1*2
                }
            }
        };

        $scope.getPageWaHLar = function (slide, flag, marginFlag) {
            if (vm.bindingtype != 'Left') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var w = 0;
                if (!marginFlag) {
                    if (!slide.arr2) {
                        return
                    }

                    if ($scope.isMaxWidth) {
                        w = slide.pageW2 = $scope.dWidth * slide.multiple2 * slide.thumbnail2;
                    } else {
                        w = slide.pageW2 = ($scope.dHeight - 100) * slide.width2 / slide.height2 * slide.thumbnail2
                    }

                    slide.arr2.pageScaleW = w / unitTransform(slide.width2);
                    slide.arr2.ratio = w / (slide.arr2.mediabox.width / 25.4 * vm.windowDpiW);
                } else {
                    if ($scope.isMaxWidth) {
                        w = slide.pageW1 = $scope.dWidth * slide.multiple * slide.thumbnail;
                    } else {
                        w = slide.pageW1 = ($scope.dHeight - 100) * slide.width / slide.height * slide.thumbnail;
                    }

                    slide.arr.pageScaleW = w / unitTransform(slide.width);
                    slide.arr.ratio = w / (slide.arr.mediabox.width / 25.4 * vm.windowDpiW);

                }
                if (marginFlag && $scope.maxW != $scope.minW) {
                    if (slide.spread) {
                        slide.marginLeft = ($scope.dWidth * 0.9 - w) / 2;
                    } else {
                        slide.marginLeft = ($scope.dWidth * 0.45 - w);
                    }
                }
                return w;
            } else {
                var h = 0;
                if (!marginFlag) {
                    if (!slide.arr2) {
                        return
                    }

                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * slide.multiple2 * slide.height2 * slide.thumbnail2 / slide.width2
                    } else {
                        h = $scope.dHeight - 100
                    }

                    slide.arr2.pageScaleH = h / unitTransform(slide.height2);
                } else {
                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * slide.multiple * slide.height * slide.thumbnail / slide.width
                    } else {
                        h = $scope.dHeight - 100
                    }

                    slide.arr.pageScaleH = h / unitTransform(slide.height);
                }
                return h;
            }
        };
        $scope.getPageWaHUad = function (slide, flag) {
            if (vm.bindingtype != 'Top') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var w1 = 0, w2 = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        w2 = $scope.dWidth * 0.7 * slide.thumbnail2
                    }
                    else {
                        w2 = ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2
                    }
                    slide.w2 = w2;
                    slide.arr2.w = w2;
                    slide.arr2.pageScaleW = w2 / unitTransform(slide.width2);
                    slide.arr2.ratio = w2 / (slide.arr2.mediabox.width / 25.4 * vm.windowDpiW);

                }
                if ($scope.isMaxWidth) {
                    w1 = $scope.dWidth * 0.7 * slide.thumbnail
                } else {
                    w1 = ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail
                }
                slide.w1 = w1;
                slide.arr.w = w1;
                slide.arr.pageScaleW = w1 / unitTransform(slide.width);
                slide.arr.ratio = w1 / (slide.arr.mediabox.width / 25.4 * vm.windowDpiW);
            } else {
                var h1 = 0, h2 = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        h2 = $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2;
                    } else {
                        h2 = ($scope.dHeight - 100) / 2;
                    }
                    slide.h2 = h2;
                    slide.arr2.h = h2;
                    slide.arr2.pageScaleH = h2 / unitTransform(slide.height2);

                }
                if ($scope.isMaxWidth) {
                    h1 = $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                } else {
                    h1 = ($scope.dHeight - 100) / 2;
                }
                slide.h1 = h1;
                slide.arr.h = h1;
                slide.arr.pageScaleH = h1 / unitTransform(slide.height);
            }
        };

        $scope.getPageWaHUad1 = function (slide, flag) {
            if (vm.bindingtype != 'Top') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var w = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        w = $scope.dWidth * 0.7 * slide.thumbnail2
                    }
                    else {
                        w = ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2
                    }
                    slide.w2 = w;
                    slide.arr2.w = w;
                    slide.arr2.pageScaleW = w / unitTransform(slide.width2);
                    slide.arr2.ratio = w / (slide.arr2.mediabox.width / 25.4 * vm.windowDpiW);

                } else {
                    if ($scope.isMaxWidth) {
                        w = $scope.dWidth * 0.7 * slide.thumbnail
                    } else {
                        w = ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail
                    }
                    slide.w1 = w;
                    slide.arr.w = w;
                    slide.arr.pageScaleW = w / unitTransform(slide.width);
                    slide.arr.ratio = w / (slide.arr.mediabox.width / 25.4 * vm.windowDpiW);
                }
                // var w = slide.bgImg2 ? !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2 : $scope.dWidth * 0.7 * slide.thumbnail2 : !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail : $scope.dWidth * 0.7 * slide.thumbnail;
                return w;
            } else {
                var h = 0;
                if (slide.arr2) {
                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2;
                    } else {
                        h = ($scope.dHeight - 100) / 2;
                    }
                    slide.h2 = h;
                    slide.arr2.h = h;
                    slide.arr2.pageScaleH = h / unitTransform(slide.height2);

                } else {
                    if ($scope.isMaxWidth) {
                        h = $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                    } else {
                        h = ($scope.dHeight - 100) / 2;
                    }
                    slide.h1 = h;
                    slide.arr.h = h;
                    slide.arr.pageScaleH = h / unitTransform(slide.height);

                }
                // var h = slide.bgImg2 ? !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 : $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2 : !$scope.isMaxWidth ? ($scope.dHeight - 100) / 2 : $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                return h;
            }
        };
        $scope.getPageScaleUad = function (slide, flag) {
            if (vm.bindingtype != 'Top') {
                return
            }

            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var pageScaleW = 0;
                if (slide.arr2) {
                    slide.arr2.pageScaleW = pageScaleW = slide.arr2.w / unitTransform(slide.width2);

                } else {
                    slide.arr.pageScaleW = pageScaleW = slide.arr.w / unitTransform(slide.width);
                }
                return pageScaleW;
            } else {
                var pageScaleH = 0;
                if (slide.arr2) {
                    slide.arr2.pageScaleH = pageScaleH = slide.arr2.h / unitTransform(slide.height2);

                } else {
                    slide.arr.pageScaleH = pageScaleH = slide.arr.h / unitTransform(slide.height);

                }
                return pageScaleH;
            }
        };

        $scope.toggle = {
            now: false
        };
        var Page = (function () {
            var pageI = 0;
            var config = {
                    $bookBlock: $('#bb-bookblock'),
                    $navNext: $('#bb-nav-next'),
                    $navPrev: $('#bb-nav-prev'),
                    $slide1: $('#slide1'),
                    $slide2: $('#slide2')
                },
                init = function () {
                    config = {
                        $bookBlock: $('#bb-bookblock'),
                        $navNext: $('#bb-nav-next'),
                        $navPrev: $('#bb-nav-prev'),
                        $slide1: $('#slide1'),
                        $slide2: $('#slide2')
                    };
                    if (vm.bindingtype == 'Left') {
                        config.$bookBlock.bookblock({
                            speed: 800,
                            shadowSides: 0.8,
                            shadowFlip: 0.7,
                            len: $scope.document.pages.length
                        });
                    } else {
                        $(".bb-item").addClass('lar-noShadow');
                        config.$bookBlock.bookblock({
                            orientation: 'horizontal',
                            speed: 700
                        });
                    }
                    initEvents();
                },
                initEvents = function () {
                    var $slides = config.$bookBlock.children();

                    // add navigation events
                    config.$navNext.on('click touchstart', function () {
                        config.$bookBlock.bookblock('next');
                        pageI = localStorage.getItem('pageI');
                        nextShow(pageI - 1);
                        return false;
                    });

                    config.$navPrev.on('click touchstart', function () {
                        config.$bookBlock.bookblock('prev');
                        pageI = localStorage.getItem('pageI');
                        prevShow(pageI - 0 + 1);
                        return false;
                    });

                    // add swipe events
                    $slides.on({
                        'click': function (event) {
                            fn(event);
                            var index = event.currentTarget.dataset.index;
                            var page = $scope.document.pages[index];
                            var index2 = index - 1;

                            var mar = (allWidth - $(".modal-content").width()) / 2;
                            var w = $(".view-article").width() / 2 + mar;

                            if (vm.bindingtype == 'Left') {
                                if (event.clientX > (w)) {
                                    index2 = index - 0 + 1;
                                    getPageWidth(index - 0 + 1, 'next', config);
                                    nextShow(index);
                                } else {
                                    getPageWidth(index - 1, 'prev', config);
                                    prevShow(index);
                                }
                                var page2 = $scope.document.pages[index2]
                                if(page2.pageW2){
                                    $(".ul-width").width(page2.pageW2-0+page2.pageW1);
                                }else{
                                    if(page2.spread){
                                        // $(".ul-width").width(page2.pageW1)
                                    }else {
                                        $(".ul-width").width(page2.pageW1*2)
                                    }
                                }
                            } else if (vm.bindingtype == 'Top') {
                                // 120px---->bb-bg 上方高度
                                if (event.pageY > 120 + $(".bb-item").eq(index).height() / 2) {
                                    index2 = index - 0 + 1;
                                    //最后一页的时候禁止点击
                                    if (index == $scope.document.pages.length - 1) {
                                        return
                                    }
                                    config.$bookBlock.bookblock('next');
                                    nextShow(index);
                                } else {
                                    config.$bookBlock.bookblock('prev');
                                    prevShow(index);
                                }

                                var page2 = $scope.document.pages[index2]
                                if(page2.w2&&page2.w2>page2.w1){
                                    $(".ul-width").width(page2.w2);
                                }else{
                                    $(".ul-width").width(page2.w1)
                                }

                                if ($scope.document.pages[index].spread == true) {
                                    $(".bb-horizontal .bb-page").css({top: '50.1%'})
                                }
                            }
                            return false;
                        }
                    });
                };

            return {init: init};

        })();

        function nextShow(index) {
            var len = $scope.document.pages.length;
            $(".leftBtn").show();
            if (index - 0 + 1 == len - 1) {
                $(".rightBtn").hide();
            }
        }

        function prevShow(index) {
            $(".rightBtn").show();
            if (index - 1 == 0) {
                $(".leftBtn").hide();
            }
        }

        function getPageWidth(index, attr, config) {
            if (!$scope.isMaxWidth) {
                var page = $scope.document.pages[index];
                var halfWidth = page.arr2 ? page.width2 / page.height2 : page.width / page.height;
                var w = ($scope.dHeight - 100) * (page.width / page.height + halfWidth);
                // console.log(w);
                $li.width(w);
                $("#bb-bookblock").width(w);
            } else {
                $li.width($("#bb-bookblock").width());
            }
            config.$bookBlock.bookblock(attr);
        }

        $scope.backTo = function () {
            $uibModalInstance.dismiss('cancel');

            $(".listBGC").show();
            $(".modal-content").show().removeClass('opacity0');
        };

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
    }
})();
