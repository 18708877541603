(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassBankDialogController', PassBankDialogController);

    PassBankDialogController.$inject = ['$timeout', '$scope', '$state', 'entity', 'MessageService','BackupPassCategory'];

    function PassBankDialogController ($timeout, $scope, $state, entity, MessageService,BackupPassCategory ) {
        var vm = this;

        vm.backupPassCategory = entity;
        if (vm.backupPassCategory.backupPasses == null) {
            vm.backupPassCategory.backupPasses = [];
        }
        if (vm.backupPassCategory.backupPasses.length>9){
            $scope.canAdd = false;
            $scope.canAddNum = 0;
        }else {
            $scope.canAdd = true;
            $scope.canAddNum = 10 - vm.backupPassCategory.backupPasses.length;
        }

        vm.clear = clear;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('passBank');
        }

        $scope.validate = function(){
            if(vm.backupPassCategory.name == null || vm.backupPassCategory.name==""){
                MessageService.error("请输入口令分类名称");
                return false;
            }

            return true;
        };

        $scope.addBackupPass = function(){
            if(!$scope.validate()){
                return;
            }

            var backupPass = {id:null,content:null,cancelled:false,added:true}
            vm.backupPassCategory.backupPasses.push(backupPass);
            $scope.canAddNum --;
        };

        $scope.deleteBackupPass = function(backupPass){
            if(backupPass == null || vm.backupPassCategory.backupPasses == null || vm.backupPassCategory.backupPasses.length == 0){
                return;
            }
            if(backupPass.id==null){
                var inde1 = vm.backupPassCategory.backupPasses.indexOf(backupPass);
                vm.backupPassCategory.backupPasses.splice(inde1,1);
            }else{
                backupPass.cancelled = true;
            }
            $scope.canAddNum ++;
        };


         $scope.save = function () {
            var isValueNull = false
            for(var i=0;i<vm.backupPassCategory.backupPasses.length;i++){
                var v = vm.backupPassCategory.backupPasses[i];
                if(v.content==null||v.content==''){
                    MessageService.error("请输入口令");
                    isValueNull = true
                    break
                }
            }
            if(isValueNull){
                return false;
            }
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            BackupPassCategory.save(vm.backupPassCategory, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess (result) {
            // $scope.$emit('backApp:propertyUpdate', result);
            $state.go("passBank");
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }

    }
})();
