(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('storeDeliveryUrgentController', storeDeliveryUrgentController);

    storeDeliveryUrgentController.$inject = ['$scope', '$state', 'Region', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Store'];

    function storeDeliveryUrgentController ($scope, $state, Region, ParseLinks, paginationConstants, pagingParams, MessageService, Store) {
        var vm = this;
        vm.save = save;
        vm.data = Store.getCurrent();

        function save() {
            if(!vm.data.urgentAmount){
                MessageService.error("请输入加急金额");
                return false;
            }
            var temp = /^\d+\.?\d{0,2}$/;
            if (!temp.test(vm.data.urgentAmount)) {
                MessageService.error("请输入有效金额");
                return false;
            }
            Store.update(vm.data,  function (res) {
                MessageService.success("保存成功");
            },  function (error) {
                MessageService.error("保存失败");
            });
        }
    }
})();
