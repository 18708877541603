(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupOrderDetailController', GroupOrderDetailController);

    GroupOrderDetailController.$inject = ['$timeout', '$scope', '$localStorage', '$uibModalInstance', 'entity', 'PayBatch', 'Store', 'Product', 'MessageService', '$q',
    'CustomerGroupBuy', 'ProductPurchase', 'ItemValueLinePackage', '$stateParams', 'ListService'];

    function GroupOrderDetailController ($timeout, $scope, $localStorage, $uibModalInstance, entity, PayBatch, Store, Product, MessageService, $q,
                                         CustomerGroupBuy, ProductPurchase, ItemValueLinePackage, $stateParams, ListService) {
        var vm = this;
        vm.isLeader = $stateParams.isLeader;
        vm.order = entity;
        vm.clear = clear;
        vm.store = Store.getCurrent();
        vm.customerGroupBuy = CustomerGroupBuy.get({id : entity.customerGroupBuyId});

        $q.all([vm.store.$promise, vm.customerGroupBuy.$promise]).then(function(){
            console.log(vm.customerGroupBuy)


        })
        function clear () {
            $uibModalInstance.close();
        }
    }
})();
