(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RedeemCodeDialog3Controller', RedeemCodeDialog3Controller);

    RedeemCodeDialog3Controller.$inject = ['$scope', '$localStorage', '$uibModalInstance', 'entity', 'ExchangeCode', 'MessageService', 'PayBatch'];

    function RedeemCodeDialog3Controller ($scope, $localStorage, $uibModalInstance, entity, ExchangeCode, MessageService, PayBatch) {
        var vm = this;
        vm.payCode = entity;
        vm.clear = clear;
        vm.save = save;
        vm.paybatchs = PayBatch.getAllByCondition({items:[
            {key:"used", op:"=", value:"true"},
            {key:"payBatchType", op:"=", value:"EXCHANGECODE"},
            {key: "storeId", op: "=", value: $localStorage.user.storeId}]}
        );

        vm.select2Options = {
            allowClear: true,
            formatNoMatches: "没有匹配到数据"
        };

        
        $scope.validate = function() {
            if (vm.payCode.batch == null || vm.payCode.batch == "") {
                MessageService.error("请输入批次号");
                return false;
            };
             
            return true;
        };

        function save() {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            ExchangeCode.activateByBatch({ batch:vm.payCode.batch}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
            MessageService.success("激活成功");
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("激活失败");
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
