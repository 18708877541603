(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomerPayOrderDetailController', CustomerPayOrderDetailController);

    CustomerPayOrderDetailController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'PayOrder', '$http', 'MessageService', '$sce'];

    function CustomerPayOrderDetailController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, PayOrder, $http, MessageService, $sce) {
        var vm = this;
        vm.store = Store.getCurrent({});
        vm.pay = entity;
        vm.cancel = cancel;
        vm.isPay = false;
        vm.payOrderId = "";


        //返回
        function cancel() {
            $state.go('customer-pay-order')
        }


        vm.type = type;
        function type(target) {
            switch (target) {
                case "WeChat":
                    return "微信";
                default:
                    return "";
            }
        }
    }
})();
