(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OperationManualCreateController', OperationManualCreateController);

    OperationManualCreateController.$inject = ['$timeout', '$scope', "msgdata",'$stateParams', '$uibModalInstance', '$q', 'Qinius', 'StoreOperationManuals', 'MessageService','UidService','Store'];

    function OperationManualCreateController($timeout, $scope,msgdata, $stateParams, $uibModalInstance, $q, Qinius,StoreOperationManuals , MessageService ,UidService,Store) {
        var vm = this;
        vm.data = {};
        vm.cancel = cancel;
        vm.save = save;
        vm.store = Store.getCurrent();
        if (msgdata.id) {
            StoreOperationManuals.get({id:msgdata.id},function(res){
                vm.data=res
            })
        }
        $scope.deletePic=function(){
            vm.data.title=''
        }
        // 上传图片------------------------------------------
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        $q.all([vm.tokens.$promise, vm.domains.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        });
         // itemvalue封面图片修改
         window.uploadBackImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var url = window.URL || window.webkitURL;
                var img = new Image(); //手动创建一个Image对象
                img.src = url.createObjectURL(file); //创建Image的对象的url
                img.onload = function () {
                    var w = Math.abs(this.width - 750);
                    var h = Math.abs(this.height - 1200);
                    console.log("w: " + w + " h: " + h);
                    console.log('height:' + this.height + '----width:' + this.width)
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', 'back-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            $timeout(function () {
                                vm.data.title = $scope.domain + data.key;
                            })
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                }
            });
        };
        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if (!vm.data.title) {
                MessageService.error("请上传标题图片");
                return
            }
            if (!vm.data.seq) {
                MessageService.error("请填写排序");
                return
            }
            if (msgdata) {
                // 编辑
                StoreOperationManuals.update(vm.data,function(res){
                    if (res.status==200) {
                        MessageService.success('保存成功')
                    }else{
                        MessageService.error(res.message||'保存失败')
                    }
                    $uibModalInstance.close();
                })
            } else {
                // 新增
                StoreOperationManuals.save(vm.data,function(res){
                    if (res.status==200) {
                        MessageService.success('保存成功')
                    }
                    $uibModalInstance.close();
                })
            }
           
        }
    }
})();
