(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('TextXmlLookController', TextXmlLookController);

    TextXmlLookController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', '$state', 'MessageService'];

    function TextXmlLookController ($timeout, $scope, $stateParams, $uibModalInstance, entity, $state, MessageService) {
        var vm = this;
        vm.cancel = cancel;
        vm.url = entity.url;
        vm.domain = entity.domain;

        //返回
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
