(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('VersionDescriptions', VersionDescriptions);

    VersionDescriptions.$inject = ['$resource', 'DateUtils'];

    function VersionDescriptions ($resource,DateUtils) {
        var resourceUrl =  'manager/' + 'api/version-descriptions/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            'getPriceByProductAndStore': {
                method: 'GET',
                params: {
                    path: 'getPriceByProductAndStore'
                }
            },
            'byCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'byCondition2'
                }
            },
            'getAllByCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'getAllByCondition2'
                }
            },
            'updateEnable': {
                method: 'GET',
                params: {
                    path: 'updateEnable'
                }
            },
            'reject': {
                method: 'GET',
                params: {
                    path: 'reject'
                }
            },
            'getNum': {
                method: 'GET',
                params: {
                    path: 'getNum'
                }
            },
            'read': {
                method: 'GET',
                params: {
                    path: 'read'
                }
            },
            'readBatch': {
                method: 'GET',
                params: {
                    path: 'readBatch'
                }
            },
            'exchangeCustomer': {
                method: 'GET',
                params: {
                    path: 'exchangeCustomer'
                }
            },
            'update': { method:'PUT' },
            'save': {
                method: 'POST'
            }
        })
    }
})();
