(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreThirdController', StoreThirdController);

    StoreThirdController.$inject = ['$scope', '$q', '$state', '$rootScope', '$stateParams', 'Store',
    'PlatformAbutment', 'MessageService', 'PlatformWechatSetting','Principal'];

    function StoreThirdController($scope, $q, $state, $rootScope, $stateParams, Store,
     PlatformAbutment, MessageService, PlatformWechatSetting,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });

        vm.tabDiv = tabDiv;
        vm.store = Store.getCurrent();
        vm.platformWechatSetting = PlatformWechatSetting.get();
        vm.abutments = PlatformAbutment.getAll({items:[{key: "platform", op: "=", value: "RRD"}]});
         vm.tabFlag = 1;
        function tabDiv(index){
            vm.tabFlag = index;
        }

        $scope.delete =function(abutment){
            MessageService.confirm("确认要删除该数据", function(){
                PlatformAbutment.del({id:abutment.id},function(data){
                    MessageService.success("删除成功");
                    vm.abutments = PlatformAbutment.getAll({items:[]});
                },function(){
                    MessageService.error("删除失败");
                })
            }, function(){

            })
        }
    }
})();
