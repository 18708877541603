(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('GroupCommodityController', GroupCommodityController);

    GroupCommodityController.$inject = ['$state', 'ProductPurchase', 'ParseLinks',  'paginationConstants', 'MessageService'
    ,'$scope', 'StoreGroupBuys', 'Qinius', '$q'];

    function GroupCommodityController($state, ProductPurchase, ParseLinks,   paginationConstants, MessageService,
        $scope, StoreGroupBuys, Qinius, $q) {
        var vm = this;
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.jump = jump;
        vm.search = search;
        vm.status = status;
        vm.tableData = [];
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            init();
        });

        function init(){
            var items = [];
            if(vm.searchQuery){
                items.push({key: "name", op: "like", value: vm.searchQuery})
            }
            StoreGroupBuys.byCondition({
                items: items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,asc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tableData = res;
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        function transition(){
            init()
        }
        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            init();
            vm.jumpText = "";
        }

        $scope.shopCb = function () {
            console.log(vm.shopId)
        };
        function search(){
            vm.page = 1;
            init();
        }
        //删除
        $scope.delete = function (item) {
            MessageService.confirm("确认要删除该商品吗？", function () {
                StoreGroupBuys.delete({id: item.id}, function () {
                    init();
                });
            });
        };
        //启停
        $scope.changeUsed = function (item) {
            StoreGroupBuys.updateEnable({id:item.id, enable:!item.enable},function (res) {
                init();
            })
        }


        function status(target) {
            switch (target) {
                case "Creating":
                    return "新建";
                case "Created":
                    return "已创建";
                case "Approved":
                    return "已通过";
                case "Cancelled":
                    return "已离职";
                case "Rejected":
                    return "已拒绝";
                case "Fail":
                    return "失败";
                default:
                    return "";
            }
        }
    }
})();
