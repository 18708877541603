(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreRechargeSettingController', StoreRechargeSettingController);

    StoreRechargeSettingController.$inject = ['$scope', '$state', 'StoreRechargeSetting','MessageService','paginationConstants','pagingParams','ParseLinks','Principal'];

    function StoreRechargeSettingController ($scope, $state, StoreRechargeSetting,MessageService,paginationConstants,pagingParams,ParseLinks,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_RECHARGE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;


        loadAll();

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                StoreRechargeSetting.delete({id: id},function () {
                    loadAll();
                });
            },function(){},true)
        }


        function loadAll () {
            if (pagingParams.search) {
                StoreRechargeSetting.byCondition({
                    items: [{key:"name",op:"like",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                StoreRechargeSetting.byCondition({
                    items: [],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.storeRechargeSettings = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("角色获取失败");
            }
        }


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
    }
})();
