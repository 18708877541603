(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('HomePageController', HomePageController);

    HomePageController.$inject = ['$scope', '$state', 'MessageService', 'SalesOrder', 'WechatUserAnalysis', '$sessionStorage', 'Store','Principal'];

    function HomePageController($scope, $state, MessageService, SalesOrder, WechatUserAnalysis, $sessionStorage, Store,Principal) {
        var vm = this;
        $(".navbar").show();
        $(".nav-bg").hide();
        $(".wrap").show();
        Principal.hasAuthority('ROLE_STORE_HOMEPAGE').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问首页的权限！");
                $state.go("home");
            }
        });

        vm.openMap = openMap;
        vm.salesOrderPayAmount = 0;
        vm.salesOrderList = [];
        vm.store = Store.getCurrent();
        vm.num = 1000;
        getHomeNum();
        function getHomeNum() {
        	vm.customerNum = 0;
        	SalesOrder.getHomeNum({items:[]}, function (res) {
        		vm.salesOrderList = res;
        		if (vm.salesOrderList !=null) {
        			for (var i = vm.salesOrderList.length - 1; i >= 0; i--) {
	        			var order = vm.salesOrderList[i];
	        			vm.salesOrderPayAmount += order.payAmount;
	        		}
        		}
        	},function(){
        		MessageService.error("今日订单获取失败");
        	});

        	$scope.getBalance = function(){
        		if (vm.store.balance == null || vm.store.balance == 0) {
        			return 0.00;
        		}
        		if (vm.store.balance >= 10000 && vm.store.balance <=1000000 ) {
        			return (Math.floor(vm.store.balance/100)/100)+ "万";
        		}else if (vm.store.balance > 1000000 && vm.store.balance <=100000000) {
        			return (Math.floor(vm.store.balance/10000)/100) + "百万";
        		}else if (vm.store.balance > 100000000) {
        			return (Math.floor(vm.store.balance/100000000)/100) + "亿";
        		}else{
        			return (vm.store.balance).toFixed(2)
        		}

        	}

        	// SalesOrder.getHomePayAmount({items:[]}, function (res) {
        	// 	vm.salesOrderPayAmount = res.message;
        	// });

        	WechatUserAnalysis.getTodayData({}, function(data) {
                if (data && data.list && data.list.length > 0) {
                    vm.customerNum = data.list[0].new_user;
                }else{

                }
            },function(){
            	MessageService.error("今日用户数据获取失败");
            });
        	SalesOrder.getHomeProduct({items:[]}, function (res) {
        		vm.productList = res.message;
        	},function(){

        	});
        }

        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('main'));

        // 指定图表的配置项和数据
		var data = [];
		var proportion = 0;
		var convertData = function () {
		    var res = [];
			SalesOrder.getHome2Map({items:[]}, function (ress) {
				vm.dataList = ress.message;
        		data = ress.message;
        		vm.citylist = ress.message;
        		if (data != null) {
        			// 显示数据按照已有数据的最大值比例显示
        			var maxNum = 0;	// 数据中的最大值
        			// 地图的最大显示值建议为300
        			var mapMaxNum = 300;
        			for (var i = 0; i < data.length; i++) {
        				var geoCoord = citylist[0][data[i].split('-')[0]];
        				if (geoCoord) {
        					var num = Number(Number(data[i].split('-')[1]).toFixed(2));
        					if (num > maxNum) {
        						maxNum = num;
        					}
        				}
        			}

        			// 比例
        			if (maxNum == 0) {
        				proportion = 0;
        			} else {
	        			proportion = (mapMaxNum / maxNum).toFixed(2);
        			}

        			// 拼接数据
				    for (var i = 0; i < data.length; i++) {
				        var geoCoord = citylist[0][data[i].split('-')[0]];
				        if (geoCoord) {
				        	var num = (Number(Number(data[i].split('-')[1]).toFixed(2)) * proportion).toFixed(2);
				        	console.log(data[i].split('-')[1] + "-----" + proportion);
				            res.push({
				                name: data[i].split('-')[0],
				                value: geoCoord.concat(num)
				            });
				        }
				    }
        		}
			    // 由于是异步操作所以在获取到数据的时候，刷新地图
			    refreshData(res);
        	})
		    return res;
		};

		var option = {
		    backgroundColor: '#F1F2F7',
		    title: {
		        text: ' | 地区销售排名情况',
		        // subtext: '双击地图进去地图界面',
		        top: 'bottom',
		        textStyle: {
		            color: '#FF5001'
		        }
		    },
		    tooltip : {
		        trigger: 'item',
		        backgroundColor: '#FF5001',
		        formatter: function (params, ticket, callback) {
		        	var num = 0.00;
		        	if (params.value[2] > 0) {
		        		num = (params.value[2] / proportion).toFixed(2);
		        	}
				    return "<div style='color: #000, text-align: center;'>" + params.name + "</b><br>    成交额¥" + num + "</div>";
				}
		    },
		    legend: {
		        orient: 'vertical',
		        y: 'bottom',
		        x:'right',
		        data:[''],
		        textStyle: {
		            color: '#fff'
		        }
		    },
		    geo: {
		        map: 'china',
		        label: {
		            emphasis: {
		                show: false
		            }
		        },
		        // roam: false,
		        zoom: 1.25,
		        itemStyle: {
		            normal: {
		                areaColor: '#FFFFFF',
		                borderColor: '#111'
		            },
		            emphasis: {
		                areaColor: '#FFFFFF'
		            }
		        }
		    },
		    series : [
		        {
		            name: '98765432',
		            type: 'scatter',
		            coordinateSystem: 'geo',
		            data: convertData(),
		            symbolSize: function (val) {
		                return val[2] / 10;
		            },
		            showEffectOn: 'render',
		            hoverAnimation: true,
		            rippleEffect: {
		            	brushType: 'stroke'
		            },
		            label: {
		                normal: {
		                    formatter: '{b}',
		                    position: 'right',
		                    show: false
		                },
		                emphasis: {
		                    show: true
		                }
		            },
		            itemStyle: {
		                normal: {
		                    color: '#FF5001'
		                }
		            }
		        },
		        {
		            name: '',
		            type: 'effectScatter',
		            coordinateSystem: 'geo',
		            data: convertData(data.sort(function (a, b) {
		                return b.value - a.value;
		            }).slice(0, 6)),
		            symbolSize: function (val) {
		                return val[2] / 10;
		            },
		            showEffectOn: 'render',
		            rippleEffect: {
		                brushType: 'stroke'
		            },
		            hoverAnimation: true,
		            label: {
		                normal: {
		                    formatter: '{b}',
		                    position: 'right',
		                    show: false			// 是否显示城市名
		                }
		            },
		            itemStyle: {
		                normal: {
		                    color: '#FF5001',
		                    shadowBlur: 10,
		                    shadowColor: '#333'
		                }
		            },
		            zlevel: 1
		        }
		    ]
		};

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);

        function refreshData(data){
     		if(!myChart){
          		return;
     		}
		    //更新数据
		    var option = myChart.getOption();
		    option.series[0].data = data;
		    option.series[1].data =  data.sort(function (a, b) {
		            return b.value - a.value;
		        }).slice(0, 6);
		    myChart.setOption(option);
		}

	    function openMap() {
	    	var url = $state.href("map-page");
	    	// 防止页面跳转
	    	sessionStorage.setItem("lmenu", "a_home1");
	    	window.open(url, '_blank');
	    }
    }
})();
