(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('WxNavigateConfigDialogController', WxNavigateConfigDialogController);

    WxNavigateConfigDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'WxNavigateConfig', '$state','MessageService'];

    function WxNavigateConfigDialogController ($timeout, $scope, $stateParams, entity, WxNavigateConfig, $state, MessageService) {
        var vm = this;

        vm.wxNavigateConfig = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('wx-navigate-config', null, { reload: true });
        }
        $scope.validate = function(){
            if(vm.wxNavigateConfig.path == null || vm.wxNavigateConfig.path==""){
                MessageService.error("请输入路径");
                return false;
            }
            if(vm.wxNavigateConfig.appId == null || vm.wxNavigateConfig.appId==""){
                MessageService.error("请输入appId");
                return false;
            }
            if(vm.wxNavigateConfig.triggerModule == null || vm.wxNavigateConfig.triggerModule==""){
                MessageService.error("请选择模块");
                return false;
            }

            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.wxNavigateConfig.id !== null) {
                WxNavigateConfig.update(vm.wxNavigateConfig, onSaveSuccess, onSaveError);
            } else {
                WxNavigateConfig.save(vm.wxNavigateConfig, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:wxNavigateConfigUpdate', result);
            $state.go('wx-navigate-config', null, { reload: true });
            vm.isSaving = false;
            MessageService.success("保存成功");
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }


    }
})();
