(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreUserDialogController', StoreUserDialogController);

    StoreUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$q', 'entity', 'StoreUser', 'StoreUser1', 'Role', 'MessageService', 'BranchStoreManagement',
    'BranchStore', 'ListService', "Store", 'VerifyCode', '$interval'];

    function StoreUserDialogController ($timeout, $scope, $stateParams, $state, $q, entity, StoreUser, StoreUser1, Role, MessageService, BranchStoreManagement,
     BranchStore, ListService, Store, VerifyCode, $interval) {
        var vm = this;

        vm.storeUser = entity;
        vm.isPhone = false;
        vm.clear = clear;
        vm.save = save;
        vm.roles = Role.byCondition2({items:[{key:"type",op:"=", value:"STORE"}]});
        vm.branchStores = BranchStore.getBranchForManagement({});
        if ($stateParams.id) {
            vm.managements = BranchStoreManagement.getAllByCondition({items:[{key:"userId",op:"=", value: $stateParams.id}]});
            $q.all([vm.storeUser.$promise, vm.roles.$promise, vm.branchStores.$promise, vm.managements.$promise ]).then(function() {
                vm.isPhone = vm.storeUser.phone ? true : false;
                for (var i = vm.storeUser.authorities.length - 1; i >= 0; i--) {
                   var au =  vm.storeUser.authorities[i];
                   if (au == "ROLE_STORE_MY_BRANCH_SHOP") {
                        vm.storeDivShow = true;
                        break;
                   }
                }
                if (vm.managements!= null && vm.managements.length>0) {
                    for (var i = vm.managements.length - 1; i >= 0; i--) {
                        var a = vm.managements[i];
                        a.userId = vm.storeUser.id;
                        a.storeId = vm.storeUser.storeId;
                        a.branchStore.edited = true;//标志数据库中是选中的
                        a.branchStore.selected = true;
                        vm.branchStores.push(a.branchStore);
                    }
                }
            }) ;
        }else{
            vm.managements = [];
            $q.all([vm.storeUser.$promise, vm.roles.$promise, vm.branchStores.$promise]).then(function() {
                 
            }) ;
        }

        $scope.roleChange = function(){
            if (vm.storeUser.roleId) {
                var role;
                for (var i = vm.roles.length - 1; i >= 0; i--) {
                    var r= vm.roles[i];
                    if (r.id == vm.storeUser.roleId) {
                        role = r;
                        break;
                    }
                }
                if (role != null) {
                    var indexOf = false;
                    for (var i = role.authorities.length - 1; i >= 0; i--) {
                       var au =  role.authorities[i];
                       if (au.code == "ROLE_STORE_MY_BRANCH_SHOP") {
                            vm.storeDivShow = true;
                            indexOf = true;
                            break;
                       }
                    }
                    if (!indexOf) {
                        vm.storeDivShow = false;
                        for (var i = vm.branchStores.length - 1; i >= 0; i--) {
                            var  branchStore = vm.branchStores[i];
                            branchStore.selected = false;
                        }

                    }
                }
            }else{
                vm.storeDivShow = false;
            }
        }

         $scope.storeSelected = function(store){
            store.selected = !store.selected;
            for(var i = 0; i<vm.branchStores.length ; i ++){
                var bs = vm.branchStores[i];
                if (bs.id == store.id) {
                    continue;
                }else{
                    bs.selected = false;
                }
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('store-user', null, { reload: 'store-user' });
        }

        $scope.validate = function(){
            console.log(vm.storeUser)
            if(vm.storeUser.name == null || vm.storeUser.name==""){
                MessageService.error("请输入用户名称");
                return false;
            }
            if (!vm.storeUser.phone) {
                MessageService.error("请输入手机号码");
                return;
            }
            if (!vm.isPhone && !vm.storeUser.code) {
                MessageService.error("请输入验证码");
                return;
            }
            if (vm.storeUser.roleId == null || vm.storeUser.roleId=="") {
                MessageService.error("请选择角色");
                return false;
            };

            if (!vm.storeUser.id&&vm.storeUser.password !== vm.confirmPassword) {
                MessageService.error("两次密码输入不一致。");
                return
            }
            return true;
        }
        function save () {
            if(!$scope.validate()){
                return;
            }
            if (vm.storeUser.id !== null) {
                checkUser(function () {
                    vm.isSaving = true;
                    StoreUser1.update(vm.storeUser, onSaveSuccess, onSaveError);
                })
            } else {
                checkUser(function () {
                    vm.isSaving = true;
                    vm.storeUser.langKey = "zh-cn";
                    vm.storeUser.message = vm.storeUser.code;
                    Store.createUser(vm.storeUser, onSaveSuccess, onSaveError);
                })
            }
        }


        function onSaveSuccess (result) {
            if(result.status && result.status != 200){
                MessageService.error(result.message || "保存失败");
                return
            }
            vm.storeUser = result.message;
            for (var i = vm.branchStores.length - 1; i >= 0; i--) {
                var s = vm.branchStores[i];
                if (s.selected && s.edited) {
                    MessageService.success("保存成功");
                    $scope.$emit('backApp:storeUserUpdate', vm.storeUser);
                    $state.go('store-user', null, { reload: 'store-user' });
                    vm.isSaving = false;
                    return;
                }
                if (s.selected && !s.edited) {
                    vm.managements.push({userId:vm.storeUser.id, branchStore: s, added:true, storeId: vm.storeUser.storeId});
                }
                if (!s.selected && s.edited) {
                    for (var j = vm.managements.length - 1; j >= 0; j--) {
                        var m = vm.managements[j];
                        if (m.branchStore  && m.branchStore.id  == s.id) {
                            m.deleted = true;
                            break;
                        }
                    }
                }
            }

            BranchStoreManagement.updateManagement(vm.managements,function(data){
                MessageService.success("保存成功");
            },function(){
                 MessageService.error("分店信息保存失败");
            });

            $scope.$emit('backApp:storeUserUpdate', vm.storeUser);
            $state.go('store-user', null, { reload: 'store-user' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        //获取验证码
        $scope.paracont = "获取验证码";
        vm.disabled = false;
        //验证码的定时器
        var timer = null;
        $scope.getVerifyCode = function () {
            if (!vm.storeUser.phone) {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.storeUser.phone.length!=11) {
                MessageService.error("请输入正确的手机号码");
                return;
            }
            if (vm.disabled) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            VerifyCode.getVerifyCode2({
                phone: vm.storeUser.phone,
                incident: "新用户注册"
            }, function (msg) {
                if(msg.status == 400){
                    MessageService.error(msg.message || "发送短信失败");
                    return
                }
                clearInterval(timer);
                timer = $interval(function () {
                    if (vm.countdown > 0) {
                        vm.disabled = true;
                        vm.countdown--;
                        $scope.paracont = "重新发送(" + vm.countdown + ")";
                    } else {
                        vm.disabled = false;
                        $interval.cancel(timer);
                        $scope.paracont = "重发验证码";
                    }
                }, 1000);
            });
        };

        function checkUser(callback) {
            if(!vm.isPhone){
                var _data = {phone: vm.storeUser.phone};
                if(!vm.storeUser.id){
                    _data.username = vm.storeUser.name
                }
                StoreUser.checkUser2(_data, function (data) {
                    if (!data.message) {
                        MessageService.error("该手机号或者用户名已存在");
                    } else {
                        callback()
                    }
                }, function () {
                    MessageService.error("校验用户名时出错");
                });
            }else{
                callback()
            }
        }
    }
})();
