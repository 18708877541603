(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('HomePageStyleNewController', HomePageStyleNewController);

    HomePageStyleNewController.$inject = ['$timeout', '$scope','$stateParams', '$uibModalInstance', '$q', 'Qinius', 'StoreOperationManuals', 'MessageService','UidService','IndexStyleSettings','Store'];

    function HomePageStyleNewController($timeout, $scope, $stateParams, $uibModalInstance, $q, Qinius,StoreOperationManuals , MessageService ,UidService,IndexStyleSettings,Store) {
        var vm = this;
        vm.data = {};
        vm.store = Store.getCurrent();
        vm.cancel = cancel;
        vm.save = save;
        
        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if (!vm.data.name) {
                MessageService.error("请填写名称");
                return
            }
            vm.data.enable=false
            vm.data.widgets=''
            // 新增
            IndexStyleSettings.save(vm.data,function(res){
                if (res.status==200) {
                    MessageService.success('保存成功')
                }
                $uibModalInstance.close();
            })
           
        }
    }
})();
