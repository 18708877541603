(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('redeem-code', {
                parent: 'entity',
                url: '/redeem-code?page&sort&search&valid',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN'],
                    pageTitle: 'global.menu.entities.redeemCode'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/redeem-code/redeem-codes.html',
                        controller: 'RedeemCodeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: false
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate('id,desc'),
                            ascending: PaginationUtil.parseAscending('id,desc'),
                            search: $stateParams.search,
                            valid: $stateParams.valid
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payCode');
                        $translatePartialLoader.addPart('payCodeType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('redeem-code-detail', {
                parent: 'redeem-code',
                url: '/redeem-code/{code}',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redeem-code/redeem-code-detail.html',
                        controller: 'RedeemCodeDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ExchangeCode', function(ExchangeCode) {
                                return ExchangeCode.get({code : $stateParams.code}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })

            .state('redeem-code.import', {
                parent: 'redeem-code',
                url: '/import',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redeem-code/redeem-code-dialog2.html',
                        controller: 'RedeemCodeDialogImportantController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    code: null,
                                    type: null,
                                    used: null,
                                    cancelled: null,
                                    amount: null,
                                    usedTime: null,
                                    usedUserId: null,
                                    usedUserName: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('redeem-code', null, {
                            reload: 'redeem-code'
                        });
                    }, function() {
                        $state.go('redeem-code');
                    });
                }]
            })
            .state('redeem-code.new', {
                parent: 'redeem-code',
                url: '/new',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/redeem-code/redeem-code-dialog.html',
                        controller: 'RedeemCodeDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function($state, $translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payBatch');
                        $translatePartialLoader.addPart('payCode');
                        $translatePartialLoader.addPart('payCodeType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('redeem-code.import-cancelled', {
                parent: 'redeem-code',
                url: '/importCancelled',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redeem-code/redeem-code-dialog6.html',
                        controller: 'RedeemCodeDialogImportantController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    code: null,
                                    type: null,
                                    used: null,
                                    cancelled: null,
                                    amount: null,
                                    usedTime: null,
                                    usedUserId: null,
                                    usedUserName: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('redeem-code', null, {
                            reload: 'redeem-code'
                        });
                    }, function() {
                        $state.go('redeem-code');
                    });
                }]
            })
            .state('redeem-code.export', {
                parent: 'redeem-code',
                url: '/export',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redeem-code/redeem-code-dialog3.html',
                        controller: 'RedeemCodeDialogImportantController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    code: null,
                                    type: null,
                                    used: null,
                                    cancelled: null,
                                    amount: null,
                                    usedTime: null,
                                    usedUserId: null,
                                    usedUserName: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('redeem-code', null, {
                            reload: 'redeem-code'
                        });
                    }, function() {
                        $state.go('redeem-code');
                    });
                }]
            })
            .state('redeem-code.activated', {
                parent: 'redeem-code',
                url: '/activate',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redeem-code/redeem-code-dialog5.html',
                        controller: 'RedeemCodeDialog3Controller',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    code: null,
                                    type: null,
                                    used: null,
                                    cancelled: null,
                                    amount: null,
                                    usedTime: null,
                                    usedUserId: null,
                                    usedUserName: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('redeem-code', null, {
                            reload: 'redeem-code'
                        });
                    }, function() {
                        $state.go('redeem-code');
                    });
                }]
            })
            .state('redeem-code.fast', {
                parent: 'redeem-code',
                url: '/fast',
                data: {
                    authorities: ['ROLE_STORE_DUIHUAN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/redeem-code/redeem-code-dialog4.html',
                        controller: 'RedeemCodeDialog2Controller',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    number: null,
                                    code: null,
                                    type: null,
                                    used: null,
                                    cancelled: null,
                                    amount: null,
                                    usedTime: null,
                                    usedUserId: null,
                                    usedUserName: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('redeem-code', null, {
                            reload: 'redeem-code'
                        });
                    }, function() {
                        $state.go('redeem-code');
                    });
                }]
            });
    }

})();
