(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-editor-settings', {
            parent: 'store',
            url: '/store-editor-settings',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-editor-settings/store-editor-settings.html',
                    controller: 'StoreEditorSettingsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }]
            }
        })
        .state('store-editor-settings-detail', {
            parent: 'store',
            url: '/store-editor-settings-detail',
            data: {
                authorities: ['ROLE_STORE_SETTING'],
                pageTitle: 'global.menu.entities.store'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-editor-settings/store-editor-settings-detail.html',
                    controller: 'StoreEditorSettingsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
