(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-entry-setting', {
            parent: 'store',
            url: '/store-entry-setting',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-entry-setting/store-entry-setting.html',
                    controller: 'StoreEntrySettingController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
            }
        })
        //  .state('store-third-tiktok-new', {
        //     parent: 'store-entry-setting',
        //     url: '/new',
        //     data: {
        //         authorities: ['ROLE_STORE_SETTING']
        //     },
        //     views: {
        //         'content@': {
        //             templateUrl: 'app/entities/store-third-tiktok/store-third-tiktok-dialog.html',
        //             controller: 'StoreThirdTiktokDialogController',
        //             controllerAs: 'vm',
        //         }
        //     },
        //     resolve: {
        //         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
        //             $translatePartialLoader.addPart('store');
        //             $translatePartialLoader.addPart('global');
        //             return $translate.refresh();
        //         }],
        //         entity: function () {
        //             return {
        //                 name: null,
        //                 id: null,
        //                 platform:"DY"
        //             };
        //         }
        //
        //     }
        // })
        // .state('store-third-tiktok-edit', {
        //     parent: 'store-entry-setting',
        //     url: '/edit/{id}',
        //     data: {
        //         authorities: ['ROLE_STORE_SETTING']
        //     },
        //     views: {
        //         'content@': {
        //             templateUrl: 'app/entities/store-third-tiktok/store-third-tiktok-dialog.html',
        //             controller: 'StoreThirdTiktokDialogController',
        //             controllerAs: 'vm',
        //         }
        //     },
        //     resolve: {
        //         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
        //             $translatePartialLoader.addPart('store');
        //             $translatePartialLoader.addPart('global');
        //             return $translate.refresh();
        //         }],
        //         entity: ['$stateParams', 'PlatformAbutment', function($stateParams, PlatformAbutment) {
        //                 return PlatformAbutment.get({id:$stateParams.id}).$promise;
        //             }
        //         ]
        //     }
        // })
        // ;
    }

})();
