(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InitMiniController', InitMiniController);

    InitMiniController.$inject = ['$scope', '$state', 'Store', 'MessageService', '$q', 'entity', 'PlatformWechatSetting', 'WechatAuth'];

    function InitMiniController ($scope, $state, Store, MessageService, $q, entity, PlatformWechatSetting, WechatAuth) {
        var vm = this;

        vm.store = Store.getCurrent();
        vm.pre_auth_code = WechatAuth.pre_auth_code();
        vm.platformWechatSetting = PlatformWechatSetting.get();
        vm.storeSmallAppSetting = entity;

        $q.all([vm.pre_auth_code.$promise, vm.platformWechatSetting.$promise, vm.storeSmallAppSetting.$promise, vm.store.$promise]).then(function () {
            if (vm.pre_auth_code.status == "200") {
                vm.preAuthCode =  vm.pre_auth_code.message;
            }
            $scope.notifyUrl = encodeURIComponent(vm.platformWechatSetting.backNotifyUrl + "/#/store/init-mini-notify");
        })

        $scope.auth = function(){ 
            window.location.href="https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid="
            +vm.platformWechatSetting.appId+"&pre_auth_code="+vm.preAuthCode+"&redirect_uri="+$scope.notifyUrl+"&auth_type=2";
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }

        $scope.pre= function(){
            if(vm.store.storeType2 != null && vm.store.storeType2.indexOf('WECHAT')>=0){
                $state.go("init-wechat");
            }else{
                $state.go("step1");
            }
        }
    }
})();
