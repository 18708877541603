
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('LogisticsController', LogisticsController);

    LogisticsController.$inject = ['$scope', '$q', '$state', 'MessageService', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Logistics', 'LogisticsCompany'];

    function LogisticsController($scope, $q, $state, MessageService, ParseLinks, paginationConstants, pagingParams, Logistics, LogisticsCompany) {
        var vm = this;

        vm.check = check;

        vm.allCompany = LogisticsCompany.getAllByCondition({items:[]});
        vm.logistics = Logistics.getAllByCondition({items:[]});

        $q.all([vm.allCompany.$promise,  vm.logistics.$promise]).then(function(){
            ProcessingData();
        });

        // 数据处理 -- 拼接数据
        function ProcessingData () {
            vm.companies = [];
            if (vm.allCompany.length > 0) {
                var company = {};
                for (var i = 0; i < vm.allCompany.length; i++) {

                    var flag = checkDate(vm.allCompany[i], vm.logistics);
                    if (flag) {
                        company = {id: vm.allCompany[i].id, name: vm.allCompany[i].name, code: vm.allCompany[i].code, 
                                    cancelled: vm.allCompany[i].cancelled, selected: true};
                        vm.companies.push(company);
                    } else {
                        company = {id: vm.allCompany[i].id, name: vm.allCompany[i].name, code: vm.allCompany[i].code, 
                                    cancelled: vm.allCompany[i].cancelled, selected: false};
                        vm.companies.push(company);
                    }
                }
            } else {
                MessageService.error("没有物流公司信息，请联系管理员");
            }
        }

        function check(company) {
            // 伪同步处理
            if (vm.companies.length > 0) {
                for (var i = 0; i < vm.companies.length; i++) {
                    if (company.id == vm.companies[i].id) {
                        if (company.selected) {
                             vm.companies[i].selected = false;
                        } else {
                            vm.companies[i].selected = true;
                        }
                    }
                }
            }
            Logistics.save({id: company.id}).$promise;
            $state.go("logistics");
        }

        // 验证数据是否存在集合中
        function checkDate(data, list) {
            var flag = false;
            if (list.length > 0) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].logisticsCompanyId == data.id) {
                        flag = true;
                        break;
                    }
                }
            }
            return flag;
        }
    }
})();
