(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('branch-store', {
            parent: 'entity',
            url: '/branch-store?page&sort&search',
            data: {
                authorities: ["ROLE_STORE_BRANCH_SHOP"],
                pageTitle: 'global.menu.entities.branchStore'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/branch-store/branch-store.html',
                    controller: 'BranchStoreController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('branchStore');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('branch-store-edit', {
            parent: 'branch-store',
            url: '/branch-store/{id}',
            data: {
                authorities: [],
                pageTitle: 'global.menu.entities.branchStore'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/branch-store/branch-store-dialog.html',
                    controller: 'BranchStoreDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('branchStore');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'BranchStore', function($stateParams, BranchStore) {
                    return BranchStore.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'branch-store',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('branch-store-detail', {
                parent: 'branch-store',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_STORE_MY_BRANCH_SHOP'],
                    pageTitle: 'global.menu.entities.myBranchStore'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/branch-store/branch-store-detail.html',
                        controller: 'BranchStoreDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('branchStore');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'BranchStore', function($stateParams, BranchStore) {
                        return BranchStore.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'branch-store',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
        .state('branch-store-detail-edit', {
                parent: 'branch-store-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_STORE_MY_BRANCH_SHOP'],
                    pageTitle: 'global.menu.entities.branchStore'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/branch-store/branch-store-dialog.html',
                        controller: 'BranchStoreDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('branchStore');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'BranchStore', function($stateParams, BranchStore) {
                        return BranchStore.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'branch-store',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

        .state('branch-store-new', {
            parent: 'branch-store',
            url: '/new',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/branch-store/branch-store-dialog.html',
                    controller: 'BranchStoreDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ["$state", '$translate', '$translatePartialLoader', function ($state, $translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('branchStore');
                    return $translate.refresh();
                }],
                entity: function() {
                    return {
                        quantity: null,
                        status: null,
                        id: null
                    };
                }
            }
        })
        .state('extension-setting-edit', {
                parent: 'extension-setting',
                url: '/edit',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.extensionSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/branch-store/extension-setting-dialog.html',
                        controller: 'ExtensionSettingDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('branchStore');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Store', function($stateParams, Store) {
                        return Store.getCurrent().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'branch-store',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
        .state('extension-setting', {
                parent: 'entity',
                url: '/extension-setting',
                data: {
                    authorities: ['ROLE_STORE_BRANCH_SHOP'],
                    pageTitle: 'global.menu.entities.extensionSetting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/branch-store/extension-setting.html',
                        controller: 'ExtensionSettingDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('branchStore');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Store', function($stateParams, Store) {
                        return Store.getCurrent().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'branch-store',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

        .state('branch-store-upload', {
            parent: 'branch-store',
            url: '/upload',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch-store/branch-store-upload.html',
                    controller: 'BranchStoreUploadController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('branchStore');
                            return $translate.refresh();
                        }],
                        entity: function() {
                            return {
                                quantity: null,
                                status: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('branch-store', null, {
                        reload: 'branch-store'
                    });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('branch-store-analysis', {
            parent: 'entity',
            url: '/branch-store-analysis',
            data: {
                authorities: ['ROLE_STORE_BRANCH_SHOP','ROLE_STORE_MY_BRANCH_SHOP'],
                pageTitle: 'global.menu.entities.branchStoreAnalysix'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/branch-store/branch-store-analysis.html',
                    controller: 'BranchStoreAnalysisController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('branch-store-analysis-mine', {
            parent: 'entity',
            url: '/branch-store-analysis/{id}',
            data: {
                authorities: [],
                pageTitle: 'global.menu.entities.branchStoreAnalysix'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/branch-store/branch-store-analysis.html',
                    controller: 'BranchStoreAnalysisController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
