(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('PageMySuffixSaveController', PageMySuffixSaveController);

    PageMySuffixSaveController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService', '$stateParams', 'ProductXmlFileServes',
     'ThemeClassify', 'Store', '$localStorage'];

    function PageMySuffixSaveController( $scope, Category, $uibModalInstance, MessageService, $stateParams, ProductXmlFileServes,
     ThemeClassify, Store, $localStorage) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.id = null;
        vm.themeClassifyList = ThemeClassify.getAllByCondition({items:[{key: "storeId", op: "=", value: $localStorage.user.storeId}]});
        vm.stores = Store.getAllByCondition({items:[]});

        if($stateParams.id){
            vm.id = $stateParams.id;
            ProductXmlFileServes.get({id: $stateParams.id}, function (resp) {
                vm.themeClassify = resp.themeId;
                vm.customStore = resp.customStoreId;
                if (resp.document && resp.document.pages && resp.document.pages.page) {
                    var p = resp.document.pages.page[0];
                    vm.bleedTop = p.trimbox.y;
                    vm.bleedBottom= p.mediabox.height - p.trimbox.height -  p.trimbox.y;
                    vm.bleedLeft= p.trimbox.x;
                    vm.bleedRight= p.mediabox.width - p.trimbox.width -  p.trimbox.x;
                }
                vm.page = {
                    name: resp.name,
                    replace: resp.document.replace +"",
                    category: resp.categoryId,
                    themeClassify: '',
                    type:resp.type,
                    customStore:{
                        id:resp.customStoreId,
                        name:resp.customStoreName
                    },
                    bleedTop:vm.bleedTop ,
                    bleedBottom:vm.bleedBottom,
                    bleedLeft:vm.bleedLeft,
                    bleedRight:vm.bleedRight
                };
            });

        }else{
            vm.page = {
                name: '',
                category: '',
                themeClassify: '',
                replace: "true",
                bleedTop:3,
                bleedBottom:3,
                bleedLeft:3,
                bleedRight:3
            };
        }

        vm.categories = Category.getAllByCondition({items: []});

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.customStoreChange = function(){
            if (vm.customStore == null) {
                vm.page.customStore = null;
            }
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.id == vm.customStore) {
                    vm.page.customStore = s;
                }
            }
        }

        $scope.validate = function () {
            if (vm.page.category == null || vm.page.category == "") {
                MessageService.error("请选择分类");
                return false;
            }
            if (vm.page.name == null || vm.page.name == "") {
                MessageService.error("请输入作品名称");
                return false;
            }
            if (vm.themeClassify == null || vm.themeClassify == "") {
                MessageService.error("请选择模板分类");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.page.themeClassify = vm.themeClassify;
            onSaveSuccess(vm.page);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
