(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('DialogConfig', DialogConfig);

    DialogConfig.$inject = ['$resource'];

    function DialogConfig ($resource) {
        var resourceUrl =  'manager/' + 'api/dialog-configs/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    if (data.productUrl !=null && !isNaN(data.productUrl)) {
                        data.productUrl = Number(data.productUrl);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
            'enable':{
                method: 'GET',
                params: {
                    path: 'enable'
                }
            }
        });
    }
})();
