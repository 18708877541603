(function () {
    'use strict';

    angular
        .module('backApp')
        .factory('User', User)
        .factory('Charge', Charge)
        .factory('Accounts', Accounts)
        .factory('PicManagements', PicManagements)
        .factory('PackageVersion', PackageVersion)
        .factory('SecretKey', SecretKey);

    User.$inject = ['$resource'];
    Charge.$inject = ['$resource'];
    Accounts.$inject = ['$resource'];
    PicManagements.$inject = ['$resource'];
    PackageVersion.$inject = ['$resource'];
    SecretKey.$inject = ['$resource'];

    function User($resource) {
        var service = $resource('service/api/users/:path/:id', {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'activate': {method: 'GET', params: {path: "activate"}},
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE'
            }
        });

        return service;
    }

    function Charge($resource) {
        var service = $resource('/security/api/charge-orders/:path/:id', {}, {
            'get': {
                method: "GET", isArray: true
                // transformResponse: function(data) {
                //     // console.log(data);
                //     // data = angular.fromJson(data);
                //     return data;
                // }
            }
        });

        return service;
    }


    function Accounts($resource) {
        var service = $resource('/security/api/account/resetPassword/:id', {}, {
            'get': {
                method: "GET", isArray: true,
                transformResponse: function(data) {
                    // console.log(data);
                    // data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
    function PicManagements($resource) {
        var resourceUrl = 'manager/' + 'api/pic-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'save': {method: 'POST'},
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }
    function PackageVersion($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/package-versions/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            "getByPort": {
                method: "GET",
                params: {
                    path: "getByPort"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'batchCreate':{
                method: 'GET',
                params: {

                    path: 'batchCreate'
                }
            },
            'delete': {
                method: 'DELETE'
            },
        });
    }
    function SecretKey($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/secret-keys/:path/:id';

        return $resource(resourceUrl, {}, {
            "generateKey": {
                method: 'GET',
                params: {
                    path: 'generateKey'
                },
            },
            'use': {
                method: 'GET',
                params: {
                    path: 'use'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'batchCreate':{
                method: 'GET',
                params: {
                    path: 'batchCreate'
                }
            },
            'delete': {
                method: 'DELETE'
            },
        });
    }
})();
