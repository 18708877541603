(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountModePaymentMiniController', OpenAccountModePaymentMiniController);

    OpenAccountModePaymentMiniController.$inject = ['$state', '$scope', '$timeout', 'Qinius', 'UidService', '$http', 'Store', 'MessageService','$q', 'StoreSmallAppWechatSetting'];

    function OpenAccountModePaymentMiniController($state, $scope, $timeout, Qinius, UidService, $http, Store, MessageService, $q, StoreSmallAppWechatSetting) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.store = Store.getCurrent();
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            vm.miniSetting = StoreSmallAppWechatSetting.getEntityByStoreId();
        });

        $scope.save = function () {
            if(!vm.miniSetting.weChatMchid){
                MessageService.error("微信收款账户不能为空");
                return
            }
            if(!vm.miniSetting.weChatKey){
                MessageService.error("微信收款秘钥不能为空");
                return
            }
            if(!vm.miniSetting.certificate){
                MessageService.error("微信收款证书KEY不能为空");
                return
            }
            StoreSmallAppWechatSetting.update(vm.miniSetting,function (res) {
                MessageService.success("配置成功");
                $scope.skip();
            },function (error) {
                MessageService.error("配置失败，请稍后重试！");
            });
        }


        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'certificate-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.miniSetting.certificate = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.removeFile = function () {
            vm.miniSetting.certificate = null;
        }
        $scope.goBack = function () {
            window.history.go(-1);
        }
        $scope.skip = function () {
            if(vm.store.storeType2.indexOf('WEB')>=0){
                $state.go('openAccount-modePayment-web');
            }else if(vm.store.storeType2.indexOf('BYTEDANCE')>=0){
                $state.go('openAccount-modePayment-byte');
            }else{
                $state.go('openAccount-style');
            }
        }
    }

})();
