(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('statisticalStore', {
            parent: 'entity',
            url: '/statistical/store',
            data: {
                authorities: ["ROLE_STORE_DATASTATISTICS"],
                pageTitle: 'home.title'
            },
            views: {
                'content2@': {
                    templateUrl: 'app/entities/statistical/statistical-store.html',
                    controller: 'StatisticalStoreController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('statisticalSales', {
            parent: 'entity',
            url: '/statistical',
            data: {
                authorities: ["ROLE_STORE_DATASTATISTICS"],
                pageTitle: 'global.menu.entities.salesManageCalculate'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/statistical/statistical-sales.html',
                    controller: 'StatisticalSalesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    return $translate.refresh();
                }]
            }
        })
        .state('data-helper', {
            parent: 'entity',
            url: '/data-helper',
            data: {
                authorities: ["ROLE_STORE_DATASTATISTICS"],
                pageTitle: 'global.menu.entities.dataHelper'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/statistical/data-helper.html',
                    controller: 'DataHelperController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    return $translate.refresh();
                }]
            }

        });
    }

})();
