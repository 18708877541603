(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-payment-setting', {
            parent: 'store',
            url: '/store-payment-setting',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            views: {

                'content@': {
                    templateUrl: 'app/entities/store-payment-setting/store-payment-setting-detail.html',
                    controller: 'StorePaymentSettingDetailController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['Store', function(Store) {
                        return Store.getCurrent().$promise;
                    }
                ]
            }
        })

        .state('store-payment-setting-edit', {
            parent: 'store-payment-setting',
            url: '/edit',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-payment-setting/store-payment-setting-dialog.html',
                    controller: 'StorePaymentSettingDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('storeType');
                    return $translate.refresh();
                }],
                entity: ['Store', function(Store) {
                        return Store.getCurrent().$promise;
                    }
                ]
            }
        })
        ;
    }

})();
