(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ExpressController', ExpressController);

    ExpressController.$inject = ['$state','$scope', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'ExpressLocation'];

    function ExpressController($state, $scope, ParseLinks, paginationConstants, pagingParams, MessageService, ExpressLocation) {

        var vm = this;

        vm.del = del;
        vm.changeStatus = changeStatus;

        getAll();
        function getAll(){
            ExpressLocation.getLocation({}, function (data) {
                vm.expressLocation = data.object;
            });
        }

        function del (obj) {
            Express.del({id: obj.id}, function(){
                $state.go('express', null, {reload: 'express'});
                MessageService.success("删除成功");
            }, function (error) {
                MessageService.error("删除失败");
            });
        }

        function changeStatus (obj) {
            ExpressLocation.changeStatus({id: obj.id}, function (){
                MessageService.success("操作成功");
                $state.go('express', null, {reload: 'express'});
            }, function (error) {
                MessageService.error("操作失败");
            })
        }
    }
})();
