(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayOrderDeleteController',PayOrderDeleteController);

    PayOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'PayOrder'];

    function PayOrderDeleteController($uibModalInstance, entity, PayOrder) {
        var vm = this;

        vm.payOrder = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PayOrder.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
