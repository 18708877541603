(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StatisticalSalesController', StatisticalSalesController);

    StatisticalSalesController.$inject = ['$scope', '$state', '$q', 'SalesOrder', 'MessageService', 'XlsxService', 'StatisticalSales', 'ProductPurchase', 'StatisticalSalesService','Principal'];

    function StatisticalSalesController($scope, $state, $q, SalesOrder, MessageService, XlsxService, StatisticalSales, ProductPurchase, StatisticalSalesService,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DATASTATISTICS').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.page = 0;
        vm.itemsPerPage = 20;

        vm.times = {
            time1: {
                key: 1,
                value: '最近7日'
            },
            time2: {
                key: 2,
                value: '最近15日'
            },
            time3: {
                key: 3,
                value: '最近30日'
            }
        };
        vm.time = vm.times.time1;

        vm.openCalendar = openCalendar;
        vm.changeTime = changeTime;
        vm.jump = jump;
        vm.fondByTime = fondByTime;
        vm.downloadData = downloadData;
        //vm.changeStaType = changeStaType;
        vm.changeDayType = changeDayType;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        // 昨日数据
        StatisticalSales.yesterdayDate({}, function(data) {
            vm.yesterdayDatePps = data.object;
        });
        SalesOrder.yesterdayDate({}, function(data) {
            vm.yesterdayDateOs = data.object;
        });

        StatisticalSalesService.yesterdayDateAbled({}, function(data) {
            vm.allPayCodeNum = data.all;
            vm.usedPayCodeNum = data.used;
            vm.activatedCodeNum = data.activated;
        });

        //vm.staType = 0;
        //function changeStaType (obj) {
        //    vm.staType = obj;
        //    if (obj == 0) {
        //        fondByTime();
        //    }
        //}
        //
        vm.dataType = "day";
        changeDayType('day');

        function changeDayType(obj) {
            vm.dataType = obj;
            if (obj == 'day') {
                $("#day").removeClass("bgcolor-fff").addClass("bgcolor-FF5001-fw");
                $("#month").removeClass("bgcolor-FF5001-fw").addClass("bgcolor-fff");
                $("#quarter").removeClass("bgcolor-FF5001-fw").addClass("bgcolor-fff");
                document.getElementById("field_startTime").disabled = false;
                document.getElementById("field_endTime").disabled = false;
                vm.times = {
                    time1: {
                        key: 1,
                        value: '最近7日'
                    },
                    time2: {
                        key: 2,
                        value: '最近15日'
                    },
                    time3: {
                        key: 3,
                        value: '最近30日'
                    }
                };
                vm.time = vm.times.time1;
            } else if (obj == 'month') {
                $("#month").removeClass("bgcolor-fff").addClass("bgcolor-FF5001-fw");
                $("#day").removeClass("bgcolor-FF5001-fw").addClass("bgcolor-fff");
                $("#quarter").removeClass("bgcolor-FF5001-fw").addClass("bgcolor-fff");
                document.getElementById("field_startTime").disabled = true;
                document.getElementById("field_endTime").disabled = true;
                vm.times = {
                    time1: {
                        key: 1,
                        value: '近3个月'
                    },
                    time2: {
                        key: 2,
                        value: '近6个月'
                    },
                    time3: {
                        key: 3,
                        value: '近9个月'
                    },
                    time4: {
                        key: 4,
                        value: '近12个月'
                    }
                };
                vm.time = vm.times.time1;
            } else if (obj == 'quarter') {
                $("#quarter").removeClass("bgcolor-fff").addClass("bgcolor-FF5001-fw");
                $("#day").removeClass("bgcolor-FF5001-fw").addClass("bgcolor-fff");
                $("#month").removeClass("bgcolor-FF5001-fw").addClass("bgcolor-fff");
                document.getElementById("field_startTime").disabled = true;
                document.getElementById("field_endTime").disabled = true;
                vm.times = {
                    time1: {
                        key: 1,
                        value: '第一季度'
                    },
                    time2: {
                        key: 2,
                        value: '第二季度'
                    },
                    time3: {
                        key: 3,
                        value: '第三季度'
                    },
                    time4: {
                        key: 4,
                        value: '第四季度'
                    }
                };
                vm.time = vm.times.time1;
            }
            changeTime();
        }

        ////获得区间内所有日期
        function getMonthBetween(start, end) {
            var result = [];
            var s = start.split("-");
            var e = end.split("-");
            var min = new Date();
            var max = new Date();
            min.setFullYear(s[0], s[1]);
            max.setFullYear(e[0], e[1]);

            var curr = min;
            while (curr <= max) {
                var month = curr.getMonth();
                if (month == 0) {
                    result.push((curr.getFullYear() - 1) + "-12");
                } else {
                    result.push(curr.getFullYear() + "-" + (month < 10 ? ("0" + month) : month));
                }
                curr.setMonth(month + 1);
            }
            return result;
        }

        // 选择时间下拉框
        function changeTime() {
            /** 日统计情况：需要计算开始和结束的时间
                月统计情况：需要计算开始和结束的月份
                季度统计下：不要计算开始和结束的时间，但是要传是第几季度 **/

            // 今天
            var today = new Date();
            // 结束时间
            var endTime = "";
            // 开始时间
            var startTime = new Date();
            // 上个月底
            var td = new Date();
            td = new Date(td.setDate(1));
            var lastMonth = new Date(td.setDate(td.getDate() - 1));

            // 解释为什么要加10天，因为每个月总会差别2~4天 ，就索性加10天
            if (vm.time.key == 1) {
                if (vm.dataType == 'month') {
                    // 最近三个月，上个月月底为结束时间，往前推三个月
                    startTime.setMonth(startTime.getMonth() - 3);
                } else if (vm.dataType == 'day') {
                    startTime.setDate(new Date().getDate() - 7);
                } else if (vm.dataType == 'quarter') {
                    // 第一季度： 当年的季度
                    vm.timeKey = 1;
                }
            } else if (vm.time.key == 2) {
                if (vm.dataType == 'month') {
                    // 最近六个月，上个月月底为结束时间，往前推六个月
                    startTime.setMonth(startTime.getMonth() - 6);
                } else if (vm.dataType == 'day') {
                    startTime.setDate(new Date().getDate() - 15);
                } else if (vm.dataType == 'quarter') {
                    // 第二季度： 当年的季度
                    vm.timeKey = 2;
                }
            } else if (vm.time.key == 3) {
                if (vm.dataType == 'month') {
                    // 最近九个月，上个月月底为结束时间，往前推九个月
                    startTime.setMonth(startTime.getMonth() - 9);
                } else if (vm.dataType == 'day') {
                    startTime.setDate(new Date().getDate() - 30);
                } else if (vm.dataType == 'quarter') {
                    // 第三季度： 当年的季度
                    vm.timeKey = 3;
                }
            } else if (vm.time.key == 4) {
                if (vm.dataType == 'month') {
                    // 最近十二个月，上个月月底为结束时间，往前推十二个月
                    startTime.setMonth(startTime.getMonth() - 12);
                } else if (vm.dataType == 'day') {
                    // 不存在
                } else if (vm.dataType == 'quarter') {
                    // 第四季度： 当年的季度
                    vm.timeKey = 4;
                }
            }
            if (vm.dataType != 'month') {
                // 日统计的结束时间是年月日---季统计的最后时间也显示为昨天
                endTime = today;
                endTime = new Date(endTime.setDate(endTime.getDate() - 1));
            } else {
                // endTime = lastMonth;
                endTime = lastMonth.getFullYear() + "-" + ((lastMonth.getMonth() + 1) > 9 ? (lastMonth.getMonth() + 1) : "0" +
                    (lastMonth.getMonth() + 1)) + "-" + lastMonth.getDate();
            }
            vm.endTime = new Date(endTime);

            vm.startTime = startTime;
            startTime = startTime.getFullYear() + "-" + ((startTime.getMonth() + 1) > 9 ? (startTime.getMonth() + 1) : "0" + (startTime.getMonth() + 1)) +
                "-" + (startTime.getDate() > 9 ? startTime.getDate() : ("0" + startTime.getDate()));

            seriesData(startTime, endTime);
        }

        function fondByTime() {
            if (vm.dataType == 'quarter') {
                return;
            }
            if (vm.startTime == '' || vm.startTime == undefined || vm.startTime == null) {
                MessageService.error('请选择开始日期');
                return;
            }
            if (vm.endTime == '' || vm.endTime == undefined || vm.endTime == null) {
                MessageService.error('请选择结束日期');
                return;
            }

            // 时间区间判断
            var date3 = vm.endTime.getTime() - vm.startTime.getTime();
            var days = Math.floor(date3 / (24 * 3600 * 1000));
            if (days >= 31) {
                MessageService.error("日统计最长时间周期31天");
                return;
            }

            var startTime = vm.startTime;
            var endTime = new Date(vm.endTime);
            startTime = startTime.getFullYear() + "-" + ((startTime.getMonth() + 1) > 9 ? (startTime.getMonth() + 1) : "0" + (startTime.getMonth() + 1)) +
                "-" + (startTime.getDate() > 9 ? startTime.getDate() : ("0" + startTime.getDate()));
            if (endTime == null) {
                endTime = new Date(vm.endTime);
            }
            if (endTime != '' && endTime != null) {
                endTime = endTime.getFullYear() + "-" + ((endTime.getMonth() + 1) > 9 ? (endTime.getMonth() + 1) : "0" + (endTime.getMonth() + 1)) + "-" + endTime.getDate();
            }
            seriesData(startTime, endTime);
        }

        vm.orderNumList = [];

        function seriesData(startTime, endTime) {
            if (endTime == '' || endTime == null) {
                endTime = vm.endTime;
            }
            if (vm.dataType == 'quarter') {
                // 季度
                var td = new Date();
                if (vm.timeKey == 1) {
                    startTime = td.getFullYear() + '-01-01';
                    endTime = td.getFullYear() + '-03-31';
                } else if (vm.timeKey == 2) {
                    startTime = td.getFullYear() + '-04-01';
                    endTime = td.getFullYear() + '-06-30';
                } else if (vm.timeKey == 3) {
                    startTime = td.getFullYear() + '-07-01';
                    endTime = td.getFullYear() + '-09-30';
                } else if (vm.timeKey == 4) {
                    startTime = td.getFullYear() + '-10-01';
                    endTime = td.getFullYear() + '-12-30';
                }
                vm.startTime = startTime;
                vm.endTime = endTime;
            } else if (vm.dataType == 'month') {
                vm.startTime.setDate(1);
                startTime = vm.startTime.getFullYear() + "-" + ((vm.startTime.getMonth() + 1) > 9 ? (vm.startTime.getMonth() + 1) : "0" + (vm.startTime.getMonth() + 1)) +
                    "-" + (vm.startTime.getDate() > 9 ? vm.startTime.getDate() : ("0" + vm.startTime.getDate()));
            }
            console.log(startTime + "  ++++++++  " + endTime);

            var bd = new Date(startTime),
                be = new Date(vm.endTime);
            var bd_time = bd.getTime(),
                be_time = be.getTime(),
                time_diff = be_time - bd_time;
            vm.date_arr = [];
            if (vm.dataType == "day") {
                for (var i = 0; i <= time_diff; i += 86400000) {
                    var ds = new Date(bd_time + i);

                    vm.date_arr.push(ds.getFullYear() + "-" + ((ds.getMonth() + 1) > 9 ? (ds.getMonth() + 1) : "0" + (ds.getMonth() + 1)) + '-' +
                        (ds.getDate() > 9 ? ds.getDate() : "0" + ds.getDate()))
                }
            } else if (vm.dataType == 'month' || vm.dataType == 'quarter') {
                vm.date_arr = getMonthBetween(startTime, endTime);
                if (vm.dataType == 'quarter') {
                    var endTime0 = new Date();
                    endTime0 = new Date(endTime0.setDate(endTime0.getDate() - 1));
                    vm.startTime = startTime;
                    vm.endTime = endTime;
                }
            }

            if (endTime.length > 10) {
                endTime = endTime.getFullYear() + "-" + ((endTime.getMonth() + 1) > 9 ? (endTime.getMonth() + 1) : "0" + (endTime.getMonth() + 1)) + "-" + endTime.getDate();
            }

            var type = 1;
            if (vm.dataType != "day") {
                type = 2;
            }
            vm.payCode2List = StatisticalSalesService.getAllByTimeSlot({
                'startTime': startTime,
                'endTime': endTime,
                'type': type
            });
            console.log(vm.payCode2List);
            //vm.salesOrder2 = SalesOrder.dateList({'startTime': startTime, 'endTime': endTime});
            //vm.pps2 = ProductPurchase.dateList({'startTime': startTime, 'endTime': endTime});
            //vm.sos2 = SalesOrder.getListPage({"startTime": startTime, "endTime": endTime});
            //vm.sosList2 = SalesOrder.getListPage2({"startTime": startTime, "endTime": endTime});
            $q.all([vm.payCode2List.$promise]).then(function() {
                var usedNums = [];
                var activatedNums = [];
                var allNums = [];

                for (var i = 0; i < vm.date_arr.length; i++) {
                    usedNums.push(0);
                    activatedNums.push(0);
                    allNums.push(0);
                    for (var j = 0; j < vm.payCode2List.length; j++) {
                        if (vm.date_arr[i] == vm.payCode2List[j].createdDate) {
                            usedNums.pop();
                            usedNums.push(vm.payCode2List[j].usedNum);

                            activatedNums.pop();
                            activatedNums.push(vm.payCode2List[j].activatedNum);

                            allNums.pop();
                            allNums.push(vm.payCode2List[j].allNum);
                        }
                    }
                }

                console.log(usedNums);
                echarts_init(vm.date_arr, usedNums, activatedNums, allNums);

            })
        }

        function echarts_init(date_arr, datas, datas2, datas3) {
            // 画图部分
            var myChart = echarts.init(document.getElementById("main"));

            // 指定图表的配置项和数据
            var option = {
                title: {
                    text: ' | 趋势图'
                },
                legend: {
                    orient: 'vertical',
                    y: 'bottom',
                    x: 'auto',
                    data: ['使用数', '激活数', '购买总数']
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: date_arr
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    name: '使用数',
                    data: datas,
                    type: 'line'
                }, {
                    name: '激活数',
                    data: datas2,
                    type: 'line'
                }, {
                    name: '购买总数',
                    data: datas3,
                    type: 'line'
                }]
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        }

        // 获取页数
        // findPage();
        function findPage() {
            SalesOrder.getData4({}, function(data) {
                vm.totalItems = data;
            })
        }

        // 跳转
        function jump() {
            if (vm.jumpText != undefined && vm.jumpText != '') {
                vm.page = vm.jumpText;
            }
            seriesData();
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat() {
            return 'yyyy-MM-dd';
        }

        // 下载数据文件
        function downloadData() {
            var tabData = [];
            var sheetName = '销售统计';
            for (var i = 0; i < vm.payCode2List.length; i++) {
                var jsonObj = {
                    "序号": i,
                    "订购时间": vm.payCode2List[i].createdDate,
                    "制卡数": vm.payCode2List[i].allNum,
                    "激活数": vm.payCode2List[i].activatedNum,
                    "使用数": vm.payCode2List[i].usedNum
                };
                tabData.push(jsonObj);
            }
            XlsxService.xlsxOut(tabData, sheetName, "销售统计");
        }
    }
})();
