(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('OpenAccountModePaymentController', OpenAccountModePaymentController);

    OpenAccountModePaymentController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', '$http', 'Store', 'MessageService', 'entity','PayOrder','$q','PackageVersion'];

    function OpenAccountModePaymentController($state, $scope, $timeout, Qinius, $uibModal, $http, Store, MessageService, entity,PayOrder, $q,PackageVersion) {
        var vm = this;
        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.data = entity;
        vm.store = Store.getCurrent();

        $q.all([vm.store.$promise, vm.data.$promise]).then(function () {
            if(vm.store.activated){
                $state.go('openAccount-commercial');
                return
            }
            init()
        })
        function init(){
            clearInterval($scope.timer);
            var data = {
                type: 'WeChat',
                amount: vm.data.price,
                name: "购买套餐"
            };
            PayOrder.createPayOrder2(data ,function(result){
                vm.payOrderId = result.id;
                $http({
                    url: '/manager/api/pay-orders/createQRCode/'+vm.payOrderId,
                    method: 'POST',
                    responseType: 'blob'
                }).success(function(res) {
                    console.log(res);
                    vm.prepare = true;
                    var blob = new Blob([res], {
                        type: "image/jpeg"
                    });
                    $scope.image = URL.createObjectURL(blob);
                    //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                    $scope.timer = setInterval(function() {
                        PayOrder.get({
                            id: vm.payOrderId
                        }, function(val) {
                            if (!!val && val.paid) {
                                clearInterval($scope.timer);
                                MessageService.success("支付完成");
                                $state.go('openAccount-commercial')
                            }
                        }, function() {});
                    }, 2000);
                })
            },function(){
                MessageService.error("创建充值订单失败，请稍后再试");
            })
        }

        $scope.save = function () {
            PayOrder.get({
                id: vm.payOrderId
            }, function(val) {
                if (!!val && val.paid) {
                    clearInterval($scope.timer);
                    MessageService.success("支付完成");
                    $state.go('openAccount-module')
                }else{
                    MessageService.success("请先支付！");
                }
            }, function() {});
            // $state.go('openAccount-commercial')
        }
    }

})();
