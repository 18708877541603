(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StorePaymentSettingDialogController', StorePaymentSettingDialogController);

    StorePaymentSettingDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'StoreWechatSetting',
    'StoreSmallAppWechatSetting', 'StorePaymentSetting', 'Qinius', 'UidService', '$uibModal', 'StorePickLocations'];

    function StorePaymentSettingDialogController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, StoreWechatSetting,
     StoreSmallAppWechatSetting, StorePaymentSetting, Qinius, UidService, $uibModal, StorePickLocations) {
        var vm = this;

        vm.store = entity;
        console.log(entity);
        vm.selectTab = 1;
        //代运营 保存
        vm.save2 = save2;
        vm.clear2 = clear2;
        vm.wechatSetting = null;
        vm.miniSetting = null;
        vm.tokens = Qinius.get();

        vm.subPayMentOptions = [{
            key: "CUSTOM",
            value: "自定义",
            show: true
        }, {
            key: "MINI",
            value: "同小程序收款账号",
            show: true
        }, {
            key: "WEB",
            value: "同电脑端收款账号",
            show: true
        }, ]

        vm.miniPayMentOptions = [{
            key: "CUSTOM",
            value: "自定义",
            show: true
        }, {
            key: "SUB",
            value: "同公众号收款账号",
            show: true
        }, {
            key: "WEB",
            value: "同电脑端收款账号",
            show: true
        }, ]

        vm.webPayMentOptions = [{
            key: "CUSTOM",
            value: "自定义",
            show: true
        }, {
            key: "SUB",
            value: "同公众号收款账号",
            show: true
        }, {
            key: "MINI",
            value: "同小程序收款账号",
            show: true
        }, ]

        $scope.payMentOPChange = function(index) {
            if (index == 1) {
                if (vm.wechatSetting.paymentSource == 'MINI') {
                    vm.wechatSetting.weChatMchid = vm.miniSetting.weChatMchid;
                    vm.wechatSetting.weChatKey = vm.miniSetting.weChatKey;
                    vm.wechatSetting.certificate = vm.miniSetting.certificate;
                }
                if (vm.wechatSetting.paymentSource == 'WEB') {
                    vm.wechatSetting.weChatMchid = vm.storePaymentSetting.weChatMchid;
                    vm.wechatSetting.weChatKey = vm.storePaymentSetting.weChatKey;
                    vm.wechatSetting.certificate = vm.storePaymentSetting.weChatCertificatePath;
                }
            }
            if (index == 2) {
                if (vm.miniSetting.paymentSource == 'SUB') {
                    vm.miniSetting.weChatMchid = vm.wechatSetting.weChatMchid;
                    vm.miniSetting.weChatKey = vm.wechatSetting.weChatKey;
                    vm.miniSetting.certificate = vm.wechatSetting.certificate;
                }
                if (vm.miniSetting.paymentSource == 'WEB') {
                    vm.miniSetting.weChatMchid = vm.storePaymentSetting.weChatMchid;
                    vm.miniSetting.weChatKey = vm.storePaymentSetting.weChatKey;
                    vm.miniSetting.certificate = vm.storePaymentSetting.weChatCertificatePath;
                }
            }
            if (index == 3) {
                if (vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.storePaymentSetting.weChatMchid = vm.wechatSetting.weChatMchid;
                    vm.storePaymentSetting.weChatAppid = vm.wechatSetting.authorizerAppId;
                    vm.storePaymentSetting.weChatKey = vm.wechatSetting.weChatKey;
                    vm.storePaymentSetting.weChatCertificatePath = vm.wechatSetting.certificate;
                }
                if (vm.storePaymentSetting.paymentSource == 'MINI') {
                    vm.storePaymentSetting.weChatMchid = vm.miniSetting.weChatMchid;
                    vm.storePaymentSetting.weChatAppid = vm.miniSetting.authorizerAppId;
                    vm.storePaymentSetting.weChatKey = vm.miniSetting.weChatKey;
                    vm.storePaymentSetting.weChatCertificatePath = vm.miniSetting.certificate;
                }
            }
        }

        $scope.removeFile = function (type) {
            if (type == 'miniSetting') {
                vm.miniSetting.certificate = null;
            }
            if (type == 'storePaymentSetting') {
                vm.storePaymentSetting.weChatCertificatePath = null;
            }
            if (type == 'wechatSetting') {
                vm.wechatSetting.certificate = null;
            }
        };

        window.updateImgFile2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];

                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'certificate-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.miniSetting.certificate = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];

                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'certificate-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.wechatSetting.certificate = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.updateImgFile3 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];

                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'certificate-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.storePaymentSetting.weChatCertificatePath = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        $scope.payMentOPshow = function(index) {
            vm.selectTab = index;
            vm.subPayMentOptions[1].show = true;
            vm.subPayMentOptions[2].show = true;
            vm.miniPayMentOptions[1].show = true;
            vm.miniPayMentOptions[2].show = true;
            vm.webPayMentOptions[1].show = true;
            vm.webPayMentOptions[2].show = true;
            if (index == 1) {
                if (vm.miniSetting.paymentSource == 'SUB' || vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.subPayMentOptions[1].show = false;
                    vm.subPayMentOptions[2].show = false;
                }
                if (vm.miniSetting.paymentSource == 'WEB') {
                    vm.subPayMentOptions[1].show = false;
                }
                if (vm.storePaymentSetting.paymentSource == 'MINI') {
                    vm.subPayMentOptions[2].show = false;
                }
            }
            if (index == 2) {
                if (vm.storePaymentSetting.paymentSource == 'MINI' || vm.wechatSetting.paymentSource == 'MINI') {
                    vm.miniPayMentOptions[1].show = false;
                    vm.miniPayMentOptions[2].show = false;
                }
                if (vm.wechatSetting.paymentSource == 'WEB') {
                    vm.miniPayMentOptions[1].show = false;
                }
                if (vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.miniPayMentOptions[2].show = false;
                }
            }
            if (index == 3) {
                if (vm.wechatSetting.paymentSource == 'WEB' || vm.miniSetting.paymentSource == 'WEB') {
                    vm.webPayMentOptions[1].show = false;
                    vm.webPayMentOptions[2].show = false;
                }
                if (vm.wechatSetting.paymentSource == 'MINI') {
                    vm.webPayMentOptions[1].show = false;
                }
                if (vm.miniSetting.paymentSource == 'SUB') {
                    vm.webPayMentOptions[2].show = false;
                }
            }
        }



        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }


        vm.storePaymentSetting = StorePaymentSetting.getCurrent();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        $q.all([vm.store.$promise, vm.storePaymentSetting.$promise, vm.tokens.$promise]).then(function(){
             if (vm.store.payType) {
                if (vm.store.payType.indexOf("paycode")>= 0 ) {
                    vm.store.paycode = true;
                }
                if (vm.store.payType.indexOf("wechat")>= 0 ) {
                    vm.store.wechat = true;
                }
                if (vm.store.payType.indexOf("underLine")>= 0 ) {
                    vm.store.underLine = true;
                }
                if (vm.store.payType.indexOf("giftVoucher")>= 0 ) {
                    vm.store.giftVoucher = true;
                }
                if (vm.store.payType.indexOf("balancePay")>= 0 ) {
                    vm.store.balancePay = true;
                }
            }
            if (vm.store.storeType2) {
                if (vm.store.storeType2.indexOf("WECHAT" )>=0) {
                    vm.store.storeTypeWechat = true;
                }
                if (vm.store.storeType2.indexOf("MINI" )>=0) {
                    vm.store.storeTypeMini = true;
                }
            }

            vm.token = vm.tokens.token;

            vm.wechatSetting = StoreWechatSetting.getByStoreId({storeId:vm.store.id});
            vm.miniSetting = StoreSmallAppWechatSetting.getEntityByStoreId({storeId:vm.store.id});
            $q.all([vm.miniSetting.$promise , vm.wechatSetting.$promise]).then(function(){
                if (!vm.wechatSetting.id) {
                    vm.selectTab = 2;
                }
                if (!vm.miniSetting.id) {
                    vm.selectTab = 3;
                }
                if (vm.miniSetting.paymentSource == 'SUB' || vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.subPayMentOptions[1].show = false;
                    vm.subPayMentOptions[2].show = false;
                }
            });
        });

        function clear2 (){
            $state.go("store-payment-setting", null, { reload: 'store-payment-setting' });
        }


        function save2 () {
            if(vm.store.selfLift && (!vm.storePickLocations || vm.storePickLocations.length == 0)){
                MessageService.error("请添加自提地址");
                return
            }
            vm.store.payType = "";
            if (vm.store.paycode) {
                vm.store.payType += "paycode;"
            }
            if (vm.store.underLine) {
                vm.store.payType += "underLine;"
            }
            if (vm.store.wechat) {
                vm.store.payType += "wechat;"
            }
            if (vm.store.giftVoucher) {
                vm.store.payType += "giftVoucher;"
            }
            if (vm.store.balancePay) {
                vm.store.payType += "balancePay;"
            }
            vm.isSaving = true;
            if (vm.wechatSetting.id) {
                StoreWechatSetting.update(vm.wechatSetting, function(data){
                }, function(data){
                    MessageService.error("公众号保存微信商户失败！");
                })
            }
            if (vm.miniSetting.id) {
                StoreSmallAppWechatSetting.update(vm.miniSetting, function(data){
                }, function(data){
                    MessageService.error("小程序保存微信商户失败！");
                })
            }
            // StorePaymentSetting.update(vm.storePaymentSetting , function(data){
            //     MessageService.success("web端支付信息保存成功");
            // },function(){
            //     MessageService.success("web端支付信息保存失败");
            // })
            if (vm.storePaymentSetting.id) {
                vm.storePaymentSetting2 = StorePaymentSetting.update(vm.storePaymentSetting);
            }else{
                vm.storePaymentSetting2 = StorePaymentSetting.save(vm.storePaymentSetting);
            }
            Store.update(vm.store , onSaveSuccess2, onSaveError);
        }

        function onSaveSuccess2 (result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");

            $state.go("store-payment-setting", null, { reload: 'store-payment-setting' });
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }

        //自提地点
        vm.storePickLocations = [];
        getStorePickLocation();
        function getStorePickLocation(){
            StorePickLocations.getAllByCondition({items:[]}, function (res) {
                vm.storePickLocations = res;
            })
        }
        $scope.addSelf = function () {
            $uibModal.open({
                templateUrl: 'app/entities/store-payment-setting/self-modal.html',
                controller: 'SelfModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {};
                    }]
                }
            }).result.then(function(res) {
                console.log(res)
                if(res){
                    getStorePickLocation();
                }
            });
        }
        $scope.editSelf = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/store-payment-setting/self-modal.html',
                controller: 'SelfModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            }).result.then(function(res) {
                console.log(res)
                if(res){
                    getStorePickLocation();
                }
            });
        }
        $scope.deleteSelf = function (data) {
            StorePickLocations.delete({id: data.id}, function (res) {
                MessageService.success("删除成功");
                getStorePickLocation();
            },function (error) {
                MessageService.error("删除失败");
            })
        }
        vm.copyUrl2=function(){
            var Url2 = document.getElementById("biao1");
            Url2.select(); // 选择对象 
            document.execCommand("Copy"); // 执行浏览器复制命令 
            MessageService.success("已复制好，可贴粘");
        }
    }
})();
