(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('StoreGroupBuys', StoreGroupBuys)
        .factory('CustomerGroupBuy', CustomerGroupBuy)
        .factory('CustomerGroupSituation', CustomerGroupSituation)
        .factory('GroupBuyOrders', GroupBuyOrders)
        .factory('CustomerWallets', CustomerWallets);

    StoreGroupBuys.$inject = ['$resource', 'DateUtils'];
    CustomerGroupBuy.$inject = ['$resource', 'DateUtils'];
    CustomerGroupSituation.$inject = ['$resource'];
    GroupBuyOrders.$inject = ['$resource', 'DateUtils'];
    CustomerWallets.$inject = ['$resource', 'DateUtils'];

    function StoreGroupBuys ($resource,DateUtils) {
        var resourceUrl =  'manager/' + 'api/store-group-buys/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            'getPriceByProductAndStore': {
                method: 'GET',
                params: {
                    path: 'getPriceByProductAndStore'
                }
            },
            'byCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'byCondition'
                }
            },
            'getAllByCondition': {
                method: 'post',
                isArray: true,
                params: {
                    path: 'getAllByCondition'
                }
            },
            'updateEnable': {
                method: 'GET',
                params: {
                    path: 'updateEnable'
                }
            },
            'reject': {
                method: 'GET',
                params: {
                    path: 'reject'
                }
            },
            'getNum': {
                method: 'GET',
                params: {
                    path: 'getNum'
                }
            },
            'exchangeCustomer': {
                method: 'GET',
                params: {
                    path: 'exchangeCustomer'
                }
            },
            'update': { method:'PUT' },
            'save': {
                method: 'POST'
            }
        })
    }

    function CustomerGroupBuy ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/customer-group-buys/:path/:id';

        return $resource(resourceUrl, {}, {
            'unJoin':{
                method: 'POST',
                params: {
                    path: 'unJoin'
                },
                isArray: true
            },
            'share': {
                method: 'GET',
                params: {
                    path: 'share'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getNum":{
                method:"GET",
                params:{
                    path:"getNum"
                }
            },
            'save':{
                method: 'POST'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function GroupBuyOrders ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/group-buy-orders/:path/:id/:customerGroupBuyId';

        return $resource(resourceUrl, {}, {
            'createSalesOrder': {
                method: 'GET',
                params: {
                    path: 'createSalesOrder'
                }
            },
            'getByCustomerGroupBuyId':{
                method: 'GET',
                params: {
                    path: 'getByCustomerGroupBuyId'
                }
            },
            'pay':{
                method: 'POST',
                params: {
                    path: 'pay'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'save':{
                method: 'POST'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function CustomerWallets ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/customer-wallets/:path/:id/:customerId';

        return $resource(resourceUrl, {}, {
            'dark':{
                method: 'GET',
                params: {
                    path: 'dark'
                }
            },
            'customer':{
                method: 'GET',
                params: {
                    path: 'customer'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'save':{
                method: 'POST'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function CustomerGroupSituation ($resource) {
        var resourceUrl =  'manager/' + 'api/customer-group-situations/:path/:id';

        return $resource(resourceUrl, {}, {
            'dark':{
                method: 'GET',
                params: {
                    path: 'dark'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'save':{
                method: 'POST'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
