(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotStatisticeController', MapDepotStatisticeController);

    MapDepotStatisticeController.$inject = [ 'MessageService','CustomerStorages','ParseLinks','paginationConstants', '$scope'];

    function MapDepotStatisticeController( MessageService,CustomerStorages,ParseLinks,paginationConstants, $scope) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.jump = jump;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 1;
        init();

        $scope.fixUsedSize = function(price){
            CustomerStorages.fixCustomerUsedSize({customerId:price.userId}, function(data){
                init();
                MessageService.success("操作成功");
            }, function (error) {
                MessageService.error("操作出错");
            })
        }
        function init() {
            if (vm.searchQuery) {
                CustomerStorages.byCondition1({
                    items: [{ key: "userName", op: "like", value: vm.searchQuery}],
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["usedSize,desc"]
                }, function (res, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.prices = res;
                }, function (error) {
                    MessageService.error("数据加载出错");
                });
            } else {
                CustomerStorages.byCondition1({
                    items: [],
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["usedSize,desc"]
                }, function (res, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.prices = res;
                }, function (error) {
                    MessageService.error("数据加载出错");
                });
            }
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            init()
        }
        function transition() {
            init()
        }
        vm.search=function(){
            init();
        }
    }
})();