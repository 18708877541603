(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SceneDialogController', SceneDialogController);

    SceneDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'Mould2Ds', 'MessageService',
    'Upload', 'UidService', 'ThemeClassify', '$localStorage'];

    function SceneDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, Mould2Ds, MessageService,
        Upload, UidService, ThemeClassify, $localStorage) {
        var vm = this;
        vm.type = entity.state;
        if(vm.type == 'look'){
            vm.page = 3;
        }else{
            vm.page = 1;
        }
        vm.data = entity.data || {
            name:'',
            cover:'',
            modelData:null,
            modelPath:null,
            background:null,
            targetPosition:null,
            cameraPosition:null,
        };
        vm.cancel = cancel;
        vm.save = save;
        vm.domains = Qinius.getDomain();
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        $q.all([vm.domains.$promise, vm.data.$promise]).then(function(){
             $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            if(vm.data.id&&vm.data.modelData){
                vm.modelData=[];
                var _data = angular.copy(vm.data.modelData);
                var _modelData = [];
                _data.forEach(function (item) {
                    if(item.page === '' || item.page === null){
                        _modelData.push(item.materialName)
                    }
                })
                _data.forEach(function (item) {
                    if(item.page>=0 && item.page!='' &&item.page != null){
                        var _modelData1 = angular.copy(_modelData);
                        _modelData1.push(item.materialName)
                        vm.modelData.push({materialName:item.materialName,modelData:_modelData1})
                    }
                })
            }
        })


        window.updateImgFile = function ($event) {
            $timeout(function () {
                for(var i=0;i<$event.target.files.length;i++){
                    var file = $event.target.files[i];
                    fileGet(file,function (item) {
                        vm.data.cover = item;
                    });
                }
            });
        }
        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                for(var i=0;i<$event.target.files.length;i++){
                    var file = $event.target.files[i];
                    fileGet(file,function (item) {
                        vm.data.background = item;
                    });
                }
            });
        }
        vm.demoPic = "";
        window.updateImgFile2 = function ($event) {
            $timeout(function () {
                for(var i=0;i<$event.target.files.length;i++){
                    var file = $event.target.files[i];
                    fileGet(file,function (item) {
                        vm.demoPic = item;
                        console.log(vm.demoPic)
                    });
                }
            });
        }
        function fileGet(file,callback) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);

            formData.append('key', "clipArt-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    callback($scope.domain + data.key);
                },
                error: function (request) {
                    MessageService.error("文件上传失败");
                }
            });

        }

        //上传3D模型
        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'model-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.data.modelPath = $scope.domain + data.key;
                    gltfInfo(vm.data.modelPath)
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("上传失败");
                }
            });
        };
        function gltfInfo(path) {
            // 加载glTF格式的模型
            vm.data.modelData = [];
            vm.materialMap = [];
            var loader = new THREE.GLTFLoader();
            if (THREE.DRACOLoader) {
                var dracoLoader = new THREE.DRACOLoader();
                if (dracoLoader) {
                    dracoLoader.setDecoderPath("lib/three/draco/");
                    loader.setDRACOLoader( dracoLoader );
                }
            }
            vm.modelLoad = true;
            loader.load(path, function (gltf) {
                gltf.scene.traverse(function(child){
                    if (child.isMesh) {
                        traverseMaterials(child, function(node,i){
                            if(vm.materialMap.indexOf(node.name) == -1){
                                var _modelData = {
                                    materialName:node.name,
                                    page:''
                                };
                                vm.data.modelData.push(_modelData);
                                vm.materialMap.push(node.name);
                            }
                        });
                    }
                });
                modelGltfInit();
                vm.modelLoad = false;
                $scope.$apply();
            }, function (xhr) {
                vm.schedule = (xhr.loaded / xhr.total * 100);
            }, function (error) {
                MessageService.error("3D模型上传失败，请稍后重试！")
                $timeout(function () {
                    vm.modelLoad = false;
                })
            })
        }
        function modelGltfInit() {
            vm.modelData=[{materialName:'',modelData:[]}];
            vm.changeModelData();
        }
        vm.changeModelData = function () {
            var _data = angular.copy(vm.data.modelData);
            vm.modelData.forEach(function (item1, index1) {
                item1.modelData = [];
            })
            _data.forEach(function (item) {
                item.page="";
                item.img="";
                vm.modelData.forEach(function (item1, index1) {
                    var _modelData = [];
                    vm.modelData.forEach(function (item2, index2) {
                        if(index1 != index2 && item2.materialName){
                            _modelData.push(item2.materialName)
                        }
                    })
                    var _exist = false;
                    _modelData.forEach(function (item3) {
                        if(item3 == item.materialName){
                            _exist = true;
                        }
                    })
                    if(!_exist){
                        item1.modelData.push(item.materialName);
                    }
                })
            })
            _data.forEach(function (item) {
                vm.modelData.forEach(function (item1, index) {
                    if(item.materialName == item1.materialName){
                        item.page = index;
                    }
                })
            })
            vm.data.modelData = "";
            $timeout(function () {
                vm.data.modelData = _data;
            })
        }
        $scope.addModelData = function () {
            vm.modelData.push({materialName:'',modelData:[]});
            vm.changeModelData();
        }
        $scope.deleteModelData = function (index) {
            vm.modelData.splice(index,1);
            vm.changeModelData();
        }

        function traverseMaterials(object, callback) {
            object.traverse(function(node){
                if (!node.isMesh) return;
                var materials = Array.isArray(node.material)
                    ? node.material
                    : [node.material];
                materials.forEach(callback);
            });
        }
        $scope.updateData = function(){
            var _data = {
                cameraPosition: vm.data.cameraPosition,
                targetPosition: vm.data.targetPosition,
            }
            vm.gltfData = angular.copy(_data);
        }
        $scope.updateData();
        vm.gltfChange = function(text){
            if(text){
                $timeout(function () {
                    var _text = JSON.parse(text);
                    vm.data.cameraPosition = _text.cameraPosition;
                    vm.data.targetPosition = _text.targetPosition;
                })
            }
        }
        $scope.clearGltfInfo = function(){
            vm.data.modelPath = null;
            vm.data.modelData = null;
            vm.data.modelBlendent = null;
            vm.data.modelData = [];
        }
        $scope.removeFile = function() {
            vm.data.cover = "";
        };

        function cancel() {
            $uibModalInstance.close();
        }
        vm.next = function () {
            if(!vm.data.name){
                MessageService.error("模型名称不能为空");
                return
            }
            if(!vm.data.cover){
                MessageService.error("模型图片不能为空");
                return
            }
            if(!vm.data.modelPath){
                MessageService.error("3D模型不能为空");
                return
            }
            vm.page = 2;
        }
        function save() {
            var _isExist = false;
            for(var i=0; i<vm.data.modelData.length; i++){
                if(vm.data.modelData[i].page != null || vm.data.modelData[i].page !==''){
                    _isExist = true;
                    break
                }
            }
            if(!_isExist){
                MessageService.error("关联材质不能为空");
                return false
            }
            if(!vm.data.background){
                MessageService.error("场景背景不能为空");
                return
            }
            for(var i=0; i<vm.data.modelData.length; i++){
                vm.data.modelData[i].img="";
            }
            // vm.data.modelData = JSON.stringify(vm.data.modelData);
            vm.isSaving = true;
            if (vm.data.id != null) {
                Mould2Ds.update(vm.data, onSaveSuccess, onSaveError);
            } else {
                Mould2Ds.save(vm.data, onSaveSuccess, onSaveError);
            }
            function onSaveSuccess (result) {
                MessageService.success("保存成功");
                $uibModalInstance.close(result);
                vm.isSaving = false;
            };

            function onSaveError () {
                MessageService.error("保存失败");
                vm.isSaving = false;
            };
        }
    }
})();
