(function () {
    'use strict';

    var InputSearchControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/components/form/input-search.html',
        controllerAs: 'vm',
        bindings: {
            model: '=', //model
            items:'<', //接口参数
            placeholder:'<',
            type:'<',
            key:'<',
            idItem:'<',
            idItem1:'<',
            width: '<', //input宽
            height: '<',//input高
            onChange:'&',//选中事件
            options: '<',
            categoryId: '<',
        },

        controller: ['$scope','Product','$timeout','StoreAbutments',
            function ($scope,Product,$timeout,StoreAbutments) {
            var vm = this;
            vm.data=[];
            vm.value="";
            vm.loading = false;
            vm.isFocus = false;
            vm.pageIndex = 0;
            vm.pageSize = 20;
            $scope.$watch('vm.model',function(n,o){
                if(vm.model){
                    vm.value = vm.model[vm.key];
                }else{
                    vm.value = "";
                }
            });
            vm.$onInit = function() {
                if(vm.model){
                    vm.value = vm.model[vm.key];
                }else{
                    vm.value = "";
                }
            }
            vm.$onChanges = function(data) {
                if(data.model && data.model.currentValue){
                    vm.model = data.model.currentValue;
                    vm.value = vm.model[vm.key];
                }
            }
            $scope.focus = function () {
                vm.data=[];
                vm.pageIndex = 0;
                vm.isFocus = true;
                init();
            }
            $scope.blur = function () {
                $timeout(function () {
                    if(vm.model && vm.model[vm.key]){
                        vm.value = vm.model[vm.key];
                    }else{
                        vm.value = "";
                    }
                    vm.isFocus = false;
                },300)
            };
            var timeoutId;
            $scope.change = function () {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(function() {
                    // console.log(vm.value);
                    vm.data = [];
                    vm.pageIndex = 0;
                    init();
                }, 300);
            };
            $scope.rightIconClick = function(){
                vm.model = {};
                vm.value = "";
                $timeout(function () {
                    vm.model.sourceType = vm.type;
                    vm.onChange({text:vm.model});
                    vm.data = [];
                    vm.pageIndex = 0;
                    init();
                },100)
            }
            $scope.selectData = function (item) {
                vm.model = item;
                vm.value = vm.model[vm.key];
                $timeout(function () {
                    vm.model.sourceType = vm.type;
                    vm.onChange({text:vm.model});
                },100)
            };
            function init() {
                if(vm.categoryId){
                    vm.isFocus = true;
                    vm.loading = true;
                    StoreAbutments.getProducts({categoryId: vm.categoryId, keyword: vm.value}, function(res) {
                        if (res.state == '200' || res.status == '200') {
                            vm.data = res.data || [];
                        } else {
                            MessageService.error(res.message || '获取商品列表失败');
                        }
                        vm.loading = false;
                    }, function (res) {
                        MessageService.error(res.data.message || '获取商品列表失败');
                        vm.loading = false;
                    });
                }
            }
        }]
    };
    angular.module('backApp')
        .component('inputSearch', InputSearchControl);

})();
