(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProducerController', ProducerController);

    ProducerController.$inject = ['ProducerManagement', '$state', 'Producer', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','MessageService','$scope', '$uibModal', 'Principal'];

    function ProducerController (ProducerManagement, $state, Producer, ParseLinks, AlertService, paginationConstants, pagingParams,MessageService,$scope, $uibModal,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_PRODUCERMANAGEMEN').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        $scope.delete = function(producer){
            if (producer.products!= null && producer.products.length>0) {
                MessageService.error("请将该生产商关联的产品取消后再删除");
                return;
            }
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Producer.delete({id: producer.id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        function loadAll () {

            var items = [];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
            }
            Producer.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.producers = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.producers, function (pro) {
                    ProducerManagement.findByProducerId({producerId: pro.id}, function (res) {
                        pro.products = res.products;
                    });
                })

                console.log(vm.producers)
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $scope.resetPassword = function(producer){
            $uibModal.open({
                templateUrl: 'app/entities/producer/password-set.html',
                controller: 'ProducerPasswordSetController',
                controllerAs: 'vm',
                backdrop: 'static',
                // size: 'sm',
                resolve: {
                    entity: [function() {
                        return producer;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    loadAll()
                }
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
