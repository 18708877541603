(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('PayOrder', PayOrder)
        .factory('RechargeSettingCopy', RechargeSettingCopy);

    PayOrder.$inject = ['$resource'];
    RechargeSettingCopy.$inject = ['$resource'];

    function PayOrder ($resource) {
        var resourceUrl =  'manager/' + 'api/pay-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            
            'getAllByCondition': {
                params:{
                    path:"getAllByCondition"
                },
                method: 'POST', 
                isArray: true
            },
            'byCondition': {
                params:{
                    path:"byCondition"
                },
                method: 'POST', 
                isArray: true
            },
            "createPayOrder": {
                params:{
                    path:"createPayOrder"
                },
                method: 'GET'
            },
            "aliPay": {
                params:{
                    path:"aliPay"
                },
                method: 'GET'
            },
            "createPayOrder2": {
                params:{
                    path:"createPayOrder2"
                },
                method: 'POST'
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function RechargeSettingCopy ($resource) {
        var resourceUrl =  'manager/' + 'api/recharge-setting-copy/:path/:id';

        return $resource(resourceUrl, {}, {

            'getAllByCondition': {
                params:{
                    path:"getAllByCondition"
                },
                method: 'POST',
                isArray: true
            },
            'byCondition': {
                params:{
                    path:"byCondition"
                },
                method: 'POST',
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'delete': { method: 'DELETE'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
