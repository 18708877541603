(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RedeemCodeDetailController', RedeemCodeDetailController);

    RedeemCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ExchangeCode', '$q', 'Customer', '$uibModalInstance', 'MessageService'];

    function RedeemCodeDetailController($scope, $rootScope, $stateParams, entity, ExchangeCode, $q, Customer, $uibModalInstance, MessageService) {
        var vm = this;

        vm.clear = clear;
        vm.unbinding = unbinding;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function unbinding(){
            MessageService.confirm("确认要解绑该兑换码？",function(){
                ExchangeCode.unbind({code:vm.redeemCode.code},function(data){
                    if (data.status == 200) {
                        MessageService.success("解绑成功");
                        $uibModalInstance.dismiss('cancel');
                    }else{
                       MessageService.error(data.message);
                    }
                },function(){
                    MessageService.error("解绑失败");
                })
            })
        }

        vm.redeemCode = entity;
        vm.customer = {};
        $q.all([vm.redeemCode.$promise]).then(function(){
            if (vm.redeemCode.userId) {
                Customer.get({id:vm.redeemCode.userId},function(data){
                    vm.customer = data;
                },function(){})
            }
        })


        var unsubscribe = $rootScope.$on('backApp:payCodeUpdate', function(event, result) {
            vm.redeemCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
