(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreThirdTiktokDialogController', StoreThirdTiktokDialogController);

    StoreThirdTiktokDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'Store', '$stateParams', 'PlatformAbutment'];

    function StoreThirdTiktokDialogController ($timeout, $scope, $q, $state, MessageService, Store, $stateParams, PlatformAbutment) {
        var vm = this;
        console.log($stateParams)
        PlatformAbutment.douYinAuth({code:$stateParams.code}, function (res) {
            if(res.status == 400){
                MessageService.error(res.message || "授权失败！");
            }else{
                MessageService.success("授权成功！");
                $state.go("store-third-tiktok-list", null, { reload: true});
            }
        }, function (error) {
            MessageService.error(error.data.message || "授权失败！");
        })
    }
})();
