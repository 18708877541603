(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('BranchStoreUrlController', BranchStoreUrlController);

    BranchStoreUrlController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'Store', '$state', 
    'MessageService', '$uibModalInstance', "PlatformWechatSetting"];

    function BranchStoreUrlController ($timeout, $scope, $stateParams, $q, Store, $state, 
        MessageService, $uibModalInstance, PlatformWechatSetting) {
        var vm = this;
        vm.data = null;
        vm.store = Store.getCurrent();
        vm.platformWechatSetting = PlatformWechatSetting.get();
        vm.cancel = cancel;
        vm.save = save;
        vm.amount = "";
        vm.shopping = null;
        vm.shoppings = [];
        vm.isDimission = false;
        vm.isShop = false;
        vm.shops= [];
        $q.all([vm.store.$promise, vm.platformWechatSetting.$promise ]).then(function(){
            $scope.storeId = vm.store.id;
            $scope.url = vm.platformWechatSetting.storeNotifyUrl+"/#/"+ vm.store.id+"/branchAdd";
        })

        $scope.copy = function(){
            document.getElementById("url").focus();
            document.getElementById("url").select();
            var tag = document.execCommand("Copy"); //执行浏览器复制命令
            if (tag) {
                MessageService.success("复制成功");
            } else {
                MessageService.error("复制失败，请Ctrl+C复制。");
            };
        }

        // $scope.copy = function(){
        //     if (!clipboard.supported) {
        //         MessageService.error("哇哦,您的浏览器不支持复制!");
        //         return;
        //     }
        //     clipboard.copyText(vm.salesOrder.deliveryNumber); 
        //     MessageService.success("复制成功!");
        // }

        //确定
        function save() {
            var _data = {
                id: vm.data.id,
                targetId:vm.shopping
            };
            if(!vm.shopping){
                MessageService.error("请选择导购");
                return
            }
            if(vm.isDimission){
                _data.status = 'Cancelled';
            }
            if(vm.isShop){
                if(!vm.branchStoreId){
                    MessageService.error("请选择门店");
                    return
                }
                _data.branchStoreId = vm.branchStoreId;
            }
            Guide.exchangeCustomer(_data, function (res) {
                MessageService.success("客户交接成功");
                $uibModalInstance.close(true);
            }, function (error) {
                MessageService.error("客户交接失败");
            })
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();
