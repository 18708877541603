(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('AfterSale', AfterSale);

    AfterSale.$inject = ['$resource', 'DateUtils'];

    function AfterSale($resource, DateUtils) {
        var resourceUrl = 'service/' + 'api/afterSale/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "changeStatus": {
                method: "GET",
                params: {
                    path: "changeStatus"
                }
            },
            "setRule": {
                method: "GET",
                params: {
                    path: "setRule"
                }
            },
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertLocalDateFromServer(data.startTime);
                        data.endTime = DateUtils.convertLocalDateFromServer(data.endTime);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'getAllNumByToday': {
                method: 'GET',
                params: {
                    path: 'getAllNumByToday'
                }
            }
        });
    }
})();
