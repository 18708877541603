(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreStyleSettingController', StoreStyleSettingController);

    StoreStyleSettingController.$inject = ['$scope', '$q', 'StoreStyleSetting', '$stateParams', 'MessageService', 'entity', 'Qinius', 'StoreConfig', 'PasswordMarketing',
        'ListService', 'Store', 'Principal', '$state'];

    function StoreStyleSettingController($scope, $q, StoreStyleSetting, $stateParams, MessageService, entity, Qinius, StoreConfig, PasswordMarketing,
        ListService, Store, Principal, $state) {
        var vm = this;

        Principal.hasAuthority('ROLE_STORE_DIANPU').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.storeStyleSetting = entity;
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.store = Store.getCurrent();
        vm.marketing = PasswordMarketing.getOne({ type: "GroupBuy" });
        vm.storeConfigs = StoreConfig.getAllByCondition({});
        //热门分类开关
        vm.storeHot = {
            type: 'HOTSHOW',
            open: false
        };
        //分类布局
        vm.displayway = {
            type: 'DISPLAYWAY',
            open: false,
            value: '0'
        };
        vm.navigateWay = {
            type: 'NAVIGATEWAY',
            open: false,
            value: '0'
        }
        $q.all([vm.domains.$promise, vm.marketing.$promise, vm.store.$promise, vm.storeConfigs.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            if (vm.marketing.id) {
                vm.show = true;
            }
            if (vm.storeConfigs && vm.storeConfigs.length > 0) {
                for (var i = vm.storeConfigs.length - 1; i >= 0; i--) {
                    var config = vm.storeConfigs[i];
                    if (config.type == 'HOTSHOW') {
                        vm.storeHot = config;
                    }
                    if (config.type == 'NAVIGATEWAY') {
                        vm.navigateWay = config;
                    }
                    if (config.type == 'DISPLAYWAY') {
                        vm.displayway = config;
                    }
                }
            }
        })
        $scope.displaywayClick = function(item){
            vm.displayway.value = item;
            $scope.storeConfigChange(vm.displayway);
        };
        $scope.tabChange = function () {
            StoreStyleSetting.update(vm.storeStyleSetting, onSaveSuccess2, onSaveError);
        }
        $scope.navigateWayClick = function (item) {
            vm.navigateWay.value = item;
            $scope.storeConfigChange(vm.navigateWay);
        };
        $scope.ferightDeduction = function (flag) {
            vm.storeFreight.open = flag;
            $scope.storeConfigChange(vm.storeFreight);
        }
        $scope.storeConfigChange = function (config) {
            if (!config.id) {
                StoreConfig.save(config, function (data) {
                    config = data;
                    MessageService.success("保存成功！");
                }, function () {
                    MessageService.error("保存失败！");
                })
            } else {
                StoreConfig.update(config, function (data) {
                    config = data;
                    MessageService.success("保存成功！");
                }, function () {
                    MessageService.error("保存失败！");
                })
            }
            if (config.type == 'SHOPPINGCART' && !config.open) {
                $scope.ferightDeduction(false)
            }
        }

        function onSaveSuccess2(result) {
            $scope.$emit('backApp:storeStyleSettingUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }
    }
})();
