(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MessageNotifyController', MessageNotifyController);

    MessageNotifyController.$inject = ['$scope', '$state', 'MessageNotify', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams','Principal'];

    function MessageNotifyController ($scope, $state, MessageNotify, ParseLinks, MessageService, paginationConstants, pagingParams,Principal) {
                var vm = this;
        Principal.hasAuthority('ROLE_STORE_DIANPU').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.pageType = pagingParams.pageType;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.statusList = [
            {name:'已发送', id:'0'},
            {name:'未发送', id:'1'},
            {name:'已撤回', id:'2'},
        ];
        vm.status = null;


        loadAll();

        $scope.revert = function(item){
            MessageService.confirm("确认要撤回该通知",function(){
                MessageNotify.revert({id: item.id},function () {
                    loadAll();
                    MessageService.success('撤回成功');
                });
            },function(){})
        }

        $scope.send = function(item){
            MessageService.confirm("确认要发送该通知",function(){
                MessageNotify.send({id: item.id},function () {
                    loadAll();
                    MessageService.success('发送成功');
                });
            },function(){})
        }

        $scope.delete = function(item){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                MessageNotify.delete({id: item.id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };

        $scope.status = function (){
            loadAll();
        }

        function getItems(){
            var items=[];
            if(vm.status !=null){
                if(vm.status == 0){
                    items.push({key:"sent",op:"=",value:true},
                        {key:"reverted",op:"=",value:false});
                }else if(vm.status == 1){
                    items.push({key:"sent",op:"=",value:false});
                }else if(vm.status == 2){
                    items.push({key:"reverted",op:"=",value:true});
                }
            }
            return items;
        }

        function loadAll () {
            var items = getItems();
            //var keyword = null;
            if (pagingParams.search) {
                items.push({key:"title", op:"like", value:pagingParams.search});
            }
            MessageNotify.query({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.messageNotifies = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                pageType: vm.pageType,
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.searchPageType = function () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }
    }
})();
