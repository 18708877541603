(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CustomerController', CustomerController);

    CustomerController.$inject = ['$scope', '$stateParams', '$state', 'Customer', 'ParseLinks', 'MessageService',
     'paginationConstants', 'pagingParams', 'BranchStore','Principal' , 'Coupons', "DateUtils" ,'$uibModal'];

    function CustomerController ($scope, $stateParams, $state, Customer, ParseLinks, MessageService,
        paginationConstants, pagingParams, BranchStore, Principal , Coupons, DateUtils ,$uibModal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.vip = pagingParams.vip;
        vm.branchStoreId = $stateParams.branchStoreId?Number($stateParams.branchStoreId):null;
        vm.type = $stateParams.type;

        console.log(vm.vip);

        loadAll();

        $scope.getCustomerSource = function(customerSource){
            //  wechat,mini,web, aliMini,byteDance
            switch(customerSource) {
                case "wechat":
                    return "H5";
                case "mini":
                    return "微信小程序";
                case "web":
                    return "web端";
                case "aliMini":
                    return "支付宝小程序";
                case "byteDance":
                    return "字节小程序";
                default:
                    return "";
            }
        }

        function loadAll () {
            var items=[];
            if(!vm.type){
                items.push({key:"isVip",op:"=",value:pagingParams.vip});
            }
            if(vm.type=="all"){
                vm.branchStores = BranchStore.getAllByCondition({});
            }
            if(vm.branchStoreId){
                vm.branchStore = BranchStore.get({id : vm.branchStoreId})
                items.push({key:"branchStoreId",op:"=",value:vm.branchStoreId});
            }
            if(vm.type){
                if (pagingParams.search) {
                    items.push({key:"name,phone",op:"like",value:pagingParams.search});
                    Customer.byCondition3({
                        items:items,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                } else {
                    Customer.byCondition3({
                        items:items,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            }else{
                if (pagingParams.search) {
                    items.push({key:"name,phone",op:"like",value:pagingParams.search});
                    Customer.byCondition({
                        items:items,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                } else {
                    Customer.byCondition({
                        items:items,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customers = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("客户获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                type: vm.type,
                branchStoreId: vm.branchStoreId
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            loadPage(vm.jumpText<=0?1:vm.jumpText);
        }

        $scope.searchBranchStore = function () {
            console.log(vm.branchStoreId)
            search()
        }

        // 点击发放优惠券，展示弹窗
        vm.userId=null
        vm.coupon=[]
        $scope.issueCoupons=function(item){
            document.body.style.overflow='hidden';
            var currentTime = DateUtils.convertLocalDateToServer(new Date());
            vm.couponDialog=true
            vm.userId=item.id
            Coupons.getAllByCondition({
                items: [
                    {key:"sendRule",op:"=",value:'AssignSend'},
                    {"key":"enabled", "op":"=", "value":true},
                    {key:"endTime",op:">=",value: currentTime},
            ]},function(res){
                vm.coupon=res
            });
        }
        // 发放优惠券取消按钮
        $scope.cancel=function(){
            vm.couponType=null
            vm.couponNumber=null
            vm.couponDialog=false
            document.body.style.overflow='auto';
        }
        // 发放优惠券确定时间
        $scope.confirm=function(){
            if (vm.couponType == null || vm.couponType =='') {
                MessageService.error("请选择优惠券");
                return
            }
            if (vm.couponNumber== null || vm.couponNumber == '') {
                MessageService.error("请输入数量");
                return
            }
            vm.QuantityDeficiency=false
            // 判断选择的当前优惠券数量是否大于发放数量
            vm.coupon.some(function(coupon){
                if (coupon.id==vm.couponType) {
                    if (coupon.totalQuantity < vm.couponNumber) {
                        vm.QuantityDeficiency=true
                    }
                }
            })
            if (vm.QuantityDeficiency) {
                MessageService.error("优惠券数量不足");
                return
            }
            Coupons.assignSendCouponToCustomer({id:vm.couponType,customerId:vm.userId,quantity:vm.couponNumber},function(res){
                MessageService.success("发放优惠券成功");
                $scope.cancel()
            },function(err){
                MessageService.error("发放优惠券失败！");
            })
        }

        $scope.checkBound = function(customer){
            var storeUserId = customer.id
            $uibModal.open({
                templateUrl: 'app/entities/customer/customers-cheak-bound.html',
                controller: 'CustomersCheakBound',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return storeUserId;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (backData) {

            });
        }

        $scope.detail = function(customer){
            $uibModal.open({
                templateUrl: 'app/entities/customer/customers-detail.html',
                controller: 'CustomersDetail',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return customer;
                    }]
                }
            }).result.then(function () {

            });
        }

        $scope.inactive = function(customer){
            MessageService.confirm("确定拉黑当前用户？",function(){
                Customer.setInactive({id: customer.id,inactive:true}, function (res) {
                    loadAll();
                    MessageService.success("拉黑成功");
                }, function () {
                    MessageService.error("拉黑失败");
                })
            },function(){},true);
        }
        $scope.recover = function(customer){
            MessageService.confirm("确定恢复当前用户？",function(){
                Customer.setInactive({id: customer.id,inactive:false}, function (res) {
                    loadAll();
                    MessageService.success("恢复成功");
                }, function () {
                    MessageService.error("恢复失败");
                })
            },function(){},true);
        }
    }
})();
