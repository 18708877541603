(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreDetailController', StoreDetailController);

    StoreDetailController.$inject = ['$scope', '$q', 'UidService', 'OrderSetting', 'entity', 'Store', 'MessageService', 'Qinius', '$state', '$http','Principal', 'StoreConfig'];

    function StoreDetailController($scope, $q, UidService, OrderSetting, entity, Store, MessageService, Qinius, $state, $http,Principal, StoreConfig) {
        var vm = this;

        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.store = entity;
        //保存
        vm.save2 = save2;
        vm.storeOrderSetting = OrderSetting.getStoreOrderSetting2();
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.types = [{key:"公众号",value:"WECHAT",selected:false}, {key:"微信小程序",value:"MINI",selected:false}, {key:"网页端",value:"WEB",selected:false},
         {key: "支付宝", value: "ALI", selected: false}, {key: "字节跳动", value: "BYTEDANCE", selected: false}, {key: "京东小程序", value: "JD", selected: false}]
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';

            vm.token = vm.tokens.token;
            if (vm.store.storeType2) {
                for (var i = vm.types.length - 1; i >= 0; i--) {
                    var t = vm.types[i];
                    if(vm.store.storeType2.indexOf(t.value)>=0){
                        t.selected = true;
                    }
                }
            }
        });
        vm.storeConfigs = [];
        vm.imgSize = {
            type:'IMGSIZE',
            open: false,
            value:null,
        };
        vm.imgDpi = {
            type:'IMGDPI',
            open: false,
            value:null,
        };
        StoreConfig.getAllByTypes({
            "storeId":vm.store.id,
            "type":["IMGSIZE", "IMGDPI","PLACEOFDELIVERY"]
        }, function(data){
            if(data.status == 200){
                vm.storeConfigs = data.message;
                $q.all([ vm.store.$promise, vm.storeConfigs.$promise]).then(function () {
                    if (vm.storeConfigs && vm.storeConfigs.length>0) {
                        for (var i = vm.storeConfigs.length - 1; i >= 0; i--) {
                            var config = vm.storeConfigs[i];
                            if (config == null) {
                                continue;
                            }
                            if (config.type == 'IMGSIZE') {
                                vm.imgSize = config;
                                continue;
                            }
                            if (config.type == 'IMGDPI') {
                                vm.imgDpi = config;
                                continue;
                            }
                            if (config.type == 'PLACEOFDELIVERY') {
                                vm.delivery = config;
                                continue;
                            }
                        }
                    }
                });
            }
        });
        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        window.showSkuList2 = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".substitutione").addClass('text-decoration-underline');
                $(".substitutione-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList2 = function (e) {
            fn(e);
            $(".substitutione-tip").hide();
            $(".substitutione").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }



        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'logo-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store.logo = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        $scope.removeFile = function (file) {
            vm.store.logo = null;
        }

        function save2() {
            vm.store.storeType2 = "";
            for (var i = vm.types.length - 1; i >= 0; i--) {
                var t = vm.types[i];
                if (t.selected) {
                    vm.store.storeType2 += t.value + ";"
                }
            }
            vm.isSaving = true;
            if (vm.store.id !== null) {
                Store.update(vm.store, onSaveSuccess2, onSaveError);
            } else {
                Store.save(vm.store, onSaveSuccess2, onSaveError);
            }
        }

        function onSaveSuccess2(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            // vm.storeOrderSetting.storeId = $stateParams.storeId;
            vm.storeOrderSetting.platformFlag = false;
            OrderSetting.updateStoreTimerSetting(vm.storeOrderSetting, function (data) {
                MessageService.success("保存成功！");
            }, function (data) {
                MessageService.error("延时信息保存失败！");
            })
            // $state.go("store-detail2", {storeId: vm.store.id});
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }

    }
})();
