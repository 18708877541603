(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('BranchStoreDialogController', BranchStoreDialogController);

    BranchStoreDialogController.$inject = ['$state', '$timeout','UidService', '$scope', 'Qinius', '$q', 'entity', 'MessageService', '$localStorage', 'Product',
    'LocationCity', 'LocationDistrict', 'LocationProvice', 'BranchStore', 'Store', 'ListService', '$http','Principal'];

    function BranchStoreDialogController ($state, $timeout,UidService, $scope, Qinius, $q, entity, MessageService, $localStorage, Product,
        LocationCity, LocationDistrict, LocationProvice, BranchStore, Store, ListService, $http,Principal) {
        var vm = this;
        // Principal.hasAuthority('ROLE_STORE_MY_BRANCH_SHOP').then(function (data) {
        //     if (!data) {
        //         MessageService.error("不好意思，你不具有访问权限！");
        //         $state.go("home");
        //     }
        // });
        vm.account = $localStorage.user;
        vm.branchStore = entity;
        vm.clear = clear;
        vm.save = save;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();

        vm.products = Product.getAllByCondition({});

        $scope.productSelected = function (p) {
            p.selected = !p.selected;
        };

        // 分店管理
        if(vm.account.authorities.indexOf("ROLE_STORE_BRANCH_SHOP")!=-1){

        }else if(vm.account.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP")!=-1){
            // // 我的分店权限
            vm.storeDivShow = true;
        }

        $q.all([vm.branchStore.$promise,vm.tokens.$promise,vm.domains.$promise,vm.store.$promise, vm.products.$promise]).then(function() {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            for (var i = vm.products.length - 1; i >= 0; i--) {
                var p = vm.products[i];
                var result = ListService.inList(p,vm.branchStore.products, ["id"] );
                if (result != null) {
                    p.selected = true;
                }
            }
            //新建
            if (vm.branchStore.id == null) {
                vm.branchStore.extensionSetting = vm.store.extensionSetting||{};
                vm.branchStore.extensionSetting.id=null;
            }

            //编辑
            if(!vm.branchStore.extensionSetting){
                vm.branchStore.extensionSetting = {};
            }

            if(vm.branchStore.extensionSetting.pickMethod==undefined||vm.branchStore.extensionSetting.pickMethod==null){
                vm.pickMethod1 = false;
                vm.pickMethod2 = false;
            }else if(vm.branchStore.extensionSetting.pickMethod=='PERCENT'){
                vm.pickMethod1 = true;
                vm.pickMethod2 = false;
            }else if(vm.branchStore.extensionSetting.pickMethod=='MONEY'){
                vm.pickMethod1 = false;
                vm.pickMethod2 = true;
            }
            if(vm.branchStore.extensionSetting.pickType=='Product'){
                vm.pickType1 = true;
                vm.pickType2 = false;
            }else if(vm.branchStore.extensionSetting.pickType=='SalesOrder'){
                vm.pickType1 = false;
                vm.pickType2 = true;
            }
            LocationProvice.query({}, function(data) {
                vm.provinces = data;
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.branchStore.province) {
                        vm.province = p;
                        break;
                    }
                }
                if (!!vm.branchStore.id) {
                    vm.districts = [];
                    LocationCity.findByProvince({
                        provinceId: vm.province.id
                    }, function(data) {
                        vm.citys = data;
                        for (var i = vm.citys.length - 1; i >= 0; i--) {
                            var c = vm.citys[i];
                            if (Trim(c.name, "g") == Trim(vm.branchStore.city, "g")) {
                                vm.city = c;
                                break;
                            }
                        }
                        if (vm.city != null) {
                            LocationDistrict.findByCities({
                                cityId: vm.city.id
                            }, function(data) {
                                vm.districts = data;
                                for (var i = vm.districts.length - 1; i >= 0; i--) {
                                    var di = vm.districts[i];
                                    if (Trim(di.name, "g") == Trim(vm.branchStore.area, "g")) {
                                        vm.area = di;
                                    }
                                }
                            });
                        }
                    });
                }
            });

            getDirection()
        });

        $scope.clickPickType = function (attr) {
            if (attr == 'Product') {
                vm.pickType2 = !vm.pickType1;
            } else {
                vm.pickType1 = !vm.pickType2;
            }
        }

         // 下载二维码
        $scope.download2code = function(){
            $http({
                url: "/fileserves/api/download/downloadBranchStoreCode?storeId=" + vm.branchStore.storeId +"&branchStoreId=" + vm.branchStore.id,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/octet-stream;charset=ISO8859-1"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = vm.branchStore.name + "公众号二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
        }


        //下载小程序二维码
        $scope.downloadQrCode = function () {
            $http({
                url: "/fileserves/api/download/downloadMiniQrCode?storeId=" + vm.branchStore.storeId +"&branchStoreId=" + vm.branchStore.id,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/octet-stream;charset=ISO8859-1"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = vm.branchStore.name + "小程序二维码.png";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
        }


        function getDirection() {
            // vm.ratio = 1;
            vm.icon = {x: 390, y: 170, width: 177, height: 184};
            vm.code = {x: 320, y: 680, width: 200, height: 200};
            vm.text = {x: 436, y: 400, width: 92, height: 22};
            var setting = vm.branchStore.extensionSetting;
            if (setting.backImg) {
                setTimeout(function () {
                    vm.ratio = $("#back-img").width() / 711;
                    if(setting.headX!=null){
                        vm.icon.x = setting.headX;
                        vm.icon.y = setting.headY;
                        vm.text.x = setting.nameX;
                        vm.text.y = setting.nameY-22;
                        vm.code.x = setting.qrCodeX;
                        vm.code.y = setting.qrCodeY;
                    }

                    console.log(vm.ratio)
                })

            }
        }

        // 素材鼠标事件
        var dragBox = {};
        var drag = false;
        $scope.moveStart = function (e, item) {
            fn(e);

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;
            dragBox.coordX = item.x * vm.ratio;
            dragBox.coordY = item.y * vm.ratio;
            dragBox.slideW = $("#back-img").width();
            dragBox.slideH = $("#back-img").height();
            drag = true;
            //鼠标移动
            window.onmousemove = function (e) {
                fn(e);
                $scope.$digest();
                if (drag) {
                    var clientX = e.clientX - dragBox.clientX;
                    var clientY = e.clientY - dragBox.clientY;

                    var offsetX = (dragBox.coordX + clientX);
                    var offsetY = (dragBox.coordY + clientY);
                    var imgWidth = item.width * vm.ratio;
                    var imgHeight = item.height * vm.ratio;

                    if (offsetX <= 0) {
                        offsetX = 0;
                    }

                    if (offsetX >= dragBox.slideW - imgWidth) {
                        offsetX = dragBox.slideW - imgWidth;
                    }

                    if (offsetY <= 0) {
                        offsetY = 0;
                    }

                    if (offsetY >= (dragBox.slideH - imgHeight)) {
                        offsetY = dragBox.slideH - imgHeight;
                    }
                    item.x = parseInt(offsetX / vm.ratio);
                    item.y = parseInt(offsetY / vm.ratio);

                }
                return false;
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                drag = false;
                dragBox = {};
                $event.stopPropagation();
                $scope.$digest();
            }
        };

        //防止事件冒泡，默认事件
        function fn(e) {
            // e = e || window.event;
            // e.cancelBubble = true;
            // e.stopPropagation();
            // e.returnValue = false;
            // e.preventDefault();
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        // 窗口宽高变化
        window.onresize = function () {
            vm.ratio = $("#back-img").width() / 711;
            $scope.$digest();
        };

        // 除去字符串中的空格
        function Trim (str, is_global) {
            var result;
            result = str.replace(/(^\s+)|(\s+$)/g,"");
            if (is_global.toLowerCase()=="g") {
                result = result.replace(/\s/g,"");
            }
            return result;
        }

        function changeProvince() {
            LocationCity.findByProvince({
                provinceId: vm.province.id
            }, function(data) {
                vm.citys = data;
                vm.districts = [];
            });
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({
                cityId: vm.city.id
            }, function(data) {
                vm.districts = data;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            if(vm.account.authorities.indexOf("ROLE_STORE_BRANCH_SHOP")!=-1){
                $state.go("branch-store",{reload:true});
            }else if(vm.account.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP")!=-1){
                if (vm.branchStore.id) {
                    $state.go("branch-store-detail",{id:vm.branchStore.id},{reload:true});
                }

            }
        }

        $scope.validate = function (){
            if (vm.branchStore.name == null) {
                MessageService.error("请输入店铺名称");
                return false;
            }
            if (vm.branchStore.synopsis == null) {
                MessageService.error("请输入店铺简介");
                return false;
            }
            if (vm.branchStore.shopowner == null) {
                MessageService.error("请输入店铺联系人");
                return false;
            }

            if (vm.area == null) {
                MessageService.error("请输入地址");
                return false;
            }

            if(vm.branchStore.extensionSetting.branchStorePercent+vm.branchStore.extensionSetting.guidePercent>100){
                MessageService.error("分店提成+导购提成不能超过100%！")
                return
            }
            return true;
        }

        function save () {
            if (!$scope.validate()) {
                return ;
            }
            vm.branchStore.products = [];
            if (vm.products && vm.products.length>0) {
                for (var i = vm.products.length - 1; i >= 0; i--) {
                    var p = vm.products[i];
                    if (p.selected) {
                        vm.branchStore.products.push(p);
                    }
                }
            }
            vm.isSaving = true;
            vm.branchStore.province = vm.province.name;
            vm.branchStore.city = vm.city.name;
            vm.branchStore.area = vm.area.name;
            if(vm.pickMethod1){
                vm.branchStore.extensionSetting.pickMethod = "PERCENT";
            }else if(vm.pickMethod2){
                vm.branchStore.extensionSetting.pickMethod = "MONEY";
            }
            if (vm.pickType1) {
                vm.branchStore.extensionSetting.pickType = "Product";
            } else if (vm.pickType2) {
                vm.branchStore.extensionSetting.pickType = "SalesOrder";
            }

            console.log(vm.branchStore);
            if(vm.branchStore.extensionSetting.backImg){
                vm.branchStore.extensionSetting.headX = vm.icon.x;
                vm.branchStore.extensionSetting.headY = vm.icon.y;
                vm.branchStore.extensionSetting.nameX = vm.text.x;
                vm.branchStore.extensionSetting.nameY = vm.text.y+22;
                vm.branchStore.extensionSetting.qrCodeX = vm.code.x;
                vm.branchStore.extensionSetting.qrCodeY = vm.code.y;
            }
            // return
            if (vm.branchStore.id !== null) {
                BranchStore.update(vm.branchStore, onSaveSuccess, onSaveError);
            } else {
                BranchStore.create(vm.branchStore, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            MessageService.success("操作完成");
            vm.isSaving = false;
            clear ();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.clickPickMethod = function (attr) {

            if(attr=='PERCENT'){
                vm.pickMethod2 = !vm.pickMethod1;
            }else{
                vm.pickMethod1 = !vm.pickMethod2;
            }
            console.log(attr)
        }

        // itemvalue封面图片修改
        window.uploadBackImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                // if (file.size / 1024 >= 1024) {
                //     MessageService.error("建议图片大小在1024K以内");
                //     return
                // }
                var url = window.URL || window.webkitURL;
                var img = new Image();              //手动创建一个Image对象
                img.src = url.createObjectURL(file);//创建Image的对象的url
                img.onload = function () {
                    // 建议尺寸711px*1130px,1024K以内
                    var w = Math.abs(this.width - 711);
                    var h = Math.abs(this.height - 1130);
                    // if (w > 11 || h > 11) {
                    //     MessageService.error("建议图片尺寸：711px*1130px");
                    //     return
                    // }
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', 'back-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            vm.branchStore.extensionSetting.backImg = data.key;
                            getDirection();
                            $scope.$digest();
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });
                }
            });
        };

        $scope.removeBackImg = function () {
            vm.branchStore.extensionSetting.backImg = null;
            vm.branchStore.extensionSetting.headX = null;
            vm.branchStore.extensionSetting.headY = null;
            vm.branchStore.extensionSetting.nameX = null;
            vm.branchStore.extensionSetting.nameY = null;
            vm.branchStore.extensionSetting.qrCodeX = null;
            vm.branchStore.extensionSetting.qrCodeY = null;
        }

        $scope.goToReadImage = function () {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image").removeClass('hide1');
            $(".modal-content").addClass('opacity-zero');
            // $(".origin-image img").attr('src', pic);
        }

        $scope.backTo = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        };

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image").addClass('hide1');
            $(".modal-content").removeClass('opacity-zero');
            $('body').css("overflow","auto");
        }

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'logo-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.branchStore.logo = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.removeFile = function() {
            vm.branchStore.logo = null;
        }

        $scope.changeShape = function (v) {
            vm.store.extensionSetting.circle = v;
            if(v){
                $(".headIcon").css("border-radius", "50%");
            }else {
                $(".headIcon").css("border-radius", "0%");
            }
        };
    }
})();
