(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CommandBatchController', CommandBatchController);

    CommandBatchController.$inject = ['$scope', '$state', 'CommandBatch', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams'];

    function CommandBatchController ($scope, $state, CommandBatch, ParseLinks, MessageService, paginationConstants, pagingParams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.clear = clear;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        loadAll(false);

        $scope.backTo = function(){
            sessionStorage.setItem("lmenu", 'a_marketing');
            $state.go("marketing");
            vm.lmenu = "a_marketing";
 
        }

        $scope.delete = function (id) {
            MessageService.confirm("确认要作废该信息吗", function () {
                CommandBatch.delete({id: id}, function () {
                    loadAll("false");
                });
            }, function () {
            }, true);
        }


        function loadAll(used) {
            vm.used = used;
            var items = [{key: "used", op: "=", value: used}];
            if (pagingParams.search) {
                items.push({key: "number,product.name", op: "like", value: pagingParams.search})
            }  
            CommandBatch.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.commandBatches = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("数据获取错误");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

    }
})();
 