(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FeedbackDialogController', FeedbackDialogController);

    FeedbackDialogController.$inject = ['$timeout', '$q', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Feedback', 'MessageService', 'Qinius', 'UidService'];

    function FeedbackDialogController ($timeout, $q, $scope, $stateParams, $uibModalInstance, entity, Feedback, MessageService, Qinius, UidService) {
        var vm = this;

        vm.feedback = entity;
        vm.clear = clear;
        vm.save = save;
        vm.attachments =[];
        vm.imgs =[];
        vm.status = vm.feedback.status;

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.tokens.$promise,vm.feedback.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        });

        //上传附件
        window.updateImgFile = function ($event) {
            vm.imgs = [];
            $timeout(function () {
                console.log($event.target.files);
                for(var i=0;i<$event.target.files.length;i++){
                    var file = $event.target.files[i];
                    vm.imgs.push(file.name);
                    fileGet(file);
                }
                function fileGet(file){
                    var getBase64 = function (file, callback) {
                        var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                        reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                        reader.onload = function () {       //  成功读取
                            $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                        };
                        reader.onerror = function (error) {     //  出错
                        };
                    };
                    getBase64(file, function (result) {
                    });
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', "feedback-"+ UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            console.log(data);
                            //vm.imgs.push(data.key);
                            vm.attachments.push({provider:"qiniu", identifier:data.key,fileName:vm.imgs[i]});
                            console.log(vm.attachments);
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });

                }
            });
        };


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function(){
            if(vm.feedback.title == null || vm.feedback.title==""){
                MessageService.error("请输入标题");
                return false;
            }
            if(vm.feedback.description == null || vm.feedback.description==""){
                MessageService.error("请输入描述");
                return false;
            }
            //if(vm.imgs== null || vm.imgs==""){
            //    MessageService.error("请上传附件");
            //    return false;
            //}

            return true;
        };

        function save () {
            vm.feedback.status = vm.status;
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            var objLine={};
            objLine.description = vm.feedback.description;
            objLine.type = "Problem";
            objLine.attachments = vm.attachments;
            console.log(objLine);
            vm.feedback.feedbackLines = [];
            vm.feedback.feedbackLines.push(objLine);
            Feedback.save(vm.feedback, onSaveSuccess, onSaveError);
        }

        $scope.removeFile = function(file,index) {
            vm.attachments.splice(index,1);
            console.log(vm.attachments);
        };

        function onSaveSuccess (result) {
            $scope.$emit('backApp:feedbackUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
