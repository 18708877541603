(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('store-third-list', {
            parent: 'store',
            url: '/store-third-list',
            data: {
                authorities: ['ROLE_STORE_SETTING']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/store-third/store-third-dialog.html',
                    controller: 'StoreThirdDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$localStorage', 'PlatformAbutment', function($localStorage, PlatformAbutment) {
                        return PlatformAbutment.byStoreId({storeId:$localStorage.user.storeId,type:'RRD'}).$promise;
                    }
                ]
            }
        })
        ;
    }

})();
