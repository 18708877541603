(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ConnectController', ConnectController);

    ConnectController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Guide', 'BranchStore', 'Tariffs'];

    function ConnectController ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, MessageService, $uibModalInstance, Guide, BranchStore, Tariffs) {
        var vm = this;
        vm.data = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.amount = "";
        vm.shopping = null;
        vm.shoppings = [];
        vm.isDimission = false;
        vm.isShop = false;
        vm.shops= [];
        init();
        function init() {
            Guide.getAllByCondition({
                items: [{key: "branchStore.id", op: "=", value: vm.data.branchStoreId},{key: "status", op: "=", value: "Approved"}]
            }, function (res) {
                for(var i=0; i<res.length; i++){
                    if(res[i].id != vm.data.id){
                        vm.shoppings.push(res[i])
                    }
                }
            });
            BranchStore.getAllByCondition({
                items: [{key: "cancelled", op: "=", value: "false"},{key: "enabled", op: "=", value: "true"}]
            }, function (res) {
                vm.shops = res;
            });
        }

        $scope.dimissionCb = function () {
            vm.isDimission = !vm.isDimission;
            vm.isShop = false;
            vm.branchStoreId = "";
        }
        $scope.shopSelect = function () {
            vm.isShop = !vm.isShop;
            vm.isDimission = false;
            vm.branchStoreId = "";
        }

        //确定
        function save() {
            var _data = {
                id: vm.data.id,
                targetId:vm.shopping
            };
            if(!vm.shopping){
                MessageService.error("请选择导购");
                return
            }
            if(vm.isDimission){
                _data.status = 'Cancelled';
            }
            if(vm.isShop){
                if(!vm.branchStoreId){
                    MessageService.error("请选择门店");
                    return
                }
                _data.branchStoreId = vm.branchStoreId;
            }
            Guide.exchangeCustomer(_data, function (res) {
                MessageService.success("客户交接成功");
                $uibModalInstance.close(true);
            }, function (error) {
                MessageService.error("客户交接失败");
            })
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();
