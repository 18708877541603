(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('banner', {
            parent: 'theme-style',
            url: '/banner',
            data: {
                authorities: ['ROLE_STORE_DIANPU'],
                pageTitle: 'backApp.banner.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/banner/banners.html',
                    controller: 'BannerController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'number,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('banner');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('banner-detail', {
            parent: 'banner',
            url: '/detail/{id}',
            data: {
                authorities: ['ROLE_STORE_DIANPU']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/banner/banner-detail.html',
                    controller: 'BannerDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Banner', function(Banner) {
                            return Banner.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })

            .state('banner.new', {
                parent: 'banner',
                url: '/new',
                data: {
                    authorities: ['ROLE_STORE_DIANPU']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/banner/banner-dialog.html',
                        controller: 'BannerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    number: null,
                                    img: null,
                                    status: null,
                                    cancelled: null,
                                    id: null
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('banner');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('banner', {}, { reload: true });
                    }, function() {
                        $state.go('banner');
                    });
                }]
            })
            .state('banner.edit', {
                parent: 'banner',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_STORE_DIANPU']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/banner/banner-dialog.html',
                        controller: 'BannerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Banner', function(Banner) {
                                return Banner.get({id : $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('banner');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('banner', {}, { reload: true });
                    }, function() {
                        $state.go('banner');
                    });
                }]
            })
            ;
    }

})();
