(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ItemValueLinePackageDialogController', ItemValueLinePackageDialogController);

    ItemValueLinePackageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'ItemValueLinePackage', 'ProductPurchase', 'ItemValueLine'];

    function ItemValueLinePackageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, ItemValueLinePackage, ProductPurchase, ItemValueLine) {
        var vm = this;

        vm.itemValueLinePackage = entity;
        vm.clear = clear;
        vm.save = save;
        vm.productpurchases = ProductPurchase.query({filter: 'itemvaluelinepackage-is-null'});
        $q.all([vm.itemValueLinePackage.$promise, vm.productpurchases.$promise]).then(function() {
            if (!vm.itemValueLinePackage.productPurchaseId) {
                return $q.reject();
            }
            return ProductPurchase.get({id : vm.itemValueLinePackage.productPurchaseId}).$promise;
        }).then(function(productPurchase) {
            vm.productpurchases.push(productPurchase);
        });
        vm.itemvaluelines = ItemValueLine.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.itemValueLinePackage.id !== null) {
                ItemValueLinePackage.update(vm.itemValueLinePackage, onSaveSuccess, onSaveError);
            } else {
                ItemValueLinePackage.save(vm.itemValueLinePackage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:itemValueLinePackageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
