(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CardConfigController', CardConfigController);

    CardConfigController.$inject = ['$scope', 'Store','MessageService'];

    function CardConfigController($scope, Store, MessageService) {
        var vm = this;
        init();
        function init(){
            vm.store = Store.getCurrent();
        }
        $scope.save = function() {
            Store.update(vm.store, function(res){
                MessageService.success('保存成功')
                init();
            },function (error){
                MessageService.error(error.data.message || '保存失败');
            })
        }
    }
})();
