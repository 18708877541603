(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreThirdPageDialogController', StoreThirdPageDialogController);

    StoreThirdPageDialogController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'PlatformAbutment'];

    function StoreThirdPageDialogController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, PlatformAbutment) {
        var vm = this;
        vm.orderAddressAppliedSwitch = false;
        vm.abutment = entity;
        vm.store = Store.getCurrent();
        vm.platforms = []
        vm._platforms = [
            {id:'RRD',name:'人人店接口'},
            {id:'DY',name:'抖店接口'},
            {id:'WLN',name:'万里牛接口'},
            {id:'WDT',name:'旺店通接口'},
            {id:'PDD',name:'拼多多接口'}
        ]

        $q.all([vm.store.$promise]).then(function (res) {
          var _abutmentType = vm.store.abutmentType.split(';') || []
          _abutmentType.forEach(function(item){
            var obj = vm._platforms.find(function(itm){
                if(itm.id == item) return item
            }) || {}
            if(obj.id)vm.platforms.push(obj)
          })
        });

        $scope.auth = function(){
            PlatformAbutment.douYinAuth({code:""}, function (res) {
                if(res.status == 400){
                    MessageService.error(res.message || "授权失败！");
                }else{
                    MessageService.success("授权成功！");
                    $state.go("store-third-page-edit", {platform:$stateParams.id}, { reload: true});
                }
            }, function (error) {
                MessageService.error(error.data.message || "授权失败！");
            })
        }

        $scope.setOrderAddressAppliedSwitch = function(){
            var number = 0;
            if (vm.orderAddressAppliedSwitch) {
                number =1;
            }
            PlatformAbutment.setOrderAddressAppliedSwitch({number:number}, function (res) {
                if(res.status == 400){
                    MessageService.error(res.message || "设置失败");
                }else{
                    MessageService.success("设置成功");
                    $state.go("store-third-page-edit", {platform:$stateParams.id}, { reload: true});
                }
            }, function (error) {
                MessageService.error(error.data.message || "设置失败");
            })
        }
        $scope.save = function() {
            if(!vm.abutment.name){
                MessageService.error("名称不能为空");
                return
            }
            if(!vm.abutment.platform){
                MessageService.error("请选择类型");
                return
            }
            vm.isSaving = true;
            PlatformAbutment.save(vm.abutment , onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $state.go('store-third-page')
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }
        $scope.goBack = function (){
            $state.go('store-third-page')
        }
        $scope.platform = function (item){
            switch (item) {
                case 'RRD':
                    return '人人店接口';
                case 'DY':
                    return '抖店接口';
                case 'WLN':
                    return '万里牛接口';
                case 'WDT':
                    return '旺店通接口';
                case 'PDD':
                    return '拼多多接口';
                default:
                    return item
            }
        }
    }
})();
