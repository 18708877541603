(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreDialogController', StoreDialogController);

    StoreDialogController.$inject = ['$timeout', '$scope', 'UidService', 'StoreWechatSetting', 'entity', 'Store', 'MessageService', '$state', 'OrderSetting',
    'Qinius', '$q', '$stateParams', 'StoreApplies','Principal', 'StoreConfig'];

    function StoreDialogController($timeout, $scope, UidService, StoreWechatSetting, entity, Store, MessageService, $state, OrderSetting,
        Qinius, $q, $stateParams, StoreApplies,Principal, StoreConfig) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });
        vm.store = entity;
        if($stateParams.type=='salesEdit'&&vm.store.numberRule!=null&&vm.store.numberRule.length==4){
            vm.disable = true;
        }
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.storeConfigs = [];
        vm.imgSize = {
            type:'IMGSIZE',
            open: false,
            value:null,
        };
        vm.imgDpi = {
            type:'IMGDPI',
            open: false,
            value:null,
        };
        vm.delivery = {
            type:'PLACEOFDELIVERY',
            open: false,
            value:null,
        };
        StoreConfig.getAllByTypes({
                "storeId":vm.store.id,
                "type":["IMGSIZE", "IMGDPI", "PLACEOFDELIVERY"]
            }, function(data){
                if(data.status == 200){
                    vm.storeConfigs = data.message;
                    $q.all([ vm.store.$promise, vm.storeConfigs.$promise]).then(function () {
                        if (vm.storeConfigs && vm.storeConfigs.length>0) {
                            for (var i = vm.storeConfigs.length - 1; i >= 0; i--) {
                                var config = vm.storeConfigs[i];
                               if (config == null) {
                                    continue;
                                }
                                if (config.type == 'IMGSIZE') {
                                    vm.imgSize = config;
                                    continue;
                                }
                                if (config.type == 'IMGDPI') {
                                    vm.imgDpi = config;
                                    continue;
                                }
                                if (config.type == 'PLACEOFDELIVERY') {
                                    vm.delivery = config;
                                    continue;
                                }
                            }
                        }
                    });
                }
            });




        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        window.showSkuList2 = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".substitutione").addClass('text-decoration-underline');
                $(".substitutione-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList2 = function (e) {
            fn(e);
            $(".substitutione-tip").hide();
            $(".substitutione").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }


        //高级设置 商户设置保存方法
        vm.save2 = save2;
        vm.cancel2 = cancel2;
        //高级设置  订单设置保存方法
        vm.save3 = save3;
        //高级设置  订单设置 返回方法
        vm.cancel3 = cancel3;

        vm.storeOrderSetting = OrderSetting.getStoreOrderSetting2();
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();
        vm.types = [{key:"公众号",value:"WECHAT",selected:false}, {key:"小程序",value:"MINI",selected:false}, {key:"第三方",value:"THIRD",selected:false}]
        $q.all([vm.domains.$promise, vm.tokens.$promise, vm.store.$promise, vm.storeOrderSetting.$promise ]).then(function () {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
             
            vm.token = vm.tokens.token;
            if (vm.store.storeType2) {
                for (var i = vm.types.length - 1; i >= 0; i--) {
                    var t = vm.types[i];
                    if(vm.store.storeType2.indexOf(t.value)>=0){
                        t.selected = true;
                    }
                }
            }
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });
        StoreWechatSetting.getPrivateWechatSetting(function (msg) {
            $scope.storeWechatSetting = msg;
        })


        // 表单校验
        $scope.validate = function () {
            if (vm.store.name == null || vm.store.name == "") {
                MessageService.error("请录入名称");
                return false;
            }
            if (vm.store.contact == null || vm.store.contact == "") {
                MessageService.error("请录入联系人");
                return false;
            }
            if (vm.store.phone == null || vm.store.phone == "") {
                MessageService.error("请录入联系方式");
                return false;
            }
            if (vm.store.email == null || vm.store.email == "") {
                MessageService.error("请录入邮箱");
                return false;
            }
            if ((vm.store.storeType == 'Person' || vm.store.storeType == null) &&
                (vm.store.idCard == null || vm.store.idCard == "")) {
                MessageService.error("请录入证件号");
                return false;
            };
            if (vm.store.storeType == 'Company' && (vm.store.companyName == null || vm.store.companyName == "")) {
                MessageService.error("请录入公司名称");
                return false;
            };
            if (vm.store.storeType == 'Company' && (vm.store.companyCode == null || vm.store.companyCode == "")) {
                MessageService.error("请录入公司工商注册码");
                return false;
            };
            if (vm.store.address == null || vm.store.address == "") {
                MessageService.error("请录入地址");
                return false;
            }
            if (vm.store.email == null || vm.store.email == "") {
                MessageService.error("请录入邮箱");
                return false;
            };
            return true;
        }


        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'logo-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store.logo = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }


        window.uploadPic2 = function (event) {
            var files = event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload2(files[0]);
            }
        }

        function qiniuUpload2(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'logo-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.store.miniIndex = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        window.uploadPic3 = function ($event,attr) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload3(files[0],attr);
            }
        }

        function qiniuUpload3(file,attr) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'logo-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(attr=='login'){
                        vm.store.webLoginLogo = data.key;
                    }else{
                        vm.store.webRegisterLogo = data.key;
                    }

                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        $scope.removeFile = function (file) {
            vm.store.logo = null;
        }

        $scope.removeFile2 = function (file) {
            vm.store.miniIndex = null;
        }

        $scope.removeFile3 = function (file,attr) {
            if(attr=='login'){
                vm.store.webLoginLogo = null;
            }else{
                vm.store.webRegisterLogo = null;
            }
        }

        function save2() {
            vm.isSaving = true;
            if (vm.store.id !== null) {
                Store.update(vm.store, onSaveSuccess2, onSaveError);
            } else {
                Store.save(vm.store, onSaveSuccess2, onSaveError);
            }
        }

        function onSaveSuccess2(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            $state.go("senior", null, { reload: true });
        }


        function cancel2() {
            $state.go("senior");
        }

        $scope.validate = function(){

        }

        function save3() {
            if (vm.store.numberRule == null || vm.store.numberRule.length<4) {
                MessageService.error("输入的订单号前缀不符合规则");
                return false;
            }
            if (/^([0-9]+)$/.test(vm.store.numberRule.substring(1)) && /[a-zA-Z]+/.test(vm.store.numberRule.substring(0,1))) {
            }else{
                MessageService.error("输入的订单号前缀不符合规则");
                return false;
            }
            Store.getAllByCondition({
                items: [{key:"numberRule", op:"=", value:vm.store.numberRule}]
            }, function(data){
                if (vm.store.id !== null) {
                    if ((data.length ==1 && data[0].id != vm.store.id) || data.length>1) {
                        MessageService.error("订单号前缀重复，请重新设定");
                    }else{
                        vm.isSaving = true;
                        Store.update(vm.store, onSaveSuccess3, onSaveError);
                        vm.storeConfigs = new Array();
                        vm.storeConfigs.push(vm.imgSize);
                        vm.storeConfigs.push(vm.imgDpi);
                        vm.storeConfigs.push(vm.delivery);
                        StoreConfig.saveStoreConfigs(vm.storeConfigs);
                    }
                } else {
                    if (data.length >0) {
                        MessageService.error("订单号前缀重复，请重新设定");
                    }else{
                        vm.isSaving = true;
                        Store.save(vm.store, onSaveSuccess3, onSaveError);
                    }

                }
            }, function(){
                MessageService.error("检查单号前缀失败，请稍后再试");
            });
        }

        function onSaveSuccess3(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            vm.storeOrderSetting.platformFlag = false;
            vm.storeOrderSetting.storeId = result.id;
            OrderSetting.updateStoreTimerSetting(vm.storeOrderSetting, function (data) {
                MessageService.success("保存成功！");
                $state.go("sales-setting", null, { reload: true });
            }, function (data) {
                MessageService.error("延时信息保存失败！");
            })
        }

        function cancel3() {
            $state.go("sales-setting");
        }

        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.activationDate = false;
        vm.datePickerOpenStatus.periodOfValidity = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
