(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayOrderController2', PayOrderController2);

    PayOrderController2.$inject = ['$scope', '$state', 'PayOrder', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService',
    'DateUtils', 'Principal'];

    function PayOrderController2 ($scope, $state, PayOrder, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService,
        DateUtils, Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_INVOICE_SUBMIT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        vm.datePickerOpenStatus = {};
        vm.openCalendar =  openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;
        vm.selectAmount = 0;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.payOrders,function (order,i) {
                if($scope.selectAll){
                    vm.selectAmount += order.amount;
                    order.select = true;
                    $scope.batchArr.push(order.id);
                    $scope.selectOne = true;
                }else{
                    vm.selectAmount = 0;
                    order.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (payOrder) {
            payOrder.select = !payOrder.select;
            // 选中
            if(payOrder.select){
                vm.selectAmount += payOrder.amount;
                $scope.batchArr.push(payOrder.id);
            }else{
                // 未选中
                 vm.selectAmount = vm.selectAmount - payOrder.amount;
                var ind = $scope.batchArr.indexOf(payOrder.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.payOrders,function (payOrder,i) {
                if(!payOrder.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.payOrders.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }


        $scope.invoiceDetail = function(){
            if ($scope.batchArr == null || $scope.batchArr.length == 0) {
                MessageService.error("请先勾选想要开票的订单");
                return;
            }
            $state.go('invoice-detail', {ids: $scope.batchArr});
        }

        loadAll();

        function loadAll () {
            var items = [{key:"paid",op:"=",value:"true"},{key:"invoiced",op:"=",value:"false"}];
            if (vm.endTime != null) {
                items.push({"key":"createdDate", op:"<=", value:DateUtils.convertLocalDateToServer(vm.endTime) });
            }
            if (vm.startTime != null) {
                items.push({"key":"createdDate", op:">=", value:DateUtils.convertLocalDateToServer(vm.startTime)});
            }
             
            PayOrder.byCondition({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.payOrders = data;
                vm.amount = 0 ;
                for (var i = vm.payOrders.length - 1; i >= 0; i--) {
                    var order = vm.payOrders[i];
                    vm.amount += order.amount;
                }
                vm.count = vm.payOrders.length;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("充值订单获取失败");
            }
        }


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }


        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }
    } 
})();
