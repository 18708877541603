(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('BranchStore', BranchStore)
        .factory('BranchStoreManagements', BranchStoreManagements);

    BranchStore.$inject = ['$resource'];
    BranchStoreManagements.$inject = ['$resource'];

    function BranchStore ($resource) {
        var resourceUrl =  'manager/' + 'api/branch-store/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getBranchForManagement":{
                method:"GET",
                params:{
                    path:"getBranchForManagement"
                },
                isArray: true
            },
            'get': {method: 'GET'},
            'update': { method:'PUT' },
            'create': { method:'POST' },
            'del': {
                method: "DELETE"
            },
            'upload':{
                method: 'POST',
                params: {
                    path: 'uploadFile'
                }
            },
            'updateWithdrawal':{
                method: 'GET',
                params: {
                    path: 'updateWithdrawal'
                }
            }
        });
    }
    function BranchStoreManagements ($resource) {
        var resourceUrl =  'manager/' + 'api/branch-store-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "findByUserId":{
                method:"GET",
                params:{
                    path:"findByUserId"
                }
            },
            'get': {method: 'GET'},
            'update': { method:'PUT' },
            'create': { method:'POST' },
            'del': {
                method: "DELETE"
            },
            'upload':{
                method: 'POST',
                params: {
                    path: 'uploadFile'
                }
            }
        });
    }
})();
