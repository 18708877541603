(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreThirdTiktokController', StoreThirdTiktokController);

    StoreThirdTiktokController.$inject = ['$timeout', '$scope', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'PlatformAbutment'];

    function StoreThirdTiktokController ($timeout, $scope, $q, $state, MessageService, entity, Store, $stateParams, PlatformAbutment) {
        var vm = this;
        vm.abutment = PlatformAbutment.douYin();

        vm.abutment.platform = "DY";
        //代运营 保存
        vm.save2 = save2;

        vm.orderAddressAppliedSwitch = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        vm.store = Store.getCurrent();
        $q.all([vm.store.$promise, vm.abutment.$promise]).then(function(){
            if (!vm.abutment.id) {
                vm.abutment.platform = "DY";
                vm.abutment.enabled = true;
            }
        }) ;

        $scope.auth = function(){
            PlatformAbutment.douYinAuth({code:""}, function (res) {
                if(res.status == 400){
                    MessageService.error(res.message || "授权失败！");
                }else{
                    MessageService.success("授权成功！");
                    $state.go("store-third-tiktok-list", null, { reload: true});
                }
            }, function (error) {
                MessageService.error(error.data.message || "授权失败！");
            })
        }

        $scope.setOrderAddressAppliedSwitch = function(){
            var number = 0;
            if (vm.orderAddressAppliedSwitch) {
                number =1;
            }
            PlatformAbutment.setOrderAddressAppliedSwitch({number:number}, function (res) {
                if(res.status == 400){
                    MessageService.error(res.message || "设置失败");
                }else{
                    MessageService.success("设置成功");
                    $state.go("store-third-tiktok-list", null, { reload: true});
                }
            }, function (error) {
                MessageService.error(error.data.message || "设置失败");
            })
        }

        


        function save2 () {
            vm.isSaving = true;
            vm.abutment.storeId = vm.store.id;
            PlatformAbutment.save(vm.abutment , onSaveSuccess2, onSaveError);
        }

        function onSaveSuccess2 (result) {
            vm.abutment = result;
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }
    }
})();
