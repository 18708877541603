(function() {
    'use strict';

    angular
    .module('backApp')
    .controller('PassListController', PassListController);

    PassListController.$inject = ['$scope', '$state'];

    function PassListController ($scope, $state) {
        var vm = this;
        
}
})();
