(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('File', File)
        .factory('FileService', FileService)
        .factory('FileService2', FileService2)
        .factory('Music', Music)
        .factory('ProductXmlFileServes', ProductXmlFileServes)
        .factory('Product', Product)
        .factory('ItemValue', ItemValue)
        .factory('Vert', Vert);

    Product.$inject = ['$resource', 'DateUtils'];
    ProductXmlFileServes.$inject = ['$resource'];
    File.$inject = ['$resource'];

    FileService.$inject = ['$resource'];
    FileService2.$inject = ['$resource'];
    Music.$inject = ['$resource', 'DateUtils'];
    Vert.$inject = ['$resource'];
    ItemValue.$inject = ['$resource'];

    function ItemValue($resource) {
        var resourceUrl = 'manager/' + 'api/item-values/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function Music($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/music/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'allByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'get': {
                method: 'GET'
            }
        });
    }


    function ProductXmlFileServes ($resource) {
        var resourceUrl =  'fileserves/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        // if (data.modelBlendent) {
                        //     data.modelBlendent = angular.fromJson(data.modelBlendent);
                        // }
                        // if (data.modelData) {
                        //      data.modelData = angular.fromJson(data.modelData);
                        // }
                    }

                    return data;
                }
            },
            "updateCountTip":{
                method:"POST",
                params:{
                    path:"updateCountTip"
                }
            },
            "batchUpdateCountTip":{
                method:"GET",
                params:{
                    path:"batchUpdateCountTip"
                }
            },

            'updatePageSize': {
                method: 'GET',
                params: {
                    path: 'updatePageSize'
                }
            },
            "save":{
                method:'POST' ,
                // transformRequest: function (data) {
                //     if (data) {
                //         if (data.modelBlendent) {
                //             data.modelBlendent = angular.toJson(data.modelBlendent);
                //         }
                //         if (data.modelData) {
                //             data.modelData = angular.toJson(data.modelData);
                //         }
                //     }
                //     return angular.toJson(data);
                // }
            },
            'update': {
                method:'PUT' ,
                // transformRequest: function (data) {
                //     if (data) {
                //         if (data.modelBlendent) {
                //             data.modelBlendent = angular.toJson(data.modelBlendent);
                //         }
                //         if (data.modelData) {
                //             data.modelData = angular.toJson(data.modelData);
                //         }
                //     }
                //     return angular.toJson(data);
                // }
            }
        });
    }

    function Vert ($resource) {
        var resourceUrl =  'fileserves/' + 'api/convert/:path/:storeId';

        return $resource(resourceUrl, {}, {
            "xmlToDocument":{
                method:"GET",
                params:{
                    path:"xmlToDocument"
                }
            },
            "cutImage":{
                method:"GET",
                params:{
                    path:"cutImage"
                }
            },

            "imgFilter":{
                method: 'GET',
                params: {
                    path: "filter"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }


    function FileService ($resource) {
        var resourceUrl =  'service/' + 'api/:path/:id';

        return $resource(resourceUrl, {}, {
            'downloadZipFile': {
                method: 'GET',
                params: {
                    path: 'downloadZipFile'
                }
            }
        });
    }

    function FileService2 ($resource) {
        var resourceUrl =  'fileserves/' + 'api/download/:path/:id';
        return $resource(resourceUrl, {}, {
            'downloadZipFileCheck': {
                method: 'GET',
                params: {
                    path: 'downloadZipFileCheck'
                }
            },
            'downloadZipFile3': {
                method: 'GET',
                params: {
                    path: 'downloadZipFile3'
                }
            },
        });
    }

    function File ($resource) {
        var resourceUrl =  'manager/' + 'api/:path/:id';

        return $resource(resourceUrl, {}, {
            'remove': {
                method: 'GET',
                params: {
                    path: 'remove'
                }
            },
            'download': {
                method: 'GET',
                params: {
                    path: 'download'
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getFile'
                }
            },
            'getFontName':{
                method:'GET',
                params:{
                    path:'getFontNames'
                }
            },
            'uploadFont':{
                method:'POST',
                params:{
                    path:'uploadFont'
                },
                isArray: true

            }
        });
    }

    function Product ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/products/:path/:id';

        return $resource(resourceUrl, {}, {
            'copy': {
                method: 'GET',
                params: {
                    path: 'copy'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getAllByCondition4":{
                method:"POST",
                params:{
                    path:"getAllByCondition4"
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            "updateSaleable":{
                method:"GET",
                params:{
                    path:"updateSaleable",
                }
            },
            "getCategoriesByProduct":{
                method:"GET",
                params:{
                    path:"getCategoriesByProduct",
                },
                isArray: true
            },
            "getProductsClient":{
                method:"GET",
                params:{
                    path:"getProductsClient",
                },
                isArray: true
            },
            "updateAllSaleable":{
                method:"GET",
                params:{
                    path:"updateAllSaleable",
                }
            },
            "updateSaleableByCategory":{
                method:"GET",
                params:{
                    path:"updateSaleableByCategory",
                }
            },
            "updateSaleableBatchByCategory":{
                method:"GET",
                params:{
                    path:"updateSaleableBatchByCategory",
                }
            },

            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            "shareProducts":{
                method:"GET",
                params:{
                    path:"shareProducts"
                },
                isArray: true
            },

            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
            "getSupplierProducts":{
                method:"GET",
                params:{
                    path:"getSupplierProducts",
                }
            },
            "grounding":{
                method:"GET",
                params:{
                    path:"grounding",
                }
            },
            "grounded":{
                method:"GET",
                params:{
                    path:"grounded",
                },
                isArray: true
            }
        });
    }


})();
