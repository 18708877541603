(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductSellLimitDetailController', ProductSellLimitDetailController);

    ProductSellLimitDetailController.$inject = ['$timeout', '$scope', '$stateParams',  '$q', 'entity', 'ProductSellLimit', 'MHistory', '$state', 'MessageService'];

    function ProductSellLimitDetailController($timeout, $scope, $stateParams,   $q, entity, ProductSellLimit, MHistory, $state, MessageService) {
       var vm = this;
        vm.productSellLimit = entity;
        vm.clear = clear;
        $q.all([  vm.productSellLimit.$promise]).then(function() {
            if (vm.productSellLimit.status && vm.productSellLimit.status ==200) {
                vm.productSellLimit = vm.productSellLimit.message;
                vm.historys = MHistory.getAllByCondition({items:[{key:"classId", op:"=", value: vm.productSellLimit.id},
                    {key:"className", op:'=', value:"ProductSellLimit"}],sort:["createdDate,desc"]});
            }else{
                MessageService.error("数据请求失败，请稍后再试");
                return;
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {

            $state.go("product-sell-limit");
        }

    }
})();
