(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('UpdateAmountController', UpdateAmountController);

    UpdateAmountController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'Store', '$state', 'MessageService', '$uibModalInstance', 'Guide'];

    function UpdateAmountController ($timeout, $scope, $stateParams, $q, entity, Store, $state, MessageService, $uibModalInstance, Guide) {
        var vm = this;
        vm.data = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.amount = ""; 
        vm.isDimission = false;
        vm.isShop = false;

        $scope.dimissionCb = function () {
            vm.isDimission = !vm.isDimission;
            vm.isShop = false;
            vm.branchStoreId = "";
        }
        $scope.shopSelect = function () {
            vm.isShop = !vm.isShop;
            vm.isDimission = false;
            vm.branchStoreId = "";
        }

        //确定
        function save() {
            if(!vm.amount){
                MessageService.error("请输入调整金额");
                return
            }

            if(!vm.remark){
                MessageService.error("请输入调整理由");
                return
            }
           
            Guide.updateAmount({id:vm.data.id, updateAmount:vm.amount, remark:vm.remark}, function (res) {
                if (res.status == 200) {
                    MessageService.success("调整成功");
                    $uibModalInstance.close(true);
                }else{
                    MessageService.error("调整失败");
                }
            }, function (error) {
                MessageService.error("调整失败");
            })
        }
        //返回
        function cancel() {
            $uibModalInstance.close(false);
        }
    }
})();
