(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pay-batch-change-application', {
            parent: 'entity',
            url: '/pay-batch-change-application?page&sort&search',
            data: {
                authorities: ['ROLE_STORE_CHANGEREQUEST'],
                pageTitle: 'backApp.payBatchChangeApplication.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-batch-change-application/pay-batch-change-applications.html',
                    controller: 'PayBatchChangeApplicationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payBatchChangeApplication');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('status');
                    return $translate.refresh();
                }]
            }
        })
        .state('pay-batch-change-application-detail', {
            parent: 'entity',
            url: '/pay-batch-change-application/{id}',
            data: {
                authorities: ['ROLE_STORE_CHANGEREQUEST'],
                pageTitle: 'backApp.payBatchChangeApplication.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-batch-change-application/pay-batch-change-application-detail.html',
                    controller: 'PayBatchChangeApplicationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payBatchChangeApplication');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PayBatchChangeApplication', function($stateParams, PayBatchChangeApplication) {
                    return PayBatchChangeApplication.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'pay-batch-change-application',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('pay-batch-change-application-detail.edit', {
            parent: 'pay-batch-change-application-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_STORE_CHANGEREQUEST']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-batch-change-application/pay-batch-change-application-dialog.html',
                    controller: 'PayBatchChangeApplicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PayBatchChangeApplication', function(PayBatchChangeApplication) {
                            return PayBatchChangeApplication.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pay-batch-change-application.new', {
            parent: 'pay-batch-change-application',
            url: '/new',
            data: {
                authorities: ['ROLE_STORE_CHANGEREQUEST']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-batch-change-application/pay-batch-change-application-dialog.html',
                    controller: 'PayBatchChangeApplicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                payBatchId: null,
                                productPurchaseId: null,
                                packageId: null,
                                spreadPrice: null,
                                productNamePrevious: null,
                                productSkuPrevious: null,
                                productNameLater: null,
                                productSkuLater: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('pay-batch-change-application', null, { reload: 'pay-batch-change-application' });
                }, function() {
                    $state.go('pay-batch-change-application');
                });
            }]
        })
        .state('pay-batch-change-application.edit', {
            parent: 'pay-batch-change-application',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_STORE_CHANGEREQUEST']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-batch-change-application/pay-batch-change-application-dialog.html',
                    controller: 'PayBatchChangeApplicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PayBatchChangeApplication', function(PayBatchChangeApplication) {
                            return PayBatchChangeApplication.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pay-batch-change-application', null, { reload: 'pay-batch-change-application' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
