(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CommandDialogController', CommandDialogController);

    CommandDialogController.$inject = ['$timeout', '$scope','UidService', 'MessageService', 'entity', 'Command','Qinius','$state','$stateParams','$q',
     'CommandBatch', 'PasswordActivity', 'DateUtils'];

    function CommandDialogController ($timeout, $scope,UidService, MessageService, entity, Command,Qinius,$state,$stateParams,$q,
     CommandBatch, PasswordActivity, DateUtils) {
        var vm = this;
        vm.command = entity;
        vm.command.passwordActivityId = $stateParams.acId;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.passwordActivity = PasswordActivity.get({id:vm.command.passwordActivityId});
        vm.domains = Qinius.getDomain();
        vm.tokens = Qinius.get();

        $q.all([vm.domains.$promise,  vm.tokens.$promise, vm.command.$promise, vm.passwordActivity.$promise]).then(function(){
            vm.commandBatches = CommandBatch.getAllByCondition({items:[
                {key:"brand.id", op :"=", value:vm.passwordActivity.brandId},
                {key:"product.id", op :"=", value:vm.passwordActivity.productId},
                {key:"endDate", op :">=", value: DateUtils.convertLocalDateToServer(new Date()) }]}
                );
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
        }) ;

        $scope.commandBatchChange = function(){
            var batch = $scope.getBatch();
            if (vm.command.commandBatchId && batch) {
                vm.command.type = batch.type;
                vm.command.usedType = batch.usedType;
                vm.command.dateEnable = batch.dateEnable;
                vm.command.endDate = DateUtils.convertDateTimeFromServer(batch.endDate) ;
                vm.command.startDate = DateUtils.convertDateTimeFromServer(batch.startDate) ;
            }else{
                vm.command.type = null;
                vm.command.usedType = null;
                vm.command.dateEnable = null;
                vm.command.endDate = null;
                vm.command.startDate = null;
            }
        }

        $scope.getBatch = function(){
            for (var i = vm.commandBatches.length - 1; i >= 0; i--) {
                var batch = vm.commandBatches[i];
                if (batch.id == vm.command.commandBatchId) {
                    return batch;
                }
            }
            return null;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go("command", {acId: vm.command.passwordActivityId} , {reload:true})
        }

        $scope.valadata = function(){
            if (vm.command.word == null || vm.command.word == "") {
                MessageService.error("请输入口令");
                return false;
            }
            if (vm.command.commandBatchId == null || vm.command.commandBatchId == "") {
                MessageService.error("请选择批次号");
                return false;
            }

            if (vm.command.dateEnable&&(vm.command.startDate == null || vm.command.startDate == "")) {
                MessageService.error("请选择开始时间");
                return false;
            }

            if (vm.command.adEnable&&(vm.command.adImage == null || vm.command.adImage == "")) {
                MessageService.error("请上传广告页");
                return false;
            }
            return true;
        }


        function save () {
            if (!$scope.valadata()) {
                return false;
            }
            vm.isSaving = true;
            if (vm.command.id !== null) {
                Command.update(vm.command, onSaveSuccess, onSaveError);
            } else {
                Command.save(vm.command, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:commandUpdate', result);
            // $uibModalInstance.close(result);
            CommandBatch.used({id:result.commandBatchId});
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $state.go("command", {acId: vm.command.passwordActivityId} , {reload:true})
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', 'back-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.command.adImage = data.key;
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("广告页上传失败");
                }
            });
        }

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files !=null && files.length >0) {
                qiniuUpload(files[0]);
            }
        }

        $scope.deletePic = function(){
            vm.command.adImage = null;
        }
    }
})();
