(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance',  'User', 'JhiLanguageService', "Role"];

    function UserManagementDialogController ($stateParams, $uibModalInstance,  User, JhiLanguageService, Role) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = {};
        vm.roles = Role.query();
		vm.load = load;
		vm.load($stateParams.id);

        function load (id) {
            User.get({id: id,scope:'back'}, function(result) {
                vm.user = result;
            });
        }

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
