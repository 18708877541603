(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderController2', SalesOrderController2);

    SalesOrderController2.$inject = ['$scope', 'Principal', '$state', 'SalesOrder', 'ParseLinks',  'paginationConstants', 'pagingParams', 
    'MessageService', '$stateParams', 'FileService2', '$timeout'];

    function SalesOrderController2 ($scope, Principal, $state, SalesOrder, ParseLinks,  paginationConstants, pagingParams, MessageService, 
        $stateParams, FileService2, $timeout) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.time = null;
        vm.jump = jump;

        vm.reJustify = reJustify;

        loadAll();

        var checkNum = 0;
        var timer = $timeout(function(){
            if (checkNum != 0) {
                checkNum = 0;
            }
        },3000);
        $scope.downloadZipFile2 = function(salesOrder){
            if (salesOrder.fileStatus != '文件组版完成') {
                return;
            }
            if (checkNum != 0) {
                MessageService.error("操作频率太快, 刷新页面重试！");
                return;
            }
            checkNum = 1;

            var dto = {};
            dto.number = salesOrder.number;
            dto.xml = salesOrder.filePath;
            dto.storeId = salesOrder.storeId;
            dto.userId = salesOrder.userId;
            dto.identifiers = [];
            // 拼接数据
            var flag = true;
            if (salesOrder.orderLines != null) {
                for (var i = 0; i < salesOrder.orderLines.length; i ++) {
                    var map = {};
                    map.id = salesOrder.orderLines[i].id;
                    map.identify = salesOrder.orderLines[i].orderArticle.fileIdentify;
                    if (salesOrder.orderLines[i].orderArticle.fileIdentify == null) {
                        // 没有文件
                        flag = false;
                    }
                    dto.identifiers.push(map);
                }
            }
            if (!flag) {
                MessageService.error("订单文件错误！");
                return;
            }
            // 修改状态
            SalesOrder.produce({id:salesOrder.id});

            var a = JSON.stringify(dto);
            window.open("fileserves/api/download/downloadZipFile2?dto="+a);
            
        }

        $scope.produce = function(id) {
            if (id == null) {
                return;
            };
            MessageService.confirm("确认订单进入生产？", function() {
                SalesOrder.produce({
                    id: id
                }, function(data) {
                    loadAll();
                }, function() {
                    MessageService.error("执行生产失败");
                })
            }, function() {})
        }
         $scope.byTime = function(time){
            vm.time = time;
            loadAll();
        }


        function loadAll () {
            var items=[{key:"status",op:"=",value:"Paid"}];
            if (vm.time != null) {
                if (vm.time == 'today') {
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()))});
                }else if(vm.time == 'week'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-7*24*60*60*1000-1))});
                }else if(vm.time == 'weeks'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-14*24*60*60*1000-1))});
                }else if(vm.time == 'moth'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-30*24*60*60*1000-1))});
                }
            };
            if (pagingParams.search) {
                items.push({key:"number",op:"like",value:pagingParams.search});
                SalesOrder.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                SalesOrder.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return ["createdDate,desc"];
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.salesOrders = data;
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i ++) {
                        // Combinationing, Combinationed, ZIPCreate, downLoad, fileError
                        if (data[i].fileStatus == 'Combinationing') {
                            data[i].fileStatus = '文件组版中';
                        } else if (data[i].fileStatus == 'Combinationed') {
                            data[i].fileStatus = '文件组版完成';
                        } else if (data[i].fileStatus == 'fileError') {
                            data[i].fileStatus = '文件生成出错';
                        }
                    }
                }
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("订单加载失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        function reJustify(obj) {
            SalesOrder.reJustify({id: obj.id}, function (data) {
                MessageService.success("订单文件重新组版中");
                loadAll();
            }, function () {
                MessageService.error("订单文件组版失败");
            });
        }
    }
})();
