(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('VipLevel', VipLevel)
        .factory('IntegralAcqSetting', IntegralAcqSetting)
        .factory('VipLevelSetting', VipLevelSetting);

    VipLevel.$inject = ['$resource', 'DateUtils'];
    IntegralAcqSetting.$inject = ['$resource', 'DateUtils'];
    VipLevelSetting.$inject = ['$resource', 'DateUtils'];

    function VipLevel($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/vip-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'storeId'
                }
            },
            "saveVipLevel": {
                method: "PUT"
            }
        });
    }

    function VipLevelSetting($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/vip-level-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            "saveVipLevelSet": {
                method: "PUT"
            },
            'del': {
                method: 'GET',
                params: {
                    path: 'del'
                }
            }
        });
    }

    function  IntegralAcqSetting($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/integral-acq-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'storeId'
                }
            },
            "save": {
                method: "PUT"
            }
        });
    }
})();