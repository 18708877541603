(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductSellLimitDialogController', ProductSellLimitDialogController);

    ProductSellLimitDialogController.$inject = ['$timeout', '$scope', '$stateParams',  '$q', 'entity', 'ProductSellLimit', 'Product', '$state', 'MessageService'];

    function ProductSellLimitDialogController ($timeout, $scope, $stateParams,   $q, entity, ProductSellLimit, Product, $state, MessageService) {
        var vm = this;

        vm.productSellLimit = entity;
        vm.clear = clear;
        vm.save = save;

        vm.valueLines = [];
        vm.products = Product.getAllByCondition({items:[{key: "prototype", op: "in", value: "Store;StoreSelf"}]});
        $q.all([ vm.products.$promise, vm.productSellLimit.$promise]).then(function() {
            if (vm.productSellLimit.status) {
                if ( vm.productSellLimit.status ==200) {
                    vm.productSellLimit = vm.productSellLimit.message;
                }else{
                    MessageService.error("数据请求失败，请稍后再试");
                    return;
                }
            }
            var str = '';
            angular.forEach(vm.productSellLimit.skuSellLimits,function (line) {
                if (!line.cancelled) {
                    str+=line.skuNumber+',';
                }
            })

            if (vm.productSellLimit.productId) {
                Product.get({
                    id: vm.productSellLimit.productId
                }, function(data) {
                    vm.product = data;
                    for (var i = data.itemValues.length - 1; i >= 0; i--) {
                        var itemValue = data.itemValues[i];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        if (itemValue.itemValueLines != null && itemValue.itemValueLines.length > 0) {
                            for (var j = itemValue.itemValueLines.length - 1; j >= 0; j--) {
                                var valueLine = itemValue.itemValueLines[j];
                                if (valueLine.cancelled) {
                                    continue;
                                }
                                var skuSellLimit = {
                                    added: true,
                                    limitQuantity:999999,
                                    sku: valueLine.sku,
                                    itemValueLineId:valueLine.id,
                                    skuNumber:valueLine.skuNumber,
                                };
                                if (str.indexOf(valueLine.skuNumber) == -1) {
                                    vm.valueLines.push(skuSellLimit)
                                }
                            }
                        }
                    }
                    console.log(vm.valueLines)
                }, function(data) {

                })
            }
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            history.back();
            // $state.go("product-sell-limit");
        }

        $scope.deleteLine = function(line){
            if (line.added) {
                var ind = vm.productSellLimit.skuSellLimits.indexOf(line);
                if (ind >= 0) {
                    vm.productSellLimit.skuSellLimits.splice(ind, 1);
                }
            }else{
                line.cancelled = true;
            }
            vm.valueLines.push(line);
        }


        $scope.addLine = function(){
            if (!vm.productSellLimit.productId) {
                MessageService.error("请先选择商品");
                return;
            }

            if(!vm.valueLines.length){
                MessageService.error("没有可添加的属性！");
                return
            }
            if(vm.valueLines[0].deleted){
                angular.forEach(vm.productSellLimit.skuSellLimits,function (line) {
                    if(line.id==vm.valueLines[0].id){
                        line.deleted = false;
                    }
                })
            }else{
                vm.productSellLimit.skuSellLimits.push(vm.valueLines[0]);
            }

            vm.valueLines.shift();
        }


        $scope.productChange = function(){
            if (!vm.productSellLimit.id) {
                var items = [{key:"product.id",op:"=",value:vm.productSellLimit.productId}];
                ProductSellLimit.byCondition({
                    items:items,
                }, function(res){
                    if(res &&res.status == 200 && res.message.length>0){
                        vm.productSellLimit.productId = null;
                        vm.productSellLimit.skuSellLimits= [];
                        MessageService.error("商品不可重复添加销售计划，请确认后再操作");
                        return;
                    }
                }, function(){});
                

                vm.productSellLimit.skuSellLimits = [];
                Product.get({id:vm.productSellLimit.productId},function(data){
                    vm.product = data;
                    vm.productSellLimit.productName = data.name;
                    for (var i = data.itemValues.length - 1; i >= 0; i--) {
                        var itemValue = data.itemValues[i];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        if (itemValue.itemValueLines!=null && itemValue.itemValueLines.length>0) {
                            for (var j = itemValue.itemValueLines.length - 1; j >= 0; j--) {
                                var valueLine =  itemValue.itemValueLines[j];
                                if (valueLine.cancelled) {
                                    continue;
                                }
                                var skuSellLimit = {
                                    added: true,
                                    limitQuantity:999999,
                                    sku: valueLine.sku,
                                    itemValueLineId:valueLine.id,
                                    skuNumber:valueLine.skuNumber,
                                };
                                vm.productSellLimit.skuSellLimits.push(skuSellLimit);
                            }
                        }
                    }
                },function(data){

                })
            }

        }

        function save () {
            vm.isSaving = true;
            if (vm.productSellLimit.id !== null) {
                ProductSellLimit.update(vm.productSellLimit, onSaveSuccess, onSaveError);
            } else {
                ProductSellLimit.save(vm.productSellLimit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:productSellLimitUpdate', result);
            history.back();
            MessageService.success("保存成功");
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
