(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InitPaymentController', InitPaymentController);

    InitPaymentController.$inject = ['$timeout', '$scope','UidService', '$q', '$state', 'MessageService', 'entity', 'Store', '$stateParams', 'StoreWechatSetting',
        'StoreSmallAppWechatSetting', 'StorePaymentSetting',
    ];

    function InitPaymentController($timeout, $scope,UidService, $q, $state, MessageService, entity, Store, $stateParams, StoreWechatSetting,
        StoreSmallAppWechatSetting, StorePaymentSetting) {
        var vm = this;

        vm.store = entity;
        vm.selectTab = 1;
        vm.storePaymentSetting = StorePaymentSetting.getCurrent();

        vm.subPayMentOptions = [{
            key: "CUSTOM",
            value: "自定义",
            show: true
        }, {
            key: "MINI",
            value: "同小程序收款账号",
            show: true
        }, {
            key: "WEB",
            value: "同电脑端收款账号",
            show: true
        }]

        vm.miniPayMentOptions = [{
            key: "CUSTOM",
            value: "自定义",
            show: true
        }, {
            key: "SUB",
            value: "同公众号收款账号",
            show: true
        }, {
            key: "WEB",
            value: "同电脑端收款账号",
            show: true
        }]

        vm.webPayMentOptions = [{
            key: "CUSTOM",
            value: "自定义",
            show: true
        }, {
            key: "SUB",
            value: "同公众号收款账号",
            show: true
        }, {
            key: "MINI",
            value: "同小程序收款账号",
            show: true
        }]

        $scope.pre= function(){
            if (vm.store.storeType2 != null && vm.store.storeType2.indexOf('MINI')>=0) {
                $state.go("init-mini");
                return;
            }else if( vm.store.storeType2 != null && vm.store.storeType2.indexOf('WECHAT')>=0){
                $state.go("init-wechat");
                return;
            }else{
                $state.go("step1");
            }
        }

        $scope.payMentOPChange = function(index) {
            if (index == 1) {
                if (vm.wechatSetting.paymentSource == 'MINI') {
                    vm.wechatSetting.weChatMchid = vm.miniSetting.weChatMchid;
                    vm.wechatSetting.weChatKey = vm.miniSetting.weChatKey;
                    vm.wechatSetting.certificate = vm.miniSetting.certificate;
                }
                if (vm.wechatSetting.paymentSource == 'WEB') {
                    vm.wechatSetting.weChatMchid = vm.storePaymentSetting.weChatMchid;
                    vm.wechatSetting.weChatKey = vm.storePaymentSetting.weChatKey;
                    vm.wechatSetting.certificate = vm.storePaymentSetting.weChatCertificatePath;
                }
            }
            if (index == 2) {
                if (vm.miniSetting.paymentSource == 'SUB') {
                    vm.miniSetting.weChatMchid = vm.wechatSetting.weChatMchid;
                    vm.miniSetting.weChatKey = vm.wechatSetting.weChatKey;
                    vm.miniSetting.certificate = vm.wechatSetting.certificate;
                }
                if (vm.miniSetting.paymentSource == 'WEB') {
                    vm.miniSetting.weChatMchid = vm.storePaymentSetting.weChatMchid;
                    vm.miniSetting.weChatKey = vm.storePaymentSetting.weChatKey;
                    vm.miniSetting.certificate = vm.storePaymentSetting.weChatCertificatePath;
                }
            }
            if (index == 3) {
                if (vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.storePaymentSetting.weChatMchid = vm.wechatSetting.weChatMchid;
                    vm.storePaymentSetting.weChatKey = vm.wechatSetting.weChatKey;
                    vm.storePaymentSetting.weChatCertificatePath = vm.wechatSetting.certificate;
                }
                if (vm.storePaymentSetting.paymentSource == 'MINI') {
                    vm.storePaymentSetting.weChatMchid = vm.miniSetting.weChatMchid;
                    vm.storePaymentSetting.weChatKey = vm.miniSetting.weChatKey;
                    vm.storePaymentSetting.weChatCertificatePath = vm.miniSetting.certificate;
                }
            }
        }

        $scope.removeFile = function (type) {
            if (type == 'miniSetting') {
                vm.miniSetting.certificate = null;
            }
            if (type == 'storePaymentSetting') {
                vm.storePaymentSetting.weChatCertificatePath = null;
            }
            if (type == 'wechatSetting') {
                vm.wechatSetting.certificate = null;
            }
        };


        window.updateImgFile2 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'certificate-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.miniSetting.certificate = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.updateImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'certificate-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.wechatSetting.certificate = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.updateImgFile3 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'certificate-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.storePaymentSetting.weChatCertificatePath = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        $scope.payMentOPshow = function(index) {
            vm.selectTab = index;
            vm.subPayMentOptions[1].show = true;
            vm.subPayMentOptions[2].show = true;
            vm.miniPayMentOptions[1].show = true;
            vm.miniPayMentOptions[2].show = true;
            vm.webPayMentOptions[1].show = true;
            vm.webPayMentOptions[2].show = true;
            if (index == 1) {
                if (vm.miniSetting.paymentSource == 'SUB' || vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.subPayMentOptions[1].show = false;
                    vm.subPayMentOptions[2].show = false;
                }
                if (vm.miniSetting.paymentSource == 'WEB') {
                    vm.subPayMentOptions[1].show = false;
                }
                if (vm.storePaymentSetting.paymentSource == 'MINI') {
                    vm.subPayMentOptions[2].show = false;
                }
            }
            if (index == 2) {
                if (vm.storePaymentSetting.paymentSource == 'MINI' || vm.wechatSetting.paymentSource == 'MINI') {
                    vm.miniPayMentOptions[1].show = false;
                    vm.miniPayMentOptions[2].show = false;
                }
                if (vm.wechatSetting.paymentSource == 'WEB') {
                    vm.miniPayMentOptions[1].show = false;
                }
                if (vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.miniPayMentOptions[2].show = false;
                }
            }
            if (index == 3) {
                if (vm.wechatSetting.paymentSource == 'WEB' || vm.miniSetting.paymentSource == 'WEB') {
                    vm.webPayMentOptions[1].show = false;
                    vm.webPayMentOptions[2].show = false;
                }
                if (vm.wechatSetting.paymentSource == 'MINI') {
                    vm.webPayMentOptions[1].show = false;
                }
                if (vm.miniSetting.paymentSource == 'SUB') {
                    vm.webPayMentOptions[2].show = false;
                }
            }
        }

        $q.all([vm.store.$promise, vm.storePaymentSetting.$promise]).then(function() {
            vm.wechatSetting = StoreWechatSetting.getByStoreId({
                storeId: vm.store.id
            });
            vm.miniSetting = StoreSmallAppWechatSetting.getEntityByStoreId({
                storeId: vm.store.id
            });
            $q.all([vm.miniSetting.$promise, vm.wechatSetting.$promise]).then(function() {
                if (!vm.wechatSetting.id) {
                    vm.selectTab = 2;
                }
                if (!vm.miniSetting.id) {
                    vm.selectTab = 3;
                }
                if (vm.miniSetting.paymentSource == 'SUB' || vm.storePaymentSetting.paymentSource == 'SUB') {
                    vm.subPayMentOptions[1].show = false;
                    vm.subPayMentOptions[2].show = false;
                }
            });
        });

        $scope.save = function() {
            if (vm.wechatSetting.id) {
                if(!vm.wechatSetting.certificate){
                    MessageService.error("请配置公众号收款商户的微信商户证书key");
                    return
                }
                vm.wechatSetting2 = StoreWechatSetting.update(vm.wechatSetting);
            }
            if (vm.miniSetting.id) {
                if(!vm.miniSetting.certificate){
                    MessageService.error("请配置小程序收款商户的微信商户证书key");
                    return
                }
                vm.miniSetting2 = StoreSmallAppWechatSetting.update(vm.miniSetting);
            }

            if(!vm.storePaymentSetting.weChatCertificatePath){
                MessageService.error("请配置电脑端收款商户的微信商户证书key");
                return
            }
            if (vm.storePaymentSetting.id) {
                vm.storePaymentSetting2 = StorePaymentSetting.update(vm.storePaymentSetting);
            }else{
                vm.storePaymentSetting2 = StorePaymentSetting.save(vm.storePaymentSetting);
            }


            $q.all([vm.storePaymentSetting2.$promise]).then(function() {
                $state.go("guide-commodity");
            });

        }

    }
})();
