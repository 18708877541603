(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('LogisticsCompany', LogisticsCompany)
        .factory('Logistics', Logistics);

    Logistics.$inject = ['$resource', 'DateUtils'];

    LogisticsCompany.$inject = ['$resource'];

    function Logistics($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/logistics/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'save': {
                method: 'POST',
                params: {
                    path: 'save'
                }
            }
        });
    }


    function LogisticsCompany ($resource) {
        var resourceUrl =  'manager/' + 'api/logistics-companies/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
            'update': { method:'PUT' },
            'delBatch': {
                method:'DELETE',
                params:{
                    path:"delBatch"
                }
            },
            "getWechatLogistics":{
                method:"GET",
                params:{
                    path:"wechat-logistics"
                },
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
