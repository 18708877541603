(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductXmlVersionController', ProductXmlVersionController);
    ProductXmlVersionController.$inject = ['$scope', '$uibModalInstance', 'MessageService', 'ProductXmls1','xmlId', '$timeout'];

    function ProductXmlVersionController( $scope, $uibModalInstance, MessageService, ProductXmls1,xmlId, $timeout) {
        var vm = this;
        vm.productXmlId = xmlId;
        vm.isCover = true;
        init()
        function init(){
            ProductXmls1.get({id: vm.productXmlId}, function (resp) {
                vm.pages = resp.document.pages.page;
                $timeout(function(){
                    swiper();
                })
            })
        }
        vm.productList = [
            {name:'照片书', id:'BOOK',},
            {name:'冲印', id:'PHOTO',},
            {name:'台历', id:'CALENDAR'},
            {name:'万物',id:'THING'},
        ];
        vm.productSeq=0;
        $scope.productCb = function(index){
            vm.productSeq=index;
        };
        vm.cancel = function () {
            $uibModalInstance.close(false);
        };
        vm.save = function () {
            var data ={
                id:vm.productXmlId,
                productType:vm.productList[vm.productSeq].id
            };
            if (vm.productList[vm.productSeq].id == 'BOOK' && vm.isCover) {
                data.patternType = 'BOOK';
            }
            ProductXmls1.upgradeProductXml(data,function (res) {
                if(res.status == 200){
                    MessageService.success("模板升级成功");
                    $uibModalInstance.close(true);
                }else{
                    MessageService.error(res.message || "模板升级失败，请稍后重试");
                    $uibModalInstance.close(false);
                }
            },function (error) {
                MessageService.error(error.data.message || "模板升级失败，请稍后重试");
                $uibModalInstance.close(false);
            })
        }
        function swiper() {
            var swiper = new Swiper('.swiper-container', {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
    }

})();
