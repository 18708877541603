(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('NavigationSettingController', NavigationSettingController);

    NavigationSettingController.$inject = ['$scope', '$state', 'StoreStyleSetting', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','NavigationSetting', 'MessageService','Principal','Store'];

    function NavigationSettingController ($scope, $state, StoreStyleSetting, ParseLinks, AlertService, paginationConstants, pagingParams, NavigationSetting, MessageService,Principal,Store) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_DIANPU').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.store = Store.getCurrent();
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        loadAll();

        function loadAll () {
            StoreStyleSetting.get({}, function (res) {
                vm.storeStyleSetting = res;
                vm.navigationSettings = [];
                angular.forEach(res.navigationSettings,function (nav) {
                    if(!nav.cancelled){
                        vm.navigationSettings.push(nav)
                    }
                })
                console.log(vm.navigationSettings.length)
                // vm.links = ParseLinks.parse(headers('link'));
                // vm.totalItems = headers('X-Total-Count');
                // vm.queryCount = vm.totalItems;
                // vm.page = pagingParams.page;
            }, onError);

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        $scope.changeUsed = function (nav) {
            NavigationSetting.used({id:nav.id},function (res) {
                nav.used = res.used;
            })
        }

        $scope.tabChange = function () {
            StoreStyleSetting.update(vm.storeStyleSetting, function (res) {
                vm.storeStyleSetting.navigateOpen = res.navigateOpen;
                MessageService.success('保存成功');
            }, function () {
                MessageService.error('启用失败')
            });
        }

        $scope.delete = function (id) {
            console.log(id);
            MessageService.confirm("确认要删除该栏目吗？", function () {
                NavigationSetting.delete({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        };
    }
})();
