(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PassProductDialogController', PassProductDialogController);

    PassProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'PassProduct', '$q', '$uibModalInstance',
    'Product', 'Qinius', 'MessageService', 'entity'];

    function PassProductDialogController ($timeout, $scope, $stateParams, PassProduct, $q, $uibModalInstance,
     Product, Qinius, MessageService, entity) {
        var vm = this;
        vm.data = entity; 
        vm.save = save;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (vm.data.brokerage ==null|| vm.data.brokerage <0 || vm.data.brokerage>100) {
                MessageService.error("提成比例不能小于0，大于100，请确认后再操作。");
                return;
            }
            // if (vm.data.maxDiscount ==null|| vm.data.maxDiscount<0 || vm.data.maxDiscount>100) {
            //     MessageService.error("抵扣上限不能小于0，大于100，请确认后再操作。");
            //     return;
            // }
            if (vm.data.type ==null ) {
                MessageService.error("请选择类型");
                return;
            }
            $uibModalInstance.close(vm.data);
        } 

        vm.types = [{key:"Normal",value:"福利商品"},{key:"Vip",value:"会员商品"}]

        $q.all([vm.data.$promise]).then(function () {
             
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        

    }
})();
