(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MessageNotifyDialogController', MessageNotifyDialogController);

    MessageNotifyDialogController.$inject = ['$timeout', '$q', '$state', '$scope', '$stateParams', 'MessageService', 'entity', 'MessageNotify'];

    function MessageNotifyDialogController ($timeout, $q, $state, $scope, $stateParams, MessageService, entity, MessageNotify) {
        var vm = this;

        vm.messageNotify = entity;
        vm.clear = clear;
        vm.save = save;  

        $q.all([vm.messageNotify.$promise, ]).then(function () {
            
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () { 
            $state.go("message-notify")
        }
        $scope.validata = function(){
            if (!vm.messageNotify.title) {
                MessageService.error("请录入标题");
                return false;
            }

            if (!vm.messageNotify.content) {
                MessageService.error("请录入内容");
                return false;
            }
            return true;
        }

        function save () {
            if(!$scope.validata()){
                return;
            }
            vm.isSaving = true;
            if (vm.messageNotify.id !== null) {
                MessageNotify.update(vm.messageNotify, onSaveSuccess, onSaveError);
            } else {
                MessageNotify.save(vm.messageNotify, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:messageNotifyUpdate', result); 
            MessageService.success("保存成功");
            $state.go("message-notify")
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }

    }
})();
