(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreDetailSeniorController', StoreDetailSeniorController);

    StoreDetailSeniorController.$inject = ['$scope', '$q', 'OrderSetting', 'entity', 'Store', 'MessageService', 'Qinius', '$state', 'StoreConfig', '$uibModal','Principal'];

    function StoreDetailSeniorController($scope, $q, OrderSetting, entity, Store, MessageService, Qinius, $state, StoreConfig, $uibModal,Principal) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_SETTING').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问商户的权限！");
                $state.go("home");
            }
        });

        vm.store = entity;
        //保存
        vm.save2 = save2;
        vm.storeOrderSetting = OrderSetting.getStoreOrderSetting2();
        vm.storeConfigs = StoreConfig.getAllByCondition({});
        //购物车开关
        vm.storeCart = {
            type:'SHOPPINGCART',
            open: false
        };
        //用户协议开关
        vm.userAgreement = {
            type:'USERPROTOCOL',
            open: false,
            value:'0'
        };
        //优惠券开关
        vm.storeCoupon = {
            type:'COUPON',
            open: false
        };
        //热门分类开关
        vm.storeHot = {
            type:'HOTSHOW',
            open: false
        };
        //运费开关
        vm.storeFreight = {
            type:'FREIGHTMETHOD',
            open: false
        };
        //下单方式
        vm.orderAgreement = {
            type:'ORDERAGREEMENT',
            open: false
        };
        //分类布局
        vm.displayway = {
            type:'DISPLAYWAY',
            open: false,
            value:'0'
        };
        vm.shareQrcode = {
            type:'SHAREQRCODE',
            open: false,
            value:'0'
        }
        vm.navigateWay = {
            type:'NAVIGATEWAY',
            open: false,
            value:'0'
        }
        // vm.storeConfig = false; //购物车开关

        $scope.automaticDeduction = function(flag){
            vm.store.automaticDeduction = flag;
            vm.save2();
        }

        $q.all([ vm.store.$promise, vm.storeConfigs.$promise]).then(function () {
            if (vm.storeConfigs && vm.storeConfigs.length>0) {
                for (var i = vm.storeConfigs.length - 1; i >= 0; i--) {
                    var config = vm.storeConfigs[i];
                    if (config.type == 'SHOPPINGCART') {
                        vm.storeCart = config;
                        continue;
                    }
                    if (config.type == 'COUPON') {
                        vm.storeCoupon = config;
                        continue;
                    }
                    if (config.type == 'HOTSHOW') {
                        vm.storeHot = config;
                        continue;
                    }
                    if (config.type == 'FREIGHTMETHOD') {
                        vm.storeFreight = config;
                        continue;
                    }
                    if (config.type == 'DISPLAYWAY') {
                        vm.displayway = config;
                        continue;
                    }
                    if (config.type == 'SHAREQRCODE') {
                        vm.shareQrcode = config;
                        continue;
                    }
                    if (config.type == 'NAVIGATEWAY') {
                        vm.navigateWay = config;
                        continue;
                    }
                    if (config.type == 'USERPROTOCOL') {
                        vm.userAgreement = config;
                        continue;
                    }
                }
            }
        });

        $scope.ferightDeduction = function(flag){
            vm.storeFreight.open = flag;
            $scope.storeConfigChange(vm.storeFreight);
        }
        $scope.orderAgreementClick = function(item){
            vm.store.orderWay = item;
            save2();
        };
        $scope.displaywayClick = function(item){
            vm.displayway.value = item;
            $scope.storeConfigChange(vm.displayway);
        };
        $scope.navigateWayClick = function(item){
            vm.navigateWay.value = item;
            $scope.storeConfigChange(vm.navigateWay);
        };

        $scope.storeConfigChange = function(config){
            if (!config.id) {
                StoreConfig.save(config, function(data){
                    config = data;
                    MessageService.success("保存成功！");
                }, function(){
                    MessageService.error("保存失败！");
                })
            }else{
                StoreConfig.update(config, function(data){
                    config = data;
                    MessageService.success("保存成功！");
                }, function(){
                    MessageService.error("保存失败！");
                })
            }
            if(config.type == 'SHOPPINGCART'&&!config.open){
                $scope.ferightDeduction(false)
            }
        }



        //加入商户
        $scope.join = function () {
            Store.getCurrent({}, function (res) {
                if(!res.parentId){
                    StoreApplies.getAllByCondition({
                        items: [{key: "storeId", op: "=", value: vm.store.id},
                            {key: "applyType", op: "=", value: "JOIN"},
                            {key: "status", op: "=", value: "Created"}]
                    }, function (res) {
                        if(res.length > 0){
                            Store.get({id:res[0].parentStoreId}, function (item) {
                                MessageService.success("【" + item.name + "】" + "商户正在审核中，不可重复提交！");
                            })
                        }else {
                            $uibModal.open({
                                templateUrl: 'app/entities/store/modal/join.html',
                                controller: 'JoinController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                resolve: {
                                    entity: [function() {
                                        return vm.store
                                    }]
                                }
                            });
                        }
                    })
                }else {
                    MessageService.success("已加盟，不可重复加盟！");
                }
            })
        }
        //解绑
        $scope.unbind = function () {
            Store.getCurrent({}, function (res) {
                if(!res.parentId){
                    MessageService.success("已解绑商户，不可重复解绑！");
                }else {
                    StoreApplies.getAllByCondition({
                        items: [{key: "parentStoreId", op: "=", value: vm.store.parentId},
                            {key: "storeId", op: "=", value: vm.store.id},
                            {key: "applyType", op: "=", value: "SIGNOUT"},
                            {key: "status", op: "=", value: "Created"}]
                    }, function (res) {
                        if(res.length > 0){
                            MessageService.success("解绑申请已提交，不可重复提交！");
                        }else {
                            MessageService.confirm("确定解绑上级商户？",function(){
                                var _data = {
                                    storeName: vm.store.name,
                                    status: vm.store.status,
                                    applyType: 'SIGNOUT',
                                    parentStoreId: vm.store.parentId,
                                }
                                StoreApplies.save(_data, function (res) {
                                    MessageService.success("解绑申请已提交！");
                                }, function (error) {
                                })
                            },function(){},true)
                        }
                    })
                }
            })
        }


        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }




        function save2() {
            vm.isSaving = true;
            if (vm.store.id !== null) {
                Store.update(vm.store, onSaveSuccess2, onSaveError);
            } else {
                Store.save(vm.store, onSaveSuccess2, onSaveError);
            }
        }

        function onSaveSuccess2(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }

        //下单协议
        $scope.clause = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/store/modal/orderAgreement-modal.html',
                controller: 'OrderAgreementModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            })
        }
    }
})();
