(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('AfterSaleController', AfterSaleController);

    AfterSaleController.$inject = ['$scope', '$q', '$state', 'Principal', 'AfterSale', 'ParseLinks', 'paginationConstants', 'pagingParams', 
    'MessageService', 'Qinius', 'Product', '$localStorage', 'BranchStoreManagements', 'Store', 'AfterSaleProblem', 'BranchStore'];

    function AfterSaleController ($scope, $q, $state, Principal, AfterSale, ParseLinks, paginationConstants, pagingParams, 
        MessageService, Qinius, Product, $localStorage, BranchStoreManagements, Store, AfterSaleProblem, BranchStore) {
        var vm = this;
        Principal.hasAuthority('ROLE_STORE_AFTERSALESMANAGEMENT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问权限！");
                $state.go("home");
            }
        });
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.productId = "";
        var s = localStorage.getItem('status');
        localStorage.removeItem('status');

        vm.isAuthenticated = Principal.isAuthenticated;

        vm.jump = jump

        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        vm.allProducts = Product.getAllByCondition({items: []});

        // 用户信息获取storeId
        vm.account = $localStorage.user;
        if (vm.account.authorities.indexOf("ROLE_STORE_BRANCH_SHOP")!=-1 ) {
            vm.branchStores = BranchStore.getAllByCondition({items: [{key: "cancelled", op: "=", value: "false"}]});
            $q.all([ vm.branchStores.$promise, vm.domains.$promise, vm.allProducts.$promise]).then(function() {
                vm.products = angular.copy(vm.allProducts);
                $scope.domain = 'http://' + vm.domains.domains[0] + '/';
                loadAll();
            });
        }else if(vm.account.authorities.indexOf("ROLE_STORE_MY_BRANCH_SHOP")!=-1){
            // 我的分店权限
            vm.storeDivShow = true;
            vm.accountBranch = BranchStoreManagements.findByUserId({userId:vm.account.id});
            $q.all([vm.store.$promise, vm.domains.$promise, vm.store.$promise, vm.accountBranch.$promise, vm.allProducts.$promise]).then(function() {
                $scope.domain = 'http://' + vm.domains.domains[0] + '/';
                if(vm.accountBranch&&vm.accountBranch.branchStore){
                    vm.userBranchStoreId = vm.accountBranch.branchStore.id;
                }
                if (vm.accountBranch&&vm.accountBranch.branchStore&& vm.store.selfSale) {
                    vm.products =vm.accountBranch.branchStore.products;
                }else{
                    vm.products = angular.copy(vm.allProducts);
                }
                vm.branchStores = [];
                loadAll();
            });
        }else{
            vm.branchStores = BranchStore.getAllByCondition({items: [{key: "cancelled", op: "=", value: "false"}]});
            $q.all([ vm.branchStores.$promise, vm.domains.$promise, vm.allProducts.$promise]).then(function() {
                $scope.domain = 'http://' + vm.domains.domains[0] + '/';
                vm.products = angular.copy(vm.allProducts);
                loadAll();
            });
        }
        vm.problems = AfterSaleProblem.getAllByCondition({items: [{key: "storeId", op: "=", value: vm.account.storeId}, {key: "enabled", op: "=", value: "true"}]});

        $scope.delete = function(banner){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Banner.delete({id: banner.id},function () {
                    loadAll();
                });
            },function(){},true);
        }

        $scope.byStatus =function(status){
            vm.status = status;
            loadAll();
        };

        $scope.byTime = function(time){
            vm.time = time;
            loadAll();
        };

        $scope.branchChange = function(){
            if (vm.branchStoreId &&  vm.store.selfSale ) {
                for (var i = vm.branchStores.length - 1; i >= 0; i--) {
                    var bs= vm.branchStores[i];
                    if (bs.id ==vm.branchStoreId ) {
                        vm.products = bs.products;
                    }
                }
            }else{
                vm.products = angular.copy(vm.allProducts);
            }
        }
        
        $scope.paramsChange = function(){
            loadAll();
        };

        function loadAll () {
            var items=[{key:"salesOrder.cancelled",op:"!=",value:"true"},{key:"salesOrder.orderLines.orderArticle.prototype",op:"=",value:"StoreSelf"}];
            if (vm.branchStoreId) {
                items.push({key:"salesOrder.branchStoreId",op:"=",value:vm.branchStoreId});
            }
            if (vm.userBranchStoreId) {
                items.push({key:"salesOrder.branchStoreId",op:"=",value:vm.userBranchStoreId});
            }
            if (vm.problemId) {
                items.push({key:"problemId",op:"=",value:vm.problemId});
            }
            if (vm.time != null) {
                if (vm.time == 'today') {
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()))});
                }else if(vm.time == 'week'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-7*24*60*60*1000-1))});
                }else if(vm.time == 'weeks'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-14*24*60*60*1000-1))});
                }else if(vm.time == 'moth'){
                    items.push({key:"createdDate",op:">=",value:(new Date(new Date(new Date().toLocaleDateString()).getTime()-30*24*60*60*1000-1))});
                }
            };
            if (!!vm.status) {
                items.push({key:"statue",op:"in",value:vm.status});
            };
            if (!!vm.productId) {
                items.push({key:"salesOrder.orderLines.orderArticle.protoProductId",op:"=",value:vm.productId});
            };

            if (vm.searchQuery) {
                items.push({key:"salesOrder.number,describe,problem,number",op:"like",value:vm.searchQuery}),
                AfterSale.byCondition1({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["lastModifiedDate,desc"]
                }, onSuccess, onError);
            } else {
                AfterSale.byCondition1({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: ["lastModifiedDate,desc"]
                }, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.allPagesCount = Math.ceil(vm.totalItems/20);
                vm.queryCount = vm.totalItems;
                vm.afterSales = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("获取数据失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }
    }
})();
