(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('SharePageConfig', SharePageConfig);

    SharePageConfig.$inject = ['$resource'];

    function SharePageConfig($resource) {
        var resourceUrl = 'manager/' + 'api/share-page-configs/:path/:id';
        return $resource(resourceUrl, {}, {
            'save': {
                method: 'PUT'
            },
            'getAllByCondition': {
                method: 'post',
                params: {
                    path: 'getAllByCondition'
                }
            },
        })
    }
     
})();